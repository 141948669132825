import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateCustomDashboardPanel } from "../../../../actions/customPanelModify";
import ModalAT from "../../../ModalAT";

const fields_indicator = [
  {
    name: "Value",
    id: "indicator__value__style",
    font_size: "indicator__value__font_size__style",
    decimal_point: "indicator__value__decimal_point__style",
  },
  {
    name: "Top Header",
    id: "indicator__top-header__style",
    font_size: "indicator__top_header__font_size__style",
    decimal_point: "",
  },
  {
    name: "Unit",
    id: "indicator__unit__style",
    font_size: "indicator__uit__font_size__style",
    decimal_point: "",
  },
  {
    name: "Bottom Text",
    id: "indicator__bottom-text__style",
    font_size: "indicator__bottom_text__font_size__style",
    decimal_point: "",
  },
  {
    name: "Background",
    id: "indicator__background__style",
    font_size: "",
    decimal_point: "",
  },
];
const fields_indicator_text = [
  {
    name: "Value",
    id: "indicator__value__style",
    font_size: "indicator__value__font_size__style",
    decimal_point: "",
  },
  {
    name: "Top Header",
    id: "indicator__top-header__style",
    font_size: "indicator__top_header__font_size__style",
    decimal_point: "",
  },
  {
    name: "Background",
    id: "indicator__background__style",
    font_size: "",
    decimal_point: "",
  },
];
const fontSizeList = [
  "0.5rem",
  "1rem",
  "1.5rem",
  "2rem",
  "2.5rem",
  "3rem",
  "3.5rem",
  "4rem",
  "4.5rem",
  "5rem",
  "5.5rem",
  "6rem",
];
const devimalPointList = [
  "No digit",
  "1 Digit",
  "2 Digit",
  "3 Digit",
  "4 Digit",
  "5 Digit",
];
const background = "#FFFFFF";

const IndicatorStyle = () => {
  const dispatch = useDispatch();
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  // --- MODAL CONF ---
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);
  // --- MODAL ATTR ---
  const [focusedInput, setFocusedInput] = React.useState(null);

  window.indicatorStyleModalOpen = (panel) => {
    setPanelInfo(panel);
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    if (panelInfo.panel_config.panelType === "INDICATOR") {
      setTimeout(() => {
        // console.log(panelInfo);
        document.getElementById("indicator__background__style").value =
          panelInfo.panel_config.data.style.background;

        document.getElementById("indicator__top-header__style").value =
          panelInfo.panel_config.data.style.topHeader;
        document.getElementById(
          "indicator__top_header__font_size__style"
        ).value = panelInfo.panel_config.data.style.topHeader_fontSize;

        document.getElementById("indicator__value__style").value =
          panelInfo.panel_config.data.style.value;
        document.getElementById("indicator__value__font_size__style").value =
          panelInfo.panel_config.data.style.value_fontSize;
        document.getElementById(
          "indicator__value__decimal_point__style"
        ).value = panelInfo.panel_config.data.style.value_decimalPoint
          ? panelInfo.panel_config.data.style.value_decimalPoint
          : "0.##0";

        document.getElementById("indicator__unit__style").value =
          panelInfo.panel_config.data.style.unit;
        document.getElementById("indicator__uit__font_size__style").value =
          panelInfo.panel_config.data.style.unit_fontSize;

        document.getElementById("indicator__bottom-text__style").value =
          panelInfo.panel_config.data.style.bottomHeader;
        document.getElementById(
          "indicator__bottom_text__font_size__style"
        ).value = panelInfo.panel_config.data.style.bottomHeader_fontSize;
      }, 500);
    }
  };
  const closeModal = () => {
    setPanelInfo(null);
    setFocusedInput();
    setIsOpen(false);
  };

  const checkValue = (field) => {
    switch (field.id) {
      case "indicator__background__style":
        return panelInfo.panel_config.data.style.background;
      case "indicator__top-header__style":
        return panelInfo.panel_config.data.style.topHeader;
      case "indicator__value__style":
        return panelInfo.panel_config.data.style.value;
      case "indicator__unit__style":
        return panelInfo.panel_config.data.style.unit;
      case "indicator__bottom-text__style":
        return panelInfo.panel_config.data.style.bottomHeader;
      default:
        break;
    }
  };

  const checkValueFontSize = (fs) => {
    switch (fs) {
      case "indicator__top_header__font_size__style":
        return panelInfo.panel_config.data.style.topHeader_fontSize
          ? panelInfo.panel_config.data.style.topHeader_fontSize
          : "";
      case "indicator__value__font_size__style":
        return panelInfo.panel_config.data.style.value_fontSize
          ? panelInfo.panel_config.data.style.value_fontSize
          : "";
      case "indicator__uit__font_size__style":
        return panelInfo.panel_config.data.style.unit_fontSize
          ? panelInfo.panel_config.data.style.unit_fontSize
          : "";
      case "indicator__bottom_text__font_size__style":
        return panelInfo.panel_config.data.style.bottomHeader_fontSize
          ? panelInfo.panel_config.data.style.bottomHeader_fontSize
          : "";
      default:
        break;
    }
  };

  const checkValueDecimalPoint = (dp) => {
    switch (dp) {
      case "indicator__value__decimal_point__style":
        return panelInfo.panel_config.data.style.value_decimalPoint
          ? panelInfo.panel_config.data.style.value_decimalPoint
          : "0.##0";
      default:
        break;
    }
  };

  const clickApplyHandler = () => {
    panelInfo.panel_config.data.style.background = document.getElementById(
      "indicator__background__style"
    ).value;

    panelInfo.panel_config.data.style.topHeader = document.getElementById(
      "indicator__top-header__style"
    ).value;
    panelInfo.panel_config.data.style.topHeader_fontSize =
      document.getElementById("indicator__top_header__font_size__style").value;

    panelInfo.panel_config.data.style.value = document.getElementById(
      "indicator__value__style"
    ).value;
    panelInfo.panel_config.data.style.value_fontSize = document.getElementById(
      "indicator__value__font_size__style"
    ).value;
    if (panelInfo.panel_config.panelType === "INDICATOR") {
      let dcml = document.getElementById(
        "indicator__value__decimal_point__style"
      ).value;
      panelInfo.panel_config.data.style.value_decimalPoint = dcml;
    }

    if (panelInfo.panel_config.panelType === "INDICATOR") {
      panelInfo.panel_config.data.style.unit = document.getElementById(
        "indicator__unit__style"
      ).value;
      panelInfo.panel_config.data.style.unit_fontSize = document.getElementById(
        "indicator__uit__font_size__style"
      ).value;
    }

    if (panelInfo.panel_config.panelType === "INDICATOR") {
      panelInfo.panel_config.data.style.bottomHeader = document.getElementById(
        "indicator__bottom-text__style"
      ).value;
      panelInfo.panel_config.data.style.bottomHeader_fontSize =
        document.getElementById(
          "indicator__bottom_text__font_size__style"
        ).value;
    }

    dispatch(
      updateCustomDashboardPanel(
        activeTabData.guid,
        panelInfo.guid,
        panelInfo.panel_config
      )
    );
    closeModal();
  };

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_indicator_style_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle="Update Indicator Style"
          contentLabel="Update Indicator Style"
          ariaHideApp={false}
        >
          <div className="d-flex justify-content-between">
            <div className="mr-2 w-75 __IND_STL_INN__">
              <div className="row form__row align-items-center mb-1">
                <div className="col-md-3">
                  <label className="form__label">Label</label>
                </div>
                <div className="col-md-3 text-center">
                  <label className="form__label">Color</label>
                </div>
                <div className="col-md-3 text-center">
                  <label className="form__label">Font Size</label>
                </div>
                {panelInfo.panel_config.panelType === "INDICATOR" ? (
                  <div className="col-md-3 text-center">
                    <label className="form__label"> "Format Decimal"</label>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {(panelInfo.panel_config.panelType === "INDICATOR"
                ? fields_indicator
                : fields_indicator_text
              ).map((field, index) => (
                <div
                  className="row form__row align-items-center mb-1"
                  key={index}
                >
                  <div className="col-md-3">
                    <label htmlFor={field.id} className="form__label">
                      {field.name}
                    </label>
                  </div>
                  <div className="col-md-3 d-flex justify-content-between">
                    {/* COLOR */}
                    {/* <div className={field.name === "Background" ? "form__group w-100" : "form__group w-50"}> */}
                    <div className={"form__group w-100"}>
                      <input
                        type="text"
                        id={field.id}
                        className="form-control form__control"
                        defaultValue={checkValue(field)}
                        onFocus={(e) => {
                          e.preventDefault();
                          setFocusedInput(field);
                          document.querySelector(
                            ".sketch-picker"
                          ).style.display = "block";
                        }}
                        onBlur={(e) => {
                          e.preventDefault();
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          // // console.log(e.clipboardData.getData("text"));
                          e.target.value = e.clipboardData.getData("text");
                        }}
                      />
                    </div>
                  </div>
                  {/* FONT SIZE & DECIMAL POINT */}
                  {field.name !== "Background" ? (
                    <>
                      <div className="col-md-3 d-flex justify-content-between">
                        {/* <div className={field.name === "Value" ? "form__group __w-33__ ml-1 mr-1" : "form__group w-50 ml-1 mr-1"}> */}
                        <div className={"form__group w-100"}>
                          <select
                            className="custom-select"
                            id={field.font_size}
                            defaultValue={checkValueFontSize(field.font_size)}
                          >
                            {fontSizeList.map((fs, index) => (
                              <option value={fs} key={index}>
                                {fs}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {panelInfo.panel_config.panelType === "INDICATOR" &&
                      field.name === "Value" ? (
                        <>
                          <div className="col-md-3 d-flex justify-content-between">
                            {/* <div className="form__group __w-33__"> */}
                            <div className={"form__group w-100"}>
                              {/* <select
                                className="custom-select"
                                id={field.decimal_point}
                                defaultValue={checkValueDecimalPoint(field.decimal_point)}
                              >
                              {
                                devimalPointList.map((dp, index) => (<option value={dp} key={index}>{dp}</option>))
                              }
                              </select> */}
                              <input
                                type="text"
                                id={field.decimal_point}
                                className="form-control form__control"
                                defaultValue={checkValueDecimalPoint(
                                  field.decimal_point
                                )}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  e.target.value =
                                    e.clipboardData.getData("text");
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                // </div>
              ))}
            </div>
            <div>
              {focusedInput ? (
                <p className="m-0">Choose Color for {focusedInput.name}</p>
              ) : (
                <></>
              )}
              <SketchPicker
                color={background}
                onChangeComplete={(color, element) => {
                  if (focusedInput) {
                    document.getElementById(focusedInput.id).value = color.hex;
                    document.getElementById(
                      focusedInput.id
                    ).style.backgroundColor = color.hex + " !important";
                  }
                }}
              />
            </div>
          </div>
          {/* SUBMIT */}
          <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
            <button
              className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
              disabled={false}
              onClick={(e) => {
                e.stopPropagation();
                clickApplyHandler();
              }}
            >
              Apply
            </button>
          </div>
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorStyle);
