import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchAssetSpecs } from "../../../../actions/webglAssociatesActions";
import ModalTemplate from "../../ModalTemplate";

const AssetSpecsViewer = ({
  fetchAssetSpecs,
  requestPending,
  assetSpecs,
  failedToFetchData,
}) => {
  const [isOpen, setOpen] = useState(false);

  window.ASViewer = (projectGuid, assetGuid) => {
    setOpen(true);
    fetchAssetSpecs(projectGuid, assetGuid);
  };

  return (
    <>
      <ModalTemplate
        isOpen={isOpen}
        requestPending={requestPending}
        setOpen={setOpen}
        modalID="ASViewer"
        ariaHideApp={false}
        dataSubmited={false}
      >
        <div className="ReactModal__Content--container ReactModal__Content--textonly">
          <div className="ReactModal__curvedBorder ReactModal__curvedBorder--top-left"></div>
          <div className="ReactModal__curvedBorder ReactModal__curvedBorder--top-right"></div>
          <div className="ReactModal__curvedBorder ReactModal__curvedBorder--bottom-left"></div>
          <div className="ReactModal__curvedBorder ReactModal__curvedBorder--bottom-right"></div>
          {!failedToFetchData && assetSpecs && assetSpecs.basic_data && (
            <div className="ReactModal__Content--block">
              <div className="ReactModal__Content--inner">
                <div className="ReactModal__Content--inner__header">
                  <div className="ReactModal__Content--inner__header--block">
                    <div className="ReactModal__Content--inner__header--block__block">
                      <marquee>
                        <h4 className="ReactModal__Content--title">
                          {" "}
                          {assetSpecs && assetSpecs.basic_data
                            ? assetSpecs.basic_data[0].value + "'s SPECIFICATIONS"
                            : "Asset Specs"}
                        </h4>
                      </marquee>
                    </div>
                  </div>
                </div>
                <div className="ReactModal__Content--inner__body">
                  <div className="ReactModal__Content--inner__body--block">
                    <table className="table ReactModal__Content--table">
                      <tbody>
                        {assetSpecs &&
                          assetSpecs.basic_data &&
                          assetSpecs.basic_data
                            .filter((specs) => specs.showing === true)
                            .map((specs, index) => (
                              <tr key={index}>
                                <td>{specs.label ? specs.label : ""}</td>
                                <td>{specs.value ? specs.value : ""}</td>
                              </tr>
                            ))}
                        {assetSpecs &&
                          assetSpecs.specifications &&
                          assetSpecs.specifications
                            .filter((specs) => specs.showing === true)
                            .map((specs, index) => (
                              <tr key={index}>
                                <td>{specs.label ? specs.label : ""}</td>
                                <td>{specs.value ? specs.value : ""}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            type="button"
            title="close"
            className="btn ReactModal__btn ReactModal__btn--close px-0"
            id="webgl-associates-modal-specification-viewer-close-btn-asviewer"
            onClick={() => {
              setOpen(false);
            }}
          ></button>
        </div>
      </ModalTemplate>

      {/* Previous modal  before 19 May 2022 */}
      {/* <ModalTemplate
      isOpen={isOpen}
      requestPending={requestPending}
      setOpen={setOpen}
      modalID="ASViewer"
      dataSubmited={false}
    >
      <span className="top-left-bottom_corner"></span>
      <span className="top-left-top_corner"></span>
      <span className="top-right-bottom_corner"></span>
      <span className="top-right-top_corner"></span>
      {!failedToFetchData && assetSpecs && assetSpecs.basic_data && (
        <div className="modal-content modal-content_table">
          <div className="modal-header justify-content-center p-0 border-0">
            <h5 className="specifications-header-title">
              {" "}
              {assetSpecs && assetSpecs.basic_data
                ? assetSpecs.basic_data[0].value
                : "Asset Specs"}
            </h5>
            <button
              type="button"
              className="transparent-btn btn-close"
              title="Close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setOpen(false);
              }}
            >
              
            </button>
          </div>
          <div className="modal-body modal__parent_2nd_child_boday">
            <table className="mb-4 pb-4">
              <tbody>
                {assetSpecs &&
                  assetSpecs.specifications &&
                  assetSpecs.specifications
                    .filter((specs) => specs.showing === true)
                    .map((specs) => (
                      <tr>
                        <td width={150} className="pe-2">
                          {specs.label ? specs.label : ""}
                        </td>
                        <td className="pe-4">
                          {specs.value ? specs.value : ""}
                        </td>
                      </tr>
                    ))}
                {assetSpecs &&
                  assetSpecs.basic_data &&
                  assetSpecs.basic_data
                    .filter((specs) => specs.showing === true)
                    .map((specs) => (
                      <tr>
                        <td width={80} className="pe-2">
                          {specs.label ? specs.label : ""}
                        </td>
                        <td className="pe-4">
                          {specs.value ? specs.value : ""}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <span className="bottom-left-bottom_corner"></span>
      <span className="bottom-left-top_corner"></span>
      <span className="bottom-right-bottom_corner"></span>
      <span className="bottom-right-top_corner"></span>
    </ModalTemplate> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  requestPending: state.ui.requestPending,
  assetSpecs: state.webglAssociates.modals.assetSpecs,
  failedToFetchData: state.webglAssociates.failedToFetchData,
});

export default connect(mapStateToProps, {
  fetchAssetSpecs,
})(AssetSpecsViewer);
