import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { getIconFromList } from "../../../static/IconServe";
import DefaultChart from "./SocketChart/DefaultChart";
import {
  deleteChart,
  deleteCustomDashboardPanel,
  updateCustomDashboardPanel,
} from "../../../actions/customPanelModify";
import SocketChartLine from "./SocketChart/SingleChart_Line";
import SocketChartBar from "./SocketChart/SingleChart_bar";
import SocketChartGague from "./SocketChart/SingleChart_gague";
import SocketChartBarGroup from "./SocketChart/SingleChart_bar_group";
import BubbleMap from "./SocketChart/BubbleMap";
import {
  rndPositionDefaultXYWH,
  rndPositionStyle,
  rndViewerClassName,
} from "../../../utils";
import ProfileGroup from "./SocketChart/ProfileGroup/ProfileGroup";

const Charts = ({ panel }) => {
  const dispatch = useDispatch();
  const panelUserAccessInfo = useSelector(
    (state) => state.customPanelModify.panelUserAccessInfo
  );
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );

  const [isDraggableUnAvailable, setIsDraggableUnAvailable] = useState(false);
  const [isMeshPlot, setIsMeshPlot] = useState(false);

  // // LINE CHART SCROLBAR IS PRESENT - IF THERE IS NO SCROLL (TOTAL PAGE = 1), SCROLLBAR SPACE SHOULD NOT BE APPEAR
  const [
    checklineChartScrollbarIsPresent,
    setCheckLineChartScrollbarIsPresent,
  ] = useState(false);

  const [zoomCtrl, setZoomCtrl] = useState(false);
  const [maximized, setMaximize] = useState(false);

  const openChartEditModal = (chart_data) => {
    switch (chart_data.defaultData.chart_type) {
      case "line":
        window.lineChartEditModalOpen(panel);
        break;
      case "bar":
        window.barChartEditModalOpen(panel);
        break;
      case "pie":
        window.pieChartEditModalOpen(panel);
        break;
      case "mesh":
        window.meshChartEditModalOpen(panel);
        break;
      case "profile_group":
        window.profileGroupEditModalOpen(panel);
        break;
      case "mapbox_density_heatmap":
        window.mapboxDensityHeatmapChartEditModalOpen(panel);
        break;
      case "stacked_bar_chart":
        window.stackedBarChartEditModalOpen(panel);
        break;
      case "stacked_column_chart":
        window.stackedColumnChartEditModalOpen(panel);
        break;
      case "gague_chart":
        window.gagueChartEditModalOpen(panel);
        break;
      case "donut_chart":
        window.donutChartEditModalOpen(panel);
        break;
      case "bubble_map":
        window.bubbleMapEditModalOpen(panel);
        break;
      default:
        break;
    }
  };

  // REACT RND INSIDE ATTRIBUTE OPERATION - START
  const getResizedDeltaHeight = () => {
    if (panel.panel_config.defaultData.chart_type === "bar") {
      return 180 - panel.panel_config.defaultPosition.height;
    } else {
      return 200 - panel.panel_config.defaultPosition.height;
    }
  };

  const resizeRNDElementAfterResizeOperatoinFinish = () => {
    if (panel.panel_config.defaultData.chart_type === "bar") {
      document.getElementById("rnd-section-chart-" + panel.guid).style.height =
        180 + "px";
    } else {
      document.getElementById("rnd-section-chart-" + panel.guid).style.height =
        200 + "px";
      setCheckLineChartScrollbarIsPresent(true);
    }
  };
  // REACT RND INSIDE ATTRIBUTE OPERATION - END

  // CLASS "chart-info-wrapper" ATTRIBUTE OPERATION - START
  const resizeChartInfoWraperHeight = () => {
    if (
      panel.panel_config.defaultData.chart_type === "line" &&
      panel.panel_config.data
    ) {
      return panel.panel_config.defaultPosition.height - 20 + "px";
    } else if (
      panel.panel_config.defaultData.chart_type === "pie" &&
      panel.panel_config.data
    ) {
      return panel.panel_config.defaultPosition.height - 21 + "px";
    } else if (
      panel.panel_config.defaultData.chart_type === "bubble_map" &&
      panel.panel_config.data
    ) {
      return maximized
        ? "100%"
        : panel.panel_config.defaultPosition.height - 20 + "px";
    } else {
      return panel.panel_config.defaultPosition.height - 0 - 20 + "px";
    }
  };
  // CLASS "chart-info-wrapper" ATTRIBUTE OPERATION - START

  const updatePanelSectionPosition = (panel, x, y, w, h) => {
    panel.panel_config.defaultPosition.x = x;
    panel.panel_config.defaultPosition.y = y;
    panel.panel_config.defaultPosition.width = w;
    panel.panel_config.defaultPosition.height = h;

    panel.panel_config.panelStyleRatio = {
      widthPercent: w / document.documentElement.clientWidth,
      // heightPercent: (h/document.documentElement.clientHeight),
      heightPercent: h,
      x: x / document.documentElement.clientWidth,
      y: y / document.documentElement.clientHeight,
    };
    dispatch(
      updateCustomDashboardPanel(
        activeTabData.guid,
        panel.guid,
        panel.panel_config
      )
    );
  };

  const getChartByType = (type) => {
    switch (type) {
      case "line":
        return panel.panel_config.bar_data ? (
          <SocketChartBarGroup chart={panel.panel_config.data} panel={panel} />
        ) : (
          // ( panel.panel_config.bar_data.is_three_dym ?
          //   <SocketChart_line3D chart={panel.panel_config.data} panel={panel}/> :
          //   <SocketChartBarGroup chart={panel.panel_config.data} panel={panel} /> )
          <SocketChartLine
            chart={panel.panel_config.data}
            panel={panel}
            checklineChartScrollbarIsPresent={checklineChartScrollbarIsPresent}
            setCheckLineChartScrollbarIsPresent={
              setCheckLineChartScrollbarIsPresent
            }
          />
        );
      // );
      case "bar":
        return panel.panel_config.data.y_axis_categories.length > 0 ? (
          <SocketChartBarGroup chart={panel.panel_config.data} panel={panel} />
        ) : (
          <SocketChartBar chart={panel.panel_config.data} panel={panel} />
        );
      case "pie":
        return (
          <SocketChartBarGroup chart={panel.panel_config.data} panel={panel} />
        );
      case "mesh":
        return (
          <SocketChartBarGroup chart={panel.panel_config.data} panel={panel} />
        );
      case "profile_group":
        return (
          <ProfileGroup panel={panel} zoomCtrl={zoomCtrl} />
        );
      case "mapbox_density_heatmap":
        return (
          <SocketChartBarGroup chart={panel.panel_config.data} panel={panel} />
        );
      case "gague_chart":
        return (
          <SocketChartGague chart={panel.panel_config.data} panel={panel} />
        );
      case "bubble_map":
        return (
          <BubbleMap
            chart={panel.panel_config.data}
            panel={panel}
            maximized={maximized}
          />
        );
      default:
        break;
    }
  };

  const minMaxPanelControlHandler = () => {
    console.log(maximized);
    setMaximize(!maximized);
    let selector = document.getElementById("rnd-section-chart-" + panel.guid);
    if (!isMeshPlot && selector.classList.contains("__IAM_A_VIEWER__")) {
      minMaxPanelControlHandler_(selector);
    } else {
      minMaxPanelControlHandler_(selector);
    }
  };

  const minMaxPanelControlHandler_ = (selector) => {
    setMaximize(!maximized);
    // setTimeout(() => {
    if (isMeshPlot) {
      setIsMeshPlot(false);
    } else {
      if (selector.classList.contains("__maximize__")) {
        selector.classList.remove("__maximize__");
        if (panel.panel_config.defaultData.chart_type === "line" || panel.panel_config.defaultData.chart_type === "profile_group")
          selector.classList.remove("__line__");
        else if (panel.panel_config.defaultData.chart_type === "bar")
          selector.classList.remove("__bar__");
        else if (panel.panel_config.defaultData.chart_type === "pie")
          selector.classList.remove("__pie__");
        else if (panel.panel_config.defaultData.chart_type === "mesh") {
          selector.classList.remove("__mesh__");
          // setIsMeshPlot(false);
        } else if (
          panel.panel_config.defaultData.chart_type === "mapbox_density_heatmap"
        ) {
          selector.classList.remove("__mapbox_density_heatmap__");
          Object.assign(panel.panel_config, { full_screen: false });
        } else if (panel.panel_config.defaultData.chart_type === "gague_chart")
          selector.classList.remove("__gague__");
        selector.closest("body").classList.remove("fullscreen__enabled");
        setZoomCtrl(false);
      } else {
        selector.classList.add("__maximize__");
        if (panel.panel_config.defaultData.chart_type === "line" || panel.panel_config.defaultData.chart_type === "profile_group")
          selector.classList.add("__line__");
        else if (panel.panel_config.defaultData.chart_type === "bar")
          selector.classList.add("__bar__");
        else if (panel.panel_config.defaultData.chart_type === "pie")
          selector.classList.add("__pie__");
        else if (panel.panel_config.defaultData.chart_type === "mesh") {
          selector.classList.add("__mesh__");
          // setIsMeshPlot(true);
        } else if (
          panel.panel_config.defaultData.chart_type === "mapbox_density_heatmap"
        ) {
          selector.classList.add("__mapbox_density_heatmap__");
          Object.assign(panel.panel_config, { full_screen: true });
        } else if (panel.panel_config.defaultData.chart_type === "gague")
          selector.classList.add("__gague__");
        selector.closest("body").classList.add("fullscreen__enabled");
        setZoomCtrl(true);
        // document.querySelector(".__mapbox_density_heatmap__ .js-plotly-plot .plotly .user-select-none").style.width = "100%"
      }
    }
    // }, 2000);
  };

  const getDefaultChartName = (ch_inf) => {
    if(ch_inf.bar_data) {
      return panel.panel_config.bar_data.chart_name;
    } else {
      if (ch_inf.data && ch_inf.data.chart_name)
        return panel.panel_config.data.chart_name;
      else {
        if (ch_inf.defaultData.chart_type === "bar") return "Single Chart";
        else if (ch_inf.defaultData.chart_type === "line")
          return "Combined Chart";
        else if (ch_inf.defaultData.chart_type === "pie") return "Pie Chart";
        else if (ch_inf.defaultData.chart_type === "gague_chart")
          return "Gague Chart";
        else return "Default Chart";
      }
    }
  };

  return (
    <>
      {/* PANEL BODY */}
      <div className="chart-body-section">
        {panel.panel_config.bar_data &&
        panel.panel_config.bar_data.chart_type_api_sep &&
        panel.panel_config.bar_data.chart_type_api_sep === "mesh" &&
        isMeshPlot ? (
          <div className="mesh-plot-div">
            <div
              className="d-flex justify-content-end"
              style={{ margin: "2px 0px 6px" }}
            >
              <span
                className="panel-remove-icon zoom_in_out"
                onClick={(e) => {
                  e.preventDefault();
                  minMaxPanelControlHandler();
                }}
              >
                {getIconFromList("ZOOM_OUT", {
                  cursor: "pointer",
                })}
              </span>
              <span
                className="panel-edit-icon"
                data-bs-toggle="modal"
                data-bs-target={"#rnd-section-chart-edit-modal-" + panel.guid}
                onClick={(e) => {
                  e.preventDefault();
                  openChartEditModal(panel.panel_config);
                  // console.log(panel.panel_config);
                }}
              >
                {getIconFromList("PENCIL_EDIT_ICON", {
                  cursor: "pointer",
                })}
              </span>
              <span
                className="panel-remove-icon"
                onClick={(e) => {
                  e.preventDefault();
                  if (panel.guid) {
                    if (panel.chart_guid) {
                      dispatch(
                        deleteChart(
                          panelFieldInfo.panel_type,
                          panelFieldInfo.panel_type_guid,
                          panel.chart_guid
                        )
                      );
                    }
                    dispatch(
                      deleteCustomDashboardPanel(activeTabData.guid, panel.guid)
                    );
                    document
                      .getElementById("rnd-section-chart-" + panel.guid)
                      .remove();
                  }
                }}
              >
                {getIconFromList("DELETE", { cursor: "pointer" })}
              </span>
            </div>
            <SocketChartBarGroup
              chart={panel.panel_config.data}
              panel={panel}
            />
          </div>
        ) : (
          <Rnd
            className={rndViewerClassName(
              "rnd-section-chart",
              panelUserAccessInfo
            )}
            id={"rnd-section-chart-" + panel.guid}
            // style={panel.panel_config.defaultStyle}
            // default={panel.panel_config.defaultPosition}
            style={rndPositionStyle(panel.panel_config.defaultStyle)}
            default={rndPositionDefaultXYWH(panel.panel_config)}
            disableDragging={
              panelUserAccessInfo && !panelUserAccessInfo.dashboard.operation
                ? true
                : isDraggableUnAvailable
            }
            onDragStop={(event, dragableData) => {
              event.preventDefault();
              if (
                panelUserAccessInfo &&
                panelUserAccessInfo.dashboard.operation
              ) {
                updatePanelSectionPosition(
                  panel,
                  dragableData.x,
                  dragableData.y,
                  panel.panel_config.defaultPosition.width,
                  panel.panel_config.defaultPosition.height
                );
              }
            }}
            onResizeStop={(event, direction, ref, delta, position) => {
              event.preventDefault();
              if (
                panelUserAccessInfo &&
                panelUserAccessInfo.dashboard.operation
              ) {
                if (
                  panel.panel_config.defaultPosition.height + delta.height >=
                  200
                ) {
                  // // console.log("if");
                  updatePanelSectionPosition(
                    panel,
                    position.x,
                    position.y,
                    panel.panel_config.defaultPosition.width + delta.width,
                    panel.panel_config.defaultPosition.height + delta.height
                  );
                } else {
                  // // console.log("else");
                  delta = {
                    width: delta.width,
                    height: getResizedDeltaHeight(),
                  };
                  updatePanelSectionPosition(
                    panel,
                    position.x,
                    position.y,
                    panel.panel_config.defaultPosition.width + delta.width,
                    panel.panel_config.defaultPosition.height + delta.height
                  );
                  resizeRNDElementAfterResizeOperatoinFinish();
                }
              }
            }}
          >
            <div className="chart-container-wrapper rnd__chart__container--wrapper __RND_EMD_CHILD_CONTAINER__">
              <div className="chart-container">
                {panelUserAccessInfo &&
                panelUserAccessInfo.dashboard.operation ? (
                  <div
                    onMouseOver={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(true);
                    }}
                    onMouseLeave={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(false);
                    }}
                  >
                    <span
                      className="panel-remove-icon zoom_in_out"
                      onClick={(e) => {
                        e.preventDefault();
                        minMaxPanelControlHandler();
                      }}
                    >
                      {getIconFromList(zoomCtrl ? "ZOOM_OUT" : "ZOOM_IN", {
                        cursor: "pointer",
                      })}
                    </span>
                    <span
                      className="panel-edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target={
                        "#rnd-section-chart-edit-modal-" + panel.guid
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        openChartEditModal(panel.panel_config);
                      }}
                    >
                      {getIconFromList("PENCIL_EDIT_ICON", {
                        cursor: "pointer",
                      })}
                    </span>
                    <span
                      className="panel-remove-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        if (panel.guid) {
                          if (panel.chart_guid) {
                            dispatch(
                              deleteChart(
                                panelFieldInfo.panel_type,
                                panelFieldInfo.panel_type_guid,
                                panel.chart_guid
                              )
                            );
                          }
                          dispatch(
                            deleteCustomDashboardPanel(
                              activeTabData.guid,
                              panel.guid
                            )
                          );
                          document
                            .getElementById("rnd-section-chart-" + panel.guid)
                            .remove();
                        }
                      }}
                    >
                      {getIconFromList("DELETE", { cursor: "pointer" })}
                    </span>
                  </div>
                ) : (
                  <div
                    onMouseOver={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(true);
                    }}
                    onMouseLeave={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(false);
                    }}
                  >
                    <span
                      className="panel-remove-icon __zoom_in_out_viewer__"
                      onClick={(e) => {
                        e.preventDefault();
                        minMaxPanelControlHandler();
                      }}
                    >
                      {getIconFromList(zoomCtrl ? "ZOOM_OUT" : "ZOOM_IN", {
                        cursor: "pointer",
                      })}
                    </span>
                  </div>
                )}
                <div
                  className="chart-info-wrapper"
                  style={{
                    height: resizeChartInfoWraperHeight(),
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      display: "block",
                      fontSize: "14px",
                      textAlign: "center",
                      lineHeight: "1.2",
                      paddingTop: "4px",
                    }}
                  >
                    {getDefaultChartName(panel.panel_config)}
                    {/* {panel.panel_config.data
                    ? panel.panel_config.data.chart_name
                      ? panel.panel_config.data.chart_name
                      : "No Name"
                    : "Default Chart"} */}
                  </span>
                  <div
                    className="wh-100 mesh__chart-plot--block"
                    onMouseOver={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(true);
                    }}
                    onMouseLeave={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(false);
                    }}
                    style={{ position: "relative" }}
                  >
                    {panel.panel_config.data ? (
                      getChartByType(panel.panel_config.defaultData.chart_type)
                    ) : (
                      <DefaultChart
                        panel={panel}
                        chart_type={panel.panel_config.defaultData.chart_type}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Rnd>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Charts);
