import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Rnd } from "react-rnd";
import DefaultChart from "../../../CustomPanel_old_not_workable/PanelsBody/SocketChart/DefaultChart";
import SocketChartLine from "../../../_CustomPanel_2/panels/SocketChart/SingleChart_Line";
import SocketChartBar from "../../../_CustomPanel_2/panels/SocketChart/SingleChart_bar";
import SocketChartPie from "../../../_CustomPanel_2/panels/SocketChart/SingleChart_pie";
import SocketChartBarGroup from "../../../_CustomPanel_2/panels/SocketChart/SingleChart_bar_group";
import SocketChartGague from "../../../_CustomPanel_2/panels/SocketChart/SingleChart_gague";

const Charts = (props) => {
  // LINE CHART SCROLBAR IS PRESENT - IF THERE IS NO SCROLL (TOTAL PAGE = 1), SCROLLBAR SPACE SHOULD NOT BE APPEAR
  const [checklineChartScrollbarIsPresent, setCheckLineChartScrollbarIsPresent] = useState(false);

  const resizeChartInfoWraperHeight = () => {
    if (props.panel.panel_config.defaultData.chart_type === "line" && props.panel.data) {
      return props.panel.panel_config.defaultPosition.height - 0 + "px";
    } else if (props.panel.panel_config.defaultData.chart_type === "pie" && props.panel.panel_config.data) {
      return props.panel.panel_config.defaultPosition.height - 20 + "px";
    } else {
      return props.panel.panel_config.defaultPosition.height - 0 - 20 + "px";
    }
  };

  const getChartByType = (type) => {
    switch (type) {
      case "line":
        return (
          props.panel.panel_config.bar_data ?
          <SocketChartBarGroup chart={props.panel.panel_config.data} panel={props.panel} /> :
          <SocketChartLine
            chart={props.panel.panel_config.data}
            panel={props.panel}
            checklineChartScrollbarIsPresent={checklineChartScrollbarIsPresent}
            setCheckLineChartScrollbarIsPresent={
              setCheckLineChartScrollbarIsPresent
            }
          />
        );
      case "bar":
        return (
          props.panel.panel_config.bar_data ? 
          <SocketChartBarGroup chart={props.panel.panel_config.data} panel={props.panel} /> : 
          <SocketChartBar chart={props.panel.panel_config.data} panel={props.panel} />
          )
      case "pie":
        return (
          props.panel.panel_config.bar_data ? 
          <SocketChartBarGroup chart={props.panel.panel_config.data} panel={props.panel} /> : 
          <SocketChartPie chart={props.panel.panel_config.data} panel={props.panel} />
          )
        // return <SocketChartBarGroup chart={props.panel.panel_config.data} panel={props.panel} />
        // <SocketChartPie chart={props.panel.panel_config.data} panel={props.panel} />;
      case "mesh":
        return (
          <SocketChartBarGroup chart={props.panel.panel_config.data} panel={props.panel} />
        );
      case "mapbox_density_heatmap":
        return (
          <SocketChartBarGroup chart={props.panel.panel_config.data} panel={props.panel} />
        );
      case "gague_chart":
        return (
          <SocketChartGague chart={props.panel.panel_config.data} panel={props.panel} />
        );
      default:
        break;
    }
  };

  return (
    <>
      {/* PANEL BODY */}
      <div className="chart-body-section component-chart">
        <Rnd
          className="rnd-section-chart"
          id={"rnd-section-chart-" + props.panel.guid}
          style={props.panel.panel_config.defaultStyle}
          default={props.panel.panel_config.defaultPosition}
          disableDragging={true}
        >
          <div className="chart-container-wrapper rnd__chart__container--wrapper">
            <div className="chart-container">
              <div
                className="chart-info-wrapper"
                style={{
                  height: resizeChartInfoWraperHeight(),
                }}
              >
                <span
                  style={{
                    width: "100%",
                    display: "block",
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "1.2",
                    paddingTop: "4px",
                  }}
                >
                  {props.panel.panel_config.data
                    ? props.panel.panel_config.data.chart_name
                      ? props.panel.panel_config.data.chart_name
                      : ""
                    : ""}
                </span>
                {props.panel.panel_config.data ? (
                  getChartByType(props.panel.panel_config.defaultData.chart_type)
                ) : (
                  <DefaultChart
                    chart_type={props.panel.panel_config.defaultData.chart_type}
                  />
                )}
              </div>
            </div>
          </div>
        </Rnd>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Charts);
