import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactModal from "react-modal";
import Draggable from "react-draggable";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import G_Loader from "../../G_Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const ModalTemplate = ({
  isOpen,
  requestPending,
  children,
  dataSubmited,
  modalID,
  drag,
  modalParentID,
  className,
}) => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#01e3fe");

  const failedToFetchData = useSelector(
    (state) => state.webglAssociates.failedToFetchData
  );
  const dispatch = useDispatch();

  function afterOpenModal() {
    const modalDisabledEle = document.querySelector(
      ".ReactModal__backdrop__disabled"
    );
    modalDisabledEle
      .closest(".ReactModal__Overlay")
      .classList.add("ReactModal__Overlay--backdrop__disabled");

    /* const modalChatroom = document.getElementById("modal__chatroom");
    modalChatroom
      .closest(".ReactModal__Overlay")
      .classList.add("modal__chatroom__overlay");
    const modalConsole = document.getElementById("modal__console");
    modalConsole
      .closest(".ReactModal__Overlay")
      .classList.add("modal__console__overlay"); */
  }

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onAfterOpen={afterOpenModal}
      className={`${className} ReactModal__backdrop__disabled`}
      id={modalParentID}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "transparent",
        },
        content: {
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          border: "none",
          background: "none",
          overflow: "hidden",
          WebkitOverflowScrolling: "touch",
          borderRadius: "0",
          outline: "none",
          padding: "5px",
        },
      }}
    >
      {failedToFetchData && (
        <div id="UnityModalLoader FailedToFetchData">
          <div className="failed-modal-ui">
            <h1 style={{ fontSize: "5rem" }}>❌</h1>
            <br />
            <small style={{ textTransform: "uppercase" }}>
              Failed To Fetch Data
            </small>
            <br />
            <button
              className="btn_close"
              onClick={() => {
                dispatch({
                  type: "FAILED_TO_FETCH_MODAL_DATA",
                  payload: false,
                });
              }}
              type="button"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {requestPending && !dataSubmited && failedToFetchData === false ? (
        <div id="UnityModalLoader">
          <G_Loader />
          {/* <HashLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          /> */}
        </div>
      ) : (
        <Fragment>
          {/* <div id="UnityModalLoader">
            <HashLoader
              color={color}
              loading={loading}
              css={override}
              size={150}
            />
          </div> */}

          <Draggable disabled={drag ? drag : true}>
            <div className={"asset_specs_modal"} id={modalID}>
              {/* <div className="modal-dialog modal-dialog-centered modal-lg"> */}
              {/* <div className="modal-content modal-content_table"> */}
              <div className="ReactModal__dialog modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content modal-content_table">
                  {children}
                </div>
              </div>
            </div>
          </Draggable>
        </Fragment>
      )}
    </ReactModal>
  );
};

export default ModalTemplate;
