import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getDatasetList } from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import request from "../../../utils/request";
import toastr from "toastr";

const PostgreSQLModal = (props) => {
  const dispatch = useDispatch();
  // DB CONNECTIVITY & SELECTION INFO
  const [postgreDBConnectionHostName, setPostgreDBConnectionDataHostName] = useState(null);
  const [postgreDBConnectionPort, setPostgreDBConnectionDataPort] = useState(null);
  const [postgreDBConnectionUsername, setPostgreDBConnectionDataUsername] = useState(null);
  const [postgreDBConnectionPassword, setPostgreDBConnectionDataPassword] = useState(null);

  // AFET CONNECTION
  const [selectedDBList, setSelectedDBList] = useState(null);
  const [selectedDBName, setSelectedDBName] = useState(null);
  const [selectedDBTableList, setSelectedDBTableList] = useState(null);
  const [selectedDBTableName, setSelectedDBTableName] = useState(null);

  // host : demo-database.cykej4iuhezo.us-east-2.rds.amazonaws.com
  // port : 5432
  // user : idare_demo
  // password : d^{g79J)
  const postGreConnectionClickHandler = async () => {
    if (
      postgreDBConnectionHostName &&
      postgreDBConnectionPort &&
      postgreDBConnectionUsername &&
      postgreDBConnectionPassword
    ) {
      try {
        let { data } = await request.post(
          `/api/db-connector/postgresql/get_db_list/`,
          {
            host: postgreDBConnectionHostName,
            port: postgreDBConnectionPort,
            user: postgreDBConnectionUsername,
            password: postgreDBConnectionPassword,
          }
        );
        setSelectedDBList(data);
      } catch (error) {
        // console.log("catch", error);
        setSelectedDBList(null);
      }
    }
  };

  useEffect(async () => {
    if (selectedDBName) {
      try {
        let { data } = await request.post(
          `/api/db-connector/postgresql/get_tables/`,
          {
            host: postgreDBConnectionHostName,
            port: postgreDBConnectionPort,
            user: postgreDBConnectionUsername,
            password: postgreDBConnectionPassword,
            dbname: selectedDBName,
          }
        );
        if (data.result.length > 0) {
          setSelectedDBTableList(data);
        }
      } catch (error) {
        // console.log("catch", error);
        setSelectedDBTableList(null);
      }
    }
  }, [selectedDBName]);

  const applySelectedColumnsHandler = async () => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    try {
      await request
        .post(
          `/api/${props.panelFieldInfo.panel_type}/${props.panelFieldInfo.panel_type_guid}/datasets/`,
          {
            name: props.inputDataName,
            source: "postgresql",
            user: postgreDBConnectionUsername,
            password: postgreDBConnectionPassword,
            host: postgreDBConnectionHostName,
            port: parseInt(postgreDBConnectionPort),
            dbname: selectedDBName,
            table: selectedDBTableName
          }
        )
        .then((res) => {
          if (res.data) {
            toastr.success("Dataset added successfully", "Success");
            dispatch(getDatasetList(props.panelFieldInfo.panel_type, props.panelFieldInfo.panel_type_guid));
            dispatch({
              type: "UPDATE_REQUEST_LOADER_STATE",
              payload: false,
            });
            document
              .getElementById("close-btn-postgresql-connection-modal")
              .click();
            document
              .getElementById("new-dataset-input-modal-close-icon-custom-panel-csv")
              .click();
            props.setInputDataName("");
          }
        });
    } catch (error) {
      // console.log("catch", error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="postgreModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-content-ui modal-content_table" style={{backgroundColor: "#07161f"}}>
            <div className="modal-header">
              <h5 className="" id="exampleModalLabel">
                Connect PostgreSQL Database
              </h5>
              <button
                type="button"
                id="close-btn-postgresql-connection-modal"
                className="btn-close transparent-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedDBList(null);
                  setSelectedDBName(null);
                  setSelectedDBTableList(null);
                  setSelectedDBTableName(null);
                }}
              >
                {getIconFromList("CROSS_ICON")}
              </button>
            </div>
            {/* STEPPER */}
            <React.Fragment>
              <div className="md-stepper-horizontal orange">
                <div className="md-step active done">
                  <div className="md-step-circle">
                    <span>1</span>
                  </div>
                  <div className="md-step-title">
                    Connect PostgreSQL Database
                  </div>
                  <div className="md-step-bar-left"></div>
                  <div className="md-step-bar-right"></div>
                </div>
                <div className="md-step active editable">
                  <div className="md-step-circle">
                    <span>2</span>
                  </div>
                  <div className="md-step-title">Select Database and Table</div>
                  <div className="md-step-bar-left"></div>
                  <div className="md-step-bar-right"></div>
                </div>
                <div className="md-step active">
                  <div className="md-step-circle">
                    <span>3</span>
                  </div>
                  <div className="md-step-title">Select Columns</div>
                  <div className="md-step-bar-left"></div>
                  <div className="md-step-bar-right"></div>
                </div>
              </div>
            </React.Fragment>
            {/* DB CONNECTION */}
            <React.Fragment>
              {!selectedDBList ? (
                <React.Fragment>
                  <div className="modal-body postgre-form">
                    <div className="mb-3">
                      <label htmlFor="hostName" className="form-label">
                        Host Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postgre-connection-hostName"
                        autoComplete="off"
                        placeholder="Host Name"
                        onKeyUp={(e) => {
                          setPostgreDBConnectionDataHostName(e.target.value);
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          e.target.value = e.clipboardData.getData("text");
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="port" className="form-label">
                        Port
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postgre-connection-port"
                        autoComplete="off"
                        placeholder="Port"
                        onKeyUp={(e) => {
                          setPostgreDBConnectionDataPort(e.target.value);
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          e.target.value = e.clipboardData.getData("text");
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="dbuserName" className="form-label">
                        Username
                      </label>
                      <textarea
                        className="form-control rounded-0 mb-2 form-control-dataname"
                        type="text"
                        // className="form-control"
                        id="postgre-connection-dbuserName"
                        autoComplete="off"
                        placeholder="Username"
                        onKeyUp={(e) => {
                          setPostgreDBConnectionDataUsername(e.target.value);
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          e.target.value = e.clipboardData.getData("text");
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="dbuserPass" className="form-label">
                        Password
                      </label>
                      <textarea
                        className="form-control rounded-0 mb-2 form-control-dataname"
                        type="password"
                        // className="form-control"
                        id="postgre-connection-dbuserPass"
                        autoComplete="off"
                        placeholder="Password"
                        onKeyUp={(e) => {
                          setPostgreDBConnectionDataPassword(e.target.value);
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          e.target.value = e.clipboardData.getData("text");
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="add-data-source btn btn-secondary rounded-0"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        postGreConnectionClickHandler();
                      }}
                    >
                      Next
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* {!selectedDBTableColumnList ? ( */}
                    <React.Fragment>
                      {selectedDBList ? (
                        <React.Fragment>
                          {/*DB SELECTION FROM DB LIST */}
                          <div className="modal-body postgre-form">
                            <div className="mb-3">
                              <div className="dropdown d-flex align-items-center">
                                <label className="w-25">Select Database</label>
                                <div className="form__group w-75">
                                  <select
                                    className="custom-select"
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setSelectedDBName(JSON.parse(e.target.value))
                                    }}
                                  >
                                    <option value={null} selected disabled>
                                      Select database
                                    </option>
                                      {selectedDBList ? selectedDBList.result.map((db, index) => (
                                        <option value={JSON.stringify(db)} key={index}>
                                          {db}
                                        </option>
                                      )) : <></>}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*DB TABLE SELECTION FROM DB TABLE LIST */}
                          {selectedDBTableList ? (
                            <React.Fragment>
                              <div className="modal-body postgre-form">
                                <div className="mb-3">
                                  <div className="dropdown d-flex">
                                    <label className="w-25">Select Table</label>
                                    <div className="form__group w-75">
                                      <select
                                        className="custom-select"
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          setSelectedDBTableName(JSON.parse(e.target.value))
                                        }}
                                      >
                                        <option value={null} selected disabled>
                                          Select table
                                        </option>
                                          {selectedDBTableList ? selectedDBTableList.result.map((table, index) => (
                                            <option value={JSON.stringify(table)} key={index}>
                                              {table}
                                            </option>
                                          )) : <></>}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </React.Fragment>

                  <div className="modal-footer">
                    {selectedDBTableName ? (
                      <button
                        type="button"
                        className="add-data-source btn btn-secondary rounded-0"
                        onClick={(e) => {
                          e.preventDefault();
                          applySelectedColumnsHandler();
                        }}
                      >
                        Apply
                      </button>
                    ) : (
                      <button
                      type="button"
                      className="add-data-source btn btn-secondary rounded-0"
                      onClick={(e) => {
                        e.preventDefault();
                          setSelectedDBList(null);
                          setSelectedDBName(null);
                          setSelectedDBTableList(null);
                          setSelectedDBTableName(null);
                      }}
                    >
                      Back
                    </button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PostgreSQLModal);
