import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchAssetSpecs,
  handleBasicDataSelection,
  handleSpecsDataChange,
} from "../../../../actions/webglAssociatesActions";
import loader from "../../../../static/loader.gif";
import request from "../../../../utils/request";
import toastr from "toastr";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

import ModalTemplate from "../../ModalTemplate";

const AssetSpecsEditor = ({
  fetchAssetSpecs,
  requestPending,
  assetSpecs,
  handleBasicDataSelection,
  handleSpecsDataChange,
}) => {
  const dispatch = useDispatch();

  const types = [
    {
      label: "Integer",
      value: "integerNumber",
      type: "number",
      pattern: "[0-9]",
      oninput: true,
    },
    {
      label: "Text",
      value: "text",
      type: "text",
    },
    {
      label: "Decimal",
      value: "decimalNumber",
      type: "number",
      pattern: "^d+(?:.d{1,2})?$",
      min: "0",
      step: "0.01",
    },
  ];

  const [isOpen, setOpen] = useState(false);
  const [projectGuid, setProjectGuid] = useState(null);
  const [assetGuid, setAssetGuid] = useState(null);
  const [dataSubmited, setDataSubmited] = useState(false);
  const failedToFetchData = useSelector(
    (state) => state.webglAssociates.failedToFetchData
  );

  window.ASEditor = (projectGuid, assetGuid) => {
    setOpen(!isOpen);
    setProjectGuid(projectGuid);
    setAssetGuid(assetGuid);
    fetchAssetSpecs(projectGuid, assetGuid);
  };

  function afterOpenModal() {
    const dataModEle = document.querySelector('.data__configaration--modal-content');
    dataModEle.closest('.modal-content_table').classList.add('ReactModal__data_configaration');
  }


  return (
    <>
      <ModalTemplate
        requestPending={requestPending}
        setOpen={setOpen}
        onAfterOpen={afterOpenModal}
        modalID="ASEditor"
        dataSubmited={dataSubmited}
        isOpen={isOpen}
      >
        <div>
          {assetSpecs && assetSpecs.basic_data && !failedToFetchData && (
            <div className="modal-content modal-content_table data__configaration--modal-content">
              <div className="modal-header justify-content-center p-2 border-0">
                <h5 className="specifications-header-title" id="exampleModalLabel">
                  {assetSpecs && assetSpecs.basic_data
                    ? assetSpecs.basic_data[0].value
                    : "Asset Specs"}
                </h5>
                <button
                  type="button"
                  className="transparent-btn btn-close"
                  title="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                ></button>
              </div>
              <div className="text-center py-3">
                <button
                  type="button"
                  className="btn btn-scifi modal-ok-btn"
                  onClick={() =>
                    dispatch({
                      type: "ADD_ASSET_SPECT_NEW_PARAM",
                    })
                  }
                >
                  <i className="btn-helper"></i>
                  <span className="btn-text">+ Add Parameter</span>
                </button>
              </div>
              <div className="modal-body modal__parent_2nd_child_boday">
                <div className="table-responsive">
                  <table className="table table-dark align-middle table-borderless bg-transparent table-align-middle">
                    {assetSpecs && assetSpecs.basic_data
                      ? assetSpecs.basic_data.map((basic_single_data, idx) => (
                        <tr key={idx}>
                          <td className="as-data-label">
                            <label>{basic_single_data.label}</label>
                          </td>
                          <td className="bg-transparent">
                            <div className="d-block">
                              <input
                                className="form-control rounded-0 as-data-input"
                                type={basic_single_data.value_type}
                                value={basic_single_data.value}
                                disabled
                              />
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <input
                              className="form-check-input as-data-checkbox"
                              type="checkbox"
                              checked={basic_single_data.showing}
                              id="flexCheckChecked"
                              onChange={(e) => {
                                // // console.log(e.target.value);
                                handleBasicDataSelection(
                                  basic_single_data.label,
                                  "showing",
                                  !basic_single_data.showing,
                                  idx
                                );
                              }}
                            />
                          </td>
                        </tr>
                      ))
                      : null}
                  </table>
                  <table className="table table-dark align-middle table-borderless bg-transparent table-align-middle">
                    <tbody>
                      {assetSpecs && assetSpecs.specifications
                        ? assetSpecs.specifications.map((specs_single_data, idx) => (
                          <tr key={idx}>
                            <td className="bg-transparent">
                              <input
                                className="form-control"
                                type="text"
                                value={specs_single_data.label}
                                placeholder="Label"
                                onChange={(e) =>
                                  handleSpecsDataChange(
                                    specs_single_data.label,
                                    "label",
                                    e.target.value,
                                    idx
                                  )
                                }
                              />
                            </td>
                            <td className="bg-transparent">
                              <div className="input-group flex-nowrap input-group-join">
                                {types.map((type, index) =>
                                  type.value === specs_single_data.value_type ? (
                                    <input
                                      key={index}
                                      className="form-control"
                                      type={type.type}
                                      min={type.min}
                                      step={type.step}
                                      pattern={type.pattern}
                                      value={
                                        !!specs_single_data.value &&
                                          type.value === "integerNumber"
                                          ? Math.round(specs_single_data.value)
                                          : specs_single_data.value
                                      }
                                      placeholder="Value"
                                      onInput={(e) =>
                                        type.oninput
                                          ? (e.target.value = parseInt(
                                            e.target.value
                                          ))
                                          : e.target.value
                                      }
                                      onChange={(e) =>
                                        handleSpecsDataChange(
                                          specs_single_data.value,
                                          "value",
                                          e.target.value,
                                          idx
                                        )
                                      }
                                    />
                                  ) : null
                                )}

                                <select
                                  className="form-select rounded-0"
                                  aria-label="Default select example"
                                  defaultValue={specs_single_data.value_type}
                                  onChange={(e) => {
                                    handleSpecsDataChange(
                                      specs_single_data.value_type,
                                      "value_type",
                                      e.target.value,
                                      idx
                                    );
                                  }}
                                >
                                  {types.map((type) => (
                                    <option
                                      key={type.value}
                                      selected={
                                        type.value === specs_single_data.value_type
                                      }
                                      value={type.value}
                                    >
                                      {type.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                            <td className="bg-transparent">
                              <input
                                className="form-control form-control-unit"
                                type="text"
                                aria-label="default input example"
                                value={specs_single_data.unit}
                                placeholder="Unit"
                                onChange={(e) => {
                                  handleSpecsDataChange(
                                    specs_single_data.unit,
                                    "unit",
                                    e.target.value,
                                    idx
                                  );
                                }}
                              />
                            </td>
                            <td className="bg-transparent">
                              <input
                                className="form-check-input as-data-checkbox"
                                type="checkbox"
                                checked={specs_single_data.showing}
                                id="flexCheckChecked"
                                onChange={(e) => {
                                  handleSpecsDataChange(
                                    specs_single_data.label,
                                    "showing",
                                    !specs_single_data.showing,
                                    idx
                                  );
                                }}
                              />
                            </td>
                            <td className="bg-transparent align-middle">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-trash"
                                viewBox="0 0 16 16"
                                onClick={() => {
                                  dispatch({
                                    type: "REMOVE_ASSET_SPECS",
                                    payload: specs_single_data.guid,
                                  });
                                }}
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </div>
                {/* <table className="table table-dark align-middle table-borderless bg-transparent">
                  <tbody>
                    {assetSpecs && assetSpecs.basic_data
                      ? assetSpecs.basic_data.map((basic_single_data, idx) => (
                        <tr key={idx}>
                          <td className="as-data-label">
                            <label>{basic_single_data.label}</label>
                          </td>
                          <td className="bg-transparent">
                            <div className="d-flex">
                              <input
                                className="form-control rounded-0 as-data-input"
                                type={basic_single_data.value_type}
                                value={basic_single_data.value}
                                disabled
                              />
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <input
                              className="form-check-input as-data-checkbox"
                              type="checkbox"
                              checked={basic_single_data.showing}
                              id="flexCheckChecked"
                              onChange={(e) => {
                                // console.log(e.target.value);
                                handleBasicDataSelection(
                                  basic_single_data.label,
                                  "showing",
                                  !basic_single_data.showing,
                                  idx
                                );
                              }}
                            />
                          </td>
                        </tr>
                      ))
                      : null}

                    <br />
                    {assetSpecs && assetSpecs.specifications
                      ? assetSpecs.specifications.map((specs_single_data, idx) => (
                        <tr key={idx}>
                          <td className="bg-transparent">
                            <input
                              className="form-control rounded-0"
                              type="text"
                              value={specs_single_data.label}
                              placeholder="Label"
                              onChange={(e) =>
                                handleSpecsDataChange(
                                  specs_single_data.label,
                                  "label",
                                  e.target.value,
                                  idx
                                )
                              }
                            />
                          </td>
                          <td className="bg-transparent">
                            <div className="d-flex">
                              {types.map((type) =>
                                type.value === specs_single_data.value_type ? (
                                  <input
                                    className="form-control rounded-0"
                                    type={type.type}
                                    min={type.min}
                                    step={type.step}
                                    pattern={type.pattern}
                                    value={
                                      !!specs_single_data.value &&
                                        type.value === "integerNumber"
                                        ? Math.round(specs_single_data.value)
                                        : specs_single_data.value
                                    }
                                    placeholder="Value"
                                    onInput={(e) =>
                                      type.oninput
                                        ? (e.target.value = parseInt(
                                          e.target.value
                                        ))
                                        : e.target.value
                                    }
                                    onChange={(e) =>
                                      handleSpecsDataChange(
                                        specs_single_data.value,
                                        "value",
                                        e.target.value,
                                        idx
                                      )
                                    }
                                  />
                                ) : null
                              )}

                              <select
                                className="form-select rounded-0"
                                aria-label="Default select example"
                                defaultValue={specs_single_data.value_type}
                                onChange={(e) => {
                                  handleSpecsDataChange(
                                    specs_single_data.value_type,
                                    "value_type",
                                    e.target.value,
                                    idx
                                  );
                                }}
                              >
                                {types.map((type) => (
                                  <option
                                    key={type.value}
                                    selected={
                                      type.value === specs_single_data.value_type
                                    }
                                    value={type.value}
                                  >
                                    {type.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <input
                              className="form-control me-2  rounded-0"
                              type="text"
                              aria-label="default input example"
                              value={specs_single_data.unit}
                              placeholder="Unit"
                              onChange={(e) => {
                                handleSpecsDataChange(
                                  specs_single_data.unit,
                                  "unit",
                                  e.target.value,
                                  idx
                                );
                              }}
                            />
                          </td>
                          <td className="bg-transparent">
                            <input
                              className="form-check-input as-data-checkbox"
                              type="checkbox"
                              checked={specs_single_data.showing}
                              id="flexCheckChecked"
                              onChange={(e) => {
                                handleSpecsDataChange(
                                  specs_single_data.label,
                                  "showing",
                                  !specs_single_data.showing,
                                  idx
                                );
                              }}
                            />
                          </td>
                          <td className="bg-transparent align-middle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                              onClick={() => {
                                dispatch({
                                  type: "REMOVE_ASSET_SPECS",
                                  payload: specs_single_data.guid,
                                });
                              }}
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                              <path
                                fillRule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                              />
                            </svg>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table> */}
              </div>
              <div className="modal-footer border-0 justify-content-end">
                <button
                  type="button"
                  className="btn btn-scifi modal-ok-btn mr-3"
                  onClick={async () => {
                    // // console.log(assetSpecs);
                    setDataSubmited(true);

                    await request
                      .post(
                        `/api/projects/${projectGuid}/assets/${assetGuid}/specifications/create_or_update/`,
                        {
                          ...assetSpecs,
                          specifications: assetSpecs.specifications.map((specs) => {
                            if (!specs.guid) {
                              return { ...specs, guid: uuidv4() };
                            }

                            return specs;
                          }),
                        }
                      )
                      .then((resp) => {
                        setDataSubmited(false);
                        toastr.success("Asset Specifications", "Updated");
                        setOpen(false);
                      })
                      .catch((err) => {
                        setDataSubmited(false);
                        toastr.error("Asset Specifications", "Failed To Update");
                        setOpen(false);
                      });
                  }}
                >
                  <i className="btn-helper"></i>
                  {dataSubmited && requestPending ? (
                    <span className="btn-text">
                      <img src={loader} height="20px" alt="" width="20px" />
                    </span>
                  ) : (
                    <span className="btn-text">
                      Submit
                    </span>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </ModalTemplate>
      {/* <ModalTemplate
      requestPending={requestPending}
      setOpen={setOpen}
      modalID="ASEditor"
      dataSubmited={dataSubmited}
      isOpen={isOpen}
    >
      {assetSpecs && assetSpecs.basic_data && !failedToFetchData && (
        <div className="modal-content modal-content_table">
          <div className="modal-header justify-content-center p-2 border-0">
            <h5 className="specifications-header-title" id="exampleModalLabel">
              {assetSpecs && assetSpecs.basic_data
                ? assetSpecs.basic_data[0].value
                : "Asset Specs"}
            </h5>
            <button
              type="button"
              className="transparent-btn btn-close"
              title="Close"
              onClick={() => {
                setOpen(false);
              }}
            ></button>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn modal-ok-btn"
              onClick={() =>
                dispatch({
                  type: "ADD_ASSET_SPECT_NEW_PARAM",
                })
              }
            >
              + Add Parameter
            </button>
          </div>
          <div className="modal-body modal__parent_2nd_child_boday">
            <table className="table table-dark align-middle table-borderless bg-transparent">
              <tbody>
                {assetSpecs && assetSpecs.basic_data
                  ? assetSpecs.basic_data.map((basic_single_data, idx) => (
                      <tr key={idx}>
                        <td className="as-data-label">
                          <label>{basic_single_data.label}</label>
                        </td>
                        <td className="bg-transparent">
                          <div className="d-flex">
                            <input
                              className="form-control rounded-0 as-data-input"
                              type={basic_single_data.value_type}
                              value={basic_single_data.value}
                              disabled
                            />
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <input
                            className="form-check-input as-data-checkbox"
                            type="checkbox"
                            checked={basic_single_data.showing}
                            id="flexCheckChecked"
                            onChange={(e) => {
                              // console.log(e.target.value);
                              handleBasicDataSelection(
                                basic_single_data.label,
                                "showing",
                                !basic_single_data.showing,
                                idx
                              );
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  : null}

                <br />
                {assetSpecs && assetSpecs.specifications
                  ? assetSpecs.specifications.map((specs_single_data, idx) => (
                      <tr key={idx}>
                        <td className="bg-transparent">
                          <input
                            className="form-control rounded-0"
                            type="text"
                            value={specs_single_data.label}
                            placeholder="Label"
                            onChange={(e) =>
                              handleSpecsDataChange(
                                specs_single_data.label,
                                "label",
                                e.target.value,
                                idx
                              )
                            }
                          />
                        </td>
                        <td className="bg-transparent">
                          <div className="d-flex">
                            {types.map((type) =>
                              type.value === specs_single_data.value_type ? (
                                <input
                                  className="form-control rounded-0"
                                  type={type.type}
                                  min={type.min}
                                  step={type.step}
                                  pattern={type.pattern}
                                  value={
                                    !!specs_single_data.value &&
                                    type.value === "integerNumber"
                                      ? Math.round(specs_single_data.value)
                                      : specs_single_data.value
                                  }
                                  placeholder="Value"
                                  onInput={(e) =>
                                    type.oninput
                                      ? (e.target.value = parseInt(
                                          e.target.value
                                        ))
                                      : e.target.value
                                  }
                                  onChange={(e) =>
                                    handleSpecsDataChange(
                                      specs_single_data.value,
                                      "value",
                                      e.target.value,
                                      idx
                                    )
                                  }
                                />
                              ) : null
                            )}

                            <select
                              className="form-select rounded-0"
                              aria-label="Default select example"
                              defaultValue={specs_single_data.value_type}
                              onChange={(e) => {
                                handleSpecsDataChange(
                                  specs_single_data.value_type,
                                  "value_type",
                                  e.target.value,
                                  idx
                                );
                              }}
                            >
                              {types.map((type) => (
                                <option
                                  key={type.value}
                                  selected={
                                    type.value === specs_single_data.value_type
                                  }
                                  value={type.value}
                                >
                                  {type.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <input
                            className="form-control me-2  rounded-0"
                            type="text"
                            aria-label="default input example"
                            value={specs_single_data.unit}
                            placeholder="Unit"
                            onChange={(e) => {
                              handleSpecsDataChange(
                                specs_single_data.unit,
                                "unit",
                                e.target.value,
                                idx
                              );
                            }}
                          />
                        </td>
                        <td className="bg-transparent">
                          <input
                            className="form-check-input as-data-checkbox"
                            type="checkbox"
                            checked={specs_single_data.showing}
                            id="flexCheckChecked"
                            onChange={(e) => {
                              handleSpecsDataChange(
                                specs_single_data.label,
                                "showing",
                                !specs_single_data.showing,
                                idx
                              );
                            }}
                          />
                        </td>
                        <td className="bg-transparent align-middle">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                            onClick={() => {
                              dispatch({
                                type: "REMOVE_ASSET_SPECS",
                                payload: specs_single_data.guid,
                              });
                            }}
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fillRule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <div className="modal-footer border-0 justify-content-end">
            <button
              type="button"
              className="btn modal-ok-btn"
              onClick={async () => {
                // console.log(assetSpecs);
                setDataSubmited(true);

                await request
                  .post(
                    `/api/projects/${projectGuid}/assets/${assetGuid}/specifications/create_or_update/`,
                    {
                      ...assetSpecs,
                      specifications: assetSpecs.specifications.map((specs) => {
                        if (!specs.guid) {
                          return { ...specs, guid: uuidv4() };
                        }

                        return specs;
                      }),
                    }
                  )
                  .then((resp) => {
                    setDataSubmited(false);
                    toastr.success("Asset Specifications", "Updated");
                    setOpen(false);
                  })
                  .catch((err) => {
                    setDataSubmited(false);
                    toastr.error("Asset Specifications", "Failed To Update");
                    setOpen(false);
                  });
              }}
            >
              {dataSubmited && requestPending ? (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img src={loader} height="20px" alt="" width="20px" />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      )}
    </ModalTemplate> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  requestPending: state.ui.requestPending,
  assetSpecs: state.webglAssociates.modals.assetSpecs,
});

export default connect(mapStateToProps, {
  fetchAssetSpecs,
  handleBasicDataSelection,
  handleSpecsDataChange,
})(AssetSpecsEditor);
