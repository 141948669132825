import React, { useEffect, useState } from "react";
import request from "../../../utils/request";
import toast from "toastr";

import "./styles.css";
import { getIconFromList } from "../../../static/IconServe";
import { useDispatch } from "react-redux";
import {
  getSubscriptions,
  postSubscribePackage,
  postCreditPurchase,
  postAdditionalPurchase
} from "../../../actions/billingAction";

const Pricing = (props) => {
  const dispatch = useDispatch();
  const [storage, setStorage] = useState(20);
  const [nofAssets, setNofAsset] = useState(20);
  const [nofPipeline, setNofPipeline] = useState(20);
  const [nofField, setNofField] = useState(5);
  const [nofFieldSharing, setNofFieldSharing] = useState(20);
  const [packagesList, setPackagesList] = useState(null);
  // PLAN SUBSCRIBE MODAL
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [cardNumberOnChange, setCardNumberOnChange] = useState(null);
  // CREDIT PURCHASE MODAL
  const [creditPurchaseDataCardNo, setCreditPurchaseDataCardNo] = useState("123456789");
  const [numberOfAsset, setNumberOfAsset] = useState(0);
  const [storageSize, setStorageSize] = useState(0);

  useEffect(async () => {
    try {
      await request.get(`/api/billing/packages/`).then((res) => {
        setPackagesList(res.data.packages);
      });
    } catch (error) {
      // console.log("catch", error);
    }
  }, []);

  return (
    <>
      <h4>Subscription Plan</h4>
      <div className="packages_wrapper">
        {packagesList ? (
          <>
            {packagesList.map((pack) => (
              <>
                <div className="single_package">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3 className="tab-content-title">{pack.name}</h3>
                  </div>
                  <h6>
                    <span style={{ display: "inline-block", width: "100px" }}>
                      Price
                    </span>
                    : ${pack.subscription_fee} / Month
                  </h6>
                  <h6>
                    <span style={{ display: "inline-block", width: "100px" }}>
                      Storage Limit
                    </span>
                    : {pack.storage_limit}
                  </h6>
                  <h6>
                    <span style={{ display: "inline-block", width: "100px" }}>
                      Asset Limit
                    </span>
                    : {pack.asset_limit}
                  </h6>
                  <h6>
                    <span style={{ display: "inline-block", width: "100px" }}>
                      Description
                    </span>
                    : {pack.description}
                  </h6>
                  {pack.special_features ? (
                    <h6>
                      <span style={{ display: "inline-block", width: "100px" }}>
                        Special Feature
                      </span>
                      : {pack.special_features}
                    </h6>
                  ) : (
                    <></>
                  )}
                  <button
                    className="btn btn-danger"
                    style={{ padding: "0px 10px", marginTop: "10px" }}
                    onClick={() => {
                      setSelectedPackage(pack);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#payment-subscription-modal"
                    disabled={pack.is_active}
                  >
                    Subscribe
                  </button>
                </div>
              </>
            ))}
            {/* ADDITIONAL PURCHASE */}
            <div className="single_package">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 className="tab-content-title">Additional Purchase</h3>
              </div>

              <h6>1 Asset = 1 Credit</h6>
              <h6>10 MB Data Storage = 1 Credit</h6>
              <h6>Credit Price = $1/Month</h6>
              <br />
              <br />
              <button
                className="btn btn-danger"
                style={{ padding: "0px 10px", marginTop: "10px" }}
                // onClick={() => {
                //   setSelectedPackage(pack);
                // }}
                data-bs-toggle="modal"
                data-bs-target="#additional-purchase-modal"
              >
                Purchase
              </button>
            </div>
          </>
        ) : (
          <div className="single_package">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 className="tab-content-title">No package is present</h3>{" "}
            </div>
          </div>
        )}
      </div>

      {/* SUBSCRIPTION PAYMENT MODAL */}
      <>
        <div
          className="modal fade"
          id="payment-subscription-modal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                width: "calc(60vw)",
                maxWidth: "450px",
                background: "#08202E",
              }}
            >
              {/* HEADER */}
              <div
                className="modal-header"
                style={{ padding: "0.35rem 1.5rem" }}
              >
                <h5 className="modal-title" style={{ color: "#FFFFFF" }}>
                  Plan Activation
                </h5>
                <span
                  className="panel-remove-icon"
                  style={{
                    position: "absolute",
                    top: "10px",
                    zIndex: "1",
                    right: "10px",
                    width: "30px",
                    height: "30px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {}}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
                </span>
              </div>
              {/* BODY */}
              <div style={{ overflowY: "auto" }}>
                <div
                  className="modal-body"
                  style={{ padding: "0.35rem 1.5rem" }}
                >
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      // // console.log(selectedDuration);
                      let pData = {
                        selected_package: selectedPackage.id,
                        duration: 30 * selectedDuration.split(" ")[0],
                        card_no: cardNumberOnChange,
                      };
                      // // console.log(pData);
                      dispatch(postSubscribePackage(pData)).then((res) => {
                        props.loadSubscriptionList();
                      });
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                      <label className="form-label m-0">Name</label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control w-200"
                          value={selectedPackage ? selectedPackage.name : ""}
                          placeholder="Plan Name"
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                      <label className="form-label m-0">Duration</label>
                      <div className="">
                        <select
                          className="form-control w-200"
                          onChange={(e) => {
                            setSelectedDuration(e.target.value);
                          }}
                        >
                          <option selected value={null}>
                            Select an option
                          </option>
                          <option value="1 Month">1 Month</option>
                          <option value="2 Month">2 Month</option>
                          <option value="3 Month">3 Month</option>
                        </select>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                      <label className="form-label m-0">Card Number</label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control w-200"
                          value={cardNumberOnChange ? cardNumberOnChange : ""}
                          placeholder="XXXX XXXX XXXX XXXX"
                          onChange={(e) => {
                            e.stopPropagation();
                            setCardNumberOnChange(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-end aligh-items-end">
                      <button
                        className="ms-4 btn mx-1 data-source_btn"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        disabled={
                          !selectedPackage ||
                          !selectedDuration ||
                          !cardNumberOnChange
                        }
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* CREDIT PURCHASE MODAL */}
      <>
        <div
          className="modal fade"
          id="additional-purchase-modal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                width: "calc(60vw)",
                maxWidth: "850px",
                background: "#08202E",
              }}
            >
              {/* HEADER */}
              <div
                className="modal-header"
                style={{ padding: "0.35rem 1.5rem" }}
              >
                <h5 className="modal-title" style={{ color: "#FFFFFF" }}>
                  Additional Purchase
                </h5>
                <span
                  className="panel-remove-icon"
                  style={{
                    position: "absolute",
                    top: "10px",
                    zIndex: "1",
                    right: "10px",
                    width: "30px",
                    height: "30px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {}}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
                </span>
              </div>
              {/* BODY */}
              <div style={{ overflowY: "auto" }}>
                <div
                  className="modal-body"
                  style={{ padding: "0.35rem 1.5rem" }}
                >
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      let pData = {
                        card_no: creditPurchaseDataCardNo,
                        credit_amount: (parseInt(numberOfAsset) + parseInt(storageSize) / 10),
                      };
                      dispatch(postCreditPurchase(pData)).then((res) => {
                        let aData = {
                          asset_count: parseInt(numberOfAsset),
                          storage_capacity: parseInt(storageSize),
                          duration: 1000,
                        };
                        dispatch(postAdditionalPurchase(aData));
                      });
                      document.querySelector("#additional-purchase-modal .panel-remove-icon").click();
                    }}
                  >
                    <div className="d-flex align-items-center w-100 mb-1">
                      <h6 className="form-label m-0">1 Asset = 1 Credit</h6>
                    </div>
                    <div className="d-flex align-items-center w-100 mb-1">
                      <h6>10 MB Data Storage = 1 Credit</h6>
                    </div>
                    <div className="d-flex align-items-center w-100 mb-1">
                      <h6>Credit Price = $1/Month</h6>
                    </div>
                    <table>
                      <tr>
                        <td className="border-0">
                          <div className="mb-1 text-center">
                            <label className="form-label m-0">
                              Number of Assets
                            </label>
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="mb-1 text-center">
                            <label className="form-label m-0">
                              Storage Size
                            </label>
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="mb-1 text-center">
                            <label className="form-label m-0">
                              Total Credit
                            </label>
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="mb-1 text-center">
                            <label className="form-label m-0">
                              Total Price / Month
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          <div className="mb-1 text-center">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="10"
                              onChange={(e) => {
                                e.stopPropagation();
                                setNumberOfAsset(e.target.value);
                              }}
                            />
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="mb-1 text-center">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="10MB"
                              onChange={(e) => {
                                e.stopPropagation();
                                setStorageSize(e.target.value);
                              }}
                            />
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="mb-1 text-center">
                            <h6 className="m-0">
                              {"Asset Credit "}: {numberOfAsset}
                            </h6>
                            <h6 className="m-0">
                              {"Storage Credit"}: {storageSize / 10}
                            </h6>
                            <h6 className="m-0">
                              {"Total Credit "}:{" "}
                              {parseInt(numberOfAsset) +
                                parseInt(storageSize) / 10}
                            </h6>
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="mb-1 text-center">
                            <h6 className="m-0">{"Subscription Fee"}: $10</h6>
                            <h6 className="m-0">
                              {"Total Credit "}:{" "}
                              {parseInt(numberOfAsset) +
                                parseInt(storageSize) / 10}
                              /Month
                            </h6>
                            <h6 className="m-0">
                              {"Total Price "}:{" "}
                              {(parseInt(numberOfAsset) +
                                parseInt(storageSize) / 10) *
                                1}
                              /Month
                            </h6>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div className="d-flex mt-3 mb-3 justify-content-end aligh-items-end">
                      <button
                        className="ms-4 btn mx-1 data-source_btn"
                        type="submit"
                        // data-bs-dismiss="modal"
                        // aria-label="Close"
                        disabled={!numberOfAsset || !storageSize}
                      >
                        Checkout
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Pricing;
