import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getIconFromList } from "../../../../static/IconServe";
import BigDataTable from "./BigDataTable/BigDataTable";

const DataTableWebGL = (props) => {
  const dispatch = useDispatch();
  const [tableInfo, setTableInfo] = useState(null);
  window.openWebglDataTableModal = (data) => {
    let dt = JSON.parse(data);
    setTableInfo({
      panel_type: dt.panel_type,
      panel_type_guid: dt.panel_type_guid,
      project_guid: dt.project_guid,
      dataset_guid: dt.dataset_guid,
      dataset_name: dt.dataset_name,
    });
    document.getElementById("data-table-modal-webgl-opener").click();
  };

  return (
    <>
      <button
        id="data-table-modal-webgl-opener"
        data-bs-toggle="modal"
        data-bs-target="#data-table-modal-webgl"
        style={{ display: "none" }}
      ></button>
      <div
        className="modal fade"
        id="data-table-modal-webgl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          style={{ maxWidth: "80vw" }}
        >
          <div className="modal-content">
            {/* HEADER */}
            <div className="modal-header">
              <h5 className="modal-title">
                {tableInfo ? tableInfo.dataset_name : "Data Table"}
              </h5>
              <span
                className="panel-remove-icon"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
              </span>
            </div>

            <div className="modal-body">
              {tableInfo ? <BigDataTable tableInfo={tableInfo} /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DataTableWebGL;
