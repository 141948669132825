import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AppLeft from "./Components/AppLeft";
import Unifier from "./Components/Unifier";
import AddNewDataSet from "./Modals/AddNewDataSet";
import ConfigureChart from "./Modals/ConfigureChart";
import { fetchExistingCharts } from "../../../actions/panelActions";
import "./style.css";

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const [isDatasetOpen, setIsDatasetOpen] = useState(false);
  const [isConfigureChartOpen, setIsConfigureChartOpen] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const projectguid = queryParams.get("projectguid");
  const panelType = queryParams.get("paneltype");

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchExistingCharts(panelType, projectguid));
    }, 2000);
  }, [dispatch, fetchExistingCharts, projectguid, panelType]);

  return (
    <div>
      {/* MODALS */}
      <AddNewDataSet
        isOpen={isDatasetOpen}
        setIsOpen={setIsDatasetOpen}
        guid={projectguid}
        panelType={panelType}
      />
      <ConfigureChart
        isOpen={isConfigureChartOpen}
        setIsOpen={setIsConfigureChartOpen}
        guid={projectguid}
        panelType={panelType}
      />
      <div className="app-container">
        <AppLeft />
        <div className="app-main">
          <div className="panel__header">
            <h3 style={{ textAlign: "center" }}>Field Name: KIZOMBA</h3>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ul className="top_buttons dataset-buttons">
                <li>
                  <button type="button" className="btn btn-scifi">
                    <i className="btn-helper"></i>
                    <span className="btn-text">Back To 3D</span>
                  </button>
                </li>
                <li onClick={() => setIsDatasetOpen(!isDatasetOpen)}>
                  <button type="button" className="btn btn-scifi">
                    <i className="btn-helper"></i>
                    <span className="btn-text">New Datasheet</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    setIsConfigureChartOpen(!isConfigureChartOpen);
                  }}
                >
                  <button type="button" className="btn btn-scifi">
                    <i className="btn-helper"></i>
                    <span className="btn-text">Add Chart</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="main-header-line">
            <Unifier />
            <div className="action-buttons">
              <button className="open-right-area">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-activity"
                >
                  <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
                </svg>
              </button>
              <button className="menu-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-menu"
                >
                  <line x1={3} y1={12} x2={21} y2={12} />
                  <line x1={3} y1={6} x2={21} y2={6} />
                  <line x1={3} y1={18} x2={21} y2={18} />
                </svg>
              </button>
            </div>
          </div>

          <div className="chart-row">
            <div className="chart-container-wrapper big">
              <div className="chart-container">
                <div className="chart-container-header">
                  <h2>Active Charts</h2>
                </div>
                {/* <ExistingCharts panelType={panelType} panelGUID={projectguid} /> */}
              </div>
            </div>
          </div>

          {/* forget the code below */}

          <div className="chart-row three">
            <div className="chart-container-wrapper">
              <div className="chart-container">
                <div className="chart-info-wrapper">
                  <h2>Applications</h2>
                  <span>20.5 K</span>
                </div>
                <div className="chart-svg">
                  <svg viewBox="0 0 36 36" className="circular-chart pink">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray="30, 100"
                      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x={18} y="20.35" className="percentage">
                      30%
                    </text>
                  </svg>
                </div>
              </div>
            </div>
            <div className="chart-container-wrapper">
              <div className="chart-container">
                <div className="chart-info-wrapper">
                  <h2>Shortlisted</h2>
                  <span>5.5 K</span>
                </div>
                <div className="chart-svg">
                  <svg viewBox="0 0 36 36" className="circular-chart blue">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray="60, 100"
                      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x={18} y="20.35" className="percentage">
                      60%
                    </text>
                  </svg>
                </div>
              </div>
            </div>
            <div className="chart-container-wrapper">
              <div className="chart-container">
                <div className="chart-info-wrapper">
                  <h2>On-hold</h2>
                  <span>10.5 K</span>
                </div>
                <div className="chart-svg">
                  <svg viewBox="0 0 36 36" className="circular-chart orange">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray="90, 100"
                      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x={18} y="20.35" className="percentage">
                      90%
                    </text>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="chart-row two">
            <div className="chart-container-wrapper big">
              <div className="chart-container">
                <div className="chart-container-header">
                  <h2>Top Active Jobs</h2>
                  <span>Last 30 days</span>
                </div>
                <div className="line-chart">
                  <canvas id="chart" />
                </div>
                <div className="chart-data-details">
                  <div className="chart-details-header" />
                </div>
              </div>
            </div>
            <div className="chart-container-wrapper small">
              <div className="chart-container">
                <div className="chart-container-header">
                  <h2>Acquisitions</h2>
                  <span href="#">This month</span>
                </div>
                <div className="acquisitions-bar">
                  <span
                    className="bar-progress rejected"
                    style={{ width: "8%" }}
                  />
                  <span
                    className="bar-progress on-hold"
                    style={{ width: "10%" }}
                  />
                  <span
                    className="bar-progress shortlisted"
                    style={{ width: "18%" }}
                  />
                  <span
                    className="bar-progress applications"
                    style={{ width: "64%" }}
                  />
                </div>
                <div className="progress-bar-info">
                  <span className="progress-color applications" />
                  <span className="progress-type">Applications</span>
                  <span className="progress-amount">64%</span>
                </div>
                <div className="progress-bar-info">
                  <span className="progress-color shortlisted" />
                  <span className="progress-type">Shortlisted</span>
                  <span className="progress-amount">18%</span>
                </div>
                <div className="progress-bar-info">
                  <span className="progress-color on-hold" />
                  <span className="progress-type">On-hold</span>
                  <span className="progress-amount">10%</span>
                </div>
                <div className="progress-bar-info">
                  <span className="progress-color rejected" />
                  <span className="progress-type">Rejected</span>
                  <span className="progress-amount">8%</span>
                </div>
              </div>
              <div className="chart-container applicants">
                <div className="chart-container-header">
                  <h2>New Applicants</h2>
                  <span>Today</span>
                </div>
                <div className="applicant-line">
                  <img
                    src="https://images.unsplash.com/photo-1587628604439-3b9a0aa7a163?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjB8fHdvbWFufGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
                    alt="profile"
                  />
                  <div className="applicant-info">
                    <span>Emma Ray</span>
                    <p>
                      Applied for <strong>Product Designer</strong>
                    </p>
                  </div>
                </div>
                <div className="applicant-line">
                  <img
                    src="https://images.unsplash.com/photo-1583195764036-6dc248ac07d9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2555&q=80"
                    alt="profile"
                  />
                  <div className="applicant-info">
                    <span>Ricky James</span>
                    <p>
                      Applied for <strong>IOS Developer</strong>
                    </p>
                  </div>
                </div>
                <div className="applicant-line">
                  <img
                    src="https://images.unsplash.com/photo-1450297350677-623de575f31c?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzV8fHdvbWFufGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
                    alt="profile"
                  />
                  <div className="applicant-info">
                    <span>Julia Wilson</span>
                    <p>
                      Applied for <strong>UI Developer</strong>
                    </p>
                  </div>
                </div>
                <div className="applicant-line">
                  <img
                    src="https://images.unsplash.com/photo-1596815064285-45ed8a9c0463?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1215&q=80"
                    alt="profile"
                  />
                  <div className="applicant-info">
                    <span>Jess Watson</span>
                    <p>
                      Applied for <strong>Design Lead</strong>
                    </p>
                  </div>
                </div>
                <div className="applicant-line">
                  <img
                    src="https://images.unsplash.com/photo-1543965170-4c01a586684e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2232&q=80"
                    alt="profile"
                  />
                  <div className="applicant-info">
                    <span>John Pellegrini</span>
                    <p>
                      Applied for <strong>Back-End Developer</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="app-right">
          <button className="close-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x"
            >
              <line x1={18} y1={6} x2={6} y2={18} />
              <line x1={6} y1={6} x2={18} y2={18} />
            </svg>
          </button>
          <div className="profile-box">
            <div className="profile-photo-wrapper">
              <img
                src="https://images.unsplash.com/photo-1551292831-023188e78222?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTE0fHxwb3J0cmFpdHxlbnwwfDB8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
                alt="profile"
              />
            </div>
            <p className="profile-text">Julia Pellegrini</p>
            <p className="profile-subtext">Recruiting Manager</p>
          </div>
          <div className="app-right-content">
            <div className="app-right-section">
              <div className="app-right-section-header">
                <h2>Messages</h2>
                <span className="notification-active">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-message-square"
                  >
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                  </svg>
                </span>
              </div>
              <div className="message-line">
                <img
                  src="https://images.unsplash.com/photo-1562159278-1253a58da141?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzB8fHBvcnRyYWl0JTIwbWFufGVufDB8MHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
                  alt="profile"
                />
                <div className="message-text-wrapper">
                  <p className="message-text">Eric Clampton</p>
                  <p className="message-subtext">
                    Have you planned any deadline for this?
                  </p>
                </div>
              </div>
              <div className="message-line">
                <img
                  src="https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
                  alt="profile"
                />
                <div className="message-text-wrapper">
                  <p className="message-text">Jess Flax</p>
                  <p className="message-subtext">
                    Can we schedule another meeting for next thursday?
                  </p>
                </div>
              </div>
              <div className="message-line">
                <img
                  src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80"
                  alt="profile"
                />
                <div className="message-text-wrapper">
                  <p className="message-text">Pam Halpert</p>
                  <p className="message-subtext">
                    The candidate has been shorlisted.
                  </p>
                </div>
              </div>
            </div>
            <div className="app-right-section">
              <div className="app-right-section-header">
                <h2>Activity</h2>
                <span className="notification-active">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-bell"
                  >
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                    <path d="M13.73 21a2 2 0 0 1-3.46 0" />
                  </svg>
                </span>
              </div>
              <div className="activity-line">
                <span className="activity-icon warning">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-alert-circle"
                  >
                    <circle cx={12} cy={12} r={10} />
                    <line x1={12} y1={8} x2={12} y2={12} />
                    <line x1={12} y1={16} x2="12.01" y2={16} />
                  </svg>
                </span>
                <div className="activity-text-wrapper">
                  <p className="activity-text">
                    Your plan is expires in <strong>3 days.</strong>
                  </p>
                  <a className="activity-link" href="#">
                    Renew Now
                  </a>
                </div>
              </div>
              <div className="activity-line">
                <span className="activity-icon applicant">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file-plus"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={12} y1={18} x2={12} y2={12} />
                    <line x1={9} y1={15} x2={15} y2={15} />
                  </svg>
                </span>
                <div className="activity-text-wrapper">
                  <p className="activity-text">
                    There are <strong>3 new applications</strong> for{" "}
                    <strong>UI Developer</strong>
                  </p>
                </div>
              </div>
              <div className="activity-line">
                <span className="activity-icon close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx={12} cy={12} r={10} />
                    <line x1={15} y1={9} x2={9} y2={15} />
                    <line x1={9} y1={9} x2={15} y2={15} />
                  </svg>
                </span>
                <div className="activity-text-wrapper">
                  <p className="activity-text">
                    Your teammate, <strong>Wade Wilson</strong> has closed the
                    job post of <strong>IOS Developer</strong>
                  </p>
                </div>
              </div>
              <div className="activity-line">
                <span className="activity-icon applicant">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file-plus"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={12} y1={18} x2={12} y2={12} />
                    <line x1={9} y1={15} x2={15} y2={15} />
                  </svg>
                </span>
                <div className="activity-text-wrapper">
                  <p className="activity-text">
                    There are <strong>4 new applications</strong> for{" "}
                    <strong>Front-End Developer</strong>
                  </p>
                </div>
              </div>
              <div className="activity-line">
                <span className="activity-icon applicant">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file-plus"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={12} y1={18} x2={12} y2={12} />
                    <line x1={9} y1={15} x2={15} y2={15} />
                  </svg>
                </span>
                <div className="activity-text-wrapper">
                  <p className="activity-text">
                    There are <strong>2 new applications</strong> for{" "}
                    <strong>Design Lead</strong>
                  </p>
                </div>
              </div>
              <div className="activity-line">
                <span className="activity-icon close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx={12} cy={12} r={10} />
                    <line x1={15} y1={9} x2={9} y2={15} />
                    <line x1={9} y1={9} x2={15} y2={15} />
                  </svg>
                </span>
                <div className="activity-text-wrapper">
                  <p className="activity-text">
                    Your teammate, <strong>Wade Wilson</strong> has closed the
                    job post of <strong>Back-End Developer</strong>
                  </p>
                </div>
              </div>
              <div className="activity-line">
                <span className="activity-icon draft">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file-minus"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={9} y1={15} x2={15} y2={15} />
                  </svg>
                </span>
                <div className="activity-text-wrapper">
                  <p className="activity-text">
                    You have drafted a job post for{" "}
                    <strong>HR Specialist</strong>
                  </p>
                  <a href="#" className="activity-link">
                    Complete Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
