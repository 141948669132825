import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import {
  deleteCustomDashboardPanel,
  updateCustomDashboardPanel,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import { rndViewerClassName } from "../../../utils";
import ResourceDocs from "./ResourceDocs";

const ImagesSingle = ({ panel }) => {
  const dispatch = useDispatch();
  const panelUserAccessInfo = useSelector(
    (state) => state.customPanelModify.panelUserAccessInfo
  );
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const [isDraggableUnAvailable, setIsDraggableUnAvailable] = useState(false);

  const updatePanelSectionPosition = (panel, x, y, w, h) => {
    panel.panel_config.defaultPosition.x = x;
    panel.panel_config.defaultPosition.y = y;
    panel.panel_config.defaultPosition.width = w;
    panel.panel_config.defaultPosition.height = h;
    dispatch(
      updateCustomDashboardPanel(
        activeTabData.guid,
        panel.guid,
        panel.panel_config
      )
    );
  };

  return (
    <>
      <div className="image-body-section">
        <Rnd
          className={rndViewerClassName("rnd-section-image single__panelimg single-img-panel", panelUserAccessInfo)}
          id={"rnd-section-image-" + panel.guid}
          style={panel.panel_config.defaultStyle}
          default={panel.panel_config.defaultPosition}
          disableDragging={
            panelUserAccessInfo && !panelUserAccessInfo.dashboard.operation
              ? true
              : isDraggableUnAvailable
          }
          onDragStop={(event, dragableData) => {
            event.preventDefault();
            if (
              panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation
            ) {
              updatePanelSectionPosition(
                panel,
                dragableData.x,
                dragableData.y,
                panel.panel_config.defaultPosition.width,
                panel.panel_config.defaultPosition.height
              );
            }
          }}
          onResizeStop={(event, direction, ref, delta, position) => {
            event.preventDefault();
            if (
              panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation
            ) {
              updatePanelSectionPosition(
                panel,
                position.x,
                position.y,
                panel.panel_config.defaultPosition.width + delta.width,
                panel.panel_config.defaultPosition.height + delta.height
              );
            }
          }}
        >
          {/* <div className="w-100 h-100"> */}
          <div className="__img_flex_column__">
            <div className="box-viewpanel">
              <div
                className="box-viewpanel-header d-flex"
                style={{ padding: "0 .5rem" }}
              >
                <h4 className="box-title mb-0 d-flex align-items-center">
                  {panel.panel_config.data ? panel.panel_config.data.name : null}
                </h4>
                <div className="box-viewpanel-header-action">
                  {panelUserAccessInfo &&
                  panelUserAccessInfo.dashboard.operation ? (
                    <button
                      className="btn btn-media-remove panel-remove-icon icon-h-w"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                          deleteCustomDashboardPanel(
                            activeTabData.guid,
                            panel.guid
                          )
                        );
                        document
                          .getElementById("rnd-section-image-" + panel.guid)
                          .remove();
                      }}
                      type="button"
                    >
                      {getIconFromList("DELETE", { cursor: "pointer" })}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="box-viewpanel-body"
              style={{
                overflowY: "auto",
                // height: "90%"
              }}
              onMouseOver={(e) => {
                e.preventDefault();
                setIsDraggableUnAvailable(true);
              }}
              onMouseLeave={(e) => {
                e.preventDefault();
                setIsDraggableUnAvailable(false);
              }}
            >
              <div className="single__panel--img">
                <img
                  src={
                    panel.panel_config.data
                      ? panel.panel_config.data.img_url
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </Rnd>
      </div>
    </>
  );
};

export default ImagesSingle;
