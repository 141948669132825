import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import "./style.css";
import toastr from "toastr";
import { s3UploaderObject } from "../../../../utils";
import { CircularProgressbar } from "react-circular-progressbar";
import Loader from "./Loader";
import request from "../../../../utils/request";
import docLogo from "./docs_logo.svg";
import { getIconFromList } from "../../../../static/IconServe";
import ResourceViewer from "./Viewer";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomDashboardPanel, deleteResource, getResources, uploadResources } from "../../../../actions/customPanelModify";

// const ResourceDocs = ({panel, panelElementId, resourceType, bodyHeight, setIsDraggableUnAvailable}) => {
const ResourceDocs = ({resourceType}) => {
  const dispatch = useDispatch();
  const panelUserAccessInfo = useSelector( (state) => state.customPanelModify.panelUserAccessInfo );
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );
  const activeTabData = useSelector( (state) => state.customPanelModify.ACTIVE_TAB_DATA );

  const [resourceDocs, setResourceDocs] = useState(null);
  const [isUploaderModalOpen, setUplaoderModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [isViewerOpen, setIsViewerModal] = useState(false);
  const [modalFile, setModalFile] = useState(null);

  useEffect(() => {
    getResourceList();
  }, []);

  const getResourceList = async () => {
    dispatch({
      type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
      payload: true,
    });
    dispatch(
      getResources(panelFieldInfo.panel_type, panelFieldInfo.panel_type_guid, resourceType)
      ).then(res => {
        setResourceDocs(res.data);
        dispatch({
          type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
          payload: false,
        });
      }).catch(err => {
        // console.log(err)
        dispatch({
          type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
          payload: false,
        });
      })
  };

  const { acceptedFiles, getRootProps } = useDropzone({
    multiple: false,
    accept: resourceType === "images" ? ".png, .jpg, .jpeg" : ".pdf",
  });

  return (
    <div className="box-viewpanel" style={{position: "relative", minWidth: "450px"}}>
      <div
        className="box-viewpanel-header d-flex"
        style={{ padding: ".375rem .75rem" }}
      >
        {/* <h4 className="box-title mb-0">
          {resourceType === "images" ? "Images" : "Documents"}
        </h4> */}
        <div className="box-viewpanel-header-action" style={{position: "absolute", top: "-54px", left: "30%"}}>
          {/* {panelUserAccessInfo && !panelUserAccessInfo.dashboard.operation ? (
            <button
              className="btn btn-media-remove panel-remove-icon icon-h-w"
              onClick={(e) => {
                e.preventDefault();
                dispatch(deleteCustomDashboardPanel(activeTabData.guid, panel.guid));
                document.getElementById(panelElementId).remove();
              }}
              type="button"
            >
              {getIconFromList("DELETE", { cursor: "pointer" })}
            </button>
          ) : null} */}
          {
            panelUserAccessInfo && panelUserAccessInfo.dashboard.operation ? 
            <>
              <button
                className="btn btn-media-add icon-h-w"
                type="button"
                onClick={() => {
                  setUplaoderModal(!isUploaderModalOpen);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  />
                </svg>
              </button>
            </> : <></>
          }
        </div>

        <Modal
          isOpen={isUploaderModalOpen}
          onRequestClose={() => {
            setUplaoderModal(!isUploaderModalOpen);
          }}
          contentLabel=" ResourceUploaderModal"
          className="ResourceUploaderModal ReactModal__dialog"
        >
          <div className="ReactModal__Content--container uploader_modal">
            <div className="ReactModal__Content--inner__header">
              <div className="ReactModal__Content--inner__header--block">
                <div className="ReactModal__Content--inner__header--block__block">
                  <h4 className="ReactModal__Content--title">Upload a file</h4>
                  <p className="modal-description">Attach the file below</p>
                </div>
              </div>
              <button
                className="btn ReactModal__btn--close"
                onClick={() => setUplaoderModal(!isUploaderModalOpen)}
              ></button>
            </div>
            <div className="ReactModal__Content--inner__body text-center">
              {uploadProgress ? (
                <CircularProgressbar
                  value={parseInt(uploadProgress)}
                  text={`${parseInt(uploadProgress)}%`}
                  strokeWidth={6}
                  styles={{
                    width: "150px",
                    textColor: "var(--secondary)",
                    pathColor: "var(--secondary)",
                    trailColor: "var(--secondary-dark-700)",
                  }}
                />
              ) : (
                <>
                  <button className="upload-area" {...getRootProps()}>
                    <span className="upload-area-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="340.531"
                        height="419.116"
                        viewBox="0 0 340.531 419.116"
                      >
                        <g id="files-new" clipPath="url(#clip-files-new)">
                          <path
                            id="Union_2"
                            data-name="Union 2"
                            d="M-2904.708-8.885A39.292,39.292,0,0,1-2944-48.177V-388.708A39.292,39.292,0,0,1-2904.708-428h209.558a13.1,13.1,0,0,1,9.3,3.8l78.584,78.584a13.1,13.1,0,0,1,3.8,9.3V-48.177a39.292,39.292,0,0,1-39.292,39.292Zm-13.1-379.823V-48.177a13.1,13.1,0,0,0,13.1,13.1h261.947a13.1,13.1,0,0,0,13.1-13.1V-323.221h-52.39a26.2,26.2,0,0,1-26.194-26.195v-52.39h-196.46A13.1,13.1,0,0,0-2917.805-388.708Zm146.5,241.621a14.269,14.269,0,0,1-7.883-12.758v-19.113h-68.841c-7.869,0-7.87-47.619,0-47.619h68.842v-18.8a14.271,14.271,0,0,1,7.882-12.758,14.239,14.239,0,0,1,14.925,1.354l57.019,42.764c.242.185.328.485.555.671a13.9,13.9,0,0,1,2.751,3.292,14.57,14.57,0,0,1,.984,1.454,14.114,14.114,0,0,1,1.411,5.987,14.006,14.006,0,0,1-1.411,5.973,14.653,14.653,0,0,1-.984,1.468,13.9,13.9,0,0,1-2.751,3.293c-.228.2-.313.485-.555.671l-57.019,42.764a14.26,14.26,0,0,1-8.558,2.847A14.326,14.326,0,0,1-2771.3-147.087Z"
                            transform="translate(2944 428)"
                            fill="var(--secondary)"
                          />
                        </g>
                      </svg>
                    </span>
                    <div>
                      {acceptedFiles.length === 1 ? (
                        <span className="upload-area-title">
                          {acceptedFiles[0].path} - {acceptedFiles[0].size}{" "}
                          bytes
                        </span>
                      ) : (
                        <div>
                          <span className="upload-area-title">
                            Drag file(s) here to upload.
                          </span>
                          <span className="upload-area-description">
                            Alternatively, you can select a file by <br />
                            <strong>clicking here</strong>
                          </span>
                        </div>
                      )}
                    </div>
                  </button>
                  <div className="modal-buttons">
                    <button
                      className="btn btn-gradient-primary"
                      onClick={() => {
                        setUplaoderModal(!isUploaderModalOpen);
                      }}
                    >
                      Cancel
                    </button>
                    {acceptedFiles.length === 1 ? (
                      <button
                        className="btn btn-gradient-primary ml-2"
                        onClick={async (e) => {
                          e.target.disabled = true;
                          // // console.log(acceptedFiles);
                          dispatch({
                            type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
                            payload: true,
                          });
                          await s3UploaderObject(
                            acceptedFiles[0],
                            panelFieldInfo.project_guid
                          )
                            .then(async (res) => {
                              dispatch(
                                uploadResources(panelFieldInfo.panel_type, panelFieldInfo.panel_type_guid, resourceType, resourceType === "images"
                                      ? {
                                          img_url: res.data.data.file,
                                          name: acceptedFiles[0].path.replace(/\.[^.$]+$/, ''),
                                        }
                                      : {
                                          doc_url: res.data.data.file,
                                          name: acceptedFiles[0].path.replace(/\.[^.$]+$/, ''),
                                        })
                                ).then(resp => {
                                  getResourceList();
                                  setUplaoderModal(!isUploaderModalOpen);
                                  setUploadProgress(null);
                                  acceptedFiles.splice(
                                    acceptedFiles.indexOf(acceptedFiles[0].path),
                                    1
                                  );
                                }).catch(err => {
                                  // console.log(err)
                                  dispatch({
                                    type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
                                    payload: false,
                                  });
                                })
                            });
                        }}
                        style={{ position: "relative" }}
                      >
                        Upload File
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal>
      </div>
      <div
        className="box-viewpanel-body"
        style={{
          overflowY: "auto",
          // height: bodyHeight - 46 + "px",
        }}
        // onMouseOver={(e) => {
        //   e.preventDefault();
        //   setIsDraggableUnAvailable(true);
        // }}
        // onMouseLeave={(e) => {
        //   e.preventDefault();
        //   setIsDraggableUnAvailable(false);
        // }}
      >
        {resourceDocs ? (
          <>
            <ul className="box-viewpanel-media-lists" style={{maxHeight: "300px", overflow: "auto"}}>
              {resourceDocs.map((item) => (
                <li key={item.guid}>
                  <div className="viewpanel-media-item d-flex p-0">
                    <div className="viewpanel-media-icon">
                      <img
                        src={
                          resourceType === "images"
                            ? item.thumbnail_url
                            : docLogo
                        }
                        alt={item.name}
                        title={item.name}
                        onClick={() => {
                          setModalFile({
                            name: item.name,
                            doc_url:
                              resourceType === "images"
                                ? item.img_url
                                : item.doc_url,
                          });
                          setIsViewerModal(true);
                        }}
                      />
                    </div>
                    <div className="viewpanel-media-content">
                      <h5
                        className="viewpanel-media-title"
                        onClick={() => {
                          setModalFile({
                            name: item.name,
                            doc_url:
                              resourceType === "images"
                                ? item.img_url
                                : item.doc_url,
                          });
                          setIsViewerModal(true);
                        }}
                      >
                        {item.name}
                      </h5>
                    </div>
                    {
                      panelUserAccessInfo && panelUserAccessInfo.dashboard.operation ? 
                      <>
                        <div className="viewpanel-media-icon viewpanel-media-actions">
                          {
                            resourceType === "images" ? 
                              <span
                                onClick={async (e) => {
                                  e.preventDefault();
                                  window.createSingleImagePanel(item);
                                }}
                                style={{cursor: "pointer"}}
                              >
                                {getIconFromList("LAUNCH", { cursor: "pointer" })}
                              </span>
                            : <></>
                          }
                          <span
                            onClick={async (e) => {
                              e.preventDefault();
                              dispatch({
                                type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
                                payload: true,
                              });
                              dispatch(
                                deleteResource(panelFieldInfo.panel_type, panelFieldInfo.panel_type_guid, resourceType, item.guid)
                                ).then(res => {
                                  getResourceList();
                                }).catch(err => {
                                  // console.log(err)
                                  dispatch({
                                    type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
                                    payload: false,
                                  });
                                })
                            }}
                          >
                            {getIconFromList("DELETE", { cursor: "pointer" })}
                          </span>
                        </div>
                      </> : <></>
                    }
                  </div>
                </li>
              ))}
            </ul>

            {modalFile && (
              <ResourceViewer
                isViewerOpen={isViewerOpen}
                setIsViewerModal={setIsViewerModal}
                file={modalFile}
              />
            )}
          </> 
        ) : (
          <div className="box-viewpanel-body text-center">
            <Loader />
            <code>Loading Field Resources</code>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourceDocs;
