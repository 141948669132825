export const generateStackedBarColumnChartLayout = (chart, bar_data) => ({
  autosize: true,
  margin: {
    l: 50,
    r: 10,
    b: 10,
    t: 20,
    pad: 10,
  },
  // width: 150,
  // height: 180,

  paper_bgcolor: "lightblue",
  plot_bgcolor: "#ddd",
  barmode: 'group',

  legend: {
    x: 1,
    y: 1.3,
    xanchor: "right",
    // xanchor: "center",
    orientation: "h", //To place the legend below chart
    font: {
      family: "Arial, sans-serif",
      size: 10,
      color: "white",
    },
  },
  // hoverlabel: {
  //   bgcolor: "red",
  // },
  xaxis: {
    automargin: true,
    // zerolinecolor: "#00FFFB",
    zerolinewidth: 0,
    // zerolinewidth: 2,
    gridcolor: "#004B54",
    griddash: "dot",
    gridwidth: 1,
    // tickwidth: 15,
    // type: 'category',
    tickcolor: "#B9B8CE",
    tickangle: "auto",
    tickfont: {
      family: "sans-serif",
      size: 12,
      color: "#01E3FE",
    },
    title: {
      standoff: 10, //Similar as Margin Top
      text: bar_data.bar_plot_type === "Vertical" ? chart.x_axis_label : chart.y_axis_label,
      font: {
        family: "sans-serif",
        size: 12,
        color: "#fff",
      },
    },
    // range: [0, 50]
    linecolor: '#00FFFB',
    linewidth: 2
  },
  yaxis: {
    automargin: true,
    // zerolinecolor: "#00FFFB",
    zerolinewidth: 0,
    // zerolinewidth: 2,
    gridcolor: "#004B54",
    griddash: "dot",
    gridwidth: 1,
    // tickwidth: 15,
    tickcolor: "#B9B8CE",
    tickfont: {
      family: "sans-serif",
      size: 12,
      color: "#01E3FE",
    },
    title: {
      text: bar_data.bar_plot_type === "Vertical" ? chart.y_axis_label : chart.x_axis_label,
      font: {
        family: "sans-serif",
        size: 12,
        color: "#fff",
      },
    },
    linecolor: '#00FFFB',
    linewidth: 2
  },
});
