import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProjectTreeInfo } from "../../../../actions/webglAssociatesActions";
import "./style.css";
import toastr from "toastr";

const FieldTree = (props) => {
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );

  const [isOpen, setIsOpen] = useState(false);
  const [fieldData, setFieldData] = useState(null);
  const [p_guid, setPguid] = useState(null);
  const dispatch = useDispatch();
  window.TREE_VIEW_CALL = false;

  window.showHideTreeView = () => {
    setPguid(null);
    if (isOpen) setIsOpen(false);
    else {
      setIsOpen(true);
      setPguid(panelFieldInfo);
      dispatch(getProjectTreeInfo(panelFieldInfo.project_guid))
        .then((res) => {
          // // console.log(res.data);
          setFieldData(res.data.tree);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const loadPanel = (data) => {
    if (data.type !== "component") {
      window.loadWebGlAndCloseCustomPanel();
      setTimeout(() => {
        window.loadCustomPanel(JSON.stringify(data));
      }, 1500);
    } else {
      toastr.warning("No dashboard for component", "Warning");
    }
  };

  const closeFieldTree = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && fieldData ? (
        <>
          <div
            className="app-project-card field_tree"
            style={{
              position: "absolute",
              top: "50px",
              left: "0px",
              background: "#08202E",
              zIndex: "1030",
            }}
          >
            <div className="d-flex justify-content-between app-project-header">
              <h4
                className="app-project-header-title pointer_hover_line"
                onClick={() => {
                  let data = {
                    type: "project",
                    project_guid: p_guid.project_guid,
                    asset_guid: "",
                    pipeline_guid: "",
                    component_guid: "",
                    location: p_guid.location,
                  };
                  loadPanel(data);
                }}
              >
                {fieldData.field_name}xx
              </h4>
              <span
                onClick={() => {
                  closeFieldTree();
                }}
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#f70000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M6 19h12v2H6v-2z" />
                </svg>
              </span>
            </div>
            <div
              className="app-project-body"
              style={{
                padding: "0.5rem 0.5rem 0.5rem 0.5rem",
                minWidth: "250px",
                maxWidth: "350px",
                overflow: "auto",
                height: "calc(100vh - 102px)",
              }}
            >
              {/* ASSET */}
              {/* --- PARENT --- */}
              <h6 className="app-project-header-title mb-1">
                <strong>{"Assets"}</strong>
              </h6>
              {fieldData.assets.map((asset, a_index) => (
                <ul className="tree treeview__list p-0 pl-4 pb-2" key={a_index}>
                  <li>
                    {asset.assets_list.length > 0 ? (
                      <>
                        <input
                          type="checkbox"
                          id={"treeview__field1" + "___" + a_index}
                        />
                        <label
                          className="tree_label pointer_hover_line"
                          htmlFor={"treeview__field1" + "___" + a_index}
                        >
                          {asset.category}
                        </label>
                      </>
                    ) : (
                      <>
                        <span className="tree_label pointer_hover_line">
                          {asset.category}
                        </span>
                      </>
                    )}

                    {/* --- CHILD --- */}
                    {asset.assets_list.map(
                      (single_asset, single_asset_index) => (
                        <ul key={single_asset_index}>
                          <li>
                            {single_asset.components.length > 0 ? (
                              <>
                                <input
                                  type="checkbox"
                                  id={
                                    "treeview__pipeline1" +
                                    "___" +
                                    a_index +
                                    "___" +
                                    single_asset_index
                                  }
                                />
                                <label
                                  htmlFor={
                                    "treeview__pipeline1" +
                                    "___" +
                                    a_index +
                                    "___" +
                                    single_asset_index
                                  }
                                  className="tree_label pointer_hover_line"
                                >
                                  <span
                                    className="tree_label pointer_hover_line"
                                    onClick={() => {
                                      let data = {
                                        type: "asset",
                                        project_guid: p_guid.project_guid,
                                        asset_guid: single_asset.guid,
                                        pipeline_guid: "",
                                        component_guid: "",
                                        location: p_guid.location,
                                      };
                                      loadPanel(data);
                                    }}
                                  >
                                    {single_asset.name}
                                  </span>
                                </label>
                              </>
                            ) : (
                              <>
                                <span
                                  className="tree_label pointer_hover_line"
                                  onClick={() => {
                                    let data = {
                                      type: "asset",
                                      project_guid: p_guid.project_guid,
                                      asset_guid: single_asset.guid,
                                      pipeline_guid: "",
                                      component_guid: "",
                                      location: p_guid.location,
                                    };
                                    loadPanel(data);
                                  }}
                                >
                                  {single_asset.name}
                                </span>
                              </>
                            )}

                            {/* --- GRAND CHILD --- */}
                            {single_asset.components.map(
                              (component, component_index) => (
                                <ul key={component_index}>
                                  <li>
                                    <span
                                      className="tree_label pointer_hover_line"
                                      onClick={() => {
                                        let data = {
                                          type: "component",
                                          project_guid: p_guid.project_guid,
                                          asset_guid: "",
                                          pipeline_guid: "",
                                          component_guid: component.guid,
                                          location: p_guid.location,
                                        };
                                        loadPanel(data);
                                      }}
                                    >
                                      {component.name}
                                    </span>
                                  </li>
                                </ul>
                              )
                            )}
                          </li>
                        </ul>
                      )
                    )}
                  </li>
                </ul>
              ))}
              {/* PIPELINE */}
              {/* --- PARENT --- */}
              <h6 className="app-project-header-title mt-4 mb-1">
                <strong>{"Pipelines"}</strong>
              </h6>
              {fieldData.pipelines.map((pipeline, p_index) => (
                <ul className="tree treeview__list p-0 pl-4 pb-2" key={p_index}>
                  <li>
                    <input
                      type="checkbox"
                      id={"treeview__field1_pipe" + "___" + p_index}
                    />
                    <label
                      className="tree_label pointer_hover_line"
                      htmlFor={"treeview__field1_pipe" + "___" + p_index}
                    >
                      {pipeline.category}
                    </label>
                    {/* --- CHILD --- */}
                    {pipeline.pipelines_list.map(
                      (single_pipeline, single_pipeline_index) => (
                        <ul key={single_pipeline_index}>
                          <li>
                            <span
                              className="tree_label pointer_hover_line"
                              onClick={() => {
                                let data = {
                                  type: "pipeline",
                                  project_guid: p_guid.project_guid,
                                  asset_guid: "",
                                  pipeline_guid: single_pipeline.guid,
                                  component_guid: "",
                                  location: p_guid.location,
                                };
                                loadPanel(data);
                              }}
                            >
                              {single_pipeline.name}
                            </span>
                          </li>
                        </ul>
                      )
                    )}
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FieldTree;
