import React, { useEffect } from "react";
import TopHeader from "../../components/TopHeader";
import ContainerFluid from "../../components/ContainerFluid";
import CopyRightFooter from "../../components/CopyRightFooter";
import { Link, useNavigate } from "react-router-dom";

import H1 from "../../static/img/documentation/Help_file_v2-1.png";
import H2 from "../../static/img/documentation/Help_file_v2-2.png";
import H3 from "../../static/img/documentation/Help_file_v2-3.png";
import H4 from "../../static/img/documentation/Help_file_v2-4.png";
import H5 from "../../static/img/documentation/Help_file_v2-5.png";
import H6 from "../../static/img/documentation/Help_file_v2-6.png";

const Documentation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuth = !!localStorage.getItem("access_token");
    if (!isAuth) {
      navigate("/");
      // // console.log("----------not-----authorized-----------");
    }
  });

  return (
    <ContainerFluid>
      <TopHeader />
      <div className="d-flex justify-content-center">
        <Link to="/home">
          {/* <p className="font_audiowide m-0"> */}
          <span className="logo_size brand_color">Back to home</span>
          {/* </p> */}
        </Link>
      </div>
      <div className="slider_box">
        <div className="container">
          <div
            id="carouselExampleControls"
            className="carousel slide documentation__slider"
            data-bs-ride="carousel"
            data-bs-interval="false"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="5"
                aria-label="Slide 6"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="img-size" src={H1} alt="First slide" />
              </div>
              <div className="carousel-item">
                <img className="img-size" src={H2} alt="First slide" />
              </div>
              <div className="carousel-item">
                <img className="img-size" src={H3} alt="First slide" />
              </div>
              <div className="carousel-item">
                <img className="img-size" src={H4} alt="First slide" />
              </div>
              <div className="carousel-item">
                <img className="img-size" src={H5} alt="First slide" />
              </div>
              <div className="carousel-item">
                <img className="img-size" src={H6} alt="First slide" />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-chevron-compact-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
                />
              </svg>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-chevron-compact-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <CopyRightFooter />
    </ContainerFluid>
  );
};

export default Documentation;
