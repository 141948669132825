import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { 
  getDatasetColumns, 
  getDatasetColumnsStatisticOperation, 
  getDatasetColumnsStatisticOperation__METADATA, 
  getDatasetColumns__METADATA, updateCustomDashboardPanel 
} from "../../../actions/customPanelModify";
import ModalAT from "../../ModalAT";

const statictics = [
  { name: "Sum" },
  { name: "Min" },
  { name: "Max" },
  { name: "Mean" },
  { name: "Median" },
  { name: "Count" },
  { name: "First" },
  { name: "Last" },
];

const staticDataset = [
  { name: "Asset Basic", calling_param: "asset_basic" },
  { name: "Asset Specification", calling_param: "asset_spec" },
  { name: "Pipeline Basic", calling_param: "pipeline_basic" },
  { name: "Pipeline Specification", calling_param: "pipeline_spec" },
];

const IndicatorUpdate = () => {
  const dispatch = useDispatch();
  const activeTabData = useSelector( (state) => state.customPanelModify.ACTIVE_TAB_DATA );
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );
  const datasetList = useSelector( (state) => state.customPanelModify.datasetList );
  const isWebglDashboardActive = useSelector((state) => state.customPanelModify.isWebglDashboard);
  // --- MODAL CONF ---
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedDatasetColumnList, setSelectedDatasetColumnList] = useState(null);
  const [selectedDatasetColumn, setSelectedDatasetColumn] = useState(null);
  const [selectedDatasetStat, setSelectedDatasetStat] = useState(null);

  window.indicatorModalOpen = (panel) => {
    setPanelInfo(panel);
    setIsOpen(true);
  };

  const afterOpenModal = () => {};
  const closeModal = () => {
    setSelectedDataset(null);
    setSelectedDatasetColumnList(null);
    setSelectedDatasetColumn(null);
    setSelectedDatasetStat(null);
    setIsOpen(false);
  };

  const selectOptionOnChangeHandler__DATASET_SELECTION = (data) => {
    if (data === "ADD_NEW_DATASET") {
      closeModal();
      document.getElementById("new-dataset-input-modal__opener-el").click();
    } else if (data) {
      setSelectedDataset(JSON.parse(data));
      dispatch({
        type: "UPDATE_REQUEST_LOADER_STATE",
        payload: true
      })
      dispatch(JSON.parse(data).guid ? 
              getDatasetColumns(panelFieldInfo.panel_type, panelFieldInfo.panel_type_guid, JSON.parse(data).guid) : 
              getDatasetColumns__METADATA(panelFieldInfo.panel_type_guid, JSON.parse(data).calling_param) )
        .then((res) => {
          setSelectedDatasetColumnList(res.data.data);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false
          })
        })
        .catch((err) => {
          // console.log(err);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false
          })
        });
    }
  };

  // CHECK STAT OPERATION - AFTER DATASET'S COLUMN SELECTION, SELECT STATISTIC AND CALL THIS FUNCTION - THIS WILL RETURN RESULT
  const checkStaticticOperation = (stat) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true
    })
    dispatch(
      selectedDataset.guid
        ? getDatasetColumnsStatisticOperation( selectedDataset.guid, panelFieldInfo.panel_type, selectedDatasetColumn, stat.toLocaleLowerCase() ) : 
          getDatasetColumnsStatisticOperation__METADATA( panelFieldInfo.panel_type_guid, selectedDataset.calling_param, selectedDatasetColumn, stat.toLocaleLowerCase() ) )
      .then((res) => {
        setSelectedDatasetStat(res.data);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false
        })
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false
        })
      });
  };

  const applyDatasetClickHandler = () => {
    panelInfo.panel_config.data.value = selectedDatasetStat
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true
    })
    dispatch(updateCustomDashboardPanel(activeTabData.guid, panelInfo.guid, panelInfo.panel_config));
    closeModal()
  }

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_indicator_value_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle="Update Indicator Value"
          contentLabel="Update Indicator Value"
          ariaHideApp={false}
        >
          {/* DATASET SELECTION */}
          <div className="form__group">
            <label htmlFor="" className="form__label">
              Select Dataset
            </label>
            <select
              className="custom-select"
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                selectOptionOnChangeHandler__DATASET_SELECTION(e.target.value);
              }}
            >
              <option value={null} selected disabled>
                Select dataset
              </option>
              {!isWebglDashboardActive ? <option value={"ADD_NEW_DATASET"}>Add New Dataset</option> : <></>}
              <optgroup label="Meta Dataset">
                {staticDataset.map((dataset, index) => (
                  <option value={JSON.stringify(dataset)} key={index}>
                    {dataset.name}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Uploaded Dataset">
                {datasetList.map((dataset, index) => (
                  <option value={JSON.stringify(dataset)} key={index}>
                    {dataset.name}
                  </option>
                ))}
              </optgroup>
            </select>
          </div>
          {/* COLUMN SELECTION */}
          {selectedDatasetColumnList ? (
            <>
              <div className="form__group mt-2">
                <label htmlFor="" className="form__label">
                  Select Dataset Column
                </label>
                <select
                  className="custom-select"
                  onChange={(e) => {
                    e.stopPropagation();
                    setSelectedDatasetColumn(e.target.value);
                  }}
                >
                  <option value={null} selected disabled>
                    Select dataset Column
                  </option>
                  {selectedDatasetColumnList.map((col, index) => (
                    <option value={col.col_name} key={index}>
                      {col.col_name}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : (
            <></>
          )}
          {selectedDatasetColumn ? (
            <>
              <div className="form__group mt-2">
                <label htmlFor="" className="form__label">
                  Select Statistic
                </label>
                <select
                  className="custom-select"
                  onChange={(e) => {
                    e.stopPropagation();
                    checkStaticticOperation(e.target.value);
                  }}
                >
                  <option value={null} selected disabled>
                    Select Statistic
                  </option>
                  {statictics.map((stat, index) => (
                    <option value={stat.name} key={index}>
                      {stat.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* SUBMIT */}
          <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
            <button
              className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
              disabled={!selectedDatasetStat}
              onClick={() => {
                applyDatasetClickHandler();
              }}
            >
              Apply
            </button>
          </div>
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorUpdate);
