import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import {
  deleteCustomDashboardPanel,
  getPanelSpecification,
  updateCustomDashboardPanel,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import { rndPositionDefaultXYWH, rndPositionStyle, rndViewerClassName } from "../../../utils";

const Specifications = ({ panel }) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const panelUserAccessInfo = useSelector(
    (state) => state.customPanelModify.panelUserAccessInfo
  );
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );

  const [specificationData, setSpecificationData] = useState(null);

  useEffect(() => {
    if (panelFieldInfo) {
      getSpecificationData(panelFieldInfo.panel_type);
    }
  }, [panelFieldInfo]);

  const getSpecificationData = async (type) => {
    dispatch(getPanelSpecification(type, panelFieldInfo))
      .then((res) => {
        setSpecificationData(res.data);
      })
      .catch((error) => {
        // console.log("catch", error);
      });
  };

  const updatePanelSectionPosition = (panel, x, y, w, h) => {
    panel.panel_config.defaultPosition.x = x;
    panel.panel_config.defaultPosition.y = y;
    panel.panel_config.defaultPosition.width = w;
    panel.panel_config.defaultPosition.height = h;

    panel.panel_config.panelStyleRatio = {
      widthPercent: (w/document.documentElement.clientWidth),
      // heightPercent: (h/document.documentElement.clientHeight),
      heightPercent: h,
      x: (x/document.documentElement.clientWidth),
      y: (y/document.documentElement.clientHeight)
    };
    dispatch(
      updateCustomDashboardPanel(
        activeTabData.guid,
        panel.guid,
        panel.panel_config
      )
    );
  };

  return (
    <>
      <div className="specification-body-section">
        <Rnd
          className={rndViewerClassName("rnd-section-specification rnd__section__panel", panelUserAccessInfo)}
          id={"rnd-section-specification-" + panel.guid}
          // style={panel.panel_config.defaultStyle}
          // default={panel.panel_config.defaultPosition}
          style={rndPositionStyle(panel.panel_config.defaultStyle)}
          default={rndPositionDefaultXYWH(panel.panel_config)}
          disableDragging={
            panelUserAccessInfo && !panelUserAccessInfo.dashboard.operation
              ? true
              : false
          }
          onDragStop={(event, dragableData) => {
            event.preventDefault();
            if (
              panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation
            ) {
              updatePanelSectionPosition(
                panel,
                dragableData.x,
                dragableData.y,
                panel.panel_config.defaultPosition.width,
                panel.panel_config.defaultPosition.height
              );
            }
          }}
          onResizeStop={(event, direction, ref, delta, position) => {
            event.preventDefault();
            if (
              panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation
            ) {
              updatePanelSectionPosition(
                panel,
                position.x,
                position.y,
                panel.panel_config.defaultPosition.width + delta.width,
                panel.panel_config.defaultPosition.height + delta.height
              );
            }
          }}
        >
          <div
            className="box-specification __RND_EMD_CHILD_CONTAINER__"
            style={{
              width: "100%",
              height: panel.panel_config.defaultPosition.height - 2 + "px",
            }}
          >
            {panelUserAccessInfo && panelUserAccessInfo.dashboard.operation ? (
              <>
                <span
                  className="panel-remove-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      deleteCustomDashboardPanel(activeTabData.guid, panel.guid)
                    );
                    document
                      .getElementById("rnd-section-specification-" + panel.guid)
                      .remove();
                  }}
                >
                  {getIconFromList("DELETE", { cursor: "pointer" })}
                </span>
              </>
            ) : (
              ""
            )}
            <div className="box-specification-header">
              <h4 className="box-specification-title mb-0">
                {panelFieldInfo.panel_type === "project"
                  ? "FIELD SPECIFICATION"
                  : panelFieldInfo.panel_type === "asset"
                  ? "ASSET SPECIFICATION"
                  : "PIEPLINE SPECIFICATION"}
              </h4>
            </div>
            <div className="box-specification-body p-0">
              <table className="table table-dark table-borderless bg-transparent mb-0">
                <tbody
                  style={{
                    maxHeight:
                      panel.panel_config.defaultPosition.height - 40 - 4 + "px",
                    overflowY: "auto",
                    display: "block",
                  }}
                >
                  {specificationData &&
                  panelFieldInfo.panel_type === "project" ? (
                    <>
                      <tr>
                        <td colSpan={2} className="fw-bold">
                          Total Structure Assets:{" "}
                          {specificationData.total_assets}
                        </td>
                      </tr>
                      {specificationData.modules.map((element, index) => (
                        <tr key={index}>
                          <td style={{ width: "40%" }}>{element.module}</td>
                          <td>: {element.count}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={2} className="fw-bold">
                          Total Structure Pipeline:{" "}
                          {specificationData.total_pipelines}
                        </td>
                      </tr>
                      {specificationData.types.map((element, index) => (
                        <tr key={index}>
                          <td style={{ width: "40%" }}>{element.type}</td>
                          <td>: {element.count}</td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={2} className="fw-bold">
                          Basic Data
                        </td>
                      </tr>
                      {specificationData && specificationData.basic_data ? (
                        specificationData.basic_data.map((element, index) =>
                          element.showing ? (
                            <tr key={index}>
                              <td style={{ width: "40%" }}>{element.label}</td>
                              <td>: {element.value}</td>
                            </tr>
                          ) : (
                            <></>
                          )
                        )
                      ) : (
                        <></>
                      )}
                      <tr>
                        <td colSpan={2} className="fw-bold">
                          Specification Data
                        </td>
                      </tr>
                      {specificationData && specificationData.specifications ? (
                        specificationData.specifications.map((element, index) =>
                          element.showing ? (
                            <tr key={index}>
                              <td style={{ width: "40%" }}>{element.label}</td>
                              <td>: {element.value}</td>
                            </tr>
                          ) : (
                            <></>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Rnd>
      </div>
    </>
  );
};

export default Specifications;
