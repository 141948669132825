import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Unity, { UnityContext } from "react-unity-webgl";

import { useRTMClient } from "@agnostech/react-agora-ng";
import TopHeader from "../../components/TopHeader";
import LoaderLogo from "./IdareLogo.gif";
import "./unityWrapper.css";
import AppNavigator from "../../components/AppNavigator";
import WebglAssociates from "../../components/WebglAssociates";
import { useDispatch, useSelector, connect } from "react-redux";
import ComponentChart from "../../components/WebglAssociates/Modals/ComponentChart";

import FieldTree from "../../components/WebglAssociates/Modals/FieldTree";
import CustomPannelDashboard from "../../components/_CustomPanel_2";
import { getProjectAccess } from "../../actions/customPanelModify";
import AddDatasetWebGL from "../../components/WebglAssociates/Modals/AddDataset";
import "./style.css";

// This is the context that Unity will use to communicate with the React app.

const unityContext = new UnityContext({
  productName: "React Unity WebGL Tests",
  companyName: "IDARE Bangladesh",
  loaderUrl: `${process.env.REACT_APP_WEBGL_PATH}${process.env.REACT_APP_WEBGL_LOADER}`,
  dataUrl: `${process.env.REACT_APP_WEBGL_PATH}${process.env.REACT_APP_WEBGL_DATA}`,
  frameworkUrl: `${process.env.REACT_APP_WEBGL_PATH}${process.env.REACT_APP_WEBGL_FRAMEWORK}`,
  codeUrl: `${process.env.REACT_APP_WEBGL_PATH}${process.env.REACT_APP_WEBGL_WASM}`,
  webglContextAttributes: {
    preserveDrawingBuffer: true,
  },
});

window.unityContext = unityContext;

const App = ({ un, currentUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rtcChannel = useRef(null);
  const [channelCreated, setChannelCreated] = useState(false);
  const [channelName, setChannelName] = useState(null);
  const [isHost, setIsHost] = useState(false);

  const [isUnityMounted] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [progression, setProgress] = useState(null);
  const [customPanelFlag, setCustomPanelFlag] = useState(false);
  const [rtcMessages, setRtcMessages] = useState([]);
  const [rtcMembers, setRtcMembers] = useState([]);
  const [rtcMembersWithUnityPayload, setRtcMembersWithUnityPayload] = useState(
    []
  );
  const [currentMessage, setCurrentMessage] = useState();
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const [useRguid, setUseRguid] = useState(null);

  const rtmClient = useRTMClient();
  window.closeDashboard = () => {};

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.loadCustomPanel(
  //       JSON.stringify({
  //         type: "project",
  //         project_guid: "a662b5c5-e8c3-480f-8586-54142ce2def3",
  //         asset_guid: "",
  //         pipeline_guid: "",
  //         component_guid: "",
  //         location: {
  //           latitude: 25.176,
  //           longitude: -77.742022,
  //           altitude: -150.0,
  //           x: 223631.03093526589,
  //           y: 2787250.8728538036,
  //           z: -150.0,
  //         },
  //       })
  //     );
  //   }, 2000);
  // }, []);

  const initializeRTM = async (unityPayload) => {
    console.clear();

    await rtmClient.login({
      uid: un,
    });

    if (rtcChannel) {
      rtcChannel.current.join();
      window.rtcChannel = rtcChannel;

      await rtmClient.addOrUpdateLocalUserAttributes({
        name: un,
        unityPayload: unityPayload,
      });

      let members = await rtcChannel.current.getMembers();

      setRtcMembers(members);
      setChannelCreated(true);

      if (members.length > 0) {
        members.map(async (member) => {
          if (member !== un) {
            let attr = await rtmClient.getUserAttributesByKeys(member, [
              "unityPayload",
            ]);

            window.unityContext.send(
              "LiveManager",
              "CreateAvatar",
              attr.unityPayload
            );
          } else {
            let attr = await rtmClient.getUserAttributesByKeys(member, [
              "unityPayload",
            ]);
            setRtcMembersWithUnityPayload([
              ...rtcMembersWithUnityPayload,
              { name: member, unityPayload: attr.unityPayload },
            ]);
          }
        });
      }
    }
  };

  window.logOutFromRTC = async () => {
    //ending audio call
    await window.leaveFromRTCChannel();

    //Destorying avatar
    window.unityContext.send(
      "LiveManager",
      "LeaveAvatar",
      rtmClient.attributes.unityPayload
    );

    //Closing RTM Modal
    window.RTMConsole();

    await rtmClient.logout();
  };

  window.createChannel = async (unityPayload) => {
    const uguid = JSON.parse(unityPayload).uguid;
    setUseRguid(uguid);
    const pguid = JSON.parse(unityPayload).pguid;
    rtcChannel.current = rtmClient.createChannel(pguid);
    await initializeRTM(unityPayload);

    const members = await rtcChannel.current.getMembers();
    members.map(async (member) => {
      let attr = await rtmClient.getUserAttributesByKeys(member, [
        "unityPayload",
      ]);
      setRtcMembersWithUnityPayload([
        { unityPayload: attr.unityPayload, name: member },
        ...rtcMembersWithUnityPayload,
      ]);
    });

    setChannelName(pguid);
  };

  window.sendAssetData = (liveData) => {
    rtcChannel.current
      .sendMessage({
        text: JSON.stringify({ dataType: "asset", data: liveData }),
      })
      .then(() => {
        setCurrentMessage({
          user: { name: un },
          message: JSON.stringify({ dataType: "asset", data: liveData }),
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  window.sendPlayerData = (liveData) => {
    rtcChannel.current
      .sendMessage({
        text: JSON.stringify({ dataType: "player", data: liveData }),
      })
      .then(() => {
        setCurrentMessage({
          user: { name: un },
          message: JSON.stringify({ dataType: "player", data: liveData }),
        });
      })
      .catch((err) => {
        // // console.log(err);
      });
  };

  useEffect(() => {
    if (channelCreated) {
      rtcChannel.current.on("ChannelMessage", async (data, uid) => {
        if (data.messageType === "TEXT") {
          const newMessageData = { user: { name: uid }, message: data.text };
          setCurrentMessage(newMessageData);
        }
        switch (JSON.parse(data.text).dataType) {
          case "asset":
            window.unityContext.send(
              "LiveManager",
              "ReceivedObjectData",
              JSON.parse(data.text).data
            );
            break;
          case "player":
            window.unityContext.send(
              "LiveManager",
              "ReceivedPlayerData",
              JSON.parse(data.text).data
            );
            break;
        }
      });
    }
  }, [channelCreated]);

  useEffect(() => {
    if (channelCreated) {
      rtcChannel.current.on("MemberJoined", async (uid) => {
        // console.log(uid);
        // console.log(uid, "Just joined the channel");

        const memberAttr = await rtmClient.getUserAttributes(uid);

        let members = await rtcChannel.current.getMembers();

        setRtcMembers(members);

        window.unityContext.send(
          "LiveManager",
          "CreateAvatar",
          memberAttr.unityPayload
        );

        setRtcMembersWithUnityPayload([
          ...rtcMembersWithUnityPayload,
          memberAttr,
        ]);
      });
    }
  }, [channelCreated, rtcMembersWithUnityPayload]);

  useEffect(() => {
    if (channelCreated) {
      rtcChannel.current.on("MemberLeft", async (uid) => {
        // console.log(uid, "Just left the channel");

        let members = await rtcChannel.current.getMembers();

        setRtcMembers(members);

        // console.log(rtcMembersWithUnityPayload);
        rtcMembersWithUnityPayload.map((member) => {
          if (member.name === uid) {
            window.unityContext.send(
              "LiveManager",
              "LeaveAvatar",
              member.unityPayload
            );
          }
        });
      });
    }
  }, [channelCreated, rtcMembersWithUnityPayload]);

  useEffect(() => {
    if (currentMessage) {
      if (rtcMessages.length === 20) {
        // console.log("Log removed from DOM...");
        setRtcMessages([currentMessage]);
      } else {
        setRtcMessages([...rtcMessages, currentMessage]);
      }
    }
  }, [currentMessage]);

  window.goMetaUniverse = async (unityPayload) => {
    await window.createChannel(unityPayload);
    await window.RTMConsole();
  };

  useEffect(() => {
    const isAuth = !!localStorage.getItem("access_token");
    if (!isAuth) navigate("/");

    unityContext.on("progress", (progression) => {
      setProgress(progression);
      if (Math.round(progression * 100) === 100) {
        setIsLoaded(true);
      }
    });

    return () => {
      unityContext.removeAllEventListeners();
    };
  });

  window.webGLFullScreen = (boolean) => {
    if (boolean === 1) {
      unityContext.setFullscreen(true);
    } else if (boolean === 0) {
      unityContext.setFullscreen(false);
    }
  };

  window.loadCustomPanel = (panelDataJsonString) => {
    window.SetWebGLKeyboardCaptureStatus(0);
    let dt = JSON.parse(panelDataJsonString);
    dispatch({
      type: "SET_CUSTOM_PANEL_AS_INITIAL_STATE",
    });
    if (dt.type === "project") {
      loadCustomPanelR();
      dispatchCustomPanelData(
        dt.type,
        dt.project_guid,
        dt.project_guid,
        dt.location
      );
    }
    if (dt.type === "asset") {
      loadCustomPanelR();
      dispatchCustomPanelData(
        dt.type,
        dt.asset_guid,
        dt.project_guid,
        dt.location
      );
    }
    if (dt.type === "pipeline") {
      loadCustomPanelR();
      dispatchCustomPanelData(
        dt.type,
        dt.pipeline_guid,
        dt.project_guid,
        dt.location
      );
    }
  };

  window.getCurrentUser = JSON.stringify(currentUser);

  const dispatchCustomPanelData = (
    panel_type,
    panel_type_guid,
    project_guid,
    location
  ) => {
    if (panel_type && panel_type_guid && project_guid) {
      dispatch({
        type: "SET_PANEL_FIELD_INFO",
        payload: {
          panel_type: panel_type,
          panel_type_guid: panel_type_guid,
          project_guid: project_guid,
          location: location,
        },
      });
      dispatch(getProjectAccess(project_guid));
    }
  };

  const loadCustomPanelR = () => {
    if (customPanelFlag) {
      setCustomPanelFlag(false);
    } else {
      setCustomPanelFlag(true);
      // setTimeout(() => {
      //   document.getElementById("assettwin-custom-panel-dashboard__modify");
      // }, 100);
      // setTimeout(() => {
      //   document.querySelector(".unity-container").style.display = "none";
      // }, 1000);
    }
  };

  window.loadWebGlAndCloseCustomPanel = () => {
    document.querySelector(".unity-container").style.display = "block";
    document.querySelector(".unity-container");
    setTimeout(() => {
      setCustomPanelFlag(false);
    }, 1000);
  };

  return (
    <div className="webgl_content_page">
      <TopHeader />
      {isUnityMounted && (
        <div className="unity-container">
          {!isLoaded && (
            <div className="loading-overlay">
              <img
                src={LoaderLogo}
                height="70px"
                style={{ textAlign: "center" }}
                alt="LOADER LOGO"
              />
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: progression * 100 + "%" }}
                />
              </div>
            </div>
          )}
          <Unity className="unity-canvas" unityContext={unityContext} />
          <AppNavigator />
          <WebglAssociates
            messages={rtcMessages}
            currentUserName={un}
            rtcMembers={rtcMembers}
            channelName={channelName}
            isHost={isHost}
            useRguid={useRguid}
          />
        </div>
      )}

      <div
        style={
          customPanelFlag
            ? {
                position: "fixed",
                background: "#05141d",
                height: "calc( 100vh - 50px)",
                bottom: "0",
                left: 0,
                zIndex: "1",
              }
            : {}
        }
      >
        {customPanelFlag && panelFieldInfo ? (
          <>
            <CustomPannelDashboard />
          </>
        ) : (
          <>
            <AddDatasetWebGL />
          </>
        )}
      </div>
      <FieldTree />
      <ComponentChart />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    un: state.auth.first_name + " " + state.auth.last_name,
    currentUser: state.auth,
  };
};

export default connect(mapStateToProps)(App);
