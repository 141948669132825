import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { deleteCustomDashboardPanel, updateCustomDashboardPanel } from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import { checkReadonlyFlag_userAccessInfo_dashboardOperation, rndPositionDefaultXYWH, rndPositionStyle, rndViewerClassName } from "../../../utils";

const Indicators = ({ panel }) => {
  const dispatch = useDispatch();
  const panelUserAccessInfo = useSelector( (state) => state.customPanelModify.panelUserAccessInfo );
  const activeTabData = useSelector( (state) => state.customPanelModify.ACTIVE_TAB_DATA );
  const [isDraggableUnAvailable, setIsDraggableUnAvailable] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);

  useEffect(() => {
    document.getElementById("rnd-section-indicator-header-title-input-" + panel.guid).value = panel.panel_config.data ? panel.panel_config.data.topHeaderName : "";
    document.getElementById("rnd-section-indicator-unit-input-" + panel.guid).value = panel.panel_config.data ? panel.panel_config.data.unit : "";
    document.getElementById("rnd-section-indicator-bottom-text-input-" + panel.guid).value = panel.panel_config.data ? panel.panel_config.data.bottomHeaderName : "";
    panel.panel_config.defaultStyle.background = panel.panel_config.data ? panel.panel_config.data.style.background : "#08202E"
  }, [panel.panel_config.data]);

  const updatePanelSectionPosition = (panel, x, y, w, h) => {
    panel.panel_config.defaultPosition.x = x;
    panel.panel_config.defaultPosition.y = y;
    panel.panel_config.defaultPosition.width = w;
    panel.panel_config.defaultPosition.height = h;

    panel.panel_config.panelStyleRatio = {
      widthPercent: (w/document.documentElement.clientWidth),
      // heightPercent: (h/document.documentElement.clientHeight),
      heightPercent: h,
      x: (x/document.documentElement.clientWidth),
      y: (y/document.documentElement.clientHeight)
    };
    dispatch( updateCustomDashboardPanel(activeTabData.guid, panel.guid, panel.panel_config) );
  };

  const checkDecimalPointForValue = () => {
    if(panel.panel_config.data) {
      if(panel.panel_config.data.style && panel.panel_config.data.style.value_decimalPoint) {
        if(isNaN(panel.panel_config.data.style.value_decimalPoint)) {
          let dd_ = "" + panel.panel_config.data.style.value_decimalPoint
          return dd_.includes(".") ? dd_.split(".")[1].length : 0
        } else {
          return panel.panel_config.data.style.value_decimalPoint
        }
      }
    } else {
      return 3;
    }
  }

  return (
    <>
      <div className="indicator-body-section">
        <Rnd
          className={rndViewerClassName("rnd-section-indicator", panelUserAccessInfo)}
          id={"rnd-section-indicator-" + panel.guid}
          style={rndPositionStyle(panel.panel_config.defaultStyle)}
          default={rndPositionDefaultXYWH(panel.panel_config)}
          disableDragging={
            panelUserAccessInfo && !panelUserAccessInfo.dashboard.operation
              ? true
              : isDraggableUnAvailable
          }
          onDragStop={(event, dragableData) => {
            event.preventDefault();
            if (panelUserAccessInfo && panelUserAccessInfo.dashboard.operation) {
              updatePanelSectionPosition(
                panel,
                dragableData.x,
                dragableData.y,
                panel.panel_config.defaultPosition.width,
                panel.panel_config.defaultPosition.height
              );
            }
          }}
          onResizeStop={(event, direction, ref, delta, position) => {
            event.preventDefault();
            if (panelUserAccessInfo && panelUserAccessInfo.dashboard.operation) {
              updatePanelSectionPosition(
                panel,
                position.x,
                position.y,
                panel.panel_config.defaultPosition.width + delta.width,
                panel.panel_config.defaultPosition.height + delta.height
              );
            }
          }}
        >
          <div
            className="chart-container-wrapper __RND_EMD_CHILD_CONTAINER__"
            style={{ padding: "0", width: "100%" }}
          >
            <div className="chart-container">
              {panelUserAccessInfo && panelUserAccessInfo.dashboard.operation ? (
                <>
                <div className="indicator-panel-customization-option"
                  onMouseOver={(e) => {
                    e.preventDefault();
                    setIsDraggableUnAvailable(true);
                  }}
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    setIsDraggableUnAvailable(false);
                  }}
                >
                  <span
                    className="indicator-panel-style-icon"
                    onClick={async (e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(true);
                      window.indicatorStyleModalOpen(panel)
                    }}
                  >
                    {getIconFromList("SETTINGS", { cursor: "pointer" })}
                  </span>
                  <span
                    className="indicator-panel-remove-icon"
                    onClick={async (e) => {
                      e.preventDefault();
                      dispatch(deleteCustomDashboardPanel(activeTabData.guid, panel.guid))
                      document.getElementById("rnd-section-indicator-" + panel.guid).remove()
                    }}
                  >
                    {getIconFromList("DELETE", { cursor: "pointer" })}
                  </span>
                </div>
                </>
              ) : (
                ""
              )}
              <div
                className="chart-info-wrapper"
                style={{
                  height: panel.panel_config.defaultPosition.height - 3 + "px",
                  display: "inline-grid",
                  width: "100%",
                  // background: panel.panel_config.data ? panel.panel_config.data.style.background : "#08202E",
                }}
              >
                {/* ------------------------ TOP HEADER ------------------------ */}
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    className="form-control  editable-input placeholder-italic"
                    id={"rnd-section-indicator-header-title-input-" + panel.guid}
                    placeholder="Top Header"
                    readOnly={!checkReadonlyFlag_userAccessInfo_dashboardOperation(panelUserAccessInfo)}
                    onKeyUp={async (e) => {
                      clearTimeout(typingTimer);
                      setTypingTimer(
                        setTimeout((evt) => {
                          panel.panel_config.data.topHeaderName = e.target.value
                          dispatch(updateCustomDashboardPanel(activeTabData.guid, panel.guid, panel.panel_config));
                        }, 2000)
                      );
                    }}
                    onFocus={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(true);
                    }}
                    onBlur={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(false);
                    }}
                    style={{
                      fontSize: "2rem",
                      color: panel.panel_config.data ? panel.panel_config.data.style ? panel.panel_config.data.style.topHeader : "#FFFFFF" : "#FFFFFF",
                      fontSize: panel.panel_config.data ? panel.panel_config.data.style ? (panel.panel_config.data.style.topHeader_fontSize ? panel.panel_config.data.style.topHeader_fontSize : "2rem") : "2rem" : "2rem",
                    }}
                  />
                </h2>
                {/* ------------------------ VALUE ------------------------ */}
                <h2
                  style={{
                    display: "flex",
                    margin: "0",
                    alignItems: "center",
                  }}
                >
                  <span
                    onClick={checkReadonlyFlag_userAccessInfo_dashboardOperation(panelUserAccessInfo) ? (e) => {
                      e.stopPropagation()
                      setIsDraggableUnAvailable(true);
                      window.indicatorModalOpen(panel)
                    }: undefined}
                    style={{
                      fontSize: "3rem",
                      cursor: "pointer",
                      fontWeight: "bold",
                      color: panel.panel_config.data ? panel.panel_config.data.style ? panel.panel_config.data.style.value : "#FFFFFF" : "#FFFFFF",
                      fontSize: panel.panel_config.data ? panel.panel_config.data.style ? (panel.panel_config.data.style.value_fontSize ? panel.panel_config.data.style.value_fontSize : "3rem") : "3rem" : "3rem",
                    }}
                    onMouseLeave={checkReadonlyFlag_userAccessInfo_dashboardOperation(panelUserAccessInfo) ? (e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(false);
                    } : undefined}
                  >
                    {panel.panel_config.data
                      ? panel.panel_config.data.value
                        ? isNaN(Number(panel.panel_config.data.value.result).toFixed(checkDecimalPointForValue()))
                          ? panel.panel_config.data.value.result
                          : Number(panel.panel_config.data.value.result).toFixed(checkDecimalPointForValue()) : "0.00" : "0.00"}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "600",
                      marginTop: "1px",
                      width: "50px",
                      minWidth: "50px",
                    }}
                  >
                    {/* ------------------------ Unit: ------------------------ */}
                    <input
                      type="text"
                      className="form-control  editable-input placeholder-italic"
                      id={"rnd-section-indicator-unit-input-" + panel.guid}
                      placeholder="unit"
                      readOnly={!checkReadonlyFlag_userAccessInfo_dashboardOperation(panelUserAccessInfo)}
                      onKeyUp={async (e) => {
                        clearTimeout(typingTimer);
                        setTypingTimer(
                          setTimeout((evt) => {
                            panel.panel_config.data.unit = e.target.value
                            dispatch(updateCustomDashboardPanel(activeTabData.guid, panel.guid, panel.panel_config));
                          }, 2000)
                        );
                      }}
                      onFocus={(e) => {
                        e.preventDefault();
                        setIsDraggableUnAvailable(true);
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        setIsDraggableUnAvailable(false);
                      }}
                      style={{
                        fontSize: "1rem",
                        marginLeft: "4px",
                        color: panel.panel_config.data ? panel.panel_config.data.style ? panel.panel_config.data.style.unit : "#FFFFFF" : "#FFFFFF",
                        fontSize: panel.panel_config.data ? panel.panel_config.data.style ? (panel.panel_config.data.style.unit_fontSize ? panel.panel_config.data.style.unit_fontSize : "1rem") : "1rem" : "1rem",
                      }}
                    />
                  </span>
                </h2>
                {/* ------------------------ BOTTOM HEADER ------------------------ */}
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    className="form-control  editable-input placeholder-italic"
                    id={"rnd-section-indicator-bottom-text-input-" + panel.guid}
                    placeholder="Bottom Header"
                    readOnly={!checkReadonlyFlag_userAccessInfo_dashboardOperation(panelUserAccessInfo)}
                    onKeyUp={async (e) => {
                      clearTimeout(typingTimer);
                      setTypingTimer(
                        setTimeout((evt) => {
                          panel.panel_config.data.bottomHeaderName = e.target.value
                          dispatch(updateCustomDashboardPanel(activeTabData.guid, panel.guid, panel.panel_config));
                        }, 2000)
                      );
                    }}
                    onFocus={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(true);
                    }}
                    onBlur={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(false);
                    }}
                    style={{
                      fontSize: "1.5rem",
                      color: panel.panel_config.data ? panel.panel_config.data.style ? panel.panel_config.data.style.bottomHeader : "#FFFFFF" : "#FFFFFF",
                      fontSize: panel.panel_config.data ? panel.panel_config.data.style ? (panel.panel_config.data.style.bottomHeader_fontSize ? panel.panel_config.data.style.bottomHeader_fontSize : "1.5rem") : "1.5rem" : "1.5rem",
                    }}
                  />
                </h2>
              </div>
            </div>
          </div>
        </Rnd>
      </div>
    </>
  );
};

export default Indicators;
