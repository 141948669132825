import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBillingPackages } from "../../actions/billingAction";
import "./style.css";

const Plan = () => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    dispatch(getBillingPackages())
      .then((res) => {
        setPackages(res.data.packages);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <div
      className="tab-pane fade"
      id="nav-Plan"
      role="tabpanel"
      aria-labelledby="nav-Plan-tab"
    >
      <h4 className="p-2">Subscription Plan</h4>
      <div className="p-2 d-flex">
        {packages.map((pack, index) => (
          <div
            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root jss23 PackageCard  css-s18byi mr-2"
            key={index}
          >
            <div className="plan-sub-header">
              <span className="plan-title-name">{pack.name}</span>
            </div>
            <div className="jss26 MuiBox-root css-0">
              <div className="MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-1xfq3g7">
                {/* <p className="MuiTypography-root MuiTypography-body1 css-1e0k9ig">
                  Max Asset
                </p> */}
                <h4 className="MuiTypography-root MuiTypography-h4 css-yrk6yb">
                  Max Asset 15
                </h4>
              </div>
              <div className="MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-1xfq3g7">
                {/* <p className="MuiTypography-root MuiTypography-body1 css-1e0k9ig">
                  Max Storage
                </p> */}
                <h4 className="MuiTypography-root MuiTypography-h4 css-yrk6yb">
                  Max Storage 10 MB
                </h4>
              </div>
            </div>
            <div className="w-75" style={{ margin: "0 auto" }}>
              <h5 className="MuiTypography-root MuiTypography-h6 css-9w5hq8 p-2 mb-2">
                Additional
              </h5>
              <div className="MuiBox-root css-gg4vpm d-flex justify-content-between">
                <p className="MuiTypography-root MuiTypography-body1 css-1e0k9ig m-0">
                  CSV
                </p>
                <span className="text-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="green"
                    className="bi bi-check2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
                  </svg>
                </span>
              </div>
              <div className="MuiBox-root css-gg4vpm d-flex justify-content-between">
                <p className="MuiTypography-root MuiTypography-body1 css-1e0k9ig m-0">
                  Post SQL
                </p>
                <span className="text-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="red"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                  </svg>
                </span>
              </div>
              <div className="MuiBox-root css-gg4vpm d-flex justify-content-between">
                <p className="MuiTypography-root MuiTypography-body1 css-1e0k9ig m-0">
                  Share Project
                </p>
                <span className="text-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="red"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plan;
