import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchComponentSpecs,
  handleComponentBasicDataSelection,
  handleComponentSpecsDataChange,
} from "../../../../actions/webglAssociatesActions";
import loader from "../../../../static/loader.gif";
import request from "../../../../utils/request";
import toastr from "toastr";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

import ModalTemplate from "../../ModalTemplate";

const ComponentSpecsEditor = ({
  fetchComponentSpecs,
  requestPending,
  componentSpecs,
  handleComponentBasicDataSelection,
  handleComponentSpecsDataChange,
}) => {
  const dispatch = useDispatch();

  const types = [
    {
      label: "Integer",
      value: "integerNumber",
      type: "number",
      pattern: "[0-9]",
      oninput: true,
    },
    {
      label: "Text",
      value: "text",
      type: "text",
    },
    {
      label: "Decimal",
      value: "decimalNumber",
      type: "number",
      pattern: "^d+(?:.d{1,2})?$",
      min: "0",
      step: "0.01",
    },
  ];

  const [isOpen, setOpen] = useState(false);
  const [projectGuid, setProjectGuid] = useState(null);
  const [assetGuid, setAssetGuid] = useState(null);
  const [componentGuid, setComponentGuid] = useState(null);
  const [dataSubmited, setDataSubmited] = useState(false);
  const failedToFetchData = useSelector(
    (state) => state.webglAssociates.failedToFetchData
  );

  // window.CSEditor = (projectGuid, assetGuid, componentGuid) => {
  window.CSEditor = (componentData) => {
    // console.log(componentData)
    let projectGuid = JSON.parse(componentData).project_guid
    let assetGuid = JSON.parse(componentData).asset_guid
    let pipelineGuid = JSON.parse(componentData).pipeline_guid
    let urlParam = JSON.parse(componentData).url_param

    let componentGuid = JSON.parse(componentData).component_guid
    setOpen(!isOpen);
    setProjectGuid(projectGuid);
    setAssetGuid(assetGuid);
    setComponentGuid(componentGuid);
    // fetchComponentSpecs(projectGuid, assetGuid, componentGuid);
    fetchComponentSpecs(componentData);
  };

  return (
    <ModalTemplate
      requestPending={requestPending}
      setOpen={setOpen}
      modalID="CSEditor"
      dataSubmited={dataSubmited}
      isOpen={isOpen}
    >
      {componentSpecs && componentSpecs.basic_data && !failedToFetchData && (
        <div className="modal-content modal-content_table">
          <div className="modal-header justify-content-center p-2 border-0">
            <h5 className="specifications-header-title" id="exampleModalLabel">
              {componentSpecs && componentSpecs.basic_data
                ? componentSpecs.basic_data[0].value + " Specifications"
                : "Component Specifications"}
            </h5>
            <button
              type="button"
              className="transparent-btn btn-close"
              title="Close"
              onClick={() => {
                setOpen(false);
              }}
            ></button>
          </div>
          <div className="">
            <div className="text-center py-3">
              <button
                type="button"
                className="btn btn-scifi modal-ok-btn"
                onClick={() =>
                  dispatch({
                    type: "ADD_COMPONENT_SPECS_NEW_PARAM",
                  })
                }
              >
                <i className="btn-helper"></i>
                <span className="btn-text">+ Add Parameter</span>
              </button>
            </div>
            <div className="modal-body modal__parent_2nd_child_boday">
              <table className="table table-dark align-middle table-borderless bg-transparent table-align-middle">
                <tbody>
                  {componentSpecs && componentSpecs.specifications
                    ? componentSpecs.specifications.map(
                        (specs_single_data, idx) => (
                          <tr key={idx}>
                            <td className="bg-transparent">
                              <input
                                className="form-control rounded-0"
                                type="text"
                                value={specs_single_data.label}
                                placeholder="Label"
                                onChange={(e) =>
                                  handleComponentSpecsDataChange(
                                    specs_single_data.label,
                                    "label",
                                    e.target.value,
                                    idx
                                  )
                                }
                              />
                            </td>
                            <td className="bg-transparent">
                              <div className="d-flex">
                                <input
                                  className="form-control rounded-0"
                                  type="text"
                                  value={specs_single_data.value}
                                  placeholder="Value"
                                  onChange={(e) =>
                                    handleComponentSpecsDataChange(
                                      specs_single_data.value,
                                      "value",
                                      e.target.value,
                                      idx
                                    )
                                  }
                                />

                                {/* <select
                                  className="form-select rounded-0"
                                  aria-label="Default select example"
                                  defaultValue={specs_single_data.value_type}
                                  onChange={(e) => {
                                    handleComponentSpecsDataChange(
                                      specs_single_data.value_type,
                                      "value_type",
                                      e.target.value,
                                      idx
                                    );
                                  }}
                                >
                                  {types.map((type) => (
                                    <option
                                      key={type.value}
                                      selected={
                                        type.value ===
                                        specs_single_data.value_type
                                      }
                                      value={type.value}
                                    >
                                      {type.label}
                                    </option>
                                  ))}
                                </select> */}
                              </div>
                            </td>
                            {/* <td className="bg-transparent">
                              <input
                                className="form-control me-2  rounded-0"
                                type="text"
                                aria-label="default input example"
                                value={specs_single_data.unit}
                                placeholder="Unit"
                                onChange={(e) => {
                                  handleComponentSpecsDataChange(
                                    specs_single_data.unit,
                                    "unit",
                                    e.target.value,
                                    idx
                                  );
                                }}
                              />
                            </td> */}
                            <td className="bg-transparent">
                              <td className="bg-transparent">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={specs_single_data.showing}
                                  id="flexCheckChecked"
                                  onChange={(e) => {
                                    handleComponentSpecsDataChange(
                                      specs_single_data.label,
                                      "showing",
                                      !specs_single_data.showing,
                                      idx
                                    );
                                  }}
                                />
                              </td>
                            </td>
                            <td className="bg-transparent">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-trash"
                                viewBox="0 0 16 16"
                                onClick={() => {
                                  dispatch({
                                    type: "REMOVE_COMPONENT_SPECS",
                                    payload: specs_single_data.guid,
                                  });
                                }}
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer border-0 justify-content-end">
            <button
              type="button"
              className="btn btn-scifi modal-ok-btn mr-3"
              onClick={async () => {
                // // console.log(componentSpecs);
                setDataSubmited(true);
                await request
                  .post(
                    `/api/projects/${projectGuid}/assets/${assetGuid}/components/${componentGuid}/specifications/create_or_update/`,
                    {
                      basic_data: [],
                      specifications: componentSpecs.specifications.map(
                        (specs) => {
                          if (!specs.guid) {
                            return { ...specs, guid: uuidv4() };
                          }
                          return specs;
                        }
                      ),
                    }
                  )
                  .then((resp) => {
                    setDataSubmited(false);
                    toastr.success("Component Specifications", "Updated");
                    setOpen(false);
                  })
                  .catch((err) => {
                    setDataSubmited(false);
                    toastr.error(
                      "Component Specifications",
                      "Failed To Update"
                    );
                    setOpen(false);
                  });
              }}
            >
              {dataSubmited && requestPending ? (
                <span className="btn-text"
                >
                  <img src={loader} height="20px" alt="" width="20px" />
                </span>
              ) : (
                <span className="btn-text">Submit</span>
              )}
            </button>
          </div>
        </div>
      )}
    </ModalTemplate>
  );
};

const mapStateToProps = (state) => ({
  requestPending: state.ui.requestPending,
  componentSpecs: state.webglAssociates.modals.componentSpecs,
});

export default connect(mapStateToProps, {
  fetchComponentSpecs,
  handleComponentBasicDataSelection,
  handleComponentSpecsDataChange,
})(ComponentSpecsEditor);
