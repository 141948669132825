import AWS from "aws-sdk";
import axios from "axios";
import { generate_uuid } from "./uuidv4";

const ENVIRONMENT_FOLDER = process.env.REACT_APP_S3_FOLDER;
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_accessKeyId,
  secretAccessKey: process.env.REACT_APP_S3_secretAccessKey,
});

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const s3UploaderObject = async (file, project_guid) => {
  // // console.log(file);
  let formData = new FormData();
  formData.append("guid", generate_uuid());
  formData.append("file", file);
  formData.append("file_type", file.type);
  let url = `${process.env.REACT_APP_REST_HOST}/api/projects/${project_guid}/s3_bucket/`;
  return axios
    .post(url, formData, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access_token")),
      },
    });
};

export const checkReadonlyFlag_userAccessInfo_dashboardOperation = (panelUserAccessInfo) => {
  if(panelUserAccessInfo && panelUserAccessInfo.dashboard.operation) {
    return panelUserAccessInfo.dashboard.operation
  } else return false;
}

export const rndViewerClassName = (clName, panelUserAccessInfo) => {
  if(checkReadonlyFlag_userAccessInfo_dashboardOperation(panelUserAccessInfo)) return clName;
  else return clName + " __IAM_A_VIEWER__";
}

export const rndPositionStyle = (defaultStyle) => {
  return defaultStyle;
}

export const rndPositionDefaultXYWH = (defaultXYWH) => {
  if(defaultXYWH.panelStyleRatio) {
    // defaultXYWH.defaultPosition.width = "50%"
    // return defaultXYWH.defaultPosition
    return {
      x: defaultXYWH.panelStyleRatio.x * document.documentElement.clientWidth,
      y: defaultXYWH.defaultPosition.y,
      width: defaultXYWH.panelStyleRatio.widthPercent * 100 + "%",
      height: defaultXYWH.defaultPosition.height

      // y: defaultXYWH.panelStyleRatio.y * document.documentElement.clientHeight,
      // height: defaultXYWH.panelStyleRatio.heightPercent * document.documentElement.clientHeight
    }
  } else return defaultXYWH.defaultPosition
}