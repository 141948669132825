import axios from "axios";

const BASE_URL = process.env.REACT_APP_REST_HOST;
const AUTH = "Bearer " + JSON.parse(localStorage.getItem("access_token"));

export const getSubscriptions = () => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/package/subscriptions/`;
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const postSubscribePackage = (data) => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/package/subscribe/`;
  return await axios.post(url, data, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const getCreditPurchase = () => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/credit/purchase/`;
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const postCreditPurchase = (data) => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/credit/purchase/`;
  return await axios.post(url, data, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const getAdditionalPurchase = () => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/additional/purchase/`;
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const postAdditionalPurchase = (data) => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/additional/purchase/`;
  return await axios.post(url, data, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

// PACKAGES LIST --------------

export const getBillingPackages = () => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/packages/`;
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const getSubscriptionInfo = () => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/package/subscription/info/`;
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const getSubscriptionTransaction = () => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/package/subscription/transaction/`;
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const postSubscriptionBill = (data) => async (dispatch) => {
  let url = `${BASE_URL}/api/billing/package/subscribe/`;
  return await axios.post(url, data, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};
