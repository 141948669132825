import React, { Fragment, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import mapboxgl from "mapbox-gl";
import request from "../../../../utils/request";
import { useSelector } from "react-redux";

const baseMaps = {
  Streets: "mapbox://styles/mapbox/streets-v10",
  Outdoors: "mapbox://styles/mapbox/outdoors-v11",
  Satellite: "mapbox://styles/mapbox/satellite-v9",
  Light: "mapbox://styles/mapbox/light-v10",
};

const color_scale = {
  RedYellowTeal: ["#F38181", "#FCE38A", "#95E1D3"],
  BlueNeonSky: ["#B2FCFF", "#5EDFFF", "#3E64FF"],
  BlackGreyTeal: ["#222831", "#393E46", "#00ADB5"],
  YellowOrangeMaroon: ["#F9ED69", "#F08A5D", "#B83B5E"],
  PinkWhiteRetro: ["#FFC7C7", "#F6F6F6", "#8785A2"],
  YellowBlueLight: ["#FEFF86", "#B0DAFF", "#DAF5FF"],
  TealBlackRed: ["#08D9D6", "#252A34", "#FF2E63"],
  NavyRedOrange: ["#293462", "#D61C4E", "#FEB139"],
  OrangeBlueNavy: ["#FF731D", "#5F9DF7", "#1746A2"],
  RedOrangeYellow: ["#FF0000", "#FFA500", "#FFFF00"],
  GreenCyanBlue: ["#008000", "#00FFFF", "#0000FF"],
  PurplePinkGray: ["#800080", "#FFC0CB", "#808080"],
};

const BubbleMap = ({ chart, panel, maximized }) => {
  const {
    base_map,
    bubble_border_color,
    color_palate,
    map_view_limit,
    z_value,
    zoom,
    zmin,
    zmax,
    tooltip_info_col,
    additional_color_palate,
  } = panel.panel_config.bar_data.chart_config;

  const mapContainer = useRef(null);
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const [isLoaderOn, setIsLoaderOn] = useState(true);

  const mapRef = useRef(null);

  useEffect(async () => {
    let map = null;
    console.clear();
    // console.log(chart);
    console.log(panel.panel_config);

    setIsLoaderOn(true);
    await request
      .post(`/api/map/chart/${chart.guid}/stats/`, {
        dataset_type: panelFieldInfo.panel_type,
        chart_config: panel.panel_config.bar_data.chart_config,
      })
      .then(({ data }) => {
        console.log(data);
        // debugger;

        setIsLoaderOn(false);

        if (
          !panel.panel_config.bar_data.chart_config.map_view_limit.max_lat ||
          !panel.panel_config.bar_data.chart_config.map_view_limit.max_lng ||
          !panel.panel_config.bar_data.chart_config.map_view_limit.min_lat ||
          !panel.panel_config.bar_data.chart_config.map_view_limit.min_lng ||
          !panel.panel_config.bar_data.chart_config.zmin ||
          !panel.panel_config.bar_data.chart_config.zmax
        ) {
          panel.panel_config.bar_data.chart_config.map_view_limit.max_lat =
            data.max_lat;
          panel.panel_config.bar_data.chart_config.map_view_limit.max_lng =
            data.max_lon;
          panel.panel_config.bar_data.chart_config.map_view_limit.min_lat =
            data.min_lat;
          panel.panel_config.bar_data.chart_config.map_view_limit.min_lng =
            data.min_lon;
          panel.panel_config.bar_data.chart_config.zmin = data.zmin;
          panel.panel_config.bar_data.chart_config.zmax = data.zmax;
        }

        /* eslint import/no-webpack-loader-syntax: off */
        mapboxgl.workerClass =
          require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

        if (mapRef.current) {
          mapRef.current.remove();
        }

        // Initialize Mapbox map
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API;

        map = new mapboxgl.Map({
          container: mapContainer.current,
          style: baseMaps[base_map],
          center: [
            (parseFloat(data.max_lat) + parseFloat(data.min_lat)) / 2,
            (parseFloat(data.max_lon) + parseFloat(data.min_lon)) / 2,
          ],
          zoom: zoom.value,
          scrollZoom: true,
          attributionControl: false,
        });

        mapRef.current = map;

        window.addEventListener("resize", () => {
          if (map) {
            map.resize();
          }
        });

        // Calculating on backend
        const bounds = [
          [parseFloat(data.min_lon), parseFloat(data.min_lat)],
          [parseFloat(data.max_lon), parseFloat(data.max_lat)],
        ];

        // Calculating on frontend
        // const bounds = [
        //   [Math.min(...data.lon), Math.min(...data.lat)],
        //   [Math.max(...data.lon), Math.max(...data.lat)],
        // ];

        map.fitBounds(bounds, { padding: 50 });
        map.setMaxZoom(14);

        // Define a color scale using D3's scaleSequential() function
        const colorScale = d3
          .scaleSequential()
          .domain([d3.min(data.z), d3.max(data.z)])
          .interpolator(d3.interpolateRgb(...color_scale[color_palate]));

        // console.log(color_scale[data.additional_color_palate]);

        const additionalColorScale = d3
          .scaleSequential()
          .domain([d3.min(data.z), d3.max(data.z)])
          .interpolator(
            d3.interpolateRgb(...color_scale[additional_color_palate])
          );

        map.on("load", () => {
          map.addLayer({
            id: "circles",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: data.z.map((d, index) => ({
                  type: "Feature",
                  properties: {
                    z: d,
                    info: data.tooltip_info_col[index],
                  },
                  geometry: {
                    type: "Point",

                    coordinates: [data.lon[index], data.lat[index]],
                  },
                })),
              },
            },
            type: "circle",
            paint: {
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["get", "z"],
                d3.min(data.z),
                5,
                d3.max(data.z),
                15,
              ],
              "circle-color": [
                "interpolate",
                ["linear"],
                ["get", "z"],
                d3.min(data.z),
                colorScale(d3.min(data.z)),
                d3.max(data.z),
                colorScale(d3.max(data.z)),
              ],
              "circle-stroke-color":
                bubble_border_color === "None"
                  ? "Black"
                  : panel.panel_config.bar_data.chart_config
                      .bubble_border_color,
              "circle-stroke-width": 1,
              "circle-stroke-opacity": bubble_border_color === "None" ? 0 : 1,
            },
          });

          if (data.additional_z_value) {
            map.addLayer({
              id: "additional-circles",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: data.additional_z_value.map((d, index) => ({
                    type: "Feature",
                    properties: {
                      value: d,
                      info: data.tooltip_info_col[index],
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [data.lon[index], data.lat[index]],
                    },
                  })),
                },
              },
              type: "circle",
              paint: {
                "circle-radius": [
                  "interpolate",
                  ["linear"],
                  ["get", "value"],
                  d3.min(data.additional_z_value),
                  5,
                  d3.max(data.additional_z_value),
                  15,
                ],
                "circle-color": [
                  "interpolate",
                  ["linear"],
                  ["get", "value"],
                  d3.min(data.additional_z_value),
                  additionalColorScale(d3.min(data.additional_z_value)),
                  d3.max(data.additional_z_value),
                  additionalColorScale(d3.max(data.additional_z_value)),
                ],
                "circle-stroke-color":
                  bubble_border_color === "None"
                    ? "Black"
                    : panel.panel_config.bar_data.chart_config
                        .bubble_border_color,
                "circle-stroke-width": 1,
                "circle-stroke-opacity": bubble_border_color === "None" ? 0 : 1,
              },
            });
          }
        });

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          anchor: "bottom",
        });

        map.on("mouseenter", "circles", (e) => {
          map.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const { z, info } = e.features[0].properties;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup
            .setLngLat(coordinates)
            .setHTML(
              `<div>
                <strong>${z_value.col_name}:</strong> ${z}<br/>
                <strong>Latitude</strong>: ${parseFloat(
                  coordinates[0].toFixed(3)
                )}<br/>
                <strong>Longitude</strong>: ${parseFloat(
                  coordinates[0].toFixed(3)
                )}<br/>
                ${
                  tooltip_info_col
                    ? `<strong>${tooltip_info_col.col_name.replace(/^\w/, (c) =>
                        c.toUpperCase()
                      )}</strong> : ${info}`
                    : ""
                }
              </div>`
            )
            .addTo(map);
        });

        map.on("mouseleave", "circles", () => {
          map.getCanvas().style.cursor = "";
          popup.remove();
        });
      })
      .catch((err) => {
        setIsLoaderOn(false);
        console.log(err);
        alert("Something went wrong");
      });
  }, [panel, chart, maximized]);

  return (
    <Fragment>
      <div
        ref={mapContainer}
        style={{ width: "100%", height: "100%" }}
        key={`${chart.guid}-${panel.panel_id}`}
      />
      {isLoaderOn ? <div className="chart-loader"></div> : <></>}
    </Fragment>
  );
};

export default BubbleMap;
