import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getIconFromList } from "../../../static/IconServe";

// const defStyle = {
//   defaultStyle: {
//     alignItems: "center",
//     justifyContent: "center",
//     background: "#08202E",
//     border: "1px solid rgb(4 232 255)",
//     borderRadius: "4px",
//   },
//   defaultPosition: {
//     x: window.innerWidth / 2.5,
//     y: window.innerHeight / 2.5,
//     width: 250,
//     // height: randomElementPosition.indicators.height,
//   },
// };

const CSVModal = (props) => {
  const dispatch = useDispatch();
  // const [loader, setLoader] = useState(false);
  // const thisModal = useSelector((state) => state.ui.datasetUploadModal);
  const animLoaderFlag = useSelector(
    (state) => state.customPanelModify.datasetUploaderAnimationFlag
  );

  // useEffect(() => {
  //   if(!thisModal) {
  //     setLoader(false);
  //   }
  // }, [thisModal]);

  return (
    <>
      <div
        className="modal fade"
        id="csv-file-upload-modal"
        tabIndex="-1"
        aria-labelledby="csv-file-upload-modal-label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-content-ui modal-content_table">
            <div className="modal-header">
              <h5 className="" id="csv-file-upload-modal-label">
                Add Data Source
              </h5>
              <button
                type="button"
                className="btn-close transparent-btn"
                id="new-dataset-up-modal-close-btn-custom-panel-csv"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {getIconFromList("CROSS_ICON")}
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group csv-file-upload">
                <input
                  type="file"
                  className="form-control"
                  id="upload-csv-file-input"
                  autoComplete="off"
                  multiple={false}
                  accept=".csv"
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // setLoader(true);
                    dispatch({
                      type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
                      payload: true,
                    });
                    props.handleFileUpload(e.target.files, "CSV");
                  }}
                />
              </div>
              {/* <span>Loading: {props.progress}% done uploading</span> */}
              {animLoaderFlag}
              {animLoaderFlag ?  <span>Uploading...</span> : ""}
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CSVModal);
