import React, { useState } from "react";
import ProjectStorage from "../ProjectStorage";
import { useDispatch } from "react-redux";
import avatar_all from "../../../static/img/avatar/all.jpg";
import FemaleWorkerWhite from "../../../static/img/avatar/female_worker_A.jpg";
import MaleWorkerSlim from "../../../static/img/avatar/male_worker_B.jpg";
import MaleWorkerBlck from "../../../static/img/avatar/male_worker_C.jpg";
import MaleWorkerFat from "../../../static/img/avatar/male_worker_D.jpg";
import MaleWorkerStrong from "../../../static/img/avatar/male_worker_E.jpg";
import request from "../../../utils/request";
import toastr from "toastr";

import AWS from "aws-sdk";
import Billing from "../Billing";
import Subscription from "../Subscription";
import CreditPurchase from "../CreditPurchase";
const ENVIRONMENT_FOLDER = process.env.REACT_APP_S3_FOLDER;
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_accessKeyId,
  secretAccessKey: process.env.REACT_APP_S3_secretAccessKey,
});

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const ProfileContentCard = ({ auth }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [ToggleState, setToggleState] = useState(1);
  const [imageUrl, setImageUrl] = useState(auth.profile_img_link);

  const [first_name, setFirstName] = useState(auth.first_name);
  const [last_name, setLastName] = useState(auth.last_name);
  const [email] = useState(auth.email);
  const [address, setAddress] = useState(auth.address);
  // const [subscription] = useState(auth.subscription);
  const [subscription] = useState(auth.is_premium_user);
  const [organization_name, setOrgName] = useState(auth.organization_name);
  const [selectedUserAvatar, setSelectedUserAvatar] = useState(auth.avatar);
  const [uploadingUserPhoto, setUploadingUserPhoto] = useState(null);
  const [userAvatar, setUserAvatar] = useState([
    {
      name: "Female Worker White",
      id: "FemaleWorkerWhite",
      img: FemaleWorkerWhite,
    },
    { name: "Male Worker Slim", id: "MaleWorkerSlim", img: MaleWorkerSlim },
    { name: "Male Worker Blck", id: "MaleWorkerBlck", img: MaleWorkerBlck },
    { name: "Male Worker Fat", id: "MaleWorkerFat", img: MaleWorkerFat },
    {
      name: "Male Worker Strong",
      id: "MaleWorkerStrong",
      img: MaleWorkerStrong,
    },
  ]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  const getAvatar = (avtr) => {
    switch (avtr) {
      case "FemaleWorkerWhite":
        return FemaleWorkerWhite;
      case "MaleWorkerSlim":
        return MaleWorkerSlim;
      case "MaleWorkerBlck":
        return MaleWorkerBlck;
      case "MaleWorkerFat":
        return MaleWorkerFat;
      case "MaleWorkerStrong":
        return MaleWorkerStrong;
      default:
        return "";
    }
  };

  const handleFileUpload = async (e) => {
    let time = new Date().getTime();
    const params = {
      ACL: "public-read",
      Body: e.target.files[0],
      Bucket: S3_BUCKET,
      Key: `${ENVIRONMENT_FOLDER}/project-dashboard/${time}_${e.target.files[0].name}`,
    };
    let upload = s3
      .upload(params)
      .on("httpUploadProgress", function (evt) {
        setUploadingUserPhoto(parseInt((evt.loaded * 100) / evt.total));
        // // console.log(
        //   "Uploaded :: " + parseInt((evt.loaded * 100) / evt.total) + "%"
        // );
      })
      .promise();
    // document.getElementById("close-edit-modal").click();
    await upload
      .then((data) => {
        // // console.log(data);
        setImageUrl(data.Location);
      })
      .catch((err) => {
        // console.log("Upload failed:", err);
      });
  };

  return (
    <div className="user__panel--col user__panel--content col-md-9">
      <div className="card card-body user__panel--card">
        <div className="tab-content-header">
          <h1 className="tab-content-title">Account Details</h1>
          {isEditing ? (
            <>
              {ToggleState === 1 && (
                <span
                  className="btn__profile--edit __is__edited"
                  onClick={async () => {
                    const updatedPayload = {
                      first_name,
                      last_name,
                      address,
                      organization_name,
                      avatar: selectedUserAvatar,
                      email: auth.email,
                      profile_img_link: imageUrl,
                    };

                    await request
                      .put("/api/auth/user/", updatedPayload)
                      .then((resp) => {
                        // // console.log(resp);
                        setIsEditing(!isEditing);
                        toastr.success("Profile updated");
                        dispatch({
                          type: "SET_UPDATED_USER",
                          payload: resp.data,
                        });
                      })
                      .catch((err) => {
                        // console.log(err);
                        toastr.error("Something wrong.");
                      });
                  }}
                >
                  SAVE CHANGES
                </span>
              )}
            </>
          ) : (
            <>
              {ToggleState === 1 && (
                <span
                  className="btn__profile--edit"
                  onClick={() => setIsEditing(!isEditing)}
                  style={{ color: "green", cursor: "pointer" }}
                >
                  EDIT PROFILE
                </span>
              )}
            </>
          )}
        </div>
        <div className="tabs__container">
          <ul
            className="nav nav-tabs nav-justified user__panel--tab"
            style={{ cursor: "pointer" }}
          >
            <li
              className={`nav-link ${getActiveClass(1, "active")}`}
              onClick={() => toggleTab(1)}
            >
              Profile
            </li>
            {/* <li
              className={`nav-link ${getActiveClass(2, "active")}`}
              onClick={() => toggleTab(2)}
            >
              Subscription
            </li> */}
            <li
              className={`nav-link ${getActiveClass(2, "active")}`}
              onClick={() => toggleTab(2)}
            >
              Field Information
            </li>
            {/* <li
              className={`nav-link ${getActiveClass(4, "active")}`}
              onClick={() => toggleTab(4)}
            >
              Billing
            </li>
            <li
              className={`nav-link ${getActiveClass(5, "active")}`}
              onClick={() => toggleTab(5)}
            >
              Credit Purchase
            </li> */}
          </ul>
          <div className="content-container">
            <div
              className={`user__panel--tab-content ${getActiveClass(
                1,
                "active-content"
              )}`}
            >
              <div className="user__panel--tab-content-block">
                <div className="user__panel--setting_container">
                  {isEditing ? (
                    <div>
                      <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          className="form-control"
                          id="first_name"
                          value={first_name}
                          onChange={({ target }) => setFirstName(target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          className="form-control"
                          id="last_name"
                          value={last_name}
                          onChange={({ target }) => setLastName(target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="organization_name">
                          Organization Name
                        </label>
                        <input
                          className="form-control"
                          id="organization_name"
                          value={organization_name ? organization_name : ""}
                          onChange={({ target }) => setOrgName(target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          className="form-control"
                          readOnly
                          value={email && email}
                        />

                        {/* {email && email} */}
                      </div>
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                          id="address"
                          className="form-control"
                          value={address ? address : ""}
                          onChange={({ target }) => setAddress(target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="first_name">Upload profile photo</label>
                        <div>
                          {uploadingUserPhoto ? (
                            <p>Uploaded {uploadingUserPhoto} %</p>
                          ) : (
                            <input
                              accept="image/*"
                              type="file"
                              onChange={handleFileUpload}
                            />
                          )}
                        </div>
                      </div>
                      <hr />
                      <h4>Avater</h4>
                      <form>
                        <div className="user__avater--container">
                          {userAvatar.map((avt, index) => (
                            <label className="user__avater--label" key={index}>
                              <input
                                id={avt.id}
                                type="radio"
                                name="radio"
                                onClick={() => setSelectedUserAvatar(avt.id)}
                                checked={
                                  selectedUserAvatar === avt.id ? true : false
                                }
                              />
                              <div className="user__avater--card">
                                <span className="checkmark"></span>
                                <img src={avt.img} alt="Admin" className="" />
                                <h6 className="user__avater--card__title">
                                  {avt.name}
                                </h6>
                              </div>
                            </label>
                          ))}
                        </div>
                      </form>
                    </div>
                  ) : (
                    <table className="table table-dark user__panel--table">
                      <tbody>
                        <tr>
                          <th scope="row" style={{ width: "140px" }}>
                            First Name
                          </th>
                          <td>{first_name}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ width: "140px" }}>
                            Last Name
                          </th>
                          <td>{last_name}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ width: "140px" }}>
                            Organization Name
                          </th>
                          <td>
                            {organization_name
                              ? organization_name
                              : "No Organization Aasigned"}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row" style={{ width: "140px" }}>
                            Email
                          </th>
                          <td>{email && email}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ width: "140px" }}>
                            Address
                          </th>
                          <td>{address ? address : "Address not added"}</td>
                        </tr>

                        <tr>
                          <th scope="row" style={{ width: "140px" }}>
                            Subscription Plan
                          </th>
                          <td>
                            {subscription
                              ? "Premium Package"
                              : "Basic Package"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ width: "140px" }}>
                            Avater
                          </th>
                          <td>
                            {" "}
                            {auth.avatar ? (
                              <img
                                src={
                                  auth.avatar
                                    ? getAvatar(auth.avatar)
                                    : avatar_all
                                }
                                alt="Admin"
                                width="150"
                              />
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            {/* <div
              className={`user__panel--tab-content ${getActiveClass(
                2,
                "active-content"
              )}`}
            >
              <Subscription subscription={subscription} />
            </div> */}
            <div
              className={`user__panel--tab-content ${getActiveClass(
                2,
                "active-content"
              )}`}
            >
              <ProjectStorage />
            </div>
            {/* <div
              className={`user__panel--tab-content ${getActiveClass(
                4,
                "active-content"
              )}`}
            >
              <Billing />
            </div>
            <div
              className={`user__panel--tab-content ${getActiveClass(
                5,
                "active-content"
              )}`}
            >
              <CreditPurchase />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContentCard;
