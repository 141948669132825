import request from "../utils/request";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_REST_HOST;
const AUTH = "Bearer " + JSON.parse(localStorage.getItem("access_token"));

export const fetchAssetSpecs = (pguid, aguid) => async (dispatch) => {
  try {
    const { data } = await request.get(
      `/api/projects/${pguid}/assets/${aguid}/specifications/`
    );

    dispatch({
      type: "SET_ASSET_SPECS_MODAL_DATA",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "FAILED_TO_FETCH_MODAL_DATA",
    });
  }
};

export const fetchPipelineSpecs = (pguid, plguid) => async (dispatch) => {
  try {
    const { data } = await request.get(
      `/api/projects/${pguid}/pipelines/${plguid}/specifications/`
    );

    dispatch({
      type: "SET_PIPELINE_SPECS_MODAL_DATA",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "FAILED_TO_FETCH_MODAL_DATA",
    });
  }
};

// export const fetchComponentSpecs = (pguid, aguid, cguid) => async (dispatch) => {
export const fetchComponentSpecs = (componentData) => async (dispatch) => {
  let projectGuid = JSON.parse(componentData).project_guid
  let assetGuid = JSON.parse(componentData).project_guid
  let pipelineGuid = JSON.parse(componentData).pipeline_guid
  let urlParam = JSON.parse(componentData).url_param
  let componentGuid = JSON.parse(componentData).component_guid
  
    try {
      const { data } = await request.get(
        // `/api/projects/${pguid}/assets/${aguid}/components/${cguid}/specifications/`
        `/api/projects/${projectGuid}/${urlParam}/${urlParam === "assets" ? assetGuid : pipelineGuid}/components/${componentGuid}/specifications/`
      );
      dispatch({
        type: "SET_COMPONENT_SPECS_MODAL_DATA",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "FAILED_TO_FETCH_MODAL_DATA",
      });
    }
  };

export const getProjectTreeInfo = (pguid) => async () => {
  let url = `${BASE_URL}/api/projects/${pguid}/field_tree/`;
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  });
};

export const handleBasicDataSelection =
  (label, key, newValue, elementIndex) => (dispatch) => {
    dispatch({
      type: "ASSET_SPEC_BASIC_DATA_CHANGE",
      payload: { label, key, newValue, elementIndex },
    });
  };

export const handlePipelineBasicDataSelection =
  (label, key, newValue, elementIndex) => (dispatch) => {
    dispatch({
      type: "PIEPLINE_SPEC_BASIC_DATA_CHANGE",
      payload: { label, key, newValue, elementIndex },
    });
  };

export const handleComponentBasicDataSelection =
  (label, key, newValue, elementIndex) => (dispatch) => {
    dispatch({
      type: "COMPONENT_SPEC_BASIC_DATA_CHANGE",
      payload: { label, key, newValue, elementIndex },
    });
  };

export const handleSpecsDataChange =
  (label, key, newValue, elementIndex) => (dispatch) => {
    dispatch({
      type: "ASSET_SPECS_DATA_CHANGE",
      payload: { label, key, newValue, elementIndex },
    });
  };

export const handlePipelineSpecsDataChange =
  (label, key, newValue, elementIndex) => (dispatch) => {
    dispatch({
      type: "PIPELINE_SPECS_DATA_CHANGE",
      payload: { label, key, newValue, elementIndex },
    });
  };

export const handleComponentSpecsDataChange =
  (label, key, newValue, elementIndex) => (dispatch) => {
    dispatch({
      type: "COMPONENT_SPECS_DATA_CHANGE",
      payload: { label, key, newValue, elementIndex },
    });
  };

export const projectExternalSync = async (api) => {
  await request
    .post(api, {})
    .then((resp) => {
      window.open(resp.data.external_url, "_blank");
    })
    .catch((err) => {
      // console.log(err);
    });
};
