import React from "react";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { getDefaultChartData } from "./defaultChartData";
import { generateChartLayout } from "./layout/layout.default";
import { generatePieChartLayout } from "./layout/layout_pie.default";
import { generateBarChartLayout } from "./layout/layout_bar.default";
import { generateStackedBarChartLayout } from "./layout/layout_stacked_bar.default";
import { generateStackedBarColumnChartLayout } from "./layout/layout_stacked_bar_column.default";
import { generateGagueChartLayout } from "./layout/layout_gague.default";
import { generateDonutChartLayout } from "./layout/layout_donut.default";
import { generateMeshChartLayout } from "./layout/layout_mesh.default";
import MeshImg from "./layout/imgs/mesh.png";
import DensityMap from "./layout/imgs/density_mapbox.png";
import DefaultMap from "./DefaultMap";
import ProfileGroup from "./ProfileGroup/ProfileGroup";

const Plot = createPlotlyComponent(Plotly);

const DefaultChart = ({ panel, chart_type }) => {
  const getChartType = () => {
    switch (chart_type) {
      case "line":
        return (
          <Plot
            data={getDefaultChartData("line_chart").data}
            layout={generateChartLayout(
              getDefaultChartData("line_chart").layout,
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "3d_line":
        return (
          <Plot
            data={getDefaultChartData("line_chart").data}
            layout={generateChartLayout(
              getDefaultChartData("line_chart").layout,
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "bar":
        return (
          <Plot
            data={getDefaultChartData("bar_chart").data}
            layout={generateBarChartLayout(
              getDefaultChartData("bar_chart").layout,
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "stacked_bar_chart":
        return (
          <Plot
            data={getDefaultChartData("stacked_bar_chart").data}
            layout={generateStackedBarChartLayout(
              getDefaultChartData("stacked_bar_chart").layout,
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "stacked_column_chart":
        return (
          <Plot
            data={getDefaultChartData("stacked_column_chart").data}
            layout={generateStackedBarColumnChartLayout(
              getDefaultChartData("stacked_bar_chart").layout,
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "gague_chart":
        return (
          <Plot
            data={getDefaultChartData("gague_chart").data}
            layout={generateGagueChartLayout(
              getDefaultChartData("gague_chart").layout,
              panel.panel_config
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "donut_chart":
        return (
          <Plot
            data={getDefaultChartData("donut_chart").data}
            layout={generateDonutChartLayout(
              getDefaultChartData("gague_chart").layout,
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "pie":
        return (
          <Plot
            data={getDefaultChartData("pie_chart").data}
            layout={generatePieChartLayout(
              getDefaultChartData("pie_chart").layout,
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );

      case "mesh":
        return (
          <img
            className="w-100"
            src={MeshImg}
            alt="mesh"
            style={{ height: "95%" }}
          />
        );

      case "profile_group":
        return <ProfileGroup panel={panel} />;
      case "mapbox_density_heatmap":
        return (
          <img
            className="w-100"
            src={DensityMap}
            alt="mesh"
            style={{ height: "95%" }}
          />
        );
      case "bubble_map":
        return <DefaultMap panel={panel} />;
      default:
        return (
          <Plot
            data={[
              {
                x: [1, 2, 3, 4],
                y: [10, 15, 13, 17],
                type: "scatter",
              },
              {
                x: [1, 2, 3, 4],
                y: [16, 5, 11, 9],
                type: "scatter",
              },
            ]}
            layout={generateChartLayout(
              {
                chart_name: "Line Chart",
                x_axis_label: "Label X",
                y_axis_label: "Label Y",
              },
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
    }
  };

  return <>{getChartType()}</>;
};

export default DefaultChart;
