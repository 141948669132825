import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatasetColumns,
  updateCustomDashboardPanel,
} from "../../../../../actions/customPanelModify";

function MeshFilter({ panel, filterMinMaxVal, isLoaderOn }) {
  const dispatch = useDispatch();

  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const [datasetColumnList, setDatasetColumnList] = useState(null);

  const [filter, setFilter] = useState(null);
  const [range, setRange] = useState({
    min: null,
    min: null,
  });
  const [rangePlaceHolder, setRangePlaceHolder] = useState({
    min: null,
    min: null,
  });

  useEffect(() => {
    if (panel.panel_config.data) {
      if (panel.panel_config.bar_data.chart_config.filter_by) {
        // console.log(panel.panel_config.bar_data.chart_config.filter_by.col);
        setFilter(
          JSON.stringify(panel.panel_config.bar_data.chart_config.filter_by.col)
        );
        setRange(panel.panel_config.bar_data.chart_config.filter_by.range);
        if (document.getElementById("mesh_filter_selection")) {
          document.getElementById("mesh_filter_selection").value =
            JSON.stringify(
              panel.panel_config.bar_data.chart_config.filter_by.col
            );
        }
      }
      // // SELEC DATASET
      // datasetList.forEach((dataset, index) => {
      //   if (panel.panel_config.data.dataset === dataset.guid) {
      //     dispatch(
      //       getDatasetColumns(
      //         panelFieldInfo.panel_type,
      //         panelFieldInfo.panel_type_guid,
      //         dataset.guid
      //       )
      //     )
      //       .then((res) => {
      //         // console.log(res.data);
      //         // setDatasetColumnList(res.data.data);
      //         setTimeout(() => {
      //           if (panel.panel_config.bar_data.chart_config.filter_by) {
      //             document.getElementById("mesh_filter_selection").value =
      //               JSON.stringify(
      //                 panel.panel_config.bar_data.chart_config.filter_by.col
      //               );
      //           }
      //         }, 2000);
      //       })
      //       .catch((err) => {
      //         // console.log(err);
      //       });
      //   }
      // });
      // }, 1000);
      // // console.log(Object.keys(panel.panel_config.bar_data.chart_config));
      let arr_ = [];
      let obj_ = panel.panel_config.bar_data.chart_config;
      Object.keys(obj_).forEach((k, i_) => {
        if (k === "x" || k === "y" || k === "z") {
          // console.log(Object.values(obj_)[i_]);
          arr_.push(Object.values(obj_)[i_]);
        }
      });
      setDatasetColumnList(arr_);

      setTimeout(() => {
        if (panel.panel_config.bar_data.chart_config.filter_by) {
          document.getElementById("mesh_filter_selection").value =
            JSON.stringify(
              panel.panel_config.bar_data.chart_config.filter_by.col
            );
        }
      }, 1000);
    }
  }, [isLoaderOn]);

  // useEffect(() => {
  //   // // console.log(filterMinMaxVal)
  //   // // console.log(panel.panel_config.bar_data.chart_config.filter_by)
  //   if (panel.panel_config.bar_data.chart_config.filter_by) {
  //     filterMinMaxVal.forEach((e) => {
  //       if (
  //         e.col.col_name ===
  //         JSON.parse(panel.panel_config.bar_data.chart_config.filter_by.col)
  //           .col_name
  //       ) {
  //         // console.log(e.min + "    " + e.max);
  //         setRangePlaceHolder({
  //           min: e.min,
  //           max: e.max,
  //         });
  //       }
  //     });
  //   }
  // }, [filterMinMaxVal, datasetColumnList]);

  useEffect(() => {
    setRangePlaceHolder({
      min: null,
      max: null,
    });
    if (filterMinMaxVal && filter) {
      Object.keys(filterMinMaxVal).forEach((f, i_) => {
        if (f.toString() === JSON.parse(filter).col_name.toString()) {
          setRangePlaceHolder({
            min: Object.values(filterMinMaxVal)[i_].min,
            max: Object.values(filterMinMaxVal)[i_].max,
          });
        }
      });
    }
  }, [filterMinMaxVal, filter]);

  const applyDatasetClickHandler = () => {
    // console.log(filter);
    // console.log(range);
    panel.panel_config.bar_data.chart_config.filter_by = filter
      ? {
          col: JSON.parse(filter),
          range: range,
        }
      : null;
    dispatch(
      updateCustomDashboardPanel(
        activeTabData.guid,
        panel.guid,
        panel.panel_config
      )
    );
  };

  return (
    <>
      <div className="mesh_filter">
        <div className="mesh_filter--block">
          <div className="row form__row">
            <div className="col-md-3">
              <div className="form__group">
                <select
                  className="custom-select"
                  id="mesh_filter_selection"
                  // value={filter ? filter.col_name : ""}
                  onChange={(e) => {
                    e.stopPropagation();
                    //   setFilter(JSON.parse(e.target.value));
                    if (JSON.parse(e.target.value).col_name === "none") {
                      setFilter(null);
                      setRange({
                        min: null,
                        max: null,
                      });
                    } else {
                      // setFilter(JSON.parse(e.target.value));
                      setFilter(e.target.value);
                      setRange({
                        min: null,
                        max: null,
                      });
                    }
                    // console.log(JSON.parse(e.target.value));
                  }}
                >
                  <option disabled value="" selected>
                    Please Select
                  </option>
                  <option value={JSON.stringify({ col_name: "none" })}>
                    None
                  </option>
                  {datasetColumnList ? (
                    <>
                      {datasetColumnList.map((col, index) => (
                        <>
                          <option value={JSON.stringify(col)} key={index}>
                            {col.col_name}
                          </option>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form__group">
                <input
                  type="number"
                  className="form-control form__control"
                  placeholder={
                    rangePlaceHolder
                      ? rangePlaceHolder.min
                        ? rangePlaceHolder.min
                        : "Min value"
                      : "Min value"
                  }
                  value={range ? (range.min ? range.min : "") : ""}
                  onChange={(e) => {
                    e.stopPropagation();
                    setRange({
                      ...range,
                      min: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form__group">
                <input
                  type="number"
                  className="form-control form__control"
                  placeholder={
                    rangePlaceHolder
                      ? rangePlaceHolder.max
                        ? rangePlaceHolder.max
                        : "Max value"
                      : "Max value"
                  }
                  value={range ? (range.max ? range.max : "") : ""}
                  onChange={(e) => {
                    e.stopPropagation();
                    setRange({
                      ...range,
                      max: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <button
                className="btn modal__submit_btn data-source_btn btn__secondary"
                //   disabled={!filter}
                onClick={() => {
                  applyDatasetClickHandler();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MeshFilter;
