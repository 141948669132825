export const generateChartLayout = (chart, parentComponent) => ({
  autosize: true,
  // margin: {
  //   // l: 50,
  //   // r: 50,
  //   b: 5,
  //   t: 5,
  //   // pad: 10,
  // },
  margin: {
    l: 50,
    r: 10,
    b: 10,
    t: 10,
    // pad: 10,
  },
  // height: 180,

  paper_bgcolor: "lightblue",
  plot_bgcolor: "#ddd",

  legend: {
    x: 1,
    y: 1.3,
    xanchor: "right",
    // xanchor: "center",
    orientation: "h", //To place the legend below chart
    font: {
      family: "Arial, sans-serif",
      size: 10,
      color: "white",
    },
  },
  // title: {
  //   text: chart.chart_name,
  //   font: {
  //     family: "sans-serif",
  //     size: 12,
  //     color: "#fff",
  //   },
  //   x: 0.5,
  //   xanchor: "center",
  //   y: 0.98,
  //   yanchor: "top",
  //   pad: {
  //     // l: 50,
  //     // r: 50,
  //     // b: 5,
  //     t: 5,
  //     // pad: 10,
  //   },
  // },
  xaxis: {
    automargin: true,
    // margin: {
    //   // l: 0,
    //   // r: 0,
    //   // b: -20,
    //   t: 10,
    //   // pad: 10,
    // },
    zeroline: true,
    showline: true,
    zerolinecolor: "#00FFFB",
    zerolinewidth: 2,
    gridcolor: "#004B54",
    griddash: "dot",
    gridwidth: 1,
    // tickwidth: 15,
    tickcolor: "#B9B8CE",
    tickfont: {
      family: "sans-serif",
      size: 12,
      color: "#01E3FE",
    },
    title: {
      standoff: parentComponent === 'WEBGL_COMPONENT_CHART' ? 5 : 10, //Similar as Margin Top
      text: chart.x_axis_label,
      font: {
        family: "sans-serif",
        size: 12,
        color: "#fff",
      },
    },
  },
  yaxis: {
    automargin: true,
    zeroline: true,
    showline: true,
    zerolinecolor: "#00FFFB",
    zerolinewidth: 2,
    gridcolor: "#004B54",
    griddash: "dot",
    gridwidth: 1,
    // tickwidth: 15,
    tickcolor: "#B9B8CE",
    tickfont: {
      family: "sans-serif",
      size: 12,
      color: "#01E3FE",
    },
    title: {
      text: chart.y_axis_label,
      font: {
        family: "sans-serif",
        size: 12,
        color: "#fff",
      },
    },
  },
});
