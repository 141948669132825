import React from "react";
import BigDataTableField from "./bigDataTableField";
import BarChartUpdateModal from "./chart_bar";
import GagueChartUpdateModal from "./chart_gague";
import LineChartUpdateModal from "./chart_line";
import PieChartUpdateModal from "./chart_pie";
import MeshChartUpdateModal from "./chart_mesh";
import MapboxDensityHeatmapChartUpdateModal from "./chart_mapbox_density_heatmap";
import DataTableUpdate from "./dataTableUpdate";
import IndicatorValueUpdate from "./IndicatorValueUpdate";
import DashboardResourceList from "./ResourceList";
import IndicatorStyle from "./style/indicator";
import BubbleMapUpdateModal from "./babble_map";
import ProfileGroupUpdateModal from "./profile_group";

const PanelModals = () => {
  return (
    <React.Fragment>
      <IndicatorValueUpdate />
      <IndicatorStyle />
      <DataTableUpdate />
      <LineChartUpdateModal />
      <BarChartUpdateModal />
      <PieChartUpdateModal />
      <MeshChartUpdateModal />
      <MapboxDensityHeatmapChartUpdateModal />
      <GagueChartUpdateModal />
      <BigDataTableField />
      <DashboardResourceList />
      <BubbleMapUpdateModal />
      <ProfileGroupUpdateModal />
    </React.Fragment>
  );
};

export default PanelModals;
