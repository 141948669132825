import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

const NumaricalFilter = ({
  bar_data,
  selectedFilteredValue,
  setSelectedFilteredValue,
  addFilter_columnData,
  selectedFilteredValue_type,
  setSelectedFilteredValueType,
}) => {
  const [type_, setType] = useState("range");

  useEffect(() => {
    // console.log(bar_data);
    if (bar_data && bar_data.selected_filter_col_value_type) {
      document.getElementById("__numaric__filter__type__").value =
        bar_data.selected_filter_col_value_type;
      setType(bar_data.selected_filter_col_value_type);
      setSelectedFilteredValueType(bar_data.selected_filter_col_value_type);

      setTimeout(() => {
        setRangeValues();
      }, 500);
    } else if (bar_data && bar_data.chart_type_api_sep === "mapbox_density_heatmap") {
      document.getElementById("__numaric__filter__type__").value =
        bar_data.filterMapbox.selectedFilteredValue_type;
      setType(bar_data.filterMapbox.selectedFilteredValue_type);
      setSelectedFilteredValueType(
        bar_data.filterMapbox.selectedFilteredValue_type
      );

      setTimeout(() => {
        setRangeValues_MAPBOX();
      }, 500);
    }
  }, [bar_data]);

  const setRangeValues = () => {
    if(bar_data.selected_filter_col_value_type === "range") {
        if(document.getElementById("__numaric__filter__type__RANGE_FIRST"))
            document.getElementById("__numaric__filter__type__RANGE_FIRST").value = bar_data.selectedFilteredValue[0] ? bar_data.selectedFilteredValue[0] : "";
            document.getElementById("__numaric__filter__type__RANGE_LAST").value = bar_data.selected_filter_col_value[1] ? bar_data.selected_filter_col_value[1] : "";
    } else if (bar_data.selected_filter_col_value_type === "greater") {
        if(document.getElementById("__numaric__filter__type__MIN_MAX"))
            document.getElementById("__numaric__filter__type__MIN_MAX").value = bar_data.selected_filter_col_value[0] ? bar_data.selected_filter_col_value[0] : "";
    } else if (bar_data.selected_filter_col_value_type === "less") {
        if(document.getElementById("__numaric__filter__type__MIN_MAX"))
            document.getElementById("__numaric__filter__type__MIN_MAX").value = bar_data.selected_filter_col_value[1] ? bar_data.selected_filter_col_value[1] : "";
    } else if (bar_data.selected_filter_col_value_type === "equal") {
        if(document.getElementById("__numaric__filter__type__MIN_MAX"))
            document.getElementById("__numaric__filter__type__MIN_MAX").value = bar_data.selected_filter_col_value[0] ? bar_data.selected_filter_col_value[0] : "";
    }
  }

  const setRangeValues_MAPBOX = () => {
    if(bar_data.filterMapbox.selectedFilteredValue_type === "range") {
        if(document.getElementById("__numaric__filter__type__RANGE_FIRST"))
            document.getElementById("__numaric__filter__type__RANGE_FIRST").value = bar_data.filterMapbox.selectedFilteredValue[0] ? bar_data.filterMapbox.selectedFilteredValue[0] : "";
            document.getElementById("__numaric__filter__type__RANGE_LAST").value = bar_data.filterMapbox.selectedFilteredValue[1] ? bar_data.filterMapbox.selectedFilteredValue[1] : "";
    } else if (bar_data.filterMapbox.selectedFilteredValue_type === "greater") {
        if(document.getElementById("__numaric__filter__type__MIN_MAX"))
            document.getElementById("__numaric__filter__type__MIN_MAX").value = bar_data.filterMapbox.selectedFilteredValue[0] ? bar_data.filterMapbox.selectedFilteredValue[0] : "";
    } else if (bar_data.filterMapbox.selectedFilteredValue_type === "less") {
        if(document.getElementById("__numaric__filter__type__MIN_MAX"))
            document.getElementById("__numaric__filter__type__MIN_MAX").value = bar_data.filterMapbox.selectedFilteredValue[1] ? bar_data.filterMapbox.selectedFilteredValue[1] : "";
    } else if (bar_data.filterMapbox.selectedFilteredValue_type === "equal") {
        if(document.getElementById("__numaric__filter__type__MIN_MAX"))
            document.getElementById("__numaric__filter__type__MIN_MAX").value = bar_data.filterMapbox.selectedFilteredValue[0] ? bar_data.filterMapbox.selectedFilteredValue[0] : "";
    }
  };

  useEffect(() => {
    if (type_ === "range") {
      document.getElementById("__numaric__filter__type__RANGE_FIRST").value =
        "";
      document.getElementById("__numaric__filter__type__RANGE_LAST").value = "";
    } else {
      document.getElementById("__numaric__filter__type__MIN_MAX").value = "";
    }
    setSelectedFilteredValue([]);
  }, [type_]);

  const checkType = (t) => {
    if (t === "range") {
      return (
        <>
          <div className="col-md-2 pl-1 pr-2">
            <div className="form__group">
              <label>Min</label>
            </div>
            <div className="form__group">
              <input
                type="text"
                className="form-control form__control"
                id="__numaric__filter__type__RANGE_FIRST"
                placeholder={addFilter_columnData[0]}
                onChange={(e) => {
                  e.stopPropagation();
                  let sfv = Object.assign([], selectedFilteredValue);
                  if (sfv.length) {
                    sfv[0] = parseFloat(e.target.value);
                  } else {
                    sfv.push(parseFloat(e.target.value));
                  }
                  setSelectedFilteredValue(sfv.filter((el, i) => i < 2));
                }}
              />
            </div>
          </div>
          <div className="col-md-2 pl-1">
            <div className="form__group">
              <label>Max</label>
            </div>
            <div className="form__group">
              <input
                type="text"
                className="form-control form__control"
                id="__numaric__filter__type__RANGE_LAST"
                placeholder={
                  addFilter_columnData[addFilter_columnData.length - 1]
                }
                onChange={(e) => {
                  e.stopPropagation();
                  let sfv = Object.assign([], selectedFilteredValue);
                  if (sfv.length) {
                    sfv[1] = parseFloat(e.target.value);
                  } else {
                    sfv.push(parseFloat(e.target.value));
                  }
                  setSelectedFilteredValue(sfv.filter((el, i) => i < 2));
                }}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="col-md-4 pl-1 pr-4">
            <div className="form__group">
              <label>Value</label>
            </div>
            <div className="form__group">
              <input
                type="text"
                className="form-control form__control"
                id="__numaric__filter__type__MIN_MAX"
                placeholder={
                  type_ !== "equal"
                    ? "Min: " +
                      addFilter_columnData[0] +
                      ", Max: " +
                      addFilter_columnData[addFilter_columnData.length - 1]
                    : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  let sfv = Object.assign([], selectedFilteredValue);
                  if (sfv.length) {
                    if (t === "greater") {
                      sfv[0] = parseFloat(e.target.value);
                      sfv[1] = parseFloat(
                        addFilter_columnData[addFilter_columnData.length - 1]
                      );
                    } else if (t === "less") {
                      sfv[0] = parseFloat(addFilter_columnData[0]);
                      sfv[1] = parseFloat(e.target.value);
                    } else if (t === "equal") {
                      sfv[0] = parseFloat(e.target.value);
                      sfv[1] = parseFloat(e.target.value);
                    }
                  } else {
                    if (t === "greater") {
                      sfv.push(parseFloat(e.target.value));
                      sfv.push(
                        parseFloat(
                          addFilter_columnData[addFilter_columnData.length - 1]
                        )
                      );
                    } else if (t === "less") {
                      sfv.push(parseFloat(addFilter_columnData[0]));
                      sfv.push(parseFloat(e.target.value));
                    } else if (t === "equal") {
                      sfv.push(parseFloat(e.target.value));
                      sfv.push(parseFloat(e.target.value));
                    }
                  }
                  setSelectedFilteredValue(sfv.filter((el, i) => i < 2));
                }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="col-md-4 pl-1 pr-2">
        <div className="form__group">
          <label>Type</label>
        </div>

        <div className="form__group">
          <select
            className="custom-select"
            id="__numaric__filter__type__"
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setType(e.target.value);
              setSelectedFilteredValueType(e.target.value);
            }}
          >
            <option value={"range"} selected>
              Value Range
            </option>
            <option value={"greater"}>Greater than</option>
            <option value={"less"}>less than</option>
            <option value={"equal"}>Equal to</option>
          </select>
        </div>
      </div>
      {checkType(type_)}
    </>
  );
};

export default NumaricalFilter;
