import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getIconFromList } from "../../../static/IconServe";
import CSVModal from "./CSVModal";
import PostgreSQLModal from "./PostgreSQLModal";
import toast from "toastr";
import { s3UploaderObject } from "../../../utils";
import {
  deleteDatasetRecord,
  postDatasetimportDataCSV,
} from "../../../actions/customPanelModify";
import MongoDBModal from "./MongoDBModal";

const AddDataset = (props) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const animLoaderFlag = useSelector(
    (state) => state.customPanelModify.datasetUploaderAnimationFlag
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );

  const [inputDataName, setInputDataName] = useState("");
  const [inputDataName_source, setInputDataNameSource] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setInputDataNameSource(false);
  }, []);

  const handleFileUpload = async (files, type) => {
    dispatch({
      type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
      payload: true,
    });
    s3UploaderObject(files[0], panelFieldInfo.project_guid)
      .then(async (data) => {
        if (type === "CSV") {
          dispatch(
            postDatasetimportDataCSV(
              panelFieldInfo.panel_type,
              panelFieldInfo.panel_type_guid,
              {
                csv_link: data.data.data.file,
                data_name:
                  inputDataName === ""
                    ? files[0].name.replace(/\.[^.$]+$/, "")
                    : inputDataName,
              }
            )
          );
          setInputDataName("");
          document.getElementById("upload-csv-file-input").value = "";
          dispatch({
            type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
            payload: false,
          });
          toast.success("CSV successfully uploaded.");
        } else if (type === "python") {
          // alert("python");
        }
        document
          .getElementById("new-dataset-up-modal-close-btn-custom-panel-csv")
          .click();
        document
          .getElementById("new-dataset-input-modal-close-icon-custom-panel-csv")
          .click();
        dispatch({
          type: "UPDATE_DATASET_UPLOAD_MODAL_STATUS",
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
          payload: false,
        });
        toast.error("Upload failed: " + err);
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id="new-dataset-input-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          style={{ maxWidth: "850px" }}
        >
          <div className="modal-content">
            {/* HEADER */}
            <div className="modal-header">
              <h5 className="modal-title">Add New Dataset</h5>
              <span
                className="panel-remove-icon"
                id="new-dataset-input-modal-close-icon-custom-panel-csv"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setInputDataName("");
                  setInputDataNameSource(false);
                }}
              >
                {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
              </span>
            </div>

            <div className="modal-body">
              <div className="add-data_section">
                <div className="row add__dataset--row mb-2">
                  <div className="col-md-4">
                    <textarea
                      className="form-control rounded-0 mb-2 form-control-dataname"
                      type="text"
                      autoComplete="off"
                      placeholder="Data Name"
                      value={inputDataName}
                      onChange={(e) => {
                        e.preventDefault();
                        setInputDataName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="dataset__select__dropdown">
                      <textarea
                        className="form-control rounded-0 mb-2 form-control-dataname dataset__select__list"
                        type="text"
                        autoComplete="off"
                        placeholder="Source"
                        readOnly
                        onClick={(e) => {
                          e.preventDefault();
                          inputDataName_source
                            ? setInputDataNameSource(false)
                            : setInputDataNameSource(true);
                        }}
                      />
                      {inputDataName_source ? (
                        <ul className="nav nav-pills d-inline dataset__select__dropdown--menu">
                          <li className="mb-1">
                            <label
                              htmlFor="upload-csv-file-input"
                              className="label__dropdown__option justify-content-start"
                            >
                              <input
                                type="file"
                                className="form-control"
                                id="upload-csv-file-input"
                                autoComplete="off"
                                multiple={false}
                                accept=".csv"
                                onChange={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  // setLoader(true);
                                  dispatch({
                                    type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
                                    payload: true,
                                  });
                                  handleFileUpload(e.target.files, "CSV");
                                }}
                              />
                              <span className="label__dropdown__option--text">
                                CSV
                              </span>
                            </label>
                          </li>
                          <li className="mb-1">
                            <a
                              className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                              href="#!"
                              data-bs-toggle="modal"
                              data-bs-target="#postgreModal"
                            >
                              PostGreSQL
                            </a>
                          </li>
                          <li className="mb-1">
                            <a
                              className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                              href="#!"
                              data-bs-toggle="modal"
                              data-bs-target="#mongoDBModal"
                            >
                              MongoDB
                              {/* {getIconFromList("FILE_LOCK")} */}
                            </a>
                          </li>
                          <li className="mb-1">
                            <a
                              className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                              href="#!"
                            >
                              AWS S3
                              {getIconFromList("FILE_LOCK")}
                            </a>
                          </li>
                          <li className="mb-1">
                            <a
                              className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                              href="#!"
                            >
                              Azure Blob
                              {getIconFromList("FILE_LOCK")}
                            </a>
                          </li>
                          <li className="mb-1">
                            <a
                              className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                              href="#!"
                            >
                              OSI PI
                              {getIconFromList("FILE_LOCK")}
                            </a>
                          </li>
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table__atwin--default table__add__dataset table-fixed">
                    <thead>
                      <tr>
                        <th className="text-left">Data Name</th>
                        <th className="text-center">Updated at</th>
                        <th className="text-center">Data Type</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {datasetList.length > 0 ? (
                        datasetList.map((dataset, index) => (
                          <tr key={index}>
                            <td className="text-left">{dataset.name}</td>
                            <td className="text-center">
                              {new Date(dataset.updated_at).getFullYear()}-
                              {parseInt(
                                new Date(dataset.updated_at).getMonth()
                              ) + 1}
                              -{new Date(dataset.updated_at).getDate()}
                            </td>
                            <td className="text-center">{dataset.source}</td>
                            <td className="text-right">
                              <span
                                className="panel-remove-icon"
                                style={{
                                  border: "1px solid #830124",
                                  borderRadius: "50%",
                                  width: "24px",
                                  height: "24px",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  dispatch({
                                    type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
                                    payload: true,
                                  });
                                  dispatch(
                                    deleteDatasetRecord(
                                      panelFieldInfo.panel_type,
                                      panelFieldInfo.panel_type_guid,
                                      dataset.guid
                                    )
                                  );
                                }}
                              >
                                {getIconFromList("DELETE", {
                                  cursor: "pointer",
                                })}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CSVModal
        inputDataName={inputDataName}
        handleFileUpload={handleFileUpload}
        progress={progress}
      />
      <PostgreSQLModal
        inputDataName={inputDataName}
        setInputDataName={setInputDataName}
        panelFieldInfo={panelFieldInfo}
      />
      <MongoDBModal
        inputDataName={inputDataName}
        setInputDataName={setInputDataName}
        panelFieldInfo={panelFieldInfo}
      />

      {animLoaderFlag ? (
        <div
          className="loader__top_with_logo loader__no_bottom__space loader analysis_runing_img"
          style={{ position: "fixed", left: "48%", top: "48%", zIndex: "9999" }}
        >
          <div className="inner one"></div>
          <div className="inner two"></div>
          <div className="inner three"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddDataset);
