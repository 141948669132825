//Don't use it for frontend
window.isWebGLCapturingKeyboard = false;

/**
 *
 * @param {int} isActivate - isActivate 0 means false, 1 means true
 */
window.SetWebGLKeyboardCaptureStatus = (isActivate) => {
  window.unityContext.send(
    "WebGLManager",
    "SetKeyboardCaptureStatus",
    isActivate
  );
};

// CreateAvatar(string userDataJson)
/**
 *
 * @param {int} isActivate - isActivate 0 means false, 1 means true
 */

const webGLFullScreen = (isActivate) => {
  if (isActivate === 1) {
    window.gameInstance.setFullscreen(true);
  } else if (isActivate === 0) {
    window.gameInstance.setFullscreen(false);
  }
};

window.closeWebGLViewerModal = (closeModalId, closeBtnElementId) => {
  if (document.getElementById(closeModalId)) {
    document.getElementById(closeBtnElementId).click();
  }
};

window.webGLFullScreen = webGLFullScreen;
