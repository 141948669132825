import axios from "axios";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";

const baseURL = process.env.REACT_APP_REST_HOST;

const axiosInstance = axios.create({
  baseURL,
});

// let access_token = localStorage.getItem("access_token");
// let refresh_token = localStorage.getItem("refresh_token");

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

let access_token = getAccessToken();
let refresh_token = getRefreshToken();

const req_timeout = 60 * 1000;

const request = () => {
  axiosInstance.interceptors.request.use(
    async (req) => {
      // // console.log(req);
      window.dispatch({
        type: "REQUEST_PENDING",
      });

      if (!access_token) {
        access_token = getAccessToken();
      }

      if (access_token) {
        req.headers["Authorization"] = "Bearer " + JSON.parse(access_token);
      }

      const user = jwtDecode(access_token);

      const isExpird = dayjs.unix(user.exp).diff(dayjs()) < 1;
      // // console.log("isExpird:", isExpird);

      if (!isExpird) return req;

      const { data } = await axios.post(baseURL + "/api/auth/token/refresh/", {
        refresh: refresh_token ? refresh_token : getRefreshToken(),
      });

      if (!data) window.location.reload();

      localStorage.setItem("access_token", JSON.stringify(data.access));
      localStorage.setItem("refresh_token", JSON.stringify(data.refresh));

      req.headers["Authorization"] = "Bearer " + JSON.parse(data.access);
      return req;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      if (process.env.REACT_APP_ENV === "dev") {
        setTimeout(() => {
          window.dispatch({
            type: "REQUEST_RESOLVED",
          });
        }, 2000);
        return response;
      } else {
        window.dispatch({
          type: "REQUEST_RESOLVED",
        });
        return response;
      }
    },
    async (error) => {
      if (process.env.REACT_APP_ENV === "dev") {
        setTimeout(() => {
          window.dispatch({
            type: "REQUEST_RESOLVED",
          });
        }, 2000);
        return Promise.reject(error);
      } else {
        window.dispatch({
          type: "REQUEST_RESOLVED",
        });
        return Promise.reject(error);
      }

      //refresh token implementation skipped for now
    }
  );

  return {
    get: (url) => {
      return axiosInstance.get(`${baseURL + url}`, { timeout: req_timeout });
    },
    post: (url, payload) => {
      return axiosInstance.post(`${baseURL + url}`, payload, {
        timeout: req_timeout,
      });
    },

    put: (url, payload) => {
      return axiosInstance.put(`${baseURL + url}`, payload, {
        timeout: req_timeout,
      });
    },
    patch: (url, payload) => {
      return axiosInstance.patch(`${baseURL + url}`, payload, {
        timeout: req_timeout,
      });
    },
    delete: (url) => {
      return axiosInstance.delete(`${baseURL + url}`, { timeout: req_timeout });
    },
  };
};

export default request();
