import thunk from "redux-thunk";
import logger from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";
import parentReducer from "./reducers";

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore() {
  return createStore(
    parentReducer,
    // reduxDevTools(applyMiddleware(logger, thunk))
    reduxDevTools(applyMiddleware(thunk))
  );
}

const store = configureStore();

export default store;
