import axios from "axios";

const BASE_URL = process.env.REACT_APP_REST_HOST;

const getToken = () => {
  return "Bearer " + JSON.parse(localStorage.getItem("access_token"));
};

export const searchChartByGuid = (chart_guid) => async (dispatch) => {
  let url = `${BASE_URL}/api/custom_dashboard/1234/panel/chart/${chart_guid}/`;
  return await axios.get(url, {
    headers: {
      Authorization: getToken(),
    },
  });
};
