import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";

const DefaultMap = ({ panel }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    let map = null;
    /* eslint import/no-webpack-loader-syntax: off */
    mapboxgl.workerClass =
      require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

    mapboxgl.accessToken =
      "pk.eyJ1IjoiZmhidGhlbm90cnRoc3RhciIsImEiOiJjbGZhcDdtbGQyaGx3M3lwcXNyb2NnN3RpIn0.y7qkhQJaalrMuemy8mZqEQ";

    map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-99.9018, 31.9686],
      zoom: 4,
      attributionControl: false,
    });

    window.addEventListener("resize", () => {
      if (map) {
        map.resize();
      }
    });

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [panel]);

  return (
    <div ref={mapContainerRef} style={{ width: "100%", height: "100%" }} />
  );
};

export default DefaultMap;
