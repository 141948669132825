import { useState, useEffect } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

export default function useAgora(client, members) {
  const appid = "4cdf07573c014c50901e4cab22180a8f";

  const [localAudioTrack, setLocalAudioTrack] = useState("");

  const [joinState, setJoinState] = useState(false);

  const [muteState, setMuteState] = useState(false);

  const [remoteUsers, setRemoteUsers] = useState([]);

  async function createLocalTracks() {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();

    setLocalAudioTrack(microphoneTrack);

    // microphoneTrack.isPlaying

    return [microphoneTrack];
  }

  async function join(channel, token, uid) {
    // console.log(uid);
    if (!client) return;
    const [microphoneTrack] = await createLocalTracks();

    await client.join(appid, channel, token, uid);
    await client.publish([microphoneTrack]);

    setJoinState(true);
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    await client.leave();
  }

  async function setMute(bool) {
    setMuteState(bool);
    await localAudioTrack.setMuted(bool);
  }

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    client.enableAudioVolumeIndicator();

    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      window.remuser = user;

      // Subscribe and play the remote audio track.
      if (mediaType == "audio") {
        user.audioTrack.play();
      }

      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };

    const handleUserUnpublished = (user) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };

    const handleUserJoined = (user) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };

    const handleUserLeft = (user) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };

    window.agoraClient = client;

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);
    client.on("volume-indicator", (volumes) => {
      volumes.forEach((volume) => {
        // console.log(`UID ${volume.uid} Level ${volume.level}`);
        window.unityContext.send("LiveManager", "IndicateSpeaker", JSON.stringify({
          uguid: volume.uid,
          vl: parseInt(volume.level),
        }));

        // if (options.uid == volume.uid && volume.level > 5) {

        // } else if (options.uid == volume.uid && volume.level < 5) {

        // if (options.uid != volume.uid && volume.level > 5) {

        // } else if (options.uid != volume.uid && volume.level < 5) {

        // }
      });
    });

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client, members]);

  return {
    localAudioTrack,
    joinState,
    leave,
    join,
    remoteUsers,
    setMute,
    muteState,
  };
}
