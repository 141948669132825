import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Table,
  Column,
  CellMeasurerCache,
  AutoSizer,
  InfiniteLoader,
} from "react-virtualized";
import DynamicColumnCell from "./TableComponents/DynamicColumn";
import request from "../../../../../utils/request";
import { useDispatch, useSelector } from "react-redux";
import { getTableDataList } from "../../../../../actions/customPanelModify";

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 25,
  defaultHeight: 40, //currently, this is the height the cell sizes to after calling 'toggleHeight'
});
const Cell = styled.div`
  height: ${(p) => p.height}px;
  white-space: normal;
  overflow: hidden;
  padding: 10px;
`;

// const BigDataTable = ({ rawData, data }) => {
const BigDataTable = (props) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );

  const [datas, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [inde, setInde] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const tableRef = useRef(null);

  const loadMore = ({ startIndex, stopIndex }) => {
    setInde(startIndex);
    if (inde !== startIndex) {
      setTimeout(() => {
        setPage(page + 1);
      }, 500);
    }

    // we need to return a promise
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  useEffect(() => {
    if (props.tableInfo.dataset_guid) {
      getRawData(props.tableInfo.dataset_guid);
    } else {
      setPage(1);
      setTotal(null);
    }

    return () => {};
  }, [page, props.tableInfo.dataset_guid]);

  window.reloadDataTablePanel = () => {
    getRawData(props.tableInfo.dataset_guid);
  }

  const getRawData = async (obj) => {
    let data_table_type = ""
    let dt = {
      guid: props.tableInfo.dataset_guid
    }
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    setLoading(true);
    dispatch(
      getTableDataList(data_table_type, props.tableInfo.panel_type, props.tableInfo.panel_type_guid, dt, page)
      ).then(res => {
        let da = [...res.data.data];
        let columns = [...res.data.columns];
        if (!total) {
          setTotal(res.data.total_rows);
        }
        setData([...datas, ...da]);
        if (cols.length === 0) {
          setCols(columns);
        }
        setLoading(false);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      }).catch(err => {
        // console.log(err)
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
  };

  const getRowHeight = ({ index }) => {
    return cache.getHeight(index);
  };

  const renderDynamic = (props) => {
    if (loading) {
      return <>...</>;
    }
    return (
      <DynamicColumnCell cache={cache} {...props}>
        <Cell height={getRowHeight(props.rowIndex)} className="column_center">
          {props.cellData}
        </Cell>
      </DynamicColumnCell>
    );
  };

  if (!props.tableInfo.dataset_guid) {
    return (
      <div
        className="panelInfo__unnamed_style"
        style={{
          height: "50vh",
        }}
      >
        <p>Please add a dataset..</p>
      </div>
    );
  }
  return (
    <div 
      style={{
        width: "80vw",
        height: "60vh",
      }}
    >
      {datas.length > 0 && datas.length && total && (
        <InfiniteLoader
          isRowLoaded={({ index }) => !!datas[index]}
          loadMoreRows={loadMore}
          rowCount={total}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  deferredMeasurementCache={cache}
                  // ref={registerChild}
                  ref={tableRef}
                  onRowsRendered={onRowsRendered}
                  headerHeight={40}
                  width={width < cols.length * 150 ? cols.length * 150 : width}
                  height={1200}
                  /* rowHeight={getRowHeight} */
                  rowHeight={40}
                  rowCount={datas.length}
                  rowGetter={({ index }) => datas[index]}
                >
                  {cols.map((col, i) => (
                    <Column
                      key={i}
                      label={col}
                      dataKey={col}
                      width={width * 0.1}
                      flexGrow={2}
                      cellRenderer={renderDynamic}
                    />
                  ))}
                </Table>
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      )}
    </div>
  );
};

export default BigDataTable;
