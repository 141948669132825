import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getIconFromList } from "../../../../static/IconServe";
import CSVModal from "../../../_CustomPanel_2/AddDataset/CSVModal";
import PostgreSQLModal from "../../../_CustomPanel_2/AddDataset/PostgreSQLModal";
import toast from "toastr";
import { s3UploaderObject } from "../../../../utils";
import { postDatasetimportDataCSV } from "../../../../actions/customPanelModify";

const AddDatasetWebGL = (props) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );
  const animLoaderFlag = useSelector( (state) => state.customPanelModify.datasetUploaderAnimationFlag );

  const [inputDataName, setInputDataName] = useState("");
  const [progress, setProgress] = useState(0);

  window.openWebglAddDatasetModal = (data) => {
    let dt = JSON.parse(data)
    if (!dt.project_guid) return;
    dispatch({
      type: "SET_PANEL_FIELD_INFO",
      payload: {
        panel_type: dt.panel_type,
        panel_type_guid: dt.panel_type_guid,
        project_guid: dt.project_guid,
        location: null,
      },
    });
    document.getElementById("new-dataset-input-modal-webgl-opener").click()
  }

  const handleFileUpload = async (files, type) => {
    dispatch({
      type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
      payload: true,
    });
    s3UploaderObject(files[0], panelFieldInfo.project_guid)
      .then(async (data) => {
        if (type === "CSV") {
          dispatch(
            postDatasetimportDataCSV(
              panelFieldInfo.panel_type,
              panelFieldInfo.panel_type_guid,
              {
                csv_link: data.data.data.file,
                data_name: inputDataName,
              }
            )
          );
          setInputDataName("");
          document.getElementById("upload-csv-file-input").value = "";
          dispatch({
            type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
            payload: false,
          });
          toast.success("CSV successfully uploaded.");
        } else if (type === "python") {
          // alert("python");
        }
        document
          .getElementById("new-dataset-up-modal-close-btn-custom-panel-csv")
          .click();
        document
          .getElementById("new-dataset-input-modal-close-icon-custom-panel-csv")
          .click();
        dispatch({
          type: "UPDATE_DATASET_UPLOAD_MODAL_STATUS",
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
          payload: false,
        });
        toast.success("Upload failed: " + err);
      });
  };

  return (
    <>
      <button
        id="new-dataset-input-modal-webgl-opener"
        data-bs-toggle="modal"
        data-bs-target="#new-dataset-input-modal-webgl"
        style={{display: "none"}}
      >
      </button>
      <div
        className="modal fade"
        id="new-dataset-input-modal-webgl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {/* HEADER */}
            <div className="modal-header">
              <h5 className="modal-title">Upload New Dataset</h5>
              <span
                className="panel-remove-icon"
                id="new-dataset-input-modal-close-icon-custom-panel-csv"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setInputDataName("");
                }}
              >
                {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
              </span>
            </div>

            <div className="modal-body">
              <div className="add-data_section">
                <textarea
                  className="form-control rounded-0 mb-2 form-control-dataname"
                  type="text"
                  autoComplete="off"
                  placeholder="Data Name"
                  value={inputDataName}
                  onChange={(e) => {
                    e.preventDefault();
                    setInputDataName(e.target.value);
                  }}
                />
                {inputDataName ? (
                  <ul className="nav nav-pills d-inline">
                    <li className="mb-1">
                      <a
                        className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                        href="#!"
                        data-bs-toggle="modal"
                        data-bs-target="#csv-file-upload-modal"
                      >
                        CSV
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                        href="#!"
                        data-bs-toggle="modal"
                        data-bs-target="#postgreModal"
                      >
                        PostGreSQL
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                        href="#!"
                      >
                        MongoDB
                        {getIconFromList("FILE_LOCK")}
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                        href="#!"
                      >
                        AWS S3
                        {getIconFromList("FILE_LOCK")}
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                        href="#!"
                      >
                        Azure Blob
                        {getIconFromList("FILE_LOCK")}
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        className="dropdown-item d-flex justify-content-between align-items-center data-src-btns"
                        href="#!"
                      >
                        OSI PI
                        {getIconFromList("FILE_LOCK")}
                      </a>
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CSVModal
        inputDataName={inputDataName}
        handleFileUpload={handleFileUpload}
        progress={progress}
      />
      <PostgreSQLModal
        inputDataName={inputDataName}
        setDatasetUpdateFlag={props.setDatasetUpdateFlag}
        setInputDataName={setInputDataName}
        panelFieldInfo={props.panelFieldInfo}
      />

      {animLoaderFlag ? (
        <div
          className="loader__top_with_logo loader__no_bottom__space loader analysis_runing_img"
          style={{ position: "fixed", left: "48%", top: "48%", zIndex: "9999" }}
        >
          <div className="inner one"></div>
          <div className="inner two"></div>
          <div className="inner three"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default AddDatasetWebGL;
