const defaultState = {
  email: null,
  first_name: null,
  last_name: null,
  organization_name: null,
  stats: null,
};

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case "SET_USER_INFO":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_USER_INFO":
      return {
        ...defaultState,
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "SET_UPDATED_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_PROJECT_STATS":
      return {
        ...state,
        stats: action.payload,
      };
    default:
      return state;
  }
}
