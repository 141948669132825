import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppInfo from "../../components/AppInfo";
import ContainerFluid from "../../components/ContainerFluid";
import CopyRightFooter from "../../components/CopyRightFooter";
import TopHeader from "../../components/TopHeader";
import SiginIn from "../../components/SiginIn";
import Register from "../../components/Register";
import ForgotPassword from "../../components/ForgotPassword";

const Login = () => {
  const [activeComponent, switchComponent] = useState("SIGININ");
  const navigate = useNavigate();

  useEffect(() => {
    const isAuth = !!localStorage.getItem("access_token");
    if (isAuth) {
      navigate("/home");
      // // console.log("----------authorized-----------");
    }
  }, [navigate]);
  return (
    <ContainerFluid>
      <TopHeader />
      <div className="row margin_top_header">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 m-auto text-center">
          {activeComponent === "SIGININ" && (
            <SiginIn switchComponent={switchComponent} />
          )}
          {activeComponent === "REGISTER" && (
            <Register switchComponent={switchComponent} />
          )}
          {activeComponent === "FORGOT_PASSWORD" && (
            <ForgotPassword switchComponent={switchComponent} />
          )}
        </div>

        <AppInfo />
      </div>
      <CopyRightFooter />
    </ContainerFluid>
  );
};

export default Login;
