import React, { Fragment, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

const AddNewDataSet = ({ isOpen, setIsOpen, guid, panelType }) => {
  const dispatch = useDispatch();
  const [dataSetName, setDataSetName] = useState("");
  const [selectedDataSetType, setSelectedDataSetType] = useState("");
  const [uploading, setUploding] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(!isOpen);
      }}
      contentLabel="AddNewDataSet"
      className={
        selectedDataSetType === "PostgreSQL"
          ? "append__modal AddNewDataSet"
          : "AddNewDataSet"
      }
    >
      <div className="ReactModal__Content--container ReactModal__Content--container__configaration_modal">
        <div className="ReactModal__Content--inner__header">
          <div className="ReactModal__Content--inner__header--block">
            <div className="ReactModal__Content--inner__header--block__block">
              <h4 className="ReactModal__Content--title">Create New Dataset</h4>
            </div>
          </div>
        </div>
        <div className="AddNewDataSet__modal_body">
          <div>
            <div className="dataset__next--input_group dataset__next--input_group--row mb-3">
              <p className="mb-1">Enter Dataset Name</p>
              <input
                className="dataset__next--input form-control"
                type="text"
                value={dataSetName}
                onChange={({ target }) => setDataSetName(target.value)}
              />
            </div>
          </div>

          {dataSetName && (
            <div className="mb-3 select__dataset--source__group">
              <p className="mb-1">Select Dataset Source</p>
              <div className="data__type">
                {[
                  { type: "CSV", featureAvailable: false },
                  { type: "PostgreSQL", featureAvailable: false },
                  { type: "MongoDB", featureAvailable: true },
                  { type: "AWS S3", featureAvailable: true },
                  { type: "Azure Blob", featureAvailable: true },
                  { type: "OSI PI", featureAvailable: true },
                ].map((item) => (
                  <button
                    key={item.type}
                    disabled={item.featureAvailable}
                    onClick={() => {
                      setSelectedDataSetType(item.type);
                    }}
                    className={
                      selectedDataSetType === item.type
                        ? "btn__item--data__type active"
                        : "btn__item--data__type"
                    }
                  >
                    {item.type}
                  </button>
                ))}
              </div>
            </div>
          )}

          {dataSetName && selectedDataSetType && selectedDataSetType === "CSV" && (
            <div>
              <p className="mb-1">Upload dataset file</p>
              <div className="input-group csv-file-upload">
                <input
                  type="file"
                  className="form-control"
                  id="upload-csv-file-input"
                  multiple={false}
                  accept=".csv"
                  required
                  onChange={(e) => {
                    setUploding(true);
                    e.preventDefault();
                    alert("REMOVED UPLOAADER");
                  }}
                />
              </div>

              {uploading && <span>Uploading...</span>}
            </div>
          )}

          {dataSetName &&
            selectedDataSetType &&
            selectedDataSetType === "PostgreSQL" && (
              <div className="">
                <div className="px-3">
                  <h5 className="mb-0">Connect PostgreSQL Database</h5>
                </div>
                <Fragment>
                  <div className="p-3 postgre-form">
                    <div className="mb-3">
                      <label htmlFor="hostName" className="form-label">
                        Host Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postgre-connection-hostName"
                        placeholder="Host Name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="port" className="form-label">
                        Port
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postgre-connection-port"
                        placeholder="Port"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="dbuserName" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postgre-connection-dbuserName"
                        placeholder="Username"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="dbuserPass" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="postgre-connection-dbuserPass"
                        placeholder="Password"
                      />
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        className="btn btn-scifi ReactModal__next--btn  rounded-0"
                      >
                        <i className="btn-helper"></i>
                        <span className="btn-text">Next</span>
                      </button>
                    </div>
                  </div>
                </Fragment>
              </div>
            )}
        </div>
      </div>
    </Modal>
  );
};

export default AddNewDataSet;
