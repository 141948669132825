import React, { useState, useRef, useEffect, Fragment } from "react";
import ModalTemplate from "../../ModalTemplate";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgora from "./useAgora";
import toastr from "toastr";

import "./console.css";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const RTMConsole = ({
  messages,
  currentUserName,
  rtcMembers,
  channelName,
  isHost,
  useRguid,
}) => {
  const {
    localAudioTrack,
    leave,
    join,
    joinState,
    remoteUsers,
    setMute,
    muteState,
  } = useAgora(client);

  const [isOpen, setOpen] = useState(false);
  const bottomRef = useRef(null);

  useEffect(() => {
    if (localAudioTrack) {
      return () => {
        localAudioTrack.stop();
      };
    }
  }, [localAudioTrack]);

  useEffect(() => {
    if (currentUserName && channelName) {
      join(channelName, null, useRguid);
    }
  }, [channelName, useRguid]);

  window.createRTCchannelAndJoin = async () => {
    // console.log("-----USER GUID");
    // console.log(useRguid);
    debugger;
    await join(channelName, null, useRguid);
  };

  window.leaveFromRTCChannel = async () => {
    await leave();
  };

  window.viewRemoteUsers = () => {
    // console.log(remoteUsers);
  };

  window.checkJoinState = () => {
    // console.log(joinState);

    return joinState;
  };

  window.RTMConsole = (projectGuid, assetGuid) => {
    setOpen(!isOpen);
  };

  window.listen = async (isListeing) => {
    // console.log(isListeing);
    if (isListeing === 0) {
      await setMute(true);
    }
    if (isListeing === 1) {
      await setMute(false);
    }
  };

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages]);

  return (
    <>
      <ModalTemplate
        isOpen={isOpen}
        setOpen={setOpen}
        modalID="RTMConsole"
        ariaHideApp={false}
        dataSubmited={false}
        drag={false}
        modalParentID="modal__console"
        className={`modal__console`}
      >
        <div className="ReactModal__Content--container ReactModal__Content--textonly">
          <div className="console console__chatroom">
            <div className="console__header">
              <div className="console__header--block">
                <h5 className="console__title">Live Participants</h5>
              </div>
              <div className="console__header-action">
                {channelName ? (
                  <Fragment>
                    {/* <button
                      className="btn console__minmax--btn"
                      onClick={async () => {
                        if (joinState) {
                          await leave();
                          toastr.success("Leaving meeting audio");
                        } else {
                          await join(channelName, null, currentUserName);
                          toastr.success("Joined meeting audio");
                        }
                      }}
                    >
                      {joinState ? "📵" : "📞"}
                    </button> */}
                    {joinState ? (
                      <button
                        className="btn console__minmax--btn"
                        onClick={async () => {
                          if (muteState) {
                            await setMute(!muteState);
                            toastr.success("Audio unmuted");
                          } else {
                            await setMute(!muteState);
                            toastr.success("Audio muted");
                          }
                        }}
                      >
                        {muteState ? "🔇" : "🎙️"}
                      </button>
                    ) : null}
                    <button
                      title="close"
                      onClick={() => {
                        setOpen(false);
                      }}
                      className="btn console__close--btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-x-lg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                      </svg>
                    </button>
                  </Fragment>
                ) : null}
              </div>
            </div>
            <div className="console__body">
              {rtcMembers.length === 0 && (
                <div className="console__participant--helptext">
                  <span>Waiting for participants</span>
                </div>
              )}
              {rtcMembers.map((memberName, idx) => (
                <div className="console__participant--item" key={idx}>
                  <span>🟢</span>{" "}
                  <h5 className="console__participant--name">{memberName}</h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
};

export default RTMConsole;
