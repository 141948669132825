export const colors = [
    "#d80301",
    "#e5df10",
    "#2c3da7",
    "#c32f71",
    "#e2b629",
    "#c4dadb",
    "#72d698",
    "#8ea7bb",
    "#5e1c3",
    "#3a1377",
    "#8f248",
    "#6eec1e",
    "#d9be0c",
    "#73690f",
    "#2c52f8",
    "#9800aa",
    "#cdece2",
    "#dfba48",
    "#fa9254",
    "#7c3582",
    "#af2393",
    "#5eaf10",
    "#a7317",
    "#7122e9",
    "#9c32b3",
    "#862d06",
    "#ac6b0e",
    "#8613ce",
    "#26a681",
    "#232ccc",
    "#c29b66",
    "#82436b",
    "#2938ce",
    "#8a0ba9",
    "#3b0bf6",
    "#8cf1b",
    "#561462",
    "#e3a0e2",
    "#38f830",
    "#93cad5",
    "#96ce65",
    "#6dba10",
    "#66bb7c",
    "#c8700e",
    "#1f338f",
    "#5bbe86",
    "#7e4f6",
    "#e9015c",
    "#c9a9bf",
    "#dc90fb",
    "#a68842",
    "#af6d26",
    "#d07150",
    "#114ed8",
    "#5feb2e",
    "#b7d80b",
    "#84a172",
    "#375099",
    "#4037b3",
    "#daa48e",
    "#7ec4f",
    "#213775",
    "#b3470a",
    "#fa9a1d",
    "#a6f573",
    "#2b6b82",
    "#671839",
    "#d28492",
    "#cb0126",
    "#93ef48",
    "#37bfe2",
    "#22b86e",
    "#897faa",
    "#35b2a3",
    "#170087",
    "#c17c8b",
    "#8c4efe",
    "#97cb19",
    "#8a8f62",
    "#174a5d",
    "#5b812f",
    "#15427e",
    "#64f219",
    "#a06bd3",
    "#911396",
    "#d46978",
    "#b55c41",
    "#af984e",
    "#639acc",
    "#1b1a9e",
    "#ea6732",
    "#4bfb95",
    "#4c4d1f",
    "#3a68da",
    "#d89f1",
    "#7db221",
    "#62c605",
    "#9443ae",
    "#61999a",
    "#799482",
    "#a0094a",
    "#a4fd7c",
    "#8f5aea",
    "#787a5e",
    "#eba587",
    "#99a825",
    "#9119af",
    "#210d52",
    "#2da00d",
    "#57f4a2",
    "#d27578",
    "#fd4ee1",
    "#681c00",
    "#dcba6b",
    "#5a877c",
    "#ea0ef6",
    "#cf1ed0",
    "#5a9c6e",
    "#6a9615",
    "#508adc",
    "#338cd7",
    "#3358a",
    "#eb3f2a",
    "#bc093b",
    "#c6a9f8",
    "#f45e90",
    "#ad0b90",
    "#b9353f",
    "#d9ba02",
    "#130ff2",
    "#79e93a",
    "#f98c70",
    "#626a75",
    "#4cdc77",
    "#185192",
    "#2ea364",
    "#f9d956",
    "#713018",
    "#e81f5b",
    "#35303d",
    "#eb98a9",
    "#a7a6aa",
    "#6529ad",
    "#2894f5",
    "#81666f",
    "#fd2823",
    "#d79ec2",
    "#67add2",
    "#fedc95",
    "#6cf45",
    "#51b37f",
    "#295412",
    "#801ab5",
    "#e7209f",
    "#ad0276",
    "#5d5d71",
    "#ffb761",
    "#f0b6ad",
    "#7ac41d",
    "#afcbf5",
    "#e09547",
    "#3f3894",
    "#710430",
    "#5be023",
    "#6ed224",
    "#47bd14",
    "#4c519e",
    "#26ce3a",
    "#24f6ec",
    "#375f76",
    "#7b356a",
    "#8e24ea",
    "#1d6271",
    "#e25179",
    "#fa011b",
    "#bdad36",
    "#e70734",
    "#54846f",
    "#83e711",
    "#730c80",
    "#46eaed",
    "#f9a14",
    "#fe1652",
    "#7ccd60",
    "#d2f7cd",
    "#c12546",
    "#b5aa82",
    "#e4742c",
    "#ac4afd",
    "#facab8",
    "#5dcd35",
    "#76bb57",
    "#6f3188",
    "#2e7228",
    "#c5d490",
    "#4f9314",
    "#a09f6",
    "#4a18af",
    "#275b6",
    "#4254dd",
    "#642c59",
    "#2bd2ab",
    "#5a11c3",
    "#d3dcf6",
    "#91a233",
    "#cef45a",
    "#e1741c",
    "#cfa990",
    "#2c67cb",
    "#ce8dc7",
    "#d51f9",
    "#21275d",
    "#636a92",
    "#684f87",
    "#380bb8",
    "#996b68",
    "#9e0b61",
    "#349168",
    "#27b4a6",
    "#e832f3",
    "#7f7c24",
    "#dccd68",
    "#9f075",
    "#c3427b",
    "#3017d9",
    "#697bc6",
    "#dd413e",
    "#985595",
    "#d187fe",
    "#b77f59",
    "#c4e8be",
    "#1bea66",
    "#7da82c",
    "#fa55f4",
    "#cc439b",
    "#74f2e",
    "#350f75",
    "#73587e",
    "#fba52a",
    "#549fa5",
    "#a5b707",
    "#4ca515",
    "#5dc767",
    "#d91f74",
    "#376197",
    "#a9c35d",
    "#1fae44",
    "#50c2af",
    "#d713ae",
    "#6a7cf3",
    "#27c8df",
    "#d85af2",
    "#3abc91",
    "#2e604d",
    "#35325e",
    "#2a4cdc",
    "#8102b8",
    "#3ddba6",
    "#19864b",
    "#3004b7",
    "#3cfbd5",
    "#ff6bb8",
    "#81017",
    "#8d74ec",
    "#bbb577",
    "#fa0b28",
    "#3aeb2",
    "#2c7627",
    "#d881f7",
    "#335f63",
    "#a6911",
    "#1eef80",
    "#9caa06",
    "#bb1121",
    "#5c48e",
    "#315ea8",
    "#37eb4b",
    "#915436",
    "#9f8199",
    "#10133e",
    "#fb6d01",
    "#7db908",
    "#e246a3",
    "#bbfad1",
    "#73abbf",
    "#584b73",
    "#487821",
    "#557736",
    "#7e304e",
    "#eb4d22",
    "#56dba9",
    "#635f97",
    "#ada9e2",
    "#fc5e1b",
    "#55ff77",
    "#792415",
    "#8e6684",
    "#8f379",
    "#bc54bf",
    "#71cab",
    "#38adf8",
    "#2208d8",
    "#610274",
    "#dc69a",
    "#173f86",
    "#b0a24",
    "#cbbcfe",
    "#705866",
    "#cf2fa4",
    "#5264d5",
    "#48996d",
    "#1899c3",
    "#7dc72f",
    "#88721b",
    "#bb395f",
    "#b3e250",
    "#47deca",
    "#c948cf",
    "#a0912d",
    "#ac2e94",
    "#b7122a",
    "#e63281",
    "#952c61",
    "#cd790e",
    "#efaa28",
    "#33ac1b",
    "#1031cb",
    "#716818",
    "#8fe50d",
    "#d491fb",
    "#50bb59",
    "#774dcf",
    "#f08e16",
    "#836105",
    "#26d958",
    "#646497",
    "#5d390b",
    "#d9c9e",
    "#cd5992",
    "#29dc6f",
    "#90e880",
    "#a887cd",
    "#80ea6c",
    "#cce5e8",
    "#9de99d",
    "#4ebd3b",
    "#434638",
    "#9d715b",
    "#661e97",
    "#5c5ece",
    "#4baa22",
    "#98616c",
    "#1e8467",
    "#6eed73",
    "#96fe0",
    "#6162d1",
    "#f808a",
    "#dacf7d",
    "#75c392",
    "#57d4f3",
    "#22f0ff",
    "#349348",
    "#b6ab74",
    "#49d259",
    "#4ec95",
    "#4ea0a3",
    "#e550f8",
    "#c99f51",
    "#ef6552",
    "#bd050c",
    "#118e8c",
    "#a4055d",
    "#4d744f",
    "#e7eb2f",
    "#dc6b78",
    "#5b987c",
    "#2157c3",
    "#c6d2ae",
    "#b0a011",
    "#2715d4",
    "#46559f",
    "#5921e4",
    "#57fa5a",
    "#c397e4",
    "#8efeb7",
    "#d80b72",
    "#babcdf",
    "#76ab0f",
    "#f5127f",
    "#37588b",
    "#ab7944",
    "#3f1dfd",
    "#b39533",
    "#cf1b89",
    "#2945c",
    "#cc7b60",
    "#95117a",
    "#40f4b5",
    "#7cd918",
    "#5415b6",
    "#d11d89",
    "#7b8b1d",
    "#8a2365",
    "#d622f9",
    "#8f5afd",
    "#b81a1f",
    "#ebe6ba",
    "#a2f003",
    "#18ad2e",
    "#f7e450",
    "#104d6b",
    "#52e391",
    "#f24d50",
    "#a801ef",
    "#ead206",
    "#f4cc34",
    "#1bd29b",
    "#a2c9b3",
    "#ed5083",
    "#fa02c",
    "#3ff83",
    "#c4daa2",
    "#20933b",
    "#56d7a3",
    "#ac7781",
    "#e6e2ed",
    "#f4a1fa",
    "#8857f4",
    "#794091",
    "#eca16b",
    "#9035f5",
    "#50d3fc",
    "#e0a001",
    "#9ef50f",
    "#feb154",
    "#905701",
    "#2acae2",
    "#c9aadc",
    "#ae1ccb",
    "#364078",
    "#6c9da9",
    "#43390b",
    "#4f574",
    "#adc5a3",
    "#30ab05",
    "#454da1",
    "#84a97d",
    "#5d55d8",
    "#171e79",
    "#8e7b7e",
    "#61a88f",
    "#62730a",
    "#3e3d2a",
    "#a4c81b",
    "#8e4031",
    "#8e618e",
    "#a1ab24",
    "#952582",
    "#e35ebe",
    "#22fb5a",
    "#586006",
    "#81f74f",
    "#a612b",
    "#b57bc1",
    "#d5877b",
    "#1a4b0",
    "#5a1704",
    "#ad3421",
    "#4fc153",
    "#7b0320",
    "#fc88ac",
    "#705a6b",
    "#f6b82d",
    "#f2ab50",
    "#1ef809",
    "#8ef49a",
    "#6d6093",
    "#4292ac",
    "#d73475",
    "#c6937f",
    "#5f48ae",
    "#c792c5",
    "#d031d3",
    "#eacf45",
    "#c24583",
    "#14a1ee",
    "#63ceb7",
    "#3e7896",
    "#e31a52",
    "#ba2110",
    "#354e2a",
    "#5c816d",
    "#6828e7",
    "#d8bb72",
    "#4f6f3",
    "#3af449",
    "#f67f10",
    "#c62305",
    "#dda304",
    "#5a6952",
    "#f05841",
    "#57f3d5",
    "#7e018d",
    "#811a0e",
    "#638a5",
    "#8d4ee9",
    "#13ed3",
    "#e0b1",
    "#939e5a",
    "#c358b5",
    "#5e5cc9",
    "#5065bb",
    "#26a7a4",
    "#bcfc62",
    "#c30361",
    "#b35a86",
    "#669755",
    "#5c42f0",
    "#ccf735",
    "#aafd89",
    "#d9cd58",
    "#80397b",
    "#f135b4",
    "#be8e28",
    "#977695",
    "#644d36",
    "#7cea66",
    "#681c80",
    "#6dd53a",
    "#6de06e",
    "#2c8650",
    "#5c2d89",
    "#da7b4a",
    "#e8d949",
    "#7383a8",
    "#5e6e19",
    "#6f1758",
    "#5e8f04",
    "#c7f136",
    "#6bd17f",
    "#54a6e3",
    "#ac5a8c",
    "#f192cf",
    "#37e99e",
    "#da184b",
    "#1e60a9",
    "#2a1687",
    "#fe34ea",
    "#57f4dc",
    "#e98623",
    "#f584dd",
    "#f5619a",
    "#9799a2",
    "#d7861c",
    "#194a30",
    "#9c32d8",
    "#a07f6d",
    "#e2bfea",
    "#812f35",
    "#a814ee",
    "#d39bab",
    "#a5b20d",
    "#3b2f90",
    "#8f6d7f",
    "#5c350b",
    "#d30341",
    "#f2ab58",
    "#a5fc12",
    "#bb5031",
    "#82fb48",
    "#89ad7c",
    "#fd22d6",
    "#589987",
    "#3b979d",
    "#6367c8",
    "#ce9f02",
    "#b7fbc5",
    "#27dc38",
    "#16ae0e",
    "#b454d4",
    "#7477c7",
    "#ef31d5",
    "#b719d3",
    "#883a37",
    "#dbe3f8",
    "#6db7e8",
    "#d7ca1b",
    "#f0dd5d",
    "#b1b922",
    "#ddb9f4",
    "#38f0cc",
    "#a01d57",
    "#7cd93",
    "#69a4a5",
    "#388940",
    "#c29772",
    "#e391f1",
    "#295945",
    "#226081",
    "#1ffc80",
    "#e86b14",
    "#2f1497",
    "#af006a",
    "#782019",
    "#7c749e",
    "#a6799e",
    "#7dbb4d",
    "#b639ce",
    "#d1e48f",
    "#2fbee6",
    "#9eac08",
    "#70c803",
    "#756f12",
    "#7641b4",
    "#e0bb8e",
    "#660e97",
    "#fbf91d",
    "#5d4331",
    "#75ad65",
    "#11c490",
    "#d864e4",
    "#b21ec4",
    "#74d29b",
    "#216f19",
    "#a6982f",
    "#b864ab",
    "#389bb2",
    "#49e61f",
    "#d256f4",
    "#b5ed55",
    "#949f11",
    "#47b80c",
    "#9b805",
    "#9b9da",
    "#d9876e",
    "#30bd71",
    "#c97b1f",
    "#77ef7a",
    "#187f75",
    "#815557",
    "#d3db91",
    "#627781",
    "#aa9f63",
    "#8a162d",
    "#5453aa",
    "#f07113",
    "#269ea6",
    "#134831",
    "#d16ccf",
    "#d58347",
    "#e61b86",
    "#df1e74",
    "#cefa2b",
    "#5a0008",
    "#777277",
    "#7719da",
    "#b6072c",
    "#49c79e",
    "#8df95",
    "#17f914",
    "#c9737e",
    "#b7579",
    "#f0ca73",
    "#cfca82",
    "#2f3661",
    "#90119e",
    "#d9dd07",
    "#c03ec8",
    "#d20b42",
    "#34834",
    "#17fbe5",
    "#4e5e96",
    "#13f504",
    "#7257f5",
    "#33d9ac",
    "#8df927",
    "#a495bf",
    "#217fcd",
    "#820769",
    "#586ba3",
    "#ee3f55",
    "#1e5043",
    "#e79d37",
    "#c6566f",
    "#855514",
    "#8a7579",
    "#6950ee",
    "#e13ef5",
    "#c6aa65",
    "#f1987c",
    "#734aee",
    "#1a70b",
    "#8a8b4",
    "#a28540",
    "#5a26bd",
    "#f9794b",
    "#d8c8c4",
    "#aeb0e0",
    "#d27765",
    "#ed3a1c",
    "#1daaec",
    "#26ecb3",
    "#f6066c",
    "#d19497",
    "#1c7f2",
    "#d28099",
    "#63f968",
    "#935b05",
    "#bdfa65",
    "#96fc8e",
    "#eec536",
    "#6c2da5",
    "#8602fd",
    "#64bd3a",
    "#5d26ff",
    "#17a6b4",
    "#a2986",
    "#7fae4e",
    "#e8e206",
    "#3c74dd",
    "#41e44a",
    "#112eac",
    "#bd5b81",
    "#d68b44",
    "#935a09",
    "#ea4e80",
    "#b5d244",
    "#390288",
    "#39b247",
    "#7ea954",
    "#38fd0c",
    "#1e8174",
    "#9e40aa",
    "#ede023",
    "#901119",
    "#58bec3",
    "#ed5139",
    "#cd5274",
    "#f93360",
    "#b0fa5e",
    "#437b61",
    "#202e73",
    "#c3a9a4",
    "#9393e4",
    "#807835",
    "#4e1a24",
    "#a977dc",
    "#99949c",
    "#be902e",
    "#5a8d16",
    "#6db056",
    "#ec6f60",
    "#eb351d",
    "#deca7b",
    "#3a25c1",
    "#5d3fbb",
    "#90c98d",
    "#7085c1",
    "#5fbdb9",
    "#d08b6f",
    "#ca4cb1",
    "#5763ec",
    "#eda155",
    "#2bba3e",
    "#fe8a21",
    "#dad87d",
    "#7e6d7c",
    "#5c543f",
    "#d7161b",
    "#42f267",
    "#e1c53",
    "#cc794e",
    "#78b320",
    "#9b2211",
    "#2f9fcb",
    "#70e35c",
    "#a902f0",
    "#10f435",
    "#1efe88",
    "#acccd4",
    "#63ef21",
    "#f3d465",
    "#332d",
    "#df7b29",
    "#43d14c",
    "#90e124",
    "#b3290d",
    "#2025ad",
    "#62791",
    "#46c578",
    "#544fe7",
    "#fa46fe",
    "#89a127",
    "#480f16",
    "#372453",
    "#828285",
    "#584817",
    "#c8c708",
    "#f0259",
    "#bf6d",
    "#4778e",
    "#fbb8c",
    "#ab45de",
    "#32dd4e",
    "#fa9867",
    "#90de3a",
    "#bd50e0",
    "#d812d5",
    "#130ef6",
    "#a12812",
    "#51fa1b",
    "#67a21a",
    "#bf63a2",
    "#e3c4ed",
    "#be5c7f",
    "#68f082",
    "#139cdf",
    "#cfedda",
    "#3261d9",
    "#a8b350",
    "#d838bb",
    "#2dbb40",
    "#cd2bf",
    "#310869",
    "#29676",
    "#ea23d6",
    "#42f12b",
    "#fc1b45",
    "#804e7",
    "#a4eecd",
    "#d0da82",
    "#91b153",
    "#992d6b",
    "#17436a",
    "#d47947",
    "#be803a",
    "#28d5b0",
    "#ee9a6e",
    "#b16972",
    "#8505bd",
    "#b30ca1",
    "#bfa810",
    "#d377f2",
    "#551544",
    "#6ab6f3",
    "#c0fe79",
    "#1da6e3",
    "#505753",
    "#51225d",
    "#275800",
    "#444b30",
    "#995d53",
    "#79d66e",
    "#a68907",
    "#d799f",
    "#904855",
    "#11f095",
    "#bbadb9",
    "#5e64bd",
    "#400b47",
    "#f99688",
    "#b3ce8f",
    "#4428e5",
    "#79f072",
    "#b36a1c",
    "#b520f8",
    "#323bb6",
    "#e39634",
    "#aae2b2",
    "#a3b321",
    "#f006c1",
    "#b59eb5",
    "#ae109d",
    "#45ba3c",
    "#b9f329",
    "#bd51ed",
    "#9b9994",
    "#23a36f",
    "#c3c694",
    "#a3714b",
    "#2176f4",
    "#f04f1f",
    "#f2abeb",
    "#2a3902",
    "#e337a7",
    "#e6d637",
    "#b249b0",
    "#3be894",
    "#8cef3b",
    "#2ff063",
    "#be85df",
    "#ce71ad",
    "#bccf7a",
    "#1dfdfe",
    "#523543",
    "#478083",
    "#a5c1a",
    "#13b2c2",
    "#a5d2bc",
    "#c8897",
    "#a9345d",
    "#213422",
    "#1e7265",
    "#d72a90",
    "#6f0f0e",
    "#108791",
    "#4183e8",
    "#d8c458",
    "#38f549",
    "#9d93b9",
    "#11e35c",
    "#e6a8af",
    "#61faf6",
    "#5afb7b",
    "#e1bb60",
    "#58401",
    "#752550",
    "#ac69c3",
    "#1e582b",
    "#99f8bf",
    "#14fa1f",
    "#145d61",
    "#81e60c",
    "#5a639f",
    "#a56d14",
    "#2db237",
    "#8c8585",
    "#c67af6",
    "#96cb1f",
    "#af027d",
    "#ce5ba5",
    "#55fe98",
    "#c8e057",
    "#79fc01",
    "#c183c2",
    "#5281a6",
    "#3542cb",
    "#a8fda4",
    "#15b2c0",
    "#bf40b1",
    "#7ccbc9",
    "#d95b57",
    "#bd5cdb",
    "#2d50ba",
    "#5684c1",
    "#d81444",
    "#dc5c89",
    "#20e42a",
    "#42291b",
    "#84e151",
    "#8f18aa",
    "#8e5558",
    "#db97ba",
    "#6673ca",
    "#ff9491",
    "#820ff1",
    "#60a46b",
    "#b13925",
    "#b52662",
    "#313210",
    "#371a0d",
    "#99a670",
    "#769d3f",
    "#a2fce5",
    "#d38027",
    "#705bef",
    "#464f15",
    "#476772",
    "#7e1c32",
    "#2c02ab",
    "#83dc73",
    "#c329d9",
    "#d9459c",
    "#1c4bb7",
    "#cc1279",
    "#b299ea",
    "#42eec0",
    "#f6d17f",
    "#c67982",
    "#c6ac43",
    "#7811c",
    "#1e6805",
    "#4eec62",
    "#6a581e",
    "#80c82c",
    "#cd3043",
    "#13f674",
    "#79eb0c",
    "#4bf43b",
    "#dfb4c8",
    "#9debd3",
    "#54437b",
    "#f27f7",
    "#f386e6",
    "#d946a3",
    "#1b6ada",
    "#9bf9cc",
    "#336e1f",
    "#d7b97a",
    "#a2bfd"
]