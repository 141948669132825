import React, { Fragment } from "react";

export default function CopyRightFooter() {
  return (
    <Fragment>
      {/* <div className="custom_footer_beta">
        <p className=" fsize_10 m-0">
          Beta-<span>v0.87</span>
        </p>
      </div>
      <div className="custom_footer">
        <div>
          <p>
            Copyright © 2020-2021
            <a className="link_custom" target="_blank" href="https://idare.io/">
              <strong>IDARE®</strong>
            </a>
            , All rights reserved. Powered by
            <a className="link_custom" target="_blank" href="https://idare.io/">
              <strong style={{ fontWeight: 700 }}>IDARE®</strong>
              <img
                style={{ width: 18 }}
                src={require("../../static/img/favicon/favicon-32x32.png")}
                alt=""
              />
            </a>
          </p>
        </div>
      </div> */}
    </Fragment>
  );
}
