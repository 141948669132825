export const generateDonutChartLayout = (chart, parentComponent) => ({
  // autosize: true,
  title: "Global Emissions 1990-2011",
  margin: { t: 25, r: 25, l: 25, b: 25 },
  annotations: [
    {
      font: {
        size: 20,
      },
      showarrow: false,
      text: "GHG",
      x: 0.17,
      y: 0.5,
    },
    {
      font: {
        size: 20,
      },
      showarrow: false,
      text: "CO2",
      x: 0.82,
      y: 0.5,
    },
  ],
  height: 250,
  width: 400,
  showlegend: false,
  grid: { rows: 1, columns: 2 },
});
