import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchComponentSpecs } from "../../../../actions/webglAssociatesActions";

import ModalTemplate from "../../ModalTemplate";

const ComponentSpecsViewer = ({
  fetchComponentSpecs,
  requestPending,
  componentSpecs,
  failedToFetchData,
}) => {
  const [isOpen, setOpen] = useState(false);

  // window.CSViewer = (projectGuid, assetGuid, componentGuid) => {
  window.CSViewer = (componentData) => {
    // console.log(componentData)
    let projectGuid = JSON.parse(componentData).project_guid
    let assetGuid = JSON.parse(componentData).asset_guid
    let pipelineGuid = JSON.parse(componentData).pipeline_guid
    let urlParam = JSON.parse(componentData).url_param

    let componentGuid = JSON.parse(componentData).component_guid
    setOpen(!isOpen);
    // fetchComponentSpecs(projectGuid, assetGuid, componentGuid);
    fetchComponentSpecs(componentData);
  };

  return (
    <>
      <ModalTemplate
        requestPending={requestPending}
        setOpen={setOpen}
        modalID="CSViewer"
        dataSubmited={false}
        isOpen={isOpen}
      >
        <div className="ReactModal__Content--container ReactModal__Content--textonly">
          <div className="ReactModal__curvedBorder ReactModal__curvedBorder--top-left"></div>
          <div className="ReactModal__curvedBorder ReactModal__curvedBorder--top-right"></div>
          <div className="ReactModal__curvedBorder ReactModal__curvedBorder--bottom-left"></div>
          <div className="ReactModal__curvedBorder ReactModal__curvedBorder--bottom-right"></div>
          {!failedToFetchData && (
            <div className="ReactModal__Content--block">
              <div className="ReactModal__Content--inner">
                <div className="ReactModal__Content--inner__header">
                  <div className="ReactModal__Content--inner__header--block">
                    <div className="ReactModal__Content--inner__header--block__block">
                      <marquee>
                        <h4 className="ReactModal__Content--title">
                          {componentSpecs && componentSpecs.basic_data
                            ? componentSpecs.basic_data[0].value + "'s SPECIFICATIONS"
                            : "Component Specifications"}
                        </h4>
                      </marquee>
                    </div>
                  </div>
                </div>
                <div className="ReactModal__Content--inner__body">
                  <div className="ReactModal__Content--inner__body--block">
                    <table className="table ReactModal__Content--table">
                      <tbody>
                        {componentSpecs &&
                          componentSpecs.specifications &&
                          componentSpecs.specifications
                            .filter((specs) => specs.showing === true)
                            .map((specs, index) => (
                              <tr key={index}>
                                <td>{specs.label ? specs.label : ""}</td>
                                <td>{specs.value ? specs.value : ""}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            type="button"
            title="close"
            className="btn ReactModal__btn ReactModal__btn--close px-0"
            id="webgl-associates-modal-specification-viewer-close-btn-csViewer"
            onClick={() => {
              setOpen(false);
            }}
          ></button>
        </div>
      </ModalTemplate>
      {/* <ModalTemplate
      requestPending={requestPending}
      setOpen={setOpen}
      modalID="CSViewer"
      dataSubmited={false}
      isOpen={isOpen}
    >
      <span className="top-left-bottom_corner"></span>
          <span className="top-left-top_corner"></span>
          <span className="top-right-bottom_corner"></span>
          <span className="top-right-top_corner"></span>
      {!failedToFetchData && (
        
        <div className="modal-content modal-content_table">
          
          <div className="modal-header justify-content-center p-0 border-0">
          
            <h5 className="specifications-header-title" id="exampleModalLabel">
            <marquee loop="infinite">
              {componentSpecs && componentSpecs.basic_data
                ? componentSpecs.basic_data[0].value + "  Specifications"
                : "Component Specifications"}
                </marquee>
            </h5>
            
            <button
              type="button"
              className="transparent-btn btn-close"
              title="Close"
              onClick={() => {
                setOpen(false);
              }}
            ></button>
          </div>
          <div className="modal-body">
            <table className="mb-4 pb-4">
              <tbody>
                {componentSpecs &&
                  componentSpecs.specifications &&
                  componentSpecs.specifications
                    .filter((specs) => specs.showing === true)
                    .map((specs) => (
                      <tr>
                        <td width={80} className="pe-2">
                          {specs.label ? specs.label : ""}
                        </td>
                        <td className="pe-4">
                          {specs.value ? specs.value : ""}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          
        </div>
      )}
      <span className="bottom-left-bottom_corner"></span>
          <span className="bottom-left-top_corner"></span>
          <span className="bottom-right-bottom_corner"></span>
          <span className="bottom-right-top_corner"></span>
    </ModalTemplate> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  requestPending: state.ui.requestPending,
  componentSpecs: state.webglAssociates.modals.componentSpecs,
  failedToFetchData: state.webglAssociates.failedToFetchData,
});

export default connect(mapStateToProps, {
  fetchComponentSpecs,
})(ComponentSpecsViewer);
