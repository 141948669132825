import React, { useEffect, useState } from "react";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { generateGagueChartLayout } from "./layout/layout_gague.default";
import request from "../../../../utils/request";
import { useSelector } from "react-redux";
import { getDefaultChartData } from "./defaultChartData";

const Plot = createPlotlyComponent(Plotly);

const SocketChartGague = ({ chart, panel }) => {
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const [isLoaderOn, setIsLoaderOn] = useState(true);
  const [gagueChartData, setGagueChartData] = useState(null);

  useEffect(async () => {
    setIsLoaderOn(true);
    // console.log(chart);
    if (chart.value) {
      setGagueChartData(null);
      chart.title.text = ""
      setGagueChartData(chart);
      setIsLoaderOn(false);
    }
  }, [panel]);

  return (
    <React.Fragment>
      {isLoaderOn ? (
        <div className="chart-loader"></div>
      ) : (
        <Plot
          data={[
            gagueChartData
              ? gagueChartData
              : getDefaultChartData("gague_chart").data,
          ]}
          layout={generateGagueChartLayout(chart, panel.panel_config)}
          useResizeHandler={true}
          className="wh-100"
        />
      )}
    </React.Fragment>
  );
};

export default SocketChartGague;
