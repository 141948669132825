const defaultState = {
  requestPending: false,
  dasboardAvailable: false,
  updatingProfile: false,
  loadingPipeline: false,
  loadingAsset: false,
  datasetUploadModal: false,
};

export default function uiReducer(state = defaultState, action) {
  switch (action.type) {
    case "REQUEST_PENDING":
      return {
        ...state,
        requestPending: true,
      };
    case "REQUEST_RESOLVED":
      return {
        ...state,
        requestPending: false,
      };
    case "SET_PROJECTS_GU_ID":
      return {
        ...state,
        dasboardAvailable: true,
      };
    case "SET_DASHBOARD_UNAVAILABLE":
      return {
        ...state,
        dasboardAvailable: false,
      };
    case "EDIT_PROFILE":
      return {
        ...state,
        updatingProfile: action.payload,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        updatingProfile: false,
      };
    case "SET_PIPELINE_LOADING":
      return {
        ...state,
        loadingPipeline: action.payload,
      };
    case "SET_ASSET_LOADING":
      return {
        ...state,
        loadingAsset: action.payload,
      };
    case "UPDATE_DATASET_UPLOAD_MODAL_STATUS":
      return {
        ...state,
        datasetUploadModal: action.payload,
      };
    default:
      return state;
  }
}
