import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import mapboxgl from "mapbox-gl";
import MapboxWorker from "mapbox-gl/dist/mapbox-gl-csp-worker";

const baseMaps = {
  Streets: "mapbox://styles/mapbox/streets-v10",
  Outdoors: "mapbox://styles/mapbox/outdoors-v11",
  Satellite: "mapbox://styles/mapbox/satellite-v9",
  Light: "mapbox://styles/mapbox/light-v10",
};

const BubbleMapBD = () => {
  const mapContainer = useRef(null);
  const [currentBaseMap, setCurrentBaseMap] = useState("Streets");
  const [columnNames, setColumnNames] = useState([]);
  const [selectedValueColumn, setValueColumn] = useState("KWh");
  const [coordinates, setCoordinates] = useState({
    southwest: { a: "88.0252", b: "20.5902" },
    northeast: { a: "92.6832", b: "26.6245" },
  });

  useEffect(() => {
    // Read data from CSV using d3
    d3.csv("100_customer_data.csv").then((csvData) => {
      // Extract column names from CSV data
      const keys = Object.keys(csvData[0]);
      setColumnNames(keys);

      // Initialize Mapbox map
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZmhidGhlbm90cnRoc3RhciIsImEiOiJjbGZhcDdtbGQyaGx3M3lwcXNyb2NnN3RpIn0.y7qkhQJaalrMuemy8mZqEQ";

      /* eslint import/no-webpack-loader-syntax: off */
      mapboxgl.workerClass =
        require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v10",
        center: [20, 50],
        zoom: 4,
        scrollZoom: true,
      });

      const bounds = [
        [88.0252, 20.5902], // Southwest coordinates
        [92.6832, 26.6245],
      ];

      map.setMaxBounds(bounds);
      map.fitBounds(bounds, { padding: 50 });

      // Add bubbles to map
      const circleLayerId = "circles";
      const bubbleRadius = 20;
      // Get the minimum and maximum values for the data column you want to map to color
      const minValue = d3.min(csvData, (d) => +d[selectedValueColumn]);
      const maxValue = d3.max(csvData, (d) => +d[selectedValueColumn]);

      // Define a color scale using D3's scaleSequential() function
      const colorScale = d3
        .scaleSequential()
        .domain([minValue, maxValue]) // Set the input domain of the color scale to the data range
        .interpolator(d3.interpolateViridis); // Set the output range of the color scale to a color ramp

      map.on("load", () => {
        map.addLayer({
          id: circleLayerId,
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: csvData.map((d) => ({
                type: "Feature",
                properties: d,
                geometry: {
                  type: "Point",
                  coordinates: [
                    parseFloat(d["longitude"]),
                    parseFloat(d["latitude"]),
                  ],
                },
              })),
            },
          },
          type: "circle",
          paint: {
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["to-number", ["get", selectedValueColumn]],
              d3.min(csvData, (d) => +d[selectedValueColumn]),
              5,
              d3.max(csvData, (d) => +d[selectedValueColumn]),
              15,
            ],
            "circle-color": [
              "interpolate",
              ["linear"],
              ["to-number", ["get", selectedValueColumn]],
              minValue,
              colorScale(minValue),
              maxValue,
              colorScale(maxValue),
            ],
            "circle-stroke-color": "#000",
            "circle-stroke-width": 1,
          },
        });

        // Add event listener to show tooltip on click
        map.on("click", circleLayerId, (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();

          // Create popup content
          const popupContent = document.createElement("div");
          popupContent.classList.add("popup-content");
          popupContent.innerHTML = `
              <p>Value: ${e.features[0].properties["KWh"]}</p>
              <p>Consumer ID : ${e.features[0].properties["consumer_id"]}</p>
              <p>SND: ${e.features[0].properties["snd"]}</p>
            `;

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setDOMContent(popupContent)
            .addTo(map);
        });
      });

      // Add event listener to change base map
      const handleBaseMapChange = (e) => {
        const selectedBaseMap = e.target.value;
        setCurrentBaseMap(selectedBaseMap);
        map.setStyle(baseMaps[selectedBaseMap]);
      };

      const handleRemoveBounds = () => {
        map.setMaxBounds(null); // remove maximum bounds
      };

      const baseMapSelect = document.getElementById("base-map-select");
      baseMapSelect.addEventListener("change", handleBaseMapChange);

      const removeBounds = document.getElementById("removeBounds");
      removeBounds.addEventListener("click", handleRemoveBounds);

      return () => map.remove(); // Clean up the map on unmount
    });
  }, [selectedValueColumn]);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div style={{ display: "flex", gap: "10px", padding: "5px 10px" }}>
        <div className="map-controls">
          <label htmlFor="base-map-type">Type:</label>
          <select id="base-map-type">
            {["Bubble Map"].map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="map-controls">
          <label htmlFor="base-map-select">Base Map:</label>
          <select id="base-map-select">
            {Object.keys(baseMaps).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="map-controls">
          <label htmlFor="base-map-select">Select Value:</label>
          <select
            id="base-value-select"
            onChange={({ target: { value } }) => setValueColumn(value)}
          >
            {columnNames.map((name) => (
              <option
                key={name}
                value={name}
                selected={name === selectedValueColumn}
              >
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="map-controls">
          <label htmlFor="base-map-select">Map Coordinates:</label>
          <input
            placeholder="Southwest coordinate A"
            value={coordinates.southwest.a}
            onChange={({ target: { value } }) => {
              setCoordinates((prevState) => {
                return {
                  ...prevState,
                  southwest: {
                    ...prevState.southwest,
                    a: value,
                  },
                };
              });
            }}
          />
          <input
            placeholder="Southwest coordinate B"
            value={coordinates.southwest.b}
            onChange={({ target: { value } }) => {
              setCoordinates((prevState) => {
                return {
                  ...prevState,
                  southwest: {
                    ...prevState.southwest,
                    b: value,
                  },
                };
              });
            }}
          />
          <input
            placeholder="Northeast coordinate A"
            value={coordinates.northeast.a}
            onChange={({ target: { value } }) => {
              setCoordinates((prevState) => {
                return {
                  ...prevState,
                  northeast: {
                    ...prevState.northeast,
                    a: value,
                  },
                };
              });
            }}
          />
          <input
            placeholder="Northeast coordinate B"
            value={coordinates.northeast.a}
            onChange={({ target: { value } }) => {
              setCoordinates((prevState) => {
                return {
                  ...prevState,
                  northeast: {
                    ...prevState.northeast,
                    b: value,
                  },
                };
              });
            }}
          />
          <button id="removeBounds">remove boundary</button>
        </div>
      </div>
      <div
        ref={mapContainer}
        style={{ height: "calc(100% - 30px)", width: "100%" }}
      />
    </div>
  );
};

export default BubbleMapBD;
