import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { register } from "../../actions/authActions";
import loader from "../../static/loader.gif";

const Register = ({ switchComponent, register }) => {
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [subscription_plan, setSubscription_plan] = useState("Free");
  const [organization_name, setOrganization_name] = useState();
  // const [avatar] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const payload = {
    first_name,
    last_name,
    email,
    password,
    subscription_plan,
    organization_name,
    avatar: "MaleWorkerSlim",
    confirm_password,
  };

  const requestPending = useSelector((state) => state.ui.requestPending);

  return (
    <div id="register">
      <form
        className="form_custom corner-button mt-5 mb-5"
        id="register_form"
        onSubmit={(event) => {
          event.preventDefault();
          register(payload);
        }}
      >
        <div className="bottom_border">
          <div>
            <h4 className="header_custom header_corner-button text-uppercase">
              Sign Up
            </h4>
          </div>
          <div className="form-row">
            <div style={{ textAlign: "left" }} className="col-md-12">
              <label
                htmlFor="register_first_name"
                className="form-label text-uppercase fs-6"
              >
                Choose Your Plan{" "}
              </label>
              <div className="inline-radio-forms">
                <div className="form-check topu">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="subscriptionPlan"
                    id="subscriptionPlan1"
                    defaultValue="Free"
                    defaultChecked
                    onClick={() => setSubscription_plan("Free")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="subscriptionPlan1"
                  >
                    Free
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="subscriptionPlan"
                    id="subscriptionPlan2"
                    defaultValue="Enterprise"
                    onClick={() => setSubscription_plan("Premium")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="subscriptionPlan2"
                  >
                    Enterprise
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group_custom mb-3 mt-4">
                <label
                  htmlFor="register_first_name"
                  className="form-label text-uppercase fs-6"
                >
                  First Name{" "}
                </label>
                <input
                  name="register_first_name"
                  type="text"
                  className="form-control form-control_custom"
                  required
                  id="register_first_name"
                  placeholder="Name"
                  value={first_name}
                  onChange={({ target }) => setFirst_name(target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group_custom mb-3 mt-4">
                <label
                  htmlFor="register_last_name"
                  className="form-label text-uppercase fs-6"
                >
                  Last Name{" "}
                </label>
                <input
                  name="register_last_name"
                  type="text"
                  className="form-control form-control_custom"
                  required
                  id="register_last_name"
                  placeholder="Name"
                  value={last_name}
                  onChange={({ target }) => setLast_name(target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group_custom mb-3">
            <label
              htmlFor="organization_name"
              className="form-label text-uppercase fs-6"
            >
              Organization's Name
            </label>
            <input
              name="organization_name"
              type="text"
              className="form-control form-control_custom"
              id="organization_name"
              required
              placeholder="Organization's Name"
              value={organization_name}
              onChange={({ target }) => setOrganization_name(target.value)}
            />
          </div>
          <div className="form-group_custom mb-3 mt-3">
            <label
              htmlFor="register_email"
              className="form-label text-uppercase fs-6"
            >
              Email address
            </label>
            <input
              name="register_email"
              type="email"
              required
              className="form-control form-control_custom"
              id="register_email"
              placeholder="Email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
          </div>
          <div className="form-group_custom mb-3 mt-3">
            <div className="d-flex justify-content-between">
              <label
                htmlFor="register_password"
                className="form-label text-uppercase fs-6"
              >
                Password
              </label>
            </div>
            <input
              type={passwordType}
              required
              className="form-control form-control_custom"
              placeholder="Password"
              id="register_password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <span
              toggle="#register_password"
              className="fa fa-fw fa-eye field-icon toggle-password"
              onClick={() =>
                setPasswordType(
                  passwordType === "password" ? "text" : "password"
                )
              }
            />
          </div>
          <div className="form-group_custom mb-3 mt-3">
            <div className="d-flex justify-content-between">
              <label
                htmlFor="register_password"
                className="form-label text-uppercase fs-6"
              >
                Confirm Password
              </label>
            </div>
            <input
              type={confirmPasswordType}
              required
              className="form-control form-control_custom"
              placeholder="Password"
              id="register_password"
              value={confirm_password}
              onChange={({ target }) => setConfirm_password(target.value)}
            />
            <span
              toggle="#register_password"
              className="fa fa-fw fa-eye field-icon toggle-password"
              onClick={() =>
                setConfirmPasswordType(
                  confirmPasswordType === "password" ? "text" : "password"
                )
              }
            />
          </div>
          <div className="btn_sign_section d-flex justify-content-between align-items-center mt-4">
            <button
              type="submit"
              className="btn button_corner text-uppercase"
              disabled={requestPending}
            >
              {requestPending ? (
                <img src={loader} height="20px" alt="" />
              ) : (
                "Sign Up"
              )}
            </button>
            <a
              href="#!"
              onClick={() => switchComponent("SIGININ")}
              className="anchor_text text-uppercase float-end"
            >
              Already have an account!
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default connect(null, { register })(Register);
