import { combineReducers } from "redux";

import auth from "./authReducer";
import dashboard from "./DashboardReducer";
import projectInfo from "./projectInfo";
import uiReducer from "./uiReducer";
import individualAssetDashboard from "./dashboardIndividualReducer";
import webglAssociates from "./webglAssociatesReducer";
import chartReducer from "./chartReducer";
import panelReducer from "./panelReducer";
import customPanel from "./customPanel";
import customPanelModify from "./customPanelModify";

export default combineReducers({
  auth,
  dashboard,
  projectInfo,
  ui: uiReducer,
  individualAssetDashboard,
  webglAssociates,
  chartReducer,
  panel: panelReducer,
  customPanel,
  customPanelModify,
});
