import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPipelineSpecs } from "../../../../../actions/panelActions";
import Loader from "../../Loader"


const PipelineSpecs = ({ projectguid }) => {
  const pipelineSpecifications = useSelector(
    (state) => state.panel.metaData.pipelineSpecifications
  );
  const dispatch = useDispatch();

  // if (!pipelineSpecifications) {
  //   setTimeout(() => {
  //     dispatch(fetchPipelineSpecs(projectguid));
  //   }, 2000);
  // }

  useEffect(() => {
    dispatch(fetchPipelineSpecs(projectguid));
  }, [projectguid]);

  return (
    <div className="card-secondary-gradient">
    {/* <div className="card-header">
      <h4 className="card-title">PIPELINE SPECIFICATIONS</h4>
    </div> */}

    {pipelineSpecifications && pipelineSpecifications.Headers ? (
      <div className="card-body">
        <div className="table-responsive">
          <table className="table rwd-table table-metadata">
            <thead>
              <tr>
                {pipelineSpecifications.Headers.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pipelineSpecifications.data.map((data, index) => (
                <tr key={index}>
                  {Object.keys(data).map((key) => (
                    <td key={key}>{data[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ) : (
      <div className="custom__loader--container">
      <Loader />
      </div>
    )}
  </div>
  );
};

export default PipelineSpecs;
