import React, { useEffect, useState } from "react";
import request from "../../../utils/request";
import toast from "toastr";

import "./styles.css";
import { getIconFromList } from "../../../static/IconServe";
import { useDispatch } from "react-redux";
import {
  getAdditionalPurchase,
  getCreditPurchase,
  getSubscriptions,
  postAdditionalPurchase,
  postCreditPurchase,
  postSubscribePackage,
} from "../../../actions/billingAction";

const Pricing = (props) => {
  const dispatch = useDispatch();
  const [packagesList, setPackagesList] = useState(null);
  // CREDIT PURCHASE MODAL
  const [creditPurchaseDataCardNo, setCreditPurchaseDataCardNo] =
    useState(null);
  const [creditPurchaseDataCreditAmt, setCreditPurchaseDataCreditAmt] =
    useState(null);
  // ADDITIONAL PURCHASE MODAL
  const [assetCnt, setAssetCnt] = useState(null);
  const [storageCapacity, setStorageCapacity] = useState(null);
  const [duration, setDuration] = useState(null);

  useEffect(async () => {
    try {
      await request.get(`/api/billing/packages/`).then((res) => {
        setPackagesList(res.data.packages);
      });
    } catch (error) {
      // console.log("catch", error);
    }
  }, []);

  return (
    <>
      {/* <h4>Subscription Plan</h4> */}
      {/* <div className="packages_wrapper">
        <button
          className="btn btn-danger"
          style={{ padding: "0px 10px", marginTop: "10px" }}
          onClick={() => {
            // setSelectedPackage(pack);
          }}
          data-bs-toggle="modal"
          data-bs-target="#credit-purchase-modal"
        >
          Purchase Credit
        </button>
        <button
          className="btn btn-danger ml-4"
          style={{ padding: "0px 10px", marginTop: "10px" }}
          onClick={() => {
            // setSelectedPackage(pack);
          }}
          data-bs-toggle="modal"
          data-bs-target="#credit-customize-modal"
        >
          Additional Purchase 
        </button>
      </div> */}

      {/* CREDIT PURCHASE MODAL */}
      <>
        <div
          className="modal fade"
          id="credit-purchase-modal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                width: "calc(60vw)",
                maxWidth: "450px",
                background: "#08202E",
              }}
            >
              {/* HEADER */}
              <div
                className="modal-header"
                style={{ padding: "0.35rem 1.5rem" }}
              >
                <h5 className="modal-title" style={{ color: "#FFFFFF" }}>
                  Purchase Credit
                </h5>
                <span
                  className="panel-remove-icon"
                  style={{
                    position: "absolute",
                    top: "10px",
                    zIndex: "1",
                    right: "10px",
                    width: "30px",
                    height: "30px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {}}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
                </span>
              </div>
              {/* BODY */}
              <div style={{ overflowY: "auto" }}>
                <div
                  className="modal-body"
                  style={{ padding: "0.35rem 1.5rem" }}
                >
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      let pData = {
                        card_no: creditPurchaseDataCardNo,
                        credit_amount: parseInt(creditPurchaseDataCreditAmt),
                      };
                      dispatch(postCreditPurchase(pData)).then((res) => {
                        props.reloadList("PURCHASE");
                      });
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                      <label className="form-label m-0">Card Number</label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control w-200"
                          value={
                            creditPurchaseDataCardNo
                              ? creditPurchaseDataCardNo
                              : null
                          }
                          placeholder="XXXX XXXX XXXX XXXX"
                          onChange={(e) => {
                            e.stopPropagation();
                            setCreditPurchaseDataCardNo(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                      <label className="form-label m-0">Credit Amount</label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control w-200"
                          value={
                            creditPurchaseDataCreditAmt
                              ? creditPurchaseDataCreditAmt
                              : null
                          }
                          placeholder="20"
                          onChange={(e) => {
                            e.stopPropagation();
                            setCreditPurchaseDataCreditAmt(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-end aligh-items-end">
                      <button
                        className="ms-4 btn mx-1 data-source_btn"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        disabled={
                          !creditPurchaseDataCardNo ||
                          !creditPurchaseDataCreditAmt
                        }
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* CREDIT ADDITIONAL PURCHASE MODAL */}
      <>
        <div
          className="modal fade"
          id="credit-customize-modal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                width: "calc(60vw)",
                maxWidth: "450px",
                background: "#08202E",
              }}
            >
              {/* HEADER */}
              <div
                className="modal-header"
                style={{ padding: "0.35rem 1.5rem" }}
              >
                <h5 className="modal-title" style={{ color: "#FFFFFF" }}>
                  Additional Credit Purchase
                </h5>
                <span
                  className="panel-remove-icon"
                  style={{
                    position: "absolute",
                    top: "10px",
                    zIndex: "1",
                    right: "10px",
                    width: "30px",
                    height: "30px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {}}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
                </span>
              </div>
              {/* BODY */}
              <div style={{ overflowY: "auto" }}>
                <div
                  className="modal-body"
                  style={{ padding: "0.35rem 1.5rem" }}
                >
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      let pData = {
                        asset_count: parseInt(assetCnt),
                        storage_capacity: parseInt(storageCapacity),
                        duration: 30 * duration.split(" ")[0],
                      };
                      dispatch(postAdditionalPurchase(pData)).then((res) => {
                        props.reloadList("ADDITIONAL_PURCHASE");
                      });
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                      <label className="form-label m-0">Asset Count</label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control w-200"
                          value={assetCnt ? assetCnt : null}
                          placeholder="50"
                          onChange={(e) => {
                            e.stopPropagation();
                            setAssetCnt(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                      <label className="form-label m-0">Storage Capacity</label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control w-200"
                          value={storageCapacity ? storageCapacity : null}
                          placeholder="50 MB"
                          onChange={(e) => {
                            e.stopPropagation();
                            setStorageCapacity(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                      <label className="form-label m-0">Duration</label>
                      <div className="">
                        <select
                          className="form-control w-200"
                          onChange={(e) => {
                            setDuration(e.target.value);
                          }}
                        >
                          <option selected value={null}>
                            Select an option
                          </option>
                          <option value="1 Month">1 Month</option>
                          <option value="2 Month">2 Month</option>
                          <option value="3 Month">3 Month</option>
                        </select>
                      </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-end aligh-items-end">
                      <button
                        className="ms-4 btn mx-1 data-source_btn"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        disabled={
                          !assetCnt ||
                          !storageCapacity ||
                          !duration
                        }
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Pricing;
