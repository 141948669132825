import React, { useEffect, useState } from "react";
import TopHeader from "../../components/TopHeader";
import ContainerFluid from "../../components/ContainerFluid";
import CopyRightFooter from "../../components/CopyRightFooter";
import { useNavigate } from "react-router-dom";
import request from "../../utils/request";
import toastr from "toastr";

const Feedback = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [user_name, setUserName] = useState("");
  const [user_phone, setPhone] = useState("");
  const [user_message, setMessage] = useState("");
  const [user_company, setCompany] = useState("");
  const [rating, setRating] = useState(5);

  useEffect(() => {
    const isAuth = !!localStorage.getItem("access_token");
    if (!isAuth) {
      navigate("/login");
      // // console.log("----------not-----authorized-----------");
    }
  });

  return (
    <ContainerFluid>
      <TopHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 mt-4 col-lg-6 col-xl-7 col-sm-12 p-0">
            <div className="sup-feed_form container mt-4 ml-5 mr-5 mx-auto w-75">
              <form
                id="form_feedback"
                onSubmit={async (e) => {
                  e.preventDefault();
                  const payload = {
                    email: email,
                    name: user_name,
                    phone: user_phone,
                    msg: user_message,
                    company: user_company,
                    rating,
                  };

                  await request
                    .post("/api/misc/feedback_email/", payload)
                    .then((resp) => {
                      // // console.log(resp);
                      toastr.success("Thank You For Your Feedback");
                      // setEmail("");
                      // setUserName("");
                      // setPhone("");
                      // setCompany("");
                      // setMessage("");
                      // setRating(5);
                    })
                    .catch((err) => {
                      if (err.response.data.msg) {
                        toastr.error(
                          err.response.data.msg,
                          "Describe your issues"
                        );
                      }
                      if (err.response.data.name) {
                        toastr.error(err.response.data.name, "Name");
                      }
                      if (err.response.data.phone) {
                        toastr.error(err.response.data.phone, "Phone");
                      }
                      if (err.response.data.email) {
                        toastr.error(err.response.data.email, "Email");
                      }
                      if (err.response.data.user_company) {
                        toastr.error(
                          err.response.data.user_company,
                          "User Company"
                        );
                      }
                    });
                }}
              >
                <h3>Send us your Feedback</h3>
                <div className="form-group">
                  <label htmlFor="feedbackInputEmail">Email</label>
                  <input
                    style={{ display: "none" }}
                    name="message_type"
                    id="feedbackInputEmail"
                    defaultValue="Feedback Message"
                  />
                  <input
                    name="user_mail"
                    type="email"
                    className="form-control"
                    id="feedbackInputEmail"
                    aria-describedby="emailHelp"
                    placeholder="Your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <small id="emailHelp" className="form-text text-light">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="feedbackInputName">Tell us your Name</label>
                  <input
                    name="user_name"
                    type="text"
                    className="form-control"
                    id="feedbackInputName"
                    placeholder="Your name"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="feedbackInputNumber">Phone No</label>
                  <input
                    name="user_phone"
                    type="number"
                    className="form-control"
                    id="feedbackInputNumber"
                    placeholder="Your number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="feedbackInputNumber">Company Name</label>
                  <input
                    name="user_company"
                    type="text"
                    className="form-control"
                    id="feedbackInputNumber"
                    placeholder="Your Company"
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="feedbackInputMessage">Short Message</label>
                  <textarea
                    name="user_message"
                    type="text"
                    className="form-control"
                    id="feedbackInputMessage"
                    placeholder="Your message"
                    defaultValue={""}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="rate-radio-btn_section d-flex align-items-center">
                  <div>
                    <label className="mb-3">Rate this app</label>
                  </div>
                </div>
                <div className="rating-css mx-auto">
                  <div className="star-icon">
                    <input
                      type="radio"
                      defaultValue="1 Star"
                      name="user_rating"
                      id="rating1"
                    />
                    <label htmlFor="rating1" className="fa fa-star" />
                    <input
                      type="radio"
                      defaultValue="2 Star"
                      name="user_rating"
                      id="rating2"
                    />
                    <label htmlFor="rating2" className="fa fa-star" />
                    <input
                      type="radio"
                      defaultValue="3 Star"
                      name="user_rating"
                      id="rating3"
                    />
                    <label htmlFor="rating3" className="fa fa-star" />
                    <input
                      type="radio"
                      defaultValue="4 Star"
                      name="user_rating"
                      id="rating4"
                    />
                    <label htmlFor="rating4" className="fa fa-star" />
                    <input
                      type="radio"
                      defaultValue="5 Star"
                      name="user_rating"
                      id="rating5"
                    />
                    <label htmlFor="rating5" className="fa fa-star" />
                  </div>
                </div>
                <div className="text-right mt-4 mb-5">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-7 col-lg-6 col-xl-5 col-sm-12 p-0 d-flex justify-content-center">
            <div className="contact_ui">
              <img
                className="img-fluid"
                src={require("../../static/assets/img/Oil-Gas-Industry.jpg")}
                alt="test"
              />
              <div className="carousel-caption_custom">
                <div className="address_ui">
                  <i className="fa fa-map-marker" />
                  <p className="ml-2">
                    <strong>Address</strong>
                    <br />
                    11251 Northwest Frwy, STE: 310, Houston, TX-77092
                    {/* 13501 Katy Frwy, Suite#1569 Houston, TX-77079 */}
                  </p>
                </div>
                <div className="phone_ui">
                  <i className="fa fa-phone" />
                  <p className="ml-2">
                    <strong>Lets talk</strong>
                    <br />
                    Ph: +1 832 243 5070, +1 713 884 6390
                  </p>
                </div>
                <div>
                  <i className="fa fa-envelope" />
                  <p className="ml-2">
                    <strong>General Support</strong>
                    <br />
                    email: info@idare.io
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CopyRightFooter />
    </ContainerFluid>
  );
};

export default Feedback;
