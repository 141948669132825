import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { appNavigator } from "../../actions/panelActions";
import loader from "../../static/loader.gif";

import ReactModal from "react-modal";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import G_Loader from "../G_Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const AppNavigator = () => {
  const [isVisable, setIsVisible] = useState(false);
  const [projectguid, setProjectGuid] = useState(false);

  const requestPending = useSelector((state) => state.ui.requestPending);

  window.activateAppNavigator = (guid) => {
    setProjectGuid(guid);
    setIsVisible(!isVisable);
  };

  return (
    <ReactModal
      isOpen={isVisable}
      id="analysis__navigator"
      style={{
        overlay: {
          inset: "1",
          backgroundColor: "transparent",
          width: "0",
          position: "fixed !important",
        },
        content: {
          maxWidth: "100% !important",
          maxHeight: "100% !important",
          border: "none",
          background: "transparent",
          height: "60px !important",
          overflow: "hidden",
        },
      }}
    >
      {requestPending ? (
        <div id="UnityModalLoader">
          {/* <G_Loader /> */}
          {/* <HashLoader
            color={"#01e3fe"}
            loading={true}
            css={override}
            size={150}
          /> */}
        </div>
      ) : (
        <Fragment>
          <div className={"asset_specs_modal"} id="ANALYSIS_NAV">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content modal-content_table">
                {requestPending ? (
                  <div className="app-navigator-drawer_menu_icon">
                    <img src={loader} height="20px" width="20px" />
                  </div>
                ) : (
                  <div className="app-navigator-drawer_menu">
                    <div className="app-navigator-drawer_items">
                      <button
                        type="button"
                        title="Engineering"
                        onClick={() =>
                          appNavigator(
                            `/api/projects/${projectguid}/external/idare_engine/`
                          )
                        }
                        className="analysisCustomBTN"
                      >
                        Engineering
                      </button>
                      <button
                        type="button"
                        title="Asset Integrity"
                        onClick={() =>
                          window.open("https://ipim.idareengine.com", "_blank")
                        }
                        className="analysisCustomBTN"
                      >
                        Asset Integrity
                      </button>
                      <button
                        type="button"
                        title="Data Analytics"
                        onClick={() =>
                          appNavigator(
                            `/api/projects/${projectguid}/external/automl/`
                          )
                        }
                        className="analysisCustomBTN"
                      >
                        Data Analytics
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </ReactModal>
  );
};

export default AppNavigator;
