import toastr from "toastr";
import requestAPI from "../utils/request";

export const saveDatasetCSV =
  (panelType, guid, csvURL, fileName, modalCloseCallback) =>
  async (dispatch) => {
    try {
      await requestAPI
        .post(`/api/${panelType}/${guid}/datasets/import_data/csv/`, {
          csv_link: csvURL,
          data_name: fileName,
        })
        .then(() => {
          modalCloseCallback();
          toastr.success("Datasets available now");
        });
    } catch (error) {
      toastr.error("With saving CSV", "Something wrong");
      // console.log("catch", error);
    }
  };

export const fetchAvailableDatasets =
  (panelType, guid, modalCloseCallback) => async (dispatch) => {
    try {
      await requestAPI
        .get(`/api/${panelType}/${guid}/datasets/`)
        .then(({ data }) => {
          dispatch({
            type: `SET_${panelType.toUpperCase()}_DATASETS`,
            payload: data,
          });

          modalCloseCallback();
          toastr.success("Datasets available now");
        });
    } catch (error) {
      toastr.error("With fetching datasets. Try again.", "Something wrong");
      // console.log("catch", error);
    }
  };

export const fetchSelectedDatasetColumns =
  (panelType, guid, datasetGUID) => async (dispatch) => {
    try {
      await requestAPI
        .get(`/api/${panelType}/${guid}/datasets/${datasetGUID}/columns/`)
        .then(({ data }) => {
          dispatch({
            type: "SET_CHART_CONFIG_COLUMNS",
            payload: data.data,
          });
          toastr.success("Columns available now", "Selected Dataset");
        });
    } catch (error) {
      toastr.error("With fetching dataset columns", "Something wrong");
      // console.log("catch", error);
    }
  };

export const createNewChart =
  (payload, panelType, guid, modalCloseCallback) => async (dispatch) => {
    try {
      await requestAPI
        .post(`/api/${panelType}/${guid}/charts/`, payload)
        .then(({ data }) => {
          modalCloseCallback();
          toastr.success("", `New ${payload.chart_type} Chart Created`);
        });
    } catch (error) {
      modalCloseCallback();
      toastr.error("Unable to create chart.", "Something wrong");
      // console.log("catch", error);
    }
  };

export const fetchExistingCharts = (panelType, guid) => async (dispatch) => {
  try {
    await requestAPI
      .get(`/api/${panelType}/${guid}/charts/`)
      .then(({ data: { charts_list } }) => {
        dispatch({
          type: "SET_EXISTING_CHARTS",
          payload: charts_list,
        });
      });
  } catch (error) {
    toastr.error("Unable to fetch charts.", "Something wrong");
    // console.log("catch", error);
  }
};

export const fetchIndicators =
  (panelType, guid, modalCloseCallback) => async (dispatch) => {
    try {
      await requestAPI
        .get(`/api/${panelType}/${guid}/summary/indicators/`)
        .then(({ data }) => {
          toastr.success("Field indicators available now", "🙌 Success");
          dispatch({
            type: "SET_EXISTING_INDICATORS",
            payload: {
              panelType,
              indicators: data,
            },
          });
          modalCloseCallback();
        });
    } catch (error) {
      modalCloseCallback();
      toastr.error("Unable to fetch indicators.", "Something wrong");
      // console.log("catch", error);
    }
  };

export const deleteIndicator =
  (panelType, guid, indicatorGuid) => async (dispatch) => {
    try {
      await requestAPI
        .delete(
          `/api/${panelType}/${guid}/summary/indicators/${indicatorGuid}/`
        )
        .then((res) => {
          toastr.success("Indicator deleted", "🙌 Success");
        });
      dispatch(fetchIndicators(panelType, guid, () => {}));
    } catch (error) {
      toastr.error("Unable to detlete indicators.", "Something wrong");
      // console.log("catch", error);
    }
  };

export const createNewIndicator =
  (panelType, guid, payload, modalCloseCallback) => async (dispatch) => {
    try {
      await requestAPI
        .post(`/api/${panelType}/${guid}/summary/indicators/`, payload)
        .then(({ data }) => {
          modalCloseCallback();
          toastr.success("New indicator created", "🙌 Success");
          dispatch(fetchIndicators(panelType, guid, modalCloseCallback));
        });
    } catch (error) {
      modalCloseCallback();
      toastr.error("Unable to create indicator.", "Something wrong");
      // console.log("catch", error);
    }
  };

export const addNewResources =
  (panelType, guid, payload, modalCloseCallback) => async (dispatch) => {
    try {
      await requestAPI
        .post(`/api/${panelType}/${guid}/documents/`, payload)
        .then((resp) => {
          dispatch(fetchResourceDocs(panelType, guid));
          toastr.success("New resource created", "🙌 Success");
          modalCloseCallback();
        });
    } catch (error) {
      toastr.error("Upload resource", "Something wrong");
      // console.log("catch", error);
    }
  };

export const fetchResourceDocs = (panelType, guid) => async (dispatch) => {
  try {
    await requestAPI
      .get(`/api/${panelType}/${guid}/documents/`)
      .then(({ data }) => {
        dispatch({
          type: "SET_RESOURCE_DOCS",
          payload: data,
        });
        toastr.success("Field resources available", "🙌 Success");
      });
  } catch (error) {
    toastr.error("Unable to fetch resource docs.", "Something wrong");
    // console.log("catch", error);
  }
};

export const fetchSpecification = (panelType, guid) => async (dispatch) => {
  try {
    await requestAPI.get(`/api/${panelType}s/${guid}`).then(({ data }) => {
      const {
        asset_scale,
        design_life,
        name,
        phase,
        reservoir_depth,
        seabed_depth,
        sub_field_name,
        water_depth,
        location,
      } = data;

      dispatch({
        type: "SET_SPECIFICATION",
        payload: {
          panelType,
          specification: {
            asset_scale,
            design_life,
            name,
            phase,
            reservoir_depth,
            seabed_depth,
            sub_field_name,
            water_depth,
            location,
          },
        },
      });
      toastr.success("Field specifications available.", "🙌 Success");
    });
  } catch (error) {
    toastr.error("Unable to fetch specification.", "Something wrong");
    // console.log("catch", error);
  }
};

export const fetchAssetBasics = (projectGuid) => async (dispatch) => {
  try {
    await requestAPI
      .get(
        `/api/web/projects/${projectGuid}/assets?ordering=-updated_at&page=1&limit=30`
      )
      .then(({ data }) => {
        dispatch({
          type: "SET_ASSET_BASIC__METADATA",
          payload: data,
        });
        toastr.success("Field asset basics available", "🙌 Success");
      });
  } catch (error) {
    toastr.error("Unable to fetch asset basics.", "Something wrong");
    // console.log("catch", error);
  }
};

export const fetchPipelineBasics = (projectGuid) => async (dispatch) => {
  try {
    await requestAPI
      .get(
        `/api/web/projects/${projectGuid}/pipelines?ordering=-updated_at&page=1&limit=30`
      )
      .then(({ data }) => {
        dispatch({
          type: "SET_PIPELINE_BASIC__METADATA",
          payload: data,
        });
        toastr.success("Field pipeline basics available", "🙌 Success");
      });
  } catch (error) {
    toastr.error("Unable to fetch pipeline basics.", "Something wrong");
    // console.log("catch", error);
  }
};

export const fetchAssetSpecs = (projectGuid) => async (dispatch) => {
  try {
    await requestAPI
      .get(`/api/web/projects/${projectGuid}/asset_specs/`)
      .then(({ data }) => {
        dispatch({
          type: "SET_ASSET_SPECS__METADATA",
          payload: data,
        });
        toastr.success("Field asset specifications available", "🙌 Success");
      });
  } catch (error) {
    toastr.error("Unable to fetch asset specifications.", "Something wrong");
    // console.log("catch", error);
  }
};

export const fetchPipelineSpecs = (projectGuid) => async (dispatch) => {
  try {
    await requestAPI
      .get(`/api/web/projects/${projectGuid}/pipeline_specs/`)
      .then(({ data }) => {
        dispatch({
          type: "SET_PIPELINE_SPECS__METADATA",
          payload: data,
        });
        toastr.success("Field pipeline specifications available", "🙌 Success");
      });
  } catch (error) {
    toastr.error("Unable to fetch pipeline specifications.", "Something wrong");
    // console.log("catch", error);
  }
};

export const appNavigator = async (api) => {
  await requestAPI
    .post(api, {})
    .then((resp) => {
      window.open(resp.data.external_url, "_blank");
    })
    .catch((err) => {
      // console.log(err);
    });
};
