import React, { useState, useEffect } from "react";
import TopHeader from "../../components/TopHeader";
import ContainerFluid from "../../components/ContainerFluid";
import request from "../../utils/request";

function getProfile() {
  return fetch(process.env.REACT_APP_REST_HOST, {
    headers: {
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("access_token")),
    },
  }).then((data) => data.json());
}

const UserProfile = () => {
  const [userProfile, setUserProfile] = useState([]);

  useEffect(async () => {
    let mounted = true;
    await getProfile()
      .then((profile) => {
        // // console.log(profile);
        if (mounted) {
          setUserProfile(profile);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => (mounted = false);
  }, []);

  return (
    <ContainerFluid>
      <TopHeader />
      {JSON.stringify(userProfile)}
    </ContainerFluid>
  );
};

export default UserProfile;
