import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Charts from "./Charts";
import { searchChartByGuid } from "../../../../actions/componentChartActions";

const C_Chart = (props) => {
  const dispatch = useDispatch();

  const [c_panel, setC_Panel] = useState(null);

  useEffect(() => {
    dispatch(searchChartByGuid(props.chart.guid)).then((res) => {
      let chart_ = res.data;
      chart_.panel_config.defaultPosition = {
        x: 0,
        y: 0,
        height: chart_.panel_config.defaultPosition.height,
        // width: (document.body.clientWidth / 10) * 3 - 22,
        width: chart_.panel_config.defaultPosition.width,
      };
      setC_Panel(chart_);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let comps = document.querySelectorAll(".component-chart")
      if(comps.length && c_panel) {
        let y__ = 0
        let l__ = 0
        let max_w__ = 0
        let max_h__ = 0
        for(let i=0; i< comps.length; i++) {
          // // console.log(i + '    ' + y__)
          // // console.log((parseInt(document.querySelectorAll(".component-chart .rnd-section-chart.react-draggable")[i].style.height.split("px")[0]) + 30) + "px")
          document.querySelectorAll(".component-chart .rnd-section-chart.react-draggable")[i].style.left = l__ + "px"
          l__ += (parseInt(document.querySelectorAll(".component-chart .rnd-section-chart.react-draggable")[i].style.width.split("px")[0]) + 8)

          let cmpW = (document.querySelectorAll(".component-chart .rnd-section-chart.react-draggable")[i].style.width).split("px")[0]
          let cmpH = (document.querySelectorAll(".component-chart .rnd-section-chart.react-draggable")[i].style.height).split("px")[0]

          max_w__ += parseInt(cmpW) + 16
          max_h__ = max_h__ > parseInt(cmpH) ? max_h__ : parseInt(cmpH)
        }
        document.querySelector("#component-chart-modal .modal-body").style.width = max_w__ + "px"
        document.querySelector("#component-chart-modal .modal-body").style.height = max_h__ + 60 + "px"
      }
    }, 2000)
  }, [c_panel]);

  /*useEffect(() => {
    setTimeout(() => {
      let comps = document.querySelectorAll(".component-chart")
      if(comps.length && c_panel) {
        let y__ = 0
        for(let i=0; i< comps.length; i++) {
          // // console.log(i + '    ' + y__)
          // // console.log((parseInt(document.querySelectorAll(".component-chart .rnd-section-chart.react-draggable")[i].style.height.split("px")[0]) + 30) + "px")
          document.querySelectorAll(".component-chart .rnd-section-chart.react-draggable")[i].style.top = y__ + "px"
          y__ += (parseInt(document.querySelectorAll(".component-chart .rnd-section-chart.react-draggable")[i].style.height.split("px")[0]) + 30)
        }
        // document.querySelector("#component-chart-modal .modal-body").style.height = ((document.body.clientHeight / 10) * 6 < y__ ? (document.body.clientHeight / 10) * 6 : y__ ) + "px"
        document.querySelector("#component-chart-modal .modal-body").style.height = ((document.body.clientHeight / 10) * 6 < y__ ? (document.body.clientHeight / 10) * 6 : y__ ) + "px"
      }
    }, 2000)
  }, [c_panel]);*/
  
  return (
    <>
      {c_panel ? (
        <Charts panel={c_panel} panelFieldInfo={props.panelFieldInfo} />
        ) : (
        <></>
      )}
    </>
  );
};

export default C_Chart;
