const initialState = {
  fieldCustomPanels: {
    panels: [],
  },
  isCustomPanelAvailable: false,
};

export default function customPanel(state = initialState, action) {
  switch (action.type) {
    case "SET_CUSTOM_PANEL_AS_INITIAL_STATE":
      return initialState;
    case "LOAD_CUSTOM_PANEL_FROM_API":
      return action.payload;
    case "UPDATE_CUSTOM_PANEL_AVAIILABLE_STATUS":
      return {
        ...state,
        isCustomPanelAvailable: action.payload,
      };
    //   AVAILABLE PANEL LIST
    case "UPDATE_CUSTOM_PANEL_AVAIILABLE_LIST":
      return {
        ...state,
        fieldCustomPanels: action.payload,
      };
    case "UPDATE_CUSTOM_PANEL_AVAIILABLE_LIST_PANELS":
      return {
        ...state,
        fieldCustomPanels: {
          ...state.fieldCustomPanels,
          panels: [...state.fieldCustomPanels.panels, action.payload],
        },
      };
    case "REMOVE_PANEL_FROM_AVAIILABLE_LIST_PANELS":
      return {
        ...state,
        fieldCustomPanels: {
          ...state.fieldCustomPanels,
          panels: [
            ...state.fieldCustomPanels.panels.slice(0, action.payload.index),
            ...state.fieldCustomPanels.panels.slice(action.payload.index + 1),
          ],
        },
      };
    case "UPDATE_CUSTOM_PANEL_RND_ELEMENT_POSITION":
      return {
        ...state,
        fieldCustomPanels: {
          ...state.fieldCustomPanels,
          panels: [
            ...state.fieldCustomPanels.panels.slice(0, action.payload.index),
            {
              data: action.payload.panelData.data,
              panelType: action.payload.panelData.panelType,
              defaultData: action.payload.panelData.defaultData,
              defaultStyle: action.payload.panelData.defaultStyle,
              defaultPosition: action.payload.position,
            },
            ...state.fieldCustomPanels.panels.slice(action.payload.index + 1),
          ],
        },
      };
    case "UPDATE_CUSTOM_PANEL_RND_ELEMENT_CHART_CREATE":
      return {
        ...state,
        fieldCustomPanels: {
          ...state.fieldCustomPanels,
          panels: [
            ...state.fieldCustomPanels.panels.slice(0, action.payload.index),
            {
              data: action.payload.chartData,
              panelType: action.payload.panelData.panelType,
              defaultData: action.payload.panelData.defaultData,
              defaultStyle: action.payload.panelData.defaultStyle,
              defaultPosition: action.payload.panelData.defaultPosition,
            },
            ...state.fieldCustomPanels.panels.slice(action.payload.index + 1),
          ],
        },
      };
    case "UPDATE_CUSTOM_PANEL_RND_ELEMENT_INDICATOR_CREATE":
      return {
        ...state,
        fieldCustomPanels: {
          ...state.fieldCustomPanels,
          panels: [
            ...state.fieldCustomPanels.panels.slice(0, action.payload.index),
            {
              data: action.payload.indicatorData,
              panelType: action.payload.panelData.panelType,
              defaultData: action.payload.panelData.defaultData,
              defaultStyle: action.payload.panelData.defaultStyle,
              defaultPosition: action.payload.panelData.defaultPosition,
            },
            ...state.fieldCustomPanels.panels.slice(action.payload.index + 1),
          ],
        },
      };
    case "UPDATE_CUSTOM_PANEL_RND_ELEMENT_DATA_TABLE_CREATE":
      return {
        ...state,
        fieldCustomPanels: {
          ...state.fieldCustomPanels,
          panels: [
            ...state.fieldCustomPanels.panels.slice(0, action.payload.index),
            {
              data: action.payload.tableData,
              panelType: action.payload.panelData.panelType,
              defaultData: action.payload.panelData.defaultData,
              defaultStyle: action.payload.panelData.defaultStyle,
              defaultPosition: action.payload.panelData.defaultPosition,
            },
            ...state.fieldCustomPanels.panels.slice(action.payload.index + 1),
          ],
        },
      };
    default:
      return state;
  }
}
