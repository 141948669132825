import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatasetColInfo,
  getDatasetColumns,
  updateCustomDashboardPanel,
} from "../../../../../actions/customPanelModify";
import ReactRangeInput from "../../../../Range";
import ReactRangeInputVertical from "../../../../Range/range_vertical";

const MeshColorRange = ({
  panel,
  setReceivedColorProps,
  colorbarscaleClass,
}) => {
  const [val, setVal] = useState(
    panel
      ? [
          panel.panel_config.bar_data.chart_config.cmin,
          panel.panel_config.bar_data.chart_config.cmax,
        ]
      : [0, 10]
  );

  const getMin = () => {
    // return -5
    return parseFloat(panel.panel_config.bar_data.chart_config.actual_cmin) < parseFloat(panel.panel_config.bar_data.chart_config.cmin)
      ? parseFloat(panel.panel_config.bar_data.chart_config.actual_cmin)
      : parseFloat(panel.panel_config.bar_data.chart_config.cmin);
  };

  const getMax = () => {
    return parseFloat(panel.panel_config.bar_data.chart_config.actual_cmax) > parseFloat(panel.panel_config.bar_data.chart_config.cmax)
      ? parseFloat(panel.panel_config.bar_data.chart_config.actual_cmax)
      : parseFloat(panel.panel_config.bar_data.chart_config.cmax);
  };

  return (
    // <></>
    <div style={{ width: "90%", margin: "20px auto 0" }}>
      <ReactRangeInputVertical
        step={panel ? panel.panel_config.bar_data.chart_config.actual_cmax / 1000 : 1}
        min={panel ? getMin() : 0}
        max={panel ? getMax() : 10}
        // min={panel ? panel.panel_config.bar_data.filter_info.range.min : 0}
        // max={panel ? panel.panel_config.bar_data.filter_info.range.max : 10}
        selectedFilteredValue={panel ? val : [3, 6]}
        setSelectedFilteredValue={(val) => {
          setReceivedColorProps(val);
          setVal(val);
        }}
        colorbarscaleClass={colorbarscaleClass}
        height={panel.panel_config.defaultPosition.height / 1.5}
        range={{min: parseFloat(panel.panel_config.bar_data.chart_config.actual_cmin), max: parseFloat(panel.panel_config.bar_data.chart_config.actual_cmax)}}
      />
    </div>
  );
};
export default MeshColorRange;
