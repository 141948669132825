import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserProfile, login } from "../../actions/authActions";
import loader from "../../static/loader.gif";
import axios from "axios";
import decode from "jwt-decode";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_REST_HOST;

const SiginIn = ({ switchComponent, login }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const payload = { email, password };

  const requestPending = useSelector((state) => state.ui.requestPending);

  return (
    <div id="login">
      <form
        className="form_custom corner-button mt-5 mb-5"
        id="login_form"
        onSubmit={async (e) => {
          e.preventDefault();

          dispatch({
            type: "REQUEST_PENDING",
          });

          try {
            const { data } = await axios.post(
              `${baseURL + "/api/auth/login/"}`,
              payload
            );
            localStorage.setItem("access_token", JSON.stringify(data.access));
            localStorage.setItem("refresh_token", JSON.stringify(data.refresh));

            toastr["success"]("Login Success", "", {
              positionClass: "toast-bottom-left",
            });
            dispatch(getUserProfile());
            dispatch({
              type: "REQUEST_RESOLVED",
            });
            navigate("/home", { replace: true });
          } catch (error) {
            window.dispatch({
              type: "REQUEST_RESOLVED",
            });

            if (error.response && error.response.data.detail) {
              return toastr["error"](
                error.response.data.detail,
                "Login Failed",
                {
                  positionClass: "toast-bottom-left",
                }
              );
            }
            if (error.response && error.response.data.error.length) {
              return toastr["error"](
                error.response.data.error[0],
                "Login Failed",
                {
                  positionClass: "toast-bottom-left",
                }
              );
            }
          }
          // login(payload, navigate);
          // navigate("/home", { replace: true });
        }}
      >
        <div className="bottom_border">
          <div>
            <h4 className="header_custom header_corner-button text-uppercase">
              Log In
            </h4>
          </div>
          <div className="form-group_custom mb-3 mt-4">
            <label htmlFor="email" className="form-label text-uppercase fs-6">
              Email address
            </label>
            <input
              type="email"
              id="email"
              autoComplete="off"
              className="form-control form-control_custom"
              placeholder="Email"
              name="email"
              aria-describedby="emailHelp"
              onChange={({ target }) => setEmail(target.value)}
              value={email}
            />
          </div>
          <div className="form-group_custom mb-3 mt-3">
            <div className="d-flex justify-content-between">
              <label
                htmlFor="password"
                className="form-label text-uppercase fs-6"
              >
                Password{" "}
              </label>
              <div className="forgot_password">
                <a
                  className="anchor_text"
                  href="#!"
                  onClick={() => switchComponent("FORGOT_PASSWORD")}
                >
                  Forgot Password
                </a>
              </div>
            </div>
            <input
              type={passwordType}
              required
              className="form-control form-control_custom"
              placeholder="Password"
              name="password"
              id="password"
              autoComplete="off"
              onChange={({ target }) => setPassword(target.value)}
              value={password}
            />
            <span
              toggle="#password"
              className="fa fa-fw fa-eye field-icon toggle-password"
              onClick={() =>
                setPasswordType(
                  passwordType === "password" ? "text" : "password"
                )
              }
            />
          </div>
          <div className="btn_sign_section d-flex justify-content-between align-items-center mt-4">
            <button
              type="submit"
              className="btn button_corner text-uppercase"
              name="signin"
            >
              {requestPending ? (
                <img src={loader} height="20px" alt="" />
              ) : (
                "Sign In"
              )}
            </button>
            <a
              href="#!"
              onClick={() => switchComponent("REGISTER")}
              className="anchor_text text-uppercase float-end"
            >
              Register for Free!
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default connect(null, { login })(SiginIn);
