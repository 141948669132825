import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isJwtExpired } from "jwt-check-expiration";
import { getUserProfile } from "../actions/authActions";

// All Pages
import App from "../pages/App";
import Support from "./Support";
import Documentation from "./Documentation";
import Feedback from "./Feedback";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import NotFound from "./NotFound";
import Login from "./Login";
import Billing from "./Billing";
import Profile from "./Profile";
import EmailVerification from "./EmailVerification";
import UserProfile from "./UserProfile";
import ProjectPanel from "./ProjectPanel";
import ProjectManagement from "./ProjectManagement";
import { logoutUser } from "../actions/authActions";
import BillPayment from "./BillPayment";
import TexasMap from "../pages/BubbleMap";

const PageRoutes = () => {
  const dispatch = useDispatch();
  window.dispatch = dispatch;

  useEffect(() => {
    const isAuth = !!localStorage.getItem("access_token");

    if (!isAuth) {
      logoutUser();
    }

    if (isAuth && isJwtExpired(localStorage.getItem("access_token"))) {
      logoutUser();
    }

    if (isAuth) {
      dispatch(getUserProfile());
    }
  }, [logoutUser]);

  return (
    <Routes>
      <Route path="/home" element={<App />} />
      <Route path="/support" element={<Support />} />
      <Route path="/documentation" element={<Documentation />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsAndConditions />} />
      <Route path="/" element={<Login />} />
      <Route path="/*" element={<NotFound />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/billing" element={<Billing />} />
      <Route path="/bill-payment" element={<BillPayment />} />
      <Route path="/email-verification" element={<EmailVerification />} />
      <Route path="/user-profile" element={<UserProfile />} />
      <Route path="/project-panel" element={<ProjectPanel />} />
      <Route path="/project-management" element={<ProjectManagement />} />
      <Route path="/bubble-map" element={<TexasMap />} />
    </Routes>
  );
};

export default PageRoutes;
