const initialValue = {
  datasets: {
    project: null,
    asset: null,
    pipeline: null,
  },
  indicators: {
    project: null,
    asset: null,
    pipeline: null,
  },
  specification: {
    project: null,
    asset: null,
    pipeline: null,
  },
  config: {
    columns: null,
  },
  existingCharts: null,
  resourceDocs: null,
  metaData: {
    pipelineSpecifications: null,
    assetSpecifications: null,
    assetBasics: null,
    pipelineBasics: null,
  },
};

export default function panelReducer(state = initialValue, action) {
  switch (action.type) {
    case "SET_PROJECT_DATASETS":
      return {
        ...state,
        datasets: {
          ...state.datasets,
          project: action.payload,
        },
      };
    case "SET_ASSET_DATASETS":
      return {
        ...state,
        datasets: {
          ...state.datasets,
          asset: action.payload,
        },
      };
    case "SET_PIPELINE_DATASETS":
      return {
        ...state,
        datasets: {
          ...state.datasets,
          pipeline: action.payload,
        },
      };
    case "SET_CHART_CONFIG_COLUMNS":
      return {
        ...state,
        config: {
          ...state.config,
          columns: action.payload,
        },
      };
    case "SET_EXISTING_CHARTS":
      return {
        ...state,
        existingCharts: action.payload,
      };
    case "SET_EXISTING_INDICATORS":
      return {
        ...state,
        indicators: {
          ...state.indicators,
          [action.payload.panelType]: action.payload.indicators,
        },
      };
    case "SET_RESOURCE_DOCS":
      return {
        ...state,
        resourceDocs: action.payload,
      };
    case "SET_SPECIFICATION":
      return {
        ...state,
        specification: {
          ...state.specification,
          [action.payload.panelType]: action.payload.specification,
        },
      };
    case "SET_ASSET_BASIC__METADATA":
      return {
        ...state,
        metaData: {
          ...state.metaData,
          assetBasics: action.payload,
        },
      };
    case "SET_PIPELINE_BASIC__METADATA":
      return {
        ...state,
        metaData: {
          ...state.metaData,
          pipelineBasics: action.payload,
        },
      };
    case "SET_ASSET_SPECS__METADATA":
      return {
        ...state,
        metaData: {
          ...state.metaData,
          assetSpecifications: action.payload,
        },
      };
    case "SET_PIPELINE_SPECS__METADATA":
      return {
        ...state,
        metaData: {
          ...state.metaData,
          pipelineSpecifications: action.payload,
        },
      };
    default:
      return state;
  }
}
