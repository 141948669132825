import React from "react";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { generateChartLayout } from "./layout.default";
import { generatePieChartLayout } from "./layout_pie.default";

const Plot = createPlotlyComponent(Plotly);

const lineColorList = [
  "FFFFFF",
  "00F8AE",
  "FDDD60",
  "FF6E76",
  "58D9F9",
  "05C091",
  "FF8A45",
  "8D48E3",
  "DD79FF",
  "FFFFFF",
  "00F8AE",
  "FDDD60",
  "FF6E76",
  "58D9F9",
  "05C091",
  "FF8A45",
  "8D48E3",
  "DD79FF",
];

const pieColorList = [
  "#D83D32",
  "#F98187",
  "#283F53",
  "#31566F",
  "#68A2C1",
  "#D83D32",
  "#F98187",
  "#283F53",
  "#31566F",
  "#68A2C1",
  "#D83D32",
  "#F98187",
  "#283F53",
  "#31566F",
  "#68A2C1"
];

const DefaultChart = (props) => {
  const getChartType = () => {
    switch (props.panelInfo) {
      case "line":
        return (
          <Plot
            data={[
              {
                x: [1, 2, 3, 4],
                y: [10, 15, 13, 17],
                type: "scatter",
              },
              {
                x: [1, 2, 3, 4],
                y: [16, 5, 11, 9],
                type: "scatter",
              },
            ]}
            layout={generateChartLayout(
              {
                chart_name: "Line Chart",
                x_axis_label: "Label X",
                y_axis_label: "Label Y",
              },
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "bar":
        return (
          <Plot
            data={[
              {
                x: ["	1_FPSO", "2_Cluster Manifold", "90_SPAR"],
                y: [20, 14, 23],
                type: "bar",
              },
            ]}
            layout={generateChartLayout(
              {
                chart_name: "Bar Chart",
                x_axis_label: "Label X",
                y_axis_label: "Label Y",
              },
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "pie":
        return (
          <Plot
            data={[
              {
                values: [19, 20, 40, 15, 6],
                labels: ["4_Well", "1_FPSO", "1_Rail", "2_Manifold", "Sami ESP"],
                type: "pie",
                name: "Default Pie Chart",
                marker: {
                  colors: pieColorList
                },
              },
            ]}
            layout={generatePieChartLayout(
              {
                chart_name: "Pie Chart",
                x_axis_label: "Label X",
                y_axis_label: "Label Y",
              },
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
      default:
        return (
          <Plot
            data={[
              {
                x: [1, 2, 3, 4],
                y: [10, 15, 13, 17],
                type: "scatter",
              },
              {
                x: [1, 2, 3, 4],
                y: [16, 5, 11, 9],
                type: "scatter",
              },
            ]}
            layout={generateChartLayout(
              {
                chart_name: "Line Chart",
                x_axis_label: "Label X",
                y_axis_label: "Label Y",
              },
              ""
            )}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
        );
    }
  };

  return <>{getChartType()}</>;
};

export default DefaultChart;
