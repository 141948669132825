export const generate3DLineChartLayout = (chart) => ({
  autosize: true,
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 20,
    pad: 10,
  },
  paper_bgcolor: "lightblue",
  plot_bgcolor: "#ddd",
  
  legend: {
    x: 1,
    y: 1,
    xanchor: "right",
    orientation: "h",
    font: {
      family: "Arial, sans-serif",
      size: 10,
      color: "white",
    },
  },
  // hoverlabel: {
  //   bgcolor: "red",
  //   font: {
  //     color: "green"
  //   }
  // },
  scene:{
    camera: {eye: {x: 1.87, y: 0.88, z: 1.00}},
    xaxis: {
      automargin: true,
      zerolinecolor: "#00FFFB",
      zerolinewidth: 2,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      // tickwidth: 15,
      tickcolor: "#B9B8CE",
      tickangle: "auto",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        standoff: 10,
        text: chart.z_axis_label,
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
    },
    yaxis: {
      automargin: true,
      zerolinecolor: "#00FFFB",
      zerolinewidth: 2,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      tickcolor: "#B9B8CE",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        text: chart.x_axis_label,
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
    },
    zaxis: {
      automargin: true,
      zerolinecolor: "#00FFFB",
      zerolinewidth: 2,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      tickcolor: "#B9B8CE",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        text: chart.y_axis_label,
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
    },
  }
});
