import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContainerFluid from "../../components/ContainerFluid";
import TopHeader from "../../components/TopHeader";
import { getIconFromList } from "../../static/IconServe";
import Credit from "./Credit";
import Plan from "./Plan";
import Subscription from "./Subscription";

const Billing = () => {
  return (
    <ContainerFluid>
      <TopHeader />
      <div className="app-content-container billing" id="billing">
        <div className="user__panel--dashboard">
          <div className="project__status"></div>
          <div className="user__panel row">
            <div className="user__panel--col user__panel--info col-md-3">
              <div className="card card-body user__panel--card">
                <div className="custom__loader--container">
                  <h4 className="p-2">Billing Dashboard</h4>
                  <nav>
                    <div
                      className="nav nav-tabs"
                      id="nav-tab"
                      role="tablist"
                      style={{
                        border: "none",
                      }}
                    >
                      <button
                        className="nav-link d-flex justify-content-between w-100 active"
                        id="nav-Subscription-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-Subscription"
                        type="button"
                        role="tab"
                        aria-controls="nav-Subscription"
                        aria-selected="true"
                      >
                        Subscription
                        {getIconFromList("ARROW_RIGHT_ICON", {})}
                      </button>
                      {/* <button
                        className="nav-link d-flex justify-content-between w-100"
                        id="nav-Plan-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-Plan"
                        type="button"
                        role="tab"
                        aria-controls="nav-Plan"
                        aria-selected="false"
                      >
                        Plans
                        {getIconFromList("ARROW_RIGHT_ICON", {})}
                      </button> */}
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <div className="user__panel--col user__panel--content col-md-9">
              <div className="card card-body user__panel--card">
                <div className="custom__loader--container">
                  <div className="tab-content" id="nav-tabContent">
                    {/* ===================================== */}
                    <Subscription />
                    {/* <Credit /> */}
                    {/* <Plan /> */}
                    {/* ===================================== */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerFluid>
  );
};

export default Billing;
