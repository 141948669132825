const initialValue = {
  dashboard: null,
  project_guid: null,
  asset_guid: null,
  pipeline_guid: null,
  component_guid: null,
  name: null,
  summary_operation: null,
  summary_indicators: null,
  project_summary: null,
  project_stat: null,
  summary_specification_data: null,
  summary_table_data: null,
  summary_chart_data: null,
  dataset_list: null,
  dataset_details: null,
  dataset_column_info: null,
  dataset_column_bar_chart: null,
  dataset_column_line_chart: null,
  asset_image_list: null,
  asset_docs_list: null,
};

export default function assetsData(state = initialValue, action) {
  switch (action.type) {
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_INITIAL_STATE_VALUE":
      return {
        dashboard: null,
        project_guid: null,
        asset_guid: null,
        pipeline_guid: null,
        component_guid: null,
        name: null,
        summary_operation: null,
        summary_indicators: null,
        project_summary: null,
        project_stat: null,
        summary_specification_data: null,
        summary_table_data: null,
        summary_chart_data: null,
        dataset_list: null,
        dataset_details: null,
        dataset_column_info: null,
        dataset_column_bar_chart: null,
        dataset_column_line_chart: null,
        asset_image_list: null,
        asset_docs_list: null,
      };
    case "SET_INDIVIDUALL_DASHBOARD_NAME":
      return {
        ...state,
        dashboard: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_PROJECT_GUID":
      return {
        ...state,
        project_guid: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_ASSET_GUID":
      return {
        ...state,
        asset_guid: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_PIPELINE_GUID":
      return {
        ...state,
        pipeline_guid: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_COMPONENT_GUID":
      return {
        ...state,
        component_guid: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_PROJECT_OR_ASSET_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_SUMMARY_OPERATION_DATA":
      return {
        ...state,
        summary_operation: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_SUMMARY_INDICATORS_DATA":
      return {
        ...state,
        summary_indicators: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_SPECIFICATION_DATA":
      return {
        ...state,
        summary_specification_data: action.payload,
      };
    case "SET_PROJECT_DASHBOARD_SUMMARY_DATA":
      return {
        ...state,
        project_summary: action.payload,
      };
    case "SET_PROJECT_DASHBOARD_STAT_DATA":
      return {
        ...state,
        project_stat: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_SUMMARY_TABLE_DATA":
      return {
        ...state,
        summary_table_data: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_SUMMARY_CHART_DATA":
      return {
        ...state,
        summary_chart_data: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_DATASET":
      return {
        ...state,
        dataset_list: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_DATASET_DETAILS":
      return {
        ...state,
        dataset_details: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_DATASET_COLUMN_INFO":
      return {
        ...state,
        dataset_column_info: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_DATASET_COLUMN_BAR_CHART":
      return {
        ...state,
        dataset_column_bar_chart: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_DATASET_COLUMN_LINE_CHART":
      return {
        ...state,
        dataset_column_line_chart: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_SUMMARY_ASSET_IMAGES_LIST":
      return {
        ...state,
        asset_image_list: action.payload,
      };
    case "SET_INDIVIDUALL_ASSET_DASHBOARD_SUMMARY_ASSET_DOCS_LIST":
      return {
        ...state,
        asset_docs_list: action.payload,
      };
    default:
      return state;
  }
}
