const initialValue = {
  panelType: null,
  panelChartList: []
};

export default function chartReducer(state = initialValue, action) {
  switch (action.type) {
    case "SET_CHART_DATA_INITIAL_STATE_VALUE":
      return initialValue;
    case "DEFAULT_CHART_DATA":
      return action.payload;
    default:
      return state;
  }
}
