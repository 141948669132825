import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  createChart,
  getChartData_Bar_UniqueValue,
  getDatasetColumns,
  updateCustomDashboardPanel,
  updateCustomDashboardPanel_CHART_GUID,
  updateCustomDashboardPanel___,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import ModalAT from "../../ModalAT";
import ReactRangeInput from "../../Range";
import ChartLayout from "./chart_layout";
// import Multiselect from 'multiselect-react-dropdown';
import Filter_ from "./filter";

const LineChartUpdateModal = () => {
  const dispatch = useDispatch();
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );
  const isWebglDashboardActive = useSelector(
    (state) => state.customPanelModify.isWebglDashboard
  );
  // --- MODAL CONF ---
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedDatasetColumnList, setSelectedDatasetColumnList] =
    useState(null);

  const [modalTitle, setModalTitle] = useState(null);

  const [chartTitle, setChartTitle] = useState("Lineplot");
  const [xAxisTitle, setXAxisTitle] = useState(null);
  const [yAxisTitle, setYAxisTitle] = useState(null);
  const [zAxisTitle, setZAxisTitle] = useState(null);
  const [xAxisCategory, setXAxisCategory] = useState(null);

  const [xAxisErrMsg, setXAxisErrMsg] = useState(null);
  const [yAxisErrMsg, setYAxisErrMsg] = useState(null);
  const [zAxisErrMsg, setZAxisErrMsg] = useState(null);
  const [xAxisCategories, setXAxisCategories] = useState([
    { group_by: null, name: "", type: "line" },
  ]);
  const [yyAxisCategories, setYYAxisCategories] = useState([
    { group_by: null, name: "", type: "line" },
  ]);
  const [zzAxisCategories, setZZAxisCategories] = useState([
    { group_by: null, name: "", type: "line" },
  ]);

  const [barGroupVariable, setBarGroupVariable] = useState(null);
  const [barGroupOperation, setBarGroupOperation] = useState(null);
  const [barMode, setBarMode] = useState("group");

  const [barPlotType, setBarPlotType] = useState("Vertical");

  const [editFlag, setEditFlag] = useState(false);

  const [chartTypeBarOrLineOrLine3D, setChartType_LineOrBarOrLine3D] =
    useState("line");

  const [addFilter, setAddFilter] = useState(null);
  const [addFilter_columnDataType, setAddFilter_columnDataType] =
    useState(null);
  const [addFilter_columnData, setAddFilter_columnData] = useState(null);
  const [filteredBySearch, setFilteredBySearch] = useState([]);
  const [selectedFilteredValue, setSelectedFilteredValue] = useState([]);
  const [selectedFilteredValue_type, setSelectedFilteredValueType] =
    useState(null);

  const [sortBy_column, setSortBy_column] = useState(null);
  const [sortBy_type, setSortBy_type] = useState(null);

  const [dataPoints, setDataPoints] = useState(5000);

  const [chartLayout, setChartLayout] = useState({
    margin: null,
    paper_bgcolor: "lightblue",
    plot_bgcolor: "#ddd",
    legend: null,
    scene: {
      camera: { eye: { x: 1.87, y: 0.88, z: 1.0 } },
      x_axis: null,
      y_axis: null,
      z_axis: null,
    },
  });

  useEffect(() => {
    if (addFilter && addFilter !== "None") {
      let column_ = JSON.parse(addFilter).col_name;
      dispatch(
        getChartData_Bar_UniqueValue(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          selectedDataset.guid,
          column_
        )
      )
        .then((res) => {
          // console.log(res.data);
          setAddFilter_columnData(res.data.col_data_x);
          setAddFilter_columnDataType(res.data.col_type);

          if (
            panelInfo.panel_config.bar_data &&
            panelInfo.panel_config.bar_data.selected_filter_col_value
          ) {
            // // console.log(panelInfo.panel_config.bar_data.selected_filter_col_value)
            // // console.log(res.data.col_type)
            if (res.data.col_type === "numeric") {
              setSelectedFilteredValue(
                panelInfo.panel_config.bar_data.selected_filter_col_value
              );
            } else {
              let fv = [];
              setTimeout(() => {
                res.data.col_data_x.forEach((el, in_) => {
                  panelInfo.panel_config.bar_data.selected_filter_col_value.forEach(
                    (col_) => {
                      if (el === col_) {
                        if (
                          document.getElementById(
                            "filter_column_unique_value_" + in_
                          )
                        ) {
                          document.getElementById(
                            "filter_column_unique_value_" + in_
                          ).checked = true;
                        }
                        // setSelectedFilteredValue([...selectedFilteredValue, col_]);
                        fv.push(col_);
                      }
                    }
                  );
                });
                setSelectedFilteredValue(fv);
              }, 1000);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
      // // console.log(selectedDataset)
      // // console.log(addFilter)
    } else if (addFilter === "None") {
      // console.log("None");
    }
  }, [addFilter]);

  useEffect(() => {
    if (chartTypeBarOrLineOrLine3D === "line_3D") {
      yyAxisCategories.forEach((el, i) => {
        // el.type = "line_3D";
        // document.querySelector( "#bar_chart_edit__chart_y_axis_category_select_option___type___" + i ).value = "line_3D";
        // document.querySelector( "#bar_chart_edit__chart_y_axis_category_select_option___type___" + i ).value = "line";
        document.querySelector(
          "#bar_chart_edit__chart_y_axis_category_select_option___type___" + i
        ).value = el.type;
      });
    } else {
      yyAxisCategories.forEach((el, i) => {
        // el.type = "line";
        if (
          document.querySelector(
            "#bar_chart_edit__chart_y_axis_category_select_option___type___" + i
          )
        )
          // document.querySelector( "#bar_chart_edit__chart_y_axis_category_select_option___type___" + i ).value = "line";
          document.querySelector(
            "#bar_chart_edit__chart_y_axis_category_select_option___type___" + i
          ).value = el.type;
      });
    }
  }, [chartTypeBarOrLineOrLine3D]);

  window.lineChartEditModalOpen = (panel) => {
    setPanelInfo(panel);
    setModalTitle("Update Combined Chart Data");
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    // console.log(panelInfo.panel_config);
    if (panelInfo.panel_config.data) {
      document.getElementById("bar_chart_edit__chart_title").value =
        panelInfo.panel_config.bar_data ? panelInfo.panel_config.bar_data.chart_name : panelInfo.panel_config.data.chart_name;
      setChartTitle(panelInfo.panel_config.bar_data ? panelInfo.panel_config.bar_data.chart_name : panelInfo.panel_config.data.chart_name);
      // SELEC DATASET
      datasetList.forEach((dataset, index) => {
        if (panelInfo.panel_config.data.dataset === dataset.guid) {
          document.getElementById(
            "bar_chart_edit__chart_dataset_select_option"
          ).value = JSON.stringify(dataset);
          // GET SELECTED DATASET COLUMN
          selectDatasetHandler(JSON.stringify(dataset));
          // SELECT X AXIS COLUMN FLAG
          setEditFlag(true);
        }
      });
      document.getElementById("bar_chart_edit__chart_x_axis_title").value =
        panelInfo.panel_config.data.x_axis_label;
      setXAxisTitle(panelInfo.panel_config.data.x_axis_label);
      document.getElementById("bar_chart_edit__chart_y_axis_title").value =
        panelInfo.panel_config.data.y_axis_label;
      setYAxisTitle(panelInfo.panel_config.data.y_axis_label);
      document.getElementById(
        "bar_chart_edit__chart_x_axis_category_select_option___operation"
      ).value = panelInfo.panel_config.bar_data.bar_group_operation;
      setBarGroupOperation(panelInfo.panel_config.bar_data.bar_group_operation);
      if (panelInfo.panel_config.bar_data) {
        if (panelInfo.panel_config.bar_data.bar_plot_type === "Vertical") {
          document.getElementById(
            "bar-plot-type-toggle-verticle"
          ).checked = true;
          document.getElementById(
            "bar-plot-type-toggle-horizontal"
          ).checked = false;
          setBarPlotType("Vertical");
        } else {
          document.getElementById(
            "bar-plot-type-toggle-verticle"
          ).checked = false;
          document.getElementById(
            "bar-plot-type-toggle-horizontal"
          ).checked = true;
          setBarPlotType("Horizontal");
        }
      }
      if (panelInfo.panel_config.data.y_axis_categories.length > 0) {
        // document.getElementById("bar_chart_edit__chart_group_option_select_option___bar_mode").value = "group";
        setBarMode("group");
      }
      setChartType_LineOrBarOrLine3D(
        panelInfo.panel_config.bar_data.is_three_dym
          ? "line_3D"
          : panelInfo.panel_config.bar_data.chart_type
      );
      setDataPoints(
        panelInfo.panel_config.bar_data.data_points
          ? panelInfo.panel_config.bar_data.data_points
          : 5000
      );
    }
  };

  useEffect(() => {
    if(panelInfo && panelInfo.panel_config.bar_data && selectedDataset) {
      console.log(panelInfo)
      console.log(panelInfo.panel_config.bar_data.dataset_guid)
      console.log(selectedDataset)
      console.log(selectedDataset.guid)
      if(panelInfo.panel_config.bar_data.dataset_guid !== selectedDataset.guid) {
        setEditFlag(false);
        setYYAxisCategories([
          { group_by: null, name: "", type: "line" },
        ]);
        document.querySelector(".__delete_series__").click();

        setZZAxisCategories([
          { group_by: null, name: "", type: "line" },
        ]);
        document.querySelector(".__delete_depth__").click();

        document.getElementById(
          "bar_chart_edit__chart_x_axis_category_select_option___category"
        ).value = "";
        setBarGroupVariable(null);

        document.getElementById(
          "bar_chart_edit__chart_sort_by"
        ).value = "";
        setSortBy_column(null);

      document.getElementById("bar_chart_edit__chart_x_axis_title").value = "";
      setXAxisTitle(null);
      document.getElementById("bar_chart_edit__chart_y_axis_title").value = "";
      setYAxisTitle(null);
      }
    }
    console.log(editFlag)
    if (
      editFlag &&
      selectedDatasetColumnList &&
      panelInfo.panel_config.bar_data
    ) {
      setEditFlag(false);
      selectedDatasetColumnList.forEach((col, index) => {
        if (
          col.col_name === panelInfo.panel_config.bar_data.bar_group_variable
        ) {
          document.getElementById(
            "bar_chart_edit__chart_x_axis_category_select_option___category"
          ).value = JSON.stringify(col);
          setBarGroupVariable(
            panelInfo.panel_config.bar_data.bar_group_variable
          );
        }
        if (
          panelInfo.panel_config.bar_data.selected_filter_column_name &&
          col.col_name ===
            panelInfo.panel_config.bar_data.selected_filter_column_name.col_name
        ) {
          document.getElementById("bar_chart_edit__chart_add_filter").value =
            JSON.stringify(col);
          setAddFilter(JSON.stringify(col));
        }
        if (col.col_name === panelInfo.panel_config.bar_data.sort_by) {
          document.getElementById("bar_chart_edit__chart_sort_by").value =
            JSON.stringify(col);
          setSortBy_column(col.col_name);
        }
      });

      document.getElementById("bar_chart_edit__chart_sort_type").value =
        panelInfo.panel_config.bar_data.sort_type;
      setSortBy_type(panelInfo.panel_config.bar_data.sort_type);

      // YY SERIES / VALUES
      let series = [];
      panelInfo.panel_config.data.y_axis_categories.forEach((y_col) => {
        series.push(y_col);
      });
      // 1 SECOND DELAY FOR DOM RANDER
      setTimeout(() => {
        panelInfo.panel_config.data.y_axis_categories.forEach((y_col, i) => {
          selectedDatasetColumnList.forEach((col, index) => {
            if (y_col.group_by === col.col_name) {
              // console.log(y_col);
              document.querySelector(
                "#bar_chart_edit__chart_y_axis_category_select_option___series_value___" +
                  i
              ).value = JSON.stringify(col);
              document.querySelector(
                "#bar_chart_edit__chart_y_axis_category___input___" + i
              ).value = y_col.name;
              document.querySelector(
                "#bar_chart_edit__chart_y_axis_category_select_option___type___" +
                  i
              ).value = y_col.type === "line_3D" ? "line" : y_col.type;
              // if(y_col.type === "line_3D") {
              //   // setChartType_LineOrBarOrLine3D("line_3D");
              //   setChartType_LineOrBarOrLine3D("line");
              // }
            }
          });
        });
      }, 1000);
      setYYAxisCategories(series);

      // zz SERIES / VALUES
      if (panelInfo.panel_config.bar_data.is_three_dym) {
        setChartType_LineOrBarOrLine3D("line_3D");
        let series_zz = [];
        panelInfo.panel_config.bar_data.z_axis_categories.forEach((z_col) => {
          series_zz.push(z_col);
        });
        // 1 SECOND DELAY FOR DOM RANDER
        setTimeout(() => {
          document.getElementById("bar_chart_edit__chart_z_axis_title").value =
            panelInfo.panel_config.bar_data.z_axis_label;
          setZAxisTitle(panelInfo.panel_config.bar_data.z_axis_label);
          panelInfo.panel_config.bar_data.z_axis_categories.forEach(
            (z_col, i) => {
              selectedDatasetColumnList.forEach((col, index) => {
                if (z_col.group_by === col.col_name) {
                  // console.log(z_col);
                  document.querySelector(
                    "#bar_chart_edit__chart_z_axis_category_select_option___series_value___" +
                      i
                  ).value = JSON.stringify(col);
                  document.querySelector(
                    "#bar_chart_edit__chart_z_axis_category___input___" + i
                  ).value = z_col.name;
                }
              });
            }
          );
        }, 1500);
        setZZAxisCategories(series_zz);
      }
    }
  }, [editFlag, selectedDatasetColumnList]);

  const closeModal = () => {
    setChartTitle(null);
    setXAxisTitle(null);
    setYAxisTitle(null);
    setXAxisCategory(null);
    setSelectedDataset(null);
    setSelectedDatasetColumnList(null);
    setBarMode("group");
    setBarGroupVariable(null);
    setBarGroupOperation(null);
    setChartType_LineOrBarOrLine3D("line");
    setXAxisErrMsg(null);
    setYAxisErrMsg(null);
    setZAxisErrMsg(null);
    setBarPlotType("Vertical");
    setXAxisCategories([{ group_by: null, name: "", type: "line" }]);
    setYYAxisCategories([{ group_by: null, name: "", type: "line" }]);
    setZZAxisCategories([{ group_by: null, name: "", type: "line" }]);
    setAddFilter(null);
    setAddFilter_columnData(null);
    setFilteredBySearch([]);
    setSelectedFilteredValue([]);
    setSelectedFilteredValueType(null);
    setSortBy_column(null);
    setSortBy_type(null);
    setDataPoints(5000);
    setIsOpen(false);
  };

  const selectDatasetHandler = (data) => {
    setYYAxisCategories([
      { group_by: null, name: "", type: "line" },
    ]);

    if (data === "ADD_NEW_DATASET") {
      closeModal();
      document.getElementById("new-dataset-input-modal__opener-el").click();
    } else {
      setSelectedDataset(JSON.parse(data));
      dispatch({
        type: "UPDATE_REQUEST_LOADER_STATE",
        payload: true,
      });
      dispatch(
        getDatasetColumns(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          JSON.parse(data).guid
        )
      )
        .then((res) => {
          setSelectedDatasetColumnList(res.data.data);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        })
        .catch((err) => {
          // console.log(err);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        });
    }
  };

  const applyDatasetClickHandler = () => {
    let chart_data = {
      // chart_type: panelInfo.panel_config.defaultData.chart_type,
      chart_type_api_sep: "line",
      // chart_type: chartTypeBarOrLine,
      is_three_dym: chartTypeBarOrLineOrLine3D === "line_3D" ? true : false,
      chart_type: "line",
      chart_line_type: "new_line",
      chart_name: chartTitle ? chartTitle : "Line Plot",
      dataset_guid: selectedDataset.guid,
      is_active: "true",
      x_axis_label: xAxisTitle ? xAxisTitle : "X",
      y_axis_label: yAxisTitle ? yAxisTitle : "Y",
      z_axis_label: zAxisTitle ? zAxisTitle : "Y",
      x_axis_category: xAxisCategory ? xAxisCategory : "no data",
      x_axis_categories: barMode === "default" ? [] : xAxisCategories,
      y_axis_categories:
        barMode === "default"
          ? []
          : yyAxisCategories.filter((yy) => yy.group_by !== null),
      z_axis_categories:
        barMode === "default"
          ? []
          : zzAxisCategories.filter((zz) => zz.group_by !== null),
      bar_mode: barMode,
      bar_group_variable: barGroupVariable,
      // bar_group_operation: chartTypeBarOrLineOrLine3D === "line_3D"? null : barGroupOperation,
      bar_group_operation: barGroupOperation,
      // x_axis_categories: xAxisCategories,
      bar_plot_type: barPlotType,
      selected_filter_column_name:
        addFilter && addFilter !== "None" ? JSON.parse(addFilter) : null,
      selected_filter_col_value:
        addFilter && addFilter !== "None" ? selectedFilteredValue : [],
      selected_filter_col_value_type:
        selectedFilteredValue_type && addFilter !== "None"
          ? selectedFilteredValue_type
          : null,
      sort_by: sortBy_column,
      sort_type: sortBy_type,
      data_points:
        dataPoints === "" || dataPoints === null || parseInt(dataPoints) === 0
          ? 5000
          : dataPoints,
    };
    // console.log(chart_data);
    // console.log(chartLayout);
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    // dispatch(
    //   createChart(
    //     panelFieldInfo.panel_type,
    //     panelFieldInfo.panel_type_guid,
    //     chart_data
    //   )
    // )
    //   .then((res) => {
    //     panelInfo.panel_config.data = res.data;
    //     panelInfo.panel_config.bar_data = chart_data;
    //     panelInfo.panel_config.chart_layout = chartLayout;
    //     dispatch(
    //       updateCustomDashboardPanel(
    //         activeTabData.guid,
    //         panelInfo.guid,
    //         panelInfo.panel_config
    //       )
    //     );
    //     dispatch(
    //       updateCustomDashboardPanel_CHART_GUID(
    //         activeTabData.guid,
    //         panelInfo.guid,
    //         res.data.guid
    //       )
    //     );
    //     closeModal();
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //   });
    if (!panelInfo.panel_config.data || panelInfo.panel_config.data.dataset !== selectedDataset.guid) {
      dispatch(
        createChart(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          chart_data
        )
      )
        .then((res) => {
          panelInfo.panel_config.data = res.data;
          panelInfo.panel_config.bar_data = chart_data;
          dispatch(
            updateCustomDashboardPanel___(activeTabData.guid, panelInfo.guid, {
              chart_guid: res.data.guid,
              panel_config: panelInfo.panel_config,
            })
          ).then(() => {
            closeModal();
          })
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      panelInfo.panel_config.data = panelInfo.panel_config.data;
      panelInfo.panel_config.data.dataset = selectedDataset.guid;
      panelInfo.panel_config.bar_data = chart_data;
      dispatch(
        updateCustomDashboardPanel(
          activeTabData.guid,
          panelInfo.guid,
          panelInfo.panel_config
        )
      );
      closeModal();
    }
  };

  // const handleFilterSearch = (searchInput) => {
  //   const matches = addFilter_columnData.filter((s) =>
  //     s.toLowerCase().includes(searchInput.toLowerCase())
  //   );
  //   setFilteredBySearch(matches);
  //   addFilter_columnData.forEach((e, index) => {
  //     if(document.getElementById("filter_column_unique_value_" + index)) {
  //       document.getElementById("filter_column_unique_value_" + index).checked = false;
  //     }
  //     setSelectedFilteredValue([]);
  //   })
  // }

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_bar_chart_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle={modalTitle}
          contentLabel={modalTitle}
          ariaHideApp={false}
        >
          <div className="chart-plot-modal-container">
            <div className="row form__row">
              <div className="col-md-6 pr-2">
                <div className="form__group">
                  <label>Chart Title</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_title"
                    placeholder="Chart Title"
                    defaultValue={"Line plot"}
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 pl-1">
                <div className="form__group">
                  <label>Select Data*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_dataset_select_option"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      selectDatasetHandler(e.target.value);
                    }}
                  >
                    <option value={null} selected disabled>
                      Select dataset
                    </option>
                    {!isWebglDashboardActive ? (
                      <option value={"ADD_NEW_DATASET"}>Add New Dataset</option>
                    ) : (
                      <></>
                    )}
                    <optgroup label="Uploaded Dataset">
                      {datasetList.map((dataset, index) => (
                        <option value={JSON.stringify(dataset)} key={index}>
                          {dataset.name}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
            {/* CATEGORY */}
            <div className="row form__row mt-2 align-items-baseline">
              <div className="col-md-6 pr-2">
                <div className="form__group">
                  <label>Select Category or Value (X axis)*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_x_axis_category_select_option___category"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      // console.log(e.target.value);
                      setBarGroupVariable(JSON.parse(e.target.value).col_name);

                      document.getElementById(
                        "bar_chart_edit__chart_x_axis_title"
                      ).value = JSON.parse(e.target.value).col_name;
                      setXAxisTitle(JSON.parse(e.target.value).col_name);

                      // SORT BY & SORT TYPE
                      document.getElementById(
                        "bar_chart_edit__chart_sort_by"
                      ).value = e.target.value;
                      setSortBy_column(JSON.parse(e.target.value).col_name);
                      document.getElementById(
                        "bar_chart_edit__chart_sort_type"
                      ).value = "asc";
                      setSortBy_type("asc");
                    }}
                  >
                    <option value="" disabled selected>
                      Please Select
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              {/* OPERATION */}
              {/* <div className="col-md-6 pl-1">
              <div className="form__group">
                <label>Select Operation*</label>
              </div>
              <div className="form__group">
                <select
                  className="custom-select"
                  id="bar_chart_edit__chart_x_axis_category_select_option___operation"
                  disabled={chartTypeBarOrLineOrLine3D === "line_3D" ? "disabled" : false}
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setBarGroupOperation(e.target.value !== "None" ? e.target.value : null);
                  }}
                >
                  <option value="count" selected>Count</option>
                  <option value="sum">Sum</option>
                  <option value="avg">Average</option>
                  <option value={null}>None</option>
                </select>
              </div>
            </div> */}
            </div>
            {/* YY SERIES OR VALUES & OPERATION */}
            <div className="row form__row mt-4 mb-2">
              <div className="col-md-12">
                <div className="d-flex justify-content-between mb-2">
                  <div className="col-md-4 pl-0 pr-2">
                    <div className="form__group">
                      <label>
                        Add Series or Value (<strong>Y Axis</strong>)
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 pl-1 pr-2">
                    <div className="form__group">
                      <label>Type</label>
                    </div>
                  </div>
                  <div className="col-md-4 pl-1 pr-0">
                    <div className="form__group">
                      <label>Series or Line Name</label>
                    </div>
                  </div>
                </div>
                {yyAxisCategories.map((y_cat, index) => (
                  <div
                    className="d-flex justify-content-between mb-2"
                    key={index}
                  >
                    <div className="col-md-4 pl-0 pr-2">
                      {/* <div className="form__group">
                      <label>Add Series or Value</label>
                    </div> */}
                      <div className="form__group">
                        <select
                          className="custom-select"
                          id={
                            "bar_chart_edit__chart_y_axis_category_select_option___series_value___" +
                            index
                          }
                          onChange={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let y_datas = yyAxisCategories;
                            y_datas[index].group_by = JSON.parse(
                              e.target.value
                            ).col_name;
                            setYYAxisCategories(y_datas);
                            // PREDEFINED INPUT VALUE
                            yyAxisCategories[index].name = JSON.parse(
                              e.target.value
                            ).col_name;
                            document.querySelector(
                              "#bar_chart_edit__chart_y_axis_category___input___" +
                                index
                            ).value = JSON.parse(e.target.value).col_name;

                            if (index === 0) {
                              document.getElementById(
                                "bar_chart_edit__chart_y_axis_title"
                              ).value = JSON.parse(e.target.value).col_name;
                              setYAxisTitle(
                                JSON.parse(e.target.value).col_name
                              );
                            }
                          }}
                        >
                          <option value="" selected disabled>
                            Add Series or Value
                          </option>
                          {selectedDatasetColumnList ? (
                            <>
                              {selectedDatasetColumnList.map((col, index_) => (
                                <>
                                  <option
                                    value={JSON.stringify(col)}
                                    key={index_}
                                  >
                                    {col.col_name}
                                  </option>
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 pl-1 pr-2">
                      {/* <div className="form__group">
                      <label>Type</label>
                    </div> */}
                      <div className="form__group">
                        <div className="form__group">
                          <select
                            className="custom-select"
                            id={
                              "bar_chart_edit__chart_y_axis_category_select_option___type___" +
                              index
                            }
                            onChange={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              yyAxisCategories[index].type = e.target.value;
                              // if(e.target.value === "line_3D") {
                              //   setChartType_LineOrBarOrLine3D("line_3D");
                              // } else {
                              //   setChartType_LineOrBarOrLine3D("line");
                              // }
                            }}
                          >
                            <option value="line" selected>
                              Line
                            </option>
                            <option
                              value="bar"
                              disabled={
                                chartTypeBarOrLineOrLine3D === "line_3D"
                              }
                            >
                              Bar
                            </option>
                            <option value="scatter">Scatter</option>
                            {/* <option value="line_3D">Line 3D</option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pl-1 pr-0 d-flex justify-content-between">
                      {/* <div className="form__group">
                      <label>Series or Line Name</label>
                    </div> */}
                      <div className="form__group">
                        <input
                          type="text"
                          className="form-control form__control"
                          id={
                            "bar_chart_edit__chart_y_axis_category___input___" +
                            index
                          }
                          placeholder="Series Name"
                          onChange={(e) => {
                            e.stopPropagation();
                            yyAxisCategories[index].name = e.target.value;
                          }}
                        />
                      </div>
                      <div className="form__group">
                        <button
                          className="btn btn__icon__delete position-relative  __delete_series__"
                          onClick={(e) => {
                            e.stopPropagation();
                            index !== 0
                              ? (yyAxisCategories[index].group_by = null)
                              : (yyAxisCategories[index].group_by = "");
                            yyAxisCategories[index].name = "";
                            yyAxisCategories[index].type = "line";
                            if (index !== 0) {
                              document.getElementById(
                                "bar_chart_edit__chart_y_axis_category_select_option___series_value___" +
                                  index
                              ).parentNode.parentNode.parentNode.className =
                                "d-flex justify-content-between mb-2 _twin_hide_";
                            } else if (index === 0) {
                              document.getElementById(
                                "bar_chart_edit__chart_y_axis_category_select_option___series_value___" +
                                  index
                              ).value = null;
                              document.getElementById(
                                "bar_chart_edit__chart_y_axis_category_select_option___type___" +
                                  index
                              ).value = "line";
                              document.getElementById(
                                "bar_chart_edit__chart_y_axis_category___input___" +
                                  index
                              ).value = "";
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="red"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fillRule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="form__group__flex d-flex justify-content-between mb-1">
                  <span style={{ color: "red" }}>
                    {yAxisErrMsg ? yAxisErrMsg : ""}
                  </span>
                </div>
                <div className="form__group__flex d-flex justify-content-end mb-1">
                  <button
                    className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        yyAxisCategories[yyAxisCategories.length - 1].group_by
                      ) {
                        setYAxisErrMsg(null);
                        setYYAxisCategories([
                          ...yyAxisCategories,
                          { group_by: null, name: "", type: "line" },
                        ]);
                      } else {
                        setYAxisErrMsg("Fill previous values");
                        setTimeout(() => {
                          setYAxisErrMsg(null);
                        }, 3000);
                      }
                      // console.log(yyAxisCategories);
                    }}
                  >
                    Add Series or Values {getIconFromList("PLUS_SQUARE", {})}
                  </button>
                </div>
              </div>
            </div>
            {/* ZZ SERIES OR VALUES & OPERATION */}
            {/* {
              chartTypeBarOrLineOrLine3D === "line_3D" ? 
              <> */}
            <div className="row form__row mt-4 mb-2">
              <div className="col-md-12">
                <div className="d-flex justify-content-between mb-2">
                  <div className="col-md-4 pl-0 pr-2">
                    <div className="form__group">
                      <label>
                        Add Depth (<strong>Z Axis</strong>)
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 pl-1 pr-2">
                    <div className="form__group">
                      <label>Depth or Line Name</label>
                    </div>
                  </div>
                  <div className="col-md-4 pl-1 pr-0"></div>
                </div>
                {zzAxisCategories.map((z_cat, index) => (
                  <div
                    className="d-flex justify-content-between mb-2"
                    key={index}
                  >
                    <div className="col-md-4 pl-0 pr-2">
                      {/* <div className="form__group">
                          <label>Add Depth</label>
                        </div> */}
                      <div className="form__group">
                        <select
                          className="custom-select"
                          id={
                            "bar_chart_edit__chart_z_axis_category_select_option___series_value___" +
                            index
                          }
                          onChange={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let z_datas = zzAxisCategories;
                            z_datas[index].group_by = JSON.parse(
                              e.target.value
                            ).col_name;
                            setZZAxisCategories(z_datas);
                            // PREDEFINED INPUT VALUE
                            zzAxisCategories[index].name = JSON.parse(
                              e.target.value
                            ).col_name;
                            document.querySelector(
                              "#bar_chart_edit__chart_z_axis_category___input___" +
                                index
                            ).value = JSON.parse(e.target.value).col_name;

                            setChartType_LineOrBarOrLine3D("line_3D");
                            setTimeout(() => {
                              if (index === 0) {
                                document.getElementById(
                                  "bar_chart_edit__chart_z_axis_title"
                                ).value = JSON.parse(e.target.value).col_name;
                                setZAxisTitle(
                                  JSON.parse(e.target.value).col_name
                                );
                              }
                            }, 200);
                          }}
                        >
                          <option value="" selected disabled>
                            Add Z Series or Value
                          </option>
                          {selectedDatasetColumnList ? (
                            <>
                              {selectedDatasetColumnList.map((col, index_) => (
                                <>
                                  <option
                                    value={JSON.stringify(col)}
                                    key={index_}
                                  >
                                    {col.col_name}
                                  </option>
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 pl-1 pr-2 d-flex justify-content-between">
                      {/* <div className="form__group">
                            <label>Depth or Line Name</label>
                          </div> */}
                      <div className="form__group">
                        <input
                          type="text"
                          className="form-control form__control"
                          id={
                            "bar_chart_edit__chart_z_axis_category___input___" +
                            index
                          }
                          placeholder="Z Series Name"
                          onChange={(e) => {
                            e.stopPropagation();
                            zzAxisCategories[index].name = e.target.value;
                          }}
                        />
                      </div>
                      <div className="form__group">
                        <button
                          className="btn btn__icon__delete position-relative __delete_depth__"
                          onClick={(e) => {
                            e.stopPropagation();
                            // zzAxisCategories[index].group_by = null;
                            zzAxisCategories[index].name = "";
                            // if(index !== 0) {
                            //   document.getElementById("bar_chart_edit__chart_z_axis_category_select_option___series_value___" + index).parentNode.parentNode.parentNode.className = "d-flex justify-content-between mb-2 _twin_hide_"
                            // }
                            document.getElementById(
                              "bar_chart_edit__chart_z_axis_category_select_option___series_value___" +
                                index
                            ).value = null;
                            document.getElementById(
                              "bar_chart_edit__chart_z_axis_category___input___" +
                                index
                            ).value = "";
                            setChartType_LineOrBarOrLine3D("line");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="red"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fillRule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-4 pl-1 pr-0"></div>
                  </div>
                ))}
                {/* <div className="form__group__flex d-flex justify-content-between mb-1">
                    <span style={{ color: "red" }}>
                      {zAxisErrMsg ? zAxisErrMsg : ""}
                    </span>
                  </div>
                  <div className="form__group__flex d-flex justify-content-center mb-1">
                    <button
                      className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (zzAxisCategories[zzAxisCategories.length - 1].group_by) {
                          setZAxisErrMsg(null);
                          setZZAxisCategories([ ...zzAxisCategories, { group_by: null, name: "", type: "line" } ]);
                        } else {
                          setZAxisErrMsg("Fill previous values");
                          setTimeout(() => {
                            setZAxisErrMsg(null);
                          }, 3000);
                        }
                      }}
                    >
                      Add Depth or Z Value {getIconFromList("PLUS_SQUARE", {})}
                    </button>
                  </div> */}
              </div>
            </div>
            {/* </> : <></>
            } */}
            {/* OPERATION */}
            <div className="row form__row mt-2">
              <div className="col-md-4 pr-2">
                <div className="form__group">
                  <label>Select Operation*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_x_axis_category_select_option___operation"
                    // disabled={chartTypeBarOrLineOrLine3D === "line_3D" ? "disabled" : false}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setBarGroupOperation(
                        e.target.value !== "None" ? e.target.value : null
                      );
                    }}
                  >
                    <option value={null} selected>
                      None
                    </option>
                    <option value="count">Count</option>
                    <option value="sum">Sum</option>
                    <option value="mean">Average</option>
                  </select>
                </div>
              </div>
            </div>
            {/* ------------- FILTERING ------------- */}
            <Filter_
              bar_data={panelInfo.panel_config.bar_data}
              setSelectedFilteredValue={setSelectedFilteredValue}
              addFilter_columnData={addFilter_columnData}
              setAddFilter_columnData={setAddFilter_columnData}
              addFilter_columnDataType={addFilter_columnDataType}
              setAddFilter_columnDataType={setAddFilter_columnDataType}
              setAddFilter={setAddFilter}
              selectedDatasetColumnList={selectedDatasetColumnList}
              selectedFilteredValue={selectedFilteredValue}
              selectedFilteredValue_type={selectedFilteredValue_type}
              setSelectedFilteredValueType={setSelectedFilteredValueType}
            />
            {/* ------------------- SORT ------------------- */}
            <div className="row form__row mt-2">
              <div className="col-md-4 pr-2">
                <div className="form__group">
                  <label>Sort By</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_sort_by"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSortBy_column(JSON.parse(e.target.value).col_name);

                      document.getElementById(
                        "bar_chart_edit__chart_sort_type"
                      ).value = "asc";
                      setSortBy_type("asc");
                    }}
                  >
                    <option value="" selected disabled>
                      Please select
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index_) => (
                          <>
                            <option value={JSON.stringify(col)} key={index_}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-4 pl-1 pr-1">
                <div className="form__group">
                  <label>Sort Type</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_sort_type"
                    disabled={sortBy_column === null}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSortBy_type(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      None
                    </option>
                    <option value="asc">Ascending</option>
                    <option value="des">Descending</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 pl-1">
                <div className="form__group">
                  <label>Data Points</label>
                </div>
                <div className="form__group">
                  <input
                    type="number"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_data_point"
                    min={100}
                    value={dataPoints}
                    placeholder="5000"
                    onChange={(e) => {
                      e.stopPropagation();
                      setDataPoints(
                        parseInt(e.target.value) > 0 ? e.target.value : ""
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            {/* X Y TITLE */}
            <div className="row form__row mt-2">
              <div
                className={
                  chartTypeBarOrLineOrLine3D === "line_3D"
                    ? "col-md-4 pr-2"
                    : "col-md-6 pr-2"
                }
              >
                <div className="form__group">
                  <label>Category axis Label</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_x_axis_title"
                    placeholder="Horizontal (X) axis Label"
                    onChange={(e) => {
                      e.stopPropagation();
                      setXAxisTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className={
                  chartTypeBarOrLineOrLine3D === "line_3D"
                    ? "col-md-4 pr-2 pl-1"
                    : "col-md-6 pl-1"
                }
              >
                <div className="form__group">
                  <label>Series/Value axis Label</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_y_axis_title"
                    placeholder="Vertical (Y) axis Label"
                    onChange={(e) => {
                      e.stopPropagation();
                      setYAxisTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
              {chartTypeBarOrLineOrLine3D === "line_3D" ? (
                <>
                  <div className="col-md-4 pl-1">
                    <div className="form__group">
                      <label>Depth Label</label>
                    </div>
                    <div className="form__group">
                      <input
                        type="text"
                        className="form-control form__control"
                        id="bar_chart_edit__chart_z_axis_title"
                        placeholder="Depth Axis Label"
                        onChange={(e) => {
                          e.stopPropagation();
                          setZAxisTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* BAR PLOT TYPE - VERTICLE / HORAIZONTAL */}
            {chartTypeBarOrLineOrLine3D !== "line_3D" ? (
              <>
                <div className="row form__row mt-2 pl-0">
                  <div className="col-md-6 pr-2 d-flex align-items-center justify-content-between">
                    <label htmlFor="" className="form__label m-0">
                      Select Chart Orientation*
                    </label>
                  </div>
                  <div className="col-md-6 pl-1 d-flex align-items-center justify-content-between">
                    <div className="form-check mr-4">
                      <input
                        className="form-check-input"
                        id="bar-plot-type-toggle-verticle"
                        type="checkbox"
                        defaultChecked={"checked"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            document.getElementById(
                              "bar-plot-type-toggle-horizontal"
                            ).checked = false;
                            setBarPlotType("Vertical");
                          }
                        }}
                      />
                      <label className="form-check-label">
                        Vertical (Column)
                      </label>
                    </div>
                    <div className="form-check mr-2">
                      <input
                        className="form-check-input"
                        id="bar-plot-type-toggle-horizontal"
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            document.getElementById(
                              "bar-plot-type-toggle-verticle"
                            ).checked = false;
                            setBarPlotType("Horizontal");
                          }
                        }}
                      />
                      <label className="form-check-label">
                        Horizontal (Bar)
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {/* <ChartLayout
              isZAxisAvailable={
                chartTypeBarOrLineOrLine3D === "line_3D" ? true : false
              }
              chartLayout={chartLayout}
              setChartLayout={setChartLayout}
            /> */}
            {/* SUBMIT */}
            <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
              <button
                className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
                disabled={!selectedDataset || !xAxisTitle || !yAxisTitle}
                onClick={() => {
                  applyDatasetClickHandler();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineChartUpdateModal);

export const Filter_Numaric = () => {
  return <>Test</>;
};
