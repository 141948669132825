import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

const ChartLayout_Legend = () => {
  const [legend, setLegend] = useState({
    x: 1,
    y: 1.3,
    xanchor: "right",
    // xanchor: "center",
    orientation: "h", //To place the legend below chart
    font: {
      family: "Arial, sans-serif",
      size: 10,
      color: "white",
    },
  });

  return (
    <>
      <div className="row form__row mt-4 pr-2">
        <div className="col-md-12 pr-2">
          <div className="form__group">
            <label>
              <strong>Legend</strong>
            </label>
          </div>
        </div>
      </div>
      {/* <div className="row form__row mt-0 pr-2">
        <div className="col-md-3 pr-2">
          <div className="form__group">
            <label>X Value</label>
          </div>
          <div className="form__group">
            <input
              type="text"
              className="form-control form__control"
              placeholder="1"
              onChange={(e) => {
                e.stopPropagation();
              }}
            />
          </div>
        </div>
        <div className="col-md-3 pr-2 pl-1">
          <div className="form__group">
            <label>Y Value</label>
          </div>
          <div className="form__group">
            <input
              type="text"
              className="form-control form__control"
              placeholder="1.3"
              onChange={(e) => {
                e.stopPropagation();
              }}
            />
          </div>
        </div>
        <div className="col-md-3 pr-2 pl-1">
          <div className="form__group">
            <label>X Anchor</label>
          </div>
          <div className="form__group">
            <select
              className="custom-select"
              onChange={(e) => {
                e.stopPropagation();
              }}
            >
              <option value="right" selected>
                Right
              </option>
              <option value="left">Left</option>
              <option value="center">Center</option>
            </select>
          </div>
        </div>
        <div className="col-md-3 pr-2 pl-1">
          <div className="form__group">
            <label>Orientation</label>
          </div>
          <div className="form__group">
            <select
              className="custom-select"
              onChange={(e) => {
                e.stopPropagation();
              }}
            >
              <option value="h" selected>
                Horizontal
              </option>
              <option value="v">Vertical</option>
            </select>
          </div>
        </div>
      </div> */}
      <div className="row form__row mt-2 pr-2">
        <div className="col-md-3 pr-2">
          <div className="form__group">
            <label>Font Size</label>
          </div>
          <div className="form__group">
            <input
              type="text"
              className="form-control form__control"
              placeholder="10"
              onChange={(e) => {
                e.stopPropagation();
              }}
            />
          </div>
        </div>
        {/* <div className="col-md-3 pr-2 pl-1">
          <div className="form__group">
            <label>Font Color</label>
          </div>
          <div className="form__group">
            <select
              className="custom-select"
              onChange={(e) => {
                e.stopPropagation();
              }}
            >
              <option value="h" selected>
                Horizontal
              </option>
              <option value="v">Vertical</option>
            </select>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ChartLayout_Legend;
