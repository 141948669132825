import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  createChart,
  getChartData_Bar_UniqueValue,
  getDatasetColInfo,
  getDatasetColumns,
  updateChart,
  updateCustomDashboardPanel,
  updateCustomDashboardPanel_CHART_GUID,
  updateCustomDashboardPanel___,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import ModalAT from "../../ModalAT";
import Filter_ from "./filter";
import DataFormatImg from "../../Images/data_format.png";
import SurfaceImg from "../../Images/surface_mesh.png";
import { Range } from "react-range";
import ReactRangeInput from "../../Range";
import toastr from "toastr";

const MeshChartUpdateModal = () => {
  const dispatch = useDispatch();
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );
  const isWebglDashboardActive = useSelector(
    (state) => state.customPanelModify.isWebglDashboard
  );
  // --- MODAL CONF ---
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedDatasetColumnList, setSelectedDatasetColumnList] =
    useState(null);

  const [modalTitle, setModalTitle] = useState(null);
  const [chartTitle, setChartTitle] = useState(null);
  const [chartDataMesh, setChartDataMesh] = useState({
    x: null,
    y: null,
    z: null,
    i: null,
    j: null,
    k: null,
    value: null,
    cmin: null,
    cmax: null,
    color_plate: "rdylbl",
    node: null,
    filter_by: null,
  });
  const [chartLayoutMesh, setChartLayoutMesh] = useState({
    aspect_ratio: {
      x: 1,
      y: 1,
      z: 1,
    },
  });
  const [editFlag, setEditFlag] = useState(false);
  const [filterInfoByColumn, setFilterInfoByColumn] = useState(null);
  const [filterRangeByColumn, setFilterRangeByColumn] = useState([]);

  useEffect(() => {
    if (filterRangeByColumn.length) {
    }
  }, [filterRangeByColumn]);

  window.meshChartEditModalOpen = (panel) => {
    setDefault();
    setPanelInfo(panel);
    setModalTitle("Update Mesh Plot Data");
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    setDefault();
    if (panelInfo.panel_config.data) {
      setChartTitle(panelInfo.panel_config.bar_data.chart_name);

      // SELEC DATASET
      datasetList.forEach((dataset, index) => {
        if (panelInfo.panel_config.data.dataset === dataset.guid) {
          document.getElementById(
            "bar_chart_edit__chart_dataset_select_option"
          ).value = JSON.stringify(dataset);
          // GET SELECTED DATASET COLUMN
          selectDatasetHandler(JSON.stringify(dataset));
          // SELECT X AXIS COLUMN FLAG
          setEditFlag(true);
        }
      });
      setChartDataMesh({
        x: panelInfo.panel_config.bar_data.chart_config.x,
        y: panelInfo.panel_config.bar_data.chart_config.y,
        z: panelInfo.panel_config.bar_data.chart_config.z,
        i: panelInfo.panel_config.bar_data.chart_config.i,
        j: panelInfo.panel_config.bar_data.chart_config.j,
        k: panelInfo.panel_config.bar_data.chart_config.k,
        value: panelInfo.panel_config.bar_data.chart_config.value,
        cmin: panelInfo.panel_config.bar_data.chart_config.cmin,
        cmax: panelInfo.panel_config.bar_data.chart_config.cmax,
        color_plate: panelInfo.panel_config.bar_data.chart_config.color_plate
          ? panelInfo.panel_config.bar_data.chart_config.color_plate
          : "rdylbl",
        node: panelInfo.panel_config.bar_data.chart_config.node,
        filter_by: panelInfo.panel_config.bar_data.chart_config.filter_by,
      });

      setChartLayoutMesh({
        aspect_ratio: {
          x: panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.x
            ? panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.x
            : 1,
          y: panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.y
            ? panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.y
            : 1,
          z: panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.z
            ? panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.z
            : 1,
        },
      });

      document.getElementById("cord_min_value").value =
        panelInfo.panel_config.bar_data.chart_config.cmin;
      document.getElementById("cord_max_value").value =
        panelInfo.panel_config.bar_data.chart_config.cmax;
      document.getElementById("cord_color").value = panelInfo.panel_config
        .bar_data.chart_config.color_plate
        ? panelInfo.panel_config.bar_data.chart_config.color_plate
        : "rdylbl";

      document.getElementById("cord_aspect_ratio_x").value = panelInfo
        .panel_config.bar_data.chart_layout.aspect_ratio.x
        ? panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.x
        : 1;
      document.getElementById("cord_aspect_ratio_y").value = panelInfo
        .panel_config.bar_data.chart_layout.aspect_ratio.y
        ? panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.y
        : 1;
      document.getElementById("cord_aspect_ratio_z").value = panelInfo
        .panel_config.bar_data.chart_layout.aspect_ratio.z
        ? panelInfo.panel_config.bar_data.chart_layout.aspect_ratio.z
        : 1;
    } else {
      setChartTitle("Mesh Plot");
    }
  };

  useEffect(() => {
    if (
      editFlag &&
      selectedDatasetColumnList &&
      panelInfo.panel_config.bar_data
    ) {
      setEditFlag(false);
      selectedDatasetColumnList.forEach((col, index) => {
        if (
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.x.col_name &&
          document.getElementById("cord_x")
        ) {
          document.getElementById("cord_x").value = JSON.stringify(col);
        }
        if (
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.y.col_name &&
          document.getElementById("cord_y")
        ) {
          document.getElementById("cord_y").value = JSON.stringify(col);
        }
        if (
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.z.col_name &&
          document.getElementById("cord_z")
        ) {
          document.getElementById("cord_z").value = JSON.stringify(col);
        }
        if (
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.i.col_name &&
          document.getElementById("cord_i")
        ) {
          document.getElementById("cord_i").value = JSON.stringify(col);
        }
        if (
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.j.col_name &&
          document.getElementById("cord_j")
        ) {
          document.getElementById("cord_j").value = JSON.stringify(col);
        }
        if (
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.k.col_name &&
          document.getElementById("cord_k")
        ) {
          document.getElementById("cord_k").value = JSON.stringify(col);
        }
        if (
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.value.col_name &&
          document.getElementById("cord_value")
        ) {
          document.getElementById("cord_value").value = JSON.stringify(col);
        }
        if (
          panelInfo.panel_config.bar_data.chart_config.node &&
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.node.col_name &&
          document.getElementById("cord_node")
        ) {
          document.getElementById("cord_node").value = JSON.stringify(col);
        }
        if (
          panelInfo.panel_config.bar_data.chart_config.filter_by &&
          col.col_name ===
            panelInfo.panel_config.bar_data.chart_config.filter_by.col
              .col_name &&
          document.getElementById("cord_filter")
        ) {
          document.getElementById("cord_filter").value = JSON.stringify(col);
          getColumnMinMaxValue(col);
          // setFilterRangeByColumn([
          //   panelInfo.panel_config.bar_data.chart_config.filter_by.range.min,
          //   panelInfo.panel_config.bar_data.chart_config.filter_by.range.max,
          // ]);
        }
      });
    }
  }, [editFlag, selectedDatasetColumnList]);

  const closeModal = () => {
    setChartTitle(null);
    setChartDataMesh({
      x: null,
      y: null,
      z: null,
      i: null,
      j: null,
      k: null,
      value: null,
      cmin: null,
      cmax: null,
      color_plate: "rdylbl",
      node: null,
      filter_by: null,
    });
    setIsOpen(false);
  };

  const selectDatasetHandler = (data) => {
    if (data === "ADD_NEW_DATASET") {
      closeModal();
      document.getElementById("new-dataset-input-modal__opener-el").click();
    } else {
      document.getElementById("cord_x").value = "";
      document.getElementById("cord_y").value = "";
      document.getElementById("cord_z").value = "";
      document.getElementById("cord_i").value = "";
      document.getElementById("cord_j").value = "";
      document.getElementById("cord_k").value = "";
      document.getElementById("cord_value").value = "";
      document.getElementById("cord_filter").value = "";

      document.getElementById("cord_min_value").value = "";
      document.getElementById("cord_max_value").value = "";
      document.getElementById("cord_color").value = "rdylbl";

      document.getElementById("cord_aspect_ratio_x").value = "";
      document.getElementById("cord_aspect_ratio_y").value = "";
      document.getElementById("cord_aspect_ratio_z").value = "";

      setChartDataMesh({
        // ...chartDataMesh,
        cmin: null,
        cmax: null,
        color_plate: "rdylbl",
      });
      setFilterInfoByColumn(null);
      setFilterRangeByColumn([]);
      setSelectedDataset(JSON.parse(data));

      dispatch({
        type: "UPDATE_REQUEST_LOADER_STATE",
        payload: true,
      });
      dispatch(
        getDatasetColumns(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          JSON.parse(data).guid
        )
      )
        .then((res) => {
          setSelectedDatasetColumnList(res.data.data);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        });
    }
  };

  const applyDatasetClickHandler = () => {
    if (!chartDataMesh.node) {
      Object.assign(chartDataMesh, { node: null });
    }
    // if (!chartDataMesh.filter_by) {
    //   Object.assign(chartDataMesh, { filter_by: null });
    // }

    if (filterInfoByColumn && filterRangeByColumn.length) {
      chartDataMesh.filter_by.col = {
        col_name: filterInfoByColumn.column,
        col_type: filterInfoByColumn.type,
      };
      chartDataMesh.filter_by.range = {
        min: filterRangeByColumn[0],
        max: filterRangeByColumn[1],
      };
    } else {
      chartDataMesh.filter_by = null;
    }

    let chart_data = {
      chart_type_api_sep: "mesh",
      is_three_dym: false,
      chart_type: "line",
      chart_line_type: "mesh",
      chart_name: chartTitle || chartTitle !== "" ? chartTitle : "3d Mesh Plot",
      dataset_guid: selectedDataset.guid,
      is_active: "true",

      x_axis_label: "X",
      y_axis_label: "Y",
      x_axis_category: "no data",
      x_axis_categories: null,
      y_axis_categories: [],
      z_axis_categories: null,

      chart_config: chartDataMesh,
      chart_layout: chartLayoutMesh,
      filter_info: filterInfoByColumn,
    };

    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    if (
      !panelInfo.panel_config.data ||
      panelInfo.panel_config.data.dataset !== selectedDataset.guid
    ) {
      dispatch(
        createChart(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          chart_data
        )
      )
        .then((res) => {
          panelInfo.panel_config.data = res.data;
          panelInfo.panel_config.bar_data = chart_data;
          dispatch(
            updateCustomDashboardPanel___(activeTabData.guid, panelInfo.guid, {
              chart_guid: res.data.guid,
              panel_config: panelInfo.panel_config,
            })
          ).then(() => {
            closeModal();
          });
        })
        .catch((err) => {
        });
    } else {
      panelInfo.panel_config.data = panelInfo.panel_config.data;
      panelInfo.panel_config.data.dataset = selectedDataset.guid;
      panelInfo.panel_config.bar_data = chart_data;
      dispatch(
        updateChart(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          panelInfo.panel_config.data.guid,
          {
            dataset_guid: selectedDataset.guid,
          }
        )
      ).then((res) => {
        dispatch(
          updateCustomDashboardPanel(
            activeTabData.guid,
            panelInfo.guid,
            panelInfo.panel_config
          )
        );
        closeModal();
      });
    }
  };

  const setDefault = () => {
    setChartTitle(null);
    setChartDataMesh({
      x: null,
      y: null,
      z: null,
      i: null,
      j: null,
      k: null,
      value: null,
      cmin: null,
      cmax: null,
      color_plate: "rdylbl",
    });
    setChartLayoutMesh({
      aspect_ratio: {
        x: 1,
        y: 1,
        z: 1,
      },
    });
    setSelectedDatasetColumnList(null);
    setFilterInfoByColumn(null);
    setFilterRangeByColumn([]);
    setEditFlag(false);
  };

  const getColumnMinMaxValue = (colInfo) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    if (colInfo.col_name !== "none") {
      dispatch(
        getDatasetColInfo(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          selectedDataset.guid,
          colInfo.col_name
        )
      ).then((res) => {
        setFilterInfoByColumn(res.data.data);
        setFilterRangeByColumn([
          res.data.data.range.min,
          res.data.data.range.max,
        ]);

        document.getElementById("filter_range_input_min").value =
          res.data.data.range.min;
        document.getElementById("filter_range_input_max").value =
          res.data.data.range.max;
        if (
          panelInfo.panel_config.data &&
          panelInfo.panel_config.bar_data.chart_config.filter_by &&
          panelInfo.panel_config.bar_data.chart_config.filter_by.col.col_name === colInfo.col_name
        ) {
          if (
            panelInfo.panel_config.data &&
            panelInfo.panel_config.bar_data.chart_config.filter_by
            //  &&
            // panelInfo.panel_config.bar_data.chart_config.filter_by.col
            //   .col_name === colInfo.col_name
          ) {
            setFilterRangeByColumn([
              panelInfo.panel_config.bar_data.chart_config.filter_by.range.min,
              panelInfo.panel_config.bar_data.chart_config.filter_by.range.max,
            ]);
          } else {
            setFilterRangeByColumn([]);
            if (!panelInfo.panel_config.data) {
              toastr.info("Creating your data", "Press Apply");
            } else {
              toastr.error("Server Error", "500");
            }
        }
        }
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
      // .catch((err) => {
      //   toastr.error("Server Error", "500");
      //   dispatch({
      //     type: "UPDATE_REQUEST_LOADER_STATE",
      //     payload: false,
      //   });
      // });
    } else {
      dispatch({
        type: "UPDATE_REQUEST_LOADER_STATE",
        payload: false,
      });
    }
  };

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_bar_chart_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle={modalTitle}
          contentLabel={modalTitle}
          ariaHideApp={false}
        >
          <div className="chart-plot-modal-container">
            {/* ROW 1 */}
            <div className="row form__row">
              <div className="col-md-6 pr-2">
                <div className="form__group">
                  <label>Chart Title</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_title"
                    placeholder="Chart Title"
                    value={chartTitle ? chartTitle : ""}
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 pl-1">
                <div className="form__group">
                  <label>Select Data*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_dataset_select_option"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      selectDatasetHandler(e.target.value);
                    }}
                  >
                    <option value={null} selected disabled>
                      Select dataset
                    </option>
                    {!isWebglDashboardActive ? (
                      <option value={"ADD_NEW_DATASET"}>Add New Dataset</option>
                    ) : (
                      <></>
                    )}
                    <optgroup label="Uploaded Dataset">
                      {datasetList.map((dataset, index) => (
                        <option value={JSON.stringify(dataset)} key={index}>
                          {dataset.name}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
            {/* Radio section for Mesh Connectivity */}
            <div className="mt-2 d-flex align-items-center">
              <p className="mb-0 mr-4">
                <strong>
                  Do you have mesh connectivity for triangular mesh
                </strong>
              </p>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="meshConnectivity"
                  id="meshConnectivityYes"
                  // defaultValue="yes"
                  checked
                />
                <label
                  className="form-check-label"
                  htmlFor="meshConnectivityYes"
                >
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="meshConnectivity"
                  id="meshConnectivityNo"
                  // defaultValue="no"
                  disabled
                />
                <label
                  className="form-check-label"
                  htmlFor="meshConnectivityNo"
                >
                  No
                </label>
              </div>
            </div>
            {/* Image */}
            <div className="row form__row">
              <div className="col-md-5 pr-2">
                <img style={{ width: "250px" }} src={SurfaceImg} />
              </div>
              <div className="col-md-7 pl-1 d-flex align-items-center">
                <img style={{ width: "360px" }} src={DataFormatImg} />
              </div>
            </div>
            {/* ROW 2 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-12 pr-2">
                <div className="form__group">
                  <label>
                    <strong>Select Surface Node Coordinates</strong>
                  </label>
                </div>
              </div>
              <div className="col-md-4 pr-2">
                <div className="form__group">
                  <label>Select X Coordinate*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_x"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        x: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                      {/* Select Col */}
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-4 pr-2 pl-1">
                <div className="form__group">
                  <label>Select Y Coordinate*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_y"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        y: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                      {/* Select Col */}
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-4 pl-1">
                <div className="form__group">
                  {/* <label>Select Longitudinal Coordinate Z*</label> */}
                  <label>Select Z Coordinate*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_z"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        z: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option value="" selected disabled>
                      Please Select
                      {/* Select Col */}
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
            </div>
            {/* ROW 3 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-12 pr-2">
                <div className="form__group">
                  <label>
                    <strong>Select Mesh Connectivity Nodes I, J, K</strong>
                  </label>
                </div>
              </div>
              <div className="col-md-4 pr-2">
                <div className="form__group">
                  <label>Select I*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_i"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        i: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                      {/* Select Col */}
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-4 pr-2 pl-1">
                <div className="form__group">
                  <label>Select J*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_j"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        j: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                      {/* Select Col */}
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-4 pl-1">
                <div className="form__group">
                  <label>Select K*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_k"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        k: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                      {/* Select Col */}
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
            </div>
            {/* ROW 4 */}
            <div className="row form__row mt-2 ">
              {/* <div className="col-md-12 pr-2">
                <div className="form__group">
                  <label>
                    <strong>Select Intensity/Value Column*</strong>
                  </label>
                </div>
              </div> */}
              <div className="col-md-4 pr-2">
                <div className="form__group">
                  <label>
                    <strong>Select Intensity/Value Column*</strong>
                  </label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_value"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        value: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                      {/* Select Col */}
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>

              <div className="col-md-4 pr-2 pl-1">
                <div className="form__group">
                  <label>Select Node</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_node"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        node:
                          JSON.parse(e.target.value).col_name === "none"
                            ? null
                            : JSON.parse(e.target.value).col_name,
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    <option value={JSON.stringify({ col_name: "none" })}>
                      None
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
            </div>
            {/* ROW - FILTER */}
            <div className="row form__row mt-2 ">
              <div className="col-md-4 pr-2">
                <div className="form__group">
                  <label>Filter By</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_filter"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      getColumnMinMaxValue(JSON.parse(e.target.value));
                      if (JSON.parse(e.target.value).col_name !== "none") {
                        setChartDataMesh({
                          ...chartDataMesh,
                          filter_by: {
                            col: JSON.parse(e.target.value),
                          },
                        });
                      } else {
                        setChartDataMesh({
                          ...chartDataMesh,
                          filter_by: null,
                        });
                        setFilterInfoByColumn(null);
                        setFilterRangeByColumn([]);
                      }
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    <option value={JSON.stringify({ col_name: "none" })}>
                      None
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              {chartDataMesh.filter_by && (
                <div className="col-md-8 pl-1">
                  <div className="form__group text-center">
                    <label>
                      Filter Range{" "}
                      {/* {filterInfoByColumn
                        ? "Min: " +
                          filterInfoByColumn.range.min +
                          "    Max: " +
                          filterInfoByColumn.range.max
                        : ""} */}
                    </label>
                  </div>
                  <div
                    className="form__group d-flex justify-content-center"
                    style={{ margin: "0 auto" }}
                  >
                    <div className="form__group">
                      <input
                        type="number"
                        className="form-control form__control"
                        id="filter_range_input_min"
                        placeholder="Y"
                        onChange={(e) => {
                          e.stopPropagation();
                          let min_ = parseFloat(e.target.value);
                          let max_ =
                            filterRangeByColumn.length && filterRangeByColumn[1]
                              ? parseFloat(filterRangeByColumn[1])
                              : 0;
                          if (
                            min_ < max_ &&
                            min_ >= parseFloat(filterInfoByColumn.range.min)
                          ) {
                            setFilterRangeByColumn([
                              parseFloat(min_),
                              parseFloat(max_),
                            ]);
                          } else {
                            toastr.warning("Out of bound", "Warning");
                            document.getElementById(
                              "filter_range_input_min"
                            ).value = parseFloat(min_).toFixed(2) / 10;
                          }
                        }}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <ReactRangeInput
                      step={
                        filterInfoByColumn
                          ? filterInfoByColumn.range.max / 100
                          : 1
                      }
                      min={
                        filterInfoByColumn ? filterInfoByColumn.range.min : 0
                      }
                      max={
                        filterInfoByColumn ? filterInfoByColumn.range.max : 10
                      }
                      selectedFilteredValue={
                        filterRangeByColumn.length
                          ? [filterRangeByColumn[0], filterRangeByColumn[1]]
                          : []
                      }
                      setSelectedFilteredValue={(e) => {
                        document.getElementById(
                          "filter_range_input_min"
                        ).value = e[0];
                        document.getElementById(
                          "filter_range_input_max"
                        ).value = e[1];
                        setFilterRangeByColumn(e);
                      }}
                    />
                    <div className="form__group">
                      <input
                        type="number"
                        className="form-control form__control"
                        id="filter_range_input_max"
                        placeholder="Y"
                        onChange={(e) => {
                          e.stopPropagation();
                          let min_ =
                            filterRangeByColumn.length && filterRangeByColumn[0]
                              ? parseFloat(filterRangeByColumn[0])
                              : 0;
                          let max_ = parseFloat(e.target.value);
                          if (
                            max_ > min_ &&
                            max_ <= parseFloat(filterInfoByColumn.range.max)
                          ) {
                            setFilterRangeByColumn([
                              parseFloat(min_),
                              parseFloat(max_),
                            ]);
                          } else {
                            toastr.warning("Out of bound", "Warning");
                            document.getElementById(
                              "filter_range_input_max"
                            ).value = parseFloat(max_).toFixed(2) / 10;
                          }
                        }}
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* </div> */}
              {/* </div> */}
            </div>
            {/* ROW 5 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-8 pr-1">
                <div className="row form__row">
                  <div className="col-md-12 pr-2">
                    <div className="form__group">
                      <label>
                        <strong>Contour Value Range</strong>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row form__row">
                  <div className="col-md-6 pr-2">
                    {/* <div className="form__group">
                      <label>Min Value</label>
                    </div> */}
                    <div className="form__group">
                      <input
                        type="text"
                        id={"cord_min_value"}
                        className="form-control form__control"
                        placeholder="Min Value"
                        // defaultValue={"Pie Chart"}
                        onChange={(e) => {
                          e.stopPropagation();
                          setChartDataMesh({
                            ...chartDataMesh,
                            cmin: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pl-1">
                    {/* <div className="form__group">
                      <label>Max Value</label>
                    </div> */}
                    <div className="form__group">
                      <input
                        type="text"
                        id={"cord_max_value"}
                        className="form-control form__control"
                        placeholder="Max Value"
                        // defaultValue={"Pie Chart"}
                        onChange={(e) => {
                          e.stopPropagation();
                          setChartDataMesh({
                            ...chartDataMesh,
                            cmax: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 pl-1">
                <div className="form__group">
                  <label>Select Color Palette</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id={"cord_color"}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartDataMesh({
                        ...chartDataMesh,
                        color_plate: e.target.value,
                      });
                    }}
                  >
                    <option disabled value="">
                      Please Select
                      {/* Select Col */}
                    </option>
                    <option value="blylrd">Blue Yellow Red</option>
                    <option value="grylrd">Green Yellow Red</option>
                    <option value="rdylbl" selected>
                      Red Yellow Blue
                    </option>
                    <option value="rdylgr">Red Yellow Green</option>
                  </select>
                </div>
              </div>
            </div>
            {/* ROW 6 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-12 pr-2">
                <div className="form__group">
                  <label>
                    <strong>Aspect Ratio</strong>
                  </label>
                </div>
              </div>
              <div className="col-md-4 pr-2">
                <div className="form__group">
                  <label>X</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    id={"cord_aspect_ratio_x"}
                    className="form-control form__control"
                    placeholder="X"
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartLayoutMesh({
                        ...chartLayoutMesh,
                        aspect_ratio: {
                          ...chartLayoutMesh.aspect_ratio,
                          x: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4 pr-2 pl-1">
                <div className="form__group">
                  <label>Y</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    id={"cord_aspect_ratio_y"}
                    className="form-control form__control"
                    placeholder="Y"
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartLayoutMesh({
                        ...chartLayoutMesh,
                        aspect_ratio: {
                          ...chartLayoutMesh.aspect_ratio,
                          y: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4 pl-1">
                <div className="form__group">
                  <label>Z</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    id={"cord_aspect_ratio_z"}
                    className="form-control form__control"
                    placeholder="Z"
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartLayoutMesh({
                        ...chartLayoutMesh,
                        aspect_ratio: {
                          ...chartLayoutMesh.aspect_ratio,
                          z: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {/* SUBMIT */}
            <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
              <button
                className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
                disabled={
                  !selectedDataset ||
                  !chartDataMesh.x ||
                  !chartDataMesh.y ||
                  !chartDataMesh.z ||
                  !chartDataMesh.i ||
                  !chartDataMesh.j ||
                  !chartDataMesh.k ||
                  !chartDataMesh.value
                }
                onClick={() => {
                  applyDatasetClickHandler();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeshChartUpdateModal);
