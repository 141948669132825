import React from "react";
import { useSelector } from "react-redux";
import AssetBasics from "./AssetBasics";
import AssetSpecs from "./AssetSpecs";
import PipelineBasics from "./PipelineBasics";
import PipelineSpecs from "./PipelineSpecs";
import "./style.css";

const Metadata = () => {
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );

  function Tabs() {
    var bindAll = function () {
      var menuElements = document.querySelectorAll("[data-tab]");
      for (var i = 0; i < menuElements.length; i++) {
        menuElements[i].addEventListener("click", change, false);
      }
    };

    var clear = function () {
      var menuElements = document.querySelectorAll("[data-tab]");
      for (var i = 0; i < menuElements.length; i++) {
        menuElements[i].classList.remove("active");
        var id = menuElements[i].getAttribute("data-tab");
        document.getElementById(id).classList.remove("active");
      }
    };

    var change = function (e) {
      clear();
      e.target.classList.add("active");
      var id = e.currentTarget.getAttribute("data-tab");
      document.getElementById(id).classList.add("active");
    };

    bindAll();
  }

  var connectTabs = new Tabs();
  return (
    <div className="mata_data__wrapper">
      <div className="app-container-body">
        <ul
          className="nav nav-tabs-custom-panel justify-content-center nav-tab__curved"
          role="tablist"
          id="nav__tab__metadata"
        >
          <li className="nav-item">
            <a
              href="#asset_basic"
              data-tab="asset_basic"
              className="b-nav-tab active"
            >
              ASSET BASIC
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#pipeline_basic"
              data-tab="pipeline_basic"
              className="b-nav-tab"
            >
              PIPELINE BASIC
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#asset_specifications"
              data-tab="asset_specifications"
              className="b-nav-tab"
            >
              ASSET SPECIFICATIONS
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#pipeline_specifications"
              data-tab="pipeline_specifications"
              className="b-nav-tab"
            >
              PIPELINE SPECIFICATIONS
            </a>
          </li>
        </ul>
        {/* Tab panes */}
        <div className="tab-content">
          <div id="asset_basic" className="b-tab active">
            <AssetBasics projectguid={panelFieldInfo.panel_type_guid} />
          </div>
          <div id="pipeline_basic" className="b-tab">
            <PipelineBasics projectguid={panelFieldInfo.panel_type_guid} />
          </div>
          <div id="asset_specifications" className="b-tab">
            <AssetSpecs projectguid={panelFieldInfo.panel_type_guid} />
          </div>
          <div id="pipeline_specifications" className="b-tab">
            <PipelineSpecs projectguid={panelFieldInfo.panel_type_guid} />
          </div>
        </div>
        {/* <AssetBasics panelType={panelType} projectguid={projectguid} />
        <PipelineBasics panelType={panelType} projectguid={projectguid} />
        <AssetSpecs panelType={panelType} projectguid={projectguid} />
        <PipelineSpecs panelType={panelType} projectguid={projectguid} /> */}

        {/* <AssetBasics projectguid={panelFieldInfo.panel_type_guid} />
        <PipelineBasics projectguid={panelFieldInfo.panel_type_guid} />
        <AssetSpecs projectguid={panelFieldInfo.panel_type_guid} />
        <PipelineSpecs projectguid={panelFieldInfo.panel_type_guid} /> */}
      </div>
    </div>
  );
};

export default Metadata;
