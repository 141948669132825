import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  createChart,
  getChartData_Bar_UniqueValue,
  getDatasetColumns,
  updateChart,
  updateCustomDashboardPanel,
  updateCustomDashboardPanel_CHART_GUID,
  updateCustomDashboardPanel___,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import ModalAT from "../../ModalAT";
import Filter_ from "./filter";

const plot_type = ["Heatmap", "Bubblemap"];
const mapbox_style = [
  "open-street-map",
  "carto-positron",
  "carto-darkmatter",
  "stamen-terrain",
  "stamen-toner",
  "stamen-watercolor",
  "basic",
  "streets",
  "outdoors",
  "light",
  "dark",
  "satellite",
  "satellite-streets",
];
const zoom_options = [
  { name: "The Earth", value: 0 },
  { name: "A continent", value: 3 },
  { name: "Large Islands", value: 4 },
  { name: "Large Rivers", value: 6 },
  { name: "Large Roads", value: 10 },
  { name: "Buildings", value: 15 },
];

const operations = [
  "None",
  "sum",
  "mean",
  "count",
  "median",
  "min",
  "max",
  "first",
  "last",
];

const color_scale = [
  { name: "Blackbody", value: "Blackbody" },
  { name: "Bluered", value: "Bluered" },
  { name: "Blues", value: "Blues" },
  { name: "Cividis", value: "Cividis" },
  { name: "Earth", value: "Earth" },
  { name: "Electric", value: "Electric" },
  { name: "Greens", value: "Greens" },
  { name: "Greys", value: "Greys" },
  { name: "Hot", value: "Hot" },
  { name: "Jet", value: "Jet" },
  { name: "Picnic", value: "Picnic" },
  { name: "Portland", value: "Portland" },
  { name: "Rainbow", value: "Rainbow" },
  { name: "RdBu", value: "RdBu" },
  { name: "Reds", value: "Reds" },
  { name: "Viridis", value: "Viridis" },
  { name: "Yellow-Green-Blue", value: "YlGnBu" },
  { name: "Yellow-Orange-Red", value: "YlOrRd" },
];

const MapboxDensityHeatmapChartUpdateModal = () => {
  const dispatch = useDispatch();
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );
  const isWebglDashboardActive = useSelector(
    (state) => state.customPanelModify.isWebglDashboard
  );
  // --- MODAL CONF ---
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedDatasetColumnList, setSelectedDatasetColumnList] =
    useState(null);

  const [modalTitle, setModalTitle] = useState(null);
  const [chartTitle, setChartTitle] = useState(null);
  const [chartDataDensitymap, setChartDataDensitymap] = useState({
    plot_type: "Heatmap",
    base_map: "open-street-map",
    zoom: { name: "Buildings", value: 15 },
    lat: null,
    lng: null,
    z_value: null,
    radius: 10,
    operation: null,
    filter: {
      col_name: null,
      col_type: null,
      value: [],
      filter_option: null,
    },
    color_palate: "YlOrRd",
    zmin: null,
    zmax: null,
    map_view_limit: {
      max_lat: null,
      max_lng: null,
      min_lat: null,
      min_lng: null,
    },
  });
  const [editFlag, setEditFlag] = useState(false);

  const [filterMapbox, setFilterMapbox] = useState({
    addFilter: null,
    addFilter_columnData: null,
    addFilter_columnDataType: null,
    filteredBySearch: [],
    selectedFilteredValue: [],
    selectedFilteredValue_type: null,
  });

  useEffect(() => {
    if (filterMapbox.addFilter && filterMapbox.addFilter !== "None") {
      let column_ = JSON.parse(filterMapbox.addFilter).col_name;
      dispatch(
        getChartData_Bar_UniqueValue(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          selectedDataset.guid,
          column_
        )
      )
        .then((res) => {
          // console.log(res.data);
          setFilterMapbox({
            ...filterMapbox,
            addFilter_columnData: res.data.col_data_x,
            addFilter_columnDataType: res.data.col_type,
          });
          if (
            panelInfo.panel_config.bar_data &&
            panelInfo.panel_config.bar_data.filterMapbox.selectedFilteredValue
          ) {
            if (res.data.col_type === "numeric") {
            } else {
              let fv = [];
              setTimeout(() => {
                res.data.col_data_x.forEach((el, in_) => {
                  panelInfo.panel_config.bar_data.filterMapbox.selectedFilteredValue.forEach(
                    (col_) => {
                      if (el === col_) {
                        if (
                          document.getElementById(
                            "filter_column_unique_value_" + in_
                          )
                        ) {
                          document.getElementById(
                            "filter_column_unique_value_" + in_
                          ).checked = true;
                        }
                        fv.push(col_);
                      }
                    }
                  );
                });
              }, 1000);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } else if (filterMapbox.addFilter === "None") {
      // console.log("None");
    }
  }, [filterMapbox.addFilter]);

  window.mapboxDensityHeatmapChartEditModalOpen = (panel) => {
    setDefault();
    setPanelInfo(panel);
    setModalTitle("Update Density Map");
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    setChartTitle(
      panelInfo.panel_config.data
        ? panelInfo.panel_config.bar_data.chart_name
        : "Density Map"
    );
    // SELEC DATASET
    datasetList.forEach((dataset, index) => {
      if (
        panelInfo.panel_config.data &&
        panelInfo.panel_config.data.dataset === dataset.guid
      ) {
        document.getElementById(
          "bar_chart_edit__chart_dataset_select_option"
        ).value = JSON.stringify(dataset);
        // GET SELECTED DATASET COLUMN
        selectDatasetHandler(JSON.stringify(dataset));
        // SELECT X AXIS COLUMN FLAG
        setEditFlag(true);
        setChartDataDensitymap(panelInfo.panel_config.bar_data.chart_config);
      }
    });
  };

  useEffect(() => {
    if (
      editFlag &&
      selectedDatasetColumnList &&
      panelInfo.panel_config.bar_data
    ) {
      setEditFlag(false);
      selectedDatasetColumnList.forEach((col, index) => {
        if (
          panelInfo.panel_config.bar_data.filterMapbox.addFilter &&
          col.col_name ===
            JSON.parse(panelInfo.panel_config.bar_data.filterMapbox.addFilter)
              .col_name
        ) {
          document.getElementById("bar_chart_edit__chart_add_filter").value =
            JSON.stringify(col);
          setFilterMapbox(panelInfo.panel_config.bar_data.filterMapbox);
        }
      });
    }
  }, [editFlag, selectedDatasetColumnList]);

  const closeModal = () => {
    setChartTitle(null);
    setIsOpen(false);
  };

  const selectDatasetHandler = (data) => {
    if (data === "ADD_NEW_DATASET") {
      closeModal();
      document.getElementById("new-dataset-input-modal__opener-el").click();
    } else {
      // setDefault();
      setChartDataDensitymap({
        ...chartDataDensitymap,
        plot_type: "Heatmap",
        base_map: "open-street-map",
        zoom: { name: "Buildings", value: 15 },
        lat: null,
        lng: null,
        z_value: null,
        radius: 10,
        operation: null,
        filter: {
          col_name: null,
          col_type: null,
          value: [],
          filter_option: null,
        },
        color_palate: "YlOrRd",
        zmin: null,
        zmax: null,
        map_view_limit: {
          max_lat: null,
          max_lng: null,
          min_lat: null,
          min_lng: null,
        },
      });
      setFilterMapbox({
        addFilter: null,
        addFilter_columnData: null,
        addFilter_columnDataType: null,
        filteredBySearch: [],
        selectedFilteredValue: [],
        selectedFilteredValue_type: null,
      });
      if (document.getElementById("bar_chart_edit__chart_add_filter")) {
        document.getElementById("bar_chart_edit__chart_add_filter").value = "";
      }
      // document.getElementById("bar_chart_edit__chart_add_filter").value = "None"
      setSelectedDataset(JSON.parse(data));

      dispatch({
        type: "UPDATE_REQUEST_LOADER_STATE",
        payload: true,
      });
      dispatch(
        getDatasetColumns(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          JSON.parse(data).guid
        )
      )
        .then((res) => {
          setSelectedDatasetColumnList(res.data.data);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        })
        .catch((err) => {
          // console.log(err);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        });
    }
  };

  const applyDatasetClickHandler = () => {
    if (filterMapbox.addFilter && filterMapbox.addFilter !== "None") {
      chartDataDensitymap.filter = {
        col_name: JSON.parse(filterMapbox.addFilter).col_name,
        col_type: JSON.parse(filterMapbox.addFilter).col_type,
        value: filterMapbox.selectedFilteredValue,
        filter_option:
          filterMapbox.addFilter_columnDataType === "numeric"
            ? filterMapbox.selectedFilteredValue_type
            : null,
      };
    } else {
      chartDataDensitymap.filter = null;
    }

    if (chartDataDensitymap.operation === "None") {
      chartDataDensitymap.operation = null;
    }

    let chart_data = {
      chart_type_api_sep: "mapbox_density_heatmap",
      is_three_dym: false,
      chart_type: "line",
      chart_line_type: "mapbox_density_heatmap",
      chart_name: chartTitle || chartTitle !== "" ? chartTitle : "Density Map",
      dataset_guid: selectedDataset.guid,
      is_active: "true",

      x_axis_label: "X",
      y_axis_label: "Y",
      x_axis_category: "no data",
      x_axis_categories: null,
      y_axis_categories: [],
      z_axis_categories: null,

      chart_config: chartDataDensitymap,
      filterMapbox:
        filterMapbox.addFilter !== "None"
          ? filterMapbox
          : {
              addFilter: null,
              addFilter_columnData: null,
              addFilter_columnDataType: null,
              filteredBySearch: [],
              selectedFilteredValue: [],
              selectedFilteredValue_type: null,
            },
    };
    console.log(chart_data);

    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    if (
      !panelInfo.panel_config.data ||
      panelInfo.panel_config.data.dataset !== selectedDataset.guid
    ) {
      dispatch(
        createChart(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          chart_data
        )
      )
        .then((res) => {
          panelInfo.panel_config.data = res.data;
          panelInfo.panel_config.bar_data = chart_data;
          dispatch(
            updateCustomDashboardPanel___(activeTabData.guid, panelInfo.guid, {
              chart_guid: res.data.guid,
              panel_config: panelInfo.panel_config,
            })
          ).then(() => {
            closeModal();
          });
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      panelInfo.panel_config.data = panelInfo.panel_config.data;
      panelInfo.panel_config.data.dataset = selectedDataset.guid;
      panelInfo.panel_config.bar_data = chart_data;
      dispatch(
        updateChart(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          panelInfo.panel_config.data.guid,
          {
            dataset_guid: selectedDataset.guid,
          }
        )
      ).then((res) => {
        console.log(res);
        dispatch(
          updateCustomDashboardPanel(
            activeTabData.guid,
            panelInfo.guid,
            panelInfo.panel_config
          )
        );
        closeModal();
      });
      // dispatch(
      //   updateCustomDashboardPanel(
      //     activeTabData.guid,
      //     panelInfo.guid,
      //     panelInfo.panel_config
      //   )
      // );
      // closeModal();
    }
  };

  const setDefault = () => {
    setChartTitle(null);
    setChartDataDensitymap({
      plot_type: "Heatmap",
      base_map: "open-street-map",
      zoom: { name: "Buildings", value: 15 },
      lat: null,
      lng: null,
      z_value: null,
      radius: 10,
      operation: null,
      filter: {
        col_name: null,
        col_type: null,
        value: [],
        filter_option: null,
      },
      color_palate: "YlOrRd",
      zmin: null,
      zmax: null,
      map_view_limit: {
        max_lat: null,
        max_lng: null,
        min_lat: null,
        min_lng: null,
      },
    });
    setFilterMapbox({
      addFilter: null,
      addFilter_columnData: null,
      addFilter_columnDataType: null,
      filteredBySearch: [],
      selectedFilteredValue: [],
      selectedFilteredValue_type: null,
    });
    if (document.getElementById("bar_chart_edit__chart_add_filter")) {
      document.getElementById("bar_chart_edit__chart_add_filter").value = "";
    }
    setSelectedDatasetColumnList(null);
    setEditFlag(false);
  };

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_bar_chart_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle={modalTitle}
          contentLabel={modalTitle}
          ariaHideApp={false}
        >
          <div className="chart-plot-modal-container">
            {/* ROW 1 */}
            <div className="row form__row">
              <div className="col-md-6 pr-2">
                <div className="form__group">
                  <label>Chart Title</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_title"
                    placeholder="Chart Title"
                    value={chartTitle ? chartTitle : ""}
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 pl-1">
                <div className="form__group">
                  <label>Select Data*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_dataset_select_option"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      selectDatasetHandler(e.target.value);
                    }}
                  >
                    <option disabled value={null} selected>
                      Select Dataset
                    </option>
                    {!isWebglDashboardActive ? (
                      <option value={"ADD_NEW_DATASET"}>Add New Dataset</option>
                    ) : (
                      <></>
                    )}
                    <optgroup label="Uploaded Dataset">
                      {datasetList.map((dataset, index) => (
                        <option value={JSON.stringify(dataset)} key={index}>
                          {dataset.name}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
            {/* ROW 2 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-3 pr-2">
                <div className="form__group">
                  <label>Select Plot Type*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id=""
                    value={
                      chartDataDensitymap.plot_type
                        ? chartDataDensitymap.plot_type
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        plot_type: e.target.value,
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    {plot_type.map((pt, index) => (
                      <option value={pt} key={index}>
                        {pt}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3 pr-2 pl-1">
                <div className="form__group">
                  <label>Select Base Map*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id=""
                    value={
                      chartDataDensitymap.base_map
                        ? chartDataDensitymap.base_map
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        base_map: e.target.value,
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    {mapbox_style.map((ms, index) => (
                      <option value={ms} key={index}>
                        {ms}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3 pr-2 pl-1">
                <div className="form__group">
                  <label>Zoom Option*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id=""
                    value={
                      chartDataDensitymap.zoom
                        ? JSON.stringify(chartDataDensitymap.zoom)
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        zoom: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    {zoom_options.map((zo, index) => (
                      <option value={JSON.stringify(zo)} key={index}>
                        {zo.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* ROW 3 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-3 pr-2">
                <div className="form__group">
                  <label>Select Lat*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id=""
                    value={
                      chartDataDensitymap.lat
                        ? JSON.stringify(chartDataDensitymap.lat)
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        lat: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            {col.col_type === "int" ||
                            col.col_type === "float" ? (
                              <>
                                <option value={JSON.stringify(col)} key={index}>
                                  {col.col_name}
                                </option>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-3 pr-2 pl-1">
                <div className="form__group">
                  <label>Select Long*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id=""
                    value={
                      chartDataDensitymap.lng
                        ? JSON.stringify(chartDataDensitymap.lng)
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        lng: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            {col.col_type === "int" ||
                            col.col_type === "float" ? (
                              <>
                                <option value={JSON.stringify(col)} key={index}>
                                  {col.col_name}
                                </option>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-3 pr-2 pl-1">
                <div className="form__group">
                  <label>Select Value*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id=""
                    value={
                      chartDataDensitymap.z_value
                        ? JSON.stringify(chartDataDensitymap.z_value)
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        z_value: JSON.parse(e.target.value),
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            {col.col_type === "int" ||
                            col.col_type === "float" ? (
                              <>
                                <option value={JSON.stringify(col)} key={index}>
                                  {col.col_name}
                                </option>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-3 pl-1">
                <div className="form__group">
                  <label>Heatmap Radius*</label>
                </div>
                <div className="form__group d-flex">
                  <button
                    className="btn_range"
                    disabled={chartDataDensitymap.radius === 1}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (chartDataDensitymap.radius > 1) {
                        setChartDataDensitymap({
                          ...chartDataDensitymap,
                          radius: parseInt(chartDataDensitymap.radius) - 1,
                        });
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-dash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                    </svg>
                  </button>
                  <input
                    type="number"
                    className="form-control form__control mx-2 text-center"
                    id=""
                    value={
                      chartDataDensitymap.radius ||
                      chartDataDensitymap.radius === 0
                        ? chartDataDensitymap.radius
                        : ""
                    }
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        radius: parseInt(e.target.value),
                      });
                      // if (e.target.value >= 1) {
                      //   setChartDataDensitymap({
                      //     ...chartDataDensitymap,
                      //     radius: parseInt(e.target.value),
                      //   });
                      // } else {
                      //   setChartDataDensitymap({
                      //     ...chartDataDensitymap,
                      //     radius: parseInt(1),
                      //   });
                      // }
                    }}
                  />
                  <button
                    className="btn_range"
                    onClick={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        radius: parseInt(chartDataDensitymap.radius) + 1,
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/* ROW 4 */}
            <div className="row form__row mt-2 ">
              {/* <div className={filterMapbox.addFilter ? "col-md-3 pr-2 mt-2" : "col-md-3 pr-2"}> */}
              <div className="col-md-3 pr-2 mt-2">
                <div className="form__group">
                  <label>Select Operation</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id=""
                    value={
                      chartDataDensitymap.operation
                        ? chartDataDensitymap.operation
                        : "None"
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      // if (e.target.value === "None") {
                      //   setChartDataDensitymap({
                      //     ...chartDataDensitymap,
                      //     operation: null,
                      //   });
                      // } else {
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        operation: e.target.value,
                      });
                      // }
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    {operations.map((opr, index) => (
                      <option value={opr} key={index}>
                        {opr}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-8 pr-2 pl-1">
                <Filter_
                  bar_data={panelInfo.panel_config.bar_data}
                  selectedDatasetColumnList={selectedDatasetColumnList}
                  panelInfo={panelInfo}
                  filterMapbox={filterMapbox}
                  setAddFilter={(e) =>
                    setFilterMapbox({
                      // ...filterMapbox,
                      addFilter: e,
                      addFilter_columnData: null,
                      addFilter_columnDataType: null,
                      filteredBySearch: [],
                      selectedFilteredValue: [],
                      selectedFilteredValue_type: null,
                    })
                  }
                  addFilter_columnData={filterMapbox.addFilter_columnData}
                  setAddFilter_columnData={(e) =>
                    setFilterMapbox({
                      ...filterMapbox,
                      addFilter_columnData: e,
                    })
                  }
                  addFilter_columnDataType={
                    filterMapbox.addFilter_columnDataType
                  }
                  setAddFilter_columnDataType={(e) =>
                    setFilterMapbox({
                      ...filterMapbox,
                      addFilter_columnDataType: e,
                    })
                  }
                  selectedFilteredValue={filterMapbox.selectedFilteredValue}
                  setSelectedFilteredValue={(e) =>
                    setFilterMapbox({
                      ...filterMapbox,
                      selectedFilteredValue: e,
                    })
                  }
                  selectedFilteredValue_type={
                    filterMapbox.selectedFilteredValue_type
                  }
                  setSelectedFilteredValueType={(e) =>
                    setFilterMapbox({
                      ...filterMapbox,
                      selectedFilteredValue_type: e,
                    })
                  }
                />
              </div>
            </div>
            {/* ROW 5 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-12 pr-2">
                <div className="form__group">
                  <label>
                    Select Datetime for Animation
                    {/* <strong>Select Datetime for Animation</strong> */}
                  </label>
                </div>
              </div>
              <div className="col-md-3 pr-2">
                <div className="form__group">
                  <select className="custom-select" id="" disabled>
                    <option disabled value="" selected>
                      Please Select
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-md-3 pl-1 d-flex align-items-center">
                <h6 className="mb-0 mr-2">Speed</h6>
                <h5 className="m-0">
                  <span className="badge badge-secondary">Normal</span>
                </h5>
              </div>
            </div>
            {/* ROW 6 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-3 pr-2">
                <div className="form__group">
                  <label>Select Color Palette*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id=""
                    value={
                      chartDataDensitymap.color_palate
                        ? chartDataDensitymap.color_palate
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        color_palate: e.target.value,
                      });
                    }}
                  >
                    <option disabled value="" selected>
                      Please Select
                    </option>
                    {color_scale.map((cs, index) => (
                      <option value={cs.value} key={index}>
                        {cs.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3 pr-2 pl-1">
                <div className="form__group">
                  <label>
                    {/* <strong>Color Bar Value Range</strong>   */}
                    Colorbar Value Range
                  </label>
                </div>
                <div className="form__group">
                  <input
                    type="number"
                    id=""
                    className="form-control form__control"
                    placeholder="Min Value"
                    value={
                      chartDataDensitymap.zmin || chartDataDensitymap.zmin === 0
                        ? chartDataDensitymap.zmin
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        zmin:
                          e.target.value || e.target.value !== ""
                            ? e.target.value
                            : null,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3 pl-1">
                <div className="form__group">
                  <label></label>
                </div>
                <div className="form__group">
                  <input
                    type="number"
                    id=""
                    className="form-control form__control"
                    placeholder="Max Value"
                    value={
                      chartDataDensitymap.zmax ? chartDataDensitymap.zmax : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        zmax:
                          e.target.value || e.target.value !== ""
                            ? e.target.value
                            : null,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {/* ROW 7 */}
            <div className="row form__row mt-2 ">
              <div className="col-md-12">
                <div className="form__group">
                  <label>
                    {/* <strong>Set Map View Limit</strong> */}
                    Set Map View Limit
                  </label>
                </div>
              </div>
              <div className="col-md-2 pr-2">
                <div className="form__group">
                  <input
                    type="text"
                    id=""
                    className="form-control form__control"
                    placeholder="Max Lat"
                    value={
                      chartDataDensitymap.map_view_limit
                        ? chartDataDensitymap.map_view_limit.max_lat
                          ? chartDataDensitymap.map_view_limit.max_lat
                          : ""
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        map_view_limit: {
                          ...chartDataDensitymap.map_view_limit,
                          max_lat:
                            e.target.value || e.target.value !== ""
                              ? e.target.value
                              : null,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2 pr-2 pl-1">
                <div className="form__group">
                  <input
                    type="text"
                    id=""
                    className="form-control form__control"
                    placeholder="Max Long"
                    value={
                      chartDataDensitymap.map_view_limit
                        ? chartDataDensitymap.map_view_limit.max_lng
                          ? chartDataDensitymap.map_view_limit.max_lng
                          : ""
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        map_view_limit: {
                          ...chartDataDensitymap.map_view_limit,
                          max_lng:
                            e.target.value || e.target.value !== ""
                              ? e.target.value
                              : null,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-2 pr-2 pl-1">
                <div className="form__group">
                  <input
                    type="text"
                    id=""
                    className="form-control form__control"
                    placeholder="Min Lat"
                    value={
                      chartDataDensitymap.map_view_limit
                        ? chartDataDensitymap.map_view_limit.min_lat
                          ? chartDataDensitymap.map_view_limit.min_lat
                          : ""
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        map_view_limit: {
                          ...chartDataDensitymap.map_view_limit,
                          min_lat:
                            e.target.value || e.target.value !== ""
                              ? e.target.value
                              : null,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2 pl-1">
                <div className="form__group">
                  <input
                    type="text"
                    id=""
                    className="form-control form__control"
                    placeholder="Min Long"
                    value={
                      chartDataDensitymap.map_view_limit
                        ? chartDataDensitymap.map_view_limit.min_lng
                          ? chartDataDensitymap.map_view_limit.min_lng
                          : ""
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartDataDensitymap({
                        ...chartDataDensitymap,
                        map_view_limit: {
                          ...chartDataDensitymap.map_view_limit,
                          min_lng:
                            e.target.value || e.target.value !== ""
                              ? e.target.value
                              : null,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {/* SUBMIT */}
            <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
              <button
                className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
                disabled={
                  !selectedDataset ||
                  !chartDataDensitymap.plot_type ||
                  !chartDataDensitymap.base_map ||
                  !chartDataDensitymap.zoom ||
                  !chartDataDensitymap.lat ||
                  !chartDataDensitymap.lng ||
                  !chartDataDensitymap.z_value ||
                  !chartDataDensitymap.radius ||
                  !chartDataDensitymap.color_palate
                }
                onClick={() => {
                  applyDatasetClickHandler();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapboxDensityHeatmapChartUpdateModal);
