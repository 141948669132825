import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSubscriptions } from "../../../actions/billingAction";
import request from "../../../utils/request";
import Packages from "../Pricing";

const Subscription = ({ subscription }) => {
  const [pricingOpen, setPricingOpen] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState(null);
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(getSubscriptions()).then((res) => {
      setSubscriptionList(res.data.subscription_list);
    });
  }, []);

  const loadSubscriptionList = () => {
    dispatch(getSubscriptions()).then((res) => {
      setSubscriptionList(res.data.subscription_list);
    });
  }

  return (
    <div className="user__panel--tab-content-block">
      <table className="table table-dark user__panel--table">
        <tbody>
          <tr>
            <th scope="row" style={{ width: "140px" }}>
              Plan Name
            </th>
            <td>
              {subscription && subscription.plan_name
                ? subscription.plan_name
                : "No plan"}
              <button
                style={{ float: "right", padding: "0px 10px" }}
                className="btn btn-danger"
                onClick={() => setPricingOpen(!pricingOpen)}
              >
                {pricingOpen ? "NOT NOW" : "UPGRADE NOW"}
              </button>
            </td>
          </tr>
          {pricingOpen ? (
            <tr>
              <td style={{ paddingBottom: "3.5rem" }} colSpan={3}>
                <Packages loadSubscriptionList={loadSubscriptionList}/>
              </td>
            </tr>
          ) : null}
          <tr>
            <th scope="row" style={{ width: "140px" }}>
              Total Space
            </th>
            <td>
              {subscription && subscription.total_space
                ? subscription.total_space
                : ""}
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "140px" }}>
              Data Used
            </th>
            <td>
              {subscription && subscription.plan_name
                ? subscription.plan_name
                : "No plan"}
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "140px" }}>
              Free Space
            </th>
            <td>
              {subscription && subscription.used_space
                ? subscription.used_space
                : ""}
            </td>
          </tr>
        </tbody>
      </table>
      <h4 className="mb-2 mt-5">Subscription History</h4>
      <div className="table-responsive">
        <table className="table table-dark user__panel--table">
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Asset Count</th>
              <th>Selected Package</th>
              <th>Storage Capacity</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionList ? (
              subscriptionList.map((s, index) => (
                <tr key={index}>
                  <td>{s.date_start}</td>
                  <td>{s.date_end}</td>
                  <td>{s.asset_count}</td>
                  <td>{s.selected_package}</td>
                  <td>{s.storage_capacity}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No available list</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Subscription;
