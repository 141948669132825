import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

const ChartLayout_Y_Axis = ({
  isZAxisAvailable,
  chartLayout,
  setChartLayout,
}) => {
  const [yAxis, setYAxis] = useState({
    automargin: true,
    // zerolinecolor: "#00FFFB",
    zerolinewidth: 0,
    // zerolinewidth: 2,
    gridcolor: "#004B54",
    griddash: "dot",
    gridwidth: 1,
    // tickwidth: 15,
    // type: 'category',
    tickcolor: "#B9B8CE",
    tickangle: "auto",
    tickfont: {
      family: "sans-serif",
      size: 12,
      color: "#01E3FE",
    },
    title: {
      standoff: 10, //Similar as Margin Top
      text: "Y title",
      font: {
        family: "sans-serif",
        size: 12,
        color: "#fff",
      },
    },
    // range: [0, 50]
    linecolor: "#00FFFB",
    linewidth: 2,
  });

  useEffect(() => {
    setChartLayout({
      ...chartLayout,
      scene: { ...chartLayout.scene, y_axis: yAxis },
    });
  }, [yAxis]);

  return (
    <>
      {/* <div className="row form__row mt-4 pr-2">
        <div className="col-md-12 pr-2">
          <div className="form__group">
            <label>
              <strong>Legend</strong>
            </label>
          </div>
        </div>
      </div> */}
      {/* <div className="row form__row mt-0 pr-2"> */}
      <div className="col-md-3 pr-2 pl-1">
        <div className="form__group">
          <label>Y Axis Title Font</label>
        </div>
        <div className="form__group">
          <input
            type="number"
            className="form-control form__control"
            placeholder="10"
            value={yAxis.title.font.size}
            onChange={(e) => {
              e.stopPropagation();
              setYAxis({
                ...yAxis,
                title: {
                  ...yAxis.title,
                  font: { ...yAxis.title.font, size: e.target.value },
                },
              });
            }}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default ChartLayout_Y_Axis;
