import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "toastr";
import { getProjectStatInfo, postCustomDashboardPanel } from "../../../../actions/customPanelModify";
import G_Loader from "../../../G_Loader";
import { getPanelData } from "../panelType";

const Y_AX = 10;

const CreateDefaultPanels = (props) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );
  const activeTabInfo = useSelector( (state) => state.customPanelModify.ACTIVE_TAB_DATA );
  const panelList = useSelector( (state) => state.customPanelModify.ACTIVE_TAB_PANEL_LIST );
  const createDefaultPanelsFlag = useSelector( (state) => state.customPanelModify.createDefaultDashboardPanels );

  useEffect(() => {
    dispatch(getProjectStatInfo(panelFieldInfo.project_guid))
      .then((res) => {
        // // console.log(res.data);
        // --- INDICATOR ---
        selectedNewPanelHandler( { name: "Indicator", type: "indicator" }, { top: "Total Asset", val: { result: res.data.total_assets, column_name: "" }, unit: "count", btm: "Asset Modules" }, { x: 0, y: 0 + Y_AX, width: 300, height: 120, } );
        selectedNewPanelHandler( { name: "Indicator", type: "indicator" }, { top: "Total Pipeline", val: { result: res.data.total_pipelines, column_name: "" }, unit: "count", btm: "Pipeline Types" }, { x: 302, y: 0 + Y_AX, width: 300, height: 120, } );
        selectedNewPanelHandler( { name: "Indicator", type: "indicator" }, { top: "Total Asset Seabed", val: { result: res.data.total_assets_seabed, column_name: "" }, unit: "count", btm: "Seabed Stat" }, { x: 604, y: 0 + Y_AX, width: 300, height: 120, } );
        // --- CHART ---
        selectedNewPanelHandler( { name: "Line Chart", type: "line_chart" }, null, { x: 300 + 2 + 300 + 2 + 300 + 2, y: 0 + Y_AX, width: window.innerWidth - (300 + 2 + 300 + 2 + 300 + 2 + 8), height: 150, })
        selectedNewPanelHandler( { name: "Bar Chart", type: "bar_chart" }, null, { x: 300 + 2 + 300 + 2 + 300 + 2, y: 152 + Y_AX, width: window.innerWidth - (300 + 2 + 300 + 2 + 300 + 2 + 8), height: 150, })
        // selectedNewPanelHandler( { name: "Stacked bar Chart", type: "stacked_bar_chart" }, null, { x: 300 + 2 + 300 + 2 + 300 + 2, y: 304 + Y_AX, width: window.innerWidth - (300 + 2 + 300 + 2 + 300 + 2 + 8), height: 150, })
        // selectedNewPanelHandler( { name: "Stacked Column Chart", type: "stacked_column_chart" }, null, { x: 300 + 2 + 300 + 2 + 300 + 2, y: 456 + Y_AX, width: window.innerWidth - (300 + 2 + 300 + 2 + 300 + 2 + 8), height: 150, })
        // selectedNewPanelHandler( { name: "Gague Chart", type: "gague_chart" }, null, { x: 300 + 2 + 300 + 2 + 300 + 2, y: 608 + Y_AX, width: window.innerWidth - (300 + 2 + 300 + 2 + 300 + 2 + 8), height: 150, })
        // selectedNewPanelHandler( { name: "Donut Chart", type: "donut_chart" }, null, { x: 300 + 2 + 300 + 2 + 300 + 2, y: 760 + Y_AX, width: window.innerWidth - (300 + 2 + 300 + 2 + 300 + 2 + 8), height: 150, })
        selectedNewPanelHandler( { name: "Pie Chart", type: "pie_chart" }, null, { x: 300 + 2, y: 122 + Y_AX, width: 300, height: 220, })
        selectedNewPanelHandler( { name: "Pie Chart", type: "pie_chart" }, null, { x: 300 + 2 + 300 + 2, y: 122 + Y_AX, width: 300, height: 220, })
        // --- TABLE ---
        selectedNewPanelHandler( { name: "Data Table", type: "data_table" }, null, { x: 300 + 2 + 300 + 2 + 300 + 2, y: 304 + Y_AX, width: window.innerWidth - (300 + 2 + 300 + 2 + 300 + 2 + 8), height: 340, })
        // --- MAP ---
        selectedNewPanelHandler( { name: "Map", type: "map" } , null, { x: 0, y: 122 + Y_AX, width: 300, height: 220, })
        // --- SPECIFICATION ---
        selectedNewPanelHandler( { name: "Specification", type: "specification" } , null, { x: 0, y: 344 + Y_AX, width: 300, height: 300, })
        // --- IMAGE ---
        selectedNewPanelHandler( { name: "Image", type: "image" } , null, { x: 300 + 2, y: 344 + Y_AX, width: 300, height: 300, })
        // --- DOCUMENT ---
        selectedNewPanelHandler( { name: "Document", type: "document" } , null, { x: 300 + 2 + 300 + 2, y: 344 + Y_AX, width: 300, height: 300, })
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const selectedNewPanelHandler = (panel, panel_data, position) => {
    // // console.log(panel);
    let panel_ = {
      name: panel.name,
      panel_config: getPanelData(panel.type.toLowerCase()),
    };
    if(panel_data && panel.type === "indicator") {
      panel_.panel_config.data.topHeaderName = panel_data.top
      panel_.panel_config.data.value = panel_data.val
      panel_.panel_config.data.unit = panel_data.unit
      panel_.panel_config.data.bottomHeaderName = panel_data.btm
    } 
    // else if (panel.type === "line_chart" || panel.type === "bar_chart" || panel.type === "pie_chart") {}
    panel_.panel_config.defaultPosition = position
    // // console.log(panel_);
    dispatch(postCustomDashboardPanel(activeTabInfo.guid, panel_));
  };

  useEffect(() => {
    if(createDefaultPanelsFlag && panelList.length === 12) {
      setTimeout(() => {
        dispatch({
          type: "CREATE_DEFAULT_DASHBOARD_PANELS_FLAG",
          payload: false,
        });
      }, 1000);
    }
  }, [createDefaultPanelsFlag, panelList])

  return (
    <>
      <p className="default-panel-info-txt">Please Wait. We are preparing your default panels.</p>
      <G_Loader style={{ position: "fixed", left: "48%", top: "48%", zIndex: "9999" }} />
    </>
  );
};

export default CreateDefaultPanels;
