import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  createChart,
  getChartData_Bar_UniqueValue,
  getDatasetColumns,
  updateCustomDashboardPanel,
  updateCustomDashboardPanel_CHART_GUID,
  updateCustomDashboardPanel___,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import ModalAT from "../../ModalAT";
// import Multiselect from 'multiselect-react-dropdown';
import Filter_ from "./filter";

const BarChartUpdateModal = () => {
  const dispatch = useDispatch();
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );
  const isWebglDashboardActive = useSelector(
    (state) => state.customPanelModify.isWebglDashboard
  );
  // --- MODAL CONF ---
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedDatasetColumnList, setSelectedDatasetColumnList] =
    useState(null);

  const [modalTitle, setModalTitle] = useState(null);

  const [chartTitle, setChartTitle] = useState("Barplot");
  const [xAxisTitle, setXAxisTitle] = useState(null);
  const [yAxisTitle, setYAxisTitle] = useState(null);
  const [xAxisCategory, setXAxisCategory] = useState(null);

  const [xAxisErrMsg, setXAxisErrMsg] = useState(null);
  const [xAxisCategories, setXAxisCategories] = useState([
    { group_by: null, name: "", type: "bar" },
  ]);

  const [barGroupVariable, setBarGroupVariable] = useState(null);
  const [barGroupOperation, setBarGroupOperation] = useState("count");
  const [barMode, setBarMode] = useState("group");

  const [barPlotType, setBarPlotType] = useState("Vertical");
  const [barPlotSubCategory, setBarPlotSubCategory] = useState(null);

  const [editFlag, setEditFlag] = useState(false);

  const [chartTypeBarOrLine, setChartType_LineOrBar] = useState("bar");

  const [addFilter, setAddFilter] = useState(null);
  const [addFilter_columnDataType, setAddFilter_columnDataType] =
    useState(null);
  const [addFilter_columnData, setAddFilter_columnData] = useState(null);
  const [filteredBySearch, setFilteredBySearch] = useState([]);
  const [selectedFilteredValue, setSelectedFilteredValue] = useState([]);
  const [selectedFilteredValue_type, setSelectedFilteredValueType] =
    useState(null);

  const [sortBy_column, setSortBy_column] = useState(null);
  const [sortBy_type, setSortBy_type] = useState(null);

  const [dataPoints, setDataPoints] = useState(5000);

  useEffect(() => {
    if (addFilter && addFilter !== "None") {
      let column_ = JSON.parse(addFilter).col_name;
      setSelectedFilteredValue([]);
      dispatch(
        getChartData_Bar_UniqueValue(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          selectedDataset.guid,
          column_
        )
      )
        .then((res) => {
          // console.log(res.data);
          setAddFilter_columnData(res.data.col_data_x);
          setAddFilter_columnDataType(res.data.col_type);

          if (
            panelInfo.panel_config.bar_data &&
            panelInfo.panel_config.bar_data.selected_filter_col_value
          ) {
            // // console.log(panelInfo.panel_config.bar_data.selected_filter_col_value)
            // // console.log(res.data.col_type)
            if (res.data.col_type === "numeric") {
              setSelectedFilteredValue(
                panelInfo.panel_config.bar_data.selected_filter_col_value
              );
            } else {
              let fv = [];
              setTimeout(() => {
                res.data.col_data_x.forEach((el, in_) => {
                  panelInfo.panel_config.bar_data.selected_filter_col_value.forEach(
                    (col_) => {
                      if (el === col_) {
                        if (
                          document.getElementById(
                            "filter_column_unique_value_" + in_
                          )
                        ) {
                          document.getElementById(
                            "filter_column_unique_value_" + in_
                          ).checked = true;
                        }
                        // setSelectedFilteredValue([...selectedFilteredValue, col_]);
                        fv.push(col_);
                      }
                    }
                  );
                });
                setSelectedFilteredValue(fv);
              }, 1000);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } else if (addFilter === "None") {
      // console.log("None");
    }
  }, [addFilter]);

  window.barChartEditModalOpen = (panel) => {
    setPanelInfo(panel);
    setModalTitle("Update Single Chart Data");
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    // console.log(panelInfo.panel_config);
    if (panelInfo.panel_config.data) {
      document.getElementById("bar_chart_edit__chart_title").value =
        panelInfo.panel_config.bar_data ? panelInfo.panel_config.bar_data.chart_name : panelInfo.panel_config.data.chart_name;
      setChartTitle(panelInfo.panel_config.bar_data ? panelInfo.panel_config.bar_data.chart_name : panelInfo.panel_config.data.chart_name);
      // SELEC DATASET
      datasetList.forEach((dataset, index) => {
        if (panelInfo.panel_config.data.dataset === dataset.guid) {
          document.getElementById(
            "bar_chart_edit__chart_dataset_select_option"
          ).value = JSON.stringify(dataset);
          // GET SELECTED DATASET COLUMN
          selectDatasetHandler(JSON.stringify(dataset));
          // SELECT X AXIS COLUMN FLAG
          setEditFlag(true);
        }
      });
      document.getElementById("bar_chart_edit__chart_x_axis_title").value =
        panelInfo.panel_config.data.x_axis_label;
      setXAxisTitle(panelInfo.panel_config.data.x_axis_label);
      document.getElementById("bar_chart_edit__chart_y_axis_title").value =
        panelInfo.panel_config.data.y_axis_label;
      setYAxisTitle(panelInfo.panel_config.data.y_axis_label);
      document.getElementById(
        "bar_chart_edit__chart_x_axis_category_select_option___operation"
      ).value = panelInfo.panel_config.bar_data.bar_group_operation;
      setBarGroupOperation(panelInfo.panel_config.bar_data.bar_group_operation);
      // document.getElementById("bar_chart_edit__chart_type_select_option___bar_line").value = panelInfo.panel_config.bar_data.chart_type;
      // setChartType_LineOrBar(panelInfo.panel_config.bar_data.chart_type);
      document.getElementById(
        "bar_chart_edit__chart_type_select_option___bar_line"
      ).value = panelInfo.panel_config.bar_data.chart_type_adv;
      setChartType_LineOrBar(panelInfo.panel_config.bar_data.chart_type_adv);
      if (panelInfo.panel_config.bar_data) {
        if (panelInfo.panel_config.bar_data.bar_plot_type === "Vertical") {
          document.getElementById(
            "bar-plot-type-toggle-verticle"
          ).checked = true;
          document.getElementById(
            "bar-plot-type-toggle-horizontal"
          ).checked = false;
          setBarPlotType("Vertical");
        } else {
          document.getElementById(
            "bar-plot-type-toggle-verticle"
          ).checked = false;
          document.getElementById(
            "bar-plot-type-toggle-horizontal"
          ).checked = true;
          setBarPlotType("Horizontal");
        }
      }
      if (panelInfo.panel_config.data.y_axis_categories.length > 0) {
        // document.getElementById("bar_chart_edit__chart_group_option_select_option___bar_mode").value = "group";
        setBarMode("group");
      }

      setDataPoints(
        panelInfo.panel_config.bar_data.data_points
          ? panelInfo.panel_config.bar_data.data_points
          : 5000
      );
    }
  };

  useEffect(() => {
    if (
      editFlag &&
      selectedDatasetColumnList &&
      panelInfo.panel_config.bar_data
    ) {
      setEditFlag(false);
      selectedDatasetColumnList.forEach((col, index) => {
        if (
          col.col_name === panelInfo.panel_config.bar_data.bar_group_variable
        ) {
          document.getElementById(
            "bar_chart_edit__chart_x_axis_category_select_option___category"
          ).value = JSON.stringify(col);
          setBarGroupVariable(
            panelInfo.panel_config.bar_data.bar_group_variable
          );
        }
        if (
          panelInfo.panel_config.bar_data.sub_category &&
          col.col_name === panelInfo.panel_config.bar_data.sub_category
        ) {
          document.getElementById(
            "bar_chart_edit__chart_x_axis_category_select_option___sub_category"
          ).value = JSON.stringify(col);
          setBarPlotSubCategory(panelInfo.panel_config.bar_data.sub_category);
        }
        if (
          panelInfo.panel_config.bar_data.selected_filter_column_name &&
          col.col_name ===
            panelInfo.panel_config.bar_data.selected_filter_column_name.col_name
        ) {
          document.getElementById("bar_chart_edit__chart_add_filter").value =
            JSON.stringify(col);
          setAddFilter(JSON.stringify(col));
        }
        if (
          col.col_name === panelInfo.panel_config.bar_data.sort_by &&
          document.getElementById("bar_chart_edit__chart_sort_by")
        ) {
          document.getElementById("bar_chart_edit__chart_sort_by").value =
            JSON.stringify(col);
          setSortBy_column(col.col_name);
        }
      });

      if (document.getElementById("bar_chart_edit__chart_sort_type")) {
        document.getElementById("bar_chart_edit__chart_sort_type").value =
          panelInfo.panel_config.bar_data.sort_type;
      }
      setSortBy_type(panelInfo.panel_config.bar_data.sort_type);

      // SERIES / VALUES
      let series = [];
      panelInfo.panel_config.data.y_axis_categories.forEach((y_col) => {
        series.push(y_col);
      });
      // 1 SECOND DELAY FOR DOM RANDER
      setTimeout(() => {
        panelInfo.panel_config.data.y_axis_categories.forEach((y_col, i) => {
          selectedDatasetColumnList.forEach((col, index) => {
            if (y_col.group_by === col.col_name) {
              // console.log(y_col);
              document.querySelector(
                "#bar_chart_edit__chart_y_axis_category_select_option___series_value___" +
                  i
              ).value = JSON.stringify(col);
              document.querySelector(
                "#bar_chart_edit__chart_y_axis_category___input___" + i
              ).value = y_col.name;
              // document.querySelector( "#bar_chart_edit__chart_y_axis_category_select_option___type___" + i ).value = y_col.type;
            }
          });
        });
      }, 1000);
      setXAxisCategories(series);
    }
  }, [editFlag, selectedDatasetColumnList]);

  const closeModal = () => {
    setChartTitle(null);
    setXAxisTitle(null);
    setYAxisTitle(null);
    setXAxisCategory(null);
    setSelectedDataset(null);
    setSelectedDatasetColumnList(null);
    setBarMode("group");
    setBarGroupVariable(null);
    setBarGroupOperation("count");
    setChartType_LineOrBar("bar");
    setXAxisErrMsg(null);
    setBarPlotType("Vertical");
    setBarPlotSubCategory(null);
    setXAxisCategories([{ group_by: null, name: "", type: "bar" }]);
    setAddFilter(null);
    setAddFilter_columnData(null);
    setFilteredBySearch([]);
    setSelectedFilteredValue([]);
    setSortBy_column(null);
    setSortBy_type(null);
    setDataPoints(5000);
    setIsOpen(false);
  };

  const selectDatasetHandler = (data) => {
    if (data === "ADD_NEW_DATASET") {
      closeModal();
      document.getElementById("new-dataset-input-modal__opener-el").click();
    } else {
      setSelectedDataset(JSON.parse(data));
      dispatch({
        type: "UPDATE_REQUEST_LOADER_STATE",
        payload: true,
      });
      dispatch(
        getDatasetColumns(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          JSON.parse(data).guid
        )
      )
        .then((res) => {
          setSelectedDatasetColumnList(res.data.data);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        })
        .catch((err) => {
          // console.log(err);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        });
    }
  };

  const applyDatasetClickHandler = () => {
    let chart_data = {
      chart_type_api_sep: "bar",
      chart_type: getChartType(chartTypeBarOrLine),
      chart_type_adv: chartTypeBarOrLine,
      chart_name: chartTitle ? chartTitle : "Bar Plot",
      dataset_guid: selectedDataset.guid,
      is_active: "true",
      x_axis_label: xAxisTitle ? xAxisTitle : "X",
      x_axis_category: xAxisCategory ? xAxisCategory : "no data",
      y_axis_label: yAxisTitle ? yAxisTitle : "Y",
      y_axis_categories:
        barMode === "default"
          ? []
          : xAxisCategories.filter((yy) => yy.group_by !== null),
      bar_mode: barMode,
      bar_group_variable: barGroupVariable,
      bar_group_operation: barGroupOperation,
      x_axis_categories: xAxisCategories.filter((yy) => yy.group_by !== null),
      bar_plot_type: barPlotType,
      sub_category: barPlotSubCategory,
      selected_filter_column_name:
        addFilter && addFilter !== "None" ? JSON.parse(addFilter) : null,
      selected_filter_col_value:
        addFilter && addFilter !== "None" ? selectedFilteredValue : [],
      selected_filter_col_value_type:
        selectedFilteredValue_type && addFilter !== "None"
          ? selectedFilteredValue_type
          : null,
      sort_by: sortBy_column,
      sort_type: sortBy_type,
      data_points:
        dataPoints === "" || dataPoints === null || parseInt(dataPoints) === 0
          ? 5000
          : dataPoints,
    };
    // console.log(chart_data);
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    // dispatch(
    //   createChart(
    //     panelFieldInfo.panel_type,
    //     panelFieldInfo.panel_type_guid,
    //     chart_data
    //   )
    // )
    //   .then((res) => {
    //     panelInfo.panel_config.data = res.data;
    //     panelInfo.panel_config.bar_data = chart_data;
    //     dispatch(
    //       updateCustomDashboardPanel(
    //         activeTabData.guid,
    //         panelInfo.guid,
    //         panelInfo.panel_config
    //       )
    //     );
    //     dispatch(
    //       updateCustomDashboardPanel_CHART_GUID(
    //         activeTabData.guid,
    //         panelInfo.guid,
    //         res.data.guid
    //       )
    //     );
    //     closeModal();
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //   });
    if(!panelInfo.panel_config.data || panelInfo.panel_config.data.dataset !== selectedDataset.guid) {
      dispatch(
        createChart(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          chart_data
        )
      )
        .then((res) => {
          panelInfo.panel_config.data = res.data;
          panelInfo.panel_config.bar_data = chart_data;
          dispatch(
            updateCustomDashboardPanel___(activeTabData.guid, panelInfo.guid, {
              chart_guid: res.data.guid,
              panel_config: panelInfo.panel_config,
            })
          ).then(() => {
            closeModal();
          })
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      panelInfo.panel_config.data = panelInfo.panel_config.data;
      panelInfo.panel_config.data.dataset = selectedDataset.guid;
      panelInfo.panel_config.bar_data = chart_data;
      dispatch(
        updateCustomDashboardPanel(
          activeTabData.guid,
          panelInfo.guid,
          panelInfo.panel_config
        )
      );
    closeModal();
    }
  };

  const getChartType = (type) => {
    if (type === "area_line") return "line";
    else if (type === "area_scatter") return "line";
    else return type;
  };

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_bar_chart_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle={modalTitle}
          contentLabel={modalTitle}
          ariaHideApp={false}
        >
          <div className="chart-plot-modal-container">
            <div className="row form__row">
              <div className="col-md-6 pr-2">
                <div className="form__group">
                  <label>Chart Title</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_title"
                    placeholder="Chart Title"
                    defaultValue={"Barplot"}
                    onChange={(e) => {
                      e.stopPropagation();
                      setChartTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 pl-1">
                <div className="form__group">
                  <label>Select Data*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_dataset_select_option"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      selectDatasetHandler(e.target.value);
                    }}
                  >
                    <option value={null} selected disabled>
                      Select dataset
                    </option>
                    {!isWebglDashboardActive ? (
                      <option value={"ADD_NEW_DATASET"}>Add New Dataset</option>
                    ) : (
                      <></>
                    )}
                    <optgroup label="Uploaded Dataset">
                      {datasetList.map((dataset, index) => (
                        <option value={JSON.stringify(dataset)} key={index}>
                          {dataset.name}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
            {/* CATEGORY & SUB CATEGORY */}
            <div className="row form__row mt-2 align-items-baseline">
              <div className="col-md-6 pr-2">
                <div className="form__group">
                  <label>Select Category*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_x_axis_category_select_option___category"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      // console.log(e.target.value);
                      setBarGroupVariable(JSON.parse(e.target.value).col_name);

                      document.getElementById(
                        "bar_chart_edit__chart_x_axis_title"
                      ).value = JSON.parse(e.target.value).col_name;
                      setXAxisTitle(JSON.parse(e.target.value).col_name);
                    }}
                  >
                    <option value="" disabled selected>
                      Please Select
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            {
                              // col.col_type === "str" ? <option value={JSON.stringify(col)} key={index}>{col.col_name}</option> : <></>
                              <option value={JSON.stringify(col)} key={index}>
                                {col.col_name}
                              </option>
                            }
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-6 pl-1">
                <div className="form__group">
                  <label>Add Sub Category</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_x_axis_category_select_option___sub_category"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setBarPlotSubCategory(
                        JSON.parse(e.target.value).col_name
                      );
                    }}
                  >
                    <option value="" selected disabled>
                      Add Sub Category (Optional)
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index) => (
                          <>
                            {
                              // col.col_type === "str" ? <option value={JSON.stringify(col)} key={index}>{col.col_name}</option> : <></>
                              <option value={JSON.stringify(col)} key={index}>
                                {col.col_name}
                              </option>
                            }
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
            </div>
            {/* SERIES OR VALUES & OPERATION */}
            <div className="row form__row mt-4">
              <div className="col-md-12">
                <div className="form__group">
                  <label>
                    <strong>Y Axis Series or Values</strong>
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex justify-content-between mb-2">
                  <div className="col-md-6 pl-0 pr-2">
                    <div className="form__group">
                      <label>Add Series or Value</label>
                    </div>
                  </div>
                  <div className="col-md-6 pl-1 pr-0">
                    <div className="form__group">
                      <label>Series or Line Name</label>
                    </div>
                  </div>
                </div>
                {xAxisCategories.map((y_cat, index) => (
                  <div className="d-flex justify-content-between mb-2">
                    <div className="col-md-6 pl-0 pr-2">
                      {/* <div className="form__group">
                      <label>Add Series or Value</label>
                    </div> */}
                      <div className="form__group">
                        <select
                          className="custom-select"
                          id={
                            "bar_chart_edit__chart_y_axis_category_select_option___series_value___" +
                            index
                          }
                          onChange={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let y_datas = xAxisCategories;
                            y_datas[index].group_by = JSON.parse(
                              e.target.value
                            ).col_name;
                            setXAxisCategories(y_datas);
                            // PREDEFINED INPUT VALUE
                            xAxisCategories[index].name = JSON.parse(
                              e.target.value
                            ).col_name;
                            document.querySelector(
                              "#bar_chart_edit__chart_y_axis_category___input___" +
                                index
                            ).value = JSON.parse(e.target.value).col_name;

                            if (index === 0) {
                              document.getElementById(
                                "bar_chart_edit__chart_y_axis_title"
                              ).value = JSON.parse(e.target.value).col_name;
                              setYAxisTitle(
                                JSON.parse(e.target.value).col_name
                              );
                            }
                          }}
                        >
                          <option value="" selected disabled>
                            Add Series or Value
                          </option>
                          {selectedDatasetColumnList ? (
                            <>
                              {selectedDatasetColumnList.map((col, index) => (
                                <>
                                  {col.col_type === "int" ||
                                  col.col_type === "float" ? (
                                    <>
                                      <option
                                        value={JSON.stringify(col)}
                                        key={index}
                                      >
                                        {col.col_name}
                                      </option>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-2 pl-1 pr-0">
                    <div className="form__group">
                      <label>Type</label>
                    </div>
                    <div className="form__group">
                        <div className="form__group">
                          <select
                            className="custom-select"
                            id={"bar_chart_edit__chart_y_axis_category_select_option___type___" + index}
                            onChange={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              xAxisCategories[index].type = e.target.value;
                            }}
                          >
                            <option value="bar" selected>Bar</option>
                            <option value="line">Line</option>
                          </select>
                        </div>
                    </div>
                  </div> */}
                    <div className="col-md-6 pl-1 pr-0 d-flex justify-content-between">
                      {/* <div className="form__group">
                      <label>Series or Line Name</label>
                    </div> */}
                      <div className="form__group" style={{ width: "85%" }}>
                        <input
                          type="text"
                          className="form-control form__control"
                          id={
                            "bar_chart_edit__chart_y_axis_category___input___" +
                            index
                          }
                          placeholder="Series Name"
                          onChange={(e) => {
                            e.stopPropagation();
                            xAxisCategories[index].name = e.target.value;
                          }}
                        />
                      </div>
                      <div className="form__group">
                        <button
                          className="btn btn__icon__delete position-relative"
                          onClick={(e) => {
                            e.stopPropagation();
                            xAxisCategories[index].group_by = null;
                            xAxisCategories[index].name = "";
                            document.getElementById(
                              "bar_chart_edit__chart_y_axis_category_select_option___series_value___" +
                                index
                            ).parentNode.parentNode.parentNode.className =
                              "d-flex justify-content-between mb-2 _twin_hide_";
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="red"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fillRule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="form__group__flex d-flex justify-content-between mb-1">
                  <span style={{ color: "red" }}>
                    {xAxisErrMsg ? xAxisErrMsg : ""}
                  </span>
                </div>
                <div className="form__group__flex d-flex justify-content-end mb-1">
                  <button
                    className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        xAxisCategories[xAxisCategories.length - 1].group_by
                      ) {
                        setXAxisErrMsg(null);
                        setXAxisCategories([
                          ...xAxisCategories,
                          { group_by: null, name: "", type: "bar" },
                        ]);
                      } else {
                        setXAxisErrMsg("Fill previous values");
                        setTimeout(() => {
                          setXAxisErrMsg(null);
                        }, 3000);
                      }
                      // console.log(xAxisCategories);
                    }}
                  >
                    Add Series {getIconFromList("PLUS_SQUARE", {})}
                  </button>
                </div>
              </div>
            </div>
            {/* OPERATION & CHART TYPE */}
            <div className="row form__row mt-2">
              <div className="col-md-6 pr-0 pr-2">
                <div className="form__group">
                  <label>Select Operation*</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_x_axis_category_select_option___operation"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      // setBarGroupOperation(e.target.value);
                      setBarGroupOperation(
                        e.target.value !== "None" ? e.target.value : null
                      );
                    }}
                  >
                    <option value={null} selected>
                      None
                    </option>
                    <option value="count" selected>
                      Count
                    </option>
                    <option value="sum">Sum</option>
                    <option value="mean">Average</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 pl-1">
                <div className="form__group">
                  <label>Type</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_type_select_option___bar_line"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setChartType_LineOrBar(e.target.value);
                    }}
                  >
                    <option value="bar" selected>
                      Bar
                    </option>
                    <option value="line">Line</option>
                    {/* <option value="area_line">Area</option> */}
                  </select>
                </div>
              </div>
            </div>
            {/* ------------- FILTERING ------------- */}
            <Filter_
              bar_data={panelInfo.panel_config.bar_data}
              setSelectedFilteredValue={setSelectedFilteredValue}
              addFilter_columnData={addFilter_columnData}
              setAddFilter_columnData={setAddFilter_columnData}
              addFilter_columnDataType={addFilter_columnDataType}
              setAddFilter_columnDataType={setAddFilter_columnDataType}
              setAddFilter={setAddFilter}
              selectedDatasetColumnList={selectedDatasetColumnList}
              selectedFilteredValue={selectedFilteredValue}
              selectedFilteredValue_type={selectedFilteredValue_type}
              setSelectedFilteredValueType={setSelectedFilteredValueType}
            />
            {/* ------------------- SORT ------------------- */}
            <div className="row form__row mt-2">
              <div className="col-md-4 pr-2">
                <div className="form__group">
                  <label>Sort By</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_sort_by"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSortBy_column(JSON.parse(e.target.value).col_name);

                      document.getElementById(
                        "bar_chart_edit__chart_sort_type"
                      ).value = "asc";
                      setSortBy_type("asc");
                    }}
                  >
                    <option value="" selected disabled>
                      Please select
                    </option>
                    {selectedDatasetColumnList ? (
                      <>
                        {selectedDatasetColumnList.map((col, index_) => (
                          <>
                            <option value={JSON.stringify(col)} key={index_}>
                              {col.col_name}
                            </option>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-4 pl-1">
                <div className="form__group">
                  <label>Sort Type</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    id="bar_chart_edit__chart_sort_type"
                    disabled={sortBy_column === null}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSortBy_type(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      None
                    </option>
                    <option value="asc">Ascending</option>
                    <option value="des">Descending</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 pl-1">
                <div className="form__group">
                  <label>Data Points</label>
                </div>
                <div className="form__group">
                  <input
                    type="number"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_data_point"
                    min={100}
                    value={dataPoints}
                    placeholder="5000"
                    onChange={(e) => {
                      e.stopPropagation();
                      setDataPoints(parseInt(e.target.value) > 0 ? e.target.value : "");
                    }}
                  />
                </div>
              </div>
            </div>
            {/* X Y TITLE */}
            <div className="row form__row mt-2">
              <div className="col-md-6 pr-2">
                <div className="form__group">
                  <label>Horizontal (X) axis Label</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_x_axis_title"
                    placeholder="Horizontal (X) axis Label"
                    onChange={(e) => {
                      e.stopPropagation();
                      setXAxisTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 pl-1">
                <div className="form__group">
                  <label>Vertical (Y) axis Label</label>
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    className="form-control form__control"
                    id="bar_chart_edit__chart_y_axis_title"
                    placeholder="Vertical (Y) axis Label"
                    onChange={(e) => {
                      e.stopPropagation();
                      setYAxisTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* BAR PLOT TYPE - VERTICLE / HORAIZONTAL */}
            <div className="row form__row mt-2 pl-0">
              <div className="col-md-6">
                <label htmlFor="" className="form__label m-0">
                  Select Chart Orientation*
                </label>
              </div>
              <div className="col-md-6 d-flex">
                <div className="form-check mr-4">
                  <input
                    className="form-check-input"
                    id="bar-plot-type-toggle-verticle"
                    type="checkbox"
                    defaultChecked={"checked"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        document.getElementById(
                          "bar-plot-type-toggle-horizontal"
                        ).checked = false;
                        setBarPlotType("Vertical");
                      }
                    }}
                  />
                  <label className="form-check-label">Vertical (Column)</label>
                </div>
                <div className="form-check mr-2">
                  <input
                    className="form-check-input"
                    id="bar-plot-type-toggle-horizontal"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        document.getElementById(
                          "bar-plot-type-toggle-verticle"
                        ).checked = false;
                        setBarPlotType("Horizontal");
                      }
                    }}
                  />
                  <label className="form-check-label">Horizontal (Bar)</label>
                </div>
              </div>
            </div>
            {/* SUBMIT */}
            <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
              <button
                className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
                disabled={!selectedDataset || !xAxisTitle || !yAxisTitle}
                onClick={() => {
                  applyDatasetClickHandler();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BarChartUpdateModal);
