import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectStats } from "../../../actions/authActions";
import Loader from "../../../components/Loader";

const ProjectStorage = () => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.auth.stats);

  if (!stats) {
    setTimeout(() => {
      dispatch(getProjectStats());
    }, 2000);
  }

  return (
    <>
      <div className="user__panel--tab-content--block">
        <h4 className="mb-2 mt-5">Total Fields Summary:</h4>

        {stats ? (
          <div className="table-responsive">
            <table className="table table-dark user__panel--table">
              <thead>
                <tr>
                  <th>{stats.No_of_Fields} Fields</th>
                  <th>{stats.No_of_Pipelines} Pipelines</th>
                  <th> {stats.No_of_Assets} Assets</th>
                  <th>{stats.No_of_Components} Components</th>
                  <th>{stats.No_of_Datasets} Datasets</th>
                  <th> {stats.No_of_Documents} Documents</th>
                  <th>{stats.No_of_Images} Images</th>
                  <th>Storage Status </th>
                  <th>{stats.No_of_Project_Share} Shared Field</th>
                </tr>
              </thead>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <div className="user__panel--tab-content--block">
        <h4 className="mb-2 mt-5">Individual Fields Details</h4>

        {stats ? (
          <div className="table-responsive">
            <table className="table table-dark user__panel--table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Pipeline</th>
                  <th>Asset</th>
                  <th>Component</th>
                  <th>Dataset</th>
                  <th>Documents</th>
                  <th>Images</th>

                  <th>Share Info</th>
                  <th>General Update Info</th>
                </tr>
              </thead>
              <tbody>
                {stats && stats.Fields && (
                  <Fragment>
                    {stats.Fields.map((field, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>
                          {field.Field_Name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {field.No_of_Pipelines ? field.No_of_Pipelines : 0}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {field.No_of_Assets}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {field.No_of_Components}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {parseInt(field.No_of_Dataset.Project) +
                            parseInt(field.No_of_Dataset.Pipeline) +
                            parseInt(field.No_of_Dataset.Asset)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {parseInt(field.No_of_Documents.Project) +
                            parseInt(field.No_of_Documents.Pipeline) +
                            parseInt(field.No_of_Documents.Asset)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {parseInt(field.No_of_Images.Project) +
                            parseInt(field.No_of_Images.Pipeline) +
                            parseInt(field.No_of_Images.Asset)}
                        </td>
                        <td>
                          <ul className="user__panel--table__list">
                            <li>Viewer: {field.No_of_viewers}</li>
                            <li>Editor: {field.No_of_editors}</li>
                            <li>No of Shares: {field.No_of_Shares}</li>
                          </ul>
                        </td>
                        <td>
                          <ul className="user__panel--table__list">
                            <li>Created at: 15 January, 2022</li>
                            <li>Last Updated: 25 January, 2022</li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default ProjectStorage;
