import React from "react";

const Billing = () => {
  return (
    <div className="user__panel--tab-content--block">
      <div className="billing-header mt-5 d-flex">
        <div className="flex-grow-1">
          <h4>Billing - (Static data - Development is in progress)</h4>
        </div>
        <div className="billing__filter d-flex flex-nowrap">
          <div className="billing__filter--group">
            <label htmlFor="billing__month">Month</label>
            <select
              name="billing__month"
              id="billing__month"
              className="custom-select custom-select-sm"
            >
              <option value="">January</option>
              <option value="">February</option>
            </select>
          </div>
          <div className="billing__filter--group">
            <label htmlFor="billing__year">Year</label>
            <select
              name="billing__year"
              id="billing__year"
              className="custom-select custom-select-sm"
            >
              <option value="">2022</option>
              <option value="">2021</option>
            </select>
          </div>
        </div>
      </div>
      <table className="table table-dark user__panel--table">
        <tbody>
          <tr>
            <th scope="row" style={{ width: "180px" }}>
              Month
            </th>
            <td style={{ width: "12px" }}>:</td>
            <td>July</td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "180px" }}>
              Subscription Type
            </th>
            <td style={{ width: "12px" }}>:</td>
            <td>Basic</td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "180px" }}>
              Subscription Fee/Month
            </th>
            <td style={{ width: "12px" }}>:</td>
            <td>$ 300.00</td>
          </tr>
          {/* <tr>
            <th scope="row" style={{ width: "180px" }}>
              Aseets used
            </th>
            <td style={{ width: "12px" }}>:</td>
          </tr> */}
          {/* <tr>
            <th scope="row" style={{ width: "180px" }}>
              Storage used
            </th>
            <td style={{ width: "12px" }}>:</td>
            <td>
              <p style={{ marginBottom: "5px", textAlign: "right" }}>
                <b>60 of 500 MB</b>
              </p>
              <div
                className="progress user__panel--progress"
                style={{ marginBottom: "10px" }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="55"
                  aria-valuemin="55"
                  aria-valuemax="100"
                  style={{ width: "55%" }}
                ></div>
              </div>
              
            </td>
          </tr> */}
          <tr>
            <th scope="row" style={{ width: "180px" }}>
              Asset Rate
            </th>
            <td style={{ width: "12px" }}>:</td>
            <td>$ 10.00</td>
          </tr>
          <tr>
            <th>Asset used</th>
            <td>:</td>
            <td>
              <p style={{ marginBottom: "5px", textAlign: "right" }}>
                <b>2 used out of 10 assets</b>
              </p>
              <div
                className="progress user__panel--progress"
                style={{ marginBottom: "10px" }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="55"
                  aria-valuemin="55"
                  aria-valuemax="100"
                  style={{ width: "55%" }}
                ></div>
              </div>
            </td>
          </tr>

          <tr>
            <th scope="row" style={{ width: "240px" }}>
              Total charge for this month
            </th>
            <td style={{ width: "12px" }}>:</td>
            <td>$ 30.00</td>
          </tr>
        </tbody>
      </table>

      <h4 className="mb-2 mt-5">History</h4>
      <div className="table-responsive">
        <table className="table table-dark user__panel--table">
          <thead>
            <tr>
              <th>Credit used: 2</th>
              <th>Current balance: 8</th>
              <th>Info</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>-1</td>
              <td>8</td>
              <td>Added new [asset name] on [project name]</td>
            </tr>
            <tr>
              <td>-1</td>
              <td>9</td>
              <td>Added new [asset name] on [project name]</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Billing;
