import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { projectExternalSync } from "../../../../actions/webglAssociatesActions";
import loader from "../../../../static/loader.gif";

import ReactModal from "react-modal";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import G_Loader from "../../../G_Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const AnalysisNavigator = () => {
  const [projectGuid, setProjectGuid] = useState(null);
  const [isVisable, setIsVisible] = useState(false);

  const requestPending = useSelector((state) => state.ui.requestPending);

  window.activateAppNavigator = (pguid) => {
    if (!pguid) return;
    setProjectGuid(pguid);
    // // console.log(isVisable);
    setIsVisible(!isVisable);
    if (!isVisable) {
      setTimeout(() => {
        document.getElementById("analysis__navigator").parentNode.style.width =
          "0px";
      }, 100);
    }
  };

  return (
    <ReactModal
      isOpen={isVisable}
      id="analysis__navigator"
      style={{
        overlay: {
          inset: "1",
          backgroundColor: "transparent",
          width: "400px",
          position: "fixed !important",
          // right: "0",
          // bottom: "0",
        },
        content: {
          maxWidth: "100% !important",
          maxHeight: "100% !important",
          border: "none",
          background: "transparent",
          height: "60px !important",
          overflow: "hidden",
          // position: "fixed",
          // right: "0",
          // bottom: "0",
          // border: "none",
          // background: "none",
          // overflow: "hidden",
          // WebkitOverflowScrolling: "touch",
          // borderRadius: "0",
          // outline: "none",
          // padding: "6px",
        },
      }}
    >
      {requestPending ? (
        <div id="UnityModalLoader">
          <G_Loader />
          {/* <HashLoader
            color={"#01e3fe"}
            loading={true}
            css={override}
            size={150}
          /> */}
        </div>
      ) : (
        <Fragment>
          <div className={"asset_specs_modal"} id="ANALYSIS_NAV">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div
                className="modal-content modal-content_table"
                style={{
                  width: "350px",
                  padding: "5px",
                  border: "1px solid #01e3fe",
                  backgroundColor: "#112f3b87",
                }}
              >
                {requestPending ? (
                  <div className="app-navigator-drawer_menu_icon">
                    <img src={loader} height="20px" width="20px" />
                  </div>
                ) : (
                  <div className="app-navigator-drawer_menu">
                    <div
                      className="app-navigator-drawer_items"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        type="button"
                        title="Engineering"
                        onClick={() =>
                          projectExternalSync(
                            `/api/projects/${projectGuid}/external/idare_engine/`
                          )
                        }
                        className="analysisCustomBTN"
                      >
                        Engineering
                      </button>
                      <button
                        type="button"
                        title="Asset Integrity"
                        onClick={() =>
                          window.open("https://ipim.idareengine.com", "_blank")
                        }
                        className="analysisCustomBTN"
                      >
                        Asset Integrity
                      </button>
                      <button
                        type="button"
                        title="Data Analytics"
                        onClick={() =>
                          projectExternalSync(
                            `/api/projects/${projectGuid}/external/automl/`
                          )
                        }
                        className="analysisCustomBTN"
                      >
                        Data Analytics
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </ReactModal>
  );
};

export default AnalysisNavigator;
