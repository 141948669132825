import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalAT from "../../ModalAT";
import ResourceDocs from "../panels/ResourceDocs";

const DashboardResourceList = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(null);
  const [panelFieldInfo_, setPanelFieldInfo_] = React.useState(null);
  const [activeTabInfo_, setActiveTabInfo_] = React.useState(null);
  const [resourceType, setResourceType] = React.useState(null);

  window.dashboardImgDocResourceModalOpener = (panelFieldInfo, activeTabInfo, restype) => {
    setPanelFieldInfo_(panelFieldInfo);
    setActiveTabInfo_(activeTabInfo);
    setResourceType(restype);
    setIsOpen(true);
  };

  const afterOpenModal = () => {};
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="resource_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle={resourceType === "images" ? "Image List" : "Document List"}
          contentLabel="Resource List"
          ariaHideApp={false}
        >
          {/* DATASET SELECTION */}
          {/* Resource List */}
          <ResourceDocs
              // panel={panel}
              // panelElementId={"rnd-section-image-" + panel.guid}
              resourceType={resourceType}
              // bodyHeight={panel.panel_config.defaultPosition.height}
              // setIsDraggableUnAvailable={setIsDraggableUnAvailable}
            />
          {/* {Array.from({ length: Object.keys(rowData).length }, (_, index) => (
            <div className="form__group">
              <p
                htmlFor=""
                className="form__label m-0"
                style={{ maxWidth: "450px", overflow: "auto" }}
              >
                {Object.keys(rowData)[index]} : {Object.values(rowData)[index]}
              </p>
            </div>
          ))} */}
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

export default DashboardResourceList;
