import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIconFromList } from "../../static/IconServe";
// import { useNavigate } from "react-router-dom";
import EnergyMetaLogo from "../../static/img/EnergyMeta_logo.png";

export default function TopHeader() {
  // const navigate = useNavigate();
  const isAuth = !!localStorage.getItem("refresh_token");
  const first_name = useSelector((state) => state.auth.first_name);
  const last_name = useSelector((state) => state.auth.last_name);
  const profile_img_link = useSelector((state) => state.auth.profile_img_link);

  // const logoutUser = () => {
  //   // debugger
  //   // window.stop();
  //   // window.location.replace("/");
  //   // window.unityContext.quitUnityInstance();
  //   document.querySelector(".unity-canvas").remove();
  //   // localStorage.removeItem("access_token");
  //   // localStorage.removeItem("refresh_token");
  //   // navigate("/")
  // };

  return (
    <div className="row header-row">
      {isAuth ? (
      <div className="d-flex header-left header-left-custom">
        {/* <div className="header-box">
          <Link to="/">
          <img width="160px" src={EnergyMetaLogo} alt="EnergyMeta Logo" />
            <p className="font_audiowide m-0">
              <span className="logo_size brand_color">
                Asset Twin<sup>®</sup>
              </span>
            </p>
          </Link>
        </div>
        <div className="header-arrow arrow-up-left" /> */}
      </div>
      ) : null}
      {isAuth ? (
        <div className="d-flex align-items-center header-right">
          <div className="d-flex">
            {/* <div className="header-arrow-right arrow-up-right" /> */}
            {/* header-box-long class will be add when hover user avatar */}
            <div className="header-box-right header-box-long">
              {/* help-menu-ui class will be remove when hover user avatar */}
              <div className="d-flex mt-1">
                <Link
                  to="/documentation"
                  target="_blank"
                  reloadDocument={true}
                  className="btn_custom text-uppercase mr-2 pr-1 pl-1"
                >
                  <img
                    className="doc-img"
                    width="28px"
                    src={require("../../static/img/doc.svg")}
                    title="Documentation"
                    alt=""
                  />
                </Link>
                <Link
                  to="/support"
                  target="_blank"
                  reloadDocument={true}
                  className="btn_custom text-uppercase ml-3 pr-2 pl-2 mr-3"
                >
                  <img
                    title="Help"
                    src={require("../../static/img/drop.svg")}
                    alt=""
                  />
                </Link>
                {/* <div className="dropdown dropdown_custom" id="LoggedUser1">
                  <button
                    className="btn transparent-btn btn-secondary p-0"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-dropdown-section w-auto">
                      <a
                        type="submit"
                        className="btn_custom text-uppercase pr-3 pl-3"
                        id="support"
                        href="#!"
                      >
                        <img
                          title="Help"
                          src={require("../../static/img/drop.svg")}
                          alt=""
                        />
                      </a>
                    </div>
                  </button>
                  <div
                    className="p-1 dropdown-menu help-dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      to="/support"
                      className="dropdown-item btn_custom text-uppercase pr-4 pl-2 text-left ml-0 mb-1"
                      target="_blank"
                      reloadDocument={true}
                      title="Support"
                    >
                      <img
                        className="mr-1"
                        src={require("../../static/img/help.svg")}
                        alt=""
                        title="Support"
                      />
                      {" Support"}
                    </Link>
                    <Link
                      to="/feedback"
                      target="_blank"
                      reloadDocument={true}
                      className="dropdown-item btn_custom text-uppercase pr-4 pl-2 text-left ml-0 mb-1"
                      title="Support"
                    >
                      <img
                        className="mr-1"
                        src={require("../../static/img/feed.svg")}
                        alt=""
                        title="Feedback"
                      />
                      {" Feedback"}
                    </Link>
                  </div>
                </div> */}
              </div>
              <div className="dropdown dropdown_custom avatar-photo-section">
                <div
                  className="user-avatar-ui"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className="user-avatar-img"
                    width="45px"
                    src={require("../../static/img/user-avatar.svg")}
                    alt="User Avatar"
                  />
                  <img
                    className="avatar-photo"
                    src={
                      profile_img_link
                        ? profile_img_link
                        : require("../../static/img/avatar.png")
                    }
                    alt=""
                  />
                  {/* <div className="for-hidden-user-title"></div> */}
                </div>
                <ul
                  className="dropdown-menu user-dropdown_custom top-header-dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {/* <li><a className="dropdown-item" href="#">Action</a></li> */}
                  <Link to="/profile" target="_blank" reloadDocument={true}>
                    {getIconFromList("PROFILE_ICON", {})}
                    Account
                  </Link>
                  <Link to="/billing" target="_blank" reloadDocument={true}>
                    {getIconFromList("BILLING_ICON", {})}
                    Billing
                  </Link>
                  <Link
                    to="/"
                    reloadDocument={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      localStorage.removeItem("access_token");
                      localStorage.removeItem("refresh_token");
                    }}
                  >
                    {getIconFromList("LOGOUT_ICON", {})}
                    Logout
                  </Link>
                  {/* <a
                    id="logout"
                    className="dropdown-item ml-0"
                    onClick={logoutUser}
                    href="#!"
                  >
                    Log out
                  </a> */}
                </ul>
              </div>
              <div className="user-title-ui">
                <img
                  className="user-title-img"
                  width="107px"
                  src={require("../../static/img/user-title.svg")}
                  alt=""
                />
                <p id="current_username" className="user-title mb-0">
                  {first_name && first_name + " "}
                  {last_name && last_name}
                </p>
              </div>
              {/* <div className="dropdown dropdown_custom" id="LoggedUser">
                <button
                  className="btn transparent-btn btn-secondary p-0"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="user-dropdown-section user-avatar-dropdown-section">
                    <div className="user-avatar-section">
                      <div className="user-avatar-position">
                        <img
                          className="user-avatar-img"
                          width="45px"
                          src={require("../../static/img/user-avatar.svg")}
                          alt="User Avatar"
                        />
                        <img
                          className="user-photo"
                          src={require("../../static/img/avatar.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="user-title-section">
                      <img
                        className="user-title-img"
                        width="107px"
                        src={require("../../static/img/user-title.svg")}
                        alt=""
                      />
                      <p id="current_username" className="user-title mb-0">
                        {defaultName}
                      </p>
                    </div>
                  </div>
                </button>
                <div
                  className="dropdown-menu user-dropdown_custom p-1"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link to="/profile" reloadDocument={true}>
                    Profile
                  </Link>
                  <a
                    id="logout"
                    className="dropdown-item ml-0"
                    onClick={logoutUser}
                  >
                    Log out
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
