import React, { useState, useRef, useEffect } from "react";
import { getIconFromList } from "../../../../../static/IconServe";

function MeshPlotViewPoint({
  viewPoint,
  setViewPoint,
  viewPointPrev,
  setViewPointPrev,
}) {
  useEffect(() => {
    setViewPoint("front");
  }, []);
  return (
    <>
      <div className="__mesh--view--icons__">
        <span
          className={viewPoint === "top" || viewPointPrev === "top" ? "active" : ""}
          title="Top View"
          onClick={(e) => {
            e.stopPropagation();
            setViewPoint("top");
            setViewPointPrev("top");
          }}
        >
          {getIconFromList("BOTTOM_VIEW_ICON", {
            cursor: "pointer",
            transform: "scale(-1, -1)",
          })}
        </span>
        <span
          className={viewPoint === "bottom" || viewPointPrev === "bottom" ? "active" : ""}
          title="Bottom View"
          onClick={(e) => {
            e.stopPropagation();
            setViewPoint("bottom");
            setViewPointPrev("bottom");
          }}
        >
          {getIconFromList("BOTTOM_VIEW_ICON", { cursor: "pointer" })}
        </span>
        <span
          className={viewPoint === "front" || viewPointPrev === "front" ? "active" : ""}
          title="Front View"
          onClick={(e) => {
            e.stopPropagation();
            setViewPoint("front");
            setViewPointPrev("front");
          }}
        >
          {getIconFromList("FRONT_VIEW_ICON", { cursor: "pointer" })}
        </span>
        <span
          className={viewPoint === "rear" || viewPointPrev === "rear" ? "active" : ""}
          title="Rear View"
          onClick={(e) => {
            e.stopPropagation();
            setViewPoint("rear");
            setViewPointPrev("rear");
          }}
        >
          {getIconFromList("REAR_VIEW_ICON", { cursor: "pointer" })}
        </span>
        <span
          className={viewPoint === "left" || viewPointPrev === "left" ? "active" : ""}
          title="Left View"
          onClick={(e) => {
            e.stopPropagation();
            setViewPoint("left");
            setViewPointPrev("left");
          }}
        >
          {getIconFromList("LEFT_VIEW_ICON", { cursor: "pointer" })}
        </span>
        <span
          className={viewPoint === "right" || viewPointPrev === "right" ? "active" : ""}
          title="Right View"
          onClick={(e) => {
            e.stopPropagation();
            setViewPoint("right");
            setViewPointPrev("right");
          }}
        >
          {getIconFromList("LEFT_VIEW_ICON", {
            cursor: "pointer",
            transform: "scaleX(-1)",
          })}
        </span>
      </div>
    </>
  );
}

export default MeshPlotViewPoint;
