import * as React from "react";
import { Range } from "react-range";

const ReactRangeInput = ({
  step,
  min,
  max,
  selectedFilteredValue,
  setSelectedFilteredValue,
}) => {
  const [values, setValues] = React.useState(
    selectedFilteredValue.length ? selectedFilteredValue : [min, max]
  );

  React.useEffect(() => {
    if (selectedFilteredValue.length > 0) {
      setValues(selectedFilteredValue);
    } else {
      setValues([min, max]);
      setSelectedFilteredValue([min, max]);
    }
  }, [selectedFilteredValue]);

  return (
    <div
      className="__react-range__"
      style={{
        display: "flex",
        justifyContent: "center",
        // flexWrap: "wrap",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        // rtl={rtl}
        onChange={(values) => {
          setValues(values);
          setSelectedFilteredValue(values);
        }}
        renderTrack={({ props, children }) => (
          <>
          <span className="mr-2"><strong>{min}</strong></span>
            <div
              className="__rander-track-mouse-event__"
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                className="__rander-track-props__"
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: "#548BF4",
                  // background: "linear-gradient(90deg, rgb(255 0 0) 20%, rgb(31 255 0) 40%, rgba(4,0,255,1) 40%)",
                  // background: getTrackBackground({
                  //   values,
                  //   colors: ["#ccc", "#548BF4", "#ccc"],
                  //   min: min,
                  //   max: max,
                  //   rtl: false,
                  // }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
            <span className="ml-2"><strong>{max}</strong></span>
          </>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            className="__render-thumb__"
            {...props}
            style={{
              ...props.style,
              height: "26px",
              width: "14px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              className="__render-thumb-abs__"
              style={{
                position: "absolute",
                top: "-20px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: "10px",
                fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                padding: "2px 4px",
                borderRadius: "4px",
                backgroundColor: "#548BF4",
              }}
            >
              {parseFloat(values[index]).toFixed(2)}
            </div>
            <div
              className="__render-thumb-abs-sd-bar__"
              style={{
                height: "16px",
                width: "4px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default ReactRangeInput;
