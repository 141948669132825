import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getDatasetList } from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import request from "../../../utils/request";
import toastr from "toastr";

const MongoDBModal = (props) => {
  const dispatch = useDispatch();
  // DB CONNECTIVITY & SELECTION INFO
  const [mongoDBConnectionHostName, setMongoDBConnectionDataHostName] = useState(null);
  const [mongoDBConnectionDataDBName, setMongoDBConnectionDataDBName] = useState(null);
  const [mongoDBConnectionDataCollection, setMongoDBConnectionDataCollection] = useState(null);
  const [mongoDBConnectionUsername, setMongoDBConnectionDataUsername] = useState(null);
  const [mongoDBConnectionPassword, setMongoDBConnectionDataPassword] = useState(null);

  const submitMongoDBConnectionCredentials = async () => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    try {
      await request
        .post(
          `/api/${props.panelFieldInfo.panel_type}/${props.panelFieldInfo.panel_type_guid}/datasets/`,
          {
            name: props.inputDataName,
            source: "mongo",
            // connection_string: "mongodb://admin:admin001@assettwin-mongo/", //user:password@host
            connection_string: `mongodb://${mongoDBConnectionUsername}:${mongoDBConnectionPassword}@${mongoDBConnectionHostName}/`, //user:password@host
            dbname: mongoDBConnectionDataDBName,
            collection: mongoDBConnectionDataCollection,
          }
        )
        .then((res) => {
          if (res.data) {
            toastr.success("Dataset added successfully", "Success");
            dispatch(
              getDatasetList(
                props.panelFieldInfo.panel_type,
                props.panelFieldInfo.panel_type_guid
              )
            );
            dispatch({
              type: "UPDATE_REQUEST_LOADER_STATE",
              payload: false,
            });
            document
              .getElementById("close-btn-mongodb-connection-modal")
              .click();
            document
              .getElementById(
                "new-dataset-input-modal-close-icon-custom-panel-csv"
              )
              .click();
            props.setInputDataName("");
            // setMongoDBConnectionDataHostName(null);
            // setMongoDBConnectionDataCollection(null);
            // setMongoDBConnectionDataDBName(null);
            // setMongoDBConnectionDataUsername(null);
            // setMongoDBConnectionDataPassword(null);
          }
        });
    } catch (error) {
      // console.log("catch", error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="mongoDBModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal-content-ui modal-content_table"
            style={{ backgroundColor: "#07161f" }}
          >
            <div className="modal-header">
              <h5 className="" id="exampleModalLabel">
                Connect Mongo Database
              </h5>
              <button
                type="button"
                id="close-btn-mongodb-connection-modal"
                className="btn-close transparent-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {getIconFromList("CROSS_ICON")}
              </button>
            </div>
            {/* DB CONNECTION */}
            <React.Fragment>
              <div className="modal-body postgre-form">
                <div className="mb-3">
                  <label htmlFor="hostName" className="form-label">
                    Host Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mongo-connection-hostName"
                    autoComplete="off"
                    placeholder="Host Name"
                    onKeyUp={(e) => {
                      setMongoDBConnectionDataHostName(e.target.value);
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.target.value = e.clipboardData.getData("text");
                    }}
                  />
                </div>
                {/* --------------------------- */}
                <div className="mb-3">
                  <label htmlFor="dbname" className="form-label">
                    Database Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mongo-connection-dbname"
                    autoComplete="off"
                    placeholder="Database name"
                    onKeyUp={(e) => {
                      setMongoDBConnectionDataDBName(e.target.value);
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.target.value = e.clipboardData.getData("text");
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="collection" className="form-label">
                    Collection
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mongo-connection-collection"
                    autoComplete="off"
                    placeholder="Collection"
                    onKeyUp={(e) => {
                      setMongoDBConnectionDataCollection(e.target.value);
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.target.value = e.clipboardData.getData("text");
                    }}
                  />
                </div>
                {/* --------------------------- */}
                <div className="mb-3">
                  <label htmlFor="dbuserName" className="form-label">
                    Username
                  </label>
                  <textarea
                    className="form-control rounded-0 mb-2 form-control-dataname"
                    type="text"
                    // className="form-control"
                    id="mongo-connection-dbuserName"
                    autoComplete="off"
                    placeholder="Username"
                    onKeyUp={(e) => {
                      setMongoDBConnectionDataUsername(e.target.value);
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.target.value = e.clipboardData.getData("text");
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="dbuserPass" className="form-label">
                    Password
                  </label>
                  <textarea
                    className="form-control rounded-0 mb-2 form-control-dataname"
                    type="password"
                    // className="form-control"
                    id="mongo-connection-dbuserPass"
                    autoComplete="off"
                    placeholder="Password"
                    onKeyUp={(e) => {
                      setMongoDBConnectionDataPassword(e.target.value);
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.target.value = e.clipboardData.getData("text");
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="add-data-source btn btn-secondary rounded-0"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    submitMongoDBConnectionCredentials();
                  }}
                >
                  Submit
                </button>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MongoDBModal);
