import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import {
  deleteCustomDashboardPanel,
  updateCustomDashboardPanel,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import { rndPositionDefaultXYWH, rndPositionStyle, rndViewerClassName } from "../../../utils";
import BigDataTable from "./BigDataTable/BigDataTable";

const DataTable = ({ panel }) => {
  const dispatch = useDispatch();
  const panelUserAccessInfo = useSelector((state) => state.customPanelModify.panelUserAccessInfo);
  const activeTabData = useSelector((state) => state.customPanelModify.ACTIVE_TAB_DATA);

  const [isDraggableUnAvailable, setIsDraggableUnAvailable] = useState(false);
  const [reloaderFlag, setReloaderFlag] = useState(true);
  const [zoomCtrl, setZoomCtrl] = useState(false);

  const updatePanelSectionPosition = (panel, x, y, w, h) => {
    panel.panel_config.defaultPosition.x = x;
    panel.panel_config.defaultPosition.y = y;
    panel.panel_config.defaultPosition.width = w;
    panel.panel_config.defaultPosition.height = h;

    panel.panel_config.panelStyleRatio = {
      widthPercent: (w/document.documentElement.clientWidth),
      // heightPercent: (h/document.documentElement.clientHeight),
      heightPercent: h,
      x: (x/document.documentElement.clientWidth),
      y: (y/document.documentElement.clientHeight)
    };
    dispatch(updateCustomDashboardPanel(activeTabData.guid, panel.guid, panel.panel_config));
    setReloaderFlag(false);
    setTimeout(() => {
      setReloaderFlag(true);
    }, 100);
  };

  useEffect(() => {
    setReloaderFlag(false);
    setTimeout(() => {
      setReloaderFlag(true);
    }, 100);
  }, [panel]);

  const minMaxPanelControlHandler = () => {
    let selector = document.getElementById("rnd-section-data-table-" + panel.guid);
    if(selector.classList.contains('__IAM_A_VIEWER__')) {
      minMaxPanelControlHandler_(selector);
    } else {
      minMaxPanelControlHandler_(selector);
    }
    // if (selector.className === "rnd-section-data-table" || selector.className === "rnd-section-data-table react-draggable") {
    //   selector.className = "rnd-section-data-table __maximize__";
    //   selector.closest("body").classList.add("fullscreen__enabled");
    //   setZoomCtrl(true);
    // } else {
    //   selector.className = "rnd-section-data-table react-draggable";
    //   selector.closest("body").classList.remove("fullscreen__enabled");
    //   setZoomCtrl(false);
    // }
  };

  const minMaxPanelControlHandler_ = (selector) => {
    if(selector.classList.contains('__maximize__')) {
      selector.classList.remove("__maximize__");
      selector.closest("body").classList.remove("fullscreen__enabled");
      setZoomCtrl(false);
    } else {
      selector.classList.add("__maximize__");
      selector.closest("body").classList.add("fullscreen__enabled");
      setZoomCtrl(true);
    }
  }


  return (
    <>
      {/* PANEL BODY */}
      <div className="data-table-body-section">
        <Rnd
          className={rndViewerClassName("rnd-section-data-table", panelUserAccessInfo)}
          id={"rnd-section-data-table-" + panel.guid}
          // style={panel.panel_config.defaultStyle}
          // default={panel.panel_config.defaultPosition}
          style={rndPositionStyle(panel.panel_config.defaultStyle)}
          default={rndPositionDefaultXYWH(panel.panel_config)}
          disableDragging={
            panelUserAccessInfo && !panelUserAccessInfo.dashboard.operation
              ? true
              : isDraggableUnAvailable
          }
          onDragStop={(event, dragableData) => {
            event.preventDefault();
            if (
              panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation
            ) {
              updatePanelSectionPosition(
                panel,
                dragableData.x,
                dragableData.y,
                panel.panel_config.defaultPosition.width,
                panel.panel_config.defaultPosition.height
              );
            }
          }}
          onResizeStop={(event, direction, ref, delta, position) => {
            event.preventDefault();
            if (
              panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation
            ) {
              updatePanelSectionPosition(
                panel,
                position.x,
                position.y,
                panel.panel_config.defaultPosition.width + delta.width,
                panel.panel_config.defaultPosition.height + delta.height
              );
            }
          }}
        >
          <div
            className="box-specification __RND_EMD_CHILD_CONTAINER__"
            style={{
              height: panel.panel_config.defaultPosition.height - 2 + "px",
            }}
          >
            <div className="box-specification-header">
              <h4 className="box-specification-title mb-0">
                {panel.panel_config.data
                  ? panel.panel_config.data.dataset_table_name
                    ? panel.panel_config.data.dataset_table_name
                    : panel.panel_config.data.name
                  : "Data Table"}
              </h4>
              <div className="d-flex justify-content-between">
                {panelUserAccessInfo &&
                panelUserAccessInfo.dashboard.operation ? (
                  <>
                    <div
                      className="table-panel-customization-option"
                      onMouseOver={(e) => {
                        e.preventDefault();
                        setIsDraggableUnAvailable(true);
                      }}
                      onMouseLeave={(e) => {
                        e.preventDefault();
                        setIsDraggableUnAvailable(false);
                      }}
                    >
                      <span
                        className="table-panel-edit-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          minMaxPanelControlHandler();
                        }}
                      >
                        {getIconFromList(zoomCtrl ? "ZOOM_OUT" : "ZOOM_IN", {
                          cursor: "pointer",
                        })}
                      </span>
                      <span
                        className="table-panel-edit-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          window.dataTableModalOpen(panel);
                        }}
                      >
                        {getIconFromList("PENCIL_EDIT_ICON", {
                          cursor: "pointer",
                        })}
                      </span>
                      <span
                        className="table-panel-remove-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            deleteCustomDashboardPanel(
                              activeTabData.guid,
                              panel.guid
                            )
                          );
                          document
                            .getElementById(
                              "rnd-section-data-table-" + panel.guid
                            )
                            .remove();
                        }}
                      >
                        {getIconFromList("DELETE", { cursor: "pointer" })}
                      </span>
                    </div>
                  </>
                ) : (
                  <div
                    className="table-panel-customization-option __table_viewer_zoom_in_out__"
                    onMouseOver={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(true);
                    }}
                    onMouseLeave={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(false);
                    }}
                  >
                    <span
                      className="table-panel-edit-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        minMaxPanelControlHandler();
                      }}
                    >
                      {getIconFromList(zoomCtrl ? "ZOOM_OUT" : "ZOOM_IN", {
                        cursor: "pointer",
                      })}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div
              className="box-specification-body p-0"
              style={{
                // width: panel.panel_config.defaultPosition.width - 4 + "px",
                width: "100%",
                height:
                  panel.panel_config.defaultPosition.height - 40 - 4 + "px",
                overflow: "auto",
                display: "block",
              }}
              onMouseOver={(e) => {
                e.preventDefault();
                setIsDraggableUnAvailable(true);
              }}
              onMouseLeave={(e) => {
                e.preventDefault();
                setIsDraggableUnAvailable(false);
              }}
            >
              {reloaderFlag ? <BigDataTable panelInfo={panel} /> : <></>}
            </div>
          </div>
        </Rnd>
      </div>
    </>
  );
};

export default DataTable;
