import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

const ChartLayout_Margin = ({ chartLayout, setChartLayout }) => {
  const [margin, setMargin] = useState({
    l: 0,
    r: 0,
    b: 0,
    t: 20,
    pad: 10,
  });

  useEffect(() => {
    setChartLayout({
      ...chartLayout,
      margin: margin,
    });
  }, [margin]);

  return (
    <>
      <div className="row form__row mt-0 pr-2">
        <div className="col-md-12 pr-2">
          <div className="form__group">
            <label>
              <strong>Margin</strong>
            </label>
          </div>
        </div>
      </div>
      <div className="row form__row mt-0 pr-2">
        <div className="col-md-3 pr-2">
          <div className="form__group">
            <label>Left</label>
          </div>
          <div className="form__group">
            <input
              type="number"
              className="form-control form__control"
              placeholder="0"
              value={margin.l.toString()}
              onChange={(e) => {
                e.stopPropagation();
                setMargin({ ...margin, l: e.target.value === "" ? 0 : parseInt(e.target.value) });
              }}
            />
          </div>
        </div>
        <div className="col-md-3 pr-2 pl-1">
          <div className="form__group">
            <label>Top</label>
          </div>
          <div className="form__group">
            <input
              type="number"
              className="form-control form__control"
              placeholder="0"
              value={margin.t.toString()}
              onChange={(e) => {
                e.stopPropagation();
                setMargin({ ...margin, t: e.target.value === "" ? 0 : parseInt(e.target.value) });
              }}
            />
          </div>
        </div>
        <div className="col-md-3 pr-2 pl-1">
          <div className="form__group">
            <label>Right</label>
          </div>
          <div className="form__group">
            <input
              type="number"
              className="form-control form__control"
              placeholder="0"
              value={margin.r.toString()}
              onChange={(e) => {
                e.stopPropagation();
                setMargin({ ...margin, r: e.target.value === "" ? 0 : parseInt(e.target.value) });
              }}
            />
          </div>
        </div>
        <div className="col-md-3 pr-2 pl-1">
          <div className="form__group">
            <label>Bottom</label>
          </div>
          <div className="form__group">
            <input
              type="number"
              className="form-control form__control"
              placeholder="0"
              value={margin.b.toString()}
              onChange={(e) => {
                e.stopPropagation();
                setMargin({ ...margin, b: e.target.value === "" ? 0 : parseInt(e.target.value) });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartLayout_Margin;
