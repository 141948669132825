import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssetBasics } from "../../../../../actions/panelActions";
import Loader from "../../Loader";

const AssetBasics = ({  projectguid }) => {
  const assetBasics = useSelector((state) => state.panel.metaData.assetBasics);
  const dispatch = useDispatch();

  // if (!assetBasics) {
  //   setTimeout(() => {
  //     dispatch(fetchAssetBasics(projectguid));
  //   }, 2000);
  // }

  useEffect(() => {
    dispatch(fetchAssetBasics(projectguid));
  }, [projectguid]);

  return (
    <div className="card-secondary-gradient">
      {/* <div className="card-header">
        <h4 className="card-title">ASSET BASIC</h4>
      </div> */}
      {assetBasics ? (
        <div className="card-body">
          <div className="table-responsive">
            <table className="table rwd-table table-metadata">
              <thead>
                <tr>
                  <th scope="col">Asset Name</th>
                  <th scope="col">Asset Type</th>
                  <th scope="col">Asset Tag</th>
                  <th scope="col">Asset Category</th>
                  <th scope="col">Latitude</th>
                  <th scope="col">Longitude</th>
                  <th scope="col">Altitude</th>
                </tr>
              </thead>
              <tbody>
                {assetBasics && assetBasics.data === null ? (
                  <p>No data available for asset basics</p>
                ) : (
                  assetBasics.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.type}</td>
                      <td>{item.guid}</td>
                      <td>{item.category}</td>
                      <td>{item.location.latitude}</td>
                      <td>{item.location.longitude}</td>
                      <td>{item.location.altitude}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="custom__loader--container">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default AssetBasics;
