const defStyle = {
  defaultStyle: {
    alignItems: "center",
    justifyContent: "center",
    background: "#08202E",
    borderRadius: "4px",
  },
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 250,
    // height: randomElementPosition.indicators.height,
  },
};

// const default_indicator = {
//   panelType: "INDICATOR",
//   defaultData: { id: new Date().getTime(), data_type: "DEFAULT" },
//   data: null,
//   defaultStyle: defStyle.defaultStyle,
//   defaultPosition: data.position,
// };
// const default_table = {
//   panelType: "DATA_TABLE",
//   defaultData: { id: new Date().getTime(), data_type: "DEFAULT" },
//   data: null,
//   defaultStyle: defStyle.defaultStyle,
//   defaultPosition: data.position,
// };
// const default_map = {
//   panelType: "MAP",
//   defaultData: { id: new Date().getTime(), data_type: "DEFAULT" },
//   data: null,
//   defaultStyle: defStyle.defaultStyle,
//   defaultPosition: data.position,
// };
// const default_specification = {
//   panelType: "SPECIFICATION",
//   defaultData: { id: new Date().getTime(), data_type: "DEFAULT" },
//   data: null,
//   defaultStyle: defStyle.defaultStyle,
//   defaultPosition: data.position,
// };
// const default_chart = {
//   panelType: "CHART",
//   defaultData: data.defaultData,
//   data: null,
//   defaultStyle: defStyle.defaultStyle,
//   defaultPosition: data.position,
// };
// const default_image = {
//   panelType: "IMAGE",
//   defaultData: data.defaultData,
//   data: null,
//   defaultStyle: defStyle.defaultStyle,
//   defaultPosition: data.position,
// };
// const default_document = {
//   panelType: "DOCUMENT",
//   defaultData: data.defaultData,
//   data: null,
//   defaultStyle: defStyle.defaultStyle,
//   defaultPosition: data.position,
// };

const text_indicator = {
  panelType: "TEXT INDICATOR",
  defaultData: { id: new Date().getTime(), type: "TEXT INDICATOR" },
  data: {
    topHeaderName: "",
    value: "",
    style: {
      background: "#08202E",
      topHeader: "#FFFFFF",
      topHeader_fontSize: "2rem",
      value: "#FFFFFF",
      value_fontSize: "3rem",
      value_decimalPoint: "0.##0",
    },
  },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 250,
    height: 120,
  },
};
const indicator = {
  panelType: "INDICATOR",
  defaultData: { id: new Date().getTime() },
  data: {
    bottomHeaderName: "",
    topHeaderName: "",
    unit: "",
    value: null,
    style: {
      background: "#08202E",
      topHeader: "#FFFFFF",
      topHeader_fontSize: "2rem",
      value: "#FFFFFF",
      value_fontSize: "3rem",
      value_decimalPoint: "0.##0",
      unit: "#FFFFFF",
      unit_fontSize: "1rem",
      bottomHeader: "#FFFFFF",
      bottomHeader_fontSize: "2rem",
    },
  },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 250,
    height: 120,
  },
};
const data_table = {
  panelType: "DATA_TABLE",
  defaultData: { id: new Date().getTime() },
  data: null,
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 350,
    height: 320,
  },
};
const map = {
  panelType: "MAP",
  defaultData: { id: new Date().getTime() },
  data: {
    panelTitle: null,
    panelDatasetSource: null,
    panelColumn: null,
  },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 300,
    height: 300,
  },
};
const specification = {
  panelType: "SPECIFICATION",
  defaultData: { id: new Date().getTime() },
  data: {
    panelTitle: null,
    panelDatasetSource: null,
    panelColumn: null,
  },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 300,
    height: 300,
  },
};
const line_chart = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "line" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 475,
    height: 220,
  },
};
const line_chart_3d = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "3d_line" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 475,
    height: 220,
  },
};
const bar_chart = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "bar" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 475,
    height: 220,
  },
};
const pie_chart = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "pie" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 275,
    height: 220,
  },
};
const mesh_chart = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "mesh" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 275,
    height: 220,
  },
};
const profile_group = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "profile_group" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 275,
    height: 220,
  },
};
const mapbox_density_heatmap = {
  data: null,
  panelType: "CHART",
  defaultData: {
    id: new Date().getTime(),
    chart_type: "mapbox_density_heatmap",
  },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 275,
    height: 220,
  },
};
const stacked_bar_chart = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "stacked_bar_chart" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 475,
    height: 220,
  },
};
const stacked_column_chart = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "stacked_column_chart" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 475,
    height: 220,
  },
};
const gague_chart = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "gague_chart" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 475,
    height: 220,
  },
};
const donut_chart = {
  data: null,
  panelType: "CHART",
  defaultData: { id: new Date().getTime(), chart_type: "donut_chart" },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 475,
    height: 220,
  },
};
const image = {
  panelType: "IMAGE",
  defaultData: { id: new Date().getTime() },
  data: {
    panelTitle: null,
    panelDatasetSource: null,
    panelColumn: null,
  },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 300,
    height: 300,
  },
};
const document = {
  panelType: "DOCUMENT",
  defaultData: { id: new Date().getTime() },
  data: {
    panelTitle: null,
    panelDatasetSource: null,
    panelColumn: null,
  },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 300,
    height: 300,
  },
};
const single_image = {
  panelType: "SINGLE_IMAGE",
  defaultData: { id: new Date().getTime() },
  data: null,
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.5,
    y: window.innerHeight / 2.5,
    width: 300,
    height: 300,
  },
};

const bubble_map = {
  data: null,
  panelType: "CHART",
  defaultData: {
    id: new Date().getTime(),
    chart_type: "bubble_map",
  },
  defaultStyle: defStyle.defaultStyle,
  defaultPosition: {
    x: window.innerWidth / 2.8,
    y: window.innerHeight / 2.8,
    width: 275,
    height: 220,
  },
};

export const getPanelData = (panelType) => {
  // // console.log(panelType);
  switch (panelType) {
    case "bubble_map":
      return bubble_map;
    case "text_indicator":
      return text_indicator;
    case "indicator":
      return indicator;
    case "data_table":
      return data_table;
    case "map":
      return map;
    case "specification":
      return specification;
    case "line_chart":
      return line_chart;
    case "line_chart_3d":
      return line_chart_3d;
    case "bar_chart":
      return bar_chart;
    case "pie_chart":
      return pie_chart;
    case "mesh_chart":
      return mesh_chart;
    case "profile_group":
      return profile_group;
    case "mapbox_density_heatmap":
      return mapbox_density_heatmap;
    case "stacked_bar_chart":
      return stacked_bar_chart;
    case "stacked_column_chart":
      return stacked_column_chart;
    case "gague_chart":
      return gague_chart;
    case "donut_chart":
      return donut_chart;
    case "image":
      return image;
    case "document":
      return document;
    case "single_image":
      return single_image;
    default:
      break;
  }
};
