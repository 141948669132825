import React, { useEffect, useState } from "react";
import TopHeader from "../../components/TopHeader";
import ContainerFluid from "../../components/ContainerFluid";
import CopyRightFooter from "../../components/CopyRightFooter";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Verify = () => {
  const [activationError, setActivationError] = useState(false);
  const [activating, setActivating] = useState(true);
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      await axios
        .get(
          window.apiBaseURL +
            "/api/auth/verify-email/?token=" +
            query.get("token")
        )
        .then(({ data }) => {
          toastr[data.toast_msg.type](
            data.toast_msg.message,
            data.toast_msg.title
          );
          setActivating(false);
          navigate("/", { replace: true });
        })
        .catch((err) => {
          setActivationError(true);
          toastr.error("Email Activation Failed");
        });
    }
    fetchData();
  }, [navigate, query]);

  return (
    <ContainerFluid>
      <TopHeader />
      {activating && (
        <div className="email__verification--content">
          <img
            className="img__loader"
            src={require("../../static/loader.gif")}
            alt=""
          />
          {activationError !== null ? (
            <h3>Hands up, Token Expired</h3>
          ) : (
            <h3>Hold on, Activating Your Account</h3>
          )}
        </div>
      )}
      <CopyRightFooter />
    </ContainerFluid>
  );
};

export default Verify;
