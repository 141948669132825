import React, { useState } from "react";
import axios from "axios";
import toastr from "toastr";

const ForgotPassword = ({ switchComponent }) => {
  const [email, setEmail] = useState("");
  return (
    <div id="forgot__password">
      <form
        className="form_custom corner-button mt-5 mb-5"
        id="forgot_pass_form"
        onSubmit={async (e) => {
          e.preventDefault();
          const payload = {
            email,
          };
          await axios
            .post(
              process.env.REACT_APP_REST_HOST +
                "/api/auth/request-reset-email/",
              payload
            )
            .then((resp) => {
              toastr.success(resp.data.success);
            })
            .catch((err) => {
              toastr.error(err.response.data.error);
            });
        }}
      >
        <div className="bottom_border">
          <div>
            <h4 className="header_custom header_corner-button text-uppercase">
              Forgot your password !
            </h4>
          </div>
          <div className="form-group_custom mb-3 mt-4">
            <label
              htmlFor="forgotInputEmail1"
              className="form-label text-uppercase fs-6"
            >
              Email address
            </label>
            <input
              type="email"
              name="forgot_email"
              id="forgot_email"
              className="form-control form-control_custom"
              placeholder="Email"
              aria-describedby="emailHelp"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
          </div>
          <div className="btn_sign_section d-flex justify-content-between align-items-center mt-4">
            <button type="submit" className="btn button_corner text-uppercase">
              Send
            </button>
            <a
              className="anchor_text text-uppercase float-end mt-0"
              href="#!"
              onClick={() => switchComponent("SIGININ")}
            >
              log in
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
