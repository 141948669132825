import React from "react";

const ProfileInfoCard = () => {
  return (
    <div className="user__panel--col user__panel--info col-md-4">
      <div className="card card-body user__panel--card">
        <div className="user__panel--avater">
          <img
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
            alt="User Name"
          />
        </div>
        <div className="user__panel--info text-center">
          <h4 className="user-panel-name">Jone Doe</h4>
          <p className="user-panel-deg">Front end Developer</p>
        </div>
        <ul className="user__panel-list">
          <li>Total Project(s): 50</li>
          <li>Total Solutions(s): 50</li>
          <li>Shared Project(s): 20</li>
        </ul>
        <ul className="user__panel-list">
          <li>Subscription Type: Premium</li>
          <li>Used Space: 50GB</li>
          <li>Free Space: 10GB</li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileInfoCard
