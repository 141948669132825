import React, { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import request from "../../../../../utils/request";
import { response } from "./def_data";
import { generateProfileGroupLayout } from "./layout.default";
import toastr from "toastr";
import { getIconFromList } from "../../../../../static/IconServe";
import { useSelector } from "react-redux";
import { colors } from "./colors";

// const colors = colors;

const sh_ = [
  { x0: 0, x1: 21, annotation: 10 },
  { x0: 22, x1: 96, annotation: 1 },
  { x0: 97, x1: 229, annotation: 2 },
  { x0: 230, x1: 334, annotation: 6 },
  { x0: 335, x1: 1199, annotation: 21 },
  { x0: 1200, x1: 1460, annotation: 4 },
  { x0: 1461, x1: 1536, annotation: 1 },
  { x0: 1537, x1: 1653, annotation: 5 },
  { x0: 1654, x1: 1654, annotation: 11 },
  { x0: 1655, x1: 1747, annotation: 7 },
  { x0: 1748, x1: 1748, annotation: 12 },
  { x0: 1749, x1: 1823, annotation: 1 },
  { x0: 1824, x1: 2016, annotation: 3 },
  { x0: 2017, x1: 2046, annotation: 13 },
  { x0: 2047, x1: 2601, annotation: 3 },
  { x0: 2602, x1: 2721, annotation: 1 },
  { x0: 2722, x1: 2935, annotation: 6 },
  { x0: 2936, x1: 3174, annotation: 3 },
  { x0: 3175, x1: 3290, annotation: 1 },
  { x0: 3291, x1: 3505, annotation: 6 },
  { x0: 3506, x1: 4019, annotation: 3 },
  { x0: 4020, x1: 4127, annotation: 1 },
  { x0: 4128, x1: 4252, annotation: 20 },
  { x0: 4253, x1: 4253, annotation: 14 },
  { x0: 4254, x1: 4338, annotation: 8 },
  { x0: 4339, x1: 4339, annotation: 15 },
  { x0: 4340, x1: 4415, annotation: 1 },
  { x0: 4416, x1: 4610, annotation: 3 },
  { x0: 4611, x1: 4640, annotation: 16 },
  { x0: 4641, x1: 5195, annotation: 3 },
  { x0: 5196, x1: 5308, annotation: 1 },
  { x0: 5309, x1: 5530, annotation: 6 },
  { x0: 5531, x1: 5743, annotation: 3 },
  { x0: 5744, x1: 5855, annotation: 1 },
  { x0: 5856, x1: 6068, annotation: 6 },
  { x0: 6069, x1: 6144, annotation: 1 },
  { x0: 6145, x1: 6307, annotation: 22 },
  { x0: 6308, x1: 6389, annotation: 23 },
  { x0: 6390, x1: 6612, annotation: 3 },
  { x0: 6613, x1: 6720, annotation: 1 },
  { x0: 6721, x1: 6721, annotation: 17 },
  { x0: 6722, x1: 6899, annotation: 9 },
  { x0: 6900, x1: 6900, annotation: 18 },
  { x0: 6901, x1: 6985, annotation: 1 },
  { x0: 6986, x1: 6999, annotation: 19 },
];

const ProfileGroup = ({ panel, zoomCtrl }) => {
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const [profileGroupData, setProfileGroupData] = useState(null);
  const [layout, setLayout] = useState({
    autosize: true,
    margin: {
      l: 50,
      r: 30,
      b: 10,
      t: 20,
      pad: 10,
    },
    paper_bgcolor: "lightblue",
    plot_bgcolor: "#ddd",
    barmode: "group",
    modebar: {
      orientation: "v",
      margin: {
        t: 50,
      },
    },
    modeBarButtonsToRemove: ["downloadImage"],
    legend: {
      x: 1,
      y: 1.3,
      xanchor: "right",
      orientation: "h",
      font: {
        family: "Arial, sans-serif",
        size: 10,
        color: "white",
      },
    },
    xaxis: {
      automargin: true,
      zerolinewidth: 0,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      tickcolor: "#B9B8CE",
      tickangle: "auto",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        standoff: 10,
        text: "X",
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
      linecolor: "#00FFFB",
      linewidth: 2,
    },
    yaxis: {
      automargin: true,
      zerolinewidth: 0,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      tickcolor: "#B9B8CE",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        text: "Y",
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
      linecolor: "#00FFFB",
      linewidth: 2,
    },
    // shapes: [
    //   {
    //     type: "rect",
    //     xref: "x",
    //     yref: "y",
    //     x0: 0,
    //     y0: -725,
    //     x1: 500,
    //     y1: -755,
    //     fillcolor: "#FFDD40",
    //     opacity: 0.3,
    //     line: {
    //       width: 0,
    //     },
    //   },
    //   {
    //     type: "rect",
    //     xref: "x",
    //     yref: "y",
    //     x0: 500,
    //     y0: -725,
    //     x1: 1000,
    //     y1: -755,
    //     fillcolor: "#FF3C41",
    //     opacity: 0.3,
    //     line: {
    //       width: 0,
    //     },
    //   },
    // ],
    // annotations: [],
  });
  const [sliderLvlVal, setSliderLvlVal] = useState({ start: 1, end: 1 });
  const [sliderVal, setSliderVal] = useState(1);
  const [dataLimit, setDataLimit] = useState(5000);
  const [chartPage, setChartPage] = useState(1);
  const [chartPageFlag, setChartPageFlag] = useState(false);
  const [isLoaderOn, setIsLoaderOn] = useState(true);

  useEffect(() => {
    setIsLoaderOn(true);

    if (!zoomCtrl) {
      setProfileGroupData(null);
    }

    if (panel.panel_config.data) {
      createChartData(chartPage);
    } else {
      let res = Object.keys(response.data);
      let chart = [];
      // for (let i = 0; i < res.length; i++) {
      for (let i = 0; i < 1; i++) {
        let trace = {
          x: Object.values(response.data)[i].x,
          y: Object.values(response.data)[i].y,
          mode: "line",
          name: "temperature",
        };
        chart.push(trace);
      }
      setProfileGroupData(chart);

      // let gr = response.group_by
      let gr = sh_;
      let shapes_arr = [];
      let annotations = [];
      for (let i = 0; i < gr.length; i++) {
        let shape_ = {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: gr[i].x0,
          y0: -725,
          x1: gr[i].x1,
          y1: -755,
          text: "Annotation Text",
          fillcolor: colors[i],
          opacity: 0.5,
          line: {
            width: 0,
          },
        };
        shapes_arr.push(shape_);

        let annotation = {
          x: (gr[i].x0 + gr[i].x1) / 2,
          y: gr[i].y1,
          xref: "x",
          yref: "y",
          text: "tedt",
          showarrow: true,
          arrowhead: 7,
          ax: 0,
          ay: -40,
        };
        annotations.push(annotation);
      }
      setLayout({ ...layout, shapes: shapes_arr });
      // setLayout({ ...layout, shapes: shapes_arr, annotations: annotations });
      console.log(layout);
      setIsLoaderOn(false);
    }
  }, [panel, zoomCtrl]);

  // PAGINATION RECALL
  useEffect(() => {
    if (chartPageFlag) {
      setChartPageFlag(false);
      createChartData(chartPage);
    }
    return () => {};
  }, [chartPageFlag]);

  // const colorArray = Array.from({ length: 100 }, () => {
  //   const color = Math.floor(Math.random() * 16777215).toString(16);
  //   return `#${color}`;
  // });

  // console.log(colorArray);

  const createChartData = async (pageNo) => {
    console.log("here===============================");
    console.log(panel);

    let series = [];
    for (
      let i = 0;
      i < panel.panel_config.bar_data.y_axis_categories.length;
      i++
    ) {
      series.push({
        name: panel.panel_config.bar_data.y_axis_categories[i].name,
        type: panel.panel_config.bar_data.y_axis_categories[i].type,
        column: panel.panel_config.bar_data.y_axis_categories[i].group_by,
      });
    }

    let payload = {
      dataset_type: panelFieldInfo.panel_type,
      axis_config: {
        x: panel.panel_config.bar_data.bar_group_variable
          ? panel.panel_config.bar_data.bar_group_variable
          : {
              col_name: "index",
              col_type: "int",
            },
        // panel.panel_config.bar_data.x_axis_category,
        y: series,
      },
      group_by: panel.panel_config.bar_data.z_axis_categories[0].group_by,
      operation: panel.panel_config.bar_data.bar_group_operation,
      filtering:
        panel.panel_config.bar_data.selected_filter_column_name &&
        panel.panel_config.bar_data.selected_filter_col_value.length > 0
          ? {
              col_name:
                panel.panel_config.bar_data.selected_filter_column_name
                  .col_name,
              value: panel.panel_config.bar_data.selected_filter_col_value,
            }
          : null,
      sorting: panel.panel_config.bar_data.sort_by
        ? {
            col_name: panel.panel_config.bar_data.sort_by,
            asc: panel.panel_config.bar_data.sort_type === "asc" ? true : false,
          }
        : null,
    };

    try {
      console.log("try");
      let { data } = await request.post(
        `/api/profile/chart/${
          panel.panel_config.data.guid
        }/stats/?page=${pageNo}&limit=${
          panel.panel_config.bar_data.data_points
            ? panel.panel_config.bar_data.data_points
            : dataLimit
        }`,
        payload
      );
      console.log(data);

      setSliderLvlVal({
        start: 1,
        end: data.total_pages,
      });

      let res = Object.keys(data.data);
      let chart = [];
      let annotations = [];
      for (let i = 0; i < res.length; i++) {
        let trace = {
          x: Object.values(data.data)[i].x,
          y: Object.values(data.data)[i].y,
          mode: "line",
          // name: "temperature",
          orientation: "v",
          textposition: "auto",
          line: {
            color: "#00FFFB",
            width: 1.5,
          },
        };
        chart.push(trace);
      }
      setProfileGroupData(chart);
      // console.log(profileGroupData);

      // LAYOUT - SHAPE & ANNOTATIONS
      let gr = data.group;
      let shapes_arr = [];

      for (let i = 0; i < gr.length; i++) {
        // SHAPE
        let shape__ = {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: gr[i].x0,
          y0: gr[i].y0,
          x1: gr[i].x1,
          y1: gr[i].y1,
          fillcolor: gr[i].color,
          opacity: 0.3,
          line: {
            width: 0,
          },
        };
        shapes_arr.push(shape__);
        // console.log(shapes_arr);

        // ANNOTATION
        let annotation = {
          x: getXFromPayload(gr, i, payload),
          y: gr[i].y1,
          xref: "x",
          yref: "y",
          text: gr[i].annotation,
          textangle: -90,
          font: {
            family: "Courier New, monospace",
            size: 10,
            color: "#FFFFFF",
          },
          showarrow: true,
          arrowhead: 2,
          arrowsize: 1,
          arrowwidth: 1,
          arrowcolor: "#FFFFFF",
          ax: 0,
          ay: -40,
        };
        annotations.push(annotation);
      }

      setLayout({
        ...layout,
        shapes: shapes_arr,
        xaxis: {
          ...layout.xaxis,
          title: {
            ...layout.xaxis.title,
            text: panel.panel_config.bar_data.x_axis_label,
          },
        },
        yaxis: {
          ...layout.yaxis,
          title: {
            ...layout.yaxis.title,
            text: panel.panel_config.bar_data.y_axis_label,
          },
        },
        annotations: annotations,
      });

      console.log(profileGroupData);
      console.log(layout);

      setIsLoaderOn(false);
    } catch (error) {
      console.log("catch");
      toastr.warning(
        "Incorrect chart parameters, please try again.",
        "Wrong parameters"
      );
    }
  };

  const getXFromPayload = (gr, i, payload) => {
    if(payload.axis_config.x.col_type === "datetime") {
      return getDateAvg(gr[i].x0, gr[i].x1)
    } else if(payload.axis_config.x.col_type === "index" || payload.axis_config.x.col_type !== "datetime") {
      return (gr[i].x0 + gr[i].x1) / 2
    } else {
      return (gr[i].x0 + gr[i].x1) / 2
    }
  };

  const getDateAvg = (date1, date2) => {
    const date1Milliseconds = new Date(date1).getTime();
    const date2Milliseconds = new Date(date2).getTime();
    const difference = Math.abs(date2Milliseconds - date1Milliseconds);
    const midpointMilliseconds = date1Milliseconds + difference / 2;
    const averageDate = new Date(midpointMilliseconds);

    return averageDate;
  };

  return (
    <React.Fragment>
      {profileGroupData && (
        <>
          <Plot
            data={profileGroupData}
            layout={layout}
            useResizeHandler={true}
            config={{ displaylogo: false }}
            className="wh-100 __profile_group__"
          />

          {sliderLvlVal.end > 1 && (
            <div className="plot__chartdata_value--block chart-input-range">
              <div className="plot__chartdata_value--flex">
                <span>{sliderLvlVal.start}</span>
                <span>{sliderVal}</span>
                <span>{sliderLvlVal.end}</span>
              </div>
              <div className="d-flex align-items-center justify-content-around">
                <span
                  style={{ display: "inline-block", width: "5%" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (sliderVal > sliderLvlVal.start) {
                      setSliderVal(parseInt(sliderVal) - 1);
                      setChartPage(parseInt(sliderVal) - 1);
                      setChartPageFlag(true);
                    }
                  }}
                >
                  {getIconFromList("ARROW_RIGHT_ICON_PAGINATION", {
                    cursor: "pointer",
                    transform: "rotate(180deg)",
                  })}
                </span>
                <input
                  type="range"
                  className="form-range"
                  min={sliderLvlVal.start}
                  max={sliderLvlVal.end}
                  step="1"
                  value={sliderVal}
                  style={{ width: "85%" }}
                  onChange={(e) => {
                    e.stopPropagation();
                    setIsLoaderOn(true);
                    setSliderVal(e.target.value);
                    setChartPage(e.target.value);
                    setChartPageFlag(true);
                  }}
                ></input>
                <span
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    width: "5%",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (sliderVal < sliderLvlVal.end) {
                      setSliderVal(parseInt(sliderVal) + 1);
                      setChartPage(parseInt(sliderVal) + 1);
                      setChartPageFlag(true);
                    }
                  }}
                >
                  {getIconFromList("ARROW_RIGHT_ICON_PAGINATION", {
                    cursor: "pointer",
                  })}
                </span>
              </div>
            </div>
          )}
        </>
      )}
      {isLoaderOn && <div className="chart-loader"></div>}
    </React.Fragment>
  );
};

export default ProfileGroup;
