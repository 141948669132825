import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssetSpecs } from "../../../../../actions/panelActions";
import Loader from "../../Loader";

const AssetSpecs = ({ projectguid }) => {
  const assetSpecifications = useSelector(
    (state) => state.panel.metaData.assetSpecifications
  );
  const dispatch = useDispatch();

  // if (!assetSpecifications) {
  //   setTimeout(() => {
  //     dispatch(fetchAssetSpecs(projectguid));
  //   }, 2000);
  // }

  useEffect(() => {
    dispatch(fetchAssetSpecs(projectguid));
  }, [projectguid]);

  return (
    <div className="card-secondary-gradient">
      {/* <div className="card-header">
        <h4 className="card-title">ASSET SPECIFICATIONS</h4>
      </div> */}

      {assetSpecifications && assetSpecifications.Headers ? (
        <div className="card-body">
          <div className="table-responsive">
            <table className="table rwd-table table-metadata">
              <thead>
                <tr>
                  {assetSpecifications.Headers.map((item) => (
                    <th key={item}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {assetSpecifications.data.map((data, index) => (
                  <tr key={index}>
                    {Object.keys(data).map((key) => (
                      <td key={key}>{data[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="custom__loader--container">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default AssetSpecs;
