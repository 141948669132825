const initialState = {
  // fieldCustomPanels: {
  //   panels: [],
  // },
  // isCustomPanelAvailable: false,
  reloadDatasetListStat: false,
  datasetList: [],
  panelFieldInfo: null,
  panelUserAccessInfo: null,
  datasetUploaderAnimationFlag: false,
  createDefaultDashboard: false,
  createDefaultDashboardPanels: false,
  CUSTOM_DASHBOARD_DATA__TAB_LIST: [],
  ACTIVE_TAB_DATA: null,
  ACTIVE_TAB_PANEL_LIST: [],
  // ACTIVE_TAB_PANEL_DATA: null,
  selectedDatasetColumn: [],
  modals: {
    indicator: {
      panel: null,
    },
    textIndicator: false,
    style: false,
  },
  // REQUEST LOADER
  requestLoader: false,
  isWebglDashboard: false,
};

export default function customPanel(state = initialState, action) {
  switch (action.type) {
    case "SET_CUSTOM_PANEL_AS_INITIAL_STATE":
      return initialState;
    case "RELOAD_DATASET_LIST_STAT":
      return { ...state, reloadDatasetListStat: action.payload };
    case "SET_PANEL_DATASET_LIST":
      return { ...state, datasetList: action.payload };
    case "SET_PANEL_FIELD_INFO":
      return { ...state, panelFieldInfo: action.payload };
    case "SET_PANEL_USER_ACCESS_INFO":
      return { ...state, panelUserAccessInfo: action.payload };
    case "SET_DATASET_LOADER_ANIMATION_FLAG_STAT":
      return { ...state, datasetUploaderAnimationFlag: action.payload };
    case "CREATE_DEFAULT_DASHBOARD_FLAG":
      return { ...state, createDefaultDashboard: action.payload };
    case "CREATE_DEFAULT_DASHBOARD_PANELS_FLAG":
      return { ...state, createDefaultDashboardPanels: action.payload };
    case "SET_CUSTOM_DASHBOARD_DATA__TAB_LIST":
      return { ...state, CUSTOM_DASHBOARD_DATA__TAB_LIST: action.payload };
    case "ADD_NEW_TAB_TO_CUSTOM_DASHBOARD_DATA__TAB_LIST":
      return {
        ...state,
        CUSTOM_DASHBOARD_DATA__TAB_LIST: [
          ...state.CUSTOM_DASHBOARD_DATA__TAB_LIST,
          action.payload,
        ],
      };
    case "UPDATE_SINGLE_TAB_CUSTOM_DASHBOARD_DATA__TAB_LIST":
      const index_tab = state.CUSTOM_DASHBOARD_DATA__TAB_LIST.findIndex((tab) => tab.guid === action.payload.guid);
      return {
        ...state,
        CUSTOM_DASHBOARD_DATA__TAB_LIST: [
          ...state.CUSTOM_DASHBOARD_DATA__TAB_LIST.slice(0, index_tab),
          action.payload,
          ...state.CUSTOM_DASHBOARD_DATA__TAB_LIST.slice(index_tab + 1)
        ],
      };
    case "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_DATA":
      return { ...state, ACTIVE_TAB_DATA: action.payload };
    case "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST":
      return { ...state, ACTIVE_TAB_PANEL_LIST: action.payload };
    case "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST__PUSH_NEW_PANEL":
      return {
        ...state,
        ACTIVE_TAB_PANEL_LIST: [...state.ACTIVE_TAB_PANEL_LIST, action.payload],
      };
    case "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST__UPDATE_PANEL":
      const index = state.ACTIVE_TAB_PANEL_LIST.findIndex(
        (panel) => panel.guid === action.payload.guid
      );
      return {
        ...state,
        ACTIVE_TAB_PANEL_LIST: [
          ...state.ACTIVE_TAB_PANEL_LIST.slice(0, index), // everything before current post
          action.payload,
          // {
          //    ...state.ACTIVE_TAB_PANEL_LIST[index],
          //    status: action.type === 'PUBLISH_POST' ? 1 : 0,
          // },
          ...state.ACTIVE_TAB_PANEL_LIST.slice(index + 1), // everything after current post
        ],
      };
    case "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST__REMOVE_PANEL":
      const index_del = state.ACTIVE_TAB_PANEL_LIST.findIndex(
        (panel) => panel.guid === action.payload
      );
      // // console.log(index_del);
      let data = state.ACTIVE_TAB_PANEL_LIST.filter(
        (panel) => panel.guid !== action.payload
      );
      // // console.log(data);
    // return {
    //   ...state,
    //   ACTIVE_TAB_PANEL_LIST: data
    // }
    // return {
    //   ...state,
    //   ACTIVE_TAB_PANEL_LIST: [
    //     ...state.ACTIVE_TAB_PANEL_LIST.slice(0, index_del), // everything before current post
    //     // action.payload,
    //     // {
    //     //    ...state.ACTIVE_TAB_PANEL_LIST[index],
    //     //    status: action.type === 'PUBLISH_POST' ? 1 : 0,
    //     // },
    //     ...state.ACTIVE_TAB_PANEL_LIST.slice(index_del + 1), // everything after current post
    //   ],
    // };

    case "UPDATE_MODAL_STATUS__INDICATOR":
      return {
        ...state,
        modals: {
          ...state.modals,
          indicator: {
            panel: action.payload.panel,
          },
        },
      };
    case "UPDATE_SELECTED_DATASET_COLUMN_LIST":
      return {
        ...state,
        selectedDatasetColumn: action.payload,
      };
    case "UPDATE_REQUEST_LOADER_STATE":
      return {
        ...state,
        requestLoader: action.payload,
      };
    case "UPDATE_WEBGL_DASHBOARD_FLAG_STATUS":
      return { ...state, isWebglDashboard: action.payload };
    default:
      return state;
  }
}
