import React from "react";
import { Link } from "react-router-dom";
import EnergyMetaLogo from "../../static/img/EnergyMeta_logo.png";

const AppInfo = () => {
  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
      <div className="row">
        <div className="col-md-6 my-auto">
          <div className="pl-3 pr-3 pb-3 text-center logo_asset_twin">
            <img width="210px" src={EnergyMetaLogo} alt="EnergyMeta Logo" />
            {/* <p className="font_audiowide m-0">
              <span className="fsize_28 brand_color">
                Asset Twin<sup>®</sup>
              </span>
            </p> */}
          </div>
          <div className>
            <h3 className="mb-5 border-bottom_secondary pb-2">
              {/* Asset Digital Twin Creator */}
              Energy Metaverse Creator
            </h3>

            <p className="mb-4 mt-2 text-justify fsize_15">
              EnergyMeta helps users create digital replicas of offshore
              wind-solar-oil-gas infrastructure assets in 2D/3D in GIS space.
              The fully automated tool helps develop multiple offshore energy
              field concepts to facilitate energy transition or tackle climate
              change. Shows metadata related to the offshore assets.
            </p>
            <p className="mb-4 text-justify fsize_15">
              Our system links multiple users, their data and operations into
              one integrated system for enhanced collaboration and unrivalled
              efficiencies across the entire asset life from concept to
              completion. For custom digital twin service please contact our US
              headquarter.
            </p>
            <div className="mt-3">
              <p>
                <strong>Contact</strong>
              </p>
              <p className="mb-0">
                Email at{" "}
                <a className="text-white" href="mailto:assettwin@idare.io">
                  assettwin@idare.io
                </a>
              </p>
              <p>
                Ph:
                <a className="text-white" href="tel:+17138846390">
                  {" "}
                  + 1 713 884 6390
                </a>
              </p>
              <p>
                Address:
                <br />
                <a
                  className="text-white"
                  href="https://www.google.com/maps/place/13501+Katy+Fwy+%231534,+Houston,+TX+77079,+USA/@29.7811534,-95.6288447,17z/data=!3m1!4b1!4m5!3m4!1s0x8640dbeb1bcc6349:0x20f3b061cecdcf6b!8m2!3d29.7811534!4d-95.626656"
                >
                  11251 Northwest Frwy, Suite#310,
                  <br />
                  Houston, TX-77092, USA
                </a>
              </p>
              <a
                className="text-white"
                target="_blank"
                href="https://idare.io/"
              >
                IDARE
              </a>
              <div>
                <a
                  className="text-white"
                  href="./terms-and-conditions-asset_twin.pdf"
                  target="_blank"
                >
                  Terms & Conditions
                </a>
              </div>
            </div>
            <div className="text-right mt-2 logo_asset_twin">
              <p className="font_audiowide m-0">
                <span className="fsize_15 mr-2 brand_color">Powered by</span>
                <span className="fsize_32 brand_color">
                  IDARE<sup>®</sup>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row img_section">
            <div className="col-12 text-center">
              <img
                className="p-2 img-fluid"
                src={require("../../static/assets/img/1.jpg")}
                alt=""
              />
            </div>
            <div className="col-12 text-center">
              <img
                className="p-2 img-fluid"
                src={require("../../static/assets/img/2.jpg")}
                alt=""
              />
            </div>
            <div className="col-12 text-center">
              <img
                className="p-2 img-fluid"
                src={require("../../static/assets/img/3.jpg")}
                alt=""
              />
            </div>
            <div className="col-12 text-center">
              <img
                className="p-2 img-fluid"
                src={require("../../static/assets/img/3.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppInfo;
