import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { 
  getDatasetColumns, 
  getDatasetColumnsStatisticOperation, 
  getDatasetColumnsStatisticOperation__METADATA, 
  getDatasetColumns__METADATA, updateCustomDashboardPanel 
} from "../../../actions/customPanelModify";
import ModalAT from "../../ModalAT";

const staticDataset = [
  { name: "Asset Basic", calling_param: "asset_basic", type: "METADATA" },
  { name: "Asset Specification", calling_param: "asset_spec", type: "METADATA" },
  { name: "Pipeline Basic", calling_param: "pipeline_basic", type: "METADATA" },
  { name: "Pipeline Specification", calling_param: "pipeline_spec", type: "METADATA" },
];

const IndicatorUpdate = () => {
  const dispatch = useDispatch();
  const activeTabData = useSelector( (state) => state.customPanelModify.ACTIVE_TAB_DATA );
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );
  const datasetList = useSelector( (state) => state.customPanelModify.datasetList );
  const isWebglDashboardActive = useSelector((state) => state.customPanelModify.isWebglDashboard);
  // --- MODAL CONF ---
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);

  const [title, setTitle] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);

  window.dataTableModalOpen = (panel) => {
    setPanelInfo(panel);
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    document.getElementById("table-data-edit-modal-name-inp").value = panelInfo.data ? panelInfo.data.name : panelInfo.name;
    // console.log(panelInfo)
  };
  const closeModal = () => {
    setTitle(null)
    setSelectedDataset(null)
    setIsOpen(false);
  };

  const selectOptionOnChangeHandler = (data) => {
    if (data === "ADD_NEW_DATASET") {
      closeModal();
      document.getElementById("new-dataset-input-modal__opener-el").click();
    } else if (data) {
      setSelectedDataset(JSON.parse(data));
    }
  };


  const applyDatasetClickHandler = () => {
    Object.assign(selectedDataset, {dataset_table_name: title ? title : selectedDataset.name});
    panelInfo.panel_config.data = selectedDataset
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true
    })
    dispatch(updateCustomDashboardPanel(activeTabData.guid, panelInfo.guid, panelInfo.panel_config));
    window.reloadDataTablePanel()
    closeModal()
  }

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_data_table_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle="Update Data Table"
          contentLabel="Update Data Table"
          ariaHideApp={false}
        >
          {/* DATASET SELECTION */}
          <div className="form__group">
            <label htmlFor="" className="form__label">
              Data Table Name
            </label>
            <input
              type="text"
              className="form-control form__control"
              id="table-data-edit-modal-name-inp"
              placeholder="Table title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="form__group">
            <label htmlFor="" className="form__label">
              Select Dataset
            </label>
            <select
              className="custom-select"
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                selectOptionOnChangeHandler(e.target.value);
              }}
            >
              <option value={null} selected disabled>
                {/* Select dataset */}
                Please Select
              </option>
              {!isWebglDashboardActive ? <option value={"ADD_NEW_DATASET"}>Add New Dataset</option> : <></>}
              <optgroup label="Meta Dataset">
                {staticDataset.map((dataset, index) => (
                  <option value={JSON.stringify(dataset)} key={index}>
                    {dataset.name}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Uploaded Dataset">
                {datasetList.map((dataset, index) => (
                  <option value={JSON.stringify(dataset)} key={index}>
                    {dataset.name}
                  </option>
                ))}
              </optgroup>
            </select>
          </div>
          {/* SUBMIT */}
          <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
            <button
              className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
              disabled={!selectedDataset}
              onClick={() => {
                applyDatasetClickHandler();
              }}
            >
              Apply
            </button>
          </div>
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorUpdate);
