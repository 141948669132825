import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  createChart,
  getDatasetColumns,
  updateCustomDashboardPanel,
} from "../../../actions/customPanelModify";
import ModalAT from "../../ModalAT";
import { getDefaultChartData } from "../panels/SocketChart/defaultChartData";
import { generateGagueChartLayout } from "../panels/SocketChart/layout/layout_gague.default";
import { SketchPicker } from "react-color";

import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { getIconFromList } from "../../../static/IconServe";
const Plot = createPlotlyComponent(Plotly);

const background = "#FFFFFF";

const GagueChartUpdateModal = () => {
  const dispatch = useDispatch();
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );
  // --- MODAL CONF ---
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedDatasetColumnList, setSelectedDatasetColumnList] =
    useState(null);

  const [chartTitle, setChartTitle] = useState(null);
  const [chartvalue, setChartValue] = useState(null);

  const [deltaRefValue, setDeltaRefValue] = useState(400);
  const [axisStartValue, setAxisRangeStartValue] = useState(0);
  const [axisEndValue, setAxisRangeEndValue] = useState(500);
  const [thresholdValue, setThresholdValue] = useState(490);

  const [axisRangeStart01, setAxisRangeStart01] = useState(0);
  const [axisRangeEnd01, setAxisRangeEnd01] = useState(250);
  const [axisRangeStart02, setAxisRangeStart02] = useState(250);
  const [axisRangeEnd02, setAxisRangeEnd02] = useState(500);

  const [reloadVisualChart, setReloadVisualChart] = useState(true);

  // --- CHART VISUAL DATA ---
  const [gagueChartData, setGagueChartData] = useState(
    getDefaultChartData("gague_chart").data
  );

  window.gagueChartEditModalOpen = (panel) => {
    setPanelInfo(panel);
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    // console.log(panelInfo.panel_config.data);
    if (panelInfo.panel_config.data) {
      // document.getElementById("gague_chart_edit__chart_title").value = panelInfo.panel_config.data.title.text;
      // setChartTitle(panelInfo.panel_config.data.title.text);
      document.getElementById("gague_chart_edit__chart_title").value = panelInfo.panel_config.bar_data ? panelInfo.panel_config.bar_data.chart_name : panelInfo.panel_config.data.chart_name;
      setChartTitle(panelInfo.panel_config.bar_data ? panelInfo.panel_config.bar_data.chart_name : panelInfo.panel_config.data.chart_name);
      document.getElementById("gague_chart_edit__chart_value").value = panelInfo.panel_config.data.value;
      setChartValue(panelInfo.panel_config.data.value);
      document.getElementById("gague_chart_edit__chart_delta_reference_value").value = panelInfo.panel_config.data.delta.reference;
      document.getElementById("gague_chart_edit__chart_threshold_value").value = panelInfo.panel_config.data.gauge.threshold.value;
      document.getElementById("gague_chart_edit__chart_axis_range_start").value = panelInfo.panel_config.data.gauge.axis.range[0];
      document.getElementById("gague_chart_edit__chart_axis_range_end").value = panelInfo.panel_config.data.gauge.axis.range[1];
      document.getElementById("gague_chart_edit__chart_axis_range_start_1").value = panelInfo.panel_config.data.gauge.steps[0].range[0];
      document.getElementById("gague_chart_edit__chart_axis_range_end_1").value = panelInfo.panel_config.data.gauge.steps[0].range[1];
      document.getElementById("gague_chart_edit__chart_axis_range_start_2").value = panelInfo.panel_config.data.gauge.steps[1].range[0];
      document.getElementById("gague_chart_edit__chart_axis_range_end_2").value = panelInfo.panel_config.data.gauge.steps[1].range[1];

      setTimeout(() => {
        document.getElementById("visual-new-gague-chart-data").click();
      }, 100);
    }
  };

  const closeModal = () => {
    setChartTitle(null);
    setGagueChartData(getDefaultChartData("gague_chart").data);
    setIsOpen(false);
  };

  const applyDatasetClickHandler = () => {
    let chart_data = {
      chart_type: panelInfo.panel_config.defaultData.chart_type,
      chart_title: chartTitle,
      value: chartvalue,
      reference_value: deltaRefValue,
      threshold: thresholdValue,
      axis_start: axisStartValue,
      axis_end: axisEndValue,
      range_01_start: axisRangeStart01,
      range_01_end: axisRangeEnd01,
      range_02_start: axisRangeStart02,
      range_02_end: axisRangeEnd02,
    };
    // console.log(chart_data);

    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });

    updateGagueChartDefaultData();
    panelInfo.panel_config.data = gagueChartData[0];
    dispatch( updateCustomDashboardPanel( activeTabData.guid, panelInfo.guid, panelInfo.panel_config ) );
    closeModal();
  };

  const updateGagueChartDefaultData = () => {
    gagueChartData[0].chart_name = chartTitle;
    gagueChartData[0].title.text = chartTitle;
    gagueChartData[0].value = parseInt(chartvalue);
    gagueChartData[0].delta.reference = parseInt(deltaRefValue);
    gagueChartData[0].gauge.threshold.value = parseInt(thresholdValue);
    gagueChartData[0].gauge.axis.range[0] = parseInt(axisStartValue);
    gagueChartData[0].gauge.axis.range[1] = parseInt(axisEndValue);
    gagueChartData[0].gauge.steps[0].range[0] = parseInt(axisRangeStart01);
    gagueChartData[0].gauge.steps[0].range[1] = parseInt(axisRangeEnd01);
    gagueChartData[0].gauge.steps[1].range[0] = parseInt(axisRangeStart02);
    gagueChartData[0].gauge.steps[1].range[1] = parseInt(axisRangeEnd02);
  }

  const visualNewChart = () => {
    updateGagueChartDefaultData();

    setReloadVisualChart(false);
    setTimeout(() => {
      setReloadVisualChart(true);
    }, 10);
  };

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_gague_chart_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle="Update Gauge Chart Data"
          contentLabel="Update Gauge Chart Data"
          ariaHideApp={false}
        >
          <div className="row form__row">
            <div className="col-md-6 pr-0">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_title"
                  placeholder="Chart Title"
                  onChange={(e) => {
                    e.stopPropagation();
                    setChartTitle(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 pl-1">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_value"
                  placeholder="Value: 420"
                  onChange={(e) => {
                    e.stopPropagation();
                    setChartValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row form__row mt-2">
            <div className="col-md-6 pr-0">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_delta_reference_value"
                  placeholder="Delta Reference Value. Ex: 400"
                  onChange={(e) => {
                    e.stopPropagation();
                    setDeltaRefValue(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 pl-1">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_threshold_value"
                  placeholder="Threshold value: 490"
                  onChange={(e) => {
                    e.stopPropagation();
                    setThresholdValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row form__row mt-2">
            <div className="col-md-6 pr-0">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_axis_range_start"
                  placeholder="Axis range starts from. Ex: 0"
                  onChange={(e) => {
                    e.stopPropagation();
                    setAxisRangeStartValue(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 pl-1">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_axis_range_end"
                  placeholder="Axis range ends. Ex: 500"
                  onChange={(e) => {
                    e.stopPropagation();
                    setAxisRangeEndValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {/* RANGE 1 */}
          <div className="row form__row mt-2">
            <div className="col-md-6 pr-0">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_axis_range_start_1"
                  placeholder="Range 1 start value: 0"
                  onChange={(e) => {
                    e.stopPropagation();
                    setAxisRangeStart01(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 pl-1">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_axis_range_end_1"
                  placeholder="Range 1 end value: 0"
                  onChange={(e) => {
                    e.stopPropagation();
                    setAxisRangeEnd01(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {/* RANGE 2 */}
          <div className="row form__row mt-2">
            <div className="col-md-6 pr-0">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_axis_range_start_2"
                  placeholder="Range 2 start value: 250"
                  onChange={(e) => {
                    e.stopPropagation();
                    setAxisRangeStart02(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 pl-1">
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  id="gague_chart_edit__chart_axis_range_end_2"
                  placeholder="Range 2 end value: 500"
                  onChange={(e) => {
                    e.stopPropagation();
                    setAxisRangeEnd02(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row form__row mt-4 mb-4 align-items-center">
            <p className="gague_chart__vis_txt">Visual Gague Chart</p>
            {reloadVisualChart ? (
              <>
                <Plot
                  data={gagueChartData}
                  layout={generateGagueChartLayout(
                    getDefaultChartData("gague_chart").layout,
                    panelInfo.panel_config
                  )}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                />
              </>
            ) : (
              <></>
            )}

            <SketchPicker
              color={background}
              onChangeComplete={(color, element) => {
                // if (focusedInput) {
                //   document.getElementById(focusedInput.id).value = color.hex;
                //   document.getElementById(focusedInput.id).style.backgroundColor = color.hex + " !important";
                // }
              }}
            />
          </div>
          {/* SUBMIT */}
          <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
            <button
              className="btn ms-4 modal__submit_btn data-source_btn btn__secondary mr-2"
              id="visual-new-gague-chart-data"
              onClick={() => {
                visualNewChart();
              }}
            >
              Visual New Data
            </button>
            <button
              className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
              // disabled={!chartTitle || !selectedDataset || !xAxisCategory}
              onClick={() => {
                applyDatasetClickHandler();
              }}
            >
              Apply
            </button>
          </div>
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GagueChartUpdateModal);
