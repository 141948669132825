import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumaricalFilter from "./numarical_filter";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "oct",
  "Nov",
  "Dec",
];

const ChartFilter = ({
  bar_data,
  setSelectedFilteredValue,
  addFilter_columnData,
  setAddFilter_columnData,
  addFilter_columnDataType,
  setAddFilter_columnDataType,
  filterMapbox,
  setAddFilter,
  selectedDatasetColumnList,
  selectedFilteredValue,
  selectedFilteredValue_type,
  setSelectedFilteredValueType,
}) => {
  const dispatch = useDispatch();
  const [filteredBySearch, setFilteredBySearch] = useState([]);
  const [selectionDate, setSelectionDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleFilterSearch = (searchInput) => {
    const matches = addFilter_columnData.filter((s) =>
      s.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredBySearch(matches);
    addFilter_columnData.forEach((e, index) => {
      if (document.getElementById("filter_column_unique_value_" + index)) {
        document.getElementById(
          "filter_column_unique_value_" + index
        ).checked = false;
      }
      setSelectedFilteredValue([]);
    });
  };

  useEffect(() => {
    if (addFilter_columnData && selectedFilteredValue) {
      // console.log(addFilter_columnData);
      // console.log(selectedFilteredValue);
      // console.log(selectionDate);
      setSelectionDate({
        ...selectionDate,
        startDate:
          selectedFilteredValue && selectedFilteredValue[0]
            ? new Date(selectedFilteredValue[0])
            : new Date(addFilter_columnData[0]),
        endDate:
          selectedFilteredValue && selectedFilteredValue[1]
            ? new Date(selectedFilteredValue[1])
            : new Date(addFilter_columnData[1]),
      });
      setSelectedFilteredValue([
        selectedFilteredValue && selectedFilteredValue[0]
          ? selectedFilteredValue[0]
          : addFilter_columnData[0],
        selectedFilteredValue && selectedFilteredValue[1]
          ? selectedFilteredValue[1]
          : addFilter_columnData[1],
      ]);
    }
  }, [addFilter_columnData]);

  const handleSelect = (ranges) => {
    // console.log(ranges);
    setSelectionDate({
      ...selectionDate,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
    setSelectedFilteredValue([
      new Date(ranges.selection.startDate).toLocaleDateString("en-US"),
      new Date(ranges.selection.endDate).toLocaleDateString("en-US"),
    ]);
  };

  return (
    <>
      {/* ------------- FILTERING ------------- */}
      <div className="row form__row mt-2">
        <div className="col-md-4 pr-2">
          <div className="form__group">
            <label>Add Filter</label>
          </div>
          <div className="form__group">
            <select
              className="custom-select"
              id="bar_chart_edit__chart_add_filter"
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedFilteredValue([]);
                setAddFilter_columnData(null);
                setAddFilter_columnDataType(null);
                setAddFilter(e.target.value);

                if (
                  bar_data &&
                  bar_data.chart_type_api_sep === "mapbox_density_heatmap"
                ) {
                  bar_data.filterMapbox.filteredBySearch = [];
                  bar_data.filterMapbox.selectedFilteredValue = [];
                  if (
                    bar_data.filterMapbox.addFilter_columnDataType === "numeric"
                  ) {
                    bar_data.filterMapbox.selectedFilteredValue_type = "range";
                  }
                }
              }}
            >
              <option value="" selected disabled>
                Please Select
              </option>
              {selectedDatasetColumnList ? (
                <>
                  <option value="None">None</option>
                  {selectedDatasetColumnList.map((col, index_) => (
                    <>
                      <option value={JSON.stringify(col)} key={index_}>
                        {col.col_name}
                      </option>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </select>
          </div>
        </div>
        {addFilter_columnData &&
        addFilter_columnDataType !== "numeric" &&
        addFilter_columnData &&
        addFilter_columnDataType !== "datetime" ? (
          // {/* -------------------- STRING COLUMN SEARCH BOX ------------------- */}
          <>
            <div className="col-md-6 pl-1">
              <div className="form__group">
                <label>Search</label>
              </div>
              <div className="form__group">
                <input
                  type="text"
                  className="form-control form__control"
                  placeholder="Search filter"
                  onChange={(e) => {
                    e.stopPropagation();
                    handleFilterSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          // {/* -------------------- NUMERIC COLUMN ------------------- */}
          <>
            {addFilter_columnData && addFilter_columnDataType === "numeric" ? (
              <NumaricalFilter
                bar_data={bar_data}
                selectedFilteredValue={selectedFilteredValue}
                setSelectedFilteredValue={setSelectedFilteredValue}
                addFilter_columnData={addFilter_columnData}
                selectedFilteredValue_type={selectedFilteredValue_type}
                setSelectedFilteredValueType={setSelectedFilteredValueType}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      {addFilter_columnData ? (
        <>
          {addFilter_columnDataType === "numeric" ? (
            <>
              {/* <div className="row form__row mt-4 pl-4 pr-4">
                    <div className="col-md-12 pr-2">
                      <ReactRangeInput 
                        step={1} 
                        min={addFilter_columnData[0]} 
                        max={addFilter_columnData[addFilter_columnData.length - 1]} 
                        selectedFilteredValue={!isNaN(selectedFilteredValue[0]) ? selectedFilteredValue : [addFilter_columnData[0], addFilter_columnData[addFilter_columnData.length - 1]]} 
                        setSelectedFilteredValue={setSelectedFilteredValue} />
                    </div>
                  </div> */}
            </>
          ) : (
            <>
              {addFilter_columnDataType !== "datetime" ? (
                // {/* -------------------- STRING COLUMN ------------------- */}
                <>
                  <div
                    className="row form__row m-0 mt-2"
                    style={{
                      border: "1px solid #FFFFFF",
                      borderBottom: "none",
                    }}
                  >
                    <div className="col-md-4 pr-2">
                      <div className="form-check mr-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              (filteredBySearch.length > 0
                                ? filteredBySearch
                                : addFilter_columnData
                              ).forEach((e, index) => {
                                // addFilter_columnData.forEach((e, index) => {
                                document.getElementById(
                                  "filter_column_unique_value_" + index
                                ).checked = true;
                                setSelectedFilteredValue(addFilter_columnData);
                              });
                            } else {
                              (filteredBySearch.length > 0
                                ? filteredBySearch
                                : addFilter_columnData
                              ).forEach((e, index) => {
                                // addFilter_columnData.forEach((e, index) => {
                                document.getElementById(
                                  "filter_column_unique_value_" + index
                                ).checked = false;
                                setSelectedFilteredValue([]);
                              });
                            }
                          }}
                        />
                        <label className="form-check-label">Select All</label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row form__row m-0"
                    style={{ border: "1px solid #FFFFFF", borderTop: "none" }}
                  >
                    {(filteredBySearch.length > 0
                      ? filteredBySearch
                      : addFilter_columnData
                    ).map((val, index) => (
                      <div className="col-md-4 pr-2" key={index}>
                        <div className="form-check mr-4">
                          <input
                            className="form-check-input"
                            id={"filter_column_unique_value_" + index}
                            type="checkbox"
                            // defaultChecked={"checked"}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedFilteredValue([
                                  ...selectedFilteredValue,
                                  val,
                                ]);
                              } else {
                                setSelectedFilteredValue(
                                  selectedFilteredValue.filter((v) => v !== val)
                                );
                              }
                            }}
                          />
                          <label className="form-check-label">{val}</label>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                // {/* -------------------- DATE COLUMN ------------------- */}
                <>
                  <DateRangePicker
                    className="date_range_picker_style-custom"
                    ranges={[selectionDate]}
                    onChange={(e) => {
                      handleSelect(e);
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChartFilter);
