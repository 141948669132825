import React, { useEffect, useRef, useState } from "react";
// import Plot from "react-plotly.js";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import request from "../../../../utils/request";
import { useSelector } from "react-redux";
import { getDefaultChartData } from "./defaultChartData";
import { generateStackedBarColumnChartLayout } from "./layout/layout_stacked_bar_column.default";
import Plot from "react-plotly.js";
import { generate3DLineChartLayout } from "./layout/layout.default_3D";
import {
  generateMeshChartConfig,
  generateMeshChartLayout,
} from "./layout/layout_mesh.default.js";
import toastr from "toastr";
import { generatePieChartLayout } from "./layout/layout_pie.default";
import { getIconFromList } from "../../../../static/IconServe";

import GenerateMeshPlot from "./meshplot/Meshplot";

const Plot_ = createPlotlyComponent(Plotly);

const lineColorList = [
  "FFFFFF",
  "00F8AE",
  "FDDD60",
  "FF6E76",
  "58D9F9",
  "05C091",
  "FF8A45",
  "8D48E3",
  "DD79FF",
  "FFFFFF",
  "00F8AE",
  "FDDD60",
  "FF6E76",
  "58D9F9",
  "05C091",
  "FF8A45",
  "8D48E3",
  "DD79FF",
  "FFFFFF",
  "00F8AE",
  "FDDD60",
  "FF6E76",
  "58D9F9",
  "05C091",
  "FF8A45",
  "8D48E3",
  "DD79FF",
  "FFFFFF",
  "00F8AE",
  "FDDD60",
  "FF6E76",
  "58D9F9",
  "05C091",
  "FF8A45",
  "8D48E3",
  "DD79FF",
  "FFFFFF",
  "00F8AE",
  "FDDD60",
  "FF6E76",
  "58D9F9",
  "05C091",
  "FF8A45",
  "8D48E3",
  "DD79FF",
];

const pieColorList = [
  "#D83D32",
  "#F98187",
  "#283F53",
  "#31566F",
  "#68A2C1",
  "#D83D32",
  "#F98187",
  "#283F53",
  "#31566F",
  "#68A2C1",
  "#D83D32",
  "#F98187",
  "#283F53",
  "#31566F",
  "#68A2C1",
  "#D83D32",
  "#F98187",
  "#283F53",
  "#31566F",
  "#68A2C1",
  "#D83D32",
  "#F98187",
  "#283F53",
  "#31566F",
  "#68A2C1",
];

const lineColorList_zz = [
  "00F8AE",
  "FDDD60",
  "FF6E76",
  "58D9F9",
  "05C091",
  "FF8A45",
  "8D48E3",
  "DD79FF",
];

const SocketChartBarGroup = ({ chart, panel }) => {
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const [colorPick, setColorPick] = useState(lineColorList);
  const [isLoaderOn, setIsLoaderOn] = useState(true);
  const [barChartData, setBarChartData] = useState({
    x: [],
    y: [],
    type: "bar",
    name: "",
  });
  const [pieGrid, setPieGrid] = useState({ rows: 1, columns: 1 });
  const [mapboxLayout, setMapboxLayout] = useState(null);

  // PAGINATION
  const [sliderLvlVal, setSliderLvlVal] = useState({ start: 1, end: 20 });
  const [sliderVal, setSliderVal] = useState(1);
  const [dataLimit, setDataLimit] = useState(5000);
  const [chartPage, setChartPage] = useState(1);
  const [chartPageFlag, setChartPageFlag] = useState(false);
  // const [meshFrame, setMeshFrame] = useState([]);
  const plotRef = useRef(null);

  useEffect(async () => {
    // console.log("==================================");
    // console.log(panel);
    setMapboxLayout(null);
    setChartPageFlag(false);
    if (panel.panel_config.bar_data.chart_type_api_sep === "bar") {
      createBarModalPlot(chartPage);
    } else if (panel.panel_config.bar_data.chart_type_api_sep === "line") {
      createLineModalPlot(chartPage);
    } else if (panel.panel_config.bar_data.chart_type_api_sep === "pie") {
      createPieModalPlot(chartPage);
    } else if (panel.panel_config.bar_data.chart_type_api_sep === "mesh") {
      // createMeshPlot();
      setIsLoaderOn(false);
    } else if (
      panel.panel_config.bar_data.chart_type_api_sep ===
      "mapbox_density_heatmap"
    ) {
      createMapboxDensityHeatmap();
    }
    //  else {
    //   createBarModalPlot(chartPage);
    // }
    return () => {};
  }, [panel]);

  // PAGINATION RECALL
  useEffect(() => {
    if (chartPageFlag) {
      setMapboxLayout(null);
      setChartPageFlag(false);
      if (panel.panel_config.bar_data.chart_type_api_sep === "bar") {
        createBarModalPlot(chartPage);
      } else if (panel.panel_config.bar_data.chart_type_api_sep === "line") {
        createLineModalPlot(chartPage);
      } else if (panel.panel_config.bar_data.chart_type_api_sep === "pie") {
        createPieModalPlot(chartPage);
      } else {
        createBarModalPlot(chartPage);
      }
    }
    return () => {};
  }, [chartPageFlag]);

  // MAPBOX - HEATMAP
  useEffect(() => {
    if (
      panel.panel_config.bar_data.chart_type_api_sep ===
      "mapbox_density_heatmap"
    ) {
      createMapboxDensityHeatmap();
    }
  }, [panel.panel_config.full_screen]);

  // --------------- MAPBOX DENSITY HEATMAP ---------------
  const createMapboxDensityHeatmap = async (pageNo) => {
    let payload = {
      dataset_type: panelFieldInfo.panel_type,
      chart_config: panel.panel_config.bar_data.chart_config,
    };

    // console.log(payload);

    setIsLoaderOn(true);
    try {
      let { data } = await request.post(
        `/api/map/chart/${chart.guid}/stats/`,
        payload
      );
      // // console.log(data);
      // let bar_data_arr = [];
      let map_dt = [
        {
          type: "densitymapbox",
          lat: data.lat,
          lon: data.lon,
          z: data.z,
          radius: panel.panel_config.bar_data.chart_config.radius,
          // colorbar: {y: 0, yanchor: 'bottom', len: 0.45}
          coloraxis: "coloraxis",
          hoverinfo: "skip",
          hovertemplate:
            panel.panel_config.bar_data.chart_config.z_value.col_name +
            ": %{z}<br>" +
            panel.panel_config.bar_data.chart_config.lat.col_name +
            ": %{lat}<br>" +
            panel.panel_config.bar_data.chart_config.lng.col_name +
            ": %{lon}<br>" +
            "<extra></extra>",
          zmin: panel.panel_config.bar_data.chart_config.zmin
            ? panel.panel_config.bar_data.chart_config.zmin
            : data.zmin,
          zmax: panel.panel_config.bar_data.chart_config.zmax
            ? panel.panel_config.bar_data.chart_config.zmax
            : data.zmax,
        },
      ];
      setMapboxLayout({
        mapbox: {
          center: {
            lat: data.mapbox_center.lat,
            lon: data.mapbox_center.lon,
          },
          style: panel.panel_config.bar_data.chart_config.base_map,
          zoom: panel.panel_config.bar_data.chart_config.zoom.value,
        },
        coloraxis: {
          // colorscale: "Blackbody",
          colorscale: panel.panel_config.bar_data.chart_config.color_palate,
        },
        // title: {
        //   text: "Earthquake Magnitude"
        // },
        width: panel.panel_config.full_screen
          ? window.innerWidth - 10
          : panel.panel_config.defaultPosition.width - 10,
        height: panel.panel_config.full_screen
          ? window.innerHeight - 10
          : panel.panel_config.defaultPosition.height - 30,
        margin: {
          t: 30,
          b: 0,
          l: 0,
          r: 0,
        },
        // mapbox_bounds: {
        //   east: 90,
        //   north: 50,
        //   south: 30,
        //   west: 20
        // }
      });
      // bar_data_arr.push(data);
      if (
        !panel.panel_config.bar_data.chart_config.map_view_limit.max_lat ||
        !panel.panel_config.bar_data.chart_config.map_view_limit.max_lng ||
        !panel.panel_config.bar_data.chart_config.map_view_limit.min_lat ||
        !panel.panel_config.bar_data.chart_config.map_view_limit.min_lng ||
        !panel.panel_config.bar_data.chart_config.zmin ||
        !panel.panel_config.bar_data.chart_config.zmax
      ) {
        panel.panel_config.bar_data.chart_config.map_view_limit.max_lat =
          data.max_lat;
        panel.panel_config.bar_data.chart_config.map_view_limit.max_lng =
          data.max_lon;
        panel.panel_config.bar_data.chart_config.map_view_limit.min_lat =
          data.min_lat;
        panel.panel_config.bar_data.chart_config.map_view_limit.min_lng =
          data.min_lon;
        panel.panel_config.bar_data.chart_config.zmin = data.zmin;
        panel.panel_config.bar_data.chart_config.zmax = data.zmax;
      }
      setBarChartData(map_dt);
      setIsLoaderOn(false);
    } catch (error) {
      // console.log("catch", error);
      toastr.warning(
        "Incorrect chart parameters, please try again.",
        "Wrong parameters"
      );
    }
  };

  // --------------- MESH CHART ---------------
  // const createMeshPlot = async (pageNo) => {
  //   let payload = {
  //     dataset_type: panelFieldInfo.panel_type,
  //     chart_config: panel.panel_config.bar_data.chart_config,
  //   };

  //   setIsLoaderOn(true);
  //   try {
  //     let { data } = await request.post(
  //       `/api/mesh/chart/${chart.guid}/stats/`,
  //       payload
  //     );
  //     // // console.log(data);
  //     let bar_data_arr = [];
  //     let bc = {
  //       x: data.x,
  //       y: data.y,
  //       z: data.z,
  //       i: data.i,
  //       j: data.j,
  //       k: data.k,
  //       intensity: data.value,
  //       cmin: data.cmin,
  //       cmax: data.cmax,
  //       type: "mesh3d",
  //       colorscale: getMeshColorScale(
  //         panel.panel_config.bar_data.chart_config.color_plate
  //       ),
  //       reversescale: getMeshColorScaleIsReverse(
  //         panel.panel_config.bar_data.chart_config.color_plate
  //       ),
  //       hovertemplate:
  //         panel.panel_config.bar_data.chart_config.z.col_name +
  //         ": %{z}<br>" +
  //         panel.panel_config.bar_data.chart_config.x.col_name +
  //         ": %{x}<br>" +
  //         panel.panel_config.bar_data.chart_config.y.col_name +
  //         ": %{y}<br>" +
  //         panel.panel_config.bar_data.chart_config.value.col_name +
  //         ": %{intensity}<br>" +
  //         "<extra></extra>",
  //     };
  //     if (
  //       !panel.panel_config.bar_data.chart_config.cmin ||
  //       !panel.panel_config.bar_data.chart_config.cmax
  //     ) {
  //       panel.panel_config.bar_data.chart_config.cmin = data.cmin;
  //       panel.panel_config.bar_data.chart_config.cmax = data.cmax;
  //     }
  //     bar_data_arr.push(bc);
  //     setBarChartData(bar_data_arr);
  //     setIsLoaderOn(false);

  //     /*
  //     var frames = [];
  //     var n = 5000;
  //     for (var ii = 0; ii < n; ii++) {
  //       frames[ii] = {
  //         data: [{ x: [], y: [], z: [], i: [], j: [], k: [], intensity: [] }],
  //       };
  //       frames[ii].data[0].x = bc.x.slice(0, ii + 1);
  //       frames[ii].data[0].y = bc.y.slice(0, ii + 1);
  //       frames[ii].data[0].z = bc.z.slice(0, ii + 1);
  //       frames[ii].data[0].i = bc.i.slice(0, ii + 1);
  //       frames[ii].data[0].j = bc.j.slice(0, ii + 1);
  //       frames[ii].data[0].k = bc.k.slice(0, ii + 1);
  //       frames[ii].data[0].intensity = bc.intensity.slice(0, ii + 1);
  //     }
  //     setMeshFrame(frames);*/
  //   } catch (error) {
  //     // console.log("catch", error);
  //     toastr.warning(
  //       "Incorrect chart parameters, please try again.",
  //       "Wrong parameters"
  //     );
  //   }
  // };

  // --------------- BAR CHART ---------------
  const createBarModalPlot = async (pageNo) => {
    let panel_chart = panel.panel_config.bar_data;

    let select_series_ = [];

    for (let i = 0; i < panel_chart.y_axis_categories.length; i++) {
      if (panel_chart.y_axis_categories[i].group_by) {
        select_series_.push(panel_chart.y_axis_categories[i].group_by);
      }
    }
    // BAR MODAL PAYLOAD
    let bp = {
      dataset_type: panelFieldInfo.panel_type,
      operation: panel.panel_config.bar_data.bar_group_operation,
      category: [panel.panel_config.bar_data.bar_group_variable],
      sub_category: panel.panel_config.bar_data.sub_category
        ? [panel.panel_config.bar_data.sub_category]
        : [],
      variable: select_series_,
      filtering: panel.panel_config.bar_data.selected_filter_column_name
        ? {
            col_name:
              panel.panel_config.bar_data.selected_filter_column_name.col_name,
            value: panel.panel_config.bar_data.selected_filter_col_value,
          }
        : null,
      sorting: panel.panel_config.bar_data.sort_by
        ? {
            col_name: panel.panel_config.bar_data.sort_by,
            asc: panel.panel_config.bar_data.sort_type === "asc" ? true : false,
          }
        : null,
    };
    // // console.log(bp)
    // ---------------------------------------
    setIsLoaderOn(true);
    try {
      let { data } = await request.post(
        `/api/chart/${chart.guid}/stats/?page=${pageNo}&limit=${
          panel.panel_config.bar_data.data_points
            ? panel.panel_config.bar_data.data_points
            : dataLimit
        }`,
        bp
      );
      // // console.log(data)

      setSliderLvlVal({
        start: 1,
        end: data.total_pages,
      });
      let bar_data_arr = [];
      data = data.data;
      let response = Object.keys(data);
      for (let i = 0; i < response.length; i++) {
        // // console.log(Object.keys(data)[i])  // OBJECT'S NAME/KEY
        // // console.log(Object.values(data)[i])  // OBJECT'S VALUE

        let bc = {
          x:
            panel.panel_config.bar_data.bar_plot_type === "Vertical"
              ? Object.values(data)[i].x
              : Object.values(data)[i].y,
          y:
            panel.panel_config.bar_data.bar_plot_type === "Vertical"
              ? Object.values(data)[i].y
              : Object.values(data)[i].x,
          orientation:
            panel.panel_config.bar_data.bar_plot_type === "Vertical"
              ? "v"
              : "h",
          name: Object.keys(data)[i],
          // fill: 'tozeroy',
          // type: panel.panel_config.bar_data.chart_type,
          type: getChartType(panel.panel_config.bar_data.chart_type_adv),
          textposition: "auto",
          opacity: 0.7,
          marker: {
            // color: colorPick,
            line: {
              color: lineColorList[i],
              width: 1.5,
            },
          },
          line: {
            color: "#" + lineColorList[i],
            width: 1.5,
          },
          hovertemplate:
            `<b>${Object.keys(data)[i]}</b><br>` +
            "%{x}, %{y}<br>" +
            "<extra></extra>",
        };
        if (
          panel.panel_config.bar_data.chart_type_adv === "area_line" ||
          panel.panel_config.bar_data.chart_type_adv === "area_scatter"
        ) {
          bc.fill = "tozeroy";
        }
        bar_data_arr.push(bc);
      }
      setBarChartData(bar_data_arr);
      setIsLoaderOn(false);
      // // console.log(bar_data_arr)
    } catch (error) {
      // console.log("catch", error);
      // toastr.warning("Request timeout", "Your internet connection is slow.");
      toastr.warning(
        "Incorrect chart parameters, please try again.",
        "Wrong parameters"
      );
    }
  };

  // --------------- LINE CHART ---------------
  const createLineModalPlot = async (pageNo) => {
    let panel_chart = panel.panel_config.bar_data;

    let select_series_ = [];
    let select_series_rename_ = [];
    let each_series_type_ = {};
    let select_z_ = [];

    for (let i = 0; i < panel_chart.y_axis_categories.length; i++) {
      if (panel_chart.y_axis_categories[i].group_by) {
        select_series_.push(panel_chart.y_axis_categories[i].group_by);
        select_series_rename_.push({
          series_name: panel_chart.y_axis_categories[i].group_by,
          user_given_name: panel_chart.y_axis_categories[i].name,
        });
        Object.assign(each_series_type_, {
          [panel_chart.y_axis_categories[i].group_by]: getPlotType(
            panel_chart.y_axis_categories[i].type
          ),
        });
      }
    }
    if (
      panel_chart.y_axis_categories[0].group_by === null ||
      panel_chart.y_axis_categories[0].group_by === ""
    ) {
      each_series_type_ = null;
    }

    if (
      panel.panel_config.bar_data.chart_type_api_sep === "line" &&
      panel.panel_config.bar_data.is_three_dym
    ) {
      for (let i = 0; i < panel_chart.z_axis_categories.length; i++) {
        if (panel_chart.z_axis_categories[i].group_by) {
          select_z_.push(panel_chart.z_axis_categories[i].group_by);
        }
      }
    }

    // LINE MODAL PAYLOAD
    // console.log(panel.panel_config.bar_data);
    let bp = {
      dataset_type: panelFieldInfo.panel_type,
      operation: panel.panel_config.bar_data.bar_group_operation,
      select_x: [panel.panel_config.bar_data.bar_group_variable],
      select_series: select_series_,
      select_z: select_z_,
      select_series_rename: select_series_rename_,
      each_series_type: each_series_type_,
      filtering:
        panel.panel_config.bar_data.selected_filter_column_name &&
        panel.panel_config.bar_data.selected_filter_col_value.length > 0
          ? {
              col_name:
                panel.panel_config.bar_data.selected_filter_column_name
                  .col_name,
              value: panel.panel_config.bar_data.selected_filter_col_value,
            }
          : null,
      sorting: panel.panel_config.bar_data.sort_by
        ? {
            col_name: panel.panel_config.bar_data.sort_by,
            asc: panel.panel_config.bar_data.sort_type === "asc" ? true : false,
          }
        : null,
    };
    // // console.log(bp)
    // ---------------------------------------
    setIsLoaderOn(true);
    try {
      let { data } = await request.post(
        `/api/line/chart/${chart.guid}/stats/?page=${pageNo}&limit=${
          panel.panel_config.bar_data.data_points
            ? panel.panel_config.bar_data.data_points
            : dataLimit
        }`,
        bp
      );
      // console.log(data);

      setSliderLvlVal({
        start: 1,
        end: data.total_pages,
      });

      let bar_data_arr = [];
      data = data.data;

      let response = Object.keys(data);

      for (let i = 0; i < response.length; i++) {
        // // console.log(Object.keys(data)[i])  // OBJECT'S NAME/KEY
        // // console.log(Object.values(data)[i])  // OBJECT'S VALUE
        if (panel.panel_config.bar_data.is_three_dym) {
          let trace = {
            x: Object.values(data)[i].z,
            y: Object.values(data)[i].x,
            z: Object.values(data)[i].y,
            // mode: 'lines+markers',
            mode:
              Object.values(data)[i].type === "scatter" ? "markers" : "lines",
            name: Object.keys(data)[i],
            opacity: 1,
            marker: {
              // color: '#bcbd22',
              color: "#" + pieColorList[i],
              colorscale: "Greens",
              size: 2,
              symbol: "circle",
              cmin: -20,
              cmax: 50,
              line: {
                // color: "rgb(0,0,0)",
                color: "#" + lineColorList[i],
                width: 1,
              },
            },
            line: {
              color: "#" + lineColorList[i],
              width: 1.5,
            },
            type: "scatter3d",
            // plot_bgcolor: 'rgb(12,163,135)',
            // bgcolor: 'cyan',
            // hovertemplate: 'y:%{y:.2f}' + '<br>z:%{z:.2f}<br>',
            hovertemplate:
              // "<b>%{text}</b><br><br>" +
              `<b>${Object.keys(data)[i]}</b><br><br>` +
              `X: (${panel.panel_config.bar_data.y_axis_label})` +
              ": %{z}<br>" +
              `Y: (${panel.panel_config.bar_data.z_axis_label})` +
              ": %{x}<br>" +
              `Z: (${panel.panel_config.bar_data.x_axis_label})` +
              ": %{y}<br>" +
              "<extra></extra>",
          };
          bar_data_arr.push(trace);
          // // console.log(bar_data_arr)
        } else {
          let bc = {
            // type: "scattergl",
            // mode: "markers",
            // marker: {
            //   color : 'rgb(152, 0, 0)',
            //   line: {
            //     width: 1,
            //     color: 'rgb(0,0,0)'}
            // },
            // x: X,
            // y: Y
            x:
              panel.panel_config.bar_data.bar_plot_type === "Vertical"
                ? Object.values(data)[i].x
                : Object.values(data)[i].y,
            y:
              panel.panel_config.bar_data.bar_plot_type === "Vertical"
                ? Object.values(data)[i].y
                : Object.values(data)[i].x,
            orientation:
              panel.panel_config.bar_data.bar_plot_type === "Vertical"
                ? "v"
                : "h",
            name: Object.keys(data)[i],
            // fill: 'tozeroy',
            type: Object.values(data)[i].type,
            mode: Object.values(data)[i].type === "scatter" ? "markers" : null,
            textposition: "auto",
            opacity: 0.7,
            marker: {
              // color: colorPick,
              line: {
                color: lineColorList[i],
                width: 1.5,
              },
            },
            line: {
              color: "#" + lineColorList[i],
              width: 1.5,
            },
            hovertemplate:
              `<b>${Object.keys(data)[i]}</b><br>` +
              "%{x}, %{y}<br>" +
              "<extra></extra>",
          };
          bar_data_arr.push(bc);
        }
      }
      setBarChartData(bar_data_arr);
      // console.log(bar_data_arr);
      setIsLoaderOn(false);
    } catch (error) {
      // console.log("catch", error);
      // if (error.response) {
      //   // console.log(error.response);
      //   // // console.log(error.response.data);
      //   // console.log(error.response.status);
      //   // // console.log(error.response.headers);
      // }
      // toastr.warning("Your internet connection is slow. Please reload this tab.", "Request timeout");
      toastr.warning(
        "Incorrect chart parameters, please try again.",
        "Wrong parameters"
      );
    }
  };

  // function gaussianRand() {
  //   var rand = 0;
  //   for (var i = 0; i < 6; i += 1) {
  //     rand += Math.random();
  //   }
  //   return (rand / 6)-0.5;
  // }

  // var X = [];
  // var    Y = [];
  // var    n = 1000000;
  //    var i;

  // for (i = 0; i < n; i += 1) {
  //   X.push(gaussianRand());
  //   Y.push(gaussianRand());
  // }

  // --------------- PIE CHART ---------------
  const createPieModalPlot = async (pageNo) => {
    let panel_chart = panel.panel_config.bar_data;

    let select_series_ = [];

    for (let i = 0; i < panel_chart.y_axis_categories.length; i++) {
      if (panel_chart.y_axis_categories[i].group_by) {
        select_series_.push(panel_chart.y_axis_categories[i].group_by);
      }
    }
    // BAR MODAL PAYLOAD
    let bp = {
      dataset_type: panelFieldInfo.panel_type,
      operation: panel.panel_config.bar_data.bar_group_operation,
      select_x: [panel.panel_config.bar_data.bar_group_variable],
      select_series: select_series_,
      filtering: panel.panel_config.bar_data.selected_filter_column_name
        ? {
            col_name:
              panel.panel_config.bar_data.selected_filter_column_name.col_name,
            value: panel.panel_config.bar_data.selected_filter_col_value,
          }
        : null,
    };
    // console.log(bp);
    // ---------------------------------------
    setIsLoaderOn(true);
    try {
      let { data } = await request.post(
        `/api/pie/chart/${chart.guid}/stats/?page=${pageNo}&limit=${
          panel.panel_config.bar_data.data_points
            ? panel.panel_config.bar_data.data_points
            : dataLimit
        }`,
        bp
      );
      // // console.log(data)

      setSliderLvlVal({
        start: 1,
        end: data.total_pages,
      });

      let bar_data_arr = [];
      data = data.data;
      let response = Object.keys(data);
      // console.clear()
      updatePieGrid(response.length);
      // // console.log(pieGrid)
      for (let i = 0; i < response.length; i++) {
        let bc = {
          labels: Object.values(data)[i].x,
          values: Object.values(data)[i].y,
          name: Object.keys(data)[i],
          type: "pie",
          marker: {
            colors: pieColorList,
          },
          // domain: {
          //   row: i,
          //   column: 0,
          // },
          // domain: {
          //   row: i < 2 ? 0 : 1,
          //   column: i,
          // },
          domain: updateDomainRowColumn(response.length, i),
          hoverinfo: "label+percent+name",
          textinfo: "percent",
          // hole: .4,
        };
        bar_data_arr.push(bc);
      }
      setBarChartData(bar_data_arr);
      setIsLoaderOn(false);
      // // console.log(bar_data_arr)
      // // console.log(pieGrid)
    } catch (error) {
      // console.log("catch", error);
      // toastr.warning("Request timeout", "Your internet connection is slow.");
      toastr.warning(
        "Incorrect chart parameters, please try again.",
        "Wrong parameters"
      );
    }
  };

  const updatePieGrid = (res_length) => {
    // // console.log(res_length)
    if (res_length == 1) {
      setPieGrid({ rows: 1, columns: 1 });
    } else if (res_length == 2) {
      setPieGrid({ rows: 1, columns: 2 });
    } else if (res_length > 2 && res_length <= 4) {
      setPieGrid({ rows: 2, columns: 2 });
    } else if (res_length > 4 && res_length <= 6) {
      setPieGrid({ rows: 2, columns: 3 });
    }
  };

  const updateDomainRowColumn = (res_length, i) => {
    if (i == 0) return { row: 0, column: 0 };
    else if (i == 1) return { row: 0, column: 1 };
    else if (i == 2) return { row: 1, column: 0 };
    else if (i == 3) return { row: 1, column: 1 };
    // else if(i == 4) return {row: 2, column: 0}
    // else if(i == 5) return {row: 2, column: 1}
    // else if(i == 6) return {row: 2, column: 2}
  };

  const getPlotType = (type) => {
    switch (type) {
      case "line":
        return "line";
      case "bar":
        return "bar";
      case "scatter":
        return "scatter";
      case "line_3D":
        return "scatter3d";
    }
  };

  // --------------- MESH COLOR SCALE ---------------
  // const getMeshColorScale = (clr_str) => {
  //   switch (clr_str) {
  //     case "blylrd":
  //     case "rdylbl":
  //       return [
  //         [0, "rgb(0,0,255)"],
  //         [0.5, "rgb(255,255,0)"],
  //         [1, "rgb(255, 0, 0)"],
  //       ];
  //     case "grylrd":
  //     case "rdylgr":
  //       return [
  //         [0, "rgb(1,106,56)"],
  //         [0.5, "rgb(255,255,0)"],
  //         [1, "rgb(255, 0, 0)"],
  //       ];
  //     default:
  //       return [
  //         [0, "rgb(0,0,255)"],
  //         [0.5, "rgb(255,255,0)"],
  //         [1, "rgb(255, 0, 0)"],
  //       ];
  //   }
  // };

  // const getMeshColorScaleIsReverse = (clr_str) => {
  //   switch (clr_str) {
  //     case "rdylbl":
  //       return true;
  //     case "rdylgr":
  //       return true;
  //     default:
  //       return false;
  //   }
  // };

  const getlayoutByChartType = (type) => {
    switch (type) {
      case "line":
        if (panel.panel_config.bar_data.chart_type_api_sep === "mesh") {
          return generateMeshChartLayout(chart, panel.panel_config.bar_data);
        } else
          return generateStackedBarColumnChartLayout(
            chart,
            panel.panel_config.bar_data
          );
      case "bar":
        return generateStackedBarColumnChartLayout(
          chart,
          panel.panel_config.bar_data
        );
      case "pie":
        return generatePieChartLayout(panel.panel_config, pieGrid);
      default:
        break;
    }
  };

  const getChartType = (type) => {
    if (type === "area_line") return "line";
    else if (type === "area_scatter") return "scatter";
    else return type;
  };

  const getPlot = () => {
    if (panel.panel_config.bar_data.is_three_dym) {
      return (
        <Plot
          data={barChartData}
          layout={generate3DLineChartLayout(panel.panel_config.bar_data)}
          // config={{displayModeBar: true}}
          useResizeHandler={true}
          className="wh-100"
        />
      );
    } else {
      if (
        panel.panel_config.bar_data.chart_type_api_sep ===
        "mapbox_density_heatmap"
      ) {
        // console.log(panel);
        // console.log(barChartData);
        // console.log(mapboxLayout);
        return (
          <Plot
            data={barChartData}
            layout={
              mapboxLayout
                ? mapboxLayout
                : {
                    mapbox: {
                      center: {
                        lon: 90,
                        lat: 25,
                      },
                      style: panel.panel_config.bar_data.chart_config.base_map,
                      zoom: panel.panel_config.bar_data.chart_config.zoom.value,
                    },
                    coloraxis: {
                      colorscale: "Viridis",
                    },
                    // title: {
                    //   text: "Earthquake Magnitude"
                    // },
                    width: panel.panel_config.full_screen
                      ? window.innerWidth - 10
                      : panel.panel_config.defaultPosition.width - 10,
                    height: panel.panel_config.full_screen
                      ? window.innerHeight - 10
                      : panel.panel_config.defaultPosition.height - 30,
                    margin: {
                      t: 30,
                      b: 0,
                    },
                    // mapbox_bounds: {
                    //   east: 90,
                    //   north: 50,
                    //   south: 30,
                    //   west: 20
                    // }
                  }
            }
            config={{
              mapboxAccessToken: process.env.REACT_APP_MAPBOX_API,
            }}
            useResizeHandler={true}
            className="wh-100"
          />
        );
      } else {
        return (
          <>
            {/* {panel.panel_config.bar_data.chart_type_api_sep} */}
            {panel.panel_config.bar_data.chart_type_api_sep === "mesh" ? (
              <GenerateMeshPlot chart={chart} panel={panel} />
            ) : (
              <Plot
                ref={plotRef}
                data={barChartData}
                layout={getlayoutByChartType(
                  panel.panel_config.bar_data.chart_type
                )}
                config={
                  panel.panel_config.bar_data.chart_type_api_sep === "mesh"
                    ? generateMeshChartConfig()
                    : {}
                }
                // frames={{meshFrame}}
                useResizeHandler={false}
                className="wh-100"
              />
            )}
          </>
        );
      }
    }
  };

  const getPaginationDesign = () => {
    if (
      panel.panel_config.bar_data.chart_type_api_sep !==
        "mapbox_density_heatmap" &&
      panel.panel_config.bar_data.chart_type_api_sep !== "mesh" &&
      sliderLvlVal.end > 1
    ) {
      return (
        <div className="plot__chartdata_value--block chart-input-range">
          <div className="plot__chartdata_value--flex">
            <span>{sliderLvlVal.start}</span>
            <span>{sliderVal}</span>
            <span>{sliderLvlVal.end}</span>
          </div>
          <div className="d-flex align-items-center justify-content-around">
            <span
              style={{ display: "inline-block", width: "5%" }}
              onClick={(e) => {
                e.stopPropagation();
                // console.log(sliderVal);
                if (sliderVal > sliderLvlVal.start) {
                  setSliderVal(parseInt(sliderVal) - 1);
                  setChartPage(parseInt(sliderVal) - 1);
                  setChartPageFlag(true);
                }
              }}
            >
              {getIconFromList("ARROW_RIGHT_ICON_PAGINATION", {
                cursor: "pointer",
                transform: "rotate(180deg)",
              })}
            </span>
            <input
              type="range"
              className="form-range"
              min={sliderLvlVal.start}
              max={sliderLvlVal.end}
              step="1"
              value={sliderVal}
              // style={{ width: "100%" }}
              style={{ width: "85%" }}
              onChange={(e) => {
                e.stopPropagation();
                setIsLoaderOn(true);
                setSliderVal(e.target.value);
                // console.log(e.target.value);
                setChartPage(e.target.value);
                setChartPageFlag(true);
                // e.target.addEventListener(
                //   "click",
                //   function (event) {
                //     event.stopImmediatePropagation();
                //     // console.log(event.target.value)
                //     setChartPage(event.target.value);
                //   },
                //   false
                // );
              }}
            ></input>
            <span
              style={{
                display: "inline-block",
                textAlign: "right",
                width: "5%",
              }}
              onClick={(e) => {
                e.stopPropagation();
                // console.log(sliderVal);
                if (sliderVal < sliderLvlVal.end) {
                  setSliderVal(parseInt(sliderVal) + 1);
                  setChartPage(parseInt(sliderVal) + 1);
                  setChartPageFlag(true);
                }
              }}
            >
              {getIconFromList("ARROW_RIGHT_ICON_PAGINATION", {
                cursor: "pointer",
              })}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      {isLoaderOn ? (
        <div className="chart-loader"></div>
      ) : (
        <>
          {getPlot()}
          {getPaginationDesign()}
        </>
      )}
    </React.Fragment>
  );
};

export default SocketChartBarGroup;
