import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { getIconFromList } from "../../../../static/IconServe";
import { useDispatch, useSelector } from "react-redux";
import { getProjectAccess } from "../../../../actions/customPanelModify";
import CustomPannelDashboard from "../../../_CustomPanel_2";

const WebGLDashboard = () => {
  const dispatch = useDispatch();
  const [isVisable, setIsVisible] = useState(false);

  window.activateWebGLDashboard = async (panelDataJsonString) => {
    if (!panelDataJsonString) return;
    window.SetWebGLKeyboardCaptureStatus(0);
    let dt = JSON.parse(panelDataJsonString);
    // console.log(dt)
    dispatch({
      type: "SET_CUSTOM_PANEL_AS_INITIAL_STATE",
    });
    if (dt.type === "project") {
      dispatchCustomPanelData(
        dt.type,
        dt.project_guid,
        dt.project_guid,
        dt.location
      );
    }
    if (dt.type === "asset") {
      dispatchCustomPanelData(
        dt.type,
        dt.asset_guid,
        dt.project_guid,
        dt.location
      );
    }
    if (dt.type === "pipeline") {
      dispatchCustomPanelData(
        dt.type,
        dt.pipeline_guid,
        dt.project_guid,
        dt.location
      );
    }
    dispatch({
      type: "UPDATE_WEBGL_DASHBOARD_FLAG_STATUS",
      payload: true,
    });
    setIsVisible(true);
  };

  const dispatchCustomPanelData = (
    panel_type,
    panel_type_guid,
    project_guid,
    location
  ) => {
    if (panel_type && panel_type_guid && project_guid) {
      dispatch({
        type: "SET_PANEL_FIELD_INFO",
        payload: {
          panel_type: panel_type,
          panel_type_guid: panel_type_guid,
          project_guid: project_guid,
          location: location,
        },
      });
      dispatch(getProjectAccess(project_guid));
    }
  };

  window.deactivateWebGLDashboard = () => {
    if (isVisable) {
      dispatch({
        type: "UPDATE_WEBGL_DASHBOARD_FLAG_STATUS",
        payload: false,
      });
      document.getElementById("webgl-WebGLDashboard").click();
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (isVisable) {
      setTimeout(() => {
        document.getElementById("WebGLDashboard").parentNode.className =
          "ReactModal__Overlay ReactModal__Overlay--after-open __transparent_overlay_";
      }, 100);
    }
  }, [isVisable]);

  return (
    <ReactModal
      isOpen={isVisable}
      id="WebGLDashboard"
      overlayClassName="WebGLDashboard_FLOATING"
      style={{
        top: "50px !important",
        // overlay: {
        //   inset: "none",
        //   backgroundColor: "transparent",
        //   //width: "400px",
        //   position: "fixed !important",
        //   right: "0",
        //   top: "0",
        // },
        content: {
          maxWidth: "100% !important",
          maxHeight: "100% !important",
          border: "none",
          background: "transparent",
          height: "60px !important",
          overflow: "hidden",
        },
      }}
    >
      <div className="modal-dialog">
        <div
          className="modal-content webgldb"
          style={{
            border: "none",
            minHeight: "200px",
            backgroundColor: "rgba(0, 0, 0, 0%) !important",
          }}
        >
          <div
            className="modal-header"
            style={{
              justifyContent: "flex-end",
              padding: "0.2rem",
              border: "none",
              display: "none",
            }}
          >
            <span
              id="webgl-WebGLDashboard"
              onClick={(e) => {
                e.preventDefault();
                setIsVisible(false);
              }}
            >
              {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
            </span>
          </div>
          <div
            className="modal-body p-0"
            style={{
              position: "fixed",
              height: "calc( 100vh - 50px)",
              bottom: "0",
              overflow:"hidden",
              maxHeight:"none",
            }}
          >
            <CustomPannelDashboard />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default WebGLDashboard;
