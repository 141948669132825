import React, { useEffect, useState } from "react";
// import { useHis } from "react-router-dom"
import TopHeader from "../../components/TopHeader";
import ContainerFluid from "../../components/ContainerFluid";
import CopyRightFooter from "../../components/CopyRightFooter";
import { useNavigate } from "react-router-dom";
import request from "../../utils/request";
import toastr from "toastr";

const Support = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [user_name, setUserName] = useState("");
  const [user_phone, setPhone] = useState("");
  const [user_message, setMessage] = useState("");
  const [support_category, setSupportCategory] = useState("User Management");

  useEffect(() => {
    const isAuth = !!localStorage.getItem("access_token");
    if (!isAuth) {
      navigate("/");
      // // console.log("----------not-----authorized-----------");
    }
  }, [navigate]);

  return (
    <ContainerFluid>
      <TopHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 mt-4 col-lg-6 col-xl-7 col-sm-12 p-0">
            <div className="sup-feed_form container mt-4 ml-5 mr-5 mx-auto w-75">
              <form
                id="support_form"
                onSubmit={async (e) => {
                  e.preventDefault();
                  const payload = {
                    email: email,
                    name: user_name,
                    phone: user_phone,
                    category: support_category,
                    msg: user_message,
                  };

                  await request
                    .post("/api/misc/support_email/", payload)
                    .then((resp) => {
                      // // console.log(resp);
                      toastr.success(
                        "Our Support Team Will Contact Soon",
                        "Email Sent..."
                      );
                    })
                    .catch((err) => {
                      // console.log(err.response);
                      if (err.response.data.msg) {
                        toastr.error(
                          err.response.data.msg,
                          "Describe your issues"
                        );
                      }
                      if (err.response.data.name) {
                        toastr.error(err.response.data.name, "Name");
                      }
                      if (err.response.data.phone) {
                        toastr.error(err.response.data.phone, "Phone");
                      }
                      if (err.response.data.email) {
                        toastr.error(err.response.data.email, "Email");
                      }
                    });
                }}
              >
                <h3>Asset Twin Support</h3>
                <div className="form-group">
                  <label htmlFor="supportInputEmail">Email</label>

                  <input
                    type="email"
                    className="form-control"
                    name="user_mail"
                    id="supportInputEmail"
                    aria-describedby="emailHelp"
                    placeholder="Your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <small id="emailHelp" className="form-text text-light">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="supportInputName">Tell us your Name</label>
                  <input
                    name="user_name"
                    type="text"
                    className="form-control"
                    id="supportInputName"
                    placeholder="Your name"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="supportInputNumber">Phone No</label>
                  <input
                    name="user_phone"
                    type="number"
                    className="form-control"
                    id="supportInputNumber"
                    placeholder="Your number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Category</label>
                  <select
                    name="support_category"
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={support_category}
                    onChange={(e) => setSupportCategory(e.target.value)}
                  >
                    <option value="User Management">User Management</option>
                    <option value="Twin System">Twin System</option>
                    <option value="Designs">Designs</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="supportDescribeMessage">
                    Describe your issues
                  </label>
                  <textarea
                    name="user_message"
                    type="text"
                    className="form-control"
                    id="supportDescribeMessage"
                    placeholder="Your message"
                    defaultValue={""}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="text-right mt-4 mb-5">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-md-7 col-lg-6 col-xl-5 col-sm-12 p-0 d-flex justify-content-center">
            <div className="contact_ui">
              <img
                className="img-fluid"
                src={require("../../static/assets/img/Oil-Gas-Industry.jpg")}
                alt="test"
              />
              <div className="carousel-caption_custom">
                <div className="address_ui">
                  <i className="fa fa-map-marker" />
                  <p className="ml-2">
                    <strong>Address</strong>
                    <br />
                    11251 Northwest Frwy, STE: 310, Houston, TX-77092
                  </p>
                </div>
                <div className="phone_ui">
                  <i className="fa fa-phone" />
                  <p className="ml-2">
                    <strong>Lets talk</strong>
                    <br />
                    Ph: +1 832 243 5070, +1 713 884 6390
                  </p>
                </div>
                <div>
                  <i className="fa fa-envelope" />
                  <p className="ml-2">
                    <strong>General Support</strong>
                    <br />
                    email: info@idare.io
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <CopyRightFooter />
    </ContainerFluid>
  );
};

export default Support;
