import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import G_Loader from "../../G_Loader";
import PanelModals from "../modals";
import Charts from "./Charts";
import DataTable from "./DataTable";
import ImagesSingle from "./Images_Single";
import Indicators from "./Indicators";
import Map from "./Map";
import Specifications from "./Specification";
import TextIndicators from "./TextIndicators";

const Panels = (props) => {
  const dispatch = useDispatch();
  const panelList = useSelector( (state) => state.customPanelModify.ACTIVE_TAB_PANEL_LIST );
  const requestLoader = useSelector( (state) => state.customPanelModify.requestLoader );

  const getPanelRender = (panel) => {
    switch (panel.panel_config.panelType) {
      case "INDICATOR":
        return <Indicators panel={panel} />;
      case "TEXT INDICATOR":
        return <TextIndicators panel={panel} />;
      case "DATA_TABLE":
        return <DataTable panel={panel} />;
      case "MAP":
        return <Map panel={panel} />;
      case "SPECIFICATION":
        return <Specifications panel={panel} />;
      case "CHART":
        return <Charts panel={panel} />;
      case "SINGLE_IMAGE":
        return <ImagesSingle panel={panel} />;
      default:
        return;
    }
  };

  return (
    <div className="panel_content_ui">
      <PanelModals />
      {panelList.length > 0 ? (
        <>
        {panelList.map((panel, index) => (
          <div key={index}>{getPanelRender(panel)}</div>
        ))}
        </>
      ) : (
        <>
          {requestLoader ? <G_Loader style={{position: "fixed", left: "48%", top: "48%", zIndex: "9999"}} /> : <h4 className="m-0 no-panel-msg">No panel Found</h4>}
        </>
      )}
      {requestLoader ? <G_Loader style={{position: "fixed", left: "48%", top: "48%", zIndex: "9999"}} /> : ""}
    </div>
  );
};
export default Panels;
