import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatasetColInfo,
  getDatasetColumns,
  updateCustomDashboardPanel,
} from "../../../../../actions/customPanelModify";
import ReactRangeInput from "../../../../Range";

const MeshRange = ({ panel, setReceivedProps, filterMinMaxVal }) => {
  const [val, setVal] = useState(
    panel && panel.panel_config.bar_data.chart_config.filter_by
      ? [
          panel.panel_config.bar_data.chart_config.filter_by.range.min,
          panel.panel_config.bar_data.chart_config.filter_by.range.max,
        ]
      : [0, 10]
  );

  const [valNonFilter, setValNonFilter] = useState(
    panel && filterMinMaxVal ? [filterMinMaxVal.z.min, filterMinMaxVal.z.max] : [0, 10]
  );

  return (
    <>
      {
        panel.panel_config.bar_data.chart_config.filter_by ?
        <div style={{width: "90%", margin: "20px auto 0"}}>
          <ReactRangeInput
            step={panel ? panel.panel_config.bar_data.chart_config.filter_by.range.max / 1000 : 1}
            min={panel ? panel.panel_config.bar_data.chart_config.filter_by.range.min : 0}
            max={panel ? panel.panel_config.bar_data.chart_config.filter_by.range.max : 10}
            // min={panel ? panel.panel_config.bar_data.filter_info.range.min : 0}
            // max={panel ? panel.panel_config.bar_data.filter_info.range.max : 10}
            selectedFilteredValue={panel ? val : [3, 6]}
            setSelectedFilteredValue={(val) => {
              setReceivedProps({axis: panel.panel_config.bar_data.chart_config.filter_by.col.col_name, val: val});
              setVal(val);
            }}
          />
        </div> :
        <div style={{width: "90%", margin: "20px auto 0"}}>
          <ReactRangeInput
            step={filterMinMaxVal ? filterMinMaxVal.z.max / 1000 : 1}
            min={filterMinMaxVal ? filterMinMaxVal.z.min : 0}
            max={filterMinMaxVal ? filterMinMaxVal.z.max : 10}
            selectedFilteredValue={filterMinMaxVal ? valNonFilter : [3, 6]}
            setSelectedFilteredValue={(val) => {
              setReceivedProps({axis: "z", val: val});
              setValNonFilter(val);
            }}
          />
        </div>
      }
    </>
  );
};
export default MeshRange;
