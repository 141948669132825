import React, { useState } from "react";

const ProfileContentCard = () => {
  const [ToggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";
  return (
    <div className="user__panel--col user__panel--content col-md-8">
      <div className="card card-body user__panel--card">
        <div className="tab-content-header">
          <h1 className="tab-content-title">Details about Jone Doe</h1>
        </div>
        <div className="tabs__container">
          <ul className="nav nav-tabs nav-justified user__panel--tab">
            <li
              className={`nav-link ${getActiveClass(1, "active")}`}
              onClick={() => toggleTab(1)}
            >
              Profile
            </li>
            <li
              className={`nav-link ${getActiveClass(2, "active")}`}
              onClick={() => toggleTab(2)}
            >
              Subscription
            </li>
            <li
              className={`nav-link ${getActiveClass(3, "active")}`}
              onClick={() => toggleTab(3)}
            >
              Field Account
            </li>
          </ul>
          <div className="content-container">
            <div
              className={`user__panel--tab-content ${getActiveClass(
                1,
                "active-content"
              )}`}
            >
              <div className="user__panel--tab-content-block">
                <table className="table table-dark user__panel--table">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Name
                      </th>
                      <td>Jone Doe</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Designation
                      </th>
                      <td>Front end Developer</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Email
                      </th>
                      <td>jone@mail.com</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Address
                      </th>
                      <td>
                        2nd Floor, House 97/A Alokdia, Road 25, Block A, Dhaka
                        1213
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Location
                      </th>
                      <td>23°45°36.6°N 90°26</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Subscription Plan
                      </th>
                      <td>Premium</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className={`user__panel--tab-content ${getActiveClass(
                2,
                "active-content"
              )}`}
            >
              <div className="user__panel--tab-content-block">
                <table className="table table-dark user__panel--table">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Plan Name
                      </th>
                      <td>
                        Premium{" "}
                        <small>
                          <a href="#!" className="text-danger">
                            Upgrade Plan
                          </a>
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Total Space
                      </th>
                      <td>50GB</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Data Used
                      </th>
                      <td>40GB</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "140px" }}>
                        Free Space
                      </th>
                      <td>10GGB</td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="mb-2 mt-5">Purchase History</h4>
                <div className="table-responsive">
                  <table className="table table-dark user__panel--table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Plan Name</th>
                        <th>Storage/Price</th>
                        <th>Status(Used/Total)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>20 January, 2022</td>
                        <td>Premium 10-BNK</td>
                        <td>10GB/10USD</td>
                        <td>8GB/10GB</td>
                      </tr>
                      <tr>
                        <td>20 December, 2021</td>
                        <td>Basic to Premium</td>
                        <td>20GB/100USD = 10GB Free</td>
                        <td>30GB/30GB</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className={`user__panel--tab-content ${getActiveClass(
                3,
                "active-content"
              )}`}
            >
              <div className="user__panel--tab-content--block">
                <h4 className="mb-2 mt-5">Project Storage History</h4>
                <div className="table-responsive">
                  <table className="table table-dark user__panel--table">
                    <thead>
                      <tr>
                        <th>Project Info</th>
                        <th>Storage Status(Used/Total)</th>
                        <th>Share Info</th>
                        <th>General Update Info</th>
                        <th>Upgrade</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <ul className="user__panel--table__list">
                            <li>Project: Health</li>
                            <li>Solution: Dangee</li>
                            <li>Type: Regression</li>
                            <li>Version: 1</li>
                          </ul>
                        </td>
                        <td>
                          <ul className="user__panel--table__list">
                            <li>Total Storage: 10GB</li>
                            <li>Used Space: 8GB</li>
                            <li>Free Space 2GB</li>
                            <li>Version: 1</li>
                          </ul>
                        </td>
                        <td>
                          <ul className="user__panel--table__list">
                            <li>Basic Viewer: 4</li>
                            <li>Premium Viewer: 3</li>
                            <li>Editor: 2</li>
                          </ul>
                        </td>
                        <td>
                          <ul className="user__panel--table__list">
                            <li>Created at: 15 January, 2022</li>
                            <li>Last Updated: 25 January, 2022</li>
                          </ul>
                        </td>
                        <td>
                          <div className="card card-body user__panel--table__upgrade-card">
                            <ul className="user__panel--table__upgrade--list">
                              <li>
                                <span>Premium</span>
                                <span>$10/m</span>
                              </li>
                              <li>
                                <span>First year</span>
                                <span>20% off</span>
                              </li>
                              <li>
                                <span>Second year</span>
                                <span>40% off</span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContentCard;
