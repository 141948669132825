const initialValue = {
  asset: null,
  pipeline: null,
  total_pipelines: null,
  assetStats: null,
  cardItems: [
    { title: "Floating Assets", value: "85" },
    { title: "Manifolds", value: "45" },
    { title: "Pipelines", value: "55" },
    { title: "Other Lines", value: "25" },
    { title: "Storage Units", value: "74" },
    { title: "Storage", value: "44" },
    { title: "Storage", value: "11" },
    { title: "Storage", value: "52" },
  ],
  tabList: [
    { name: "Summary", id: "tab-1" },
    { name: "Asset Basic", id: "tab-2" },
    { name: "Asset Specs", id: "tab-3" },
    { name: "Line Basic", id: "tab-4" },
    { name: "Line Specs", id: "tab-5" },
  ],
  assetTableHeader: [
    // { name: "Serial No" },
    { name: "Asset Tag" },
    { name: "Asset Name" },
    { name: "Asset Type" },
    { name: "Asset category" },
    { name: "Asset 3D Module" },
    { name: "Latitude" },
    { name: "Longitude" },
    { name: "Altitude" },
    { name: "Easting (X)" },
    { name: "Northing (Y)" },
    { name: "Elevation (Z)" },
    { name: "Created Date" },
    { name: "Updated Date" },
  ],
  assetSpecs: null,
  assetSpecsHeaders: null,
  pipelineSpecs: null,
  pipelineSpecsHeaders: null,
  types: null,
  total_pipelines_pages: null,
  total_assets_pages: null,
};

export default function assetsData(state = initialValue, action) {
  switch (action.type) {
    case "SET_DASHBOARD_DATA":
      // // console.log(action.payload);
      return {
        ...state,
        asset: action.payload.asset.data,
        pipeline: action.payload.pipelines.data,
        assetStats: action.payload.assetStats.modules,
        totalModules: action.payload.assetStats.total_assets,
        total_pipelines: action.payload.assetStats.total_pipelines,
        types: action.payload.assetStats.types,
        total_pipelines_pages: action.payload.pipelines.total_pages,
        total_assets_pages: action.payload.asset.total_pages,
      };
    case "UPDATE_PIPELINE_DASHBOARD_DATA":
      // // console.log(action.payload);
      return {
        ...state,
        pipeline: action.payload.data,
      };
    case "UPDATE_ASSET_DASHBOARD_DATA":
      return {
        ...state,
        asset: action.payload.data,
      };
    case "SET_ASSET_SPECS":
      // // console.log(action.payload);
      return {
        ...state,
        assetSpecs: action.payload["data"] ? action.payload["data"] : [],
        assetSpecsHeaders: action.payload["Headers"]
          ? action.payload["Headers"]
          : [],
      };
    case "SET_PIPELINE_SPECS":
      return {
        ...state,
        pipelineSpecs: action.payload["data"] ? action.payload["data"] : [],
        pipelineSpecsHeaders: action.payload["Headers"]
          ? action.payload["Headers"]
          : [],
      };
    default:
      return state;
  }
}
