import React, { useState } from "react";
import ResourceViewer from "../../../CustomPanel_old_not_workable/PanelsBody/ResourceDocs/Viewer";

const WebGLResourceViewer = () => {
  const [isViewerOpen, setIsViewerModal] = useState(false);
  const [modalFile, setModalFile] = useState(null);

  window.openWebglResourceViewer = (name, url) => {
    setModalFile({
      name: name,
      doc_url: url,
    });
    setIsViewerModal(true);
  };

  return (
    <>
      {modalFile && (
        <ResourceViewer
          isViewerOpen={isViewerOpen}
          setIsViewerModal={setIsViewerModal}
          file={modalFile}
        />
      )}
    </>
  );
};

export default WebGLResourceViewer;
