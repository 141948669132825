import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postSubscriptionBill } from "../../actions/billingAction";
import ContainerFluid from "../../components/ContainerFluid";
import TopHeader from "../../components/TopHeader";
import "./style.css";
import toastr from "toastr";

const ASSET_PER_CREDIT = process.env.REACT_APP_ASSET_PER_CREDIT;
const STORAGE_PRE_CREDIT = process.env.REACT_APP_STORAGE_PRE_CREDIT;
const USD_CREDIT_PER_MONTH = process.env.REACT_APP_USD_CREDIT_PER_MONTH;

const BillPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get("type");
  const packName = queryParams.get("pack");
  const assets = queryParams.get("assets");
  const storage = queryParams.get("storage");
  const total = queryParams.get("ttl");

  const [cardNo, setCardNo] = useState(null);
  const [cardExpData, setCardExpData] = useState(null);
  const [cardCVV, setCardCVV] = useState(null);
  const [cardHolderName, setCardHolderName] = useState(null);

  const onSubmitClickHandler = () => {
    let data = {
      package: parseInt(type),
      card_no: cardNo,
      no_of_asset: parseInt(assets),
      storage_capacity: parseInt(storage),
    };
    // // console.log(data)
    dispatch(postSubscriptionBill(data)).then(res => {
      // // console.log(res.data);
      toastr.success("Payment Success");
      navigate("/billing");
    }).catch(err => {
      // console.log(err);
      toastr.error("Payment Error");
    });
  };

  return (
    <ContainerFluid>
      <TopHeader />
      <div className="container" id="bill-payment">
        <div className="row">
          {/* <div className="col-lg-4 mb-lg-0 mb-3">
            <div className="card p-3">
              <div className="img-box">
                <img
                  src="https://www.freepnglogos.com/uploads/visa-logo-download-png-21.png"
                  alt=""
                />
              </div>
              <div className="number">
                <label className="fw-bold" for="">
                  **** **** **** 1060
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <small>
                  <span className="fw-bold">Expiry date:</span>
                  <span>10/16</span>
                </small>
                <small>
                  <span className="fw-bold">Name:</span>
                  <span>Kumar</span>
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-lg-0 mb-3">
            <div className="card p-3">
              <div className="img-box">
                <img
                  src="https://www.freepnglogos.com/uploads/mastercard-png/file-mastercard-logo-svg-wikimedia-commons-4.png"
                  alt=""
                />
              </div>
              <div className="number">
                <label className="fw-bold">**** **** **** 1060</label>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <small>
                  <span className="fw-bold">Expiry date:</span>
                  <span>10/16</span>
                </small>
                <small>
                  <span className="fw-bold">Name:</span>
                  <span>Kumar</span>
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-lg-0 mb-3">
            <div className="card p-3">
              <div className="img-box">
                <img
                  src="https://www.freepnglogos.com/uploads/discover-png-logo/credit-cards-discover-png-logo-4.png"
                  alt=""
                />
              </div>
              <div className="number">
                <label className="fw-bold">**** **** **** 1060</label>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <small>
                  <span className="fw-bold">Expiry date:</span>
                  <span>10/16</span>
                </small>
                <small>
                  <span className="fw-bold">Name:</span>
                  <span>Kumar</span>
                </small>
              </div>
            </div>
          </div> */}
          <div className="col-12 mt-4">
            <div className="card p-3">
              <p className="mb-0 fw-bold h4">Payment Methods</p>
            </div>
          </div>
          <div className="col-12">
            <div className="card p-3">
              {/* <div className="card-body border p-0">
                <p>
                  <a
                    className="btn btn-primary w-100 h-100 d-flex align-items-center justify-content-between"
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="true"
                    aria-controls="collapseExample"
                  >
                    <span className="fw-bold">PayPal</span>
                    <span className="fab fa-cc-paypal"></span>
                  </a>
                </p>
                <div className="collapse p-3 pt-0" id="collapseExample">
                  <div className="row">
                    <div className="col-8">
                      <p className="h4 mb-0">Summary</p>
                      <p className="mb-0">
                        <span className="fw-bold">Product:</span>
                        <span className="c-green">: Name of product</span>
                      </p>
                      <p className="mb-0">
                        <span className="fw-bold">Price:</span>
                        <span className="c-green">:$452.90</span>
                      </p>
                      <p className="mb-0">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Atque nihil neque quisquam aut repellendus, dicta
                        vero? Animi dicta cupiditate, facilis provident
                        quibusdam ab quis, iste harum ipsum hic, nemo qui!
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="card-body border p-0">
                <p>
                  <a
                    className="btn btn-primary p-2 w-100 h-100 d-flex align-items-center justify-content-between"
                    // data-bs-toggle="collapse"
                    // href="#collapseExample"
                    // role="button"
                    // aria-expanded="true"
                    // aria-controls="collapseExample"
                  >
                    <span className="fw-bold">Credit Card</span>
                    {/* <span className="">
                      <span className="fab fa-cc-amex"></span>
                      <span className="fab fa-cc-mastercard"></span>
                      <span className="fab fa-cc-discover"></span>
                    </span> */}
                  </a>
                </p>
                <div className="collapse show p-3 pt-0" id="collapseExample">
                  <div className="row">
                    <div className="col-lg-5 mb-lg-0 mb-3">
                      <p className="h4 mb-2">Summary</p>
                      <p className="mb-0">
                        <span className="fw-bold">Subscription Plan:</span>
                        <span className="c-green">
                          : {packName}
                        </span>
                      </p>
                      <p className="mb-0">
                        <span className="fw-bold">No of Assets:</span>
                        <span className="c-green">: {assets}</span>
                      </p>
                      <p className="mb-0">
                        <span className="fw-bold">Data Storage:</span>
                        <span className="c-green">: {storage}</span>
                      </p>
                      <p className="mb-0">
                        <span className="fw-bold">Total Credit:</span>
                        <span className="c-green">
                          : {parseInt(assets) + parseInt(storage) / STORAGE_PRE_CREDIT}
                        </span>
                      </p>
                      <p className="mb-0">
                        <span className="fw-bold">Total Price:</span>
                        <span className="c-green">: ${total}</span>
                      </p>
                      {/* <p className="mb-0">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Atque nihil neque quisquam aut repellendus, dicta
                        vero? Animi dicta cupiditate, facilis provident
                        quibusdam ab quis, iste harum ipsum hic, nemo qui!
                      </p> */}
                    </div>
                    <div className="col-lg-7">
                      <form
                        action=""
                        className="form"
                        onSubmit={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          document.getElementById("payment-submit-btn_").disabled = true;
                          onSubmitClickHandler();
                        }}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="form__div">
                              <input
                                type="number"
                                className="form-control"
                                placeholder=" "
                                id="bill_payment_card_no"
                                onChange={(e) => {
                                  setCardNo(e.target.value);
                                }}
                              />
                              <label
                                for="bill_payment_card_no"
                                className="form__label"
                              >
                                Card Number
                              </label>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form__div">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=" "
                                id="bill_payment_card_exp_date"
                                onChange={(e) => {
                                  setCardExpData(e.target.value);
                                }}
                              />
                              <label
                                for="bill_payment_card_exp_date"
                                className="form__label"
                              >
                                MM / yy
                              </label>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form__div">
                              <input
                                type="number"
                                className="form-control"
                                placeholder=" "
                                id="bill_payment_card_cvv"
                                onChange={(e) => {
                                  setCardCVV(e.target.value);
                                }}
                              />
                              <label
                                for="bill_payment_card_cvv"
                                className="form__label"
                              >
                                cvv code
                              </label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form__div">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=" "
                                id="bill_payment_card_holder_name"
                                onChange={(e) => {
                                  setCardHolderName(e.target.value);
                                }}
                              />
                              <label
                                for="bill_payment_card_holder_name"
                                className="form__label"
                              >
                                name on the card
                              </label>
                            </div>
                          </div>
                          <div className="col-12">
                            <button className="btn btn-primary w-100" type="submit" id="payment-submit-btn_">
                              Sumbit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="btn btn-primary payment">Make Payment</div>
          </div> */}
        </div>
      </div>
    </ContainerFluid>
  );
};

export default BillPayment;
