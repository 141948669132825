exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ModalAT_ModalAT__3naSL {\n  position: relative;\n  width: 100%;\n  min-width: 250px;\n  max-width: 660px;\n  margin-left: auto;\n  margin-right: auto;\n}\n.ModalAT___content_block__2xFcl {\n  background-color: rgba(var(--secondary-dark-600-rgb), 0.95);\n  width: 100%;\n}\n.ModalAT___header__1rB06,\n.ModalAT___body__3m19d {\n  padding: 1rem;\n}\n.ModalAT___header__1rB06 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid rgba(255, 255, 255, 0.25);\n}\n.ModalAT___header_title__d0QJi {\n  margin-bottom: 0;\n}\n.ModalAT___close_btn__2gZJ- {\n  -webkit-user-select: none;\n          user-select: none;\n  border: 0;\n  background-color: transparent;\n  font-size: 1rem;\n  font-weight: normal;\n  line-height: 1.5;\n}\n.ModalAT___close_btn__2gZJ- svg {\n  fill: #fff;\n}\n\n", ""]);

// exports
exports.locals = {
	"ModalAT": "ModalAT_ModalAT__3naSL",
	"__content_block": "ModalAT___content_block__2xFcl",
	"__header": "ModalAT___header__1rB06",
	"__body": "ModalAT___body__3m19d",
	"__header_title": "ModalAT___header_title__d0QJi",
	"__close_btn": "ModalAT___close_btn__2gZJ-"
};