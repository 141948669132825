import React from "react";
import AssetSpecsViewer from "./AssetSpecsViewer";
import AssetSpecsEditor from "./AssetSpecsEditor";
import PipelineSpecsView from "./PipelineSpecsView";
import PipelineSpecsEditor from "./PipelineSpecsEditor";
import ChartSettings from "./ChartSettings";
import ComponentSpecsEditor from "./ComponentSpecsEditor";
import ComponentSpecsViewer from "./ComponentSpecsViewer";
import AnalysisNavigator from "./AnalysisNavigator";
import ComponentChart from "./ComponentChart";
import WebGLResourceViewer from "./ResourceViewer";
import AddDatasetWebGL from "./AddDataset";
import DataTableWebGL from "./DataTable";
import WebGLDashboard from "./WebGLDashboard";
// import FieldTree from "./FieldTree";
import RTMConsole from "./RTMConsole";

const SynchronizeModals = ({
  messages,
  currentUserName,
  rtcMembers,
  channelName,
  isHost,
  useRguid,
}) => {
  return (
    <React.Fragment>
      <AnalysisNavigator />
      <AssetSpecsViewer />
      <AssetSpecsEditor />
      <PipelineSpecsView />
      <PipelineSpecsEditor />
      <ChartSettings />
      <ComponentSpecsEditor />
      <ComponentSpecsViewer />
      <ComponentChart />
      {/* <FieldTree /> */}
      <WebGLResourceViewer />
      {/* <AddDatasetWebGL /> */}
      <DataTableWebGL />
      <WebGLDashboard />
      <RTMConsole
        messages={messages}
        currentUserName={currentUserName}
        rtcMembers={rtcMembers}
        channelName={channelName}
        isHost={isHost}
        useRguid={useRguid}
      />
    </React.Fragment>
  );
};

export default SynchronizeModals;
