export const generatePieChartLayout = (chart, parentComponent) => ({
  autosize: true,
  // margin: {
  //   // l: 50,
  //   // r: 50,
  //   b: 5,
  //   t: 5,
  //   // pad: 10,
  // },
  margin: {
    l: 50,
    r: 5,
    b: 10,
    t: 5,
    // pad: 5,
  },

  paper_bgcolor: "lightblue",
  plot_bgcolor: "#ddd",

  legend: {
    x: 1,
    y: 1,
    // xanchor: "right",
    // // xanchor: "center",
    orientation: "h", //To place the legend below chart
    font: {
      family: "Arial, sans-serif",
      size: 10,
      color: "grey",
    },
  },
  // title: {
  //   text: chart.chart_name,
  //   font: {
  //     family: "sans-serif",
  //     size: 12,
  //     color: "#fff",
  //   },
  //   x: 0.5,
  //   xanchor: "center",
  //   y: 0.98,
  //   yanchor: "top",
  //   pad: {
  //     // l: 50,
  //     // r: 50,
  //     // b: 5,
  //     // t: 25,
  //     // pad: 10,
  //   },
  // },
  xaxis: {
    automargin: true,
    zerolinecolor: "#fff",
    gridcolor: "#04CAE3",

    tickfont: {
      family: "sans-serif",
      size: 12,
      color: "#04CAE3",
    },
    zerolinecolor: "#fff",
    gridwidth: 1,
    title: {
      standoff: parentComponent === 'WEBGL_COMPONENT_CHART' ? 5 : 10, //Similar as Margin Top
      text: chart.x_axis_label,
      font: {
        family: "sans-serif",
        size: 12,
        color: "#fff",
      },
    },
  },
  yaxis: {
    automargin: true,
    zerolinecolor: "#fff",
    tickcolor: "rgba(255,0,0,0.75)",
    // tickwidth: 15,
    // zerolinewidth: 2,
    gridcolor: "#04CAE3",
    gridwidth: 1,
    tickfont: {
      family: "sans-serif",
      size: 12,
      color: "#04CAE3",
    },
    title: {
      text: chart.y_axis_label,
      font: {
        family: "sans-serif",
        size: 12,
        color: "#fff",
      },
    },
  },
});
