import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchAssetSpecs } from "../../../../actions/webglAssociatesActions";
import ReactModal from "react-modal";
import loader from "../../../../static/loader.gif";

const ChartSettings = ({ fetchAssetSpecs, requestPending, assetSpecs }) => {
  const [isOpen, setOpen] = useState(false);

  const handleChartSettings = (projectGuid, assetGuid) => {
    setOpen(!isOpen);
    fetchAssetSpecs(projectGuid, assetGuid);
  };
  window.handleChartSettings = handleChartSettings;

  // handleChartSettings("f6479a55-3ea7-4678-8cf7-3e4b959bbcbc","e2954c91-79dc-46ec-a6e9-27971e6f13cc")

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "transparent",
        },
        content: {
          position: "absolute",
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "40px",
          border: "none",
          background: "none",
          // overflow: "hidden",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
        },
      }}
    >
      {requestPending ? (
        <div id="chartSettings">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content_table">
              <div className="modal-header header-bg p-2 border-0">
                <h5 className="" id="exampleModalLabel">
                  Chart Settings
                </h5>
                <button
                  type="button"
                  className="transparent-btn btn-close btn-close-white"
                  title="Close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                    />
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      // width: "100%",
                      padding: "50px 0",
                    }}
                  >
                    <img src={loader} height="100px" alt="" width="100px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="chartSettings">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content_table">
              <div className="modal-header header-bg p-2 border-0">
                <h5 className="" id="exampleModalLabel">
                  Chart Settings
                </h5>
                <button
                  type="button"
                  className="transparent-btn btn-close btn-close-white"
                  title="Close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                    />
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <button type="button" className="btn modal-ok-btn">
                    + Add Chart
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-dark align-middle table-borderless bg-transparent">
                    <tbody>
                      <tr>
                        <td className="bg-transparent">Chart Title</td>
                        <td className="bg-transparent">Source</td>
                        <td className="bg-transparent">Show</td>
                        <td className="bg-transparent">Delete</td>
                      </tr>
                      <tr>
                        <td className="bg-transparent">Motion</td>
                        <td className="bg-transparent">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>CSV</option>
                            <option value="1">One</option>
                          </select>
                        </td>
                        <td className="bg-transparent">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                        </td>
                        <td className="bg-transparent">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-transparent">Test Title</td>
                        <td className="bg-transparent">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>CSV</option>
                            <option value="1">One</option>
                          </select>
                        </td>
                        <td className="bg-transparent">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                            checked
                          />
                        </td>
                        <td className="bg-transparent">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-transparent">Test 02</td>
                        <td className="bg-transparent">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>CSV</option>
                            <option value="1">One</option>
                          </select>
                        </td>
                        <td className="bg-transparent">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                            checked
                          />
                        </td>
                        <td className="bg-transparent">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ReactModal>
  );
};

const mapStateToProps = (state) => ({
  requestPending: state.ui.requestPending,
  assetSpecs: state.webglAssociates.modals.assetSpecs,
});

export default connect(mapStateToProps, {
  fetchAssetSpecs,
})(ChartSettings);
