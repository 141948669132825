import React, { useState } from "react";
import ReactModal from "react-modal";
import { getIconFromList } from "../../../../static/IconServe";
import { useDispatch } from "react-redux";
import C_Chart from "./C_Chart";

const ComponentChart = () => {
  const dispatch = useDispatch();
  const [isVisable, setIsVisible] = useState(false);
  const [componentCharts, setComponentCharts] = useState([]);

  // window.activateComponentChart = async (project_guid, asset_guid, chartListJson) => {
  window.activateComponentChart = async (jsonObject, chartObject) => {
    let project_guid = JSON.parse(jsonObject).project_guid
    let asset_guid = JSON.parse(jsonObject).asset_guid
    let pipeline_guid = JSON.parse(jsonObject).pipeline_guid
    let url_param = JSON.parse(jsonObject).url_param

    let chartListJson = chartObject

    // console.log(project_guid)
    // console.log(asset_guid)
    // console.log(pipeline_guid)
    // console.log(url_param)
    // console.log(chartListJson)
    
    setComponentCharts([]);
    if (!project_guid) return;
    dispatch({
      type: "SET_PANEL_FIELD_INFO",
      payload: url_param === "assets" ? {
        panel_type: "asset",
        panel_type_guid: asset_guid,
        project_guid: project_guid,
        location: null,
      } : {
        panel_type: "pipeline",
        panel_type_guid: asset_guid,
        project_guid: project_guid,
        location: null,
      },
    });
    setComponentCharts(JSON.parse(chartObject).charts);
    if (JSON.parse(chartListJson).charts.length > 0) setIsVisible(true);

    // // console.log(JSON.parse(chartListJson).charts)
    if (!isVisable) {
      setTimeout(() => {
        document.getElementById("component-chart-modal").parentNode.style.width = "0px";
      }, 500);
    }
  };

  window.deactivateComponentChart = () => {
    if (isVisable) {
      document.getElementById("webgl-component-chart-modal").click();
      setIsVisible(false);
    }
  };

  return (
    <ReactModal
      isOpen={isVisable}
      id="component-chart-modal"
      style={{
        // overlay: {
        //   inset: "none",
        //   backgroundColor: "transparent",
        //   //width: "400px",
        //   position: "fixed !important",
        //   right: "0",
        //   top: "0",
        // },
        content: {
          maxWidth: "100% !important",
          maxHeight: "100% !important",
          border: "none",
          background: "transparent",
          height: "60px !important",
          overflow: "hidden",
        },
      }}
    >
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ border: "none", minHeight: "200px" }}
        >
          <div
            className="modal-header"
            style={{
              justifyContent: "flex-end",
              padding: "0.2rem",
              border: "none",
              display: "none",
            }}
          >
            <span
              id="webgl-component-chart-modal"
              onClick={(e) => {
                e.preventDefault();
                setIsVisible(false);
              }}
            >
              {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
            </span>
          </div>
          <div className="modal-body">
            {componentCharts.length > 0 ? (
              <>
                {componentCharts.map((chart, index) => (
                  <>
                    {chart.is_active ? (
                      <div key={index}>
                        <C_Chart
                          index={index}
                          chart={chart}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ComponentChart;
