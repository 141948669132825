import React from "react";
import SynchronizeModals from "./Modals";
import "./webgl-associates.css";

const WebglAssociates = ({
  messages,
  currentUserName,
  rtcMembers,
  channelName,
  isHost,
  useRguid,
}) => (
  <React.Fragment>
    <SynchronizeModals
      messages={messages}
      currentUserName={currentUserName}
      rtcMembers={rtcMembers}
      channelName={channelName}
      isHost={isHost}
      useRguid={useRguid}
    />
  </React.Fragment>
);

export default WebglAssociates;
