import React, { useEffect, useState } from "react";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { generatePieChartLayout } from "./layout/layout_pie.default";
import request from "../../../../utils/request";
import { useSelector } from "react-redux";

const Plot = createPlotlyComponent(Plotly);

const pieColorList = [
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
];

const SocketChartPie = ({ chart, panel }) => {
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );
  const [isLoaderOn, setIsLoaderOn] = useState(true);
  const [pieChartData, setPieChartData] = useState({
    labels: [],
    values: [],
    type: "pie",
    name: "",
  });

  useEffect(async () => {
    setIsLoaderOn(true);
    try {
      let { data } = await request.get(
        `/api/${panelFieldInfo.panel_type}/${panelFieldInfo.panel_type_guid}/charts/${chart.guid}/data/?column_name=${chart.x_axis_category}`
      );
      let bar_chart = {
        labels: data.col_data_x,
        values: data.col_data_y,
        type: "pie",
        name: chart.chart_name,
        marker: {
          colors: pieColorList,
        },
        domain: {
          row: 0,
          column: 0,
        },
        // hoverinfo: 'label+percent+name',
        textinfo: panel.panel_config.defaultData.pie_chart_text_info
          ? "percent"
          : "none",
      };
      setPieChartData(bar_chart);
      setIsLoaderOn(false);
    } catch (error) {
      // console.log("catch", error);
    }
  }, [panel]);

  return (
    <React.Fragment>
      <>
        <Plot
          data={[pieChartData]}
          layout={generatePieChartLayout(chart, panel.panel_config)}
          useResizeHandler={true}
          className="wh-100"
        />
      </>
      {isLoaderOn ? <div className="chart-loader"></div> : <></>}
    </React.Fragment>
  );
};

export default SocketChartPie;
