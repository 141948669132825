import React from "react";
import { CellMeasurer } from "react-virtualized";

const DynamicColumn = ({
  dataKey,
  parent,
  rowIndex,
  columnIndex,
  cellData,
  children,
  cache,
}) => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={dataKey}
      parent={parent}
      rowIndex={rowIndex}
    >
      {children}
    </CellMeasurer>
  );
};

export default DynamicColumn;
