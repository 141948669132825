import React, { useEffect, useState } from "react";
// import Plot from "react-plotly.js";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { generateBarChartLayout } from "./layout/layout_bar.default";
import request from "../../../../utils/request";
import { useSelector } from "react-redux";

const Plot = createPlotlyComponent(Plotly);

const barColorList = [
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
];

const SocketChartBar = ({ chart, panel }) => {
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );
  const [isLoaderOn, setIsLoaderOn] = useState(true);
  const [barChartData, setBarChartData] = useState({
    x: [],
    y: [],
    type: "bar",
    name: "",
  });

  useEffect(async () => {
    // console.log(chart)
    setIsLoaderOn(true);
    try {
      let { data } = await request.get(
        `/api/${panelFieldInfo.panel_type}/${panelFieldInfo.panel_type_guid}/charts/${chart.guid}/data/?column_name=${chart.x_axis_category}`
      );
      let bar_chart = {
        x: data.col_data_x,
        y: data.col_data_y,
        type: "bar",
        name: chart.chart_name,
        textposition: 'auto',
        // hoverinfo: 'none',
        opacity: 0.5,
        marker: {
          // color: '#0498B3',
          color: barColorList,
          line: {
            color: '#FFFFFF',
            width: 1.5
          }
        }
      };
      setBarChartData(bar_chart);
      setIsLoaderOn(false);
    } catch (error) {
      // console.log("catch", error);
    }
  }, [panel]);

  return (
    <React.Fragment>
      <>
        <Plot
          data={[barChartData]}
          layout={generateBarChartLayout(chart, "")}
          useResizeHandler={true}
          className="wh-100"
        />
      </>
      {isLoaderOn ? <div className="chart-loader"></div> : <></>}
    </React.Fragment>
  );
};

export default SocketChartBar;
