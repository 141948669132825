import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getBillingPackages,
  getSubscriptionInfo,
  getSubscriptionTransaction,
} from "../../actions/billingAction";
import SubscriptionTransaction from "./SubscriptionTransaction";

const ASSET_PER_CREDIT = process.env.REACT_APP_ASSET_PER_CREDIT;
const STORAGE_PRE_CREDIT = process.env.REACT_APP_STORAGE_PRE_CREDIT;
const USD_CREDIT_PER_MONTH = process.env.REACT_APP_USD_CREDIT_PER_MONTH;

const Subscription = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [subscriptionType, setSubscriptionType] = useState(null); // 0 = Basic, 1 = Premium
  const [upgradeToNoOfAsset, setUpgradeToNoOfAsset] = useState(0);
  const [upgradeToNoOfStorage, setUpgradeToNoOfStorage] = useState(0);
  const [paymentPeriod, setPaymentPeriod] = useState(0); // 0 = Monthly, 1 = Yearly
  const today = useState(
    new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate()
  );

  const [subscriptionInfo, setSubcriptionInfo] = useState(null);
  const [packages, setPackages] = useState([]);

  const checkoutClickEvent = () => {
    // navigate("/bill-payment");
    let param_str =
      "type=" +
      subscriptionType.id +
      "&pack=" +
      subscriptionType.name +
      "&assets=" +
      upgradeToNoOfAsset +
      "&storage=" +
      upgradeToNoOfStorage +
      "&ttl=" +
      getTotalPrice();
    window.open("/bill-payment" + "?" + param_str, "_blank");
  };

  useEffect(() => {
    dispatch(getSubscriptionInfo())
      .then((res) => {
        setSubcriptionInfo(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    dispatch(getBillingPackages())
      .then((res) => {
        setPackages(res.data.packages);
        setSubscriptionType(res.data.packages[1]);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const getStartDateFromExpDate = (date) => {
    let YYYY = new Date(
      new Date(date).getTime() - 30 * 24 * 60 * 60 * 1000
    ).getFullYear();
    let DD = new Date(
      new Date(date).getTime() - 30 * 24 * 60 * 60 * 1000
    ).getDate();
    let MM =
      new Date(new Date(date).getTime() - 30 * 24 * 60 * 60 * 1000).getMonth() +
      1;
    return YYYY + "-" + MM + "-" + DD;
  };

  const getTotalPrice = () => {
    let asset_p = parseFloat(upgradeToNoOfAsset);
    let storage_p = parseFloat(upgradeToNoOfStorage) / STORAGE_PRE_CREDIT;
    let ttl_credit = asset_p + storage_p;
    let ttl_credit_price_per_mnth = ttl_credit * USD_CREDIT_PER_MONTH;
    let ttl_p =
      ttl_credit_price_per_mnth +
      parseFloat(subscriptionType ? subscriptionType.subscription_fee : 0);
    return ttl_p;
  };

  return (
    <div
      className="tab-pane fade show active"
      id="nav-Subscription"
      role="tabpanel"
      aria-labelledby="nav-Subscription-tab"
    >
      <h4 className="p-2">Subscription</h4>
      <div className="p-2">
        <table className="table">
          <tbody>
            <tr>
              <td width="200">
                <strong>Month</strong>
              </td>
              <td>
                <strong>Current Status</strong>
              </td>
              <td>
                <strong>Upgrade to</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Subscription Type:</strong>
              </td>
              <td>
                {subscriptionInfo ? subscriptionInfo.subscribed_package : ""}
              </td>
              <td>
                <div className="d-flex">
                  {packages.map((pac, ind) => (
                    <div className="form-check mr-2" key={ind}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={"subscription_type_" + ind}
                        defaultChecked={ind === 1 ? "checked" : ""}
                        onChange={(e) => {
                          if (e.target.checked) {
                            packages.forEach((p, i) => {
                              if (ind !== i) {
                                document.getElementById(
                                  "subscription_type_" + i
                                ).checked = false;
                              }
                            });
                            // // console.log(pac);
                            setSubscriptionType(pac);
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"subscription_type_" + ind}
                      >
                        {pac.name + " $" + pac.subscription_fee + "/month"}
                      </label>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Subscription Fee/Month:</strong>
              </td>
              <td>
                ${subscriptionInfo ? subscriptionInfo.subscription_fee : 0}
              </td>
              <td>
                <h6>1 Asset = 1 Credit</h6>
                <h6>{STORAGE_PRE_CREDIT} MB Data Storage = 1 Credit</h6>
                <h6>Credit Price = ${USD_CREDIT_PER_MONTH}/Month</h6>
              </td>
            </tr>
            <tr>
              <td>
                <strong>No. of Asset</strong>
              </td>
              <td>{subscriptionInfo ? subscriptionInfo.asset_count : 0}</td>
              <td>
                <div className="d-flex align-items-center">
                  <input
                    className=""
                    type="number"
                    placeholder="10"
                    value={upgradeToNoOfAsset}
                    style={{ width: "50px" }}
                    readOnly
                  />
                  <div className="w-75 ml-2">
                    <div className="plot__chartdata_value--flex">
                      <span>{0}</span>
                      <span id="billing-no_of_asset-range">
                        {upgradeToNoOfAsset}
                      </span>
                      <span>{process.env.REACT_APP_SUBSCRIPTION_MAX_ASSET}</span>
                    </div>
                    <input
                      type="range"
                      className="form-range w-100"
                      min={0}
                      max={process.env.REACT_APP_SUBSCRIPTION_MAX_ASSET}
                      step="10"
                      defaultValue={0}
                      onChange={(e) => {
                        e.stopPropagation();
                        document.getElementById(
                          "billing-no_of_asset-range"
                        ).textContent = e.target.value;
                        e.target.addEventListener(
                          "click",
                          function (event) {
                            event.stopImmediatePropagation();
                            setUpgradeToNoOfAsset(event.target.value);
                          },
                          false
                        );
                      }}
                    ></input>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Data Storage</strong>
              </td>
              <td>
                {subscriptionInfo ? subscriptionInfo.storage_capacity : 0} MB
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <input
                    className=""
                    type="number"
                    placeholder="100mb"
                    value={upgradeToNoOfStorage}
                    style={{ width: "50px" }}
                    readOnly
                  />
                  <div className="w-75 ml-2">
                    <div className="plot__chartdata_value--flex">
                      <span>{0}</span>
                      <span id="billing-no_of_storage-range">
                        {upgradeToNoOfStorage}
                      </span>
                      <span>{process.env.REACT_APP_SUBSCRIPTION_MAX_STORAGE}</span>
                    </div>
                    <input
                      type="range"
                      className="form-range w-100"
                      min={0}
                      max={process.env.REACT_APP_SUBSCRIPTION_MAX_STORAGE}
                      step="100"
                      defaultValue={0}
                      onChange={(e) => {
                        e.stopPropagation();
                        document.getElementById(
                          "billing-no_of_storage-range"
                        ).textContent = e.target.value;
                        e.target.addEventListener(
                          "click",
                          function (event) {
                            event.stopImmediatePropagation();
                            setUpgradeToNoOfStorage(event.target.value);
                          },
                          false
                        );
                      }}
                    ></input>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Total Credit/month :</strong>
              </td>
              <td>
                {subscriptionInfo ? subscriptionInfo.re_curring_credit_cost : 0}
              </td>
              <td>
                <h6>Asset Credit : {parseInt(upgradeToNoOfAsset)}</h6>
                <h6>
                  Storage Credit :{" "}
                  {parseInt(upgradeToNoOfStorage) / STORAGE_PRE_CREDIT}
                </h6>{" "}
                <h6>
                  Total Credit :{" "}
                  {parseInt(upgradeToNoOfAsset) +
                    parseInt(upgradeToNoOfStorage) / STORAGE_PRE_CREDIT}
                </h6>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Total Price/month :</strong>
              </td>
              <td>${subscriptionInfo ? subscriptionInfo.total_price : 0}</td>
              <td>
                <h6>
                  Subscription Fee: $
                  {subscriptionType ? subscriptionType.subscription_fee : 0}
                </h6>
                <h6>
                  Total Credit :{" "}
                  {parseInt(upgradeToNoOfAsset) +
                    parseInt(upgradeToNoOfStorage) / STORAGE_PRE_CREDIT}
                  /Month
                </h6>
                <h6>
                  Total Price : ${getTotalPrice()}
                  /Month
                </h6>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Discount/Promotion</strong>
              </td>
              <td>- </td>
              <td>- </td>
            </tr>
            <tr>
              <td>
                <strong>Start Date</strong>
              </td>
              <td>
                {subscriptionInfo
                  ? getStartDateFromExpDate(subscriptionInfo.expire_date)
                  : ""}
              </td>
              <td>{/*today*/}</td>
            </tr>
            <tr>
              <td>
                <strong>End Date</strong>
              </td>
              <td>{subscriptionInfo ? subscriptionInfo.expire_date : ""}</td>
              <td className="text-center">
                <button
                  style={{
                    color: "white",
                    minWidth: "150px",
                    border: "none",
                    backgroundColor: "#dc3545",
                    borderColor: "#dc3545",
                    borderRadius: "0.25rem",
                  }}
                  onClick={() => {
                    checkoutClickEvent();
                  }}
                >
                  Checkout
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <SubscriptionTransaction />
    </div>
  );
};

export default Subscription;
