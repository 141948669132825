import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import toastr from "toastr";
import {
  fetchAvailableDatasets,
  fetchSelectedDatasetColumns,
  createNewChart,
} from "../../../../../actions/panelActions";

const ConfigureChart = ({ isOpen, setIsOpen, guid, panelType }) => {
  const dispatch = useDispatch();
  const dataSets = useSelector((state) => state.panel.datasets[panelType]);
  const columns = useSelector((state) => state.panel.config.columns);

  const [selectedDataset, setSelectedDataset] = useState("");
  const [selectedChartType, selectChartType] = useState("");
  const [datasetGUID, setDatasetGUID] = useState("");
  const [chartTitle, setChartTitle] = useState("");
  const [xAxisTitle, setxAxisTitle] = useState("");
  const [yAxisTitle, setyAxisTitle] = useState("");
  const [xAxisType, setxAxisType] = useState("");
  const [yAxisSeries, updateSeries] = useState([
    { series_name: "", category_name: "" },
  ]);

  if (isOpen && !dataSets) {
    dispatch(fetchAvailableDatasets(panelType, guid, () => {}));
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(!isOpen);
      }}
      contentLabel="ConfigureChart"
      className="ConfigureChart"
    >
      <div className="ReactModal__Content--container__configaration_modal">
        <div className="ReactModal__Content--inner__header">
          <div className="ReactModal__Content--inner__header--block">
            <div className="ReactModal__Content--inner__header--block__block">
              <h4 className="ReactModal__Content--title">
                Configure New Chart
              </h4>
            </div>
          </div>
        </div>
        <div className="ReactModal__Content--inner__body">
          <div className="input-group React__input_group mb-3">
            <button
              className="dropdown-toggle data-source_btn"
              type="button"
              id
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-placement="top"
              title="Select a dataset"
            >
              <span className="me-2">
                {selectedDataset === "" ? "SELECT DATASET" : selectedDataset}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-arrow-down"
                viewBox="0 0 16 16"
                style={{ height: 24 }}
              >
                <path
                  fillRule="evenodd"
                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                />
              </svg>
            </button>
            <ul
              className="dropdown-menu p-0 add-data-dropdown-item"
              aria-labelledby
              style={{}}
            >
              {dataSets
                ? dataSets.map((singleSet) => (
                    <li>
                      <a
                        className="dropdown-item d-flex justify-content-between align-items-center"
                        href="#!"
                        onClick={() => {
                          setSelectedDataset(singleSet.name);
                          setDatasetGUID(singleSet.guid);
                          dispatch(
                            fetchSelectedDatasetColumns(
                              panelType,
                              guid,
                              singleSet.guid
                            )
                          );
                        }}
                      >
                        {singleSet.name}
                      </a>
                    </li>
                  ))
                : null}
            </ul>

            <button
              className="dropdown-toggle data-source_btn"
              type="button"
              id
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-placement="top"
              title="Select a dataset"
            >
              <span className="me-2">
                {selectedChartType === "" ? "CHART TYPE" : selectedChartType}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-arrow-down"
                viewBox="0 0 16 16"
                style={{ height: 24 }}
              >
                <path
                  fillRule="evenodd"
                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                />
              </svg>
            </button>
            <ul
              className="dropdown-menu p-0 add-data-dropdown-item"
              aria-labelledby
              style={{}}
            >
              {["line", "bar", "pie"].map((type) => (
                <li key={type}>
                  <a
                    className="dropdown-item d-flex justify-content-between align-items-center"
                    href="#!"
                    onClick={() => selectChartType(type)}
                  >
                    {type}
                  </a>
                </li>
              ))}
            </ul>
            <input
              type="text"
              id="chart-name-inp-f"
              className="form-control"
              placeholder="Enter Chart Title"
              value={chartTitle}
              onChange={({ target }) => setChartTitle(target.value)}
            />
          </div>
          <div className="d-flex mb-3 justify-content-between aligh-items-center">
            <div className="w-50">
              <div className="mb-3">
                <input
                  type="text"
                  id="chart-name-inp-f"
                  className="form-control"
                  placeholder="Enter Chart Title"
                  value={chartTitle}
                  onChange={({ target }) => setChartTitle(target.value)}
                />
              </div>
            </div>
            <div className="w-50">
              <div className="dropdown d-flex">
                <button
                  className="ms-4 d-flex align-items-center justify-content-between btn mx-1 data-source_btn w-100"
                  type="button"
                  id
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-placement="top"
                  title="Select a dataset"
                >
                  <span className="me-2">
                    {selectedDataset === ""
                      ? "SELECT DATASET"
                      : selectedDataset}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-arrow-down"
                    viewBox="0 0 16 16"
                    style={{ height: 24 }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                  </svg>
                </button>
                <ul
                  className="dropdown-menu p-0 add-data-dropdown-item w-100"
                  aria-labelledby
                  style={{}}
                >
                  {dataSets
                    ? dataSets.map((singleSet) => (
                        <li>
                          <a
                            className="dropdown-item d-flex justify-content-between align-items-center"
                            href="#!"
                            onClick={() => {
                              setSelectedDataset(singleSet.name);
                              setDatasetGUID(singleSet.guid);
                              dispatch(
                                fetchSelectedDatasetColumns(
                                  panelType,
                                  guid,
                                  singleSet.guid
                                )
                              );
                            }}
                          >
                            {singleSet.name}
                          </a>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            </div>
            <div className="w-50">
              <div className="dropdown d-flex">
                <button
                  className="ms-4 d-flex align-items-center justify-content-between btn mx-1 data-source_btn w-100"
                  type="button"
                  id
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-placement="top"
                  title="Select a dataset"
                >
                  <span className="me-2">
                    {selectedChartType === ""
                      ? "CHART TYPE"
                      : selectedChartType}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-arrow-down"
                    viewBox="0 0 16 16"
                    style={{ height: 24 }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                  </svg>
                </button>
                <ul
                  className="dropdown-menu p-0 add-data-dropdown-item w-100"
                  aria-labelledby
                  style={{}}
                >
                  {["Line", "Bar", "Pie"].map((type) => (
                    <li key={type}>
                      <a
                        className="dropdown-item d-flex justify-content-between align-items-center"
                        href="#!"
                        onClick={() => selectChartType(type)}
                      >
                        {type}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {selectedChartType && selectedDataset && chartTitle && (
            <div className="input-group React__input_group mb-3">
              <input
                type="text"
                placeholder="X Axis Title"
                value={xAxisTitle}
                onChange={({ target: { value } }) => setxAxisTitle(value)}
                className="form-control"
              />
              <input
                type="text"
                placeholder="Y Axis Title"
                value={yAxisTitle}
                onChange={({ target: { value } }) => setyAxisTitle(value)}
                className="form-control"
              />
            </div>
          )}
          {selectedChartType &&
            selectedDataset &&
            chartTitle &&
            xAxisTitle &&
            yAxisTitle && (
              <div className="input-group React__input_group mb-3">
                <span className="input-group-text">Select Category or X Axis</span>
                <button
                  className="dropdown-toggle data-source_btn input-group-like-control"
                  type="button"
                  id
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-placement="top"
                  title="Select a dataset"
                >
                  <span className="me-2">
                    {xAxisType === "" ? "SELECT TYPE" : xAxisType}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-arrow-down"
                    viewBox="0 0 16 16"
                    style={{ height: 24 }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                  </svg>
                </button>
                <ul
                  className="dropdown-menu p-0 add-data-dropdown-item"
                  aria-labelledby
                  style={{}}
                >
                  {columns
                    ? columns.map((column) => (
                        <li key={column.col_name}>
                          <a
                            className="dropdown-item d-flex justify-content-between align-items-center"
                            href="#!"
                            onClick={() => setxAxisType(column.col_name)}
                          >
                            {column.col_name}
                          </a>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            )}
          {selectedChartType &&
            selectedDataset &&
            chartTitle &&
            xAxisTitle &&
            yAxisTitle &&
            xAxisType && (
              <div>
                <p className="mb-1">Series of Y Axis</p>
                {yAxisSeries.map((series, idx) => (
                  <div
                    className="input-group React__input_group mb-3"
                    key={idx}
                  >
                    <button
                      className="dropdown-toggle data-source_btn"
                      type="button"
                      id
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-placement="top"
                      title="Select a dataset"
                    >
                      <span className="me-2">
                        {series.category_name === ""
                          ? "SELECT SERIES"
                          : yAxisSeries[idx].category_name}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-arrow-down"
                        viewBox="0 0 16 16"
                        style={{ height: 24 }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                        />
                      </svg>
                    </button>
                    <ul
                      className="dropdown-menu p-0 add-data-dropdown-item w-100"
                      aria-labelledby
                      style={{}}
                    >
                      {columns
                        ? columns.map((column) => (
                            <li key={column.col_name}>
                              <a
                                className="dropdown-item d-flex justify-content-between align-items-center"
                                href="#!"
                                onClick={() => {
                                  const existingArray = [...yAxisSeries];
                                  existingArray[idx].category_name =
                                    column.col_name;
                                  updateSeries(existingArray);
                                }}
                              >
                                {column.col_name}
                              </a>
                            </li>
                          ))
                        : null}
                    </ul>
                    <input
                      className="form-control"
                      type="text"
                      value={yAxisSeries[idx].series_name}
                      onChange={({ target: { value } }) => {
                        const existingArray = [...yAxisSeries];
                        existingArray[idx].series_name = value;
                        updateSeries(existingArray);
                      }}
                    />
                  </div>
                ))}
                <button
                  className="btn btn-scifi position-relative"
                  onClick={() => {
                    const lastSeries = yAxisSeries[yAxisSeries.length - 1];
                    // // console.log(lastSeries);
                    if (
                      lastSeries.category_name === "" ||
                      lastSeries.series_name === ""
                    ) {
                      toastr.error("Please fill the empty series field");
                      return;
                    }

                    updateSeries([
                      ...yAxisSeries,
                      { series_name: "", category_name: "" },
                    ]);
                  }}
                >
                  <i className="btn-helper"></i>
                  <span className="btn-text">add extra series</span>
                </button>
              </div>
            )}
          {selectedChartType &&
            selectedDataset &&
            chartTitle &&
            xAxisTitle &&
            yAxisTitle &&
            xAxisType &&
            yAxisSeries[yAxisSeries.length - 1].category_name &&
            yAxisSeries[yAxisSeries.length - 1].series_name && (
              <button
                className="btn btn-scifi position-relative mt-2"
                onClick={() => {
                  // // console.log({
                  //   chart_name: chartTitle,
                  //   dataset_guid: datasetGUID,
                  //   is_active: "true",
                  //   x_axis_label: yAxisTitle,
                  //   x_axis_category: xAxisType,
                  //   y_axis_label: xAxisTitle,
                  //   y_axis_categories: yAxisSeries,
                  //   chart_type: selectedChartType,
                  // });
                  dispatch(
                    createNewChart(
                      {
                        chart_name: chartTitle,
                        dataset_guid: datasetGUID,
                        is_active: "true",
                        x_axis_label: yAxisTitle,
                        x_axis_category: xAxisType,
                        y_axis_label: xAxisTitle,
                        y_axis_categories: yAxisSeries,
                        chart_type: selectedChartType,
                      },
                      panelType,
                      guid,
                      () => setIsOpen(!isOpen)
                    )
                  );
                }}
              >
                <i className="btn-helper"></i>
                <div className="btn-text">add new chart</div>
              </button>
            )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfigureChart;
