const lineColorList = [
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
];

const pieColorList = [
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
  "#D83D32", "#F98187", "#283F53", "#31566F", "#68A2C1",
];

// ==================================================
const line_chart = {
  data: [
    {
      x: [1, 2, 3, 4],
      y: [10, 15, 13, 17],
      type: "scatter",
    },
    {
      x: [1, 2, 3, 4],
      y: [16, 5, 11, 9],
      type: "scatter",
    },
  ],
  layout: {
    chart_name: "Line Chart",
    x_axis_label: "Label X",
    y_axis_label: "Label Y",
  },
};
// ==================================================
const bar_chart = {
  data: [
    {
      x: ["	1_FPSO", "2_Cluster Manifold", "90_SPAR"],
      y: [20, 14, 23],
      name: "Type 1",
      type: "bar",
      textposition: 'auto',
      // hoverinfo: 'none',
      opacity: 0.5,
      marker: {
        color: lineColorList,
        line: {
          color: '#FFFFFF',
          width: 1.5
        }
      }
    },
  ],
  layout: {
    chart_name: "Bar Chart",
    x_axis_label: "Label X",
    y_axis_label: "Label Y",
  },
};
// ==================================================
const stacked_bar_chart = {
  data: [
    {
      x: ["	1_FPSO", "2_Cluster Manifold", "90_SPAR"],
      y: [20, 14, 23],
      name: "Type 1",
      type: "bar",
      textposition: 'auto',
      // hoverinfo: 'none',
      opacity: 0.5,
      marker: {
        color: lineColorList,
        line: {
          color: '#FFFFFF',
          width: 1.5
        }
      }
    },
    {
      x: ["	Tweet", "3 Star", "90_SPAR"],
      y: [55, 27, 46],
      name: "Type 2",
      type: "bar",
      textposition: 'auto',
      // hoverinfo: 'none',
      opacity: 0.5,
      marker: {
        color: pieColorList,
        line: {
          color: '#FFFFFF',
          width: 1.5
        }
      }
    },
  ],
  layout: {
    chart_name: "Stacked Bar Chart",
    x_axis_label: "Label X",
    y_axis_label: "Label Y",
  },
};
// ==================================================
const stacked_column_chart = {
  data: [
    {
      x: ["	1_FPSO", "2_Cluster Manifold", "90_SPAR"],
      y: [20, 14, 23],
      name: "Type 1",
      type: "bar",
      textposition: 'auto',
      // hoverinfo: 'none',
      opacity: 0.5,
      marker: {
        color: lineColorList,
        line: {
          color: '#FFFFFF',
          width: 1.5
        }
      }
    },
    {
      x: ["	1_FPSO", "2_Cluster Manifold", "90_SPAR"],
      y: [55, 27, 46],
      name: "Type 2",
      type: "bar",
      textposition: 'auto',
      // hoverinfo: 'none',
      opacity: 0.5,
      marker: {
        color: pieColorList,
        line: {
          color: '#FFFFFF',
          width: 1.5
        }
      }
    },
  ],
  layout: {
    chart_name: "Stacked Column Chart",
    x_axis_label: "Label X",
    y_axis_label: "Label Y",
  },
};
// ==================================================
// https://plotly.com/javascript/gauge-charts/
const gague_chart = {
  data: [
    {
      type: "indicator",
      mode: "gauge+number+delta",
      value: 420,
      title: { text: "Speed", font: { size: 24 } },
      delta: { reference: 400, increasing: { color: "RebeccaPurple" } },
      gauge: {
        axis: { range: [null, 700], tickwidth: 1, tickcolor: "darkblue" },
        bar: { color: "darkblue" },
        bgcolor: "white",
        borderwidth: 2,
        bordercolor: "gray",
        steps: [
          { range: [0, 250], color: "cyan" },
          { range: [250, 400], color: "royalblue" },
          // { range: [200, 300], color: "yellow" },
        ],
        threshold: {
          line: { color: "red", width: 4 },
          thickness: 0.75,
          value: 490,
        },
      },
    },
    // {
    //   domain: { x: [0, 1], y: [0, 1] },
    //   value: 270,
    //   title: { text: "Speed" },
    //   type: "indicator",
    //   mode: "gauge+number",
    // },
  ],
  layout: {
    chart_name: "Gague Chart",
    x_axis_label: "Label X",
    y_axis_label: "Label Y",
  },
};
// ==================================================
const donut_chart = {
  data: [
    {
      values: [16, 15, 12, 6, 5, 4, 42],
      labels: [
        "US",
        "China",
        "European Union",
        "Russian Federation",
        "Brazil",
        "India",
        "Rest of World",
      ],
      domain: { column: 0 },
      name: "GHG Emissions",
      hoverinfo: "label+percent+name",
      hole: 0.4,
      type: "pie",
      marker: {
        colors: pieColorList,
      },
    },
    {
      values: [27, 11, 25, 8, 1, 3, 25],
      labels: [
        "US",
        "China",
        "European Union",
        "Russian Federation",
        "Brazil",
        "India",
        "Rest of World",
      ],
      text: "CO2",
      textposition: "inside",
      domain: { column: 1 },
      name: "CO2 Emissions",
      hoverinfo: "label+percent+name",
      hole: 0.4,
      type: "pie",
      marker: {
        colors: pieColorList,
      },
    },
  ],
  layout: {
    chart_name: "Donut Chart",
    x_axis_label: "Label X",
    y_axis_label: "Label Y",
  },
};
// ==================================================
const pie_chart = {
  data: [
    {
      values: [19, 20, 40, 15, 6],
      labels: ["4_Well", "1_FPSO", "1_Rail", "2_Manifold", "Sami ESP"],
      type: "pie",
      name: "Default Pie Chart",
      marker: {
        colors: pieColorList,
      },
    },
  ],
  layout: {
    chart_name: "Pie Chart",
    x_axis_label: "Label X",
    y_axis_label: "Label Y",
  },
};
// ==================================================
const mesh_chart = {
    type: "mesh3d",
    x: [0, 0, 1, 1, 0, 0, 1, 1],
    y: [0, 1, 1, 0, 0, 1, 1, 0],
    z: [0, 0, 0, 0, 1, 1, 1, 1],
    i: [7, 0, 0, 0, 4, 4, 6, 6, 4, 0, 3, 2],
    j: [3, 4, 1, 2, 5, 6, 5, 2, 0, 1, 6, 3],
    k: [0, 7, 2, 3, 6, 7, 1, 1, 5, 5, 7, 6],
    value: [0, 0.14285714285714285, 0.2857142857142857, 0.42857142857142855, 0.5714285714285714, 0.7142857142857143, 0.8571428571428571, 1],
    colorscale: [
      [0, 'rgb(255, 0, 255)'],
      [0.5, 'rgb(0, 255, 0)'],
      [1, 'rgb(0, 0, 255)']
    ]
}

export const getDefaultChartData = (type) => {
  switch (type) {
    case "line_chart":
      return line_chart;
    case "bar_chart":
      return bar_chart;
    case "stacked_bar_chart":
      return stacked_bar_chart;
    case "stacked_column_chart":
      return stacked_column_chart;
    case "gague_chart":
      return gague_chart;
    case "donut_chart":
      return donut_chart;
    case "pie_chart":
      return pie_chart;
    case "mesh_chart":
      return mesh_chart;
    default:
      break;
  }
};
