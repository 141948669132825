export const generateGagueChartLayout = (chart, panel_config) => ({
  autosize: true,

  // width: 300,
  // height: 200,
  // width: panel_config.defaultPosition.width,
  // height: 200,
  // height: panel_config.defaultPosition.height - 20,
  margin: { t: 50, r: 10, l: 10, b: 10 },
  paper_bgcolor: "lavender",
  font: { color: "white", family: "Arial" }
  // margin: {
  //   l: 50,
  //   r: 10,
  //   b: 10,
  //   t: 10,
  //   pad: 10,
  // },

  // paper_bgcolor: "lightblue",
  // plot_bgcolor: "#ddd",

  // legend: {
  //   x: 1,
  //   y: 1.3,
  //   xanchor: "right",
  //   // xanchor: "center",
  //   orientation: "h", //To place the legend below chart
  //   font: {
  //     family: "Arial, sans-serif",
  //     size: 10,
  //     color: "white",
  //   },
  // },
});
