export const generateMeshChartLayout = (chart, config) => ({
  autosize: true,
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 20,
    pad: 10,
  },
  paper_bgcolor: "lightblue",
  plot_bgcolor: "#ddd",
  modebar: {
    orientation: "h",
    margin: {
      t: 10,
    },
  },
  legend: {
    orientation: "h",
    font: {
      family: "sans-serif",
      size: 12,
      color: "#000",
    },
  },
  hoverlabel: {
    bgcolor: "blue",
    font: {
      color: "yellow",
    },
  },
  // colorbar: {
  //   tickfont: {
  //     color: 'white' // set the font color of the color scale side
  //   }
  // },
  scene: {
    // colorbar: {
    //   tickcolor: "#FF0000",
    //   tickfont: {
    //     color: "#FF0000",
    //   },
    //   title: {
    //     text: "My Colorbar Title",
    //     font: {
    //       color: "#FF0000",
    //     },
    //   },
    // },
    // colorbar: {
    //   tickfont: {
    //     color: 'white'
    //   }
    // },
    // colorbar: {
    //   title: {
    //     text: 'My Colorbar Title',
    //     font: {
    //       color: 'white'
    //     }
    //   },
    //   tickfont: {
    //     color: 'white'
    //   },
    //   ticklabelfont: {
    //     color: 'white'
    //   }
    // },
    // camera: {
    //   center: {
    //     x: -1,
    //     y: 1,
    //     z: 0.2,
    //   },
    //   eye: {
    //     x: 1,
    //     y: -4,
    //     z: -6,
    //   },
    //   up: {
    //     x: 5,
    //     y: 0,
    //     z: 0,
    //   }
    // },
    // aspectratio: {
    //   x: config.chart_layout ? config.chart_layout.aspect_ratio.x ? config.chart_layout.aspect_ratio.x : 2 : 2,
    //   y: config.chart_layout ? config.chart_layout.aspect_ratio.y ? config.chart_layout.aspect_ratio.y : 1.5 : 1.5,
    //   z: config.chart_layout ? config.chart_layout.aspect_ratio.z ? config.chart_layout.aspect_ratio.z : 6 : 2,
    // },
    camera: {
      center: {
        x: 0,
        y: 0,
        z: 0.2,
      },
      eye: {
        x: 5.9132815419820295,
        y: 5.724879332663094,
        z: 0.7906420504007412,
      },
      // {
      //   x: 2,
      //   y: 8,
      //   z: 0.5,
      // },
    },
    aspectratio: {
      x: config.chart_layout
        ? config.chart_layout.aspect_ratio.x
          ? parseInt(config.chart_layout.aspect_ratio.x)
          : 1
        : 1,
      y: config.chart_layout
        ? config.chart_layout.aspect_ratio.y
          ? parseInt(config.chart_layout.aspect_ratio.y)
          : 1
        : 1,
      z: config.chart_layout
        ? config.chart_layout.aspect_ratio.z
          ? parseInt(config.chart_layout.aspect_ratio.z)
          : 1
        : 1,
      // x: 1,
      // y: 1,
      // z: 5,
    },
    xaxis: {
      automargin: true,
      zerolinecolor: "#00FFFB",
      zerolinewidth: 2,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      // tickwidth: 15,
      tickcolor: "#B9B8CE",
      tickangle: "auto",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        standoff: 10,
        text: "X",
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
    },
    yaxis: {
      automargin: true,
      zerolinecolor: "#00FFFB",
      zerolinewidth: 2,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      tickcolor: "#B9B8CE",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        standoff: 10,
        text: "Y",
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
    },
    zaxis: {
      range: [],
      automargin: true,
      zerolinecolor: "#00FFFB",
      zerolinewidth: 2,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      tickcolor: "#B9B8CE",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        standoff: 10,
        text: "Z",
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
    },
  },
});

// CONFIG
export const generateMeshChartConfig = () => ({
  // modeBarButtons: [["zoom3d", "pan3d", "orbitRotation", "tableRotation", "handleDrag3d", "resetCameraDefault3d", "resetCameraLastSave3d", "hoverClosest3d"]],
  // modeBarButtons: [
  //   [
  //     {
  //       name: "download",
  //       attr: "download",
  //       title: "Download as Image",
  //       icon: {
  //         width: 10,
  //         height: 10,
  //         path: "M5,1 L5,8 L2,5 L5,8 L8,5 M2,9 L8,9",
  //       },
  //       click: () => {
  //         alert("download");
  //       },
  //     },
  //   ],
  //   [
  //     "zoom2d",
  //     "pan2d",
  //     "select2d",
  //     "lasso2d",
  //     "zoomIn2d",
  //     "zoomOut2d",
  //   ],
  // ],
  // modeBarButtonsToAdd: [
  //   {
  //     name: "Update view",
  //     icon: {
  //       'width': 500,
  //       'height': 600,
  //       'path': 'M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z'
  //     },
  //     click: function (gd) {
  //       // var newColor = ['green', 'red', 'blue'][Math.floor(3 * Math.random())];
  //       // Plotly.restyle(gd, "line.color", newColor);
  //       alert("button1");
  //     },
  //   },
  //   // {
  //   //   name: "button1",
  //   //   icon: Plotly.Icons.pencil,
  //   //   direction: "up",
  //   //   click: function (gd) {
  //   //     alert("button1");
  //   //   },
  //   // },
  //   // "zoomIn2d",
  //   // "zoomOut2d",
  // ],
  // modeBarButtonsToRemove: [
  //   "pan2d",
  //   "select2d",
  //   "lasso2d",
  //   "resetScale2d",
  //   "zoomOut2d",
  // ],
  showEditInChartStudio: true,
  plotlyServerURL: "https://chart-studio.plotly.com",
});

export const camera_view_1 = () => {
  return {
    center: {
      x: 0,
      y: 0,
      z: 0.2,
    },
    eye: {
      x: 2,
      y: 8,
      z: 0.5,
    },
    up: {
      x: 0,
      y: 0,
      z: 1,
    },
  };
};

export const camera_view_top = () => {
  return {
    center: {
      x: 0,
      y: 0,
      z: 0.2,
    },
    eye: {
      x: 8.888244494148796e-17,
      y: 4.973897063519088e-16,
      z: 8.451666498350498,
    },
    up: {
      x: 0,
      y: 0,
      z: 1,
    },
  };
};

export const camera_view_bottom = () => {
  return {
    center: {
      x: 0,
      y: 0,
      z: 0.2,
    },
    eye: {
      x: 1.358603775906059e-16,
      y: 4.866606279580799e-16,
      z: -8.0516664983505,
    },
    up: {
      x: 0,
      y: 0,
      z: 1,
    },
  };
};

export const camera_view_front = () => {
  return {
    center: {
      x: 0,
      y: 0,
      z: 0.2,
    },
    eye: {
      x: 5.9132815419820295,
      y: 5.724879332663094,
      z: 0.7906420504007412,
    },
    up: {
      x: 0,
      y: 0,
      z: 1,
    },
  };
};

export const camera_view_rear = () => {
  return {
    center: {
      x: 0,
      y: 0,
      z: 0.2,
    },
    eye: {
      x: -5.819282538254819,
      y: -5.627728658773374,
      z: 1.798318142042872,
    },
    up: {
      x: 0,
      y: 0,
      z: 1,
    },
  };
};

export const camera_view_left = () => {
  return {
    center: {
      x: 0,
      y: 0,
      z: 0.2,
    },
    eye: {
      x: 5.7006764032931505,
      y: -5.768894957535723,
      z: 1.7205721008408539,
    },
    up: {
      x: 0,
      y: 0,
      z: 1,
    },
  };
};

export const camera_view_right = () => {
  return {
    center: {
      x: 0,
      y: 0,
      z: 0.2,
    },
    eye: {
      x: -5.58496553892498,
      y: 5.9105460268927015,
      z: 1.6012871201161047,
    },
    up: {
      x: 0,
      y: 0,
      z: 1,
    },
  };
};
