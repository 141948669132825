import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "toastr";
import {
  getDatasetList,
  postCustomDashboardPanel,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";
import AddDataset from "../AddDataset";
import { getPanelData } from "./panelType";
import CustomPannelDashboard_HeaderSettings from "./Settings";
import CustomPannelDashboard_HeaderTabs from "./Tabs";

const panelList = [
  { name: "Text Indicator", type: "text_indicator" },
  { name: "Indicator", type: "indicator" },
  { name: "Data Table", type: "data_table" },
  { name: "Map", type: "map" },
  { name: "Specification", type: "specification" },
  {
    name: "Chart",
    type: "NONE",
    netstat: true,
    arr: [
      { name: "Combined Chart", type: "line_chart" },
      { name: "Single Chart", type: "bar_chart" },
      { name: "Gauge Chart", type: "gague_chart" },
      { name: "Pie Chart", type: "pie_chart" },
      { name: "Mesh Chart", type: "mesh_chart" },
      { name: "Profile Group", type: "profile_group" },
      {
        name: "Map Based Chart",
        type: "NONE",
        netstat: true,
        arr: [
          { name: "Density Heatmap", type: "mapbox_density_heatmap" },
          { name: "Bubble Map", type: "bubble_map" },
        ],
      },
    ],
  },
];

const CustomPannelDashboard_Header = (props) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const activeTabInfo = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const isWebglDashboard = useSelector(
    (state) => state.customPanelModify.isWebglDashboard
  );
  const panelUserAccessInfo = useSelector(
    (state) => state.customPanelModify.panelUserAccessInfo
  );

  const selectedNewPanelHandler = (panel) => {
    let panel_ = {
      name: panel.name,
      panel_config: getPanelData(panel.type.toLowerCase()),
    };
    // console.log(panel);
    dispatch(postCustomDashboardPanel(activeTabInfo.guid, panel_));
  };

  window.createSingleImagePanel = (image_info) => {
    let p = { name: "SINGLE IMAGE", type: "single_image" };
    let panel_ = {
      name: p.name,
      panel_config: getPanelData(p.type.toLowerCase()),
    };
    panel_.panel_config.data = image_info;
    // console.log(panel_);
    dispatch(postCustomDashboardPanel(activeTabInfo.guid, panel_));
  };

  window.closeDashboard = () => {
    if (isWebglDashboard) {
      window.deactivateWebGLDashboard();
    } else {
      window.loadWebGlAndCloseCustomPanel();
    }
    window.SetWebGLKeyboardCaptureStatus(1);
    dispatch({
      type: "SET_CUSTOM_PANEL_AS_INITIAL_STATE",
    });
  };

  return (
    <div className="panel_header_ui">
      <div
        className="dashboard-header d-flex justify-content-center"
        style={{ padding: "5px 0px" }}
      >
        <div className="summary-conf-icon-section">
          {/* <span
            className="go-to-webgl-icon"
            id="3D-scenario-icon-project"
            onClick={() => {
             
            }}
          >
            {getIconFromList("WEBGL_ICON", { cursor: "pointer" })}
          </span> */}
          {activeTabInfo ? (
            activeTabInfo.guid &&
            panelUserAccessInfo &&
            panelUserAccessInfo.dashboard.operation ? (
              <>
                <div className="dropdown">
                  <span
                    id="new-dataset-input-modal__opener-el"
                    data-bs-toggle="modal"
                    data-bs-target="#new-dataset-input-modal"
                    title="Add New Dataset"
                  >
                    {getIconFromList("ADD_NEW_DATASET_CUSTOM_ICON", {
                      cursor: "pointer",
                    })}
                  </span>
                </div>
                <div className="dropdown">
                  <span
                    id="add-new-pannel-btn-icon"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    title="Add New Panel"
                    data-mdb-toggle="dropdown"
                  >
                    {getIconFromList("ADD_NEW_PANEL_CUSTOM_ICON", {
                      cursor: "pointer",
                    })}
                  </span>
                  <ul
                    className="dropdown-menu create-panel"
                    aria-labelledby="add-new-pannel-btn-icon"
                  >
                    {panelList.map((panel, index) => (
                      <li key={index}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            if (panel.type !== "NONE") {
                              selectedNewPanelHandler(panel);
                            }
                          }}
                        >
                          {panel.type !== "NONE" ? (
                            <>{panel.name}</>
                          ) : (
                            <>{panel.name} &raquo;</>
                          )}
                        </a>
                        {panel.netstat ? (
                          <>
                            <ul className="dropdown-menu dropdown-submenu">
                              {panel.arr.map((sub_panel, sub_panel_index) => (
                                <li key={sub_panel_index}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (sub_panel.type !== "NONE") {
                                        selectedNewPanelHandler(sub_panel);
                                      }
                                    }}
                                  >
                                    {sub_panel.type !== "NONE" ? (
                                      <>{sub_panel.name}</>
                                    ) : (
                                      <>{sub_panel.name} &raquo;</>
                                    )}
                                  </a>

                                  {sub_panel.netstat ? (
                                    <>
                                      <ul className="dropdown-menu dropdown-submenu">
                                        {sub_panel.arr.map(
                                          (sub_panel_item, index) => (
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  selectedNewPanelHandler(
                                                    sub_panel_item
                                                  );
                                                  console.log();
                                                }}
                                              >
                                                {sub_panel_item.name}
                                              </a>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <span
            title="Image Panel"
            onClick={() => {
              // console.log("here");
              window.dashboardImgDocResourceModalOpener(
                panelFieldInfo,
                activeTabInfo,
                "images"
              );
            }}
            style={{ cursor: "pointer" }}
          >
            {getIconFromList("PHOTO_LIBRARY", {
              cursor: "pointer",
            })}
          </span>
          <span
            title="Document Panel"
            onClick={() => {
              // console.log("here");
              window.dashboardImgDocResourceModalOpener(
                panelFieldInfo,
                activeTabInfo,
                "documents"
              );
            }}
            style={{ cursor: "pointer" }}
          >
            {getIconFromList("DOCUMENTS_LIST", {
              cursor: "pointer",
            })}
          </span>
          <span
            className="field-tree-icon"
            title="Field Tree"
            onClick={() => {
              window.showHideTreeView();
            }}
          >
            {getIconFromList("ACCOUNT_TREE_ICON", {
              cursor: "pointer",
            })}
          </span>
          {/* <span
            className="webgl-dashboard-exit-icon"
            title="Close Dashboard"
            onClick={() => {
              document.getElementById("3D-scenario-icon-project").click();
            }}
          >
            EXIT
          </span> */}
        </div>
      </div>

      <AddDataset />
      <CustomPannelDashboard_HeaderSettings />
      <CustomPannelDashboard_HeaderTabs />
    </div>
  );
};

export default CustomPannelDashboard_Header;
