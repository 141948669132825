import React from "react";

export const getIconFromList = (iconName, styles) => {
  switch (iconName) {
    case "ARROW_DOWN":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={16}
          height={16}
          fill="currentColor"
          className="bi bi-arrow-down"
          viewBox="0 0 16 16"
          style={styles}
        >
          <path
            fillRule="evenodd"
            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
          />
        </svg>
      );
    case "ZOOM_IN":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
          style={styles}
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g>
              <g>
                <path d="M15,3l2.3,2.3l-2.89,2.87l1.42,1.42L18.7,6.7L21,9V3H15z M3,9l2.3-2.3l2.87,2.89l1.42-1.42L6.7,5.3L9,3H3V9z M9,21 l-2.3-2.3l2.89-2.87l-1.42-1.42L5.3,17.3L3,15v6H9z M21,15l-2.3,2.3l-2.87-2.89l-1.42,1.42l2.89,2.87L15,21h6V15z" />
              </g>
            </g>
          </g>
        </svg>
      );
    case "ZOOM_OUT":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 48 48"
          height="68px"
          viewBox="0 0 48 78"
          width="48px"
          fill="#FFFFFF"
          style={styles}
        >
          <path d="M12 41.5v-3h24.05v3Z" />
        </svg>
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   enable-background="new 0 0 24 24"
        //   height="24px"
        //   viewBox="0 0 24 24"
        //   width="24px"
        //   fill="#FFFFFF"
        //   style={styles}
        // >
        //   <g>
        //     <rect fill="none" height="24" width="24" />
        //   </g>
        //   <g>
        //     <path d="M9,9l0-6L7,3l0,2.59L3.91,2.5L2.5,3.91L5.59,7L3,7l0,2L9,9z M21,9V7l-2.59,0l3.09-3.09L20.09,2.5L17,5.59V3l-2,0l0,6L21,9z M3,15l0,2h2.59L2.5,20.09l1.41,1.41L7,18.41L7,21h2l0-6L3,15z M15,15l0,6h2v-2.59l3.09,3.09l1.41-1.41L18.41,17H21v-2L15,15z" />
        //   </g>
        // </svg>
      );
    case "LAUNCH":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
        </svg>
      );
    case "PHOTO_LIBRARY":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M20 4v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 9.67l1.69 2.26 2.48-3.1L19 15H9zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z" />
        </svg>
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   height="24px"
        //   viewBox="0 0 24 24"
        //   width="24px"
        //   fill="#FFFFFF"
        // >
        //   <path d="M0 0h24v24H0z" fill="none" />
        //   <path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z" />
        // </svg>
      );
    case "DOCUMENTS_LIST":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M11 7h6v2h-6zm0 4h6v2h-6zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7zM20.1 3H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM19 19H5V5h14v14z" />
        </svg>
      );
    case "RELOAD":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          fill="#fff"
          className="bi bi-x"
          viewBox="0 0 16 16"
          style={styles}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
        </svg>
      );
    case "PLUS_SQUARE":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          fill="currentColor"
          className="bi bi-plus-square"
          viewBox="0 0 16 16"
          style={styles}
        >
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
      );
    case "FILE_LOCK":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          fill="currentColor"
          className="bi bi-file-lock2"
          viewBox="0 0 16 16"
          style={styles}
        >
          <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z" />
        </svg>
      );
    case "FILE_EARMARK_TEXT":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="46"
          height="46"
          fill="currentColor"
          className="bi bi-file-earmark-text"
          viewBox="0 0 16 16"
          style={styles}
        >
          <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
          <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
        </svg>
      );
    case "CROSS_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#fff"
          className="bi bi-x"
          viewBox="0 0 16 16"
          style={styles}
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      );
    case "PENCIL_SQUARE":
      return (
        <svg
          fill="#FFFFFF"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="24px"
          height="24px"
          style={styles}
        >
          <rect fill="none" height="24" width="24" />
          <path d="M3,10h11v2H3V10z M3,8h11V6H3V8z M3,16h7v-2H3V16z M18.01,12.87l0.71-0.71c0.39-0.39,1.02-0.39,1.41,0l0.71,0.71 c0.39,0.39,0.39,1.02,0,1.41l-0.71,0.71L18.01,12.87z M17.3,13.58l-5.3,5.3V21h2.12l5.3-5.3L17.3,13.58z" />
        </svg>
      );
    case "DELETE":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#830124"
          style={styles}
        >
          <title>Delete</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
        </svg>
      );
    case "ADD_CHART":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FEFEFE"
          style={styles}
        >
          {/* <title>Add Chart</title> */}
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M22 5v2h-3v3h-2V7h-3V5h3V2h2v3h3zm-3 14H5V5h6V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6h-2v6zm-4-6v4h2v-4h-2zm-4 4h2V9h-2v8zm-2 0v-6H7v6h2z" />
        </svg>
      );
    case "SETTINGS":
      return (
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   viewBox="0 0 24 24"
        //   width="28px"
        //   height="28px"
        //   fill="#FEFEFE"
        //   style={styles}
        // >
        //   <title>Configuration</title>
        //   <g>
        //     <path d="M0,0h24v24H0V0z" fill="none" />
        //     <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
        //   </g>
        // </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="28px"
          height="28px"
          fill="#FEFEFE"
          style={styles}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
        </svg>
      );
    case "ADD_NEW":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FEFEFE"
          style={styles}
        >
          {/* <title>Add New Dataset</title> */}
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M10 8H8v4H4v2h4v4h2v-4h4v-2h-4zm4.5-1.92V7.9l2.5-.5V18h2V5z" />
        </svg>
      );
    case "ADD_PHOTO":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FEFEFE"
          style={styles}
        >
          <title>Add Image</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 7v2.99s-1.99.01-2 0V7h-3s.01-1.99 0-2h3V2h2v3h3v2h-3zm-3 4V8h-3V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8h-3zM5 19l3-4 2 3 3-4 4 5H5z" />
        </svg>
      );
    case "ADD_FILE":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FEFEFE"
          style={styles}
        >
          <title>Add File</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z" />
        </svg>
      );
    case "WEBGL_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#FEFEFE"
          style={styles}
        >
          <title>3D Scenario</title>
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M7.53 21.48C4.26 19.94 1.92 16.76 1.56 13H.06c.51 6.16 5.66 11 11.95 11l.66-.03-3.81-3.81-1.33 1.32zm.89-6.52c-.19 0-.37-.03-.52-.08-.16-.06-.29-.13-.4-.24-.11-.1-.2-.22-.26-.37-.06-.14-.09-.3-.09-.47h-1.3c0 .36.07.68.21.95.14.27.33.5.56.69.24.18.51.32.82.41.3.1.62.15.96.15.37 0 .72-.05 1.03-.15.32-.1.6-.25.83-.44s.42-.43.55-.72.2-.61.2-.97c0-.19-.02-.38-.07-.56-.05-.18-.12-.35-.23-.51-.1-.16-.24-.3-.4-.43-.17-.13-.37-.23-.61-.31.2-.09.37-.2.52-.33.15-.13.27-.27.37-.42.1-.15.17-.3.22-.46s.07-.32.07-.48c0-.36-.06-.68-.18-.96s-.29-.51-.51-.69c-.2-.19-.47-.33-.77-.43C9.11 8.05 8.77 8 8.4 8c-.36 0-.69.05-1 .16-.3.11-.57.26-.79.45-.21.19-.38.41-.51.67-.12.26-.18.54-.18.85h1.3c0-.17.03-.32.09-.45s.14-.25.25-.34.23-.17.38-.22.3-.08.48-.08c.4 0 .7.1.89.31.19.2.29.49.29.86 0 .18-.03.34-.08.49s-.14.27-.25.37c-.11.1-.25.18-.41.24-.16.06-.36.09-.58.09h-.77v1.03h.77c.22 0 .42.02.6.07s.33.13.45.23c.12.11.22.24.29.4s.1.35.1.57c0 .41-.12.72-.35.93-.23.23-.55.33-.95.33zm8.55-5.92c-.32-.33-.7-.59-1.14-.77-.44-.18-.93-.27-1.47-.27H12v8h2.3c.55 0 1.06-.09 1.51-.27s.84-.43 1.16-.76c.32-.33.57-.73.74-1.19.17-.47.26-.99.26-1.57v-.4c0-.58-.09-1.1-.26-1.57s-.42-.87-.74-1.2zm-.39 3.16c0 .42-.05.79-.14 1.13-.1.33-.24.62-.43.85-.19.23-.43.41-.71.53-.29.12-.62.18-.99.18h-.91V9.12h.97c.72 0 1.27.23 1.64.69.38.46.57 1.12.57 1.99v.4zM12.01 0l-.66.03 3.81 3.81 1.33-1.33c3.27 1.55 5.61 4.72 5.96 8.48h1.5C23.45 4.84 18.3 0 12.01 0z" />
        </svg>
      );
    case "PLUS_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          // width="24"
          // height="24"
          viewBox="0 0 24 24"
          fill="#FEFEFE"
          style={styles}
        >
          {/* <title>Add</title> */}
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z" />
        </svg>
      );
    case "ANIMATION_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#2CA02C"
          style={styles}
        >
          <title>Play</title>
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M9.5,16.5v-9l7,4.5L9.5,16.5z" />
          </g>
        </svg>
      );
    case "STOP_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#830124"
          style={styles}
        >
          <title>Stop Animation</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M6 6h12v12H6z" />
        </svg>
      );
    case "CHANGE_CIRCLE_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <rect fill="none" height="24" width="24" />
          <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8 S16.41,20,12,20z M16.17,14.76l-1.1-1.1c0.71-1.33,0.53-3.01-0.59-4.13C13.79,8.84,12.9,8.5,12,8.5c-0.03,0-0.06,0.01-0.09,0.01 L13,9.6l-1.06,1.06L9.11,7.83L11.94,5L13,6.06l-0.96,0.96c1.27,0.01,2.53,0.48,3.5,1.44C17.24,10.17,17.45,12.82,16.17,14.76z M14.89,16.17L12.06,19L11,17.94l0.95-0.95c-1.26-0.01-2.52-0.5-3.48-1.46c-1.71-1.71-1.92-4.35-0.64-6.29l1.1,1.1 c-0.71,1.33-0.53,3.01,0.59,4.13c0.7,0.7,1.63,1.04,2.56,1.01L11,14.4l1.06-1.06L14.89,16.17z" />
        </svg>
      );
    case "RESET_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          style={styles}
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <g>
              <path d="M12,5V2L8,6l4,4V7c3.31,0,6,2.69,6,6c0,2.97-2.17,5.43-5,5.91v2.02c3.95-0.49,7-3.85,7-7.93C20,8.58,16.42,5,12,5z" />
              <path d="M6,13c0-1.65,0.67-3.15,1.76-4.24L6.34,7.34C4.9,8.79,4,10.79,4,13c0,4.08,3.05,7.44,7,7.93v-2.02 C8.17,18.43,6,15.97,6,13z" />
            </g>
          </g>
        </svg>
      );
    case "LEFT_VIEW_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          style={styles}
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <g>
              <path d="M6,5H4v16c0,1.1,0.9,2,2,2h10v-2H6V5z" />
              <path d="M18,1h-8C8.9,1,8,1.9,8,3v14c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V3C20,1.9,19.1,1,18,1z M18,17h-8v-1h8V17z M18,14h-8V6h8 V14z M18,4h-8V3h8V4z" />
              <path d="M12.5,10.25h1.63l-0.69,0.69L14.5,12L17,9.5L14.5,7l-1.06,1.06l0.69,0.69H12c-0.55,0-1,0.45-1,1V12h1.5V10.25z" />
            </g>
          </g>
        </svg>
      );
    case "FRONT_VIEW_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          style={styles}
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <path d="M19,19V5c0-1.1-0.9-2-2-2H7C5.9,3,5,3.9,5,5v14H3v2h18v-2H19z M17,19H7V5h10V19z M13,11h2v2h-2V11z" />
          </g>
        </svg>
      );
    case "REAR_VIEW_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          style={styles}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M5 20v2h5v2l3-3-3-3v2zm9 0h5v2h-5zm3-20H7C5.9 0 5 .9 5 2v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 16H7V2h10v14zm-5-9c1.1 0 2-.9 1.99-2 0-1.1-.9-2-2-2S10 3.9 10 5s.89 2 2 2z" />
        </svg>
      );
    case "BOTTOM_VIEW_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          style={styles}
        >
          <rect fill="none" height="24" width="24" />
          <path d="M22,22H2v-2h20V22z M10,2H7v16h3V2z M17,8h-3v10h3V8z" />
        </svg>
      );
    case "PENCIL_EDIT_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          style={styles}
        >
          <title>Edit</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
        </svg>
      );
    case "ACCOUNT_TREE_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
          style={styles}
        >
          <rect fill="none" height="24" width="24" />
          <path d="M22,11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3H22z M7,9H4V5h3V9z M17,15h3v4h-3V15z M17,5h3v4h-3V5z" />
        </svg>
      );
    case "CSS_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          width="24px"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          style={styles}
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <path d="M9.5,14v-1H11v0.5h2v-1h-2.5c-0.55,0-1-0.45-1-1V10c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1v1H13v-0.5h-2v1h2.5 c0.55,0,1,0.45,1,1V14c0,0.55-0.45,1-1,1h-3C9.95,15,9.5,14.55,9.5,14z M17,15h3c0.55,0,1-0.45,1-1v-1.5c0-0.55-0.45-1-1-1h-2.5v-1 h2V11H21v-1c0-0.55-0.45-1-1-1h-3c-0.55,0-1,0.45-1,1v1.5c0,0.55,0.45,1,1,1h2.5v1h-2V13H16v1C16,14.55,16.45,15,17,15z M8,10 c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v4c0,0.55,0.45,1,1,1h3c0.55,0,1-0.45,1-1v-1H6.5v0.5h-2v-3h2V11H8V10z" />
          </g>
        </svg>
      );
    case "ARROW_RIGHT_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
          style={styles}
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
          </g>
        </svg>
      );
    case "ARROW_RIGHT_ICON_PAGINATION":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="12px"
          fill="#FFFFFF"
          style={styles}
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
          </g>
        </svg>
      );
    case "PROFILE_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
          style={styles}
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g>
              <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M7.35,18.5C8.66,17.56,10.26,17,12,17 s3.34,0.56,4.65,1.5C15.34,19.44,13.74,20,12,20S8.66,19.44,7.35,18.5z M18.14,17.12L18.14,17.12C16.45,15.8,14.32,15,12,15 s-4.45,0.8-6.14,2.12l0,0C4.7,15.73,4,13.95,4,12c0-4.42,3.58-8,8-8s8,3.58,8,8C20,13.95,19.3,15.73,18.14,17.12z" />
              <path d="M12,6c-1.93,0-3.5,1.57-3.5,3.5S10.07,13,12,13s3.5-1.57,3.5-3.5S13.93,6,12,6z M12,11c-0.83,0-1.5-0.67-1.5-1.5 S11.17,8,12,8s1.5,0.67,1.5,1.5S12.83,11,12,11z" />
            </g>
          </g>
        </svg>
      );
    case "BILLING_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
          style={styles}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z" />
        </svg>
      );
    case "LOGOUT_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#FFFFFF"
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
          </g>
        </svg>
      );
    case "LIST_BULLETED_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          width="24px"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          style={styles}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z" />
        </svg>
      );
    case "ADD_NEW_DATASET_CUSTOM_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="currentColor"
          width={26}
          height={26}
          style={styles}
        >
          <g>
            <path
              d="M360.43,279.83c7.6,0,14.63-0.3,21.62,0.05c57.67,2.9,103.55,47.96,108.56,106.49c4.79,55.89-34.29,109.33-89.77,120.7
		c-47.39,9.72-86.72-5.14-117.1-42.96c-2.19-2.73-3.97-3.85-7.63-3.06c-42.68,9.22-85.78,10.56-129.14,6.46
		c-26.4-2.5-52.32-7.32-77.2-16.76c-12.41-4.71-24.22-10.57-34.29-19.44c-9.44-8.32-14.43-18.31-14.41-31.38
		c0.23-109.08,0.24-218.16-0.02-327.24c-0.03-13.72,5.13-24.13,15.25-32.62C50.91,27.8,68.23,21,86.23,15.83
		c44.4-12.77,89.81-15.24,135.63-12.37c29.73,1.86,58.99,6.58,87.13,16.79c13.5,4.9,26.39,11.02,37.3,20.65
		c9.45,8.34,14.33,18.39,14.27,31.44c-0.29,67.04-0.13,134.09-0.13,201.13C360.43,275.58,360.43,277.7,360.43,279.83z
		 M273.36,394.63c0.12,57.2,45.88,102.78,103.02,102.61c56.07-0.17,101.65-46.52,101.46-103.18
		c-0.19-55.68-46.51-101.76-102.01-101.48C318.93,292.87,273.25,338.36,273.36,394.63z M34.25,94.22c0,2.75,0,4.5,0,6.25
		c0,24.27,0.48,48.56-0.2,72.81c-0.33,11.89,4.46,20.06,13.5,26.69c11.69,8.58,24.91,13.89,38.61,18.14
		c27.84,8.64,56.5,12.48,85.52,13.64c43.13,1.73,85.75-1.48,127.15-14.71c13.48-4.31,26.47-9.81,37.6-18.9
		c7.41-6.05,11.33-13.38,11.13-23.48c-0.49-24.81-0.16-49.63-0.17-74.45c0-1.71-0.15-3.42-0.26-5.86
		c-15.06,12.57-31.89,19.91-49.73,24.93c-63.11,17.76-126.85,18.29-190.83,5.67C80.85,119.88,56.11,111.87,34.25,94.22z
		 M34.25,206.57c0,26.97,0.38,53.24-0.18,79.49c-0.23,10.79,3.72,18.89,12.14,24.53c7.91,5.31,16.26,10.27,25.03,13.91
		c26.49,10.99,54.5,15.52,82.92,17.94c39.75,3.38,79.22,1.74,118.28-6.66c2.63-0.57,5.64-2.5,7.15-4.7
		c14.14-20.67,32.74-35.57,56.18-44.38c3.82-1.44,7.72-2.64,11.34-3.87c0-25.6,0-50.68,0-75.47c-7.97,4.69-15.57,9.89-23.76,13.87
		c-22.35,10.86-46.36,16.2-70.8,19.7c-34.56,4.94-69.26,5.89-103.99,2.23C108.21,238.9,68.65,231.99,34.25,206.57z M197.88,120.46
		c8.18-0.52,23.04-1.01,37.8-2.49c26.74-2.69,53.02-7.68,77.89-18.38c10.7-4.6,20.99-9.96,28.61-19.16
		c6.71-8.1,7.41-16.44,0.55-24.12c-4.96-5.56-11.09-10.46-17.53-14.26c-17.83-10.5-37.63-15.89-57.8-19.61
		c-54.8-10.13-109.55-9.74-164.02,2.34c-19.5,4.32-38.51,10.44-55,22.25C29.56,60.49,29.33,75.61,48.47,88.5
		c9.34,6.29,19.83,11.38,30.46,15.18C115.18,116.62,153,120.02,197.88,120.46z M269.32,350.25c-2.21,0.25-3.62,0.34-5,0.59
		c-37.68,6.61-75.57,8.14-113.62,4.35c-41.09-4.1-81.39-10.92-116.44-36.76c0,2.19,0,3.91,0,5.64c0,24.46,0.57,48.93-0.23,73.36
		c-0.41,12.67,4.92,21.1,14.57,27.78c3.86,2.67,7.92,5.09,12.09,7.23c17.06,8.74,35.36,13.74,54.06,17.31
		c41.78,7.99,83.83,8.74,126.01,4.07c10.85-1.2,21.6-3.34,32.87-5.13C257.64,416.22,256.21,383.95,269.32,350.25z"
            />
            <path
              d="M369.13,401.71c-10.93,0-21.22,0-31.5,0c-1.81,0-3.64,0.12-5.43-0.08c-3.92-0.44-6.56-2.55-6.43-6.66
		c0.12-4.04,2.66-6.23,6.73-6.26c9.78-0.07,19.56-0.04,29.34-0.05c2.15,0,4.3,0,7.29,0c0-4.78,0-9.22,0-13.66
		c0-7.79-0.03-15.58,0.02-23.37c0.03-4.29,2.27-7.09,6.55-7.05c4.29,0.03,6.44,2.88,6.46,7.16c0.03,10.14,0.02,20.29,0.02,30.43
		c0,1.95,0,3.9,0,6.49c10.77,0,21.04,0,31.31,0c1.81,0,3.62-0.02,5.43,0.05c4.08,0.16,6.61,2.34,6.61,6.37
		c0.01,4.39-2.91,6.51-7.08,6.57c-8.69,0.13-17.39,0.05-26.08,0.06c-3.21,0-6.42,0-10.2,0c0,10.81,0,21.08,0,31.36
		c0,1.81,0.08,3.63-0.04,5.43c-0.27,4.05-2.56,6.56-6.54,6.52c-3.97-0.04-6.36-2.58-6.41-6.64c-0.11-9.24-0.05-18.48-0.05-27.72
		C369.13,407.97,369.13,405.27,369.13,401.71z"
            />
            <path
              d="M187.82,189.8c-16.37-1.31-34.59-2.72-52.8-4.24c-5.75-0.48-8.62-3.14-8.26-7.23c0.38-4.36,3.78-6.06,9.88-5.77
		c24.21,1.15,48.43,2.34,72.65,3.02c8.1,0.23,16.26-0.97,24.37-1.66c5.76-0.49,9.01,1.27,9.51,5.5c0.51,4.33-2.01,6.98-7.87,7.4
		C220.13,187.91,204.95,188.74,187.82,189.8z"
            />
            <path
              d="M285.19,189.02c-10.66-0.08-19.36-8.86-19.26-19.44c0.1-10.66,8.75-19.23,19.47-19.27c10.89-0.05,19.74,8.91,19.51,19.74
		C304.68,180.72,295.97,189.1,285.19,189.02z M285.49,175.99c3.79-0.32,6.28-2.45,6.25-6.43c-0.03-3.7-2.35-5.99-6.16-6.1
		c-3.83-0.11-6.24,2.11-6.44,5.78C278.91,173.2,281.22,175.54,285.49,175.99z"
            />
            <path
              d="M191.27,300.33c-19.12-1.57-38.25-3.05-57.35-4.77c-5.21-0.47-8-3.68-7.09-7.57c1.06-4.54,4.3-5.86,8.67-5.28
		c32.47,4.32,65.01,4.62,97.6,1.62c0.72-0.07,1.44-0.13,2.17-0.19c4.12-0.3,7.33,0.96,7.86,5.51c0.55,4.81-2.4,6.94-6.74,7.42
		c-5.04,0.55-10.1,1.17-15.16,1.28c-9.95,0.2-19.92,0.06-29.87,0.06C191.32,299.04,191.3,299.69,191.27,300.33z"
            />
            <path
              d="M285.29,260.49c10.72-0.07,19.47,8.45,19.62,19.1c0.15,10.77-8.85,19.87-19.61,19.81c-10.51-0.06-19.41-9.02-19.37-19.51
		C265.98,269.21,274.6,260.56,285.29,260.49z M283.86,273.25c-1.27,1.63-4.09,3.7-4.59,6.23c-0.76,3.85,2.05,6.53,6,6.69
		c3.7,0.15,6.24-2.14,6.46-5.88C291.97,276.27,289.55,274.06,283.86,273.25z"
            />
            <path
              d="M188.8,410.25c-16.79-1.3-35.52-2.67-54.25-4.24c-5.53-0.46-8.59-3.65-7.76-7.69c0.95-4.65,4.27-5.59,8.6-5.34
		c21.46,1.24,42.92,2.77,64.41,3.28c11.88,0.28,23.81-1.35,35.72-1.95c5.45-0.27,8.69,3.22,7.58,7.85
		c-0.91,3.81-3.82,4.96-7.34,5.18C220.8,408.25,205.83,409.19,188.8,410.25z"
            />
          </g>
        </svg>
      );
    case "ADD_NEW_PANEL_CUSTOM_ICON":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          width={24}
          height={24}
          viewBox="0 0 512 512"
          style={styles}
        >
          <g>
            <path
              d="M111.75,507.48c-8.04-2.65-15.91-5.97-19.79-13.99c-2.42-5-4.44-10.77-4.53-16.24c-0.45-27.15-0.25-54.31-0.19-81.46
		c0.02-8.33,5.79-13.16,12.82-11c4.39,1.35,6.76,5.17,6.77,11.28c0.04,17.01,0.01,34.03,0.01,51.04c0,9.16-0.01,18.32,0,27.48
		c0.02,9.97,3.28,13.26,13.25,13.26c89.95,0.01,179.9,0,269.86,0c28.13,0,56.26,0.01,84.39,0c9.96,0,13.24-3.29,13.24-13.26
		c0.01-118.43,0-236.87,0-355.3c0-1.6,0-3.21,0-5.39c-2.11,0-3.85,0-5.59,0c-17.34,0-34.67,0.04-52.01-0.01
		c-19.28-0.06-31.62-12.35-31.69-31.58c-0.06-18.97-0.01-37.95-0.01-57.72c-1.85,0-3.56,0-5.27,0c-90.93,0-181.87,0-272.8,0
		c-10.2,0-13.36,3.24-13.38,13.62c0,2.77,0,5.53,0,8.7c6.22-0.74,12.11-1.48,18.02-2.13c2.44-0.27,4.89-0.42,7.34-0.48
		c7.94-0.2,11.91,3.53,11.96,11.46c0.08,14.89,0.02,29.77,0.02,44.66c0,23.39,0.01,46.78,0,70.18c-0.01,9.71-3.29,13.03-12.9,13.04
		c-37.78,0.01-75.56,0.01-113.34,0c-9.95,0-13.6-3.48-13.02-13.31C8.06,116.88,33.97,78.6,82.12,55.44
		c3.78-1.82,5.62-3.53,5.19-7.86c-0.42-4.21-0.18-8.5-0.07-12.75C87.68,17.59,99.92,5.29,117.19,5c12.75-0.21,25.51-0.05,38.27-0.05
		c82.76,0,165.51,0.04,248.27-0.09c5.83-0.01,10.24,1.73,14.35,5.88c27.65,27.86,55.48,55.56,83.2,83.35
		c2.16,2.17,3.97,4.69,5.94,7.05c0,127.27,0,254.54,0,381.81c-0.99,2.56-1.85,5.17-2.98,7.67c-4.24,9.35-12.22,13.99-21.55,16.87
		C359.04,507.48,235.39,507.48,111.75,507.48z M25.27,163.67c33.17,0,66.06,0,98.88,0c0-33.2,0-66.07,0-98.96
		C72.74,68.29,28.86,112.21,25.27,163.67z M471,94.23c-17.6-17.61-35.59-35.6-53.08-53.09c0,13.37-0.1,27.9,0.05,42.43
		c0.06,6.35,3.76,10.49,9.86,10.59C442.64,94.39,457.46,94.23,471,94.23z"
            />
            <path
              d="M159.78,199.24c0-27.81,0-54.78,0-81.76c0-8.99-0.08-17.99,0.03-26.98c0.09-7.35,4.01-11.03,11.37-11.01
		c56.94,0.13,109.1,40.59,123.31,95.66c16.39,63.52-13.88,126.22-73.85,152.94C167.7,351.68,101.9,334.43,66.96,288
		c-16.94-22.5-26.06-47.66-26.85-75.82c-0.27-9.49,3.16-12.94,12.85-12.95c33.51-0.01,67.02,0,100.53-0.01
		C155.41,199.24,157.32,199.24,159.78,199.24z M60.29,218.87c3.64,47.77,40.51,88.75,87.77,98.07
		c47.43,9.36,96.52-14.08,118.64-56.36c23.48-44.87,11.77-94.39-13.07-121.63c-1.27,1.21-2.57,2.4-3.81,3.64
		c-23.59,23.58-47.22,47.12-70.69,70.8c-3.85,3.89-8.03,5.57-13.49,5.55c-33.18-0.14-66.37-0.07-99.55-0.07
		C64.16,218.87,62.24,218.87,60.29,218.87z M179.65,183.07c19.57-19.57,39.36-39.36,59.28-59.29
		c-16.89-13.76-36.64-22.04-59.28-24.23C179.65,127.88,179.65,155.89,179.65,183.07z"
            />
            <path
              d="M261.33,334.51c18.79,0.5,32.2,17.83,27.72,36.21c-0.75,3.06-0.12,4.76,1.95,6.8c10.6,10.44,21.13,20.95,31.56,31.56
		c2.07,2.1,3.8,2.9,6.78,1.78c6.01-2.25,12.21-2.15,18.22,0.19c2.44,0.95,4.05,0.64,5.98-1.31c17.02-17.2,34.14-34.3,51.31-51.34
		c1.98-1.97,2.39-3.63,1.62-6.43c-4.45-16.2,4.94-32.03,21.28-36.33c15.14-3.99,31.1,5.56,35.29,21.11
		c4.17,15.5-5.05,31.88-20.98,35.55c-5.43,1.25-11.58,0.16-17.24-0.79c-2.78-0.46-4.54-0.97-6.79,1.31
		c-16.77,16.98-33.66,33.85-50.62,50.65c-2.05,2.03-2.35,3.68-1.44,6.39c4.08,12.19-0.47,25.46-10.99,32.91
		c-10.04,7.1-24.04,6.97-33.99-0.32c-10.3-7.55-14.73-20.64-10.67-32.63c0.99-2.93,0.67-4.72-1.5-6.86
		c-10.39-10.19-20.68-20.48-30.88-30.85c-1.98-2.02-3.63-2.69-6.33-1.35c-6.66,3.32-13.65,3.07-20.46,0.63
		c-2.81-1.01-4.44-0.44-6.4,1.56c-10.09,10.26-20.24,20.45-30.53,30.51c-1.98,1.93-2.12,3.53-1.32,5.99
		c4.33,13.31-0.64,27.1-12.12,34.15c-11.68,7.16-26.43,5.56-36.14-3.94c-9.8-9.58-11.55-24.56-4.26-36.5
		c6.97-11.42,20.81-16.4,34.17-12.07c2.55,0.83,4.11,0.41,5.97-1.49c10.08-10.27,20.25-20.45,30.51-30.52
		c2.15-2.11,2.55-3.87,1.62-6.83C226.69,353.26,241.44,333.98,261.33,334.51z M347.91,438.85c0.12-5.13-4.37-9.77-9.57-9.86
		c-5.26-0.1-9.71,4.23-9.79,9.53c-0.08,5.27,4.25,9.82,9.46,9.94C343.07,448.57,347.79,443.99,347.91,438.85z M185.42,429.01
		c-5.19-0.1-9.77,4.33-9.87,9.54c-0.1,5.18,4.35,9.82,9.53,9.92c5.1,0.11,9.79-4.43,9.92-9.59
		C195.13,433.79,190.59,429.11,185.42,429.01z M434.67,353.8c5.27,0.01,9.72-4.42,9.71-9.65c-0.01-5.27-4.4-9.6-9.73-9.59
		c-5.3,0.01-9.76,4.41-9.75,9.61C424.9,349.36,429.4,353.8,434.67,353.8z M270.38,363.67c-0.08-5.25-4.55-9.56-9.85-9.5
		c-5.35,0.06-9.59,4.4-9.53,9.75c0.07,5.31,4.47,9.6,9.78,9.52C266.07,373.35,270.46,368.88,270.38,363.67z"
            />
            <path
              d="M393.91,141.38c18.79,0,37.59-0.03,56.38,0.03c2.26,0.01,4.64,0.13,6.74,0.83c4.14,1.39,6.26,4.58,6.25,8.97
		c-0.01,4.39-2.18,7.47-6.29,8.97c-1.5,0.55-3.19,0.79-4.79,0.79c-38.89,0.04-77.79,0.03-116.68,0.04c-4.82,0-8.76-1.59-10.66-6.26
		c-2.69-6.59,2.02-13.08,9.73-13.32c6.86-0.21,13.73-0.05,20.59-0.05C368.09,141.38,381,141.38,393.91,141.38z"
            />
            <path
              d="M393.91,208.12c-19.45,0-38.89,0.04-58.34-0.02c-7.04-0.02-11.61-4.14-11.45-10.01c0.15-5.56,4.44-9.46,10.91-9.56
		c8.33-0.13,16.67-0.03,25-0.03c30.4,0,60.79-0.01,91.19,0.01c6.64,0,10.68,2.58,11.8,7.35c1.64,6.98-3.07,12.22-11.26,12.25
		C432.47,208.17,413.19,208.12,393.91,208.12z"
            />
            <path
              d="M359.52,235.61c8.15,0,16.31-0.07,24.46,0.02c6.81,0.07,11.21,3.89,11.34,9.58c0.13,5.88-4.38,9.97-11.41,10
		c-16.14,0.07-32.29,0.07-48.43,0c-6.81-0.03-11.21-3.87-11.35-9.58c-0.15-5.86,4.41-9.93,11.43-10
		C343.54,235.54,351.53,235.61,359.52,235.61z"
            />
            <path
              d="M430.13,235.67c5.13-0.11,9.74,4.38,9.84,9.58c0.1,5.19-4.32,9.79-9.5,9.91c-5.2,0.11-9.68-4.29-9.76-9.57
		C420.63,240.28,424.9,235.78,430.13,235.67z"
            />
            <path
              d="M106.76,358.46c-0.11,5.12-4.82,9.68-9.89,9.57c-5.12-0.11-9.67-4.82-9.57-9.9c0.11-5.12,4.82-9.68,9.89-9.57
		C102.31,348.67,106.86,353.38,106.76,358.46z"
            />
          </g>
        </svg>
      );
    default:
      return "";
  }
};
