import * as React from "react";
import { Range, Direction } from "react-range";
// import { getTrackBackground } from "./utils";

// const STEP = 0.1;
// const MIN = 0;
// const MAX = 100;

const ReactRangeInputVertical = ({
  step,
  min,
  max,
  selectedFilteredValue,
  setSelectedFilteredValue,
  direction,
  colorbarscaleClass,
  height,
  range,
}) => {
  const [values, setValues] = React.useState(
    selectedFilteredValue.length ? selectedFilteredValue : [min, max]
  );

  React.useEffect(() => {
    if (selectedFilteredValue.length > 0) {
      setValues(selectedFilteredValue);
    }
  }, [selectedFilteredValue]);

  return (
    <div
      className="__react-range__"
      style={{
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        direction="to top"
        // rtl={rtl}
        onChange={(values) => {
          setValues(values);
          setSelectedFilteredValue(values);
        }}
        draggableTrack={true}
        renderTrack={({ props, children }) => (
          <>
            <span><strong>{range.max}</strong></span>
            <div
              className="__rander-track-mouse-event__"
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                display: "flex",
                height: height + "px",
                width: "36px",
              }}
            >
              <div
                className={"__rander-track-props__" + " " + colorbarscaleClass}
                ref={props.ref}
                style={{
                  width: "5px",
                  height: "100%",
                  borderRadius: "4px",
                  background: "#548BF4",
                  // background: "linear-gradient(90deg, rgb(255 0 0) 20%, rgb(31 255 0) 40%, rgba(4,0,255,1) 40%)",
                  // background: getTrackBackground({
                  //   values,
                  //   colors: ["#ccc", "#548BF4", "#ccc"],
                  //   min: min,
                  //   max: max,
                  //   rtl: false,
                  // }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
            <span><strong>{range.min}</strong></span>
          </>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            className="__render-thumb__"
            {...props}
            style={{
              ...props.style,
              height: "14px",
              width: "26px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              className="__render-thumb-abs__"
              style={{
                position: "absolute",
                top: "-20px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: "10px",
                fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                padding: "2px 4px",
                borderRadius: "4px",
                backgroundColor: "#548BF4",
                transform: "rotate(90deg)",
                right: "-16px",
              }}
            >
              {parseFloat(values[index]).toFixed(2)}
            </div>
            <div
              className="__render-thumb-abs-sd-bar__"
              style={{
                height: "16px",
                width: "5px",
                transform: "rotate(90deg)",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default ReactRangeInputVertical;
