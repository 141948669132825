const initialValue = {
    gu_id: null
  };
  
  export default function assetsData(state = initialValue, action) {
    switch (action.type) {
      case "SET_PROJECTS_GU_ID":
        return { ...state, gu_id: action.payload };
      default:
        return state;
    }
  }
  