import React from "react";
import TopHeader from "../../components/TopHeader";
import "./panelstyle.css";
import DashboardLayout from "./DashboardLayout";

const AppPanel = () => {
  return (
    <div className="app_panel">
      <TopHeader />
      <DashboardLayout />
    </div>
  );
};

export default AppPanel;
