import React, { useEffect } from "react";
import TopHeader from "../../components/TopHeader";
import ContainerFluid from "../../components/ContainerFluid";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../actions/authActions";
import ProfileInfoCard from "./ProfileInfoCard";
import ProfileContentCard from "./ProfileContentCard";
import Loader from "../../components/Loader";
import "./style.css";

const Profile = () => {
  const dispatch = useDispatch();
  // const project_info = useSelector((state) => state.auth.project_info);
  // const subscription = useSelector((state) => state.auth.subscription);
  const auth = useSelector((state) => state.auth);

  // if (!auth.stats) {
  //   setTimeout(() => {
  //     dispatch(getUserProfile());
  //   }, 2000);
  // }

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <ContainerFluid>
      <TopHeader />

      <div className="app-content-container" id="profile__container">
        <div className="user__panel--dashboard">
          <div className="project__status"></div>
          <div className="user__panel row">
            {auth.stats ? (
              <ProfileInfoCard
                info={auth.stats}
              />
            ) : (
              <div className="user__panel--col user__panel--info col-md-3">
                <div className="card card-body user__panel--card">
                  <div className="custom__loader--container">
                    <Loader />
                    <small>Please wait...</small>
                  </div>
                </div>
              </div>
            )}

            {auth.avatar ? (
              <ProfileContentCard auth={auth} />
            ) : (
              <div className="user__panel--col user__panel--content col-md-9">
                <div className="card card-body user__panel--card">
                  <div className="custom__loader--container">
                    <Loader />
                    <small>Retriving user details...</small>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ContainerFluid>
  );
};

export default Profile;
