export const generatePieChartLayout = (panel_config, pieGrid) => ({
  autosize: true,
  // margin: {
  //   // l: 50,
  //   // r: 50,
  //   b: 5,
  //   t: 5,
  //   // pad: 10,
  // },
  // width: panel_config.defaultPosition.width,
  // // 20px Chart
  // height: panel_config.defaultPosition.height - 21,
  margin: {
    l: 50,
    r: 5,
    b: 10,
    t: 20,
    pad: 5,
  },

  paper_bgcolor: "lightblue",
  plot_bgcolor: "#ddd",
  grid: pieGrid,

  legend: {
    x: 1,
    y: 1,
    // xanchor: "right",
    // // xanchor: "center",
    orientation: "h", //To place the legend below chart
    font: {
      family: "Arial, sans-serif",
      size: 10,
      color: "grey",
    },
  },
});
