import React from "react";
import Modal from "react-modal";
import styles from "./ModalAT.module.css";

const customStyles = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "100%",
  },
};

const ModalAT = ({
  id,
  isOpen,
  onAfterOpen,
  onRequestClose,
  shouldCloseOnOverlayClick,
  contentLabel,
  modalTitle,
  children,
}) => {
  return (
    <Modal
      id={id}
      className={styles.ModalAT}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={customStyles}
      modalTitle={modalTitle}
      contentLabel={contentLabel}
      ariaHideApp={false}
    >
      <div className={styles.__content_block} style={{boxShadow:'0px 0px 5px #10c7cd', borderRadius:'4px'}}>
        <div className={styles.__header}>
          <h5 className={styles.__header_title}>{modalTitle}</h5>
          <button className={styles.__close_btn} onClick={onRequestClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </button>
        </div>
        <div className={styles.__body}>{children}</div>
      </div>
    </Modal>
  );
};
export default ModalAT;

