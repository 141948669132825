import React from "react";
import "./style.css";

const Loader = () => {
  return (
    <div className="custom__loader">
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  );
};

export default Loader;
