import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <Fragment>
    <h1 style={{ marginTop: "20%", textAlign: "center" }}>404</h1>
    <h2 style={{ marginTop: "2%", textAlign: "center" }}>PAGE NOT FOUND</h2>
    <Link to="/">
      <h4 style={{ marginTop: "2%", textAlign: "center" }}>LOGIN HERE</h4>
    </Link>
  </Fragment>
);

export default NotFound;
