import React, { useEffect, useState } from "react";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { generateChartLayout } from "./layout/layout.default";
import { getIconFromList } from "../../../../static/IconServe";
import request from "../../../../utils/request";
import { useSelector } from "react-redux";

const Plot = createPlotlyComponent(Plotly);

const lineColorList = [
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
  "FFFFFF", "00F8AE", "FDDD60", "FF6E76", "58D9F9", "05C091", "FF8A45", "8D48E3", "DD79FF",
];

const SocketChartLine = ({
  chart,
  panel,
  checklineChartScrollbarIsPresent,
  setCheckLineChartScrollbarIsPresent,
}) => {
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );

  const [initialChartData, setInitialChartData] = useState([]);
  const [isAnimation, setIsAnimation] = useState(false);
  const [sliderLvlVal, setSliderLvlVal] = useState({ start: 1, end: 20 });
  const [sliderVal, setSliderVal] = useState(1);
  const [dataLimit, setDataLimit] = useState(5000);
  const [lineChartPage, setLineChartPage] = useState(1);
  const [lineChartPageFlag, setLineChartPageFlag] = useState(false);
  const [isLoaderOn, setIsLoaderOn] = useState(true);

  useEffect(() => {
    if (sliderLvlVal.end === 1 || checklineChartScrollbarIsPresent)
      document.getElementById("rnd-section-chart-" + panel.guid).style.height = panel.panel_config.defaultPosition.height - 0 + "px";
    setCheckLineChartScrollbarIsPresent(false);
  }, [sliderLvlVal, checklineChartScrollbarIsPresent]);

  useEffect(async () => {
    setIsLoaderOn(true);
    const { y_axis_categories } = chart;
    const initialChartData = [];

    for (let i = 0; i < y_axis_categories.length; i++) {
      initialChartData.push({
        x: [],
        y: [],
        mode: "lines",
        name: y_axis_categories[i].series_name,
        line: {
          color: lineColorList[i],
          width: 1,
          // dash: "dot"
        },
      });
    }

    setInitialChartData(initialChartData);
    if (isAnimation) {
      let WS = new WebSocket(
        `${process.env.REACT_APP_WS_HOST}/ws/${panelFieldInfo.panel_type}/${panelFieldInfo.panel_type_guid}/charts/${chart.guid}`
      );
      if (initialChartData.length && WS) {
        WS.onopen = () => console.info("WS Connected...");

        WS.onmessage = async (evt) => {
          const { data } = await JSON.parse(evt.data);
          data.y_axis_categories.forEach((axis) => {
            initialChartData.forEach((chart, i) => {
              // // console.log(chart);
              if (initialChartData[i].x.length > 50) {
                initialChartData[i].x.shift();
                initialChartData[i].y.shift();
              }
              if (axis.series_name === chart.name) {
                initialChartData[i].x.push(data.x_axis_value);
                initialChartData[i].y.push(axis.value);
                setInitialChartData((xData) => [...xData, initialChartData]);
              }
            });
          });
          setIsLoaderOn(false);
        };

        WS.onclose = () => {
          WS.close();
          // // console.log("WS disconnected");
        };

        WS.onerror = (errEvt) => {
          console.error("WS error observed: ", errEvt);
          // getChartList();
        };

        return () => {
          WS && WS.close();
        };
      }
    } else {
      try {
        let { data } = await request.get(
          `/api/${panelFieldInfo.panel_type}/${panelFieldInfo.panel_type_guid}/charts/${chart.guid}/data/?page=1&limit=${dataLimit}`
        );
        setSliderLvlVal({
          start: 1,
          end: data.total_pages,
        });
        data.data.y_axis_categories.forEach((axis) => {
          initialChartData.forEach((chart, i) => {
            // // console.log(chart);
            if (axis.series_name === chart.name) {
              initialChartData[i].x = data.data.x_axis_value;
              initialChartData[i].y = axis.value;
              setInitialChartData((xData) => [...xData, initialChartData]);
            }
          });
        });
        setIsLoaderOn(false);
      } catch (error) {
        // console.log("catch", error);
      }
    }
  }, [isAnimation, panel]);

  useEffect(() => {
    if (lineChartPageFlag) {
      getLineChartData(lineChartPage);
    }
  }, [lineChartPage]);

  const getLineChartData = async (pageNo) => {
    setIsLoaderOn(true);
    try {
      let { data } = await request.get(
        `/api/${panelFieldInfo.panel_type}/${panelFieldInfo.panel_type_guid}/charts/${chart.guid}/data/?page=${pageNo}&limit=${dataLimit}`
      );
      setSliderLvlVal({
        start: 1,
        end: data.total_pages,
      });
      data.data.y_axis_categories.forEach((axis) => {
        initialChartData.forEach((chart, i) => {
          // console.log(chart);
          if (axis.series_name === chart.name) {
            initialChartData[i].x = data.data.x_axis_value;
            initialChartData[i].y = axis.value;
            setInitialChartData((xData) => [...xData, initialChartData]);
          }
        });
      });
      setIsLoaderOn(false);
    } catch (error) {
      // console.log("catch", error);
    }
  };

  const stopIcon = {
    position: "absolute",
    top: "4px",
    left: "40px",
    zIndex: 1,
  };

  const animationIcon = {
    position: "absolute",
    top: "4px",
    left: "40px",
    zIndex: 1,
    border: "1px solid #2CA02C",
  };

  return (
    <React.Fragment>
      <>
        {isAnimation ? (
          <>
            <span
              className="chart-remover"
              style={stopIcon}
              onClick={(e) => {
                setIsAnimation(false);
              }}
            >
              {getIconFromList("STOP_ICON", { cursor: "pointer" })}
            </span>
          </>
        ) : (
          <>
            <span
              className="chart-remover"
              style={animationIcon}
              onClick={(e) => {
                setIsAnimation(true);
              }}
            >
              {getIconFromList("ANIMATION_ICON", { cursor: "pointer" })}
            </span>
          </>
        )}
        <Plot
          data={initialChartData}
          layout={generateChartLayout(chart, "")}
          useResizeHandler={true}
          // className="wh-100 mt--20"
          className="wh-100"
        />
        {!isAnimation && sliderLvlVal.end > 1 ? (
          <div className="plot__chartdata_value--block chart-input-range">
            <div className="plot__chartdata_value--flex">
              <span>{sliderLvlVal.start}</span>
              <span>{sliderVal}</span>
              <span>{sliderLvlVal.end}</span>
            </div>
            <input
              type="range"
              id={"range_" + chart.guid}
              className="form-range"
              min={sliderLvlVal.start}
              max={sliderLvlVal.end}
              step="1"
              value={sliderVal}
              style={{ width: "100%" }}
              onChange={(e) => {
                e.stopPropagation();
                setIsLoaderOn(true);
                setSliderVal(e.target.value);
                setLineChartPageFlag(true);
                e.target.addEventListener(
                  "click",
                  function (event) {
                    event.stopImmediatePropagation();
                    setLineChartPage(event.target.value);
                  },
                  false
                );
              }}
            ></input>
          </div>
        ) : (
          <></>
        )}
      </>
      {isLoaderOn ? <div className="chart-loader"></div> : <></>}
    </React.Fragment>
  );
};

export default SocketChartLine;
