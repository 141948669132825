import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "toastr";
import {
  deleteCustomDashboard,
  getCustomDashboardPanels,
  postCustomDashboard,
  updateCustomDashboard,
} from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";

const CustomPannelDashboard_HeaderTabs = (props) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const customDashboardTabs = useSelector(
    (state) => state.customPanelModify.CUSTOM_DASHBOARD_DATA__TAB_LIST
  );
  const panelUserAccessInfo = useSelector(
    (state) => state.customPanelModify.panelUserAccessInfo
  );

  const [tabName, setTabName] = useState(null);
  const [typingTimer, setTypingTimer] = useState(null);

  const createNewTab = () => {
    dispatch(
      postCustomDashboard(
        {
          name: tabName,
          dashboard_type: panelFieldInfo.panel_type,
          dashboard_type_guid: panelFieldInfo.panel_type_guid,
          dashboard_config: {},
        },
        false
      )
    );
    setTabName(null);
    document.getElementById("new-tab-create-field").value = "";
  };

  const selectTabClickHandler = (data) => {
    dispatch({
      type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST",
      payload: [],
    });
    dispatch({
      type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_DATA",
      payload: data,
    });
    if (data.guid) {
      dispatch(getCustomDashboardPanels(data.guid));
    }
    window.SetWebGLKeyboardCaptureStatus(0);
  };

  return (
    <>
      {/* CUSTOM PANEL TABS */}
      <div className="custom-panel-tab-section sdf">
        <ul
          className="nav nav-pills nav-tabs-custom-panel nav-tab__curved"
          id="custom-panel-tabs"
          role="tablist"
        >
          {customDashboardTabs.length > 0
            ? customDashboardTabs.map((tab, index) => (
                <li
                  className={
                    tab.dashboard_config.is_webgl_dashboard
                      ? "nav-item ACTIVE_WEBGL_DASHBOARD"
                      : "nav-item DEACTIVE_WEBGL_DASHBOARD"
                  }
                  role="presentation"
                  key={index}
                >
                  <button
                    className="nav-link"
                    id="home-tab"
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="true"
                    onClick={(e) => {
                      selectTabClickHandler(tab);
                    }}
                  >
                    {tab.name}
                  </button>
                </li>
              ))
            : ""}
          {panelFieldInfo && panelFieldInfo.panel_type === "project" ? (
            <>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={(e) => {
                    dispatch({
                      type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_DATA",
                      payload: {
                        name: "Meta Data",
                      },
                    });
                    selectTabClickHandler({ name: "Meta Data" });
                  }}
                >
                  Meta Data
                </button>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item" role="presentation"></li>
            </>
          )}
          {panelUserAccessInfo && panelUserAccessInfo.dashboard.operation ? (
            <>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  type="button"
                  title="Add New Tab"
                  data-bs-toggle="modal"
                  data-bs-target="#custom_panel_modify__new-tab-create-modal"
                  onClick={(e) => {}}
                >
                  <span>
                    {getIconFromList("PLUS_ICON", { cursor: "pointer" })}
                  </span>
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  type="button"
                  title="Dashboard Tab Settings"
                  data-bs-toggle="modal"
                  data-bs-target="#custom-panel-tab-list-modal"
                  onClick={(e) => {}}
                >
                  <span>
                    {getIconFromList("SETTINGS", { cursor: "pointer" })}
                  </span>
                </button>
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>

      {/* NEW TAB CREATE MODAL */}
      <div
        className="modal fade"
        id="custom_panel_modify__new-tab-create-modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" style={{ color: "#FFFFFF" }}>
                Create New Tab
              </h5>
              <span
                className="panel-remove-icon"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
              </span>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  Tab Name
                </label>
                <input
                  type="text"
                  className="form-control color_white_with_border"
                  id="new-tab-create-field"
                  placeholder="New Tab"
                  onChange={(e) => {
                    e.stopPropagation();
                    setTabName(e.target.value);
                  }}
                />
              </div>
              <div className="text-right justify-content-end button-custom-group">
                <button
                  type="button"
                  className="btn btn-custom-secondary color_white_with_border"
                  data-bs-dismiss="modal"
                  disabled={!tabName}
                  onClick={() => {
                    createNewTab();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TAB EDIT */}
      <div
        className="modal fade"
        id="custom-panel-tab-list-modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{
            width: "500px",
            maxWidth: "550px",
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" style={{ color: "#FFFFFF" }}>
                Tab List
              </h5>
              <span
                className="panel-remove-icon"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
              </span>
            </div>
            <div className="modal-body">
              <div className="table-responsive table__fixed__scrollable">
                <table className="table table-dark table__atwin--default">
                  <tbody>
                    <tr>
                      <td className="text-left" style={{ width: "50%" }}>
                        <label className="m-0">Dashboard Name</label>
                      </td>
                      <td
                        className="text-right"
                        style={{ width: "50%", padding: "0 !important" }}
                      >
                        <div className="d-flex justify-content-between">
                          <label className="m-0 w-50 d-flex justify-content-center">
                            3D Appearance
                          </label>
                          <label className="m-0 w-50 d-flex justify-content-center">
                            Delete
                          </label>
                        </div>
                      </td>
                    </tr>
                    {customDashboardTabs.length > 0 ? (
                      customDashboardTabs.map((tab, index) => (
                        <tr key={index}>
                          <td className="text-left" style={{ width: "50%" }}>
                            {index !== 0 ? (
                              <input
                                className="form-control tab-name-edit-field"
                                type="text"
                                placeholder={tab.name}
                                defaultValue={tab.name}
                                onKeyUp={async (e) => {
                                  clearTimeout(typingTimer);
                                  setTypingTimer(
                                    setTimeout((evt) => {
                                      dispatch(
                                        updateCustomDashboard(tab.guid, {
                                          name: e.target.value,
                                        })
                                      );
                                    }, 2000)
                                  );
                                }}
                              />
                            ) : (
                              <>{tab.name}</>
                            )}
                          </td>
                          <td className="text-right" style={{ width: "50%" }}>
                            {index > 0 ? (
                              <div className="d-flex justify-content-around">
                                <div className="form-check mr-2 d-flex justify-content-center w-50">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    title="3D Appearance"
                                    defaultChecked={
                                      tab.dashboard_config.is_webgl_dashboard
                                        ? "checked"
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        dispatch(
                                          updateCustomDashboard(tab.guid, {
                                            dashboard_config: {
                                              is_webgl_dashboard: true,
                                            },
                                          })
                                        );
                                      } else {
                                        dispatch(
                                          updateCustomDashboard(tab.guid, {
                                            dashboard_config: {
                                              is_webgl_dashboard: false,
                                            },
                                          })
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center w-50">
                                  <span
                                    className="panel-remove-icon"
                                    style={{
                                      border: "1px solid #830124",
                                      borderRadius: "50%",
                                      width: "24px",
                                      height: "24px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onClick={async (e) => {
                                      dispatch(
                                        deleteCustomDashboard(
                                          tab.guid,
                                          panelFieldInfo.panel_type,
                                          panelFieldInfo.panel_type_guid
                                        )
                                      );
                                    }}
                                  >
                                    {getIconFromList("DELETE", {
                                      cursor: "pointer",
                                    })}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomPannelDashboard_HeaderTabs;
