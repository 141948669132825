import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "toastr";
import { deleteDatasetRecord } from "../../../actions/customPanelModify";
import { getIconFromList } from "../../../static/IconServe";

const CustomPannelDashboard_HeaderSettings = (props) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );
  return (
    <>
      <div
        className="modal fade"
        id="custom-panel-display-config"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{
            width: "400px",
            maxWidth: "450px",
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" style={{ color: "#FFFFFF" }}>
                Dataset List
              </h5>
              <span
                className="panel-remove-icon"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {getIconFromList("CROSS_ICON", { cursor: "pointer" })}
              </span>
            </div>
            <div className="modal-body">
              {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-dataset-list-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-dataset-list"
                    type="button"
                    role="tab"
                    aria-controls="pills-dataset-list"
                    aria-selected="true"
                  >
                    Dataset List
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-testtest-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-testtest"
                    type="button"
                    role="tab"
                    aria-controls="pills-testtest"
                    aria-selected="false"
                  >
                    testtest
                  </button>
                </li>
              </ul> */}
              <div className="table-responsive table__fixed__scrollable">
                <table className="table table-dark table__atwin--default">
                  <thead>
                    <tr>
                      <th className="text-left" colSpan={2}>
                        Dataset Name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {datasetList.length > 0 ? (
                      datasetList.map((dataset, index) => (
                        <tr key={index}>
                          <td className="text-left">{dataset.name}</td>
                          <td className="text-right">
                            <span
                              className="panel-remove-icon"
                              style={{
                                border: "1px solid #830124",
                                borderRadius: "50%",
                                width: "24px",
                                height: "24px",
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={async (e) => {
                                e.preventDefault();
                                dispatch({
                                  type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
                                  payload: true,
                                });
                                dispatch(
                                  deleteDatasetRecord(
                                    panelFieldInfo.panel_type,
                                    panelFieldInfo.panel_type_guid,
                                    dataset.guid
                                  )
                                );
                              }}
                            >
                              {getIconFromList("DELETE", {
                                cursor: "pointer",
                              })}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomPannelDashboard_HeaderSettings;
