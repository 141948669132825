import axios from "axios";
import toastr from "toastr";

const BASE_URL = process.env.REACT_APP_REST_HOST;

const getToken = () => {
  return "Bearer " + JSON.parse(localStorage.getItem("access_token"));
};

export const getProjectAccess = (project_guid) => async (dispatch) => {
  let url = `${BASE_URL}/api/projects/${project_guid}/access/`;
  await axios
    .get(url, {
      headers: {
        Authorization: getToken(),
      },
    })
    .then((res) => {
      dispatch({
        type: "SET_PANEL_USER_ACCESS_INFO",
        payload: res.data,
      });
    })
    .catch((err) => {
      // console.log(err);
    });
};

// ------------------------------------- CUSTOM DASHBOARD APIS - STARTS -------------------------------------
export const postCustomDashboard = (data, flag) => async (dispatch) => {
  // flag = crete new Summary dashboard's default panels
  let url = `${BASE_URL}/api/custom_dashboard/`;
  // return
  await axios
    .post(url, data, {
      headers: {
        Authorization: getToken(),
      },
    })
    .then((res) => {
      dispatch({
        type: "ADD_NEW_TAB_TO_CUSTOM_DASHBOARD_DATA__TAB_LIST",
        payload: res.data,
      });
      let tabs = document.querySelectorAll("#custom-panel-tabs li").length;
      if (tabs > 3) {
        // setTimeout(() => {
        document
          .querySelector(
            "#custom-panel-tabs li:nth-child(" + (tabs - 3) + ") button"
          )
          .click();
        // }, 500)
      }
      if (flag) {
        dispatch({
          type: "CREATE_DEFAULT_DASHBOARD_PANELS_FLAG",
          payload: true,
        });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const getCustomDashboard =
  (dashboard_type, dashboard_type_guid) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let url = `${BASE_URL}/api/custom_dashboard/?dashboard_type=${dashboard_type}&dashboard_type_guid=${dashboard_type_guid}`;
    // return
    await axios
      .get(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: "CREATE_DEFAULT_DASHBOARD_FLAG",
            payload: false,
          });
          dispatch({
            type: "SET_CUSTOM_DASHBOARD_DATA__TAB_LIST",
            payload: res.data,
          });
          // document.querySelector("#custom-panel-tabs li button").click();
          let wd_cnt = res.data.filter((tab) =>
            tab.dashboard_config && tab.dashboard_config.is_webgl_dashboard
              ? true
              : false
          );

          if (document.querySelector("#WebGLDashboard")) {
            if (wd_cnt.length) {
              if (
                document.querySelector(
                  "#custom-panel-tabs li.ACTIVE_WEBGL_DASHBOARD button"
                )
              ) {
                document
                  .querySelector(
                    "#custom-panel-tabs li.ACTIVE_WEBGL_DASHBOARD button"
                  )
                  .click();
              }
              // dispatch(createCloseButtonOnWebGLOverlayDashboard());
            } else {
              window.deactivateWebGLDashboard();
              toastr.info("No vital information recorded.");
            }
          } else {
            document.querySelector("#custom-panel-tabs li button").click();
          }
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        } else {
          dispatch({
            type: "CREATE_DEFAULT_DASHBOARD_FLAG",
            payload: true,
          });
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

export const getSingleCustomDashboard =
  (dashboard_guid) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/`;
    await axios
      .get(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
        dispatch({
          type: "UPDATE_SINGLE_TAB_CUSTOM_DASHBOARD_DATA__TAB_LIST",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };

export const updateCustomDashboard =
  (dashboard_guid, data) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/`;
    // return
    await axios
      .put(url, data, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        toastr.success("Update successfully.", "Success");
        dispatch(getSingleCustomDashboard(res.data.data.guid));
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };

export const deleteCustomDashboard =
  (dashboard_guid, dashboard_type, dashboard_type_guid) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}`;
    // return
    await axios
      .delete(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch(getCustomDashboard(dashboard_type, dashboard_type_guid));
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };
// ------------------------------------- CUSTOM DASHBOARD APIS - ENDS ---------------------------------------

// ------------------------------------- CUSTOM DASHBOARD - PANEL APIS - STARTS -------------------------------------
export const postCustomDashboardPanel =
  (dashboard_guid, panel_data) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/panel/`;
    // return
    await axios
      .post(url, panel_data, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        // console.log(res);
        dispatch({
          type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST__PUSH_NEW_PANEL",
          payload: res.data.guid ? res.data : [],
        });
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };

export const getCustomDashboardPanels =
  (dashboard_guid) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/panel/`;
    await axios
      .get(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST",
          payload: res.data.length ? res.data : [],
        });
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };

// NOT WORK YET
export const getSingleCustomDashboardPanelDetails =
  (dashboard_guid, panel_guid) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: false,
    });
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/panel/${panel_guid}/`;
    await axios
      .get(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };

export const updateCustomDashboardPanel =
  (dashboard_guid, panel_guid, data) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let p_data = {
      panel_config: data,
    };
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/panel/${panel_guid}/`;
    // return
    await axios
      .put(url, p_data, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST__UPDATE_PANEL",
          payload: res.data.data,
        });
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };

export const updateCustomDashboardPanel_CHART_GUID =
  (dashboard_guid, panel_guid, guid) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let p_data = {
      chart_guid: guid,
    };
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/panel/${panel_guid}/`;
    // return
    await axios
      .put(url, p_data, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST__UPDATE_PANEL",
          payload: res.data.data,
        });
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };

export const updateCustomDashboardPanel___ =
  (dashboard_guid, panel_guid, data) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/panel/${panel_guid}/`;
    // return
    await axios
      .put(url, data, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST__UPDATE_PANEL",
          payload: res.data.data,
        });
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };

export const deleteCustomDashboardPanel =
  (dashboard_guid, panel_guid) => async (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_LOADER_STATE",
      payload: true,
    });
    let url = `${BASE_URL}/api/custom_dashboard/${dashboard_guid}/panel/${panel_guid}/`;
    // return
    await axios
      .delete(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        // dispatch(getCustomDashboardPanels(dashboard_guid))
        // dispatch({
        //   type: "UPDATE_CUSTOM_DASHBOARD_DATA__ACTIVE_TAB_PANEL_LIST__REMOVE_PANEL",
        //   payload: panel_guid
        // })
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
      });
  };
// ------------------------------------- CUSTOM DASHBOARD - PANEL APIS - ENDS ---------------------------------------

export const getDatasetList =
  (panel_type, panel_type_guid) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/datasets/`;
    // return
    await axios
      .get(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch({
          type: "SET_PANEL_DATASET_LIST",
          payload: res.data,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

export const deleteDatasetRecord =
  (panel_type, panel_type_guid, dataset_guid) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/datasets/${dataset_guid}/records/`;
    await axios
      .delete(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch(getDatasetList(panel_type, panel_type_guid));
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
        dispatch({
          type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

export const postDatasetimportDataCSV =
  (panel_type, panel_type_guid, data) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/datasets/import_data/csv/`;
    await axios
      .post(url, data, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        dispatch(getDatasetList(panel_type, panel_type_guid));
        dispatch({
          type: "UPDATE_REQUEST_LOADER_STATE",
          payload: false,
        });
        dispatch({
          type: "SET_DATASET_LOADER_ANIMATION_FLAG_STAT",
          payload: false,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

// GET DATASET COLUMN - UPLOADED DATASET
export const getDatasetColumns =
  (panel_type, panel_type_guid, dataset_guid) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/datasets/${dataset_guid}/columns/`;
    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// GET DATASET COLUMN - METADATA (ONLY FOR PROJECT)
export const getDatasetColumns__METADATA =
  (panel_type_guid, calling_param) => async (dispatch) => {
    let url = `${BASE_URL}/api/project/${panel_type_guid}/metadata/dataset/column/?dataset_type=${calling_param}`;
    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// GET DATASET COLUMN STATISTIC OPERATION - INDICATOR
export const getDatasetColumnsStatisticOperation =
  (dataset_guid, panel_type, col_name, stat) => async (dispatch) => {
    let url = `${BASE_URL}/api/dataset/${dataset_guid}/stats/?dataset_type=${panel_type}&column_name=${col_name}&operation=${stat}`;
    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// GET DATASET COLUMN STATISTIC OPERATION - INDICATOR - METADATA (ONLY FOR PROJECT)
export const getDatasetColumnsStatisticOperation__METADATA =
  (panel_type_guid, calling_param, col_name, stat) => async (dispatch) => {
    let url = `${BASE_URL}/api/project/${panel_type_guid}/metadata/dataset/stats/?dataset_type=${calling_param}&column_name=${col_name}&operation=${stat}`;
    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// ========================== FILE SYSTEM ==========================
// GET FILE / IMAGE RESOURCES
export const getResources =
  (panel_type, panel_type_guid, resourceType) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/${resourceType}/`;
    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// UPLOAD FILE / IMAGE RESOURCES
export const uploadResources =
  (panel_type, panel_type_guid, resourceType, data) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/${resourceType}/`;
    return await axios.post(url, data, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// DELETE FILE / IMAGE RESOURCES
export const deleteResource =
  (panel_type, panel_type_guid, resourceType, item_guid) =>
  async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/${resourceType}/${item_guid}/`;
    return await axios.delete(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// ========================== FILE SYSTEM ==========================

// ====================== PANEL SPECIFICATION ======================
export const getPanelSpecification =
  (panel_type, panelFieldInfo) => async (dispatch) => {
    let url = `${BASE_URL}`;
    if (panel_type === "project")
      url = `${BASE_URL}/api/web/projects/${panelFieldInfo.panel_type_guid}/asset_pipeline_stats/`;
    else if (panel_type === "asset")
      url = `${BASE_URL}/api/projects/${panelFieldInfo.project_guid}/assets/${panelFieldInfo.panel_type_guid}/specifications/`;
    else if (panel_type === "pipeline")
      url = `${BASE_URL}/api/projects/${panelFieldInfo.project_guid}/pipelines/${panelFieldInfo.panel_type_guid}/specifications/`;

    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };
// ====================== PANEL SPECIFICATION ======================

// ====================== PANEL DATA TABLE ======================
export const getTableDataList =
  (table_type, panel_type, panel_type_guid, data, page) => async (dispatch) => {
    let url = `${BASE_URL}`;
    if (table_type === "METADATA")
      url = `${BASE_URL}/api/project/${panel_type_guid}/metadata/dataset/?dataset_type=${data.calling_param}&page=${page}&limit=100`;
    else
      url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/datasets/${data.guid}/records/?page=${page}&limit=100`;
    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };
// ====================== PANEL DATA TABLE ======================

// ====================== PANEL CHART ======================
export const createChart =
  (panel_type, panel_type_guid, chart_data) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/charts/`;
    return await axios.post(url, chart_data, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// ====================== UPDATE CHART'S DATASET ======================

export const updateChart =
  (panel_type, panel_type_guid, chart_guid, data) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/charts/${chart_guid}/`;
    return await axios.put(url, data, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

export const deleteChart =
  (panel_type, panel_type_guid, chart_guid) => async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/charts/${chart_guid}/`;
    await axios
      .delete(url, {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((res) => {
        toastr.success("Chart successfully removed.", "Deleted");
      })
      .catch((err) => {
        // console.log(err);
      });
  };
// ====================== PANEL CHART ======================

// PROJECT STAT
export const getProjectStatInfo = (project_guid) => async (dispatch) => {
  let url = `${BASE_URL}/api/web/projects/${project_guid}/asset_pipeline_stats/`;
  return await axios.get(url, {
    headers: {
      Authorization: getToken(),
    },
  });
};

// ====================== GET UNIQUE VALUE FROM A COLUMN ======================

// PROJECT STAT
export const getChartData_Bar_UniqueValue =
  (panel_type, panel_type_guid, dataset_guid, column_name) =>
  async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/datasets/${dataset_guid}/get_chart_data/bar/${column_name}/`;
    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// ====================== GET UNIQUE VALUE FROM A COLUMN ======================

// PROJECT STAT
export const getDatasetColInfo =
  (panel_type, panel_type_guid, dataset_guid, column_name) =>
  async (dispatch) => {
    let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/datasets/${dataset_guid}/columns/${column_name}/filters/`;
    return await axios.get(url, {
      headers: {
        Authorization: getToken(),
      },
    });
  };

// export const getChartData_Bar_UniqueValue =
//   (panel_type, panel_type_guid, dataset_guid, column_name) =>
//   async (dispatch) => {
//     let url = `${BASE_URL}/api/${panel_type}/${panel_type_guid}/datasets/${dataset_guid}/get_chart_data/bar/${column_name}/`;
//     return await axios.get(url, {
//       headers: {
//         Authorization: getToken(),
//       },
//     });
//   };

// ====================== CUSTOM CODE ======================
const createCloseButtonOnWebGLOverlayDashboard = () => {
  let selectedUL = document.getElementById("custom-panel-tabs").parentNode;

  let c_li = document.createElement("div");
  c_li.className = "nav-item ACTIVE_WEBGL_DASHBOARD __float_close__";

  let c_li__button = document.createElement("button");
  c_li__button.className = "nav-link overlay-close-btn";
  c_li__button.title = "Close Dashboard";
  c_li__button.innerHTML = "X";

  c_li__button.addEventListener("click", (e) => {
    e.preventDefault();
    document.getElementById("3D-scenario-icon-project").click();
  });

  c_li.append(c_li__button);
  selectedUL.append(c_li);
};
