import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import { AgoraProvider } from "@agnostech/react-agora-ng";
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from "agora-rtm-sdk";

import { Integrations } from "@sentry/tracing";
import "react-virtualized/styles.css";
import PageRoutes from "./pages";
import store from "./store";
import "react-circular-progressbar/dist/styles.css";
import "./actions/miscActions";
import "./static/assets/css/styles.css";
import "./static/assets/css/custom-styles.css";
import "mapbox-gl/dist/mapbox-gl.css";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
const rtmClient = AgoraRTM.createInstance(process.env.REACT_APP_AGORA_APP_ID);

if (process.env.REACT_APP_ENV === "prod") {
  Sentry.init({
    dsn: "https://ee0c742d8eef4aeabdf4a30a143a34e7@o1202417.ingest.sentry.io/6327577", // add the dsn url
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}

AgoraRTC.setLogLevel(4);

ReactDOM.render(
  <AgoraProvider
    client={client}
    rtmClient={rtmClient}
    appId={process.env.REACT_APP_AGORA_APP_ID}
  >
    <Provider store={store}>
      <BrowserRouter>
        <PageRoutes />
      </BrowserRouter>
    </Provider>
  </AgoraProvider>,
  document.getElementById("root")
);
