import React from "react";
import "./style.css";

const G_Loader = ({style}) => {
  return (
    <div className="loader__top_with_logo loader__no_bottom__space loader analysis_runing_img" style={style ? style : {}}>
      <div className="inner one"></div>
      <div className="inner two"></div>
      <div className="inner three"></div>
    </div>
  );
};

export default G_Loader;
