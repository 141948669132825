/* eslint-disable */
import axios from "axios";
import decode from "jwt-decode";
import toastr from "toastr";
import { toastOptions } from "../utils/globalConfig";
import request from "../utils/request";
import { isJwtExpired } from "jwt-check-expiration";

const baseURL = process.env.REACT_APP_REST_HOST;

window.apiBaseURL = baseURL;

export const getUserProfile = () => async (dispatch) => {
  try {
    const { data } = await request.get("/api/auth/user/");
    dispatch({
      type: "SET_USER_INFO",
      payload: data,
    });
  } catch (error) {
    // console.log(error);
    toastr.warning("Unable to find user profile");
  }
};

export const getProjectStats = () => async (dispatch) => {
  try {
    const { data } = await request.get("/api/user/project/stats/");
    dispatch({
      type: "SET_PROJECT_STATS",
      payload: data,
    });
  } catch (error) {
    // console.log(error);
  }
};

export const register = (payload) => async (dispatch) => {
  window.dispatch({
    type: "REQUEST_PENDING",
  });

  try {
    if (process.env.REACT_APP_ENV === "dev") {
      await axios.post(baseURL + "/api/auth/signup/", payload);

      setTimeout(() => {
        window.dispatch({
          type: "REQUEST_RESOLVED",
        });
      }, 2000);

      toastr["success"](
        "Thank you please verify your email address.",
        "Registration Completed",
        toastOptions
      );
    } else {
      await axios.post(baseURL + "/api/auth/signup/", payload);

      window.dispatch({
        type: "REQUEST_RESOLVED",
      });

      toastr["success"](
        "Thank you please verify your email address.",
        "Registration Completed",
        toastOptions
      );
    }
  } catch (error) {
    window.dispatch({
      type: "REQUEST_RESOLVED",
    });
    if (error.response) {
      if (error.response.data instanceof Object) {
        Object.keys(error.response.data).map(function (key, index) {
          toastr["warning"](
            error.response.data[key][0],
            "Registration failed",
            toastOptions
          );
          return 0;
        });
      }
    }
  }
};

export const login = (payload, navigate) => async (dispatch) => {
  dispatch({
    type: "REQUEST_PENDING",
  });

  try {
    const { data } = await axios.post(
      `${baseURL + "/api/auth/login/"}`,
      payload
    );
    localStorage.setItem("access_token", JSON.stringify(data.access));
    localStorage.setItem("refresh_token", JSON.stringify(data.refresh));
    const decodedAccessToken = decode(data.access);

    toastr["success"]("Login Success", "", {
      positionClass: "toast-bottom-left",
    });
    dispatch({
      type: "SET_USER_INFO",
      payload: { ...decodedAccessToken },
    });
    dispatch({
      type: "REQUEST_RESOLVED",
    });
    navigate("/home", { replace: true });
  } catch (error) {
    window.dispatch({
      type: "REQUEST_RESOLVED",
    });

    if (error.response && error.response.data.detail) {
      return toastr["error"](error.response.data.detail, "Login Failed", {
        positionClass: "toast-bottom-left",
      });
    }
    if (error.response && error.response.data.error.length) {
      return toastr["error"](error.response.data.error[0], "Login Failed", {
        positionClass: "toast-bottom-left",
      });
    }
  }
};

window.getAccessToken = () => {
  let access_token = JSON.parse(localStorage.getItem("access_token"));
  const refresh_token = JSON.parse(localStorage.getItem("refresh_token"));

  if (isJwtExpired(access_token)) {
    axios
      .post(baseURL + "/api/auth/token/refresh/", {
        refresh: refresh_token,
      })
      .then((resp) => {
        access_token = resp.data.access;
        localStorage.setItem("access_token", JSON.stringify(resp.data.access));
      })
      .catch((err) => {
        logoutUser();
      });
    return access_token;
  }
  return access_token;
};

export function logoutUser() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  // localStorage.removeItem("ASSETTWIN_UNITY_LOADER_RECALL");

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/api/auth/verify-email/"
  ) {
    window.location.replace("/");
  }
}

//Download File
window.DownloadFile = (url, fileName, type) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName + "." + type);
  document.body.appendChild(link);
  link.click();
};

// Toastr global function
const toastRunner = (type, title, message, timeOut) =>
  toastr[type](message, title, {
    ...toastOptions,
    timeOut: timeOut * 1000, //timeOut given in Second and multiply it with 1000 to get miliseconds
    extendedTimeOut: timeOut * 1000, //timeOut given in Second and multiply it with 1000 to get miliseconds
  });

window.toastRunner = toastRunner;

// let dynamicModalTimeout = 20000; //15 second
let limitMessageStr = "You can contact our support team from this link";
limitMessageStr = limitMessageStr.link(window.location.origin + "/support");

const statusCodes = {
  ["100"]: {
    status:
      "The client SHOULD continue with its request. This interim response is used to inform the client that the initial part of the request has been received and has not yet been rejected by the server. The client SHOULD continue by sending the remainder of the request or, if the request has already been completed, ignore this response",
  },
  ["101"]: {
    status:
      "The server understands and is willing to comply with the client's request, via the Upgrade message header field (section 14.42), for a change in the application protocol being used on this connection.",
  },
  ["200"]: {
    status: "Your request has succeeded.",
  },
  ["201"]: {
    status:
      "Your request has been fulfilled and has resulted in one or more new resources being created in the server",
  },
  ["202"]: {
    status:
      "Your request has been accepted for processing, but the processing has not been completed.",
  },
  ["203"]: {
    status:
      "Your request was successful but the enclosed payload has been modified from that of the origin server's 200 (OK) response by a transforming proxy",
  },
  ["204"]: {
    status:
      "The server has successfully fulfilled your request and that there is no additional content to view",
  },
  ["205"]: {
    status:
      "The server has fulfilled your request and desires that the user agent reset the `document view`, which caused the request to be sent, to its original state as received from the origin server.",
  },
  ["206"]: {
    status: "The server has fulfilled the partial GET request for the resource",
  },
  ["300"]: {
    status:
      "The server desires that the User engage in reactive negotiation to select the most appropriate representation(s) for its needs",
  },
  ["301"]: {
    status:
      "The requested resource has been assigned a new permanent URL and any future references to this resource ought to use one of the enclosed URLs",
  },
  ["302"]: {
    status:
      "The requested resource resides temporarily under a different URL.  Since the redirection might be altered on occasion, the client ought to continue to use the effective request URL for future requests.",
  },
  ["303"]: {
    status:
      "The server is redirecting the user agent to a different resource, as indicated by a URL in the Location header field, which is intended to provide an indirect response to the original request",
  },
  ["304"]: {
    status:
      "The requested resource has not been modified since the last time you accessed it.",
  },
  ["307"]: {
    status:
      "The requested resource resides temporarily under a different URL and the user agent MUST NOT change the request method if it performs an automatic redirection to that URL",
  },
  ["308"]: {
    status:
      "The resource requested has been definitively moved to the URL given by the Location headers",
  },
  ["400"]: {
    status:
      "The server cannot or will not process the request due to something that is perceived to be a client error (e.g., malformed request syntax, invalid request message framing, or deceptive request routing)",
  },
  ["401"]: {
    status: "Your access token has expired. Please relogin to continue",
  },
  ["402"]: {
    status: "Payment Required",
  },
  ["403"]: {
    status:
      "The client does not have access rights to the content; that is, it is unauthorized, so the server is refusing to give the requested resource",
  },
  ["404"]: {
    status: "The requested resource was not found",
  },
  ["405"]: {
    status:
      "The request method is known by the server but is not supported by the requested resource",
  },
  ["406"]: {
    status: `You have reached your maximum resource limit for this project. Please upgrade your subscription Plan. ${limitMessageStr}`,
  },
  ["407"]: {
    status: "Proxy Authentication Required",
  },
  ["408"]: {
    status:
      "The server did not receive a complete request message within the time that it was prepared to wait",
  },
  ["409"]: {
    status:
      "The request could not be completed due to a conflict with the current state of the requested resource",
  },
  ["410"]: {
    status:
      "Access to the requested resource is no longer available at the origin server and that this condition is likely to be permanent",
  },
  ["411"]: {
    status:
      "The server refuses to accept the request without a defined Content-Length. The client MAY repeat the request if it adds a valid Content-Length header field containing the length of the message body in the request message",
  },
  ["412"]: {
    status:
      "The client has indicated preconditions in its request headers which the server does not meet.",
  },
  ["413"]: {
    status:
      "The server is refusing to process a request because the request payload is larger than the server is willing or able to process.  The server MAY close the connection to prevent the client from continuing the request",
  },
  ["414"]: {
    status:
      "The server is refusing to service the request because the request-target is longer than the server is willing to interpret",
  },
  ["415"]: {
    status:
      "The origin server is refusing to service the request because the payload is in a format not supported by this method on the requested resource",
  },
  ["416"]: {
    status:
      "Server cannot serve the requested ranges. The most likely reason is that the document doesn't contain such ranges, or that the Range header value, though syntactically correct, doesn't make sense",
  },

  ["417"]: {
    status:
      "The expectation given in an Expect request-header field could not be met by this server, or, if the server is a proxy, the server has unambiguous evidence that the request could not be met by the next-hop server.",
  },
  ["422"]: {
    status:
      "The request was well-formed but was unable to be followed due to semantic errors",
  },
  ["425"]: {
    status:
      "The server is unwilling to risk processing a request that might be replayed, which creates the potential for a replay attack",
  },
  ["426"]: {
    status:
      "The server refuses to perform the request using the current protocol but might be willing to do so after the client upgrades to a different protocol.",
  },
  ["428"]: {
    status:
      "The server requires the request to be conditional. Typically, this means that a required precondition header, such as If-Match, is missing.",
  },
  ["429"]: {
    status: "The user has sent too many requests in a given amount of time",
  },

  ["431"]: {
    status:
      "The server refuses to process the request because the request’s HTTP headers are too long. The request may be resubmitted after reducing the size of the request headers",
  },
  ["451"]: {
    status:
      "The user requested a resource that is not available due to legal reasons, such as a web page for which a legal action has been issued",
  },
  ["499"]: {
    status: "Client has closed request while it's processing",
  },
  ["500"]: {
    status:
      "Data Fetching encountered an unexpected condition that prevented it from fulfilling the request",
  },
  ["501"]: {
    status:
      "The server does not support the functionality required to fulfill the request. The server does not recognize the request method and is not capable of supporting it for any resource.",
  },
  ["502"]: {
    status:
      "The server, while acting as a gateway or proxy, received an invalid response from the upstream server",
  },
  ["503"]: {
    status:
      "the server is not ready to handle the request. Common causes are a server that is down for maintenance or that is overloaded",
  },
  ["505"]: {
    status:
      "The server does not support, or refuses to support, the HTTP protocol version that was used in the request message. The server is indicating that it is unable or unwilling to complete the request using the same major version as the client",
  },
  ["508"]: {
    status:
      "It indicates that the server terminated an operation because it encountered an infinite loop while processing a request with `Depth: infinity`. This status indicates that the entire operation failed.",
  },
  ["511"]: {
    status:
      "The client needs to authenticate to gain network access.Network operators sometimes require some authentication, acceptance of terms, or other user interaction before granting access (for example in an internet café or at an airport). They often identify clients who have not done so using their Media Access Control (MAC) addresses",
  },
  ["521"]: {
    status:
      "Web server is down.It means that your web browser was able to successfully connect to Cloudflare, but Cloudflare was not able to connect to the origin web server",
  },
  ["525"]: {
    status:
      "The SSL handshake between a domain using Cloudflare and the origin web server failed.",
  },
};

const findHTTPStatus = (responseStatusCode) => {
  for (let statusCode in statusCodes) {
    if (parseInt(statusCode) === parseInt(responseStatusCode)) {
      return statusCodes[statusCode];
      //Instad of console, toster call will happen
    }
  }
};

window.statusToaster = (type, code, timeOut) => {
  let message = findHTTPStatus(code);
  toastRunner(type, "Error " + code + " Occured", message.status, timeOut);
  // if (code === 406) alert("Maximum Resource limit Reached!");
  //modal will be addup letter
  // dynamicModal(
  //   "Maximum Resource limit Reached!",
  //   "You have reached your maximum resource limit in your current subscription Plan. Please urgrade your subscription plan. You can contact our support team for further help"
  // );
};

window.openUrlInNewTab = (link) => {
  window.open(link, "_blank");
};
