import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "toastr";
import {
  getCustomDashboard,
  getDatasetList,
  postCustomDashboard,
} from "../../actions/customPanelModify";
import Metadata from "../AppPanel/ChildComponents/Metadata";
import CreateDefaultPanels from "./header/defaultPanels";
import CustomPannelDashboard_Header from "./header";
import Panels from "./panels";

const CustomPannelDashboard = (props) => {
  const dispatch = useDispatch();
  const panelFieldInfo = useSelector( (state) => state.customPanelModify.panelFieldInfo );
  const activeTabData = useSelector( (state) => state.customPanelModify.ACTIVE_TAB_DATA );
  const defaultDashboardSUMMARY = useSelector( (state) => state.customPanelModify.createDefaultDashboard );
  const createDefaultPanelsFlag = useSelector( (state) => state.customPanelModify.createDefaultDashboardPanels );

  useEffect(() => {
    dispatch(
      getCustomDashboard(panelFieldInfo.panel_type, panelFieldInfo.panel_type_guid)
      );
    dispatch( getDatasetList(panelFieldInfo.panel_type, panelFieldInfo.panel_type_guid) );
  }, [props.customPanelInfo]);

  // FIRST TIME CREATE SUMMARY TAB
  useEffect(() => {
    // if (defaultDashboardSUMMARY && panelFieldInfo.panel_type === "project") {
    if (defaultDashboardSUMMARY) {
      dispatch(
        postCustomDashboard({
          name: "Summary",
          // dashboard_type: "project",
          dashboard_type: panelFieldInfo.panel_type,
          // dashboard_type_guid: panelFieldInfo.project_guid,
          dashboard_type_guid: panelFieldInfo.panel_type_guid,
          dashboard_config: {},
        }, panelFieldInfo.panel_type === "project" ? true : false)
      );
    }
  }, [defaultDashboardSUMMARY]);

  return (
    <div
      id="assettwin-custom-panel-dashboard__modify"
      className="custom-panel-dashboard__modify"
    >
      <CustomPannelDashboard_Header />
      {createDefaultPanelsFlag ? <CreateDefaultPanels /> 
      : <>
          {!activeTabData ? (
            <div
              className="loader__top_with_logo loader__no_bottom__space loader analysis_runing_img"
              style={{ position: "fixed", left: "48%", top: "48%", zIndex: "9999" }}
            >
              <div className="inner one"></div>
              <div className="inner two"></div>
              <div className="inner three"></div>
            </div>
          ) : (
            <>{activeTabData.guid ? <Panels /> : <Metadata />}</>
          )}
        </>
      }
    </div>
  );
};

export default CustomPannelDashboard;
