import React, { useEffect, useState } from "react";
import ModalAT from "../../ModalAT";

const BigDataTableField = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(null);

  window.bidDataTableFieldModalOpen = (data) => {
    // console.log(data);
    setRowData(data);
    setIsOpen(true);
  };

  const afterOpenModal = () => {};
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {modalIsOpen ? (
        <ModalAT
          id="rnd_section_indicator_value_edit_modal_MODAL_AT"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          modalTitle="Row Details"
          contentLabel="Row Details"
          ariaHideApp={false}
        >
          {/* DATASET SELECTION */}
          {Array.from({ length: Object.keys(rowData).length }, (_, index) => (
            <div className="form__group">
              <p
                htmlFor=""
                className="form__label m-0"
                style={{ maxWidth: "450px", overflow: "auto" }}
              >
                {Object.keys(rowData)[index]} : {Object.values(rowData)[index]}
              </p>
            </div>
          ))}
        </ModalAT>
      ) : (
        ""
      )}
    </>
  );
};

export default BigDataTableField;
