import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSubscriptionTransaction } from "../../actions/billingAction";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ASSET_PER_CREDIT = process.env.REACT_APP_ASSET_PER_CREDIT;
const STORAGE_PRE_CREDIT = process.env.REACT_APP_STORAGE_PRE_CREDIT;
const USD_CREDIT_PER_MONTH = process.env.REACT_APP_USD_CREDIT_PER_MONTH;

const SubscriptionTransaction = () => {
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    dispatch(getSubscriptionTransaction())
      .then((res) => {
        setTransactions(res.data.transaction_list);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <div
      className="tab-pane fade show active"
      id="nav-Subscription"
      role="tabpanel"
      aria-labelledby="nav-Subscription-tab"
    >
      <div className="p-2">
        <h4 className="p-0">Payment Summary</h4>
        <table className="table payment-summary">
          <thead>
            <tr>
              <th>Year</th>
              <th>Month</th>
              <th>Subscription</th>
              <th>Subscription Fee</th>
              <th>Storage Fee</th>
              <th>Asset Fee</th>
              <th>Total Fee</th>
              <th>Paid</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((tr, index) => (
              <tr key={index}>
                <td>{new Date(tr.purchased_at).getFullYear()}</td>
                <td>{months[new Date(tr.purchased_at).getMonth() - 1]}</td>
                <td>{tr.subscribed_package}</td>
                <td>{tr.subscription_fee}</td>
                <td>{tr.storage_fee}</td>
                <td>{tr.asset_fee}</td>
                <td>{tr.cost}</td>
                <td>Yes</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscriptionTransaction;
