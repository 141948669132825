import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAdditionalPurchase, getCreditPurchase, getSubscriptions } from "../../../actions/billingAction";
import request from "../../../utils/request";
import Price from "./Price";

const CreditPurchase = ({ subscription }) => {
  const [purchaseList, setPurchaseList] = useState(null);
  const [additionalPurchaseList, setAdditionalPurchaseList] = useState(null);
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(getCreditPurchase()).then((res) => {
      setPurchaseList(res.data.credit_purchase_list);
    });
    dispatch(getAdditionalPurchase()).then((res) => {
      setAdditionalPurchaseList(res.data.additional_purchase_list);
    });
  }, []);

  const reloadList = (listType) => {
    if(listType === "PURCHASE") {
      dispatch(getCreditPurchase()).then((res) => {
        setPurchaseList(res.data.credit_purchase_list);
      });
    } else if (listType === "ADDITIONAL_PURCHASE") {
      dispatch(getAdditionalPurchase()).then((res) => {
        setAdditionalPurchaseList(res.data.additional_purchase_list);
      });
    }
  }

  return (
    <div className="user__panel--tab-content-block">
      <table className="table table-dark user__panel--table">
        <tbody>
            <tr>
              <td className="border-0" colSpan={3}>
                <Price reloadList={reloadList} />
              </td>
            </tr>
        </tbody>
      </table>
      <h4 className="mb-2 mt-5">Credit Purchase History</h4>
      <div className="table-responsive">
        <table className="table table-dark user__panel--table">
          <thead>
            <tr>
              <th>Card No</th>
              <th>Credti Amount</th>
              <th>Price</th>
              <th>Purchase Time</th>
            </tr>
          </thead>
          <tbody>
            {purchaseList ? (
              purchaseList.map((s, index) => (
                <tr key={index}>
                  <td>{s.card_no}</td>
                  <td>{s.credit_amount}</td>
                  <td>{s.price}</td>
                  <td>{(s.purchased_at).split("T")[0]}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No available list</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <h4 className="mb-2 mt-5">Additional Purchase History</h4>
      <div className="table-responsive">
        <table className="table table-dark user__panel--table">
          <thead>
            <tr>
              <th>Date Start</th>
              <th>Date End</th>
              <th>Asset Count</th>
              <th>Cost</th>
              <th>Duration</th>
              <th>Purchased at</th>
              <th>Storage Capacity</th>
            </tr>
          </thead>
          <tbody>
            {additionalPurchaseList ? (
              additionalPurchaseList.map((s, index) => (
                <tr key={index}>
                  <td>{s.date_start}</td>
                  <td>{s.date_end}</td>
                  <td>{s.asset_count}</td>
                  <td>{s.cost}</td>
                  <td>{s.duration}</td>
                  <td>{(s.purchased_at).split("T")[0]}</td>
                  <td>{s.storage_capacity}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No available list</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CreditPurchase;
