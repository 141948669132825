import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContainerFluid from "../../components/ContainerFluid";
import TopHeader from "../../components/TopHeader";
import { getIconFromList } from "../../static/IconServe";

const Credit = () => {
  return (
    <div
      className="tab-pane fade"
      id="nav-Credit"
      role="tabpanel"
      aria-labelledby="nav-Credit-tab"
    >
      <h4 className="p-2">Credit</h4>
      <div className="credits_content">
        <h6 className="card_table_heading">Summary</h6>
        <div className="credits_summary">
          <div className="credits_summary_column">
            <p className="credits_summary_content">
              <strong>Total credit remaining</strong>
            </p>
            <p className="credits_summary_content">499.99</p>
          </div>
          <div className="credits_summary_column">
            <p className="credits_summary_content">
              <strong>Total credit used</strong>
            </p>
            <p className="credits_summary_content">0.01</p>
          </div>
        </div>
        <h6 className="card_table_heading">Credits</h6>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Purchase data</th>
                <th>Credits</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sunday, August 28, 2022 3:23 PM</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Credit;
