import React, { Fragment, useState } from "react";
import ModalAT from "../../ModalAT";
import { useDispatch, useSelector } from "react-redux";
import {
  createChart,
  getDatasetColumns,
  updateCustomDashboardPanel,
  updateCustomDashboardPanel_CHART_GUID,
  updateCustomDashboardPanel___,
} from "../../../actions/customPanelModify";

const baseMaps = {
  Streets: "mapbox://styles/mapbox/streets-v10",
  Outdoors: "mapbox://styles/mapbox/outdoors-v11",
  Satellite: "mapbox://styles/mapbox/satellite-v9",
  Light: "mapbox://styles/mapbox/light-v10",
};

const color_scale = [
  { name: "Red Yellow Teal", value: "RedYellowTeal" },
  { name: "Blue Neon Sky", value: "BlueNeonSky" },
  { name: "Yellow Orange Maroon", value: "YellowOrangeMaroon" },
  { name: "Pink White Retro", value: "PinkWhiteRetro" },
  { name: "Yellow Blue Light", value: "YellowBlueLight" },
  { name: "Teal Black Red", value: "TealBlackRed" },
  { name: "Navy Red Orange", value: "NavyRedOrange" },
  { name: "Orange Blue Navy", value: "OrangeBlueNavy" },
  { name: "Red Orange Yellow", value: "RedOrangeYellow" },
  { name: "Green Cyan Blue", value: "GreenCyanBlue" },
  { name: "Purple Pink Gray", value: "PurplePinkGray" },
];

const mapbox_zoom = [
  { name: "Buildings", value: 15 },
  { name: "Large Roads", value: 10 },
  { name: "Large Rivers", value: 6 },
  { name: "Large Islands", value: 4 },
  { name: "A continent", value: 3 },
  { name: "The Earth", value: 0 },
];

const BubbleMapUpdateModal = () => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [panelInfo, setPanelInfo] = React.useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [chartTitle, setChartTitle] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [bubbleMapConfig, setBubbleMapConfig] = useState({
    plot_type: "bubble_map",
    base_map: "Streets",
    zoom: { name: "Buildings", value: 15 },
    lat: null,
    lng: null,
    z_value: null,
    radius: 10,
    operation: null,
    filter: null,
    color_palate: "RedOrangeYellow",
    zmin: null,
    zmax: null,
    map_view_limit: {
      max_lat: null,
      max_lng: null,
      min_lat: null,
      min_lng: null,
    },
    bubble_border_width: 1,
    bubble_border_color: "None",
    tooltip_info_col: null,
    map_boundary: "Flexible",
    additional_z_value: null,
    additional_color_palate: "RedYellowTeal",
  });

  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );

  const isWebglDashboardActive = useSelector(
    (state) => state.customPanelModify.isWebglDashboard
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const datasetList = useSelector(
    (state) => state.customPanelModify.datasetList
  );

  const [isAdditionalValueUI, setAdditionalValueUI] = useState(false);

  const [selectedDatasetColumnList, setSelectedDatasetColumnList] =
    useState(null);

  const selectDatasetHandler = (data) => {
    if (data === "ADD_NEW_DATASET") {
      closeModal();
      document.getElementById("new-dataset-input-modal__opener-el").click();
    } else {
      setSelectedDataset(JSON.parse(data));

      dispatch({
        type: "UPDATE_REQUEST_LOADER_STATE",
        payload: true,
      });

      dispatch(
        getDatasetColumns(
          panelFieldInfo.panel_type,
          panelFieldInfo.panel_type_guid,
          JSON.parse(data).guid
        )
      )
        .then((res) => {
          setSelectedDatasetColumnList(res.data.data);
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_REQUEST_LOADER_STATE",
            payload: false,
          });
        });
    }
  };

  const afterOpenModal = () => {
    const { panel_config } = panelInfo;
    console.log(panel_config);

    setChartTitle(
      panel_config.data ? panel_config.bar_data.chart_name : "Bubble Map"
    );

    setSelectedZoomOption(
      panel_config.data ? panel_config.bar_data.baseMaps : "Streets"
    );

    // Dataset selection
    datasetList.forEach((dataset) => {
      if (panel_config.data && panel_config.data.dataset === dataset.guid) {
        document.getElementById(
          "bar_chart_edit__chart_dataset_select_option"
        ).value = JSON.stringify(dataset);

        // GET SELECTED DATASET COLUMN
        selectDatasetHandler(JSON.stringify(dataset));

        // SELECT X AXIS COLUMN FLAG
        setBubbleMapConfig(panel_config.bar_data.chart_config);

        if (panel_config.bar_data.chart_config.additional_z_value) {
          setAdditionalValueUI(true);
        }

        if (
          panel_config.data &&
          panel_config.bar_data &&
          panel_config.bar_data.chart_config &&
          panel_config.bar_data.chart_config.zoom
        ) {
          setSelectedZoomOption(panel_config.bar_data.chart_config.zoom.name);
        }
      }
    });
  };
  const closeModal = () => {
    setIsOpen(!modalIsOpen);
    setSelectedDataset(null);
    setBubbleMapConfig({
      plot_type: "bubble_map",
      base_map: "Streets",
      zoom: { name: "Buildings", value: 15 },
      lat: null,
      lng: null,
      z_value: null,
      radius: 10,
      operation: null,
      filter: null,
      color_palate: "RedOrangeYellow",
      zmin: null,
      zmax: null,
      map_view_limit: {
        max_lat: null,
        max_lng: null,
        min_lat: null,
        min_lng: null,
      },
      bubble_border_width: 1,
      bubble_border_color: "None",
      tooltip_info_col: null,
      map_boundary: "Flexible",
      additional_z_value: null,
    });
  };

  window.bubbleMapEditModalOpen = (panel) => {
    // console.log(panel);

    // SOLVE THE DEFAULT VALUE ISSUE HERE

    setPanelInfo(panel);
    setModalTitle("Update Bubble Map");
    setBubbleMapConfig({
      ...bubbleMapConfig,
      bubble_border_width: 1,
    });

    setIsOpen(true);
  };
  const [selectedZoomOption, setSelectedZoomOption] = useState("");

  return (
    <ModalAT
      id="rnd_section_bar_chart_edit_modal_MODAL_AT"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      modalTitle={modalTitle}
      contentLabel={modalTitle}
      ariaHideApp={false}
    >
      <div className="chart-plot-modal-container">
        <div className="row form__row mb-3">
          <div className="col-md-6">
            <div className="form__group">
              <label>Chart Title</label>
            </div>
            <div className="form__group">
              <input
                type="text"
                className="form-control form__control"
                id="bar_chart_edit__chart_title"
                placeholder="Chart Title"
                value={chartTitle ? chartTitle : ""}
                onChange={(e) => {
                  e.stopPropagation();
                  setChartTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form__group">
              <label>Select Data*</label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                id="bar_chart_edit__chart_dataset_select_option"
                onChange={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  selectDatasetHandler(e.target.value);
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    map_view_limit: {
                      max_lat: null,
                      max_lng: null,
                      min_lat: null,
                      min_lng: null,
                    },
                  });
                }}
              >
                <option disabled value={null} selected>
                  Select Dataset
                </option>
                {!isWebglDashboardActive && (
                  <option value={"ADD_NEW_DATASET"}>Add New Dataset</option>
                )}
                <optgroup label="Uploaded Dataset">
                  {datasetList.map((dataset, index) => (
                    <option value={JSON.stringify(dataset)} key={index}>
                      {dataset.name}
                    </option>
                  ))}
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div className="row form__row mb-3">
          <div className="col-md-4">
            <div className="form__group">
              <div className="form__group">
                <label>Select Base Map*</label>
              </div>
              <select
                id="base-map-select"
                className="custom-select"
                value={bubbleMapConfig.base_map ? bubbleMapConfig.base_map : ""}
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    base_map: e.target.value,
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                {Object.keys(baseMaps).map((name, i) => (
                  <option key={i} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form__group">
              <label>Zoom Option</label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                value={selectedZoomOption}
                onChange={({ target: { value } }) => {
                  setSelectedZoomOption(
                    mapbox_zoom.filter((level) => level.name === value)[0].name
                  );
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    zoom: mapbox_zoom.filter(
                      (level) => level.name === value
                    )[0],
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                {mapbox_zoom.map((zo, i) => (
                  <option value={zo.name} key={i}>
                    {zo.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form__group">
              <label>Map Boundary </label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                value={bubbleMapConfig.map_boundary}
                onChange={({ target: { value } }) => {
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    map_boundary: value,
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                {["Flexible", "Fixed"].map((item, i) => (
                  <option value={item} key={i}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row form_row mb-3">
          <div className="col-md-4">
            <div className="form__group">
              <label>Select Lat*</label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                value={
                  bubbleMapConfig.lat ? JSON.stringify(bubbleMapConfig.lat) : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    lat: JSON.parse(e.target.value),
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                <option
                  disabled
                  value={
                    bubbleMapConfig.lat === null ? "" : bubbleMapConfig.lat
                  }
                >
                  Please Select
                </option>
                {!!selectedDatasetColumnList &&
                  selectedDatasetColumnList.map((col, index) => (
                    <>
                      {col.col_type === "int" || col.col_type === "float" ? (
                        <option value={JSON.stringify(col)} key={index}>
                          {col.col_name}
                        </option>
                      ) : null}
                    </>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form__group">
              <label>Select Long*</label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                value={
                  bubbleMapConfig.lng ? JSON.stringify(bubbleMapConfig.lng) : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    lng: JSON.parse(e.target.value),
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                <option
                  disabled
                  value={
                    bubbleMapConfig.lng === null ? "" : bubbleMapConfig.lng
                  }
                >
                  Please Select
                </option>
                {!!selectedDatasetColumnList &&
                  selectedDatasetColumnList.map((col, index) => (
                    <>
                      {col.col_type === "int" || col.col_type === "float" ? (
                        <option value={JSON.stringify(col)} key={index}>
                          {col.col_name}
                        </option>
                      ) : null}
                    </>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form__group">
              <label>Select Value*</label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                value={
                  bubbleMapConfig.z_value
                    ? JSON.stringify(bubbleMapConfig.z_value)
                    : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    z_value: JSON.parse(e.target.value),
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                <option
                  disabled
                  value={
                    bubbleMapConfig.z_value === null
                      ? ""
                      : bubbleMapConfig.z_value
                  }
                >
                  Please Select
                </option>
                {!!selectedDatasetColumnList &&
                  selectedDatasetColumnList.map((col, index) => (
                    <>
                      {col.col_type === "int" || col.col_type === "float" ? (
                        <option value={JSON.stringify(col)} key={index}>
                          {col.col_name}
                        </option>
                      ) : null}
                    </>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row form__row mb-3">
          <div className="col-md-4">
            <div className="form__group">
              <label>Select Info</label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                value={
                  bubbleMapConfig.tooltip_info_col
                    ? JSON.stringify(bubbleMapConfig.tooltip_info_col)
                    : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    tooltip_info_col: JSON.parse(e.target.value),
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                <option
                  disabled
                  value={
                    bubbleMapConfig.tooltip_info_col === null
                      ? ""
                      : bubbleMapConfig.tooltip_info_col
                  }
                >
                  Please Select
                </option>
                {!!selectedDatasetColumnList &&
                  selectedDatasetColumnList.map((col, index) => (
                    <option value={JSON.stringify(col)} key={index}>
                      {col.col_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form__group">
              <label>Select Color Palette*</label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                value={bubbleMapConfig.color_palate}
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    color_palate: e.target.value,
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                {color_scale.map((cs, index) => (
                  <option value={cs.value} key={index}>
                    {cs.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form__group">
              <label>Bubble Border</label>
            </div>
            <div className="form__group">
              <select
                className="custom-select"
                value={bubbleMapConfig.bubble_border_color}
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    bubble_border_color: e.target.value,
                  });
                }}
                disabled={!selectedDatasetColumnList}
              >
                {["None", "Black", "Teal", "Navy", "Purple", "Blue"].map(
                  (num, index) => (
                    <option value={num} key={index}>
                      {num}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="row form_row mb-3">
          {!isAdditionalValueUI && (
            <div className="col-md-4">
              <button
                className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
                onClick={() => {
                  if (!isAdditionalValueUI) {
                    setAdditionalValueUI(!isAdditionalValueUI);
                    setBubbleMapConfig({
                      ...bubbleMapConfig,
                      additional_z_value: null,
                    });
                  } else {
                    setAdditionalValueUI(!isAdditionalValueUI);
                  }
                }}
              >
                Add Additional Value
              </button>
            </div>
          )}

          {isAdditionalValueUI && (
            <Fragment>
              <div className="col-md-4">
                <div className="form__group">
                  <label>Select Additional Value</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    value={
                      bubbleMapConfig.additional_z_value
                        ? JSON.stringify(bubbleMapConfig.additional_z_value)
                        : ""
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      setBubbleMapConfig({
                        ...bubbleMapConfig,
                        additional_z_value: JSON.parse(e.target.value),
                      });
                    }}
                    disabled={!selectedDatasetColumnList}
                  >
                    <option
                      disabled
                      value={
                        bubbleMapConfig.additional_z_value === null
                          ? ""
                          : bubbleMapConfig.additional_z_value
                      }
                    >
                      Please Select
                    </option>
                    {!!selectedDatasetColumnList &&
                      selectedDatasetColumnList.map((col, index) => (
                        <>
                          {col.col_type === "int" ||
                          col.col_type === "float" ? (
                            <option value={JSON.stringify(col)} key={index}>
                              {col.col_name}
                            </option>
                          ) : null}
                        </>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form__group">
                  <label>Additional Color Palette</label>
                </div>
                <div className="form__group">
                  <select
                    className="custom-select"
                    value={bubbleMapConfig.additional_color_palate}
                    onChange={(e) => {
                      e.stopPropagation();
                      setBubbleMapConfig({
                        ...bubbleMapConfig,
                        additional_color_palate: e.target.value,
                      });
                    }}
                    disabled={!selectedDatasetColumnList}
                  >
                    {color_scale.map((cs, index) => (
                      <option value={cs.value} key={index}>
                        {cs.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form__group">
                  <label />
                </div>
                <button
                  onClick={() => {
                    if (!isAdditionalValueUI) {
                      setAdditionalValueUI(!isAdditionalValueUI);
                    } else {
                      setAdditionalValueUI(!isAdditionalValueUI);
                      setBubbleMapConfig({
                        ...bubbleMapConfig,
                        additional_z_value: null,
                      });
                      console.log(bubbleMapConfig);
                    }
                  }}
                  className="btn ms-4 btn-block modal__submit_btn data-source_btn btn__secondary"
                >
                  Remove
                </button>
              </div>
            </Fragment>
          )}
        </div>

        <div className="row form__row mt-1 mb-3">
          <div className="col-md-12">
            <div className="form__group">
              <label>Set Map Boundary Coordinates</label>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form__group">
              <input
                type="text"
                className="form-control form__control"
                placeholder="Max Lat (North)"
                value={
                  bubbleMapConfig.map_view_limit
                    ? bubbleMapConfig.map_view_limit.max_lat
                      ? bubbleMapConfig.map_view_limit.max_lat
                      : ""
                    : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    map_view_limit: {
                      ...bubbleMapConfig.map_view_limit,
                      max_lat:
                        e.target.value || e.target.value !== ""
                          ? e.target.value
                          : null,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form__group">
              <input
                type="text"
                id=""
                className="form-control form__control"
                placeholder="Max Long (East)"
                value={
                  bubbleMapConfig.map_view_limit
                    ? bubbleMapConfig.map_view_limit.max_lng
                      ? bubbleMapConfig.map_view_limit.max_lng
                      : ""
                    : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    map_view_limit: {
                      ...bubbleMapConfig.map_view_limit,
                      max_lng:
                        e.target.value || e.target.value !== ""
                          ? e.target.value
                          : null,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form__group">
              <input
                type="text"
                id=""
                className="form-control form__control"
                placeholder="Min Lat (South)"
                value={
                  bubbleMapConfig.map_view_limit
                    ? bubbleMapConfig.map_view_limit.min_lat
                      ? bubbleMapConfig.map_view_limit.min_lat
                      : ""
                    : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    map_view_limit: {
                      ...bubbleMapConfig.map_view_limit,
                      min_lat:
                        e.target.value || e.target.value !== ""
                          ? e.target.value
                          : null,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form__group">
              <input
                type="text"
                className="form-control form__control"
                placeholder="Min Long (West)"
                value={
                  bubbleMapConfig.map_view_limit
                    ? bubbleMapConfig.map_view_limit.min_lng
                      ? bubbleMapConfig.map_view_limit.min_lng
                      : ""
                    : ""
                }
                onChange={(e) => {
                  e.stopPropagation();
                  setBubbleMapConfig({
                    ...bubbleMapConfig,
                    map_view_limit: {
                      ...bubbleMapConfig.map_view_limit,
                      min_lng:
                        e.target.value || e.target.value !== ""
                          ? e.target.value
                          : null,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="form__group mt-2 mb-0 btn__groups d-flex justify-content-end">
          <button
            className="btn ms-4 modal__submit_btn data-source_btn btn__secondary"
            onClick={() => {
              // applyDatasetClickHandler();

              let chart_data = {
                chart_type_api_sep: "bubble_map",
                is_three_dym: false,
                chart_type: "line",
                chart_line_type: "bubble_map",
                chart_name:
                  chartTitle || chartTitle !== "" ? chartTitle : "Bubble Map",
                dataset_guid: selectedDataset.guid,
                is_active: "true",

                x_axis_label: "X",
                y_axis_label: "Y",
                x_axis_category: "no data",
                x_axis_categories: null,
                y_axis_categories: [],
                z_axis_categories: null,

                chart_config: {
                  ...bubbleMapConfig,
                  map_view_limit: {
                    max_lat: bubbleMapConfig.map_view_limit.max_lat,
                    max_lng: bubbleMapConfig.map_view_limit.max_lng,
                    min_lat: bubbleMapConfig.map_view_limit.min_lat,
                    min_lng: bubbleMapConfig.map_view_limit.min_lng,
                  },
                },
                filterMapbox: {},
              };

              console.log(chart_data);

              dispatch({
                type: "UPDATE_REQUEST_LOADER_STATE",
                payload: true,
              });
              if (
                !panelInfo.panel_config.data ||
                panelInfo.panel_config.data.dataset !== selectedDataset.guid
              ) {
                dispatch(
                  createChart(
                    panelFieldInfo.panel_type,
                    panelFieldInfo.panel_type_guid,
                    chart_data
                  )
                )
                  .then((res) => {
                    panelInfo.panel_config.data = res.data;
                    panelInfo.panel_config.bar_data = chart_data;
                    dispatch(
                      updateCustomDashboardPanel___(
                        activeTabData.guid,
                        panelInfo.guid,
                        {
                          chart_guid: res.data.guid,
                          panel_config: panelInfo.panel_config,
                        }
                      )
                    ).then(() => {
                      closeModal();
                    });
                  })
                  .catch((err) => {
                    // console.log(err);
                  });
              } else {
                panelInfo.panel_config.data = {
                  ...panelInfo.panel_config.data,
                  ...chart_data,
                };
                panelInfo.panel_config.bar_data = chart_data;
                dispatch(
                  updateCustomDashboardPanel(
                    activeTabData.guid,
                    panelInfo.guid,
                    panelInfo.panel_config
                  )
                );
                closeModal();
              }
            }}
            disabled={
              !selectedDataset ||
              !bubbleMapConfig.plot_type ||
              !bubbleMapConfig.base_map ||
              !bubbleMapConfig.zoom ||
              !bubbleMapConfig.lat ||
              !bubbleMapConfig.lng ||
              !bubbleMapConfig.z_value ||
              !bubbleMapConfig.radius ||
              !bubbleMapConfig.color_palate
            }
          >
            Apply
          </button>
        </div>
      </div>
    </ModalAT>
  );
};

export default BubbleMapUpdateModal;
