import { v4 as uuidv4 } from "uuid";

const defaultState = {
  failedToFetchData: false,
  modals: {
    assetSpecs: null,
    pipelineSpecs: null,
    componentSpecs: null,
  },
};

export default function webglAssociatesReducer(state = defaultState, action) {
  switch (action.type) {
    case "SET_ASSET_SPECS_MODAL_DATA":
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          ...state.modals,
          assetSpecs: action.payload,
        },
      };

    case "SET_PIPELINE_SPECS_MODAL_DATA":
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          ...state.modals,
          pipelineSpecs: action.payload,
        },
      };
    case "SET_COMPONENT_SPECS_MODAL_DATA":
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          ...state.modals,
          componentSpecs: action.payload,
        },
      };

    case "ASSET_SPEC_BASIC_DATA_CHANGE":
      const updatedElement = {
        ...state.modals.assetSpecs.basic_data[action.payload.elementIndex],
        [action.payload.key]: action.payload.newValue,
      };

      const updatedBasicData = [...state.modals.assetSpecs.basic_data];
      updatedBasicData[action.payload.elementIndex] = updatedElement;

      return {
        ...state,
        failedToFetchData: false,
        modals: {
          assetSpecs: {
            ...state.modals.assetSpecs,
            basic_data: updatedBasicData,
          },
        },
      };

    case "PIEPLINE_SPEC_BASIC_DATA_CHANGE":
      const updatedPipelineElement = {
        ...state.modals.pipelineSpecs.basic_data[action.payload.elementIndex],
        [action.payload.key]: action.payload.newValue,
      };

      const updatedPipelineBasicData = [
        ...state.modals.pipelineSpecs.basic_data,
      ];
      updatedPipelineBasicData[action.payload.elementIndex] =
        updatedPipelineElement;

      return {
        ...state,
        failedToFetchData: false,
        modals: {
          pipelineSpecs: {
            ...state.modals.pipelineSpecs,
            basic_data: updatedPipelineBasicData,
          },
        },
      };

    case "COMPONENT_SPEC_BASIC_DATA_CHANGE":
      const updatedComponentElement = {
        ...state.modals.componentSpecs.basic_data[action.payload.elementIndex],
        [action.payload.key]: action.payload.newValue,
      };

      const updatedComponentBasicData = [
        ...state.modals.componentSpecs.basic_data,
      ];
      updatedComponentBasicData[action.payload.elementIndex] =
        updatedComponentElement;

      return {
        ...state,
        failedToFetchData: false,
        modals: {
          componentSpecs: {
            ...state.modals.componentSpecs,
            basic_data: updatedComponentBasicData,
          },
        },
      };

    case "ASSET_SPECS_DATA_CHANGE":
      const updatedSpecsElement = {
        ...state.modals.assetSpecs.specifications[action.payload.elementIndex],
        [action.payload.key]: action.payload.newValue,
      };

      const updatedSpecsData = [...state.modals.assetSpecs.specifications];
      updatedSpecsData[action.payload.elementIndex] = updatedSpecsElement;

      return {
        failedToFetchData: false,
        ...state,
        modals: {
          assetSpecs: {
            ...state.modals.assetSpecs,
            specifications: updatedSpecsData,
          },
        },
      };
    case "PIPELINE_SPECS_DATA_CHANGE":
      const updatedPipelineSpecsElement = {
        ...state.modals.pipelineSpecs.specifications[
          action.payload.elementIndex
        ],
        [action.payload.key]: action.payload.newValue,
      };

      const updatedPipelineSpecsData = [
        ...state.modals.pipelineSpecs.specifications,
      ];
      updatedPipelineSpecsData[action.payload.elementIndex] =
        updatedPipelineSpecsElement;

      return {
        ...state,
        failedToFetchData: false,
        modals: {
          pipelineSpecs: {
            ...state.modals.pipelineSpecs,
            specifications: updatedPipelineSpecsData,
          },
        },
      };
    case "COMPONENT_SPECS_DATA_CHANGE":
      const updatedComponentSpecsElement = {
        ...state.modals.componentSpecs.specifications[
          action.payload.elementIndex
        ],
        [action.payload.key]: action.payload.newValue,
      };

      const updatedComponentSpecsData = [
        ...state.modals.componentSpecs.specifications,
      ];
      updatedComponentSpecsData[action.payload.elementIndex] =
        updatedComponentSpecsElement;

      return {
        ...state,
        failedToFetchData: false,
        modals: {
          componentSpecs: {
            ...state.modals.componentSpecs,
            specifications: updatedComponentSpecsData,
          },
        },
      };
    case "REMOVE_ASSET_SPECS":
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          assetSpecs: {
            ...state.modals.assetSpecs,
            specifications: state.modals.assetSpecs.specifications.filter(
              (specs) => specs.guid !== action.payload
            ),
          },
        },
      };
    case "REMOVE_PIPELINE_SPECS":
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          pipelineSpecs: {
            ...state.modals.pipelineSpecs,
            specifications: state.modals.pipelineSpecs.specifications.filter(
              (specs) => specs.guid !== action.payload
            ),
          },
        },
      };
    case "REMOVE_COMPONENT_SPECS":
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          componentSpecs: {
            ...state.modals.componentSpecs,
            specifications: state.modals.componentSpecs.specifications.filter(
              (specs) => specs.guid !== action.payload
            ),
          },
        },
      };
    case "ADD_ASSET_SPECT_NEW_PARAM":
      const newSpecs = {
        guid: uuidv4(),
        label: "",
        value: "",
        showing: true,
        unit: "",
        value_type: "text",
      };
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          assetSpecs: {
            ...state.modals.assetSpecs,
            specifications: [
              newSpecs,
              ...state.modals.assetSpecs.specifications,
            ],
          },
        },
      };
    case "ADD_PIPELINE_SPECT_NEW_PARAM":
      const newPipelineSpecs = {
        guid: uuidv4(),
        label: "",
        value: "",
        showing: true,
        unit: "",
        value_type: "text",
      };
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          pipelineSpecs: {
            ...state.modals.pipelineSpecs,
            specifications: [
              newPipelineSpecs,
              ...state.modals.pipelineSpecs.specifications,
            ],
          },
        },
      };
    case "ADD_COMPONENT_SPECS_NEW_PARAM":
      const newComponentSpecs = {
        guid: uuidv4(),
        label: "",
        value: "",
        showing: true,
        unit: "",
        value_type: "text",
      };
      return {
        ...state,
        failedToFetchData: false,
        modals: {
          componentSpecs: {
            ...state.modals.componentSpecs,
            specifications: [
              newComponentSpecs,
              ...state.modals.componentSpecs.specifications,
            ],
          },
        },
      };

    case "FAILED_TO_FETCH_MODAL_DATA":
      return {
        ...state,
        failedToFetchData: !state.failedToFetchData,
      };

    default:
      return state;
  }
}
