import React from "react";
import { useSelector } from "react-redux";

const ProfileInfoCard = ({ info }) => {
  const profile_img_link = useSelector((state) => state.auth.profile_img_link);
  const first_name = useSelector((state) => state.auth.first_name);
  const last_name = useSelector((state) => state.auth.last_name);
  const organization_name = useSelector(
    (state) => state.auth.organization_name
  );
  return (
    <div className="user__panel--col user__panel--info col-md-3">
      <div className="card card-body user__panel--card">
        <div className="user__panel--avater">
          <img
            src={
              profile_img_link
                ? profile_img_link
                : "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
            }
            alt="User Name"
          />
        </div>
        <div className="user__panel--info text-center">
          <h4 className="user-panel-name">{first_name + " " + last_name}</h4>
          <p className="user-panel-deg">
            {organization_name ? organization_name : "No Organization Aasigned"}
          </p>
        </div>
        <div className="user__profile--statistics">
          <div className="card__statistic">
            <h2 className="card__counter">{info.No_of_Fields}</h2>
            <h5 className="card__title">Total Fields</h5>
          </div>
          <div className="card__statistic">
            <h2 className="card__counter">{info.No_of_Assets}</h2>
            <h5 className="card__title">Total Assets</h5>
          </div>
          <div className="card__statistic">
            <h2 className="card__counter">{info.No_of_Pipelines}</h2>
            <h5 className="card__title">Total Pipelines</h5>
          </div>
          <div className="card__statistic">
            <h2 className="card__counter">{info.No_of_Components}</h2>
            <h5 className="card__title">Total Components</h5>
          </div>
          <div className="card__statistic">
            <h2 className="card__counter">{info.No_of_Datasets}</h2>
            <h5 className="card__title">Total Datasets</h5>
          </div>
          <div className="card__statistic">
            <h2 className="card__counter">{info.No_of_Images}</h2>
            <h5 className="card__title">Total Images</h5>
          </div>
          <div className="card__statistic">
            <h2 className="card__counter">{info.No_of_Documents}</h2>
            <h5 className="card__title">Total Documents</h5>
          </div>
          <div className="card__statistic">
            <h2 className="card__counter">{info.No_of_Project_Share}</h2>
            <h5 className="card__title">Total Shared Project</h5>
          </div>
        </div>
        <ul className="user__panel-list user__panel--progress-lists">
          {/* <li>Subscription Type: {subscription.subscription_type}</li> */}
          <li>
            <span>Used Space: {info.Used_Storage}</span>
            <div className="progress user__panel--progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: (parseFloat(info.Used_Storage) / parseFloat(info.Total_Storage)) * 100 + "%" }}
                aria-valuenow={info.Total_Storage}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
          </li>
          <li>
            <span>Free Space: {info.Total_Storage - info.Used_Storage}</span>
            <div className="progress user__panel--progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: (parseFloat(info.Remaining_Storage) / parseFloat(info.Total_Storage)) * 100 + "%" }}
                aria-valuenow={info.Total_Storage}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileInfoCard;
