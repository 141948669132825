import React from "react";
import ProfileInfoCard from "../ProfileInfoCard";
import ProfileContentCard from "../ProfileContentCard";
import "./style.css";

const ProjectManagement = () => {
  return (
    <div className="user__panel--dashboard">
      <div className="user__panel row">
        <ProfileInfoCard />
        <ProfileContentCard />
      </div>
    </div>
  );
};

export default ProjectManagement;
