import React, { useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import "./styles.css";

const list = [
  {
    id: "0",
    order: 0,
    unifierName: "Unifier 1",
    unifierData: 500,
  },
  {
    id: "1",
    order: 1,
    unifierName: "Unifier 2",
    unifierData: 1000,
  },
  {
    id: "2",
    order: 2,
    unifierName: "Unifier 2",
    unifierData: 1500,
  },
  {
    id: "3",
    order: 3,
    unifierName: "Unifier 3",
    unifierData: 2000,
  },
  {
    id: "4",
    order: 4,
    unifierName: "Unifier 4",
    unifierData: 2500,
  },
  {
    id: "5",
    order: 5,
    unifierName: "Unifier 5",
    unifierData: 3000,
  },
  {
    id: "6",
    order: 6,
    unifierName: "Unifier 6",
    unifierData: 3500,
  },
  {
    id: "7",
    order: 7,
    unifierName: "Unifier 7",
    unifierData: 4000,
  },
];

function sortMainList(list) {
  return list.slice().sort((first, second) => first.order - second.order);
}

const Unifier = () => {
  const [sortedList, setSortList] = useState(list);

  const sortList = () => {
    setSortList(sortMainList(sortedList));
  };

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = sortedList;
    if (destinationIndex === 0) {
      list[sourceIndex].order = list[0].order - 1;
      sortList();
      return;
    }
    if (destinationIndex === list.length - 1) {
      list[sourceIndex].order = list[list.length - 1].order + 1;
      sortList();
      return;
    }
    if (destinationIndex < sourceIndex) {
      list[sourceIndex].order =
        (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
      sortList();
      return;
    }
    list[sourceIndex].order =
      (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
    sortList();
  };

  return (
    <ListManager
      items={sortedList}
      direction="horizontal"
      maxItems={8}
      render={(item) => <ListElement item={item} />}
      onDragEnd={reorderList}
    />
  );
};

function ListElement({ item }) {
  return (
    <div className="chart-container-wrapper">
      <div className="chart-container">
        <div className="delete_item" onClick={() => alert(item.unifierName)}>
          <span>❌</span>
        </div>
        <div className="chart-info-wrapper">
          <h2>{item.unifierName}</h2>
          <span>{item.unifierData}</span>
        </div>
      </div>
    </div>
  );
}

export default Unifier;
