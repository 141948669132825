import React, { useEffect, useRef, useState } from "react";
// import Plot from "react-plotly.js";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import request from "../../../../../utils/request";
import { useSelector } from "react-redux";
import { getDefaultChartData } from "./../defaultChartData";
import { generateStackedBarColumnChartLayout } from "./../layout/layout_stacked_bar_column.default";
import Plot from "react-plotly.js";
import { generate3DLineChartLayout } from "./../layout/layout.default_3D";
import {
  camera_view_bottom,
  camera_view_front,
  camera_view_left,
  camera_view_rear,
  camera_view_right,
  camera_view_top,
  generateMeshChartConfig,
  generateMeshChartLayout,
} from "./../layout/layout_mesh.default.js";
import toastr from "toastr";
import { generatePieChartLayout } from "./../layout/layout_pie.default";
import { getIconFromList } from "../../../../../static/IconServe";
import icon_btm from "./../static/bottom_view.png";
import MeshPlotViewPoint from "./viewpoint";
import MeshFilter from "./filter";
import MeshRange from "./Range";
import MeshColorRange from "./ColorRange";

const GenerateMeshPlot = ({ chart, panel }) => {
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const [isLoaderOn, setIsLoaderOn] = useState(true);
  const [barChartData, setBarChartData] = useState([]);
  const plotRef = useRef(null);
  const [viewPoint, setViewPoint] = useState("front");
  const [viewPointPrev, setViewPointPrev] = useState("front");
  const [mlayout, setMlayout] = useState(
    generateMeshChartLayout(chart, panel.panel_config.bar_data)
  );
  const [filterMinMaxVal, setFilterMinMaxVal] = useState(null);
  const [receivedProps, setReceivedProps] = useState(null);
  const [receivedColorProps, setReceivedColorProps] = useState(null);

  useEffect(async () => {
    createMeshPlot();
    setViewPoint("front");
    setMlayout(generateMeshChartLayout(chart, panel.panel_config.bar_data));

    return () => {};
  }, [panel]);

  // --------------- MESH CHART ---------------
  const createMeshPlot = async (pageNo) => {
    let payload = {
      dataset_type: panelFieldInfo.panel_type,
      chart_config: panel.panel_config.bar_data.chart_config,
    };

    setIsLoaderOn(true);
    try {
      let { data } = await request.post(
        `/api/mesh/chart/${chart.guid}/stats/`,
        payload
      );
      let bar_data_arr = [];
      let bc = {
        x: data.x,
        y: data.y,
        z: data.z,
        i: data.i,
        j: data.j,
        k: data.k,
        intensity: data.value,
        cmin: data.actual_cmin,
        cmax: data.actual_cmax,
        type: "mesh3d",
        colorscale: getMeshColorScale(
          panel.panel_config.bar_data.chart_config.color_plate
        ),
        reversescale: getMeshColorScaleIsReverse(
          panel.panel_config.bar_data.chart_config.color_plate
        ),
        showscale: false,
        hovertemplate:
          panel.panel_config.bar_data.chart_config.x.col_name +
          ": %{x}<br>" +
          panel.panel_config.bar_data.chart_config.y.col_name +
          ": %{y}<br>" +
          panel.panel_config.bar_data.chart_config.z.col_name +
          ": %{z}<br>" +
          panel.panel_config.bar_data.chart_config.value.col_name +
          ": %{intensity:.3f}<br>" +
          "<extra></extra>",
        decimation: 1,
        colorbar: {
          tickfont: {
            color: "#FFFFFF",
          },
        },
      };
      if (
        !panel.panel_config.bar_data.chart_config.cmin ||
        !panel.panel_config.bar_data.chart_config.cmax ||
        !panel.panel_config.bar_data.chart_config.actual_cmin ||
        !panel.panel_config.bar_data.chart_config.actual_cmax
      ) {
        panel.panel_config.bar_data.chart_config.cmin = data.cmin;
        panel.panel_config.bar_data.chart_config.cmax = data.cmax;
        panel.panel_config.bar_data.chart_config.actual_cmin = data.actual_cmin;
        panel.panel_config.bar_data.chart_config.actual_cmax = data.actual_cmax;
      }

      setFilterMinMaxVal(data.column_range);

      // findDatasMinMax(data);
      bar_data_arr.push(bc);
      setBarChartData(bar_data_arr);
      setIsLoaderOn(false);
    } catch (error) {
      toastr.warning(
        "Incorrect chart parameters, please try again.",
        "Wrong parameters"
      );
    }
  };

  // const findDatasMinMax = (data) => {
  //   let arr__ = [];
  //   arr__.push({
  //     col: panel.panel_config.bar_data.chart_config.x,
  //     min: getMinValueOfArr(data.x),
  //     max: getMaxValueOfArr(data.x),
  //   });
  //   arr__.push({
  //     col: panel.panel_config.bar_data.chart_config.y,
  //     min: getMinValueOfArr(data.y),
  //     max: getMaxValueOfArr(data.y),
  //   });
  //   arr__.push({
  //     col: panel.panel_config.bar_data.chart_config.z,
  //     min: getMinValueOfArr(data.z),
  //     max: getMaxValueOfArr(data.z),
  //   });
  // };

  // const getMinValueOfArr = (arr) => {
  //   return arr.reduce(function (p, v) {
  //     return p < v ? p : v;
  //   });
  // };

  // const getMaxValueOfArr = (arr) => {
  //   return arr.reduce(function (p, v) {
  //     return p > v ? p : v;
  //   });
  // };

  // --------------- MESH COLOR SCALE ---------------
  const getMeshColorScale = (clr_str) => {
    switch (clr_str) {
      case "blylrd":
      case "rdylbl":
        return [
          [0, "rgb(0,0,255)"],
          [0.5, "rgb(255,255,0)"],
          [1, "rgb(255, 0, 0)"],
        ];
      case "grylrd":
      case "rdylgr":
        return [
          [0, "rgb(1,106,56)"],
          [0.5, "rgb(255,255,0)"],
          [1, "rgb(255, 0, 0)"],
        ];
      default:
        return [
          [0, "rgb(0,0,255)"],
          [0.5, "rgb(255,255,0)"],
          [1, "rgb(255, 0, 0)"],
        ];
    }
  };

  const getMeshColorScaleIsReverse = (clr_str) => {
    switch (clr_str) {
      case "rdylbl":
        return true;
      case "rdylgr":
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    let l = Object.assign({}, mlayout);

    if (barChartData.length > 0) {
      switch (viewPoint) {
        case "front":
          mlayout.scene.camera = camera_view_front();
          break;
        case "top":
          mlayout.scene.camera = camera_view_top();
          break;
        case "bottom":
          mlayout.scene.camera = camera_view_bottom();
          break;
        case "left":
          mlayout.scene.camera = camera_view_left();
          break;
        case "right":
          mlayout.scene.camera = camera_view_right();
          break;
        case "rear":
          mlayout.scene.camera = camera_view_rear();
          break;
        default:
          // l = generateMeshChartLayout();
          // mlayout = generateMeshChartLayout();
          break;
      }
      setViewPoint("default");
      // document.getElementById("default_mesh_view_point").click();
    }
  }, [viewPoint]);

  useEffect(() => {
    if (receivedProps) {
      switch (receivedProps.axis) {
        case "x":
          mlayout.scene.xaxis.range = receivedProps.val;
          break;
        case "y":
          mlayout.scene.yaxis.range = receivedProps.val;
          break;
        case "z":
          mlayout.scene.zaxis.range = receivedProps.val;
          break;
        default:
          mlayout.scene.zaxis.range = receivedProps.val;
          break;
      }
      // document.querySelector("[data-title='Pan']").click();
      // document.querySelector("[data-title='Toggle show closest data on hover']").click();
      let selector = document.querySelectorAll("[data-title='Toggle show closest data on hover']");
      selector.forEach(e => {
        e.click();
      })
    }
  }, [receivedProps]);

  useEffect(() => {
    if (receivedColorProps) {
      barChartData[0].cmin = parseFloat(receivedColorProps[0]);
      barChartData[0].cmax = parseFloat(receivedColorProps[1]);
      // document.querySelector("[data-title='Toggle show closest data on hover']").click();
      let selector = document.querySelectorAll("[data-title='Toggle show closest data on hover']");
      selector.forEach(e => {
        e.click();
      })
    }
  }, [receivedColorProps]);

  const getPlot = () => {
    return (
      <Plot
        ref={plotRef}
        data={barChartData}
        layout={mlayout}
        config={generateMeshChartConfig()}
        // frames={{meshFrame}}
        useResizeHandler={false}
        className="wh-100 mesh__plot--chart"
      />
    );
  };

  return (
    <React.Fragment>
      {isLoaderOn ? (
        <div className="chart-loader"></div>
      ) : (
        <>
          {panel && (
            <MeshRange
              panel={panel}
              setReceivedProps={setReceivedProps}
              filterMinMaxVal={filterMinMaxVal}
            />
          )}
          <div
            className="__mesh-plot-color-parent__"
            style={{ position: "absolute", top: "16%", right: "-18px" }}
          >
            {panel &&
              panel.panel_config.bar_data.chart_config.actual_cmin &&
              panel.panel_config.bar_data.chart_config.actual_cmax && (
                <MeshColorRange
                  panel={panel}
                  setReceivedColorProps={setReceivedColorProps}
                  colorbarscaleClass={
                    panel.panel_config.bar_data.chart_config.color_plate
                  }
                />
              )}
          </div>

          {getPlot()}
          <MeshPlotViewPoint
            viewPoint={viewPoint}
            setViewPoint={setViewPoint}
            viewPointPrev={viewPointPrev}
            setViewPointPrev={setViewPointPrev}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default GenerateMeshPlot;
