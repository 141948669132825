export const generateProfileGroupLayout = (data) => ({
    autosize: true,
    margin: {
      l: 50,
      r: 10,
      b: 10,
      t: 20,
      pad: 10,
    },  
    paper_bgcolor: "lightblue",
    plot_bgcolor: "#ddd",
    barmode: 'group',
  
    legend: {
      x: 1,
      y: 1.3,
      xanchor: "right",
      orientation: "h",
      font: {
        family: "Arial, sans-serif",
        size: 10,
        color: "white",
      },
    },
    xaxis: {
      automargin: true,
      zerolinewidth: 0,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      tickcolor: "#B9B8CE",
      tickangle: "auto",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        standoff: 10,
        text: data.x_axis_label,
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
      linecolor: '#00FFFB',
      linewidth: 2
    },
    yaxis: {
      automargin: true,
      zerolinewidth: 0,
      gridcolor: "#004B54",
      griddash: "dot",
      gridwidth: 1,
      tickcolor: "#B9B8CE",
      tickfont: {
        family: "sans-serif",
        size: 12,
        color: "#01E3FE",
      },
      title: {
        text: data.y_axis_label,
        font: {
          family: "sans-serif",
          size: 12,
          color: "#fff",
        },
      },
      linecolor: '#00FFFB',
      linewidth: 2
    },
  });
  