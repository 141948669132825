import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import GoogleMapReact from "google-map-react";
import {
  useLoadScript,
  GoogleMap,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import { getIconFromList } from "../../../static/IconServe";
import { fetchAssetSpecs } from "../../../actions/panelActions";
import {
  deleteCustomDashboardPanel,
  updateCustomDashboardPanel,
} from "../../../actions/customPanelModify";
import { rndPositionDefaultXYWH, rndPositionStyle, rndViewerClassName } from "../../../utils";

const Maps = ({ panel }) => {
  const dispatch = useDispatch();
  const assetSpecData = useSelector(
    (state) => state.panel.metaData.assetSpecifications
  );
  const panelFieldInfo = useSelector(
    (state) => state.customPanelModify.panelFieldInfo
  );
  const panelUserAccessInfo = useSelector(
    (state) => state.customPanelModify.panelUserAccessInfo
  );
  const activeTabData = useSelector(
    (state) => state.customPanelModify.ACTIVE_TAB_DATA
  );
  const [isDraggableUnAvailable, setIsDraggableUnAvailable] = useState(false);

  const [center, setCenter] = useState({
    lat: 23.797337,
    lng: 90.403146,
  });
  const [markers, setMarker] = useState([]);

  useEffect(() => {
    dispatch(fetchAssetSpecs(panelFieldInfo.project_guid));
  }, []);

  useEffect(() => {
    setCenter({
      lat: panelFieldInfo ? panelFieldInfo.location.latitude : 23.797337,
      lng: panelFieldInfo ? panelFieldInfo.location.longitude : 90.403146,
    });
    setMarker([]);
    if (assetSpecData) {
      let list = [];
      if (panelFieldInfo.panel_type === "project") {
        assetSpecData.data.forEach((data, index) => {
          let obj = {
            id: index + 1,
            name: data["Asset Module"],
            position: {
              lat: data["Latitude"],
              lng: data["Longitude"],
            },
          };
          list.push(obj);
        });
      } else {
        let obj = {
          id: 1,
          name: "Asset Module",
          position: {
            lat: panelFieldInfo ? panelFieldInfo.location.latitude : 23.797337,
            lng: panelFieldInfo ? panelFieldInfo.location.longitude : 90.403146,
          },
        };
        list.push(obj);
      }
      setMarker(list);
    }
  }, [assetSpecData]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXFgPPviqeXU444fHY5ynWVB_FCvOga5o", // Add your API key
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  const updatePanelSectionPosition = (panel, x, y, w, h) => {
    panel.panel_config.defaultPosition.x = x;
    panel.panel_config.defaultPosition.y = y;
    panel.panel_config.defaultPosition.width = w;
    panel.panel_config.defaultPosition.height = h;

    panel.panel_config.panelStyleRatio = {
      widthPercent: (w/document.documentElement.clientWidth),
      // heightPercent: (h/document.documentElement.clientHeight),
      heightPercent: h,
      x: (x/document.documentElement.clientWidth),
      y: (y/document.documentElement.clientHeight)
    };
    dispatch(
      updateCustomDashboardPanel(
        activeTabData.guid,
        panel.guid,
        panel.panel_config
      )
    );
  };

  return (
    <>
      <div className="map-body-section">
        <Rnd
          className={rndViewerClassName("rnd-section-map rnd__section__panel", panelUserAccessInfo)}
          id={"rnd-section-map-" + panel.guid}
          // style={panel.panel_config.defaultStyle}
          // default={panel.panel_config.defaultPosition}
          style={rndPositionStyle(panel.panel_config.defaultStyle)}
          default={rndPositionDefaultXYWH(panel.panel_config)}
          disableDragging={
            panelUserAccessInfo && !panelUserAccessInfo.dashboard.operation
              ? true
              : isDraggableUnAvailable
          }
          onDragStop={(event, dragableData) => {
            event.preventDefault();
            // // console.log()
            if (
              panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation
            ) {
              updatePanelSectionPosition(
                panel,
                dragableData.x,
                dragableData.y,
                panel.panel_config.defaultPosition.width,
                panel.panel_config.defaultPosition.height
              );
            }
          }}
          onResizeStop={(event, direction, ref, delta, position) => {
            event.preventDefault();
            if (
              panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation
            ) {
              updatePanelSectionPosition(
                panel,
                position.x,
                position.y,
                panel.panel_config.defaultPosition.width + delta.width,
                panel.panel_config.defaultPosition.height + delta.height
              );
            }
          }}
        >
          <div className="chart-container-wrapper __RND_EMD_CHILD_CONTAINER__">
            <div className="chart-container" style={{ padding: "0" }}>
              {panelUserAccessInfo &&
              panelUserAccessInfo.dashboard.operation ? (
                <>
                  <span
                    className="panel-remove-icon"
                    onMouseOver={(e) => {
                      e.preventDefault();
                      setIsDraggableUnAvailable(true);
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        deleteCustomDashboardPanel(
                          activeTabData.guid,
                          panel.guid
                        )
                      );
                      document
                        .getElementById("rnd-section-map-" + panel.guid)
                        .remove();
                    }}
                  >
                    {getIconFromList("DELETE", { cursor: "pointer" })}
                  </span>
                </>
              ) : (
                ""
              )}

              <div
                className="chart-info-wrapper"
                style={{
                  height: panel.panel_config.defaultPosition.height - 3 + "px",
                }}
              >
                <h4>
                  {panelFieldInfo.panel_type === "project"
                    ? "Asset Locations"
                    : "Asset Location"}
                </h4>
                {isLoaded ? (
                  <GoogleMap
                    onLoad={handleOnLoad}
                    onClick={() => setActiveMarker(null)}
                    zoom={4}
                    center={center}
                    mapContainerStyle={{
                      width: "100%",
                      height:
                        panel.panel_config.defaultPosition.height - 54 + "px",
                    }}
                    onMouseOver={() => {
                      setIsDraggableUnAvailable(true);
                    }}
                    onMouseOut={() => {
                      setIsDraggableUnAvailable(false);
                    }}
                  >
                    {markers.map(({ id, name, position }) => (
                      <Marker
                        key={id}
                        position={position}
                        onClick={() => handleActiveMarker(id)}
                      >
                        {activeMarker === id ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                          >
                            <div className="text-dark">{name}</div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    ))}
                  </GoogleMap>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Rnd>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Maps);
