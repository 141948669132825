import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ChartLayout_Legend from "./legend";
import ChartLayout_Margin from "./margin";
import ChartLayout_X_Axis from "./x_axis";
import ChartLayout_Y_Axis from "./y_axis";
import ChartLayout_Z_Axis from "./z_axis";

const ChartLayout = ({ isZAxisAvailable, chartLayout, setChartLayout }) => {
  return (
    <>
      <div className="row form__row mt-4">
        <div className="col-md-12 pr-2">
          <div className="form__group">
            <label>
              <strong>Layout Configuration</strong>
            </label>
          </div>
        </div>
      </div>
      <ChartLayout_Margin
        chartLayout={chartLayout}
        setChartLayout={setChartLayout}
      />
      {/* <ChartLayout_Legend /> */}
      <div className="row form__row mt-4 pr-2">
        <ChartLayout_X_Axis
          chartLayout={chartLayout}
          setChartLayout={setChartLayout}
        />
        <ChartLayout_Y_Axis
          chartLayout={chartLayout}
          setChartLayout={setChartLayout}
        />
        {isZAxisAvailable ? (
          <ChartLayout_Z_Axis
            chartLayout={chartLayout}
            setChartLayout={setChartLayout}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ChartLayout;
