import React from "react";
import Modal from "react-modal";
import FileViewer from "react-file-viewer";

const customStyles = {
  content: {
    width: "70%",
    padding: "0",
    top: "0",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
  },
};
let re = /(?:\.([^.]+))?$/;

const ResourceViewer = ({ isViewerOpen, setIsViewerModal, file }) => {
  // // console.log(isViewerOpen);
  // // console.log(setIsViewerModal);
  // // console.log(file);

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  const checkFileNameExt = () => {
    // // console.log("re.exec(file.name)[1]");
    // // console.log(file);
    // // console.log(file.name);
    // // console.log(re.exec(file.name));
    // // console.log(re.exec(file.type));
    // let fileExt = re.exec(file.name)[1];
    let fileExt = get_url_extension(file.doc_url);
    // // console.log(fileExt);
    if (
      fileExt.toLocaleLowerCase() === "png" ||
      fileExt.toLocaleLowerCase() === "jpg" ||
      fileExt.toLocaleLowerCase() === "jpeg" ||
      fileExt.toLocaleLowerCase() === "gif" ||
      fileExt.toLocaleLowerCase() === "svg"
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <Modal
      isOpen={isViewerOpen}
      onRequestClose={() => {
        setIsViewerModal(!isViewerOpen);
      }}
      style={customStyles}
      contentLabel="Image Modal"
      id="ReactModal__media_viewer"
      ariaHideApp={false}
    >
      <div className="ReactModal__Content--container">
        <div className="ReactModal__curvedBorder ReactModal__curvedBorder--top-left"></div>
        <div className="ReactModal__curvedBorder ReactModal__curvedBorder--top-right"></div>
        <div className="ReactModal__curvedBorder ReactModal__curvedBorder--bottom-left"></div>
        <div className="ReactModal__curvedBorder ReactModal__curvedBorder--bottom-right"></div>
        <div className="ReactModal__Content--block">
          <div className="ReactModal__Content--inner">
            <div className="ReactModal__Content--inner__header">
              <div className="ReactModal__Content--inner__header--block">
                <div className="ReactModal__Content--inner__header--block__block">
                  <marquee loop="infinite">
                    <h4 className="ReactModal__Content--title">{file.name}</h4>
                  </marquee>
                </div>
              </div>
            </div>
            <div className="ReactModal__Content--inner__body">
              {checkFileNameExt() ? (
                <div className="media_type__image">
                  <img src={file.doc_url} />
                </div>
              ) : (
                <FileViewer
                  // fileType={re.exec(file.name)[1]}
                  fileType={get_url_extension(file.doc_url)}
                  filePath={file.doc_url}
                  errorComponent={<h2>System can't view this file type</h2>}
                  onError={(err) => {
                    // console.log(err);
                  }}
                />
              )}
              <a href={file.doc_url} download className="resource_dnld_btn">
                <button>Download</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn ReactModal__btn ReactModal__btn--close px-0"
        onClick={() => setIsViewerModal(!isViewerOpen)}
      ></button>
    </Modal>
  );
};

export default ResourceViewer;
