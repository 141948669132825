import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPipelineBasics } from "../../../../../actions/panelActions";
import Loader from "../../Loader";

const PipelineBasics = ({ projectguid }) => {
  const pipelineBasics = useSelector(
    (state) => state.panel.metaData.pipelineBasics
  );
  const dispatch = useDispatch();

  // if (!pipelineBasics) {
  //   setTimeout(() => {
  //     dispatch(fetchPipelineBasics(projectguid));
  //   }, 2000);
  // }

  useEffect(() => {
    dispatch(fetchPipelineBasics(projectguid));
  }, [projectguid]);

  return (
    <div className="card-secondary-gradient">
      {/* <div className="card-header">
        <h4 className="card-title">PIPELINE BASIC</h4>
      </div> */}
      {pipelineBasics ? (
        <div className="card-body">
          <div className="table-responsive">
            <table className="table rwd-table table-metadata table-pipeline__basic">
              <thead>
                <tr>
                  <th className="bg-transparent"></th>
                  <th className="bg-transparent"></th>
                  <th colSpan={16}>Connection Matrix</th>
                  <th className="bg-transparent"></th>
                  <th className="bg-transparent"></th>
                </tr>
                <tr>
                  <th className="bg-transparent"></th>
                  <th className="bg-transparent"></th>
                  <th colSpan={8}>Start Asset</th>
                  <th colSpan={8}>End Asset</th>
                  <th className="bg-transparent"></th>
                  <th className="bg-transparent"></th>
                </tr>
                <tr>
                  {[{ name: "Asset Name" }, { name: "Asset Type" }].map(
                    (h, index) => (
                      <th key={index}>{h.name}</th>
                    )
                  )}
                  {[
                    { name: "Name" },
                    { name: "Status" },
                    { name: "Altitude" },
                    { name: "Latitude" },
                    { name: "Longitude" },
                    { name: "Easting (X)" },
                    { name: "Northing (Y)" },
                    { name: "Elevation (Z)" },
                  ].map((h, index) => (
                    <th key={index}>{h.name}</th>
                  ))}
                  {[
                    { name: "Name" },
                    { name: "Status" },
                    { name: "Altitude" },
                    { name: "Latitude" },
                    { name: "Longitude" },
                    { name: "Easting (X)" },
                    { name: "Northing (Y)" },
                    { name: "Elevation (Z)" },
                  ].map((h, index) => (
                    <th key={index}>{h.name}</th>
                  ))}
                  <th>Created Date</th>
                  <th>Updated Date</th>
                </tr>
              </thead>
              <tbody>
                {pipelineBasics && pipelineBasics.data === null ? (
                  <p>No data available for pipeline basics</p>
                ) : (
                  pipelineBasics.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.type}</td>

                      <td>{item.connection_matrix.start_asset.name}</td>

                      <td>{item.connection_matrix.start_asset.status}</td>

                      <td>
                        {item.connection_matrix.start_asset.location.altitude}
                      </td>
                      <td>
                        {item.connection_matrix.start_asset.location.latitude}
                      </td>
                      <td>
                        {item.connection_matrix.start_asset.location.longitude}
                      </td>
                      <td>{item.connection_matrix.start_asset.location.x}</td>
                      <td>{item.connection_matrix.start_asset.location.y}</td>
                      <td>{item.connection_matrix.start_asset.location.z}</td>

                      <td>{item.connection_matrix.end_asset.name}</td>

                      <td>{item.connection_matrix.end_asset.status}</td>

                      <td>
                        {item.connection_matrix.end_asset.location.altitude}
                      </td>
                      <td>
                        {item.connection_matrix.end_asset.location.latitude}
                      </td>
                      <td>
                        {item.connection_matrix.end_asset.location.longitude}
                      </td>
                      <td>{item.connection_matrix.end_asset.location.x}</td>
                      <td>{item.connection_matrix.end_asset.location.y}</td>
                      <td>{item.connection_matrix.end_asset.location.z}</td>

                      <td>{item.created_at}</td>
                      <td>{item.updated_at}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="custom__loader--container">
        <Loader />
        </div>
      )}
    </div>
  );
};

export default PipelineBasics;
